import { AutoCol, Button, Checkbox, Grid, Icon, Label, Tooltip } from "@intility/bifrost-react";
import React, {useEffect, useState} from 'react';
import produce from 'immer';
import {CompanyUser} from '../../../../../utils/hub/models';
import {useDispatch, useSelector} from 'react-redux';
import './newUser.scss';
import {faAngleLeft, faTrash} from '@fortawesome/pro-light-svg-icons';
import CountryOptions from '../Options/CountryOptions';
import LanguageOptions from '../Options/LanguageOptions';
import { isStateValid, validateUser, validateUserIntility } from "../../../../../utils/wizard/stateValidations";
import {addCompanyUserAsync, multiPut} from '../../../../../redux/company/users/userThunks';
import PropTypes from 'prop-types';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import SelectWithFeedback from '../../../../SelectWithFeedback/SelectWithFeedback';
import devLog from '../../../../../utils/devLog';
import UserTypeOptions, { getIntilityUserDescriptionOptions, RequiredValue } from "../Options/UserTypeOptions";
import useIntilityPermission from "../../../../../hooks/useIntilityPermission";
import { checkImmutableMatch } from "./UserRow";

export const NewUser = ({newUser, templateUser, currentUserId, setCurrentUserId, isComplete, clean}) => {
  const {userList, information} = useSelector(state => state.company.data.userPlatform);
  const [editable, setEditable] = useState(new CompanyUser());
  const [verify, setVerify] = useState(false);
  const [validation, setValidation] = useState(undefined);
  const [selectableBilling, setSelectableBilling] = useState([]);
  const [selectableLocation, setSelectableLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedBilling, setSelectedBilling] = useState({});
  const [userType, setUserType] = useState();
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {billingList} = useSelector(state => state.company.data.billingPlatform);
  const validEmailCharsRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const intilityUser = useIntilityPermission();
  const dispatch = useDispatch();

  const locationName = locationList?.find(b => b.id === editable?.locationId)?.name;
  const billingName = billingList?.find(b => b.id === editable?.billingId)?.name;

  useEffect(() => {
    if (currentUserId) {
      const errors = validateUser(editable);
      setValidation(errors);
    }
  }, [editable, currentUserId])

  useEffect(() => {
    if (templateUser) {
      setSelectedLocation({value: templateUser.locationId, label: templateUser.locationName, type: 'locationId'});
      devLog('User template: ', templateUser.locationId);
      setEditable(
        produce(editable, draft => {
          draft.firstName = templateUser.firstName;
          draft.lastName = templateUser.lastName;
          draft.mobilePhone = templateUser.phoneNumber;
          draft.primaryEmailAddress = templateUser.email;
          draft.locationId = templateUser.locationId;
        })
      );
    } else if (currentUserId) {
      setSelectedLocation({value: editable.locationId, label: locationName, type: 'locationId'});
      setSelectedBilling({value: editable.billingId, label: billingName, type: 'billingId'});
    }
  }, [templateUser]);

  useEffect(() => {
    if (billingList.length === 1) {
      setSelectedBilling({value: billingList[0].id, label: billingList[0].name, type: 'billingId'});
      setEditable(
        produce(draft => {
          draft.billingId = billingList[0].id;
        })
      );
    }
    setSelectableBilling(
      billingList?.map(billing => ({
        label: billing.name,
        value: billing.id,
        type: 'billingId'
      }))
    );
  }, [billingList]);

  useEffect(() => {
    if (locationList.length === 1) {
      setSelectedLocation({value: locationList[0].id, label: locationList[0].name, type: 'locationId'});
      setEditable(
        produce(draft => {
          draft.locationId = locationList[0].id;
        })
      );
    }
    setSelectableLocation(
      locationList?.map(location => ({
        label: location.name,
        value: location.id,
        type: 'locationId'
      }))
    );
  }, [locationList]);

  useEffect(() => {
    if (verify) {
      const errors = validateUser(editable);
      setValidation(errors);
    }
  }, [editable]);

  useEffect(() => {
    if (currentUserId) {
      setEditable(userList.find(u => u.id === currentUserId));
    }
  }, [userList, currentUserId]);

  const onChange = e => {
    const {name, value, checked} = e.target;

    if (name === 'accountEnabled') {
      setEditable(
        produce(draft => {
          draft[name] = checked;
        })
      );
    } else {
      setEditable(
        produce(draft => {
          draft[name] = value;
        })
      );
    }

  };

  const updateSelect = item => {
    if (item === null) return;
    setEditable(
      produce(draft => {
        draft[item.type] = item.value;
      })
    );
  };

  const saveUser = () => {
    setVerify(true);
    let errors = validateUser(editable);
    if (intilityUser) {
      errors = validateUserIntility(editable);
    }
    setValidation(errors);
    const isValid = isStateValid(errors);
    if (isValid && currentUserId === undefined) {
      dispatch(addCompanyUserAsync(editable));
      newUser(false);
      setVerify(false);
    } else if (isValid && currentUserId !== undefined) {
      dispatch(multiPut([editable]));
      newUser(false);
      setVerify(false);
    }
  };

  const deleteUser = () => {
    if (currentUserId !== undefined) {
      const user = produce(editable, draft => {
          draft.isEnabled = false;
        })
      setEditable(user);
      dispatch(multiPut([user]));
      setCurrentUserId(undefined);
      newUser(false);
    }
  };

  return (
    <div className={'new-user'} style={clean ? {padding: 0} : undefined}>
      {!clean && (
        <p
          style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}
          onClick={() => newUser(false)}
        >
          <Icon icon={faAngleLeft} /> User registration
        </p>
      )}
      {!clean && (
        <div className='new-user-header'>
          <h2>
            {currentUserId
              ? `Edit user: ${editable.firstName || ''} ${editable.lastName || ''}`
              : currentUserId
              ? `${editable.firstName || ''} ${editable.lastName || ''}`
              : 'Add new user'}
          </h2>
        </div>
      )}
      <AutoCol width={200} gap={8}>
        <InputWithFeedback
          onChange={onChange}
          value={editable.firstName}
          label='First Name'
          name='firstName'
          required={true}
          otherProps={
            validation?.firstName && {
              state: 'alert',
              feedback: 'Missing value'
            }
          }
        />
        <div>
          <InputWithFeedback
            onChange={onChange}
            value={editable.lastName}
            label='Last Name'
            name='lastName'
            required={RequiredValue(editable.intilityUserType)}
            otherProps={
              validation?.lastName ? {
                state: 'alert',
                feedback: 'Missing value'
              } : (!checkImmutableMatch(editable, 'lastName') && editable?.immutableCopy?.lastName) &&
              {
                feedback: `Last sync Azure value: ${editable?.immutableCopy?.lastName}`
              }
            }
          />
        </div>
        <SelectWithFeedback
          isValidated={validation?.billingId}
          label='Billing company'
          maxMenuHeight={200}
          required={true}
          isClearable={false}
          value={selectedBilling}
          className='users-box-select'
          placeholder={'- Select billing company -'}
          options={selectableBilling}
          onChange={item => {
            updateSelect(item);
            setSelectedBilling(item);
          }}
          otherProps={
            currentUserId && {
              value: {
                value: editable?.billingId,
                label: billingName
              }
            }
          }
        />
        <SelectWithFeedback
          isValidated={validation?.language}
          label='Language'
          maxMenuHeight={200}
          required={true}
          isClearable={false}
          className='users-box-select'
          placeholder={'- Select language -'}
          options={LanguageOptions}
          onChange={item => updateSelect(item)}
          otherProps={
            currentUserId && {
              value: {value: editable?.language, label: editable?.language}
            }
          }
        />
        <div>
          <InputWithFeedback
            onChange={onChange}
            value={editable.primaryEmailAddress}
            name='primaryEmailAddress'
            label='Username (UPN)'
            required={true}
            otherProps={
              validation?.primaryEmailAddress ? {
                state: 'alert',
                feedback: 'Missing value or wrong format'
              } : !validEmailCharsRegex.test(editable.primaryEmailAddress) ? {
                state: 'alert',
                feedback: 'Email invalid, please review'
              } : (!checkImmutableMatch(editable, 'primaryEmailAddress') && editable?.immutableCopy?.primaryEmailAddress) &&
              {
                feedback: `Last sync Azure value: ${editable?.immutableCopy?.primaryEmailAddress}`
              }
            }
          />
        </div>
        <div>
          <InputWithFeedback
            onChange={onChange}
            maxLength={20}
            value={editable.mobilePhone}
            name='mobilePhone'
            label='Mobile Phone'
            required={RequiredValue(editable.intilityUserType)}
            otherProps={
              validation?.mobilePhone ? {
                state: 'alert',
                feedback: 'Needs to be in format: +4712431243'
              } : (!checkImmutableMatch(editable, 'mobilePhone') && editable?.immutableCopy?.mobilePhone) &&
              {
                feedback: `Last sync Azure value: ${editable?.immutableCopy?.mobilePhone || 'None'}`
              }
            }
          />
        </div>
        <SelectWithFeedback
          isValidated={validation?.locationId}
          maxMenuHeight={200}
          required={true}
          label={'Location'}
          isClearable={false}
          value={selectedLocation}
          className='users-box-select'
          placeholder={'- Select location -'}
          options={selectableLocation}
          onChange={item => {
            updateSelect(item);
            setSelectedLocation(item);
          }}
          otherProps={
            currentUserId && {
              value: {value: editable.locationId, label: locationName}
            }
          }
        />
        <SelectWithFeedback
          isValidated={validation?.country}
          maxMenuHeight={200}
          required={true}
          label={'Country'}
          isClearable={false}
          className='users-box-select'
          placeholder={'- Select country -'}
          options={CountryOptions}
          onChange={item => updateSelect(item)}
          otherProps={
            currentUserId && {
              value: {value: editable.country, label: editable.country}
            }
          }
        />
        <SelectWithFeedback
          isValidated={validation?.intilityUserType}
          maxMenuHeight={200}
          label={'Intility user type'}
          isClearable={false}
          className='users-box-select'
          placeholder={'- Select user type -'}
          options={getIntilityUserDescriptionOptions(information.userTypes)}
          value={userType}
          onChange={item => {
            setUserType({ value: item.value, label: item.shortLabel, type: item.type });
            updateSelect({ value: item.value, label: item.shortLabel, type: item.type })
          }}
          otherProps={
            currentUserId && {
              value: UserTypeOptions[editable?.intilityUserType]
            }
          }
        />
      </AutoCol>
      <h4>Optional data</h4>
      <Grid small={2}>
        <div>
          <p style={{color: 'var(--bfc-base-c-2)'}}>
            Employment information
          </p>
          <Grid small={1} medium={2} large={2}>
            <InputWithFeedback
              onChange={onChange}
              value={editable.department}
              name='department'
              label='Department'
            />
            <InputWithFeedback onChange={onChange} value={editable.title} name='title' label='Title' />
            <div>
              <InputWithFeedback
                onChange={onChange}
                otherProps={
                  !validEmailCharsRegex.test(editable.mail) ? {
                    state: 'alert',
                    feedback: 'Email invalid, please review'
                  } : (!checkImmutableMatch(editable, 'mail') && editable?.immutableCopy?.mail) &&
                  {
                    feedback: `Last sync Azure value: ${editable?.immutableCopy?.mail}`
                  }
                }
                value={editable.mail}
                name='mail'
                label='Mail' />
            </div>
            <InputWithFeedback
              onChange={onChange}
              value={editable.employeeId}
              name='employeeId'
              label='Employee Id'/>
            <Grid.Span small={1} medium={2} large={2}>
              <InputWithFeedback
                onChange={onChange}
                value={editable.description}
                name='description'
                label='Description'
              />
            </Grid.Span>
            <Grid.Span small={1} medium={2} large={2}>
              <InputWithFeedback
                onChange={onChange}
                value={editable.comment}
                name='comment'
                label='Comment'
              />
            </Grid.Span>
          </Grid>
        </div>
        <div>
          <p style={{color: 'var(--bfc-base-c-2)'}}>
            AD details
          </p>
          <Grid small={1} medium={2} large={2}>
            <InputWithFeedback onChange={onChange} value={editable.displayName} name='displayName' label='Display name' />
            <InputWithFeedback onChange={onChange} value={editable.oldSam} name='oldSam' label='Old Sam' />
            <InputWithFeedback onChange={onChange} value={editable.oldSid} name='oldSid' label='Old Sid' />
            <div>
              <Label style={{marginBottom: 'var(--bfs4)'}}>AAD Enabled</Label>
              <Checkbox style={{marginTop: 'auto'}} button type={"switch"} onChange={onChange} checked={editable.accountEnabled} name='accountEnabled' label='Account enabled' />
              {!checkImmutableMatch(editable, 'accountEnabled', true) &&
                <p className='bf-small' style={{margin: 0, marginTop: 'var(--bfs4)'}}>Last sync Azure value: {editable?.immutableCopy?.accountEnabled === false ? <span style={{color: 'var(--bfc-alert)'}}>Disabled</span> : <span style={{color: 'var(--bfc-success)'}}>Enabled</span>}</p>
              }
            </div>
          </Grid>
        </div>
      </Grid>
      <div>
        <p style={{color: 'var(--bfc-base-c-2)'}}>
          Additional office information
        </p>
        <div>
          <Grid small={4}>
            <InputWithFeedback
              onChange={onChange}
              value={editable.physicalOffice}
              name='physicalOffice'
              label='Physical Office'
            />
            <InputWithFeedback
              onChange={onChange}
              value={editable.officePhone}
              name='officePhone'
              label='Office Phone'
            />
          </Grid>
          <Grid small={2}>
            <InputWithFeedback
              onChange={onChange}
              value={editable.streetAddress}
              name='streetAddress'
              label='Street Address'
            />
          </Grid>
          <Grid small={10}>
            <Grid.Span small={1}>
              <InputWithFeedback
                onChange={onChange}
                value={editable.postalCode}
                name='postalCode'
                label='Postal Code'
              />
            </Grid.Span>
            <Grid.Span small={2}>
                <InputWithFeedback onChange={onChange} value={editable.city} name='city' label='City' />
            </Grid.Span>
            <Grid.Span small={2}>
                <InputWithFeedback onChange={onChange} value={editable.state} name='state' label='State' />
            </Grid.Span>
          </Grid>
        </div>
      </div>
      <div className='new-user-buttons'>
        {currentUserId ? (
          <Button variant='outline' state='alert' icon={faTrash} onClick={() => deleteUser()}>
            Delete
          </Button>
        ) : (
          <div />
        )}
        <div>
          <Button
            onClick={() => {
              newUser(false);
            }}
          >
            Cancel
          </Button>
          <Button variant='filled' onClick={() => saveUser()}>
            {currentUserId ? 'Save changes' : 'Add user'}
          </Button>
        </div>
      </div>
    </div>
  );
};

NewUser.propTypes = {
  newUser: PropTypes.func,
  templateUser: PropTypes.object,
  currentUserId: PropTypes.string,
  setCurrentUserId: PropTypes.func,
  isComplete: PropTypes.bool,
  clean: PropTypes.bool
};

export default NewUser;
