import { Button, Icon, Input, Modal, Tooltip } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { IamsUser } from "../../../../../utils/hub/models";
import { addIamsUserAsync } from "../../../../../redux/company/iamsUsers/iamsUserThunks";
import { fetchUsersAsync } from "../../../../../redux/company/users/userThunks";
import { useDispatch, useSelector } from "react-redux";
import { emptyGuid } from "../../../../../utils/guid";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

export const NewIamsRegistrationModal = ({isOpen, setIsOpen, userList}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [newIamsUser, setNewIamsUser] = useState(undefined);
  const [edited, setEdited] = useState(false);
  const [computerText, setComputerText] = useState('');
  const dispatch = useDispatch();

  const userOptions = userList.map(u => {
    if (u.isEnabled === false) return {value: u, data: `${u?.firstName} ${u?.lastName}`, label: <Tooltip content='User deleted, if incorrect restore in Users page.'>${u?.firstName} ${u?.lastName} <Icon icon={faExclamationTriangle} style={{color: 'var(--bfc-warning)'}}/></Tooltip>};
    return {value: u, data: `${u?.firstName} ${u?.lastName}`, label: `${u?.firstName} ${u?.lastName}`};
  });

  useEffect(() => {
    if (edited) {
      dispatch(addIamsUserAsync(newIamsUser)).then(() => dispatch(fetchUsersAsync(id))).catch(error => console.log(error));
      setEdited(false);
      closeModal();
    }
  }, [edited]);

  const closeModal = () => {
    setIsOpen(false);
    setSelectedUser(undefined);
    setComputerText('');
  }

  const createNewIamsEntry = (user) => {
    if (user !== undefined) {
      setNewIamsUser(produce(new IamsUser(), draft => {
        draft.companyUserId = user.id === null ? emptyGuid : user.id;
        draft.companyId = user.companyId === null ? emptyGuid : user.companyId;
        draft.locationId = user.locationId === null ? emptyGuid : user.locationId;
        draft.locationName = user.location === null ? draft.locationName : user.location;
        draft.email = user.primaryEmailAddress === null ? draft.email : user.primaryEmailAddress;
        draft.firstName = user.firstName === null ? draft.firstName : user.firstName;
        draft.lastName = user.lastName === null ? draft.lastName : user.lastName;
        draft.phoneNumber = user.mobilePhone === null ? draft.phoneNumber : user.mobilePhone;
        draft.comment = "This is a fictitious scan for users who for any reason require multiple computers.";
        draft.companyUserMatch = 0;
        draft.recommendation = 'Verify';
        draft.computerAction = 'Replace';
        draft.model = computerText;
        draft.computerName = `${user.firstName}s new computer`;
        draft.oldUsername = `Autogenerated${Date.now()}`;
        draft.warranty = new Date();
        draft.purchasedDate = new Date();
      }));
      setEdited(true);
    }
  };

  return (
    <Modal isOpen={isOpen} header={'Add new computer'} onRequestClose={() => closeModal()}>
      <div style={{minHeight: '350px', minWidth: '300px', justifyContent: 'space-between'}}>
        <div>
          <Select
            label='Owner of computer'
            filterOption={(option, rawInput) => {
              console.log(option)
              if (option?.value === '201') return true;
              return option?.data?.data?.toString()?.toLowerCase()?.includes(rawInput?.toLowerCase());
            }}
            options={userOptions}
            value={selectedUser}
            onChange={item => setSelectedUser(item)}
            required={true}
          />
          <div />
        </div>
        <Input label={"Computer model"} value={computerText} onChange={e => setComputerText(e.target.value)}/>
        <div className='modal-button-bar'>
          <Button variant={'outline'} onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            variant={'filled'}
            disabled={selectedUser === undefined}
            onClick={() =>  createNewIamsEntry(selectedUser.value)}
          >
            Update user
          </Button>
        </div>
      </div>
    </Modal>
  )

}
NewIamsRegistrationModal.propTypes = {
  userList: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default NewIamsRegistrationModal