import Logs from '../../components/BackOffice/Logs/Logs';
import {faServer} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Changelog',
  path: 'changelog/',
  icon: faServer,
  editable: false,
  component: Logs,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Elastic logs';
  }
};
