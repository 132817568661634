import {adminPermissionPropTypes} from './permissionPropTypes';
import useAdminPermission from '../../hooks/useAdminPermission';

const AdminPermission = ({children}) => {
  const hasEditPermission = useAdminPermission();

  if (!hasEditPermission) return null;

  return children;
};

AdminPermission.propTypes = adminPermissionPropTypes;

export default AdminPermission;
