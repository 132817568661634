import { Checkbox, Input, TextArea } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import AppStepCard from "../AppStepCard";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { environmentOptions } from "../../ApplicationOptions";

export const StepEight = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, setIsOpen, totalSteps}) => {
  const [environment, setEnvironment] = useState([]);
  const [notRelevant, setNotRelevant] = useState(appObject.extraNotRelevant || false);

  useEffect(() => {
    const environmentValues = appObject?.environment?.split(',');
    const environmentArr = [];
    if (environmentValues?.includes('prod')) environmentArr.push({value: 'prod', label: 'Production'});
    if (environmentValues?.includes('dev')) environmentArr.push({value: 'dev', label: 'Development'});
    if (environmentValues?.includes('test')) environmentArr.push({value: 'test', label: 'Testing'});
    setEnvironment(environmentArr);
  }, []);

  const onNotRelevant = (e) => {
    const {checked} = e.target;
    setAppObject(
      produce(draft => {
        draft.comment = checked ? 'N/A' : '';
        draft.location = checked ? 'N/A' : '';
        draft.environment = checked ? 'N/A' : '';
        draft.extraNotRelevant = !notRelevant;
      })
    );
    if (checked) setEnvironment([]);
    setNotRelevant(!notRelevant);
  }

  const onChange = (value, name) => {
    setAppObject(
      produce(draft => {
        if (name === 'environment') {
          draft.environment = value.map(i => i.value).join(',')
        } else {
          draft[name] = value;
        }
      })
    );
  };

  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} progress={progress} stepReady={notRelevant || (appObject?.comment?.length > 0 || appObject?.environment?.length > 0)} setProgress={setProgress} header='Wrap it up' totalSteps={totalSteps}>
      <Checkbox label='Not relevant for this application' className='step-not-relevant-check' checked={notRelevant} onChange={(e) => onNotRelevant(e)}/>
      <div className='bfl-grid step-eight-select-row'>
        <Select
          options={environmentOptions}
          name='environment'
          label='Application environment'
          placeholder='Prod/dev/test'
          value={environment}
          isMulti={true}
          onChange={e => {
            setEnvironment(e);
            onChange(e, 'environment')
          }}
          isDisabled={notRelevant}
        />
        <Input
          value={appObject?.location}
          name='location'
          onChange={e => onChange(e.target.value, 'location')}
          label='Location specific app'
          placeholder='If relevant'
          disabled={notRelevant}
        />
      </div>
      <TextArea onChange={e => onChange(e.target.value, 'comment')} disabled={notRelevant} value={appObject?.comment} name='comment' label='Add a description / comment' description='The previous steps summarizes the information we need about the application, but maybe you have more to add?'/>
    </AppStepCard>
  );
};

export default StepEight

StepEight.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  newAppType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number
}