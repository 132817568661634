import React, {useEffect, useState} from 'react';
import Flex from 'components/Flex/Flex';
import {Button, Drawer, Input, TextArea} from '@intility/bifrost-react';
import DatePicker from "@intility/bifrost-react-datepicker";
import {IntilityPermission} from '../../../../../Permissions';
import {useDispatch, useSelector} from 'react-redux';
import {ComputerSetup} from '../../../../../../utils/hub/models';
import {setMacUrl} from '../../../../../../redux/company/computerSetup/computerSetupThunks';
import produce from 'immer';
import PropTypes from 'prop-types';

const ComputerRegistrationSlider = ({setIsOpen, isOpen, iamsLink}) => {
  const norwegianTemplate = (link, date, macLink) => {
    return (
      `Hei,%0D%0A%0D%0AI forbindelse med overgangen til ny IT-plattform hos Intility, må det gjennomføres ` +
      ` en kartlegging av deres IT-utstyr. Nå trenger vi din hjelp for å få oversikt over alle PC-ene ` +
      `som er i bruk per i dag.%0D%0A%0D%0AVi ber deg derfor bruke ett minutt på å sette i gang et program ` +
      ` kalt lntility Asset Management System (IAMS), som vil registrere dine PC-spesifikasjoner ` +
      `(RAM, minne, lagringsplass, hastighet) og installerte programmer. Merk at programmet ikke samler inn ` +
      ` personlig- eller bedriftssensitiv data.%0D%0A%0D%0AViktig! Programmet må gjennomføres lokalt på din PC. ` +
      ` Det kan ikke kjøres fra eksternt skrivebord som for eksempel Citrix desktop.%0D%0A%0D%0ADu finner ` +
      ` veiledning for kartleggingen vedlagt i denne mailen. Link til applikasjonen finner dere her:%0D%0A%0D%0A ` +
      `${link}%0D%0A%0D%0AEller denne lenken for Mac-brukere:%0D%0A%0D%0A${macLink}%0D%0A%0D%0AFint om alle ` +
      ` har fullført denne kartleggingen innen ${date}.`
    );
  };

  const englishTemplate = (link, date, macLink) => {
    return (
      `Hi,%0D%0A%0D%0AIn regards to the movement of IT services to Intility we need everyone ` +
      ` to complete a mapping of our current computer park. Therefore I ask that you to complete the` +
      `instructions in the attached guide, by running a application called Intility Asset ` +
      ` Management System (IAMS). The application will collect your computer’s hardware` +
      `specifications (RAM, HDD size, processor, etc.) and installed software. The application ` +
      ` will not collect any personal or business sensitive information.%0D%0A%0D%0ANote! ` +
      `This application must be run locally on your PC, not through external desktops like Citrix.
      %0D%0A%0D%0AYou can access the application by going to this link:%0D%0A%0D%0A${link}%0D%0A%0D%0AOr ` +
      ` this link for Mac-users:%0D%0A%0D%0A${macLink}%0D%0A%0D%0APlease complete this analysis by ${date}.`
    );
  };
  const norwegianValue = (link, date, macLink) => {
    return (
      `Hei,
       
I forbindelse med overgangen til ny IT-plattform hos Intility, må det gjennomføres en kartlegging av deres IT-utstyr. Nå trenger vi din hjelp for å få oversikt over alle PC-ene ` +
      `som er i bruk per i dag.
      
Vi ber deg derfor bruke ett minutt på å sette i gang et program kalt lntility Asset Management System (IAMS), som vil registrere dine PC-spesifikasjoner ` +
      `(RAM, minne, lagringsplass, hastighet) og installerte programmer. Merk at programmet ikke samler inn personlig- eller bedriftssensitiv data.
      
Viktig! Programmet må gjennomføres lokalt på din PC. Det kan ikke kjøres fra eksternt skrivebord som for eksempel Citrix desktop.
      
Du finner veiledning for kartleggingen vedlagt i denne mailen. Link til applikasjonen finner dere her:
      
      
${link}
          
Eller denne lenken for Mac-brukere:
      
${macLink}

Fint om alle har fullført denne kartleggingen innen ${date}.`
    );
  };

  const englishValue = (link, date, macLink) => {
    return (
      `Hi,
      
In regards to the movement of IT services to Intility we need everyone to complete a mapping of our current computer park. Therefore I ask that you to complete the` +
      `instructions in the attached guide, by running a application called Intility Asset Management System (IAMS). The application will collect your computer’s hardware` +
      `specifications (RAM, HDD size, processor, etc.) and installed software. The application will not collect any personal or business sensitive information.
      
Note! This application must be run locally on your PC, not through external desktops like Citrix.
      
You can access the application by going to this link:
      
${link}  
    
Or this link for Mac users:
      
${macLink}
      
Please complete this analysis by ${date}.`
    );
  };

  const dispatch = useDispatch();
  const [computers, setComputers] = useState(new ComputerSetup());
  const {desktopSettings} = useSelector(state => state.company.data.computerSetupPlatform);
  const [dateTime, setDateTime] = useState(new Date(Date.now()));

  useEffect(() => {
    setComputers(desktopSettings);
  }, [desktopSettings]);

  const onChange = e => {
    const {name, value} = e.target;
    setComputers(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  return (
    <Drawer overlay header='E-mail template' isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div className='location-slider'>
        <Flex>
          <DatePicker label='Add a Deadline date' selected={dateTime} onChange={d => setDateTime(d)} required />
          <Button
            style={{alignSelf: 'flex-end'}}
            variant='filled'
            onClick={() => setDateTime(new Date(Date.now()))}
            key={0}
          >
            Reset template
          </Button>
        </Flex>
        <Flex>
          <TextArea
            id='english-template'
            readOnly
            label='English'
            value={englishValue(iamsLink, dateTime, desktopSettings.iamsMacUrl || 'Not generated yet.')}
          />
        </Flex>
        <Flex>
          <a
            href={`mailto:Remember to attach guide from step 1.?subject=Intility mapping&body=${englishTemplate(
              iamsLink,
              dateTime,
              desktopSettings.iamsMacUrl || 'Not generated yet.'
            )}`}
          >
            <Button key={0}>Draft E-mail</Button>
          </a>
        </Flex>
        <Flex>
          <TextArea
            id='norwegian-template'
            readOnly
            label='Norwegian'
            value={norwegianValue(iamsLink, dateTime, desktopSettings.iamsMacUrl || 'Foreløpig ikke generert.')}
          />
        </Flex>
        <Flex>
          <a
            href={`mailto:Husk å legge ved guiden fra steg 1.?subject=Intility kartlegging&body=${norwegianTemplate(
              iamsLink,
              dateTime,
              desktopSettings.iamsMacUrl || 'Foreløpig ikke generert.'
            )}`}
          >
            <Button key={0}>Draft E-mail</Button>
          </a>
        </Flex>
        <IntilityPermission>
          <div style={{marginTop: 'var(--bfs24)'}}>
            <h5>Intility input for mac users:</h5>
            <Input value={computers.iamsMacUrl || ''} name='iamsMacUrl' onChange={onChange} label='Mac URL:' />
            <Button onClick={() => dispatch(setMacUrl(computers))} style={{marginTop: 'var(--bfs8)'}}>
              Submit
            </Button>
          </div>
        </IntilityPermission>
      </div>
    </Drawer>
  );
};

ComputerRegistrationSlider.propTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  iamsLink: PropTypes.string
};

export default ComputerRegistrationSlider;
