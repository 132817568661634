import {Company, Contact} from './models';

export class CompanyState {
  differentProductionContact = false;
  onboardingContact = new Contact();
  productionContact = new Contact();
  data = new Company();
  errors = {
    organizationNumber: false,
    name: false,
    address: false,
    domainName: false,
    email: false
  };
}

export class ApproveState {
  approved = false;
  errors = {};
}
