import {useDispatch, useSelector} from 'react-redux';
import { Link, NavLink, Redirect, Route, useHistory, useLocation } from "react-router-dom";
import React, {useEffect} from 'react';
import GlobalLoader from '../GlobalLoader/GlobalLoader';
import pageData from 'utils/globalSettings/index';
import useIntilityPermission from '../../hooks/useIntilityPermission';
import { Breadcrumbs, Icon, Spinner, Tabs } from "@intility/bifrost-react";
import './globalSettings.scss';
import { fetchGlobalTasksAsync } from "../../redux/company/onboardTasks/onboardTaskThunks";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";

const pages = pageData;

const GlobalSettings = () => {
  const {user} = useSelector(state => state.auth);
  const {taskStatus} = useSelector(state => state.company.data.taskPlatform);
  const {information: detailsInformation, detailStatus} = useSelector(state => state.company.data.detailsPlatform);
  const {fetching: updateLoading} = useSelector(state => state.company.update);
  const intilityUser = useIntilityPermission();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;
    dispatch(fetchGlobalTasksAsync());
  }, [user, dispatch]);

  useEffect(() => {
    // dispatch(fetchDetailsAsync(companyId));
  }, [intilityUser]);

  if (taskStatus?.errorGlobal) return <Redirect to='/' />;

  return intilityUser ? (
    <>
      <div id='global-settings'>
        <div className='global-settings-content'>
          {pages.map((page, i) => (
            <Route key={i} exact path={`/overview/settings/${page.path}`} component={page.component} />
          ))}
          <GlobalLoader loading={updateLoading} />
        </div>
      </div>
    </>
  ) : (
    <Spinner />
  );
};

export default GlobalSettings;
