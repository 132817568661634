import {Badge, Button, Checkbox, FormatDate, Icon, Input, Table, Tooltip} from '@intility/bifrost-react';
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import {getIntilityUserType, IntilityUserType, UserStatusTypes} from '../Options/UserTypeOptions';
import React, { useEffect, useState } from "react";
import {checkImmutableMatch, getStatusBadge, getUserName} from './UserRow';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAsync } from "../../../../../redux/company/users/userThunks";
import produce from "immer";
import './userRow.scss';
import useCurrentTheme from '../../../../Navigation/useCurrentTheme';

export const restoreUser = (user, dispatch) => {
  const editUser = produce(user, draft => {
    draft.isEnabled = true;
  });

  dispatch(updateUserAsync(editUser))
}

export const diffDateDays = (date) => {
  const dueDate = new Date(date);
  const currentDate = new Date(Date.now());
  if (dueDate?.getFullYear() === 1) return;
  const diffTime = Math.abs(dueDate - currentDate);
  return Math.round(diffTime / (1000 * 60 * 60 * 24));
}

export const DeletedUserRow = ({ user, isComplete, setAnySelected, anySelected, isAllSelected, userEditList, save, bulk, openLicenseModal, userRows, hideCheckbox }) => {
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {billingList} = useSelector(state => state.company.data.billingPlatform);
  const [editable, setEditable] = useState(user);
  const [selected, setSelected] = useState(false);
  const [edited, setEdited] = useState(false);
  const validEmailCharsRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validPhoneNumberRegex = /^\+?[0-9\s\-()]{7,15}$/;
  const locationName = locationList?.find(b => b.id === user.locationId)?.name;
  const billingName = billingList?.find(b => b.id === user.billingId)?.name;
  const userName = getUserName(user);
  const theme = useCurrentTheme();

  const changeSelection = e => {
    const {checked} = e.target;
    setSelected(checked);
    if (checked) {
      setAnySelected(i => i + 1);
    } else {
      setAnySelected(i => i - 1);
    }
  };

  const getStatus = () => {
    const inBilling = billingList.some(b => b.id === user.billingId);
    const inLocation = locationList.some(b => b.id === user.locationId);
    const validPrimaryEmail = validEmailCharsRegex.test(user.primaryEmailAddress);
    const validEmail = validEmailCharsRegex.test(user.mail);
    const validPhoneNumber = user.intilityUserType === IntilityUserType.IntilityIdUser && !validPhoneNumberRegex.test(user.mobilePhone);
    const emailMatch = (user?.mail?.length > 0 && user?.primaryEmailAddress?.toLowerCase()?.trim() !== user?.mail?.toLowerCase()?.trim());

    if (!inBilling || !inLocation || user?.primaryEmailAddress?.length === 0) return UserStatusTypes.Attention;
    if (emailMatch) return UserStatusTypes.Warning;
    if (validPhoneNumber) return UserStatusTypes.Warning;
    if (!validPrimaryEmail || ((user.mail !== null && user.mail !== '') && !validEmail)) return UserStatusTypes.Warning;

    return UserStatusTypes.Ready;
  };

  useEffect(() => {
    setEditable(user);
  }, [user]);

  useEffect(() => {
    if (save && (edited || (bulk && selected))) {
      const index = userEditList.findIndex(obj => obj.id === editable.id);
      if (index === -1) {
        userEditList.splice(0, 0, editable);
      }
    }
  }, [save, userEditList, editable, edited, bulk, selected]);

  useEffect(() => {
    setSelected(isAllSelected);
  }, [isAllSelected]);

  useEffect(() => {
    if (anySelected === 0 && !isAllSelected) {
      setSelected(false);
      setEdited(false);
    }
  }, [anySelected, isAllSelected]);

  const lineStatus = getStatus();

  const lineColor = () => {
    switch (lineStatus) {
      case UserStatusTypes.Ready:
        return 'var(--bfc-base-3)';
      case UserStatusTypes.Warning:
        return theme === 'dark' ? '#292b24' : '#fffbf0';
      case UserStatusTypes.Attention:
        return theme === 'dark' ? '#262533' : '#fff4f3';
    }
  }

  return (
    <Table.Row className={'user-row'} style={lineStatus === UserStatusTypes.Attention ? {background: 'hsla(var(--bfc-alert-fade-hsl), 0.4)'} : lineStatus === UserStatusTypes.Warning ? {background: 'hsla(var(--bfc-warning-fade-hsl), 0.3)'} : undefined}>
      {!hideCheckbox ?
        <Table.Cell style={{background: lineColor()}}>
          <div className='user-row-first-cell'>
            <Checkbox
              id={`row_${user.id}`}
              label={userName}
              hideLabel={true}
              checked={selected}
              onChange={changeSelection}
            />
          </div>
        </Table.Cell> :
        undefined
      }
      <Table.Cell style={{position: 'sticky', left: !hideCheckbox ? '67.5px' : 0, overflow: 'hidden', zIndex: 1, background: lineColor(), borderRight: '1px solid var(--bfc-base-dimmed)'}}>
        <span style={{display: 'flex', flexDirection: 'column'}}>
          <span>
            {user.primaryEmailAddress}
            {!validEmailCharsRegex.test(user.primaryEmailAddress) ?
              <Tooltip content='Email invalid, please review' placement='right'><Icon style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
              :
              (user?.mail?.length > 0 && user.primaryEmailAddress?.toLowerCase()?.trim() !== user?.mail?.toLowerCase()?.trim()) &&
              <Tooltip content='Mismatch between UPN and e-mail' placement='right'><Icon style={{color: 'var(--bfc-warning)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
            }
          </span>
          {!checkImmutableMatch(user, 'primaryEmailAddress') &&
            <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy?.primaryEmailAddress}</span>
          }
        </span>
      </Table.Cell>
      <Table.Cell>
        {getStatusBadge(lineStatus)}
      </Table.Cell>
      {userRows.map((r, i) => {
          if (r.value === 'createdAt' || r.value === 'modifiedAt') {
            return (
              <Table.Cell key={i}>
                {
                  diffDateDays(user[r.value]) === 0 ?
                    <Badge>Today</Badge>
                    : user[r.value] ?
                    <FormatDate date={user[r.value]} /> :
                      <Badge>Never</Badge>
                }
              </Table.Cell>
            )
          } else if (r.value === 'lastName') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  <span>
                    {user[r.value]}
                  </span>
                  {!checkImmutableMatch(user, r.value) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy[r.value]}</span>
                  }
                </span>
              </Table.Cell>
            )
          } else if (r.value === 'accountEnabled') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  <span>
                    {editable[r.value] === false ? <Badge state={'alert'}>Disabled</Badge> : <Badge state={'success'}>Enabled</Badge>}
                  </span>
                  {!checkImmutableMatch(user, r.value, true) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>
                      Entra ID: {editable?.immutableCopy[r.value] === false ? <span style={{color: 'var(--bfc-alert)'}}>Disabled</span> : <span style={{color: 'var(--bfc-success)'}}>Enabled</span>}
                    </span>
                  }
                </span>
              </Table.Cell>
            )
          } else if (r.value === 'primaryEmailAddress') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  <span>
                    {user[r.value]}
                    {!validEmailCharsRegex.test(user[r.value]) ?
                      <Tooltip content='Email invalid, please review' placement='right'><Icon style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
                      :
                      (user?.mail?.length > 0 && user[r.value]?.toLowerCase()?.trim() !== user?.mail?.toLowerCase()?.trim()) &&
                      <Tooltip content='Mismatch between UPN and e-mail' placement='right'><Icon style={{color: 'var(--bfc-warning)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
                    }
                  </span>
                  {!checkImmutableMatch(user, r.value) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy[r.value]}</span>
                  }
                </span>
              </Table.Cell>
            )
          } else if (r.value === 'mail') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  <span>
                    {user[r.value]}
                    {(user[r.value] !== null && user[r.value] !== '') && !validEmailCharsRegex.test(user[r.value]) &&
                      <Tooltip content='Email invalid, please review' placement='right'><Icon style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
                    }
                  </span>
                  {!checkImmutableMatch(user, r.value) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy[r.value]}</span>
                  }
                </span>
              </Table.Cell>
            )
          } else if (r.value === 'mobilePhone') {
            return (
              <Table.Cell key={i}>
                {(user[r.value]) ?
                  <span style={{display: 'flex', flexDirection: 'column'}}>
                      <span>
                        {user[r.value]}
                        {user.intilityUserType === IntilityUserType.IntilityIdUser && !validPhoneNumberRegex.test(user[r.value]) &&
                          <Tooltip content='Phone number invalid, please review' placement='right'><Icon style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
                        }
                      </span>
                    {!checkImmutableMatch(user, r.value) &&
                      <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy[r.value]}</span>
                    }
                    </span>
                  : user.intilityUserType === IntilityUserType.IntilityIdUser &&
                  <Badge pill state='alert'><Icon style={{color: 'var(--bfc-base-c-alert)'}} icon={faExclamationTriangle}/> Missing</Badge>
                }

              </Table.Cell>
            )
          } else if (r.value === 'intilityUserType') {
            return (
              <Table.Cell key={i}>{getIntilityUserType(user.intilityUserType)}</Table.Cell>
            )
          } else if (r.value === 'licenses') {
            return (
              <Table.Cell key={i}>
                {(user.immutableCopy === null || user.immutableCopy === undefined) ?
                  <Tooltip content='Only available on Imported Entra ID Users'>
                    <Button state={"inactive"}>View licenses ({user[r.value]?.length})</Button>
                  </Tooltip>
                  :
                  <Button disabled={user[r.value]?.length === 0} onClick={() => openLicenseModal(user)}>View licenses ({user[r.value]?.length})</Button>
                }
              </Table.Cell>
            )
          } else if (r.value === 'locationId' || r.value === 'billingId') {
            const value = r.value === 'locationId' ? locationName : billingName;
            return (
              <Table.Cell key={i}>
                {value || <Badge pill state='alert'><Icon style={{color: 'var(--bfc-base-c-alert)'}} icon={faExclamationTriangle}/> Missing</Badge>}
              </Table.Cell>
            )
          }
          return (
            <Table.Cell key={i}>
              {user[r.value]}
            </Table.Cell>
          )
        })
      }
    </Table.Row>
  )

}

DeletedUserRow.propTypes = {
  user: PropTypes.object,
  isComplete: PropTypes.bool,
  isAllSelected: PropTypes.bool,
  setAnySelected: PropTypes.func,
  anySelected: PropTypes.number,
  userEditList: PropTypes.array,
  save: PropTypes.bool,
  bulk: PropTypes.bool,
  openLicenseModal: PropTypes.func,
  userRows: PropTypes.array
}

export default DeletedUserRow