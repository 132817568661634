import {Microsoft365} from '../../../components/Hub/Pages';
import pluralize from '../../../utils/pluralize';
import {faServer} from '@fortawesome/pro-solid-svg-icons';
import {Microsoft365Origin} from '../../../components/Hub/Pages/SystemsAndServices.Applications/Applications';

const getApps = apps => {
  let count = apps.information.applicationCount;
  if (apps.applicationList !== undefined) count = apps.applicationList.filter(a => a.origin === Microsoft365Origin).length;
  return count;
};

export default {
  title: 'Microsoft 365 and Azure',
  path: 'microsoft365',
  icon: faServer,
  isEnabled: (company) => {return company?.microsoft365Platform?.state},
  component: Microsoft365,
  getStatus: company => {
    if (getApps(company.applicationPlatform) > 0) {
      return '';
    }
    return 'error';
  },
  getStatusText: (status, company) => {
    if (status === 'error') {
      return 'No 365 apps registered';
    }
    return `${
      getApps(company.applicationPlatform) > 0
        ? getApps(company.applicationPlatform)
        : 'No'
    } ${pluralize('365 application', getApps(company.applicationPlatform))} registered`;
  }
};
