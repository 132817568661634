import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  fetchCommunicationItemsSuccess,
  updateCommunicationItemsInformationSuccess,
  updateCommunicationItemsSuccess
} from './communicationActions';

const updateOnCommunicationInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchCommunicationItemsSuccess]: (state, action) => getState(action.payload, state),
    [updateCommunicationItemsSuccess]: (state, action) => getState(action.payload, state),
    [updateCommunicationItemsInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const communicationInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    note: '',
    communicationCount: '',
    modifiedAt: '',
    isEnabled: true
  },
  updateOnCommunicationInformationChangeReducer((payload, state) => {
    state.id = payload.communicationPlatform.information.id;
    state.companyId = payload.communicationPlatform.information.companyId;
    state.note = payload.communicationPlatform.information.note;
    state.communicationCount = payload.communicationPlatform.information.communicationCount;
    state.isEnabled = payload.communicationPlatform.information.isEnabled;
    state.modifiedAt = payload.communicationPlatform.information.modifiedAt;
    return state;
  })
);

const updateOnCommunicationChangeReducer = getState => {
  return {
    [fetchCommunicationItemsSuccess]: (state, action) => getState(action.payload, state),
    [updateCommunicationItemsSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const communicationsReducer = createReducer(
  [],
  updateOnCommunicationChangeReducer((payload, state) => {
    state = payload.communicationPlatform.communicationList;
    return state;
  })
);

const updateOnCommunicationStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchCommunicationItemsSuccess]: (state, action) => getState(action.payload, state),
    [communicationInformationReducer]: (state, action) => getState(action.payload, state),
  };
};

export const communicationStateReducer = createReducer(
  0,
  updateOnCommunicationStateReducer((payload, state) => {
    state = payload.communicationPlatform.state;
    return state;
  })
);

export const communicationStatusSlice = createSlice({
  name: 'communicationAction',
  initialState: {
    fetching: false,
    updating: false,
    error: false
  },
  reducers: {
    fetchCommunicationItems: state => {
      state.fetching = true;
    },
    fetchCommunicationItemsError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateCommunicationItems: state => {
      state.updating = true;
    },
    updateCommunicationItemsError: state => {
      state.updating = false;
      state.error = true;
    },
    updateCommunicationItemsClear: state => {
      state.updating = false;
      state.fetching = false;
      state.error = false;
    }
  },
  extraReducers: {
    [fetchCommunicationItemsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateCommunicationItemsSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateCommunicationItemsInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchCommunicationItems,
  fetchCommunicationItemsError,
  updateCommunicationItems,
  updateCommunicationItemsError,
  updateCommunicationItemsClear
} = communicationStatusSlice.actions;
