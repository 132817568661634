import PropTypes from 'prop-types';
import React from 'react';
import './informationBox.scss';
import {Badge, Icon} from '@intility/bifrost-react';
import {faCopy, faInfoCircle} from '@fortawesome/pro-regular-svg-icons';

export const InformationBox = ({leftText, right, image, value, link, emptyState}) => {
  return (
    <div className='information-box'>
      <div>
        <span>
          <span className='image-container'>{image || ''}</span>
          <h5>{leftText}</h5>
        </span>
      </div>
      <div style={{flex: '1'}}>
        <span className='information-box-content'>
          {right ||
            (link ? (
              value ? (
                <span>
                  <a className='bf-link' target='_blank' href={value} rel='noopener noreferrer'>
                    Link
                  </a>
                  <Icon onClick={() => navigator.clipboard.writeText(value)} icon={faCopy} />
                </span>
              ) : emptyState ? emptyState : (
                <Badge state='warning'>
                  <Icon icon={faInfoCircle} /> No information
                </Badge>
              )
            ) : value ? (
              <p className='bf-large'>{value}</p>
            ) : emptyState ? emptyState : (
              <Badge state='warning'>
                <Icon icon={faInfoCircle} /> No information
              </Badge>
            ))}
        </span>
      </div>
    </div>
  );
};

InformationBox.propTypes = {
  leftText: PropTypes.string,
  image: PropTypes.node,
  right: PropTypes.node,
  value: PropTypes.any,
  link: PropTypes.bool
};

export default InformationBox;
