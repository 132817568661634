import {faPhoneLaptop} from '@fortawesome/pro-solid-svg-icons';
import ProjectTeam from '../../components/BackOffice/ProjectTeam/ProjectTeam';

export default {
  title: 'Project Team',
  path: 'projectTeam/',
  icon: faPhoneLaptop,
  editable: false,
  component: ProjectTeam
};
