import {createAction} from '@reduxjs/toolkit';
import {
  normalizeDetails,
  normalizeDetailsInformation,
  normalizeDetailsList,
  normalizeFinancialDetails
} from './detailNormalizers';

export const fetchDetailsListSuccess = createAction('detailsAction/fetchDetailsListSuccess', apiDetailss => ({
  payload: normalizeDetailsList(apiDetailss)
}));

export const fetchInformationSuccess = createAction('detailsAction/fetchDetailsInformationSuccess', apiDetailss => ({
  payload: normalizeDetailsInformation(apiDetailss)
}));

export const fetchDetailsSuccess = createAction('detailsAction/fetchDetailsSuccess', apiDetails => ({
  payload: normalizeDetails(apiDetails)
}));

export const fetchFinancialDetailsSuccess = createAction('detailsAction/fetchFinancialDetailsSuccess', apiDetails => ({
  payload: normalizeFinancialDetails(apiDetails)
}));

export const updateDetailSuccess = createAction('detailsAction/updateDetailsSuccess', apiDefaultItems => ({
  payload: normalizeDetails(apiDefaultItems)
}));

export const updateDetailInformationSuccess = createAction('detailsAction/updateInformationSuccess', information => ({
  payload: normalizeDetailsInformation(information)
}));
