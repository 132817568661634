import {Icon} from '@intility/bifrost-react';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';

const accordionTitle = (title, anySelected, defaultMessage) => {
  return (
    <div className='accordion-title'>
      <div className='accordion-title-content'>
        <p style={{marginRight: 'var(--bfs4)'}}>{title}</p>
        {anySelected ? <Icon icon={faCheck}/> : <p>{defaultMessage || 'Default selected'}</p>}
      </div>
    </div>
  );
};

export default accordionTitle;
