import React from 'react';
import './contactBox.scss';
import skyLogo from 'assets/images/TopBG.png';
import { Button, Card, Icon, Tooltip } from "@intility/bifrost-react";
import PropTypes from 'prop-types';
import { faPlus, faUserPlus, faUser, faComments, faCommentSlash } from "@fortawesome/pro-regular-svg-icons";
import CheckUserDeleted from "../../../../../utils/hub/checkUserDeleted";

const ContactBox = ({contact, title, edit}) => {
  return (
    <div className='contact-box'>
      <h5 style={{margin: 'var(--bfs16) 0'}}>{title} <CheckUserDeleted userName={contact?.firstName + contact?.lastName} email={contact?.email} phoneNumber={contact?.phoneNumber}/></h5>
      <Card align='center'>
        <Card.Image url={skyLogo} style={{minHeight: '90px'}} />
        <Card.Logo
          {...(contact?.photo
            ? {
                imageUrl: `data:image/png;base64,${contact?.photo}`
              }
            : {
                icon: faUser
              })}
        />
        <Card.Title>
          {!contact.firstName && !contact.lastName ? 'No contact name' : `${contact?.firstName} ${contact?.lastName}`}
        </Card.Title>
        <Card.Content style={{minHeight: '63px'}}>
          <a className='bf-p bf-link' style={{color: 'var(--bfc-base-c-2)', margin: '0'}} href={`mailto:${contact?.email}`}>
            {contact.email || 'No email'}
          </a>
          <p style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>{contact.phoneNumber || 'No phone number'}</p>
        </Card.Content>
        <Card.Content>
          <p onClick={() => edit()} className='bf-strong' style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}>
            EDIT
          </p>
        </Card.Content>
      </Card>
    </div>
  );
};

ContactBox.propTypes = {
  contact: PropTypes.object,
  title: PropTypes.string,
  edit: PropTypes.func
};

export const VendorContactBox = ({contact, title, edit}) => {
  return (
    <div className='contact-box'>
      <h5 style={{margin: 'var(--bfs16) 0'}}>{title}</h5>
      <Card align='center'>
        <Card.Image style={{minHeight: '90px', backgroundColor: 'var(--bfc-base-c-wcag)', opacity: '0.5'}}  url={'https://onboard.intility.com'}/>
        <Card.Logo icon={faUser} />
        {contact?.contactable === true ?
          <div className='contact-icon-image' style={{backgroundColor: 'var(--bfc-success-fade)'}}>
            <Tooltip content='Intility can contact this vendor' placement='top' state='neutral'>
              <Icon className='bf-small' style={{color: 'var(--bfc-success-fade-c)'}} icon={faComments}/>
            </Tooltip>
          </div>
            : contact?.contactable === false &&
          <div className='contact-icon-image' style={{backgroundColor: 'var(--bfc-alert-fade)'}}>
            <Tooltip content='Intility can not contact this vendor yet' placement='top' state='neutral'>
              <Icon className='bf-small' style={{color: 'var(--bfc-alert-fade-c)'}} icon={faCommentSlash}/>
            </Tooltip>
          </div>
        }
        <Card.Title>
          {contact.vendorName}
        </Card.Title>
        <Card.Content style={{minHeight: '63px'}}>
          <p style={{margin: '0'}}>{contact.firstName} {contact.lastName}</p>
          <a className='bf-p bf-link' style={{color: 'var(--bfc-base-c-2)', margin: '0'}} href={`mailto:${contact.email}`}>
            {contact.email}
          </a>
          <p style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>{contact.phoneNumber}</p>
        </Card.Content>
        <Card.Content>
          <p onClick={() => edit()} className='bf-strong' style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}>
            EDIT
          </p>
        </Card.Content>
      </Card>
    </div>
  );
};

VendorContactBox.propTypes = {
  contact: PropTypes.object,
  title: PropTypes.string,
  edit: PropTypes.func
};

export const NewContactBox = ({contact, title, addContact}) => {
  return (
    <div className='contact-box'>
      <h5 style={{margin: 'var(--bfs16) 0'}}>{title}</h5>
      <Card align='center'>
        <Card.Image style={{minHeight: '90px', backgroundColor: 'var(--bfc-base-c-wcag)', opacity: '0.5'}}  url={'https://onboard.intility.com'}/>
        <Card.Logo
          {...(contact?.photo
            ? {
                imageUrl: `data:image/png;base64,${contact?.photo}`
              }
            : {
                icon: faUserPlus
              })}
        />
        <Card.Title>
          <p style={{marginBottom: 'var(--bfs4)'}} className='bf-em'>Please add your current vendor</p>
        </Card.Title>
        <Card.Content style={{minHeight: '62px'}}>
        </Card.Content>
        <Card.Content style={{display: 'flex', padding: '0', marginBottom: '0', height: '50px', border: '0'}}>
          <Button onClick={() => addContact()} style={{flex: '1', borderRadius: '0 0 var(--bfl-border-radius) var(--bfl-border-radius)'}} variant={"filled"} icon={faPlus}>Add contact</Button>
        </Card.Content>
      </Card>
    </div>
  );
};

NewContactBox.propTypes = {
  contact: PropTypes.object,
  title: PropTypes.string,
  addContact: PropTypes.func
};

export default ContactBox;
