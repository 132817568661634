export const editClientApplication = (applicationList, computerSetupPlatform) => {
  return {
    computerSetupPlatform: {
      desktopSettings: computerSetupPlatform
    }
  };
};

export const normalizeComputerSetup = computerSetupPlatform => {
  return {
    computerSetupPlatform: {
      desktopSettings: computerSetupPlatform,
      ...computerSetupPlatform
    }
  };
};

export const normalizeComputerSetupInformation = computerSetupPlatform => {
  return {
    computerSetupPlatform: {
      desktopSettings: computerSetupPlatform,
      ...computerSetupPlatform
    }
  };
};
