import ApplicationsNew from '../../../components/Hub/Pages/SystemsAndServices.Applications/Applications_new';
import {Applications} from '../../../components/Hub/Pages';
import pluralize from '../../../utils/pluralize';
import {faBrowser} from '@fortawesome/pro-regular-svg-icons';
import EditApplication from '../../../components/Hub/Pages/SystemsAndServices.Applications/NewApplication/EditApplication';

export default {
  title: 'Applications',
  path: 'applications',
  icon: faBrowser,
  isEnabled: company => {
    return company?.applicationPlatform?.state;
  },
  component: Applications,
  getStatus: company => {
    if (company.applicationPlatform.information.applicationCount > 0) {
      return '';
    }
    return 'error';
  },
  getStatusText: (status, company) => {
    if (status === 'error') {
      return 'No applications registered';
    }
    return `${
      company.applicationPlatform.applicationList?.length > 0
        ? company.applicationPlatform.applicationList?.length
        : company.applicationPlatform.information.applicationCount || 'No'
    } ${pluralize('application', company.applicationPlatform.information.applicationCount)} registered`;
  }
};

export const Application = {
  title: 'Application',
  path: 'applications/:applicationId',
  isEnabled: company => {
    return company?.applicationPlatform?.state;
  },
  component: EditApplication
};
