import React from "react";
import {Badge, dateFormat, useBreakpoint, useFloatingMessage} from '@intility/bifrost-react';
import { Checkbox, Icon } from "@intility/bifrost-react";
import { faEdit, faListCheck, faQuestionCircle, faTasks } from "@fortawesome/pro-regular-svg-icons";
import { faComment, faUser } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from 'prop-types';
import './onboardTask.scss';
import { useDispatch, useSelector } from "react-redux";
import { updateOnboardTaskAsync } from "../../../../../redux/company/onboardTasks/onboardTaskThunks";
import produce from "immer";
import { responsibleType } from "../../../../BackOffice/EditComponents/EditOnboardTasks";
import useIntilityPermission from "../../../../../hooks/useIntilityPermission";
import { pageDataOptions } from "../../../../../utils/hub/pageData/pageDataOptions";
import { useHistory } from "react-router-dom";

const OnboardTaskComponent = ({task, taskUser, setSelectedTask, setEditTask, simpleTask, drawerTask, setTaskHighight, setIsOpen, ...props}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth);
  const intilityUser = useIntilityPermission();
  const { showFloatingMessage } = useFloatingMessage();
  const fromMedium = useBreakpoint("medium", null);
  const dispatch = useDispatch();
  const history = useHistory();

  const checkTask = () => {
    const updatedTask = produce(task, draft => {
      draft.complete = !task.complete;
    })
    dispatch(updateOnboardTaskAsync(updatedTask))
  };

  const superHighlight = () => {
    setTaskHighight(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  }

  const selfAssignTask = () => {
    const updatedTask = produce(task, draft => {
      draft.userId = user.id
    });
    dispatch(updateOnboardTaskAsync(updatedTask))
  };

  const dueTask = (date, complete) => {
    if (date === null || date === undefined || complete) return;
    const dueDate = new Date(date);
    const currentDate = new Date(Date.now());
    if (dueDate?.getFullYear() === 1) return;
    const diffTime = Math.abs(dueDate - currentDate);
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    if (currentDate > dueDate) return <Badge style={{marginLeft: 'var(--bfs12)'}} state={'alert'}>Task overdue!</Badge>
    if (diffDays === 0) return <Badge style={{marginLeft: 'var(--bfs12)'}} state={'warning'}>Due today</Badge>
    if (diffDays <= 3) return <Badge style={{marginLeft: 'var(--bfs12)'}} state={'warning'}>Due in {diffDays} days</Badge>
  }

  return (
    <div className='onboard-task' {...props}>
      <div className='task-text'>
        {!drawerTask &&
          <div className='task-text-checkbox'>
            <Checkbox checked={task?.complete} onChange={() => checkTask()} label='task text' hideLabel/>
          </div>
        }
        <div className='task-text-content'>
          <div className='task-text-header'>
            <span className='bf-p bf-strong' onClick={() => {
              if (drawerTask) {
                return;
              } else if (task?.request === 'Invite project team to Onboard') {
                superHighlight();
              } else if (history.location.pathname === `/${id}/${pageDataOptions[task?.section]?.path}`) {
                showFloatingMessage('Task can be completed on the current page');
              } else history.push(`/${id}/${pageDataOptions[task?.section]?.path}`);
            }} style={{margin: '0', marginRight: 'var(--bfs4)', cursor: 'pointer'}}>{task?.request}</span>
            {dueTask(task?.dueDate, task?.complete)}
            {(task?.guideUrl && !drawerTask) &&
              <div className='open-guide' key='open-guide'>
                <span onClick={() => setSelectedTask(task)}><Icon icon={faQuestionCircle}/>
                  {fromMedium &&
                    <p>Read more</p>
                  }
                </span>
              </div>
            }
          </div>
          <div className='task-text-info'>
            <div>
              <div>
                <Icon icon={pageDataOptions[task?.section]?.icon || faTasks} />
              </div>
              <span className='bf-p bf-small'>
                Assignee:
                {!taskUser ? drawerTask ?
                    <span className='bf-em'>None</span> : !task?.complete ?
                  <span className='bf-em'>None - <span className='bf-link' style={{color: 'var(--bfc-natural)'}} onClick={() => selfAssignTask()}>assign yourself</span></span> :
                  <span className='bf-em'>None</span> :
                  <span>{
                    taskUser?.profilePhoto ?
                    <img src={`data:image/png;base64,${taskUser?.profilePhoto}`} alt='img' />
                    :
                    <span style={{height: `12px`, marginRight: 'var(--bfs4)'}}>
                      <Icon style={{margin: 'auto'}} icon={faUser} />
                    </span>

                  }{taskUser?.firstName} {taskUser?.lastName}</span>
                }
              </span>
              {((task?.responsible === responsibleType.CUSTOMER || intilityUser) && !drawerTask && !task?.complete) &&
                <span className='bf-p bf-link bf-small' onClick={() => {
                  setEditTask(task);
                }}>
                  <Icon icon={faEdit}/>
                  Edit
                </span>
              }
            </div>
            <p className='bf-small bf-em' style={{color: 'var(--bfc-base-c-2)'}}>
              {task?.complete ? 'Completed: ' : 'Due date: '}
              {new Date(task?.complete ? task?.modifiedAt : task?.dueDate)?.getFullYear() > 1 ? dateFormat('no-nb', new Date(task?.complete ? task?.modifiedAt : task?.dueDate), task?.complete ? {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              } : {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit'}) : 'Not set'}
              {task?.complete && ` by ${task?.modifiedBy}`}
            </p>
          </div>
          {task?.comment?.length > 0 &&
            <div style={{display: 'flex'}}>
              <div style={{display: "flex", flexDirection: 'row', borderRadius: '3px', background: 'var(--bfc-theme-c-1)', padding: '4px 8px', alignItems: 'center', marginTop: 'var(--bfs4)'}}>
                <Icon icon={faComment} style={{fontSize: '12px', marginRight: 'var(--bfs4)', color: 'var(--bfc-base-c-theme)'}}/>
                <p className='bf-small' style={{margin: 0}}>{task?.comment}</p>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

OnboardTaskComponent.propTypes = {
  task: PropTypes.object,
  taskUser: PropTypes.object,
  setSelectedTask: PropTypes.func,
  setTaskHighight: PropTypes.func,
  setIsOpen: PropTypes.func,
  setEditTask: PropTypes.func,
  simpleTask: PropTypes.bool,
  drawerTask: PropTypes.bool,
};

export const CompleteTaskComponent = ({showFinalize, isComplete, setIsComplete, togglePrompt, ...props}) => {
  const intilityUser = useIntilityPermission();
  const { showFloatingMessage } = useFloatingMessage();
  const toggle = () => {
    if (togglePrompt && !isComplete) {
      const result = confirm('You will not be able to make additional changes here once toggled. Future additions/edits will need to be made in the Intility portal. Do you wish to proceed?');
      if (result) setIsComplete(true);
    } else if (togglePrompt && isComplete && !intilityUser) {
      showFloatingMessage('Changes locked, future changes will be handled by Intility portal.');
    } else {
      setIsComplete(x => !x);
    }
  };

  return (
    <div className='onboard-task' style={!showFinalize ? {color: 'var(--bfc-base-c-2)'} : undefined} {...props}>
      <div className='task-text'>
        <div className='task-text-checkbox'>
          <Checkbox disabled={!showFinalize && !intilityUser} checked={isComplete} onChange={() => toggle()} label='task text' hideLabel/>
        </div>
        <div className='task-text-content'>
          <div className='task-text-header'>
            <span className='bf-p bf-strong' style={{margin: '0', marginRight: 'var(--bfs4)'}}>Complete Section</span>
          </div>
          <div className='task-text-info'>
            <div>
              <div>
                <Icon icon={faListCheck} />
              </div>
              <span className='bf-p bf-small' style={!showFinalize ? {color: 'var(--bfc-base-c-2)'} : undefined}>
                Assignee:
                <span className='bf-em'>Customer contact</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompleteTaskComponent.propTypes = {
  showFinalize: PropTypes.bool,
  isComplete: PropTypes.bool,
  setIsComplete: PropTypes.func
};

export default OnboardTaskComponent;
