import { OverviewWelcomeBox, OverviewTaskBox } from "./OverviewBox";
import React from "react";
import './overviewBoxes.scss';

export const OverviewBoxes = () => {

  return (
    <div className='box-content bfl-grid'>
      <OverviewTaskBox/>
      <OverviewWelcomeBox/>
    </div>
  )
}

export default OverviewBoxes