import produce from 'immer';
import devLog from '../../utils/devLog';

export const editListItem = (list, item) => {
  const index = list.findIndex(obj => obj.id === item.id);
  return produce(list, draft => {
    draft[index] = {...item};
  });
};

export const toggleListItem = (list, itemId) => {
  const index = list.findIndex(obj => obj.id === itemId);
  return produce(list, draft => {
    draft[index] = produce(draft[index], draft => {
      draft.isEnabled = !draft.isEnabled
    });
  });
};

export const addListItem = (list, item) => {
  return [...list, item];
};

export const deleteListItem = (list, itemId) => {
  const index = list.findIndex(obj => obj.id === itemId);
  return produce(list, draft => {
    draft.splice(index, 1);
  });
};

export const editListItems = (list, items) => {
  return list.map(item => {
    const found = items.find(s => s.id === item.id);
    if (found) {
      return found;
    }
    return item;
  });
};

export const deleteListItems = (list, items) => {
  const newList = list.map(i => i);
  items.forEach(item => {
    const index = newList.findIndex(s => s.id === item.id);
    if (index > -1) {
      newList.splice(index, 1);
    }
  });
  return newList;
};

export default apiCompany => {
  devLog('Normalizing', apiCompany);

  return {
    ...apiCompany,
    applicationPlatform: {
      information: apiCompany.applicationPlatform,
      state: apiCompany.applicationPlatform?.state,
      filters: [],
      applicationList: []
    },
    domainPlatform: {
      information: apiCompany.domainPlatform,
      state: apiCompany.domainPlatform?.state,
      domainList: []
    },
    locationPlatform: {
      information: apiCompany.locationPlatform,
      state: apiCompany.locationPlatform?.state,
      locationList: []
    },
    documentationPlatform: {
      information: apiCompany.documentationPlatform,
      state: apiCompany.documentationPlatform?.state,
      documentList: []
    },
    financialDetails: [],
    detailsPlatform: {
      information: apiCompany.detailsPlatform,
      detailList: []
    },
    userPlatform: {
      information: apiCompany.userPlatform,
      state: apiCompany.userPlatform?.state,
      user: {},
      userList: []
    },
    iamsUserPlatform: {
      information: apiCompany.iamsUserPlatform,
      state: apiCompany.iamsUserPlatform?.state,
      iamsUserList: []
    },
    printerPlatform: {
      information: apiCompany.printerPlatform,
      state: apiCompany.printerPlatform?.state,
      printerList: []
    },
    billingPlatform: {
      information: apiCompany.billingPlatform,
      state: apiCompany.billingPlatform?.state,
      billingList: []
    },
    communicationPlatform: {
      information: apiCompany.communicationPlatform,
      state: apiCompany.communicationPlatform?.state,
      communicationList: []
    },
    taskPlatform: {
      information: apiCompany.taskPlatform,
      taskList: [],
      globalTaskList: [],
    },
    intilityServicePlatform: {
      information: apiCompany.intilityServicePlatform,
      state: apiCompany.intilityServicePlatform?.state,
      intilityServiceList: [],
      globalIntilityServiceList: [],
    },
    mobilePlatform: {
      mobileDevices: apiCompany.mobilePlatform,
      state: apiCompany.mobilePlatform?.state,
    },
    microsoft365Platform:{
      information: apiCompany.microsoft365Platform,
      state: apiCompany.microsoft365Platform?.state,
    },
    computerSetupPlatform: {
      ...apiCompany.computerSetupPlatform,
      desktopSettings: {
        ...apiCompany.computerSetupPlatform
      }
    }
  };
};
