import { Input } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import '../locationEditDrawer.scss';

const comlineOptions = [
  { label: 'Internet access (layer 3)', value: 'Internet access' },
  { label: 'Point-to-point link (layer 2)', value: 'Point to point link' },
  { label: 'Dark fibre (dedicated fibre)', value: 'Dark fibre' },
  { label: 'Dedicated wavelength', value: 'Dedicated wavelength' }
];

export const ComlinesContent = ({ location, editLocation }) => {
  const [comlineConnection, setComlineConnection] = useState();

  useEffect(() => {
    setComlineConnection(comlineOptions.find(i => i.value === location?.comlinesConnection) || '');
  }, []);

  return (
    <div className='info-content'>
      <Select value={comlineConnection} options={comlineOptions} label='Comline type'
              onChange={(e) => {
                editLocation('comlinesConnection', e.value)
                setComlineConnection(e);
              }} />
      <Input label='Lease time/Contract term'
             onChange={(e) => editLocation('leaseTime', e.target.value)} value={location?.leaseTime}/>
      <Input value={location?.lineNumber} label='Primary line number/circuit ID' onChange={(e) => editLocation('lineNumber', e.target.value)} />
      <Input value={location?.secondaryLineNumber} label='Secondary line number/circuit ID' onChange={(e) => editLocation('secondaryLineNumber', e.target.value)} />
      <Input value={location?.isp} label='Internet service provider (ISP)' description='Current supplier of lines' onChange={(e) => editLocation('isp', e.target.value)} />
    </div>
  )
}

ComlinesContent.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
};