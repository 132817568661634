import { Badge, Button, Icon, StepBar, Tooltip } from "@intility/bifrost-react";
import './networkProgressBox.scss';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import CommentRow, { commentTypes } from "./CommentRow";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

export const ApplicationProgressBox = ({application}) => {
  const [visible, setVisible] = useState(false);

  const isColored = () => {
    let number = 0;
    if (application?.applicationStatus?.appInfoSent === 1) number = 1;
    if (application?.applicationStatus?.appInfoSent === 2) number = 2;
    if (application?.applicationStatus?.solutionDesignProgress === 1) number = 3;
    if (application?.applicationStatus?.solutionDesignProgress === 2) number = 4;
    if (application?.applicationStatus?.serverStaged === 1) number = 5;
    if (application?.applicationStatus?.serverStaged === 2) number = 6;
    if (application?.applicationStatus?.installation === 1) number = 7;
    if (application?.applicationStatus?.installation === 2) number = 8;
    if (application?.applicationStatus?.testing === 1) number = 9;
    if (application?.applicationStatus?.testing === 2) number = 10;
    return number;
  };

  const isComplete = () => {
    return (
      application?.applicationStatus?.appInfoSent === 2 &&
      application?.applicationStatus?.solutionDesignProgress === 2 &&
      application?.applicationStatus?.serverStaged === 2 &&
      application?.applicationStatus?.installation === 2 &&
      application?.applicationStatus?.testing === 2
    );
  };

  const getClass = (appValue, nextLimmit, itemValue) => {
    let classes = '';
    if (appValue > nextLimmit) classes += 'next-checked ';
    if (appValue === nextLimmit || appValue === nextLimmit - 1) classes += 'current-checked ';
    if (itemValue === 1) classes += 'current-half-checked ';
    if (classes === '') classes += 'prev-checked ';
    return classes;
  };

  const getLastClass = (appValue, lastLimmit, itemValue, complete) => {
    let classes = '';
    if (appValue > lastLimmit && !complete) classes += 'next-checked current-checked ';
    if (appValue > lastLimmit && complete) classes += 'next-checked ';
    if (itemValue === 1) classes += 'current-half-checked ';
    if (classes === '') classes += 'prev-checked ';
    return classes;
  };

  const copyUsers = () => {
    return application?.companyUsers?.filter(u => u?.isDisabled === false)
      ?.filter(c => c?.companyUser?.isEnabled === true)
      ?.map(u => u.companyUser?.primaryEmailAddress)?.toString()?.replaceAll(',', ';');
  }

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 2000);
    }
  }, [visible]);

  const applicationValue = isColored();
  const complete = isComplete();

  const sortedComments = application?.applicationStatus?.comments && [...application?.applicationStatus?.comments]?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <div className='network-progress-box'>
      <div className='copy-row'>
        <p>{application?.name}</p>
        <div>
          <Tooltip
            content='Copied!'
            visible={visible}
          >
            <Button small onClick={() => {
              navigator.clipboard.writeText(copyUsers())
              setVisible(true);
            }}>Copy ({application?.companyUsers?.filter(u => u?.isDisabled === false)?.filter(c => c?.companyUser?.isEnabled === true)?.length || '0'}) users</Button>
          </Tooltip>
          {application?.applicationStatus?.appInfoReady === true &&
            <Badge state='success' style={{marginLeft: 'var(--bfs8)'}}>
              <Icon icon={faCheck} marginRight />
              AppInfo ready
            </Badge>
          }
        </div>
      </div>
      <div className='box-stepbar'>
        {application?.applicationStatus?.onboardApp ? (
          <StepBar variant='circle'>
            <StepBar.Item
              active={application?.applicationStatus?.appInfoSent > 0}
              className={getClass(applicationValue, 2, application?.applicationStatus?.appInfoSent)}
            >
              {application?.applicationStatus?.appInfoSent === 1 ? 'Appinfo sent' : 'Appinfo received'}
            </StepBar.Item>
            <StepBar.Item
              active={application?.applicationStatus?.solutionDesignProgress > 0}
              className={getClass(applicationValue, 4, application?.applicationStatus?.solutionDesignProgress)}
            >
              {application?.applicationStatus?.solutionDesignProgress === 1
                ? 'Solution design (in progress)'
                : 'Solution design'}
            </StepBar.Item>
            <StepBar.Item
              active={application?.applicationStatus?.serverStaged > 0}
              className={getClass(applicationValue, 6, application?.applicationStatus?.serverStaged)}
            >
              {application?.applicationStatus?.serverStaged === 1 ? 'Server staged' : 'Vendor has access to server'}
            </StepBar.Item>
            <StepBar.Item
              active={application?.applicationStatus?.installation > 0}
              className={getClass(applicationValue, 8, application?.applicationStatus?.installation)}
            >
              {application?.applicationStatus?.installation === 1 ? 'Installation (in progress)' : 'Installation'}
            </StepBar.Item>
            <StepBar.Item
              active={application?.applicationStatus?.testing > 0}
              className={getLastClass(applicationValue, 8, application?.applicationStatus?.testing, complete)}
            >
              {application?.applicationStatus?.testing === 1 ? 'Testing (in progress)' : 'Testing'}
            </StepBar.Item>
            <StepBar.Item active={complete} className={complete ? 'current-checked last-element ' : 'prev-checked '}>
              Application ready
            </StepBar.Item>
          </StepBar>
        ) : (
          <p style={{margin: '0', color: 'var(--bfc-base-c-2)'}}>
            App is no longer relevant for the company and will not be transfered to Intility
          </p>
        )}
      </div>
      <div className='box-details' style={application?.applicationStatus?.comments?.length === 0 ? {padding: 0, borderTop: 0} : undefined}>
        <div className='app-details'>
          {sortedComments?.map(c => {
            return <CommentRow key={c.id} comment={c} notEditable={true} commentType={commentTypes.APPLICATION} />
          })}
        </div>
      </div>
    </div>
  );
};

ApplicationProgressBox.propTypes = {
  application: PropTypes.object
};

export default ApplicationProgressBox;
