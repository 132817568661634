import ApplicationFiltersDetails from '../../components/BackOffice/ApplicationFiltersDetails/ApplicationFiltersDetails';
import {faServer} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'ApplicationFilters',
  path: 'applicationFilters/',
  icon: faServer,
  editable: false,
  component: ApplicationFiltersDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
