import {editListItem} from '../../../utils/hub/normalize';

export const editBilling = (billingList, billingPlatform) => {
  const newList = editListItem(billingList, billingPlatform);

  return {
    billingPlatform: {
      billingList: newList
    }
  };
};

export const normalizeBilling = billingPlatform => {
  return {
    billingPlatform: {
      billingList: billingPlatform.billingList,
      information: billingPlatform,
      state: billingPlatform.state
    }
  };
};

export const normalizeBillingInformation = billingPlatform => {
  return {
    billingPlatform: {
      information: billingPlatform,
      state: billingPlatform.state
    }
  };
};

export const normalizeBillingList = billingPlatform => {
  return {
    billingPlatform: {
      billingList: billingPlatform.billingList
    }
  };
};
