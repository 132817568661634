import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Table,
  Tooltip,
  useFloatingMessage
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { faCheck, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { OnboardReadyStatusOptions } from "../ApplicationDetails/ApplicationDetails";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { countRowChecks } from "./EditApplications";
import produce from "immer";
import CommentRow, { commentTypes } from "../Components/CommentRow";
import { ApplicationComment } from "../../../utils/hub/models";

export const EditApplicationsTableRow = ({application, listCopy, setListCopy, itemsToEdit, setItemsToEdit, setOpenModal, setCurrentStatus, setEditComment, sharefileLink}) => {
  const [readyStatus, setReadyStatus] = useState({});
  const { showFloatingMessage } = useFloatingMessage();

  useEffect(() => {
    setReadyStatus(getReadyStatus(application.readyStatus))
  }, [application]);

  const editComment = (c) => {
    setOpenModal(true);
    setEditComment(c);
    setCurrentStatus(application?.applicationStatus);
  }

  const sortedComments = application?.applicationStatus?.comments && [...application?.applicationStatus?.comments]?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const getReadyStatus = (readyStatus) => {
    switch (readyStatus) {
      case 0:
        return {value: 0, label: 'Not Ready'}
      case 1:
        return {value: 1, label: 'Ready'}
      case 2:
        return {value: 2, label: sharefileLink.stringValue.length === 0 ?
            <Tooltip content='Missing sharefile link in workplace tab.' placement='top'>
              <Badge state={"warning"}>Need Files <Icon icon={faInfoCircle}/></Badge>
            </Tooltip> : 'Need Files'}
      case 3:
        return {value: 3, label: 'Files not available'}
      case 4:
        return {value: 4, label: 'Awaiting review'}
      case 5:
        return {value: 5, label: 'Packaging'}
    }
  }

  const onAppChange = (name, value, application, stage) => {
    let newValue;
    if (name === 'readyStatus') {
      newValue = produce(application, draft => {
        draft.readyStatus = value;
      });
    } else if (name === 'appInfoReady') {
      newValue = produce(application, draft => {
        draft.applicationStatus.appInfoReady = value;
      });
    } else {
      newValue = produce(application, draft => {
        draft.applicationStatus[name] = value ? stage : stage - 1;
      });
    }

    const index = listCopy.findIndex(obj => obj.id === application.id);
    setListCopy(
      produce(listCopy, draft => {
        draft[index] = {...newValue};
      })
    );

    const i = itemsToEdit.findIndex(obj => obj?.id === application.id);
    if (i > -1) {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    } else {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    }
  };

  const getRowValue = application => {
    const count = countRowChecks(application);
    return count < 10 ? `${count / 2}/5 steps completed` : 'Ready to go live';
  };

  return (
    <Table.Row key={application.id} className='edit-app-row' limitExpandClick content={
      <div style={{ padding: 'var(--bfs16) var(--bfs24)' }}>
        <Button variant={"outline"} state={!application?.applicationStatus ? "inactive" : "default"} onClick={() => {
          if (!application?.applicationStatus) {
            showFloatingMessage("Need to set and save a status before adding message on old applications.")
          } else {
            setOpenModal(true);
            setEditComment(new ApplicationComment())
            setCurrentStatus(application?.applicationStatus);
          }
        }}>Add comment</Button>
        {sortedComments?.map(c => {
          return <CommentRow key={c?.id} comment={c} editComment={editComment} commentType={commentTypes.APPLICATION}/>
        })}
      </div>
    }>
      <Table.Cell>{application.name}</Table.Cell>
      <Table.Cell className='from-large'>{sortedComments[0]?.comment || ''}</Table.Cell>
      <Table.Cell>
        <Checkbox
          className='hover-checkbox'
          label='Appinfo ready'
          checked={application?.applicationStatus?.appInfoReady === true}
          onChange={e => onAppChange('appInfoReady', e.target.checked, application, e.target.checked)}
        />
      </Table.Cell>
      <Table.Cell>
        <Select
          label='Onboard status'
          hideLabel
          options={OnboardReadyStatusOptions}
          value={readyStatus}
          onChange={e => {
            setReadyStatus(getReadyStatus(e.value))
            onAppChange('readyStatus', e.value, application)
          }}
        />
      </Table.Cell>
      <Table.Cell className='backoffice-weird-input'>
        <Dropdown
          arrow={false}
          popperOptions={{
            modifiers: [
              {
                name: 'sameWidth',
                enabled: true,
                fn: ({state}) => {
                  state.styles.popper.width = `${state.rects.reference.width}px`;
                },
                phase: 'beforeWrite',
                requires: ['computeStyles'],
                effect: ({state}) => {
                  state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                }
              }
            ]
          }}
          noPadding
          content={
            <>
              <Checkbox
                className='hover-checkbox'
                label='Appinfo sent'
                checked={application?.applicationStatus?.appInfoSent > 0}
                onChange={e => onAppChange('appInfoSent', e.target.checked, application, 1)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Appinfo received'
                checked={application?.applicationStatus?.appInfoSent > 1}
                onChange={e => onAppChange('appInfoSent', e.target.checked, application, 2)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Solution design (in progress)'
                checked={application?.applicationStatus?.solutionDesignProgress > 0}
                onChange={e => onAppChange('solutionDesignProgress', e.target.checked, application, 1)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Solution design (finished)'
                checked={application?.applicationStatus?.solutionDesignProgress > 1}
                onChange={e => onAppChange('solutionDesignProgress', e.target.checked, application, 2)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Server staged'
                checked={application?.applicationStatus?.serverStaged > 0}
                onChange={e => onAppChange('serverStaged', e.target.checked, application, 1)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Vendor has access to server'
                checked={application?.applicationStatus?.serverStaged > 1}
                onChange={e => onAppChange('serverStaged', e.target.checked, application, 2)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Installation  (in progress)'
                checked={application?.applicationStatus?.installation > 0}
                onChange={e => onAppChange('installation', e.target.checked, application, 1)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Installation (finished)'
                checked={application?.applicationStatus?.installation > 1}
                onChange={e => onAppChange('installation', e.target.checked, application, 2)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Testing (in progress)'
                checked={application?.applicationStatus?.testing > 0}
                onChange={e => onAppChange('testing', e.target.checked, application, 1)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Testing (finished)'
                checked={application?.applicationStatus?.testing > 1}
                onChange={e => onAppChange('testing', e.target.checked, application, 2)}
              />
            </>
          }
        >
          <Input
            {...(countRowChecks(application) === 10 && {
              icon: faCheck
            })}
            readOnly
            value={getRowValue(application)}
            label={'test'}
            hideLabel={true}
          />
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )

}

EditApplicationsTableRow.propTypes = {
  application: PropTypes.object,
  listCopy: PropTypes.array,
  setListCopy: PropTypes.func,
  itemsToEdit: PropTypes.array,
  setItemsToEdit: PropTypes.func,
  savedComment: PropTypes.bool,
  setSavedComment: PropTypes.func,
  sharefileLink: PropTypes.object
}

export default EditApplicationsTableRow

