import EditServiceSettings from '../../components/BackOffice/EditComponents/EditServiceSettings';
import {faPuzzlePiece} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Service settings',
  path: 'services/edit',
  icon: faPuzzlePiece,
  editable: true,
  component: EditServiceSettings,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Service settings';
  }
};
