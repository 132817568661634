import React from 'react';
import {Card} from '@intility/bifrost-react';
import PropTypes from 'prop-types';
import {Contact} from '../../../../../utils/hub/models';
import './contactInformation.scss';
import CheckUserDeleted from "../../../../../utils/hub/checkUserDeleted";

const ContactInformation = ({contact, title}) => {
  const contactName =
    contact?.firstName || contact?.lastName ? `${contact?.firstName || ''} ${contact?.lastName || ''}` : null;

  return (
    <Card id='contact-information' align='center'>
      <Card.Content>
        <div className='contact-container-text'>
          <h5>{title}</h5>
          <p style={contactName ? {color: 'var(--bfc-base-c-1)'} : {color: 'var(--bfc-base-c-2)'}}>
            {contactName || 'Not registered'} <CheckUserDeleted userName={contact?.firstName + contact?.lastName} email={contact?.email} phoneNumber={contact?.phoneNumber}/>
          </p>
          {contact?.email ? (
            <a className='bf-link' href={`mailto:${contact?.email}`}>
              {contact?.email}
            </a>
          ) : (
            <p style={{color: 'var(--bfc-base-c-2)'}}>
              Not registered
            </p>
          )}
          <p
            style={contact?.phoneNumber ? {color: 'var(--bfc-base-c-1)'} : {color: 'var(--bfc-base-c-2)'}}
          >
            {contact?.phoneNumber || 'Not registered'}
          </p>
        </div>
      </Card.Content>
    </Card>
  );
};

ContactInformation.propTypes = {
  contact: PropTypes.object,
  title: PropTypes.string
};

export default ContactInformation;
