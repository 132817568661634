import React from 'react';
import {Badge, Button, Icon} from '@intility/bifrost-react';
import {faPencil} from '@fortawesome/pro-regular-svg-icons';
import {GetVendorRole, GetInternalRole} from '../NewApplication/NewContent/NewContact';

const Contact = ({badgeList, firstName, lastName, phoneNumber, email, onEdit, isInternal}) => {
  return (
    <div className='added-contact'>
      <div className='contact-header'>
        <div>
          <div style={{color: 'var(--bfc-theme-3)', fontSize: '16px', fontWeight: '600'}}>
          {(firstName || '') + ' ' + (lastName || '')}
          </div>
          <div style={{color: 'var(--bfc-base-c-1)', fontSize: 'var(--bf-font-size-s)', fontWeight: '400'}}>
            {phoneNumber} • {email}
          </div>
        </div>
        <Button pill noPadding onClick={onEdit}>
          <Icon icon={faPencil} />
        </Button>
      </div>
      <div style={{marginTop: '12px'}}>
        {badgeList.map((badge, index) => (
          <Badge key={index} pill className='contact-role-badge'>
            {isInternal ? GetInternalRole(badge.contactRole) : GetVendorRole(badge.contactRole)}
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default Contact;
