import React from 'react';
import './computerRegistrationBox.scss';
import {Icon} from '@intility/bifrost-react';
import PropTypes from 'prop-types';

const ComputerRegistrationBox = ({step, icon, header, children, ...props}) => {
  return (
    <div className='computer-reg-box-item'>
      <div className='computer-reg-box-item-logo' style={{backgroundColor: 'var(--bfc-chill)'}}>
        <h3>
          {step}
        </h3>
      </div>
      <div className='computer-reg-box-item-body'>
        <span>
          <Icon icon={icon} />
          <h5>{header}</h5>
        </span>
        {children}
      </div>
    </div>
  );
};

ComputerRegistrationBox.propTypes = {
  step: PropTypes.number,
  icon: PropTypes.object,
  header: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string])
};

export default ComputerRegistrationBox;
