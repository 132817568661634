import overviewData from './overviewData';
import taskData from './taskData';
import contactData from './contactData';
import usersData from './usersData';
import billingData from './billingData';
import domainsData from './domainsData';
import locationsData, {Location} from './locationsData';
import microsoft365Data from './microsoft365Data';
import serverApplicationsData, {Application} from './applicationsData';
import computerData, {CustomData} from './hardwareData';
import communicationData from './communicationData';
import printersData from './printersData';
import commentData from './commentData';
import mobileDevicesData, {AppsData, ConditionalData} from './mobileDevicesData';
import mobileArticles from './mobileArticles';
import overviewArticles from './overviewArticles';
import intilityServicesData from './intilityServicesData';
import intilityServicesArticles from './intilityServicesArticles';
import contactUsData from './contactUsData';

export default [
  overviewData,
  taskData,
  contactData,
  usersData,
  billingData,
  domainsData,
  locationsData,
  Location,
  microsoft365Data,
  serverApplicationsData,
  Application,
  communicationData,
  computerData,
  CustomData,
  commentData,
  intilityServicesData,
  intilityServicesArticles,
  mobileDevicesData,
  AppsData,
  ConditionalData,
  mobileArticles,
  contactUsData,
  printersData,
  overviewArticles
];
