import { Button, Icon, Modal, Pagination, Table, TextArea } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { faUndo } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import './editComponent.scss';
import { useDispatch, useSelector } from "react-redux";
import {
  addDomainCommentAsync, editDomainCommentAsync,
  fetchDomainsAsync,
  multiPutDomainStatusAsync
} from "../../../redux/company/domain/domainThunks";
import EditDomainsTableRow from "./EditDomainsTableRow";
import { DomainComment } from "../../../utils/hub/models";
import produce from "immer";

export const countRowChecks = domain => {
  if (!domain?.domainStatus) return 0;
  let checked = 0;
  if (domain?.domainStatus?.establishedDialogue > 0) {
    checked += 1;
  }
  checked += domain?.domainStatus?.scheduledTransfer;
  return checked;
};

const EditDomains = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {domainList} = useSelector(state => state.company.data.domainPlatform);
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [listCopy, setListCopy] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [newComment, setNewComment] = useState('');
  const [editComment, setEditComment] = useState(new DomainComment());
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchDomainsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    setListCopy(domainList);
  }, [domainList]);

  useEffect(() => {
    setNewComment(editComment?.comment);
  }, [editComment]);

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutDomainStatusAsync(itemsToEdit));
      setItemsToEdit([]);
    }
  };

  const saveComment = () => {
    if (editComment.id) {
      const comment = produce(editComment, draft => {
        draft.comment = newComment;
      });
      dispatch(editDomainCommentAsync(comment))
    } else {
      const comment = produce(new DomainComment(), draft => {
        draft.comment = newComment;
        draft.domainStatusId = currentStatus?.id;
      });
      dispatch(addDomainCommentAsync(comment, currentStatus?.id))
    }
    setNewComment('');
    setEditComment(new DomainComment());
    setCurrentStatus(null);
    setOpenModal(false);
  }

  const resetValues = () => {
    setItemsToEdit([]);
    setListCopy(domainList);
  };

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const sortedData = [...listCopy]
    .filter(a => a.isEnabled === true)
    .sort((a, b) => {
      if (sort.key === 'status' && sort.direction === 'asc') {
        return countRowChecks(a) - countRowChecks(b);
      } else if (sort.key === 'status' && sort.direction === 'desc') {
        return countRowChecks(b) - countRowChecks(a);
      } else if (sort.direction === 'asc') {
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    })

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: domainList?.length, label: 'All'});
    return tableOptions;
  };

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Domains</h3>
        <div className='backoffice-button-group'>
          {(itemsToEdit.length > 0) && (
            <span onClick={() => resetValues()} className='icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button
            variant={'filled'}
            disabled={itemsToEdit.length === 0}
            onClick={() => saveDetails()}
          >
            Save
          </Button>
        </div>
      </div>

      <div className='backoffice-editable-table'>
        <Table>
          <colgroup>
            <col className='from-large' style={{width: '3%'}} />
            <col className='from-large' style={{width: '77%'}} />
            <col className='from-large' style={{width: '20%'}} />
          </colgroup>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
                Domains
              </Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('status')} onClick={onSortChangeCreator('status')}>
                Status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedAndIndexedData.map((domain, i) =>
              <EditDomainsTableRow
                key={i}
                domain={domain}
                listCopy={listCopy}
                setListCopy={setListCopy}
                itemsToEdit={itemsToEdit}
                setItemsToEdit={setItemsToEdit}
                setOpenModal={setOpenModal}
                setCurrentStatus={setCurrentStatus}
                setEditComment={setEditComment}
              />
            )}
          </Table.Body>
        </Table>
      </div>

      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
      <Modal isOpen={openModal} onRequestClose={() => setOpenModal(false)} header={editComment?.id ? "Edit comment" : "Add comment"}>
        <TextArea label='Comment' value={newComment} onChange={e => setNewComment(e.target.value)} rows={6}/>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 'var(--bfs12)', minWidth: '400px'}}>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button variant={"filled"} onClick={() => saveComment()}>{editComment?.id ? 'Save' : 'Add'}</Button>
        </div>
      </Modal>
    </div>
  )

}

export default EditDomains