import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { GlobalTeamRole, MemberRole, GlobalTeamObject} from "../../../utils/hub/models";
import useIntilityAdminPermission from "../../../hooks/useIntilityAdminPermission";
import {
  Button,
  Checkbox,
  Drawer,
  FieldGroup, Grid,
  Icon,
  Input,
  Label, Message, Modal,
  useBreakpoint
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import {
  addGlobalRoleAsync, addProjectGlobalTeamAsync, deleteProjectGlobalTeamAsync,
  fetchGlobalRolesAsync,
  fetchProjectGlobalTeamsAsync, updateProjectGlobalTeamAsync
} from "../../../redux/company/projectTeams/projectGlobalTeamsThunks";
import { faPlus, faStar, faTimes } from "@fortawesome/pro-regular-svg-icons";
import produce from "immer";
import { GlobalTeam } from "../../BackOffice/ProjectTeam/Team";
import './projectTeamSettings.scss';

export const ProjectTeamSettings = ({}) => {
  const {list, roles} = useSelector(state => state.company.data.projectGlobalTeams);
  const [listCopy, setListCopy] = useState([]);
  const {user} = useSelector(state => state.auth);
  const [options, setOptions] = useState([{value: 1, label: 'Add role'}]);
  const [team, setTeam] = useState(new GlobalTeamObject());
  const [teamId, setTeamId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addingRole, setAddingRole] = useState(false);
  const [newRole, setNewRole] = useState(new MemberRole());
  const canEdit = useIntilityAdminPermission();
  const dispatch = useDispatch();
  const toMedium = useBreakpoint(null, 'medium');
  const fromMediumToXl = useBreakpoint('medium', 'xl');

  useEffect(() => {
    setListCopy(list);
  }, [list]);

  useEffect(() => {
    if (!user) return;
    dispatch(fetchProjectGlobalTeamsAsync());
    dispatch(fetchGlobalRolesAsync());
  }, [user, dispatch]);

  useEffect(() => {
    const roleOptions = roles?.map(i => ({label: i.name, value: i}));
    roleOptions.splice(roleOptions.length, 0, {
      value: '201',
      label: (
        <span style={{color: 'var(--bfc-base-c-theme)'}}>
          <Icon icon={faPlus} /> Add new role
        </span>
      )
    });
    setOptions(roleOptions);
  }, [roles]);

  const canEditAdmin = isGlobal => {
    if (isGlobal) {
      return canEdit;
    } else return true;
  };

  const openDrawer = (team, teamId) => {
    setTeam(team);
    setTeamId(teamId);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setTeam(new GlobalTeamObject());
    setTeamId(null);
    setDrawerOpen(false);
  };

  const onChange = (name, value, setState) => {
    if (name === 'isGlobal' && value === false) {
      const result = confirm(
        'Are you sure you wish to remove global state, the resource will no longer be available to companies other than the one in which it was created.'
      );
      if (result) {
        setState(
          produce(draft => {
            draft[name] = value;
          })
        );
      }
    } else {
      setState(
        produce(draft => {
          draft[name] = value;
        })
      );
    }
  };

  const onRoleChange = (value, number) => {
    setTeam(
      produce(team, draft => {
        draft.teamRoles[number].name = value.name;
      })
    );
  };


  const addRole = () => {
    const newValue = produce(team, draft => {
      draft.teamRoles.push(new GlobalTeamRole());
    });
    setTeam(newValue);
  };

  const addRoleOption = role => {
    const newOpt = produce(new MemberRole(), draft => {
      draft.name = role.name;
    });
    dispatch(addGlobalRoleAsync(newOpt));
    resetRoleModal();
  };

  const resetRoleModal = () => {
    setAddingRole(false);
    setNewRole(new MemberRole());
  };

  const removeRole = role => {
    const result = confirm(
      'Are you sure you wish to remove this role from the global team? It will be removed from all projects.'
    );
    if (result) {
      const i = team.teamRoles.findIndex(
        r => r.id === role.id && r.name === role.name
      );
      const newValue = produce(team, draft => {
        draft.teamRoles.splice(i, 1);
      });
      setTeam(newValue);
    }
  };

  const saveProjectTeam = (teamId) => {
    if (teamId) {
      dispatch(updateProjectGlobalTeamAsync(team));
    } else {
      dispatch(addProjectGlobalTeamAsync(team));
    }
    closeDrawer();
  };

  const deleteTeam = (teamId) => {
    const result = confirm('Are you sure you wish to delete global team? This will have no effect on current companies.');
    if (result) {
      dispatch(deleteProjectGlobalTeamAsync(teamId));
      setDrawerOpen(false);
    }
  };

  const chunk = list.length / (toMedium ? 1 : fromMediumToXl ? 2 : 4);
  const rest = list.length % (toMedium ? 1 : 2);

  const getRows = () => {
    let i, j, temporary;
    const test = [];
    for (i = 0, j = listCopy.length; i < j; i += chunk) {
      if (i === 0 && rest > 0) {
        temporary = list.slice(i, ++i + chunk);
      } else {
        temporary = list.slice(i, i + chunk);
      }
      test.push(
        <Grid.Span key={i} medium={1} large={1} xl={1} xxl={1}>
          {temporary.map((l, i) => {
            return (
              <GlobalTeam
                key={i}
                team={l}
                openDrawer={openDrawer}
              />
            );
          })}
        </Grid.Span>
      );
    }
    return test;
  };

  return (
    <div id='global-team-settings' className='global-settings-page bfl-page-padding'>
      <Message header='Global teams available for all companies' state={"warning"}>
        Editing, adding or deleting teams on this page will affect all <u>new</u> companies. (Needs Onboard admin to edit) <br/><Icon icon={faStar}/> = Team manager
      </Message>
      <div className='global-filter-row'>
          <h3>Project team</h3>
          <Button variant='outline' onClick={() => openDrawer(new GlobalTeamObject(), null)}>
            Add global team
          </Button>
      </div>
      <Grid cols={6} medium={4} large={5} xl={6} xxl={6} gap={12}>
        {getRows().map(i => i)}
      </Grid>
      <Drawer
        header={teamId ? 'Edit global team' : 'Add global team'}
        isOpen={drawerOpen}
        overlay
        disableFocusTrap={true}
        onRequestClose={() => closeDrawer()}
        className='backoffice-editable-container-drawer'
        footer={
          <div className='team-drawer-footer'>
            {teamId && (
              <Button
                variant={'outline'}
                disabled={!canEditAdmin(true)}
                onClick={() => deleteTeam(team.id)}
                state={'alert'}
              >
                Delete team
              </Button>
            )}
            <Button onClick={() => setDrawerOpen(false)}>Cancel</Button>
            <Button variant={'filled'} onClick={() => saveProjectTeam(teamId)}>
              {teamId ? 'Save' : 'Add'}
            </Button>
          </div>
        }
      >
        <Message state={"warning"} header='Global team' style={{marginBottom: 'var(--bfs12)'}}>
          Team will be available in all new projects. All team roles will be standard for this team.
        </Message>
        <Input label='Team name' value={team?.name || ''} onChange={e => onChange('name', e.target.value, setTeam)} />
        <div className='input-group'>
          <Label>Team members</Label>
          <p>Selected roles will be standard roles for all company projects</p>
          {team.teamRoles.map((m, i) => {
            return (
              <div className='input-group-role-row' key={i}>
                <FieldGroup>
                  <Select
                    label='role'
                    hideLabel
                    isDisabled={!canEditAdmin(true)}
                    placeholder='Role'
                    value={m?.name ? {label: m?.name, value: m} : null}
                    onChange={item => {
                      if (item.value === '201') {
                        setAddingRole(true);
                        onChange('isGlobal', true, setNewRole);
                      } else {
                        onRoleChange(item.value, i);
                      }
                    }}
                    options={options}
                  />
                  <Button icon={faTimes} onClick={() => removeRole(m)} />
                </FieldGroup>
              </div>
            );
          })}
          <div className='input-group-footer'>
            <Button icon={faPlus} style={{flex: '1'}} onClick={() => addRole()} disabled={!canEditAdmin(true)}>
              Add member slot
            </Button>
          </div>
        </div>
        <Modal isOpen={addingRole} header='Add new role' onRequestClose={() => resetRoleModal()}>
          <div>
            <Checkbox
              label='Set role as global'
              disabled={true}
              checked={true}
              onChange={() => console.log('no')}
            />
            <p className='bf-small bf-em'>
              - A global role can be used in all teams and will be available in all projects.{' '}
            </p>
            <Input
              label='Role name'
              value={newRole?.name || ''}
              onChange={e => onChange('name', e.target.value, setNewRole)}
            />
          </div>
          <div style={{display: 'flex', marginTop: 'var(--bfs24)'}}>
            <Button style={{flex: '1', marginRight: 'var(--bfs12)'}} onClick={() => resetRoleModal()}>
              Cancel
            </Button>
            <Button style={{flex: '1'}} variant={'filled'} onClick={() => addRoleOption(newRole)}>
              Add new role
            </Button>
          </div>
        </Modal>
      </Drawer>
    </div>
  );
};

export default ProjectTeamSettings
