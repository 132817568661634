import {editPermissionPropTypes} from './permissionPropTypes';
import useWizardPermission from '../../hooks/useWizardPermission';

const WizardPermission = ({children}) => {
  const hasEditPermission = useWizardPermission();

  if (!hasEditPermission) return null;

  return children;
};

WizardPermission.propTypes = editPermissionPropTypes;

export default WizardPermission;
