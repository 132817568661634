import React from "react";
import { TextArea } from "@intility/bifrost-react";
import PropTypes from "prop-types";

export const CommentContent = ({ location, editLocation }) => {

  return (
    <div className='info-content'>
      <TextArea value={location?.comment || ''}
                label='Comment'
                onChange={(e) => editLocation('comment', e.target.value)} />
    </div>
  )
}

CommentContent.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
};