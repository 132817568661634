import PropTypes from 'prop-types';

const fileUploaderPropTypes = {
  onChange: PropTypes.func,
  files: PropTypes.array,
  name: PropTypes.string,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  small: PropTypes.bool,
  buttonText: PropTypes.string
};

export const fileUploaderFilePropTypes = {
  data: PropTypes.object,
  onDelete: PropTypes.func
};

export default fileUploaderPropTypes;
