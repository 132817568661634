import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Location } from "../../../../../../utils/hub/models";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocationsAsync } from "../../../../../../redux/company/locations/locationThunks";
import BasicPage from "../../../BasicPage";
import "./locationInformation.scss";
import GeneralInfoCard from "./sidebar/GeneralInfoCard";
import NetworkCard from "./sidebar/NetworkCard";
import FloorplanCard from "./sidebar/FloorplanCard";
import { Accordion, BackButton, Badge, Button, Icon, ProgressBar, useFloatingMessage } from "@intility/bifrost-react";
import LocationEditDrawer from "../LocationEditDrawer/LocationEditDrawer";
import {
  faArrowRightArrowLeft, faBell,
  faBolt,
  faBox, faCamera,
  faCameraCctv,
  faFirewall,
  faKey,
  faLightbulb,
  faOutlet,
  faPencil, faPhone,
  faPhoneOffice,
  faProjector,
  faRouter, faScreencast,
  faServer, faSignal,
  faTablet,
  faTemperature0,
  faTv,
  faWifi
} from "@fortawesome/pro-regular-svg-icons";
import { fetchLocationProgress } from "../../Locations";
import DeliveryAccordionContent from "./accordion/DeliveryAccordionContent";
import ComlinesAccordionContent from "./accordion/ComlinesAccordionContent";
import CommentAccordionContent from "./accordion/CommentAccordionContent";
import LocationSystemAccordion from "./LocationSystems/LocationSystemAccordion";
import LocationSystemAccordionItem from "./LocationSystems/LocationSystemAccordionItem";
import { fetchUsersAsync } from "../../../../../../redux/company/users/userThunks";
import MeetingRoomAccordion from "./accordion/MeetingRoomAccordion";

export const DrawerType = {
  INFO: 'info',
  HEADER: 'header',
  WIFI: 'wifi',
  FLOORPLANS: 'floorplans',
  DELIVERY: 'delivery',
  COMLINES: 'comlines',
  NETWORK: 'network',
  MEETINGROOM: 'meetingroom',
  COMMENT: 'comment',
  USERS: 'users',
  NETWORKSYSTEMS: 'networkunits',
  PROPTECHSYSTEMS: 'proptechunits',
};

export const NetworkSystemType = [
  { name: "Network rack", type: 0, icon: faServer},
  { name: 'Router', type: 0, icon: faRouter},
  { name: 'Switch', type: 0, icon: faArrowRightArrowLeft},
  { name: 'UPS (Uninterruptible Power Supply)', type: 0, icon: faOutlet},
  { name: 'Firewall', type: 0, icon: faFirewall},
  { name: 'Wireless access point', type: 0, icon: faWifi},
  { name: 'PoE injector', type: 0, icon: faBolt},
];

export const PropTechSystemType = [
  { name: 'Access control', type: 3, icon: faKey},
  { name: 'Surveillance system', type: 3, icon: faCameraCctv},
  { name: 'Alarm system', type: 3, icon: faBell},
  { name: 'Light control', type: 3, icon: faLightbulb},
  { name: 'Climate control', type: 3, icon: faTemperature0},
  { name: 'IP phones', type: 3, icon: faPhoneOffice},
  { name: 'IP phone central/server', type: 3, icon: faPhoneOffice},
  { name: 'IPTV', type: 3, icon: faTv},
];

export const DocumentationType = {
  NETWORK: 0,
  PRINTERS: 1,
  PROPTECH: 3,
}

const LocationInformation = () => {
  const {information, locationList} = useSelector(state => state.company.data.locationPlatform);
  const {locationId} = useParams();
  const [location, setLocation] = useState(new Location());
  const [baseLocation, setBaseLocation] = useState(new Location());
  const {id} = useSelector(state => state.company.data.info);
  const [edited, setEdited] = useState(false);
  const {user} = useSelector(state => state.auth);
  const [activeAccordion, setActiveAccordion] = useState([])
  const [drawerPage, setDrawerPage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const { showFloatingMessage } = useFloatingMessage();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  useEffect(() => {
    let isCancelled = false;
    if (location) {
      const progress = fetchLocationProgress(location);
      if (!isCancelled) {
        setProgress(progress)
      }
      return () => {
        isCancelled = true;
      };
    }
  }, [location])

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
    dispatch(fetchUsersAsync(id));
  }, [user, dispatch, id]);


  useEffect(() => {
    if (locationId !== undefined && locationId !== null && locationId !== false) {
      const newLocation = locationList?.find(loc => loc?.id === locationId);
      if (newLocation !== undefined && edited === false) {
        setLocation(newLocation);
        setBaseLocation({...newLocation});
      }
    } else {
      setLocation(new Location());
    }
  }, [locationId, locationList]);

  const handleChange = key => {
    if (!activeAccordion.includes(key)) {
      setActiveAccordion([...activeAccordion, key])
    } else {
      setActiveAccordion(activeAccordion.filter(pa => pa !== key))
    }
  }

  const editLocation = (page) => {
    if (isComplete === true) {
      showFloatingMessage("Locations main page set to completed, uncheck to edit.", {state: 'warning'})
    } else {
      setDrawerPage(page)
    }
  }

  return (
    <BasicPage id='location-info'>
      <div className='content-main'>
        <BackButton style={{marginBottom: 'var(--bfs8)'}} onClick={() => history.push(`/${id}/locations`)} text='Locations'/>
        <div className='location-details'>
          <div className='location-info-sidebar'>
            <GeneralInfoCard location={location} isComplete={isComplete} editLocation={() => editLocation(DrawerType.INFO)} editUsers={() => editLocation(DrawerType.USERS)}/>
            <NetworkCard location={location} isComplete={isComplete} editLocation={() => editLocation(DrawerType.NETWORK)}/>
            <FloorplanCard location={location} isComplete={isComplete} editLocation={() => editLocation(DrawerType.FLOORPLANS)}/>
          </div>
          <div className='location-info-content'>
            <div className='location-info-header'>
              <h2>
                {baseLocation.name}
              </h2>
              <div>
                {location?.locationTypes?.split(',')?.map(l =>
                  <Badge key={l}>{l}</Badge>
                )}
                <Button pill noPadding small state={isComplete ? 'inactive' : 'default'} onClick={() => editLocation(DrawerType.HEADER)}><Icon icon={faPencil}/></Button>
              </div>
            </div>
            <ProgressBar value={progress} hideLabel/>
            <div className='info-progress-text'>
              <p className='bf-small bf-strong'>{progress}%</p>
              <p className='bf-small'>of location information complete</p>
            </div>
            <Accordion className='system-accordion'>
              <DeliveryAccordionContent location={location} isDisabled={isComplete} activeAccordion={activeAccordion} handleChange={handleChange} editLocation={() => editLocation(DrawerType.DELIVERY)}/>
              <ComlinesAccordionContent location={location} isDisabled={isComplete} activeAccordion={activeAccordion} handleChange={handleChange} editLocation={() => editLocation(DrawerType.COMLINES)}/>
              <LocationSystemAccordion location={location} editLocation={() => editLocation(DrawerType.NETWORKSYSTEMS)} isDisabled={isComplete} activeAccordion={activeAccordion} accordionName='Network infrastructure' handleChange={handleChange} type={DocumentationType.NETWORK}>
                {NetworkSystemType.map(t => {
                  return <LocationSystemAccordionItem key={t.name} location={location} title={t.name} icon={t.icon} />;
                })}
                {location?.locationSystemItems?.filter(i => (i.itemType === 1 && i.systemType === DocumentationType.NETWORK))?.map(t => {
                  return <LocationSystemAccordionItem key={t.name} location={location} title={t.name} icon={faBox} />;
                })}
              </LocationSystemAccordion>
              <MeetingRoomAccordion location={location} isDisabled={isComplete} activeAccordion={activeAccordion} accordionName={`Meeting rooms & Audio Visual units (${location?.meetingRooms?.length || 0})`} handleChange={handleChange} editLocation={() => editLocation(DrawerType.MEETINGROOM)}/>
              <LocationSystemAccordion location={location} editLocation={() => editLocation(DrawerType.PROPTECHSYSTEMS)} isDisabled={isComplete} activeAccordion={activeAccordion} accordionName='Technical systems' accordionTitle={<span>Property technical systems <span className='bf-em'>(connected to the network)</span></span>} handleChange={handleChange} type={DocumentationType.PROPTECH}>
                {PropTechSystemType.map(t => {
                  return <LocationSystemAccordionItem key={t.name} location={location} title={t.name} icon={t.icon} />;
                })}
                {location?.locationSystemItems?.filter(i => (i.itemType === 1 && i.systemType === DocumentationType.PROPTECH))?.map(t => {
                  return <LocationSystemAccordionItem key={t.name} location={location} title={t.name} icon={faBox} />;
                })}
              </LocationSystemAccordion>
              <CommentAccordionContent location={location} isDisabled={isComplete} activeAccordion={activeAccordion} handleChange={handleChange} editLocation={() => editLocation(DrawerType.COMMENT)}/>
            </Accordion>
          </div>
        </div>
        <LocationEditDrawer location={location} onRequestClose={() => setDrawerPage(null)} page={drawerPage} isDisabled={isComplete}/>
      </div>
    </BasicPage>
  )

}

export default LocationInformation