import apiFetch from './apiFetch';

export const fetchCustomWallpaper = async desktopSettings => {
  const {customWallpaper, companyId} = desktopSettings;
  if (!customWallpaper) return;

  const blob = await apiFetch(`/computerSetup/${companyId}/wallpaper`).then(res => res.blob());

  const image = URL.createObjectURL(blob);

  return {
    imageUri: image,
    isCustom: true
  };
};

export const fetchLocationImage = async location => {
  const {locationImage, companyId, id} = location;
  if (!locationImage) return;

  const blob = await apiFetch(`/companies/${companyId}/locations/${id}/image`).then(res => res.blob());

  const image = URL.createObjectURL(blob);

  return {
    imageUri: image,
    isCustom: true
  };
};

export const checkWallpaperProperties = (wallpaper, setErrors) => {
  setErrors([]); // State reset

  const {imageUri} = wallpaper;
  const image = new Image();
  image.src = imageUri;

  image.onload = () => {
    const {width, height} = image;

    if (width < 1920) {
      setErrors(state => [
        ...state,
        "Image width is less than 1920 pixels. This will result in the image not streching across an HD display's full width."
      ]);
    }

    if (height < 1080) {
      setErrors(state => [
        ...state,
        "Image height is less than 1080 pixels. This may result in the image not streching across an HD display's full height."
      ]);
    }
  };
};
