import {createAsyncThunk, createReducer, createSlice} from '@reduxjs/toolkit';
import {apiFetch, apiFetchIdCollection} from 'utils/apiFetch';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess} from '../companyUpdateSlice';

const getSelectOptions = isRecommended => {
  return [
    {
      label: 'Yes',
      value: true,
      isRecommended: isRecommended === 'YES'
    },
    {
      label: 'No',
      value: false,
      isRecommended: isRecommended === 'NO'
    }
  ];
};

export const conditionalAccessStore = [
  {
    conditionalName: 'requireDeviceCompliance',
    viewName: 'Require Device Compliance | All Microsoft Cloud Apps',
    selectOptions: getSelectOptions('YES'),
    description:
      'Device must be enrolled and compliant with the set device requirements to access company apps and data  '
  },

  {
    conditionalName: 'requireManagedBrowserAllApps',
    viewName: 'Require Managed Browser All Apps',
    selectOptions: getSelectOptions('NO'),
    description:
      'Require the use of approved browser (Microsoft Edge) to reach company resources (e.g Office 365) via Web-browser. '
  }
];

export const getMobileDevicesData = createAsyncThunk('mobileDevices/getMobileDevicesData', async companyID => {
  const response = await apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyID}/mobile`,
    method: 'GET'
  });

  return {...response};
});

// Compliance Policies

export const toggleMobileDevices = createAsyncThunk('mobileDevices/toggleEnabled', async (_, {getState, dispatch}) => {
  const {id} = getState().company.data.info;
  const response = await apiFetch(`/companies/${id}/mobile/toggle`, {
    method: 'PATCH'
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const result = await response.json();

  dispatch(putAppsIsEnabledSuccess(result));
  return result;
});

export const updateCompliancePolicy = createAsyncThunk(
  'mobileDevices/updateCompliancePolicy',
  async (payload, {getState}) => {
    const {policyType, values} = payload;

    const policy = getState().company.data.mobilePlatform.mobileDevices[policyType];

    const {id} = getState().company.data.info;
    const url = `/companies/${id}/mobile/${policyType}`;

    const response = await apiFetch(url, {
      method: 'PUT',
      body: JSON.stringify({...policy, ...values}),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const updatedPolicy = await response.json();

    return {policyType, data: updatedPolicy};
  }
);

export const fetchCompliancePolicies = createAsyncThunk(
  'mobileDevices/fetchCompliancePolicies',
  async (companyId, {dispatch}) => {
    const response = await apiFetch(`/companies/${companyId}/mobile`);

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const policies = await response.json();
    dispatch(fetchCompliancePoliciesSuccess(policies));
  }
);

// Conditional Access

export const fetchConditionalAccess = createAsyncThunk(
  'mobileDevices/fetchConditionalAccess',
  async (companyId, {dispatch}) => {
    const response = await apiFetch(`/companies/${companyId}/mobile`);

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const access = await response.json();
    dispatch(fetchConditionalAccessSuccess(access));
  }
);

export const updateConditionalAccess = createAsyncThunk(
  'mobileDevices/updateConditionalAccess',
  async (payload, {getState}) => {
    const {id} = getState().company.data.info;
    const url = `/companies/${id}/mobile/conditionalAccessPolicies`;

    const response = await apiFetch(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const updatedConditional = await response.json();

    return updatedConditional;
  }
);

// Business Application

export const updateBusinessApplication = createAsyncThunk(
  'mobileDevices/updateBusinessApplication',
  async (payload, {getState}) => {
    const {id} = getState().company.data.info;

    const url = `/companies/${id}/mobile/businessApplications`;
    const response = await apiFetch(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return await response.json();
  }
);


const updateOnMobileStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const mobileStateReducer = createReducer(
  0,
  updateOnMobileStateReducer((payload, state) => {
    state = payload.mobilePlatform.state;
    return state;
  })
);

const mobileDevicesSlice = createSlice({
  name: 'mobileDevices',
  initialState: {
    android: null,
    apple: null,
    appConfig: null,
    appsCompanyPortal: true,
    appsProtectionPolicy: null,
    loading: false,
    businessApplications: null,
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    state: 0
  },
  reducers: {
    fetchCompliancePoliciesPending: (state, {payload}) => {
      state.loading = payload;
    },
    // PATCH Conditional Access
    fetchCompliancePoliciesSuccess: (state, {payload}) => {
      state.android = payload.android;
      state.apple = payload.apple;
      state.appConfig = payload.appConfig;
      state.id = payload.id;
      state.companyId = payload.companyId;
      state.isEnabled = payload.isEnabled;
      state.modifiedAt = payload.modifiedAt;
      state.state = payload.state;
    },
    fetchConditionalAccessSuccess: (state, {payload}) => (state = payload),
    patchIsEnabledSuccess: (state, {payload}) => (state = payload),
    // PUT Compliance Policies
    putCompliancePoliciesSuccess: (state, {payload}) => {
      state[payload.active] = payload.data;
      return state;
    },
    putCompliancePoliciesIsEnabledSuccess: (state, {payload}) => {
      state.isEnabled = payload.isEnabled;
      return state;
    },
    // PUT Apps
    putAppsCompanyPortalSuccess: (state, {payload}) => {
      state.appsCompanyPortal = payload;
      return state;
    },
    putAppsProtectionPolicySuccess: (state, {payload}) => {
      state.appsProtectionPolicy = payload;
      return state;
    },
    putAppsIsEnabledSuccess: (state, {payload}) => {
      state.isEnabled = payload.isEnabled;
      return state;
    }
  },
  extraReducers: {
    [updateConditionalAccess.fulfilled]: (state, {payload}) => {
      state.conditionalAccessPolicies = payload;
    },
    [getMobileDevicesData.fulfilled]: (state, action) => {
      return {...action.payload};
    },
    [fetchCompliancePolicies.fulfilled]: state => {
      state.loading = false;
    },
    [fetchCompliancePolicies.pending]: state => {
      state.loading = true;
    },
    [updateCompliancePolicy.fulfilled]: (state, {payload}) => {
      const {policyType, data} = payload;
      state[policyType] = data;
    },
    [fetchConditionalAccess.fulfilled]: (state, {payload}) => {
      state.conditionalData = payload;
    },
    [updateBusinessApplication.fulfilled]: (state, {payload}) => {
      state.businessApplications = payload;
    },
    [toggleMobileDevices.fulfilled]: (state, payload) => {
      state = payload;
    }
  }
});

export const {
  patchConditionalAccessSuccess,
  patchIsEnabledSuccess,
  putCompliancePoliciesSuccess,
  putCompliancePoliciesIsEnabledSuccess,
  putAppsCompanyPortalSuccess,
  putAppsProtectionPolicySuccess,
  putAppsIsEnabledSuccess,
  fetchCompliancePoliciesSuccess,
  fetchConditionalAccessSuccess,
  fetchCompliancePoliciesPending
} = mobileDevicesSlice.actions;

export default mobileDevicesSlice;
