import {editListItem} from '../../../utils/hub/normalize';

export const editIntilityService = (intilityServiceList, onboardIntilityService) => {
  const newList = editListItem(intilityServiceList, onboardIntilityService);

  return {
    intilityServicePlatform: {
      intilityServiceList: newList
    }
  };
};
export const editGlobalIntilityService = (globalIntilityServiceList, onboardIntilityService) => {
  const newList = editListItem(globalIntilityServiceList, onboardIntilityService);

  return {
    intilityServicePlatform: {
      globalIntilityServiceList: newList
    }
  };
};

export const normalizeIntilityServices = intilityServicePlatform => {
  return {
    intilityServicePlatform: {
      intilityServiceList: intilityServicePlatform.intilityServiceList,
      information: intilityServicePlatform,
      state: intilityServicePlatform.state
    }
  };
};

export const normalizeGlobalIntilityServices = globalIntilityServiceList => {
  return {
    intilityServicePlatform: {
      globalIntilityServiceList: globalIntilityServiceList
    }
  };
};

export const normalizeIntilityServiceInformation = intilityServicePlatform => {
  console.log(intilityServicePlatform)
  return {
    intilityServicePlatform: {
      information: intilityServicePlatform
    }
  };
};

export const normalizeIntilityServiceList = intilityServicePlatform => {
  return {
    intilityServicePlatform: {
      intilityServiceList: intilityServicePlatform.intilityServiceList
    }
  };
};
