import {createSlice} from '@reduxjs/toolkit';

const viewSlice = createSlice({
  name: 'view',
  initialState: 'Wizard',
  reducers: {
    setView: (state, action) => {
      return action.payload;
    }
  }
});

export const viewReducer = viewSlice.reducer;
export const {setView} = viewSlice.actions;

export default viewSlice;
