import {Locations} from '../../../components/Hub/Pages';
import pluralize from '../../../utils/pluralize';
import {faChairOffice} from '@fortawesome/pro-regular-svg-icons';
import LocationInformation
  from '../../../components/Hub/Pages/Locations/components/LocationInformation/LocationInformation';

export default {
  title: 'Locations',
  path: 'locations',
  icon: faChairOffice,
  isEnabled: (company) => { return company?.locationPlatform?.state},
  component: Locations,
  getStatus: company => {
    if (company.locationPlatform.information.locationCount > 0) {
      return '';
    }
    return 'error';
  },
  getStatusText: (status, company) => {
    if (status === 'error') {
      return 'No locations registered';
    }
    return `${
      company.locationPlatform.locationList?.length > 0
        ? company.locationPlatform.locationList?.length
        : company.locationPlatform.information.locationCount || 'No'
    } ${pluralize('location', company.locationPlatform.information.locationCount)} registered`;
  }
};

export const Location = {
  title: 'Location',
  path: `locations/:locationId`,
  isEnabled: (company) => {return company?.locationPlatform?.state},
  component: LocationInformation
}