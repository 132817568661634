// ------ Helpers ------
import {RequiredValue} from '../../components/Hub/Pages/Users/Options/UserTypeOptions';

export const ValidateEmail = email => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return !regex.test(email);
};

export const ValidatePhonenumber = number => {
  const regex = /^\+?[0-9\s\-()]{7,15}$/;

  return !regex.test(number);
};

export const ValidateOrgNumber = number => {
  const regex = /^[0-9]{3}\s*[0-9]{3}\s*[0-9]{3}\s*$/g;

  return !regex.test(number);
};

export const ValidateDomain = number => {
  const regex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/g;

  return !regex.test(number);
};

// Exposed method, calls the right validation based on property name.
// Each function returns an errors oject with properties where true means there is an error.
export const validateState = state => {
  const {organizationNumber, name, address, email, domainName} = state.data;
  const {onboardingContact, productionContact, differentProductionContact} = state;
  const {firstName, lastName, email: onboardingEmail, phoneNumber} = onboardingContact;
  const {
    firstName: productionFirstName,
    lastName: productionLastName,
    email: productionEmail,
    phoneNumber: productionPhoneNumber
  } = productionContact;
  return {
    // organizationNumber: !/^[0-9]{3}\s*[0-9]{3}\s*[0-9]{3}\s*$/g.test(organizationNumber), // 123456789, 123 456 789 etc.
    organizationNumber: organizationNumber?.length === 0,
    name: name?.length === 0,
    address: address?.length === 0,
    domainName: ValidateDomain(domainName),
    email: ValidateEmail(email),

    onboardingContactFirstName: firstName?.length === 0,
    onboardingContactLastName: lastName?.length === 0,
    productionContactFirstName: productionFirstName?.length === 0 && differentProductionContact,
    productionContactLastName: productionLastName?.length === 0 && differentProductionContact
  };
};

export const validateUser = user => {
  return {
    firstName: user?.firstName?.length === 0 || user?.firstName === null,
    lastName: RequiredValue(user.intilityUserType) ? user?.lastName?.length === 0 || user?.lastName === null : false,
    mobilePhone: RequiredValue(user?.intilityUserType) ? ValidatePhonenumber(user?.mobilePhone) : false,
    primaryEmailAddress: ValidateEmail(user?.primaryEmailAddress),
    billingId: user?.billingId?.length === 0 || user?.billingId === null,
    locationId: user?.locationId?.length === 0 || user?.locationId === null,
    language: user?.language?.length === 0 || user?.language === null,
    country: user?.country?.length === 0 || user?.country === null
  };
};

export const validateUserIntility = user => {
  return {
    firstName: user?.firstName?.length === 0 || user?.firstName === null,
    mobilePhone: RequiredValue(user?.intilityUserType) ? ValidatePhonenumber(user?.mobilePhone) : false,
    primaryEmailAddress: ValidateEmail(user?.primaryEmailAddress)
  };
};

export const validateContact = user => {
  return {
    phoneNumber: ValidatePhonenumber(user?.phoneNumber),
    email: ValidateEmail(user?.email)
  };
};

export const validateBilling = billing => {
  return {
    organizationNumber: billing?.organizationNumber?.length === 0,
    email: ValidateEmail(billing?.email),
    name: billing?.name?.length === 0,
    zipCode: billing?.name?.length === 0,
    postalPlace: billing?.name?.length === 0,
    country: billing?.name?.length === 0,
    currency: billing?.name?.length === 0,
    address: billing?.address?.length === 0
  };
};

export const validateCompleteApp = app => {
  return {
    version: app?.newVersion?.length === 0,
    vendorContacts: app?.vendorContacts?.length === 0,
    internalContacts: app?.internalContacts?.length === 0,
    login: app?.activeDirectory === false && app?.appSpecificUser === false && app?.azureAd === false,
    integrations: app?.applicationRelations?.length === 0
  };
};

export const isStateValid = errors => {
  const errorsArray = Object.keys(errors).map(key => errors[key]);

  // usually the errors object is 1 level deep, so just map and check if there is an error
  return !errorsArray.some(error => error);
};
