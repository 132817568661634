import {addListItem, editListItem} from '../../../utils/hub/normalize';

export const editLocation = (locationList, location) => {
  const newList = editListItem(locationList, location);

  return {
    locationPlatform: {
      locationList: newList
    }
  };
};

export const addNewLocation = (location, locationList) => {
  const newList = addListItem(locationList, location);
  return {
    locationPlatform: {
      locationList: newList
    }
  };
};

export const normalizeLocations = locationPlatform => {
  return {
    locationPlatform: {
      locationList: locationPlatform.locationList,
      information: locationPlatform,
      state: locationPlatform.state
    }
  };
};

export const normalizeLocationInformation = locationPlatform => {
  return {
    locationPlatform: {
      information: locationPlatform,
      state: locationPlatform.state
    }
  };
};

export const normalizeLocationList = locationPlatform => {
  return {
    locationPlatform: {
      locationList: locationPlatform.locationList
    }
  };
};
