import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './contact.scss';
import {fetchLocationsAsync} from '../../../../redux/company/locations/locationThunks';
import BasicPage from '../BasicPage';
import ContactBox, { NewContactBox, VendorContactBox } from "./ContactBox/ContactBox";
import EditContactSlider from './EditContactSlider/EditContactSlider';
import {fetchApplicationsAsync} from '../../../../redux/company/applications/applicationThunks';
import ContactContainer from './ContactContainer/ContactContainer';
import EditVendorContactSlider from "./EditContactSlider/EditVendorContactSlider";
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [contact, setContact] = useState(null);
  const {locationPlatform, info, applicationPlatform, currentVendorContact} = useSelector(
    state => state.company.data
  );
  const {user} = useSelector(state => state.auth);

  const dispatch = useDispatch();

  // fetch locations
  useEffect(() => {
    if (!user || !info.id) return;
    dispatch(fetchLocationsAsync(info.id));
  }, [user, dispatch, info.id]);

  // fetch server applications
  useEffect(() => {
    if (!user || !info.id) return;
    dispatch(fetchApplicationsAsync(info.id));
  }, [user, dispatch, info.id]);


  const editContact = contact => {
    setContact(contact);
    setOpen(true);
  };

  const editVendorContact = contact => {
    setContact(contact);
    setShowVendorModal(true);
  };

  const closeModal = () => {
    setContact(null);
    setShowVendorModal(false);
    setOpen(false);
  }

  const vendorAppData =
    applicationPlatform.applicationList &&
    applicationPlatform.applicationList.length > 0 &&
    applicationPlatform.applicationList
      .map(a => {
        return a.vendorContacts.map(v => {
          return {
            name: a.name,
            vendorContact: v
          };
        });
      })
      .flatMap(u => u);

  const internalAppData =
    applicationPlatform.applicationList &&
    applicationPlatform.applicationList.length > 0 &&
    applicationPlatform.applicationList
      .map(a => {
        return a.internalContacts.map(v => {
          return {
            name: a.name,
            internalContact: v
          };
        });
      })
      .flatMap(u => u);

  return (
    <BasicPage id='contact' taskType={taskType.CONTACT}>
      <div className='content-main'>
        <h2>Contact information</h2>
        <div className='bfl-grid main-contact-grid'>
          {info?.onboardingContact && (
            <ContactBox
              title='Onboarding contact'
              contact={info.onboardingContact}
              edit={() => editContact(info.onboardingContact)}
            />
          )}
          {info?.productionContact && (
            <ContactBox
              title='Production contact'
              contact={info.productionContact}
              edit={() => editContact(info.productionContact)}
            />
          )}
          {!currentVendorContact?.currentVendorContact?.id && (
            <NewContactBox
              title='Current vendor'
              contact={currentVendorContact?.currentVendorContact}
              addContact={() => setShowVendorModal(true)}
            />
          )}
          {currentVendorContact?.currentVendorContact?.id && (
            <VendorContactBox
              title='Current vendor'
              contact={currentVendorContact.currentVendorContact}
              edit={() => editVendorContact(currentVendorContact.currentVendorContact)}
            />
          )}
        </div>
        {locationPlatform.locationList && locationPlatform.locationList.length > 0 && (
          <ContactContainer
            contactType='locationContact'
            link='locations'
            title='Location contacts'
            list={locationPlatform.locationList}
          />
        )}
        {vendorAppData && vendorAppData.length > 0 && (
          <ContactContainer
            contactType='vendorContact'
            link='applications'
            title='Application Vendor contacts'
            list={vendorAppData}
          />
        )}
        {internalAppData && internalAppData.length > 0 && (
          <ContactContainer
            contactType='internalContact'
            link='applications'
            title='Application Internal contacts'
            list={internalAppData}
          />
        )}
        <EditContactSlider contact={contact} closeModal={closeModal} isOpen={open} />
        <EditVendorContactSlider contact={contact} closeModal={closeModal} isOpen={showVendorModal} />
      </div>
    </BasicPage>
  );
};

export default Contact;
