import {createAction} from '@reduxjs/toolkit';
import {
  editDocumentation,
  normalizeDocumentation,
  normalizeDocumentationInformation,
  normalizeDocumentList
} from './documentationNormalizers';

export const fetchDocumentListSuccess = createAction(
  'documentationAction/fetchDocumentListSuccess',
  apiDocumentation => ({
    payload: normalizeDocumentList(apiDocumentation)
  })
);

export const fetchInformationSuccess = createAction(
  'documentationAction/fetchDocumentationInformationSuccess',
  apiDocumentation => ({
    payload: normalizeDocumentationInformation(apiDocumentation)
  })
);

export const updateDocumentationInformationSuccess = createAction(
  'documentationAction/updateInformationSuccess',
  information => ({
    payload: normalizeDocumentationInformation(information)
  })
);

export const fetchDocumentationSuccess = createAction(
  'documentationAction/fetchDocumentationSuccess',
  apiDocumentation => ({
    payload: normalizeDocumentation(apiDocumentation)
  })
);

export const updateDocumentationSuccess = createAction(
  'documentationAction/updateDocumentationSuccess',
  (documentation, documentList) => ({
    payload: editDocumentation(documentList, documentation)
  })
);
