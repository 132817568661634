import {faTasks} from '@fortawesome/pro-regular-svg-icons';
import Tasks from '../../../components/Hub/Pages/Tasks/Tasks';
import PageState from '../pageState';

const overviewData = {
  title: 'Tasks',
  path: 'tasks',
  icon: faTasks,
  isEnabled: () => {return PageState.ENABLED},
  component: Tasks,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return company.info.name;
  }
};

export default overviewData;
