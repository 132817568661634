import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { animated, useSpring } from "@react-spring/web";
import { useMeasure } from "../../CompanyOverviewNew/CompanyOverviewNew";
import './basicPage.scss';
import { Button, Drawer, Icon, Message, Modal, useBreakpoint } from "@intility/bifrost-react";
import OnboardTaskComponent, { CompleteTaskComponent } from "./Tasks/TaskContainer/OnboardTaskComponent";
import TaskModal from "./Tasks/TaskModal/TaskModal";
import apiFetch from "../../../utils/apiFetch";
import useIntilityPermission from "../../../hooks/useIntilityPermission";
import { useSelector } from "react-redux";
import { TaskArticleComponent } from "./Devices.MobileDevices/Articles/ArticleComponent";
import { PublishArticleDrawer } from "./Devices.MobileDevices/Articles/PublishArticle";
import tasksComplete from '../../../assets/images/task/CompleteTaskList.png';
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import { responsibleType } from "../../BackOffice/EditComponents/EditOnboardTasks";

const TaskTopBar = ({id, taskType, extraTypes, isComplete, setIsComplete, togglePrompt}) => {
  const companyId = useSelector(state => state.company.data.info.id);
  const {taskList} = useSelector(state => state.company.data.taskPlatform);
  const [taskUsers, setTaskUsers] = useState([]);
  const [isOpen, setOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [smallModal, setSmallModal] = useState(false);
  const [bind, {height: viewHeight}] = useMeasure();
  const props = useSpring({height: isOpen ? 341 : 17});
  const otherProps = useSpring({top: isOpen ? 341 : 17});
  const [sort, setSort] = useState(1);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editTask, setEditTask] = useState(undefined);
  const intilityUser = useIntilityPermission();
  const toSmall = useBreakpoint(null, 'small');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setOpen(window.localStorage.showTopbar !== 'false');
  }, []);

  useEffect(() => {
    if (intilityUser && id) {
      fetchIntilityMembers();
    }
  }, [intilityUser, id]);

  const fetchIntilityMembers = () => {
    if (intilityUser) {
      apiFetch(`/users/${id}`)
        .then(response => {
          if (!response.ok) {
            setTaskUsers([]);
            return [];
          }
          return response.json()
        })
        .then(users => setTaskUsers(users))
    }
  };

  useEffect(() => {
    if (toSmall) {
      setOpen(false);
    } else setSmallModal(false);
  }, [toSmall])

  const sectionTasks = taskList && [...taskList]?.filter(t => t.isEnabled === true)?.filter(s => s.taskType === responsibleType.CUSTOMER)?.sort((t1, t2) => {
    const dateA = new Date(t1.dueDate);
    const dateB = new Date(t2.dueDate);
    if (dateA.getFullYear() === 1) return 1;
    if (dateB.getFullYear() === 1) return -1;
    return dateA.getTime() - dateB.getTime();
  }).sort((t1, t2) => {
    const dateA = new Date(t1.dueDate);
    const dateB = new Date(t2.dueDate);
    if (t1.complete) return 1;
    if (t2.complete) return -1;
    if (dateA.getFullYear() !== 1) return 0;
    if (dateB.getFullYear() !== 1) return 0;
  })?.filter(t => {
    return t.section === taskType?.value || extraTypes?.includes(t.section)
  });

  const filteredTaskList = sectionTasks?.filter(t => {
    if (sort === 1) return !t.complete;
    return t.complete;
  });

  const editCurrentTask = (task) => {
    setEditTask(task);
    setModalOpen(true);
  }


  return (
    <div className='basic-hub-page' id={id}>
      <animated.div className='box-dropdown bfl-grid' style={{...props}}>
        <div className='box-dropdown-tasks'>
          <div className='bf-scrollbar-small'>
            <div style={{flex: '1'}} className='content-box'>
              <div className='task-container-content'>
                <div className='task-container-tasks'>
                  <div className='header-buttonbar'>
                    {intilityUser ?
                      <span onClick={() => {
                        localStorage.setItem(`backUrl${companyId}`, location.pathname);
                        history.push(`/details/${companyId}/tasks/edit`)
                      }}>
                        <h5>Section tasks</h5>
                        <Icon icon={faCog} />
                      </span> :
                      <h5>Section tasks</h5>
                    }
                    <Button.Group>
                      <Button active={sort === 1} onClick={() => setSort(1)}>To do ({sectionTasks?.filter(t => !t?.complete)?.length})</Button>
                      <Button active={sort === 2} onClick={() => setSort(2)}>Complete ({sectionTasks?.filter(t => t?.complete === true)?.length})</Button>
                    </Button.Group>
                  </div>
                  <div className='task-list bf-scrollbar-small'>
                    {(filteredTaskList.length === 0 && sort === 2) ?
                      <Message state={"neutral"} noIcon header='No complete tasks yet'>
                        Check tasks when you have completed them
                      </Message>
                      : (filteredTaskList.length === 0 && sort === 1 && !setIsComplete) ?
                      <div className='tasks-complete-image' >
                        <img src={tasksComplete} alt='All tasks complete'/>
                        <h5>Nothing left to do on this page!</h5>
                      </div>
                    : filteredTaskList?.map((task, key) => {
                      return (
                        <OnboardTaskComponent
                          key={key}
                          simpleTask={true}
                          task={task}
                          taskUser={task?.user}
                          setSelectedTask={setSelectedTask}
                          setEditTask={editCurrentTask}
                        />
                      );
                    })
                    }
                    {(setIsComplete && sort !== 2) &&
                      <CompleteTaskComponent showFinalize={taskList?.filter(t => {
                        return t.section === taskType?.value && t.complete === false
                      }).length === 0} isComplete={isComplete} setIsComplete={setIsComplete} togglePrompt={togglePrompt}/>
                    }
                  </div>
                </div>
              </div>
            </div>
            <TaskModal task={editTask} setTask={setEditTask} isOpen={modalOpen} setIsOpen={setModalOpen}/>
          </div>
        </div>
        <TaskArticleComponent category={taskType.subPage} />
      </animated.div>
      <animated.div className='box-header' {...bind} style={{ ...otherProps }}>
        <div/>
        <button onClick={() => {
          if (toSmall) {
            setSmallModal(true)
          } else {
            localStorage.setItem('showTopbar', isOpen ? 'false' : 'true');
            setOpen(!isOpen);
          }
        }}>
          What to do on this page?
        </button>
      </animated.div>
      <Drawer header={selectedTask?.request} isOpen={selectedTask !== null} overlay={true} onRequestClose={() => setSelectedTask(null)}
              footer={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button onClick={() => setSelectedTask(null)}>Close guide</Button>
                  <div/>
                </div>
              }>
        {selectedTask?.guideUrl?.includes('publish.intility.com') ?
          <PublishArticleDrawer articleId={selectedTask?.guideUrl.split('/articles/')[1] || null}/>
          :
          <div>
            <p>{selectedTask?.comment}</p>
            <a className='bf-link' target='_blank' rel='noopener noreferrer' href={selectedTask?.guideUrl}>{selectedTask?.guideUrl}</a>
          </div>
        }
      </Drawer>
      <Modal isOpen={smallModal && toSmall} header='What to do on this page?' onRequestClose={() => setSmallModal(false)}>
        <div id='task-top-bar-modal'>
          <TaskArticleComponent category={taskType.subPage} />
          <Button.Group>
            <Button active={sort === 1} onClick={() => setSort(1)}>To do ({sectionTasks?.filter(t => !t?.complete)?.length})</Button>
            <Button active={sort === 2} onClick={() => setSort(2)}>Complete ({sectionTasks?.filter(t => t?.complete === true)?.length})</Button>
          </Button.Group>
          <div className='task-list bf-scrollbar-small'>
            {(filteredTaskList.length === 0 && sort === 2) ?
              <Message state={"neutral"} noIcon header='No complete tasks yet'>
                Check tasks when you have completed them
              </Message>
              : (filteredTaskList.length === 0 && sort === 1 && !setIsComplete) ?
                <div className='tasks-complete-image' >
                  <img src={tasksComplete} alt='All tasks complete'/>
                  <h5>Nothing left to do on this page!</h5>
                </div>
                : filteredTaskList?.map((task, key) => {
                  return (
                    <OnboardTaskComponent
                      key={key}
                      simpleTask={true}
                      task={task}
                      taskUser={task?.user}
                      setSelectedTask={setSelectedTask}
                      setEditTask={editCurrentTask}
                    />
                  );
                })
            }
            {(setIsComplete && sort !== 2) &&
              <CompleteTaskComponent showFinalize={taskList?.filter(t => {
                return t.section === taskType?.value && t.complete === false
              }).length === 0} isComplete={isComplete} setIsComplete={setIsComplete} togglePrompt={togglePrompt}/>
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};

TaskTopBar.propTypes = {
  id: PropTypes.string,
  taskType: PropTypes.object,
  extraTypes: PropTypes.array,
  isComplete: PropTypes.bool,
  togglePrompt: PropTypes.bool,
  setIsComplete: PropTypes.func
};

export default TaskTopBar;
