import {components} from 'react-select';
import React from 'react';
import UserRowOptions from '../Options/UserRowOptions';
import Select from '@intility/bifrost-react-select';

export const UserValueContainer = ({name, setUserRows, setUserTableRows, addFilter, removeFilter, userRows}) => {
  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children
    if (Array.isArray(values)) {
      values = `${name}(${values.length})`
    }
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    )
  };

  return (
    <Select label="Toggle rows" hideLabel placeholder="View columns" options={UserRowOptions}
            onChange={e => {
              setUserRows(e);
              setUserTableRows(e);
              if (e.length > 0) {
                addFilter({
                  type: 'selectedRows',
                  value: e.length,
                  displayName: 'Columns',
                  rows: JSON.stringify(e)
                });
              } else {
                removeFilter({type: 'selectedRows'});
              }
            }}
            value={userRows}
            isMulti={true}
            name="Columns"
            style={{minWidth: '200px'}}
            components={{ValueContainer}} />
  )
}