import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {ButtonWithTooltip} from '../ButtonWithTooltip/ButtonWithTooltip';
import {Button, Modal} from '@intility/bifrost-react';
import './csvUploader.scss';

export const getFilesArray = fileList => {
  const files = [];

  for (let i = 0; i < fileList?.length; i++) {
    const file = fileList[i];
    files.push(file);
  }

  return files;
};

export const CsvUploader = ({
  onChange,
  isDisabled,
  pill,
  setReplace,
  type,
  filled,
  name,
  multiple = false,
  validContentTypes = ['.csv'],
  listLength
}) => {
  const fileRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onFileSelect = e => {
    return onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.target.files)
      }
    });
  };

  const upload = () => {
    if (listLength > 0) {
      setShowModal(true);
    } else {
      fileRef.current && fileRef.current.click();
    }
  };

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  }, [showTooltip]);

  return [
    <ButtonWithTooltip
      key={0}
      pill={pill}
      filled={filled}
      isComplete={isDisabled}
      btnOnClick={() => upload()}
      tooltipText={'Page set to complete, un-toggle to edit.'}
    >
      {name}
    </ButtonWithTooltip>,
    <input
      key={1}
      type='file'
      ref={fileRef}
      multiple={multiple}
      accept={validContentTypes.join(', ')}
      onChange={onFileSelect}
      style={{display: 'none'}}
    />,
    <Modal isOpen={showModal} header={`Upload ${type}s`} key={2} onRequestClose={() => setShowModal(false)}>
      <p>
        Uploading a CSV file with {type}s will either replace your current {type} list with the new CSV {type}s.
        <br /> Or, add the CSV {type}s to your current {type} list.
        <br /> How do you wish to proceed?
      </p>
      <div className='csv-uploader-footer'>
        <Button
          variant={'basic'}
          onClick={() => {
            setShowModal(false);
            setReplace(true);
            fileRef.current && fileRef.current.click();
          }}
        >
          Replace current list
        </Button>
        <Button
          variant={'filled'}
          onClick={() => {
            setShowModal(false);
            setReplace(false);
            fileRef.current && fileRef.current.click();
          }}
        >
          Add to list
        </Button>
      </div>
    </Modal>
  ];
};

CsvUploader.propTypes = {
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  setReplace: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  pill: PropTypes.bool,
  filled: PropTypes.bool,
  validContentTypes: PropTypes.array,
  listLength: PropTypes.number
};

export default CsvUploader;
