import {faWallet} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 5,
  text: 'All billing addresses registered',
  icon: faWallet,
  isEnabled: data => {
    return data.billingPlatform?.information?.isEnabled;
  },
  isEnabledOverview: data => {
    return data.billingPlatform?.isEnabled;
  },
  getStatusText: data => {
    return data.billingPlatform.information.modifiedAt;
  }
};
