export class Contact {
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
}

export class Company {
  name = '';
  organizationNumber = '';
  address = '';
  email = '';
  domainName = '';
  extra = {};
}

export class Domain {
  name = '';
}
