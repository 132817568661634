import {useEffect, useState} from 'react';
import jwtDecode from 'jwt-decode';
import devLog from 'utils/devLog';
import * as Sentry from '@sentry/browser';
import apiFetch from 'utils/apiFetch';
import {useLocation} from 'react-router-dom';

const getAccessToken = () => window.localStorage.accessToken;

const handleHash = () => {
  // requires hash to be in format 'accessToken=blabla&expiresIn=123'
  const hashRegex = /accessToken=(.+?(?=&))&expiresIn=(.+)/i;

  const matches = hashRegex.exec(window.location.hash);

  const accessToken = matches[1];
  const expiresIn = matches[2];
  if (!accessToken || !expiresIn) return;

  const expiresAt = Date.now() + expiresIn * 1000;

  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('expiresAt', expiresAt);

  // remove the hash from url
  window.history.replaceState(null, null, ' ');
};

const getUser = () => {
  return apiFetch('/user').then(response => {
    if (!response.ok) {
      throw Error('User has access token, but no user in database');
    }

    return response.json();
  });
};

const logout = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('expiresAt');

  window.location.reload();
};

const getRoles = () => {
  if (!getAccessToken()) return [];

  const decodedToken = jwtDecode(getAccessToken());
  // role claim is either undefined, a string, or an array of strings

  const {role} = decodedToken;

  // no roles
  if (!role) {
    return [];
  }

  // one role
  if (!Array.isArray(role)) {
    return [role];
  }

  // multiple roles
  return role;
};

const useAuth = () => {
  const [authenticating, setAuthenticating] = useState(true);
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const location = useLocation();

  // check for new access token in hash only at first render
  useEffect(() => {
    // if just got access token from api
    const {hash} = window.location;
    if (hash) {
      devLog('User just logged in, reading hash.');
      handleHash();
    }

    // has not logged in before
    const accessToken = getAccessToken();
    if (!accessToken) {
      devLog('User is not logged in, please log in.');
      setAuthenticating(false);
      return;
    }

    const expiresAt = window.localStorage.expiresAt;

    // token is expired
    if (Date.now() > expiresAt) {
      devLog('Token is expired, please log in.');
      logout();

      return;
    }

    // user should be logged in
    getUser()
      .then(apiUser => {
        setUser(apiUser);
        devLog('User is logged in.', apiUser);
        setAuthenticating(false);

        Sentry.configureScope(scope => {
          scope.setUser({
            email: apiUser.email,
            id: apiUser.id,
            username: apiUser.name
          });
        });
      })
      .catch(() => {
        devLog('User has access token, but no user in database. Logging user out.');
        logout();
        setAuthenticating(false);
      });
  }, []);


  useEffect(() => {
    const expiresAt = window.localStorage.expiresAt;
    // token is expired
    if (Date.now() > expiresAt) {
      devLog('Token is expired, please log in.');
      logout();

      return;
    }

  }, [location.pathname])

  useEffect(() => {
    setRoles(getRoles());
  }, [user]);

  return {
    user,
    authenticating,
    logout,
    roles
  };
};

export default useAuth;
