import { Checkbox, FieldGroup, Input, Pagination, Table } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import {faSearch} from '@fortawesome/pro-solid-svg-icons';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import { setIamsUserAsync } from "../../../../../redux/company/users/userThunks";
import PropTypes from 'prop-types';
import './registeredContent.scss';

export const NoComputerUsers = ({deletedUsers, isComplete}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [searchCriterea, setSearchCriterea] = useState({
    value: 'firstName',
    label: 'First name'
  });
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState({key: 'firstName', direction: 'asc'});
  const dispatch = useDispatch();

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const sortedData = deletedUsers
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key].toString()?.localeCompare(b[sort.key].toString());
      } else {
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key].toString()?.localeCompare(a[sort.key].toString());
      }
    })
    .filter(s => {
      if (s[searchCriterea.value] === null) return s;
      return s[searchCriterea.value]?.toLowerCase().includes(searchValue.toLowerCase());
    });

  const SearchOptions = [
    {value: 'firstName', label: 'First name'},
    {value: 'lastName', label: 'Last name'},
    {value: 'primaryEmailAddress', label: 'Email'}
  ];

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: deletedUsers?.length, label: 'All'});
    return tableOptions;
  };

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className='iams-content'>
      <div className='content-top-bar bfl-grid'>
        <FieldGroup label='Search'>
          <Select
            label='search type'
            hideLabel
            className='select-group-select'
            value={searchCriterea}
            options={SearchOptions}
            onChange={item => setSearchCriterea(item)}
          />
          <Input
            className='select-group-input'
            placeholder={`Search for ${searchCriterea.label}`}
            label='search input'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
        </FieldGroup>
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('firstName')} onClick={onSortChangeCreator('firstName')}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorting={getSortProp('primaryEmailAddress')}
              onClick={onSortChangeCreator('primaryEmailAddress')}
            >
              User principal name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorting={getSortProp('comment')}
              onClick={onSortChangeCreator('comment')}
            >
              User comment
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAndIndexedData.map((u, i) => {
            return (
              <Table.Row key={u?.id + i}>
                <Table.Cell>{`${u?.firstName || ''} ${u?.lastName || ''}`}</Table.Cell>
                <Table.Cell>{u?.primaryEmailAddress || '-'}</Table.Cell>
                <Table.Cell>{u?.comment || '-'}</Table.Cell>
                <Table.Cell>
                  {<Checkbox disabled={isComplete} onChange={() => dispatch(setIamsUserAsync(u.id, true))} label={'Restore'} />}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <div className='bottom-line'>
        <div className='row-box' />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
    </div>
  );
};

NoComputerUsers.propTypes = {
  deletedUsers: PropTypes.array,
  isComplete: PropTypes.bool
};

export default NoComputerUsers;
