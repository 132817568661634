import React from 'react';
import domainBoxPropTypes from './domainBoxPropTypes';
import { Badge, Icon, Table } from "@intility/bifrost-react";
import {faCheck, faEllipsisH} from '@fortawesome/pro-regular-svg-icons';
import BoxItem from '../../../../BoxItem/BoxItem';
import './domainBox.scss';

const getDomainStatus = (value) => {
  return (
    <span>
      {value === true ?
        <span><Icon icon={faCheck}/> Yes</span> :
        '-'
      }
    </span>
  )
}

const getContactName = (domain) => {
  const firstName = domain?.domainContact?.firstName;
  const lastName = domain?.domainContact?.lastName;
  if (firstName && lastName) return firstName + ' ' + lastName;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return '';
}

const DomainBox = ({domain, primaryId, toggleEdit, setDomainId, isComplete}) => {
  const editDomain = () => {
    setDomainId(domain.id);
    toggleEdit(true);
  };

  return (
    <Table.Row className='domain-row' limitExpandClick content={
      <div className='domain-row-dropbox bfl-grid'>
        <div className='to-medium'>
          <BoxItem className='to-small' customDefault='-' value={getDomainStatus(domain?.isEmailDomain)} label='E-mail domain'/>
          <BoxItem className='to-small' customDefault='-' value={getDomainStatus(domain?.isWebDomain)} label='Web domain'/>
          <BoxItem className='to-medium' customDefault={<Badge state={"warning"}>Missing info</Badge>} value={domain?.registrar} label='Registrar'/>
        </div>
        <div>
          <BoxItem customDefault={<Badge state={"warning"}>Missing info</Badge>} value={getContactName(domain)} label='Contact'/>
          <BoxItem customDefault={<Badge state={"warning"}>Missing info</Badge>} value={domain?.domainContact?.email} label='E-mail'/>
          <BoxItem customDefault={<Badge state={"warning"}>Missing info</Badge>} value={domain?.domainContact?.phoneNumber} label='Phone'/>
        </div>
      </div>
    }>
      <Table.Cell><span><span className='bf-link' onClick={() => editDomain()}>{domain?.name}</span> {primaryId === domain.id && <Badge state={"default"}>Main domain</Badge>}</span></Table.Cell>
      <Table.Cell className='from-medium'>{domain?.registrar}</Table.Cell>
      <Table.Cell className='from-xl'>{getContactName(domain)}</Table.Cell>
      <Table.Cell className='from-small'>{getDomainStatus(domain?.isEmailDomain)}</Table.Cell>
      <Table.Cell className='from-small'>{getDomainStatus(domain?.isWebDomain)}</Table.Cell>
      <Table.Cell>{getDomainStatus(domain?.moveToIntility)}</Table.Cell>
      <Table.Cell className='domain-row-edit'>
        {isComplete ? (
          <Icon icon={faEllipsisH} style={{color: 'var(--bfc-base-c-2)'}} />
        ) : (
          <Icon icon={faEllipsisH} style={{cursor: 'pointer'}} onClick={() => editDomain()} />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

DomainBox.propTypes = domainBoxPropTypes;

export default DomainBox;
