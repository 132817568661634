import './locationCard.scss';
import { Badge, Button, Icon, ProgressBar, Tooltip } from "@intility/bifrost-react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { faMapPin, faInfoCircle, faUser } from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";
import GoogleMapsPane from "../GoogleMapsPane/GoogleMapsPane";
import { imagePane } from "../GoogleMapsPane/imagePane";
import React, { useEffect, useState } from "react";
import { fetchLocationProgress } from "../../Locations";

const LocationCard = ({location, isComplete}) => {
  const {id} = useSelector(state => state.company.data.info);
  const { userList } = useSelector(state => state.company.data.userPlatform);
  const [progress, setProgress] = useState(0);
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;
    if (location) {
      const progress = fetchLocationProgress(location);
      if (!isCancelled) {
        setProgress(progress)
      }
      return () => {
        isCancelled = true;
      };
    }
  }, [location]);

  const userCount = userList?.filter(b => b.locationId === location.id)?.length;

  const formatAddress = (location) => {
    const addressItems = [];
    location?.address && addressItems.push(location.address);
    location?.zipCode && addressItems.push(location.zipCode);
    location?.postalPlace && addressItems.push(location.postalPlace);
    location?.country && addressItems.push(location.country);
    if (addressItems.length > 0) {
      return <p>{addressItems.join(', ')}</p>
    }
    return <p className='bf-em'>Unknown address</p>
  }

  return (
    <div className='location-card'>
      <div className='image-container' style={{ backgroundImage: `url(${imagePane(location)})` }}>
        <GoogleMapsPane location={location} uploadButton={false}/>
      </div>
      <div className='location-card-content'>
        <p onClick={() => history.push(`/${id}/locations/${location.id}`)} style={{color: 'var(--bfc-base-c-1)'}} className='bf-large bf-title-link'>{location?.name}</p>
        <span>
          <Icon icon={faMapPin}/>
          {formatAddress(location)}
        </span>
        <span>
          <Icon icon={faUser}/>
          ({userCount}/{location?.userCount})
        </span>
        <span style={{marginBottom: 'auto'}}>
          <span>
            {location?.intilityManaged === 1 &&
              <Badge style={{margin: 'var(--bfs2)'}} state={"neutral"}>Intility managed</Badge>
            }
            {location?.locationTypes?.split(',')?.map(l =>
              <Badge style={{margin: 'var(--bfs2)'}} key={l}>{l}</Badge>
            )}
          </span>
        </span>
        <Button onClick={() => history.push(`/${id}/locations/${location.id}`)} variant={progress < 100 ? "filled" : "basic"}>{progress < 100 ? "Edit location to complete" : "Edit location"}</Button>
        <ProgressBar disabled={progress < 100} value={progress} hideLabel/>
        <span>
          <p className='bf-small bf-strong' style={{marginRight: 'var(--bfs4)'}}>{progress}%</p>
          <p className='bf-small'>of location information complete</p>
        </span>
      </div>
    </div>
  );

}

LocationCard.propTypes = {
  location: PropTypes.object,
  isComplete: PropTypes.bool
};

export default LocationCard