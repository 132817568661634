import React, {useEffect, useState} from 'react';
import {
  AutoCol, Badge,
  Button,
  Checkbox, CheckboxCard, dateFormat, Drawer,
  Dropdown,
  Icon,
  Input, Label, Message,
  Modal,
  Pagination,
  Table,
  TextArea, Tooltip
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import DatePicker from "@intility/bifrost-react-datepicker";
import './editComponent.scss';
import './editApplicationFilters.scss';
import {useDispatch, useSelector} from 'react-redux';
import { OnboardTask, OnboardTicket, Project } from "../../../utils/hub/models";
import produce from 'immer';
import { faAdd, faEllipsisH, faLink, faPencil, faTicket } from "@fortawesome/pro-regular-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import {
  addOnboardTaskAsync, deleteOnboardTaskAsync,
  fetchGlobalTasksAsync,
  fetchOnboardTasksAsync, updateOnboardTaskAsync,
} from "../../../redux/company/onboardTasks/onboardTaskThunks";
import apiFetch from "../../../utils/apiFetch";
import { pageDataOptions, projectAreaOptions } from "../../../utils/hub/pageData/pageDataOptions";
import { emptyGuid } from "../../../utils/guid";
import { PublishArticleDrawer } from "../../Hub/Pages/Devices.MobileDevices/Articles/PublishArticle";
import {
  addOnboardTicketAsync,
  fetchOnboardTicketsAsync,
  addProjectAsync,
  getRotUserIdAsync
} from "../../../redux/company/onboardTickets/onboardTicketThunks";
import Flex from "../../Flex/Flex";
import { Link } from "react-router-dom";

export const responsibleType = {
  CUSTOMER: 0,
  INTILITY: 1,
};

export const EditOnboardTasks = () => {
  const {id, createdAt, name, companyServiceId} = useSelector(state => state.company.data.info);
  const { companyCode } = useSelector(state => state.company.data.detailsPlatform.information);
  const {taskList, globalTaskList, information} = useSelector(state => state.company.data.taskPlatform);
  const [sort, setSort] = useState({key: 'request', direction: 'asc'});
  const [ticketReady, setTicketReady] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [newTask, setNewTask] = useState(new OnboardTask());
  const [newTicket, setNewTicket] = useState(new OnboardTicket());
  const [ticketCategoryOptions, setTicketCategoryOptions] = useState([]);
  const [ticketTypeOptions, setTicketTypeOptions] = useState([]);
  const [responsibleUser, setResponsibleUser] = useState();
  const [responsibleIntilityUser, setResponsibleIntilityUser] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [intilityUserOptions, setIntilityUserOptions] = useState([]);
  const [projectAreas, setProjectAreas] = useState([]);
  const [taskFilter, setTaskFilter] = useState(0);
  const [taskTypeFilter, setTaskTypeFilter] = useState(0);
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [selectedTask, setSelectedTask] = useState(null);
  const dispatch = useDispatch();

  const checkCompanyCode = (code) => {
    apiFetch(`/companies/${emptyGuid}/tickets/codeCheck?companyCode=${code}`)
        .then(response => {
          if (!response.ok) {
            setTicketReady(false);
            return false;
          }
          return response.json()
        })
        .then(res => {
          setTicketReady(res);
        })
  };

  const [isProjectConnectedState, setIsProjectConnected] = useState(false);
  const isProjectConnected = !!information.ticketManagerProjectId || isProjectConnectedState;
  const [projectUrlState, setProjectUrl] = useState('');
  const projectUrl = projectUrlState || (isProjectConnected ? `${process.env.REACT_APP_SUPPORT_PLATFORM_LINK}/process-manager/projects/project/${information.ticketManagerProjectId}` : '');
  const [isChecked, setIsChecked] = useState(true);
  

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchOnboardTasksAsync(id));
    dispatch(fetchGlobalTasksAsync());
    fetchTaskUsers();
    fetchTicketCategories();
    fetchTicketTypes();
  }, [user, dispatch, id]);

  useEffect(() => {
    if (id !== undefined && id?.length !== 0) {
      dispatch(fetchOnboardTicketsAsync(id, companyCode));
    }
  }, [id]);

  useEffect(() => {
    if (companyCode !== '' && companyCode !== undefined) {
      checkCompanyCode(companyCode);
    }
    setNewTicket(
      produce(newTicket, draft => {
        draft.companyCode = companyCode;
      })
    );
  }, [companyCode, showTicketModal]);

  useEffect(() => {
    setNewTicket(
      produce(newTicket, draft => {
        draft.processId = isChecked ? information.ticketManagerProjectId : '';
      })
    );
  }, [information.ticketManagerProjectId, showTicketModal]);

  const fetchTaskUsers = () => {
    apiFetch(`/users/${id}`)
      .then(response => {
        if (!response.ok) {
          setUserOptions([]);
          return [];
        }
        return response.json()
      })
      .then(users => {
        setUserOptions(users?.filter(u => !u.email?.toLowerCase()?.endsWith("@intility.no"))?.map(u => ({label: `${u?.firstName} ${u?.lastName} (${u?.provider})`, value: u.id})));
        setIntilityUserOptions(users?.filter(u => u.email?.toLowerCase()?.endsWith("@intility.no"))?.map(u => ({label: `${u?.firstName} ${u?.lastName}`, value: u})))
      })
  };

  const fetchTicketCategories = () => {
    apiFetch(`/companies/${id}/Tickets/categories`)
      .then(response => {
        if (!response.ok) {
          setTicketCategoryOptions([]);
          return [];
        }
        return response.json()
      })
      .then(categories => {
        setTicketCategoryOptions(categories?.map(u => ({label: u?.fullName, value: u})));
      })
  };

  const fetchTicketTypes = () => {
    apiFetch(`/companies/${id}/Tickets/types`)
      .then(response => {
        if (!response.ok) {
          setTicketTypeOptions([]);
          return [];
        }
        return response.json()
      })
      .then(types => {
        setTicketTypeOptions(types?.map(u => ({label: u?.name, value: u})));
      })
  };

  const onNewTaskChange = (e, name) => {
    if (name === 'taskType') {
      if (e === responsibleType.INTILITY) {
        setResponsibleUser(null);
        setNewTask(
          produce(newTask, draft => {
            draft.taskType = e;
            draft.userId = null;
          })
        )
      } else {
        setNewTask(
          produce(newTask, draft => {
            draft.taskType = e;
          })
        )
      }
    } else if (e === null && name === 'userId') {
      setNewTask(
        produce(newTask, draft => {
          draft.userId = null;
        })
      )
    } else if (e === null && name === 'intilityContactId') {
      setNewTask(
        produce(newTask, draft => {
          draft.intilityContactId = null;
        })
      )
    } else {
      const { value } = e;

      setNewTask(
        produce(newTask, draft => {
          if (name === 'dueDate') {
            draft[name] = new Date(e?.setHours(12));
          } else if (name === 'responsible') {
            draft.responsible = value;
            draft.userId = null;
          } else if (name === 'intilityContactId') {
            draft.intilityContactId = value.id;
          } else if (name === 'priority') {
            const newValue = parseInt(value?.toString()) || 0;
            draft[name] = newValue > 100 ? 100 : newValue;
          } else {
            draft[name] = value;
          }
        })
      )
    }
  }

  const onNewTicketChange = (e, name) => {
    if (e === null && name === 'userId') {
      setNewTicket(
        produce(newTicket, draft => {
          draft.userId = null;
        })
      )
    } else {
      const { value } = e;

      setNewTicket(
        produce(newTicket, draft => {
          if (name === 'categoryId') {
            draft.categoryId = value.id;
          } else if (name === 'typeId') {
            draft.typeId = value.id;
          } else if (name === 'ticketOwner') {
            draft.ticketOwner = value.email
          } else {
            draft[name] = value;
          }
        })
      )
    }
  }

  Date.prototype.addDays = function(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const toggleTask = (task) => {
    if (task?.generalTaskId === undefined) {
      const newTask = produce(new OnboardTask(), draft => {
          draft.section = task.section;
          if (task.deadlineStartDays > 0) {
            draft.dueDate = new Date(createdAt).addDays(task.deadlineStartDays);
          }
          draft.request = task.request;
          draft.taskType = task.taskType;
          draft.projectArea = task.projectArea;
          draft.guideUrl = task.guideUrl;
          draft.generalTaskId = task.id;
        });
      dispatch(addOnboardTaskAsync(newTask));
    } else {
      const updatedTask = produce(task, draft => {
        draft.isEnabled = !task.isEnabled;
      });
      dispatch(updateOnboardTaskAsync(updatedTask));
    }
  };

  const editTask = (task) => {
    setNewTask(task);
    setResponsibleUser(task?.user ? { label: `${task?.user?.firstName} ${task?.user?.lastName}`, value: task?.user?.id} : undefined);
    setResponsibleIntilityUser(task?.intilityContact ? { label: `${task?.intilityContact?.firstName} ${task?.intilityContact?.lastName}`, value: task?.intilityContactId} : undefined);
    setShowModal(true);
  };

  const resetTasks = () => {
    setNewTask(new OnboardTask());
    setResponsibleUser(undefined);
    setResponsibleIntilityUser(undefined);
    setShowModal(false);
  }

  const resetTickets = () => {
    setNewTicket(new OnboardTicket());
    setShowTicketModal(false);
  }

  const onSave = (newTask) => {
    if (newTask.id === undefined || newTask.id === null) {
      if (newTask.request?.length > 0) {
        dispatch(addOnboardTaskAsync(newTask));
      }
    } else {
      dispatch(updateOnboardTaskAsync(newTask));
    }
    resetTasks();
  }

  const onSaveTicket = (newTicket) => {
    dispatch(addOnboardTicketAsync(newTicket));
    resetTickets();
  }
  
  const onCreateProject = async () => {
    const response = await dispatch(getRotUserIdAsync(user.email, id));
    const ownerGuid = response.userGuid;
    const newProject = {
      title: name + "("+companyCode+")"+" onboarding",
      description: "Tickets related to onboarding for "+name+" ("+companyCode+")",
      ownerGuid: ownerGuid,
      startedOn: new Date().toISOString(),
      impactsAll: false,
      companyGuid: companyServiceId
    };

    const projectResponse = await dispatch(addProjectAsync(newProject));
    const projectId = projectResponse.id;

    setProjectUrl(`${process.env.REACT_APP_SUPPORT_PLATFORM_LINK}/process-manager/projects/project/${projectId}`);
    setIsProjectConnected(true);
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setNewTicket(prevTicket => ({
      ...prevTicket,
      processId: event.target.checked ? information.ticketManagerProjectId : ''
    }));
  };

  const globalList = globalTaskList.filter(g => !taskList.find(t => t.generalTaskId === g.id));
  const concatTaskList = taskList.concat(globalList);

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: concatTaskList?.length, label: 'All'});
    return tableOptions;
  };


  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const filterProjectAreaFunction = (item) => {
    if (projectAreas.length === 0) return true;
    let filtered = false;
    projectAreas.forEach(f => {
      if (item?.projectArea === f.value) {
        filtered = true;
      }
    });
    return filtered;
  }

  const sortedData = concatTaskList
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (sort.key === 'dueDate') return new Date(a[sort.key]) - new Date(b[sort.key]);
        if (sort.key === 'taskType') return a[sort.key] - b[sort.key];
        if (sort.key === 'responsible') return a[sort.key] - b[sort.key];
        if (sort.key === 'published') return a?.generalTaskId !== undefined;
        if (sort.key === 'section') return pageDataOptions[a[sort.key]]?.label.localeCompare(pageDataOptions[b[sort.key]]?.label);
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        if (sort.key === 'dueDate') return new Date(b[sort.key]) - new Date(a[sort.key]);
        if (sort.key === 'taskType') return b[sort.key] - a[sort.key];
        if (sort.key === 'responsible') return b[sort.key] - a[sort.key];
        if (sort.key === 'published') return b?.generalTaskId !== undefined;
        if (sort.key === 'section') return pageDataOptions[b[sort.key]]?.label.localeCompare(pageDataOptions[a[sort.key]]?.label);
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    }).filter(t => filterProjectAreaFunction(t))
    .filter(t => {
      if (taskTypeFilter === 1) return t?.taskType === responsibleType.CUSTOMER;
      if (taskTypeFilter === 2) return t?.taskType === responsibleType.INTILITY;
      return t;
    }).filter(t => {
      if (taskFilter === 1) return t?.generalTaskId !== undefined;
      if (taskFilter === 2) return t?.generalTaskId === undefined;
      return t;
    }).filter(s => {
      return s.request?.toLowerCase().includes(searchValue.toLowerCase());
    });

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className='backoffice-editable-container'>
      <Message header='Global vs. custom task'>
        Publish the tasks you want {name} to see in their task dashboard, if you want to give this company specific tasks relevant only for them, press add task. Custom tasks will only be visible to the current company.
        If you need to edit a global task visit <Link to='/overview/settings/globalTasks'>Global settings</Link>
      </Message>
      <div className='filter-row' style={{marginTop: 'var(--bfs24)'}}>
        <div className='filter-btn-row'>
          <Input
            className='table-search-input'
            placeholder='Search for task'
            label='Search'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
          <Select className='header-select' placeholder='Project area' label='Project area filter' isMulti={true} value={projectAreas} options={projectAreaOptions} hideLabel onChange={(e) => setProjectAreas(e)} />
          <Button.Group>
            <Button active={taskTypeFilter === 0} onClick={() => {
              setTaskTypeFilter(0)
              setPage(1);
            }}>All tasks</Button>
            <Button active={taskTypeFilter === 1} onClick={() => {
              setTaskTypeFilter(1)
              setPage(1);
            }}>External</Button>
            <Button active={taskTypeFilter === 2} onClick={() => {
              setTaskTypeFilter(2)
              setPage(1);
            }}>Internal</Button>
          </Button.Group>
          <Button.Group>
            <Button active={taskFilter === 0} onClick={() => {
              setTaskFilter(0)
              setPage(1);
            }}>All tasks</Button>
            <Button active={taskFilter === 1} onClick={() => {
              setTaskFilter(1)
              setPage(1);
            }}>Published</Button>
            <Button active={taskFilter === 2} onClick={() => {
              setTaskFilter(2)
              setPage(1);
            }}>Deactivated</Button>
          </Button.Group>
          <p className='bf-em' style={{margin: 'auto', color: 'var(--bfc-base-c-2)'}}>{sortedData.length} of {concatTaskList.length} tasks</p>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          {isProjectConnected  ? (
            <a href={projectUrl} style={{marginTop: 'auto', marginRight: 'var(--bfs8)'}} target="_blank" rel="noopener noreferrer">
            <Button icon={faLink}>
              Go to project
          </Button>
          </a>
          ) : (
            <Button style={{marginTop: 'auto', marginRight: 'var(--bfs8)'}} icon={faLink} onClick={onCreateProject}>Create Project</Button>
          )}
          
          <Button style={{marginTop: 'auto', marginRight: 'var(--bfs8)'}} icon={faAdd} disabled={!ticketReady} onClick={() => setShowTicketModal(true)}>Ticket</Button>
          <Button style={{marginTop: 'auto'}} icon={faAdd} onClick={() => setShowModal(true)}>Task</Button>
        </div>
      </div>
      <Table style={{marginTop: 'var(--bfs16)'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell/>
            <Table.HeaderCell sorting={getSortProp('request')} onClick={onSortChangeCreator('request')}>
              Request
            </Table.HeaderCell>
            <Table.HeaderCell style={{minWidth: '150px'}} sorting={getSortProp('guideUrl')} onClick={onSortChangeCreator('guideUrl')}>
              Guide Url
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('section')} onClick={onSortChangeCreator('section')}>
              Onboard Section
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('projectArea')} onClick={onSortChangeCreator('projectArea')}>
              Project Area
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('userId')} onClick={onSortChangeCreator('userId')}>
              Assigned User
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('intilityContactId')} onClick={onSortChangeCreator('intilityContactId')}>
              Intility contact
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('taskType')} onClick={onSortChangeCreator('taskType')}>
              Type
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('ticketId')} onClick={onSortChangeCreator('ticketId')}>
              Ticket
            </Table.HeaderCell>
            <Table.HeaderCell style={{minWidth: '120px'}} sorting={getSortProp('dueDate')} onClick={onSortChangeCreator('dueDate')}>
              Due date
            </Table.HeaderCell>
            <Table.HeaderCell style={{minWidth: '120px'}} sorting={getSortProp('published')} onClick={onSortChangeCreator('published')}>
              Publish task
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAndIndexedData.map((task, i) => (
            <TaskTableRow key={i} task={task} toggleTask={toggleTask} editTask={editTask} deleteTask={deleteOnboardTaskAsync} setSelectedTask={setSelectedTask} setShowTicketModal={setShowTicketModal} newTicket={newTicket} setNewTicket={setNewTicket} ticketReady={ticketReady}/>
          ))}
        </Table.Body>
      </Table>
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div />
        )}
        <Select
          key='Items'
          label='Table size'
          className='table-size-dropdown'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
      <Modal isOpen={showModal} onRequestClose={() => resetTasks()} header={`${(newTask?.id !== emptyGuid && newTask?.id !== undefined) ? 'Edit' : 'Add new'} company Task`}>
        <div id='edit-task-modal'>
          <AutoCol>
            <CheckboxCard
                label='External'
                checked={newTask?.taskType === responsibleType.CUSTOMER}
                onChange={(e) => onNewTaskChange(e.target.checked && responsibleType.CUSTOMER, 'taskType')}
                type='radio'
            >
              Will be visible for customers in Onboard
            </CheckboxCard>
            <CheckboxCard
                label='Internal'
                checked={newTask?.taskType === responsibleType.INTILITY}
                onChange={(e) => onNewTaskChange(e.target.checked && responsibleType.INTILITY, 'taskType')}
                type='radio'
            >
              Visible for Intility only. Can connect with CS ticket
            </CheckboxCard>
          </AutoCol>
          <Flex flex={[1]}>
            <TextArea label='Task description' rows={1} value={newTask?.request} required onChange={(e) => onNewTaskChange(e.target, 'request')}/>
          </Flex>
          <Flex flex={[1, 1]}>
            <Select label='Onboard Section' value={pageDataOptions[newTask?.section]} required options={pageDataOptions} onChange={(e) => onNewTaskChange(e, 'section')}/>
            <Input label='GuideUrl' value={newTask?.guideUrl} optional onChange={(e) => onNewTaskChange(e.target, 'guideUrl')}/>
          </Flex>
          <Flex flex={[1, 1]}>
            <Select label='Project area' value={projectAreaOptions.find(o => o.value === newTask?.projectArea)} optional options={projectAreaOptions} onChange={(e) => onNewTaskChange(e, 'projectArea')}/>
            <DatePicker
              selected={
                newTask?.dueDate
                  ? new Date(newTask?.dueDate).getFullYear() === 1
                    ? null
                    : new Date(newTask?.dueDate)
                  : null
              }
              optional onChange={(e) => onNewTaskChange(e, 'dueDate')} label='Deadline date'/>
          </Flex>
          <Flex flex={[1, 1]}>
            <Select isClearable={true} label='Responsible user' value={responsibleUser} options={userOptions} optional onChange={(e) => {
              onNewTaskChange(e, 'userId');
              setResponsibleUser(e);
            }}/>
            <Select isClearable={true} label='Intility contact' value={responsibleIntilityUser} options={intilityUserOptions} optional onChange={(e) => {
              onNewTaskChange(e, 'intilityContactId');
              setResponsibleIntilityUser(e);
            }}/>
          </Flex>
          <Flex>
            <TextArea label='Comment' value={newTask?.comment} optional onChange={(e) => onNewTaskChange(e.target, 'comment')}/>
          </Flex>
          <Flex flex={[1, 1]}>
            <div/>
            <Input label='Ticket ID' value={newTask.ticketId} onChange={(e) => onNewTaskChange(e.target, 'ticketId')}/>
          </Flex>
        </div>
        <div style={{marginTop: 'var(--bfs16)', display: 'flex', justifyContent: 'space-between'}}>
          <div/>
          <Button disabled={newTask.request?.length === 0} onClick={() => onSave(newTask)}>Save</Button>
        </div>
      </Modal>
      <Modal isOpen={showTicketModal} onRequestClose={() => resetTickets()} header={`${(newTicket?.id !== emptyGuid && newTicket?.id !== undefined) ? 'Edit' : 'Add new'} company Ticket`}>
        <div id='task-modal'>
          <div key='global' style={{marginRight: 'var(--bfs24)'}}>
            <Input label='Title' value={newTicket?.title} required onChange={(e) => onNewTicketChange(e.target, 'title')}/>
            <Select label='Ticket category' value={ticketCategoryOptions?.find(t => t?.value?.id === newTicket.categoryId)} required options={ticketCategoryOptions} onChange={(e) => onNewTicketChange(e, 'categoryId')}/>
          </div>
          <div key='general' >
            <Input label='Company code' value={newTicket?.companyCode} required onChange={(e) => onNewTicketChange(e.target, 'companyCode')}/>
            <Select label='Ticket type' value={ticketTypeOptions?.find(t => t?.value?.id === newTicket.typeId)} required options={ticketTypeOptions} onChange={(e) => onNewTicketChange(e, 'typeId')}/>
          </div>
        </div>
        <Select value={intilityUserOptions?.find(t => t?.value?.email === newTicket.ticketOwner)} options={intilityUserOptions} label='Ticket owner' onChange={(e) => onNewTicketChange(e, 'ticketOwner')}/>
        <TextArea label='Ticket message' value={newTicket?.messageBody} onChange={(e) => onNewTicketChange(e.target, 'messageBody')}/>
        <div style={{marginTop: 'var(--bfs16)', display: 'flex', justifyContent: 'space-between'}}>
          <div/>
          <Button disabled={newTicket.title?.length === 0 || newTicket.companyCode?.length === 0} onClick={() => onSaveTicket(newTicket)}>Save</Button>
        </div>
        {isProjectConnected ? (
  <Checkbox defaultChecked onChange={handleCheckboxChange} label={`Connect to project (${name} ${companyCode})`} />
) : (
  <Button onClick={onCreateProject}>Connect Project</Button>
)}
      </Modal>
      <Drawer isOpen={selectedTask !== null} overlay={true} onRequestClose={() => setSelectedTask(null)}>
        {selectedTask?.guideUrl?.includes('publish.intility.com') ?
          <PublishArticleDrawer articleId={selectedTask?.guideUrl.split('/articles/')[1] || null}/>
          :
          <div>
            <h5>{selectedTask?.request}</h5>
            <p>{selectedTask?.comment}</p>
            <a className='bf-link' target='_blank' rel='noopener noreferrer' href={selectedTask?.guideUrl}>{selectedTask?.guideUrl}</a>
          </div>
        }
      </Drawer>
    </div>
  );}

export default EditOnboardTasks;

const TaskTableRow = ({ task, toggleTask, editTask, deleteTask, setSelectedTask, setShowTicketModal, newTicket, setNewTicket, ticketReady}) => {
  const {id, name} = useSelector(state => state.company.data.info);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const getBadge = (task) => {
    if ((task?.generalTaskId === null) && (task?.taskPlatformId !== null)) {
      return <Tooltip content='Company specific task'>
        <span>
          <Badge>Custom</Badge>
        </span>
      </Tooltip>
    } else {
      return <span><Badge state={"neutral"}>Global</Badge></span>
    }
  };

  const getUrl = (sectionType) => {
    switch (sectionType)
    {
      case 0: return "";
      case 1: return "tasks";
      case 2: return "contact";
      case 3: return "billing";
      case 4: return "domains";
      case 5: return "locations";
      case 6: return "users";
      case 7: return "microsoft365";
      case 8: return "applications";
      case 9: return "communication";
      case 10: return  "computers/registration";
      case 11: return  "computers/clientDesign";
      case 12: return  "mobile-devices/Compliance Policies";
      case 13: return  "mobile-devices/Conditional Access";
      case 14: return  "mobile-devices/Apps";
      case 15: return  "printers";
      case 16: return  "documentation";
      default: return "tasks";
    };
  }

  const showTicket = () => {
    let categoryId = '11405713638944768';
    switch (task?.projectArea) {
      case 'Application': {
        categoryId = "24864898460692480";
        break;
      }
      case 'Network': {
        categoryId = "11405702310129664";
        break;
      }
      case 'CloudAppIdentity': {
        categoryId = "11405702968635392";
        break;
      }
      case 'Workplace': {
        categoryId = "20153833575407616";
        break;
      }
      case 'ProjectManagement': {
        categoryId = "11405277200642048";
        break;
      }
      default: categoryId = '11405713638944768'
    }
    setNewTicket(
      produce(newTicket, draft => {
        draft.title = draft.messageBody = `${name} - ${task?.request}`
        draft.taskId = task.id
        draft.categoryId = categoryId
        draft.ticketOwner = task?.intilityContact?.email || '';
        draft.messageBody = `${task.comment || task?.intilityContact?.email ? 'You have been assigned a task in Onboard' : 'Ticket related to Onboard task'}. <br/><br/><a target='_blank' rel='noopener noreferrer' href='https://onboard.intility.com/${id}/${getUrl(task.section)}'>Task link</a>`
      })
    );
    setShowTicketModal(true)
  }

  return (
    <Table.Row className='edit-task-table' limitExpandClick content={
      <div className='bfl-padding'>
        <Label>Comment</Label>
        <p>{task?.comment}</p>
      </div>
    }>
      <Table.Cell>
        <span style={{display: 'flex', justifyContent: 'space-between'}}>
          {task?.request}
          {getBadge(task)}
        </span>
      </Table.Cell>
      <Table.Cell><a href='#' className='bf-link' onClick={() => setSelectedTask(task)}>{task?.guideUrl?.includes('publish.intility.com') ? 'Publish article' : task?.guideUrl?.length > 0 ? 'Other link' : ''}</a>{
        task?.guideUrl?.includes('publish.intility.com') ? <a className='bf-link' style={{marginLeft: 'var(--bfs4)',display: 'inline'}} href={`https://publish.intility.com/articles/${task?.guideUrl.split('/articles/')[1]}/edit`} rel='noopener noreferrer' target='_blank'><Icon icon={faPencil} /></a>
        : task?.guideUrl?.length > 0 ? '' :
        <span style={{color: 'var(--bfc-base-c-2)'}}>No guide</span>
      }
      </Table.Cell>
      <Table.Cell>{pageDataOptions[task?.section]?.label}</Table.Cell>
      <Table.Cell>{projectAreaOptions.find(o => o.value === task?.projectArea)?.label}</Table.Cell>
      <Table.Cell>{task?.user &&
          <span style={{display: 'flex', alignItems: 'center'}}>
          {task?.user && <Badge state='success' style={{display: 'flex', alignItems: 'center'}}><img style={{height: '18px', width: '18px', borderRadius: '50%', marginRight: 'var(--bfs4)'}} src={`data:image/png;base64,${task?.user?.profilePhoto}`} alt='img' />{task?.user?.lastName}</Badge>}
        </span>
      }</Table.Cell>
      <Table.Cell>{task?.intilityContact &&
          <span style={{display: 'flex', alignItems: 'center'}}>
          {task?.intilityContact && <Badge style={{display: 'flex', alignItems: 'center', marginRight: 'var(--bfs4)'}}><img style={{height: '18px', width: '18px', borderRadius: '50%', marginRight: 'var(--bfs4)'}} src={`data:image/png;base64,${task?.intilityContact?.profilePhoto}`} alt='img' />{task?.intilityContact?.lastName}</Badge>}
        </span>
      }</Table.Cell>
      <Table.Cell>{task?.taskType === responsibleType.CUSTOMER ? 'Customer' : task?.taskType === responsibleType.INTILITY && 'Intility'}</Table.Cell>
      <Tooltip content={!ticketReady ? 'Missing CompanyCode connection on information page, or company not imported to TicketManager' : 'Publish task  in order to create ticket, Intility tasks will only be visible to Intility users'} disabled={ticketReady || ((task.generalTaskId !== undefined && task.taskPlatformId === undefined) || (task.taskPlatformId !== undefined && task.isEnabled))}>
        <Table.Cell>{task.ticketId ?
          <a className='bf-link' target='_blank' rel='noopener noreferrer' href={`${process.env.REACT_APP_TICKET_LINK}${task?.ticketId}`}><Icon className='bfc-theme' icon={faTicket}/></a> :
              <Button small={true} disabled={!ticketReady || ((task.generalTaskId === undefined && task.taskPlatformId !== undefined) || (task.taskPlatformId === undefined && task.isEnabled))} onClick={() => showTicket()}>Create</Button>
        }</Table.Cell>
      </Tooltip>
      <Table.Cell>
        {new Date(task?.dueDate)?.getFullYear() > 1 ? dateFormat('no-nb', new Date(task?.dueDate), {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit'
        }) : 'Not set'}
      </Table.Cell>
      <Table.Cell><Checkbox type={"switch"} label='Publish task' hideLabel={true} checked={(task.generalTaskId !== undefined && task.taskPlatformId === undefined) || (task.taskPlatformId !== undefined && task.isEnabled)} onChange={() => toggleTask(task)}/></Table.Cell>
      <Table.Cell>
        {(task.generalTaskId === undefined && task.taskPlatformId === undefined) ?
          <div/> :
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Dropdown onClickOutside={() => setDropdownOpen(false)} visible={dropdownOpen} content={
              <>
                <Button variant={"outline"} style={{marginRight: 'var(--bfs8)'}} onClick={() => editTask(task)}>Edit task</Button>
                <Button variant={"outline"} state={"alert"} onClick={() => {
                  if (task.generalTaskId !== undefined) {
                    dispatch(deleteOnboardTaskAsync(task.id))
                  } else {
                    deleteTask(task);
                  }
                  setDropdownOpen(false);
                }}>Delete</Button>
              </>
            } variant='border'>
              <Icon icon={faEllipsisH} style={{cursor: 'pointer'}} onClick={() => setDropdownOpen(!dropdownOpen)}/>
            </Dropdown>
          </div>
        }
      </Table.Cell>
    </Table.Row>
  );
}
