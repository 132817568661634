
export const LocationState = {
    UNMANAGED: "Unmanaged",
    MANAGED: "Managed",
    TERMINATING: "Terminating",
    INDEPLOYMENT: "InDeployment",
    TERMINATED: "Terminated",
}

export const LocationStateOptions = [
  {label: 'Unmanaged', value: LocationState.UNMANAGED},
  {label: 'Managed', value: LocationState.MANAGED},
  {label: 'Terminating', value: LocationState.TERMINATING},
  {label: 'InDeployment', value: LocationState.INDEPLOYMENT},
  {label: 'Terminated', value: LocationState.TERMINATED},
];

export default LocationStateOptions
