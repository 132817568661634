import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  fetchDetails,
  fetchDetailsError,
  fetchFinancialDetails,
  fetchFinancialDetailsError,
  updateDetails,
  updateDetailsError
} from './detailReducers';
import {fetchDetailsSuccess, fetchFinancialDetailsSuccess} from './detailActions';
import produce from 'immer';
import {emptyGuid} from '../../../utils/guid';

export const fetchDetailsAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.detailsPlatform.detailStatus;
  if (fetching) return null;
  dispatch(fetchDetails());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/details`,
    method: 'GET'
  }).then(details => dispatch(fetchDetailsSuccess(details)))
    .catch(error => dispatch(fetchDetailsError(error)));
};

export const fetchFinancialDetailsAsync = companyId => (dispatch, getState) => {
  const {fetchingFinancial} = getState().company.data.detailsPlatform.detailStatus;
  if (fetchingFinancial) return null;
  dispatch(fetchFinancialDetails());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/details/financial`,
    method: 'GET'
  }).then(details => dispatch(fetchFinancialDetailsSuccess(details)))
    .catch(error => dispatch(fetchFinancialDetailsError(error)));
};

export const fetchDetailAsync = (companyId, name) => (dispatch, getState) => {
  const {fetching} = getState().company.data.detailsPlatform.detailStatus;
  if (fetching) return null;
  dispatch(fetchDetails());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/detail?name=${name}`,
    method: 'GET'
  }).then(details => dispatch(fetchDetailsSuccess(details)))
    .catch(error => dispatch(fetchDetailsError(error)));
};

export const addDetailAsync = (name, collectionName, dispatch, computerSetupId) => (dispatch, getState) => {
  const {updating} = getState().company.data.detailsPlatform.detailStatus;
  if (updating) return null;
  dispatch(updateDetails());

  const {id} = getState().company.data.info;

  const body = {
    name: name,
    type: collectionName,
    detailsPlatformId: computerSetupId
  };

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/details`,
    method: 'POST',
    body: body
  })
    .then(() => dispatch(fetchDetailsAsync(id)))
    .catch(error => dispatch(updateDetailsError(error)));
};

export const multiPutAsync = detailList => (dispatch, getState) => {
  const {updating} = getState().company.data.detailsPlatform.detailStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;
  const platformId = getState().company.data.detailsPlatform.information.id;
  dispatch(updateDetails());

  const body = detailList.map(detail => {
    return produce(detail, draft => {
      draft.detailsPlatformId = platformId || emptyGuid;
    });
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/details`,
    method: 'PUT',
    body: body
  })
    .then(() => dispatch(fetchDetailsAsync(id)))
    .catch(error => dispatch(updateDetailsError(error)));
};

export const putDetailAsync = detail => (dispatch, getState) => {
  const {updating} = getState().company.data.detailsPlatform.detailStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;
  const platformId = getState().company.data.detailsPlatform.information.id || emptyGuid;
  dispatch(updateDetails());

  const body = produce(detail, draft => {
    draft.detailsPlatformId = platformId;
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/details/${platformId}`,
    method: 'PUT',
    body: body
  })
    .then(() => dispatch(fetchDetailsAsync(id)))
    .catch(error => dispatch(updateDetailsError(error)));
};

export const deleteDetailAsync = software => (dispatch, getState) => {
  const {updating} = getState().company.data.detailsPlatform.detailStatus;
  if (updating) return null;
  dispatch(updateDetails());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/details/${software.id}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchDetailsAsync(id)))
    .catch(error => dispatch(updateDetailsError(error)));
};
