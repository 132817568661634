import { Icon } from "@intility/bifrost-react";
import './overviewCard.scss';
import PropTypes from "prop-types";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";


export const MoneyCard = ({ header, icon, estimated, actual, range }) => {

  return (
    <div className={"money-card"}>
      <div className='card-header'>
        <Icon icon={icon}/>
        <p className='bf-strong bf-small'>{header}</p>
        {range &&
          <div>
            <p className='bf-small'>From week {range[0]?.week} in {range[0]?.year}</p>
            <p className='bf-small'> - </p>
            <p className='bf-small'>to week {range[range.length - 1]?.week} in {range[range.length - 1]?.year}</p>
          </div>
        }
      </div>
      <div className='card-body'>
        <div>
          <h1>{actual}</h1>
          <p className='bf-small'>Installed (NOK)</p>
        </div>
        <div className='separator'/>
        <div>
          <h1>{estimated}</h1>
          <p className='bf-small'>Predicted (NOK)</p>
        </div>
      </div>
    </div>
  )
}

export const CustomerCard = ({ header, icon, company, money}) => {
  const history = useHistory();

  return (
    <div className='overview-card'>
      <div className='finance-header'>
        <Icon icon={icon}/>
        <p className='bf-strong bf-small'>{header}</p>
      </div>
      <div className='card-body'>
        <div>
          <h5 className='bf-link' onClick={() => history.push(`/${company.id}`)}>{company?.name}</h5>
          <p className='bf-small'>{money?.toLocaleString()} kr/mo</p>
        </div>
      </div>
    </div>
  )
}

export const SettingsCard = ({ header, icon, tasks, devices }) => {
  const history = useHistory();

  return (
    <div className='settings-card' onClick={() => history.push('/overview/settings/globalTasks')}>
      <div className='card-header'>
        <Icon icon={icon}/>
        <span>
          <p className='bf-strong bf-small'>{header}</p>
          <Icon icon={faArrowRight}/>
        </span>
      </div>
      <div className='card-body'>
        <div>
          <h1>{tasks?.length}</h1>
          <p className='bf-small'>Global tasks</p>
        </div>
        <div className='separator'/>
        <div>
          <h1>{devices}</h1>
          <p className='bf-small'>Devices</p>
        </div>
      </div>
    </div>
  )
}

export const OverviewCard = ({ header, icon, value, prefix }) => {
  return (
    <div className='overview-card'>
      <div className='card-header'>
        <p className='bf-strong bf-small'>{header}</p>
        <Icon icon={icon}/>
      </div>
      <div className='card-body'>
        <h1 style={prefix ? {marginRight: '8px'} : undefined}>{value}</h1>
        {prefix &&
          <p className='bf-strong bf-small'>{prefix}</p>
        }
      </div>
    </div>
  )
}

OverviewCard.propTypes = {
  header: PropTypes.string,
  icon: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
  prefix: PropTypes.string
}

export default OverviewCard