import PropTypes from "prop-types";
import '../LocationSystems/locationSystemAccordion.scss';
import React from "react";
import { Accordion, Badge, Button, Icon } from "@intility/bifrost-react";
import tabSlash from '../../../../../../../assets/images/location/tabSlash.svg';
import tabSlashDark from '../../../../../../../assets/images/location/tabSlashDark.svg';
import castSlash from '../../../../../../../assets/images/location/castSlash.svg';
import castSlashDark from '../../../../../../../assets/images/location/castSlashDark.svg';
import {
  faDisplay, faDisplaySlash,
  faPencil, faScreencast, faTablet,
  faVideo,
  faVideoSlash
} from "@fortawesome/pro-regular-svg-icons";

const MeetingRoomAccordion = ({location, editLocation, activeAccordion, accordionName, accordionTitle, handleChange, isDisabled}) => {
  const floors = [...new Set(location?.meetingRooms?.map(m => m.floor?.trim()))].sort();

  return (
    <Accordion.Item
      title={accordionTitle || accordionName}
      actions={location?.meetingRooms?.length === 0 ?
        <Button onClick={editLocation}
                state={isDisabled ? 'inactive' : 'default'}
                style={{marginTop: '8px', marginRight: '8px'}}
                variant='filled' small >Add information</Button>
        :
        <Accordion.Action
          icon={faPencil}
          style={isDisabled ? { color: 'var(--bfc-neutral)' } : undefined}
          onClick={editLocation}
          aria-label='Edit'
        />}
      active={activeAccordion.includes(accordionName)}
      onClick={() => handleChange(accordionName)}
    >
      <div className='location-system-accordion'>
        {floors?.map((f, i) => {
          const rooms = location?.meetingRooms?.filter(m => m.floor?.trim() === f);
          return (
            <div className='meeting-room-floor' key={i}>
              <div className='meeting-room-header'>
                <p className='bf-large bf-strong'>{f}.floor</p>
                <p className='bf-em'>{rooms?.length} meeting rooms</p>
              </div>
              <div className='meeting-room-floor-content bfl-autocol'>
                {
                  rooms?.map(c => {
                    return (
                      <div className='meeting-room-floor-content-item' key={c?.id}>
                        <p className='bf-strong'>{c?.name} {c?.software && `(${c?.software})`}</p>
                        <p>{c?.sharedCalendarUpn}</p>
                        <p>{c?.additionalInformation || '-'}</p>
                        <div>
                          <Badge state={c?.bookingPanel ? "chill" : 'neutral'}>
                            {c?.bookingPanel ?
                              <Icon icon={faTablet}/>
                              : [
                                  <img key='light' className='bf-light-only' src={tabSlash} alt='Tab'/>,
                                  <img key='dark' className='bf-dark-only' src={tabSlashDark} alt='Tab'/>
                                ]
                            }
                            {c?.bookingPanel || 'No booking panel'}
                          </Badge>
                        </div>
                        <div>
                          <Badge state={c.videoConference ? "chill" : 'neutral'}>
                            <Icon icon={c.videoConference ? faVideo : faVideoSlash}/>
                            {c?.videoConference || 'No video conference'}
                          </Badge>
                        </div>
                        <div>
                          <Badge state={c.screenAndSignage ? "chill" : 'neutral'}>
                            <Icon icon={c.screenAndSignage ? faDisplay : faDisplaySlash}/>
                            {c?.screenAndSignage || 'No screen or signage display'}
                          </Badge>
                        </div>
                        <div>
                          <Badge state={c.castUnit ? "chill" : 'neutral'}>
                            {c?.castUnit ?
                              <Icon icon={faScreencast}/>
                              : [
                                <img key='light' className='bf-light-only' src={castSlash} alt='Cast'/>,
                                <img key='dark' className='bf-dark-only' src={castSlashDark} alt='Cast'/>
                              ]
                            }
                            {c?.castUnit || 'No casting device'}
                          </Badge>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })}
      </div>
    </Accordion.Item>
  )

}

MeetingRoomAccordion.propTypes = {
  location: PropTypes.object,
  contactList: PropTypes.array,
  activeAccordion: PropTypes.array,
  accordionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  accordionName: PropTypes.string,
  handleChange: PropTypes.func,
  editLocation: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default MeetingRoomAccordion