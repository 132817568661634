import { Badge, Label } from "@intility/bifrost-react";
import React from "react";
import './boxItem.scss';
import PropTypes from "prop-types";

export const ApplicationBoxItem = ({ name, value }) => {

  return (
    <div id='application-box-item'>
    <Label>{name}</Label>
      {value ?
        <p className='bf-large'>{value}</p>
        :
        <Badge state={"warning"}>Missing information</Badge>
      }
    </div>
  );

};

ApplicationBoxItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any
}
