import {
  fetchMemberRoles,
  fetchMemberRolesError,
  fetchProjectGlobalTeams,
  fetchProjectGlobalTeamsError,
  updateMemberRole,
  updateMemberRoleError,
  updateProjectGlobalTeams,
  updateProjectGlobalTeamsError
} from './projectGlobalTeamsReducers';
import apiFetch, {apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  addMemberRoleSuccess,
  addProjectGlobalTeamSuccess,
  deleteProjectGlobalTeamSuccess,
  fetchMemberRolesSuccess,
  fetchProjectGlobalTeamsSuccess,
  updateProjectGlobalTeamSuccess
} from './projectGlobalTeamsActions';
import {emptyGuid} from '../../../utils/guid';
import produce from 'immer';

export const fetchProjectGlobalTeamsAsync = () => (dispatch, getState) => {
  const {fetching} = getState().company.data.projectGlobalTeams.projectGlobalTeamStatus;
  if (fetching) return null;
  dispatch(fetchProjectGlobalTeams());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/global`,
    method: 'GET'
  }).then(teams => dispatch(fetchProjectGlobalTeamsSuccess(teams)))
    .catch(error => dispatch(fetchProjectGlobalTeamsError(error)));
};

export const addProjectGlobalTeamAsync = (team) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectGlobalTeams.projectGlobalTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectGlobalTeams;
  dispatch(updateProjectGlobalTeams());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/global`,
    method: 'POST',
    body: team
  }).then(team => dispatch(addProjectGlobalTeamSuccess(team, list)))
    .catch(error => dispatch(updateProjectGlobalTeamsError(error)));
};

export const deleteProjectGlobalTeamAsync = (teamId) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectGlobalTeams.projectGlobalTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectGlobalTeams;
  dispatch(updateProjectGlobalTeams());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/${teamId}/global`,
    method: 'DELETE'
  })
    .then(() => {
      dispatch(deleteProjectGlobalTeamSuccess(teamId, list));
    })
    .catch(error => dispatch(updateProjectGlobalTeamsError(error)));
};

export const updateProjectGlobalTeamAsync = (team) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectGlobalTeams.projectGlobalTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectGlobalTeams;
  dispatch(updateProjectGlobalTeams());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/${team.id}/global`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: team
  }).then(team => dispatch(updateProjectGlobalTeamSuccess(team, list)))
    .catch(error => dispatch(updateProjectGlobalTeamsError(error)));
};

export const updateProjectGlobalTeamLeadAsync = (team, roleId) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectGlobalTeams.projectGlobalTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectGlobalTeams;
  dispatch(updateProjectGlobalTeams());

  const body = produce(team, draft => {
    draft.teamManager = roleId;
  });
  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/${team.id}/projectLead/global`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: body
  }).then(team => dispatch(updateProjectGlobalTeamSuccess(team, list)))
    .catch(error => dispatch(updateProjectGlobalTeamsError(error)));
};

export const fetchGlobalRolesAsync = () => (dispatch, getState) => {
  const {fetchingRoles} = getState().company.data.projectGlobalTeams.projectGlobalTeamStatus;
  if (fetchingRoles) return null;
  dispatch(fetchMemberRoles());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/roles/global`,
    method: 'GET'
  }).then(roles => dispatch(fetchMemberRolesSuccess(roles)))
    .catch(error => dispatch(fetchMemberRolesError(error)));
};

export const addGlobalRoleAsync = (role) => (dispatch, getState) => {
  const {updatingRoles} = getState().company.data.projectGlobalTeams.projectGlobalTeamStatus;
  if (updatingRoles) return null;
  const {roles} = getState().company.data.projectGlobalTeams;
  dispatch(updateMemberRole());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/roles/global`,
    method: 'POST',
    body: role
  }).then(role => dispatch(addMemberRoleSuccess(role, roles)))
    .catch(error => dispatch(updateMemberRoleError(error)));
};

