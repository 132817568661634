import produce from "immer";
import { Accordion, Badge, Button, Checkbox, Icon, useFloatingMessage } from "@intility/bifrost-react";
import BoxItem from "../../../../BoxItem/BoxItem";
import { faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Flex from "../../../../Flex/Flex";
import { GetVendorRole } from "./NewContent/NewContact";
import PropTypes from "prop-types";
import React from "react";
import { contactType } from "../ApplicationTypes";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { getInternalTitle, getVendorTitle } from "./ContactTitles";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import './contactList.scss';

export const VendorContacts = ({contacts, setEditContact, setOpen, setType, editing, relevant, onRelevantChange}) => {
  const setVendor = () => {
    setType(contactType.VENDOR);
  };

  return (
    <div className='application-contact-box'>
      <div className='box-header'>
        <h3>Vendor contacts {(editing && relevant !== 2 && contacts?.length === 0) &&
          <Badge state="warning">Missing info</Badge>}</h3>
        {(editing && relevant !== 2) ?
          <Button
            small={true}
            icon={faPlus}
            onClick={() => {
              setOpen(true);
              setVendor();
            }}
          >
            Add new contact
          </Button> : (!editing && relevant !== 2 && contacts?.length === 0) && <Badge state='warning'>Missing info</Badge>
        }
      </div>
      {relevant !== 2 ?
        <p>Please list the relevant contacts from the vendor</p> :
        <p className='bf-large' style={{color: 'var(--bfc-base-c)'}}>N/A</p>
      }
      {editing &&
        <Checkbox style={{ marginBottom: 'var(--bfs8)' }} label='Not relevant' checked={relevant === 2} onChange={e => onRelevantChange(e, 'vendorStatus', 0, 2)} />
      }
      {relevant !== 2 &&
        <ContactList
          contacts={contacts}
          setOpen={setOpen}
          setEditContact={setEditContact}
          getTitle={getVendorTitle}
          setType={setVendor}
          editing={editing}
        />
      }
    </div>
  );
};

VendorContacts.propTypes = {
  contacts: PropTypes.array,
  setEditContact: PropTypes.func,
  setOpen: PropTypes.func,
  setType: PropTypes.func,
  relevant: PropTypes.number,
  onRelevantChange: PropTypes.func,
  editing: PropTypes.bool
};

export const InternalContacts = ({contacts, setEditContact, setOpen, setType, editing, relevant, onRelevantChange}) => {
  const setInternal = () => {
    setType(contactType.INTERNAL);
  };

  return (
    <div className='application-contact-box'>
      <div className='box-header'>
        <h3>Internal contacts {(editing && relevant !== 2 && contacts?.length === 0) &&
          <Badge state="warning">Missing info</Badge>}</h3>
        {(editing && relevant !== 2) ?
          <Button
            small={true}
            icon={faPlus}
            onClick={() => {
              setOpen(true);
              setInternal();
            }}
          >
            Add new contact
          </Button> : (!editing && relevant !== 2 && contacts?.length === 0) && <Badge state='warning'>Missing info</Badge>
        }
      </div>
      {relevant !== 2 ?
        <p>Please list the relevant contacts from the vendor</p> :
        <p className='bf-large' style={{color: 'var(--bfc-base-c)'}}>N/A</p>
      }
      {editing &&
        <Checkbox style={{marginBottom: 'var(--bfs8)'}} label='Not relevant' checked={relevant === 2} onChange={e => onRelevantChange(e,'internalStatus', 0, 2)}/>
      }
      {relevant !== 2 &&
        <ContactList
          contacts={contacts}
          setOpen={setOpen}
          setEditContact={setEditContact}
          getTitle={getInternalTitle}
          setType={setInternal}
          editing={editing}
        />
      }
    </div>
  );
};

InternalContacts.propTypes = {
  contacts: PropTypes.array,
  setEditContact: PropTypes.func,
  setOpen: PropTypes.func,
  setType: PropTypes.func,
  relevant: PropTypes.number,
  onRelevantChange: PropTypes.func,
  editing: PropTypes.bool
};

export const ContactList = ({contacts, setEditContact, setOpen, setType, getTitle, editing, deletable, deleteObject, setDeleteObject}) => {
  const { showFloatingMessage } = useFloatingMessage();

  const deleteContact = contact => {
    const index = contacts.findIndex(a => a?.email === contact?.email && a.phoneNumber === contact.phoneNumber)
    setDeleteObject(produce(deleteObject, draft => {
      draft?.internalContacts.splice(index, 1);
    }));
  };

  return (
    <Accordion>
      {contacts?.map((c, i) => {
        return (
          <Accordion.Item className='accordion-item' actions={
            <Accordion.Action
              icon={faCopy}
              onClick={() => {
                navigator.clipboard.writeText(`${c?.firstName} ${c?.lastName}`);
                showFloatingMessage("Contact name copied.");
              }}
              aria-label='Edit'
            />
          } key={i} title={getTitle(c, 2)}>
            <div className='box-content'>
              <BoxItem label='Phone' value={c?.phoneNumber} />
              <BoxItem label='E-mail' value={c?.email} />
              {editing ?
                <Icon
                  icon={faPencil}
                  onClick={() => {
                    setEditContact(c);
                    setOpen(true);
                    setType();
                  }}
                /> :
                <div/>
              }
              {deletable &&
                <Icon
                  style={{color: 'var(--bfc-alert)'}}
                  icon={faTrashAlt}
                  onClick={() => deleteContact(c)}
                />
              }
            </div>
            {c?.contactRoles?.length > 2 && (
              <Flex>
                <div className='accordion-badge-container'>
                  {c.contactRoles.slice(2).map((r, i) => {
                    return (
                      <Badge key={i} state='neutral'>
                        {GetVendorRole(r?.contactRole)}
                      </Badge>
                    );
                  })}
                </div>
              </Flex>
            )}
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array,
  setEditContact: PropTypes.func,
  getTitle: PropTypes.func,
  setOpen: PropTypes.func,
  setType: PropTypes.func,
  editing: PropTypes.bool,
  deletable: PropTypes.bool,
  setDeleteObject: PropTypes.func,
  deleteObject: PropTypes.object
};