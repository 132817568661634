import {immerable} from 'immer';
import {emptyGuid} from '../guid';

export class Project {
  [immerable] = true;

  title = '';
  description = '';
  ownerGuid = '';
  startedOn = '';
  endedOn = '';
  impactsAll = false;
  companyGuid = emptyGuid;
}

export class Contact {
  [immerable] = true;
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  companyId = emptyGuid;
}

export class VendorContact {
  [immerable] = true;
  vendorName = '';
  contactable = false;
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  companyId = emptyGuid;
}

export class LocationSystemContact {
  [immerable] = true;
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  systemType = 0;
  systemResponsibilities = '';
  companyId = emptyGuid;
  locationId = emptyGuid;
};

export class LocationSystemItem {
  [immerable] = true;
  id = emptyGuid;
  name = '';
  systemType = 0;
  itemType = 0;
  amount = 0;
  companyId = emptyGuid;
  locationId = emptyGuid;
}

export class LocationMeetingRoom {
  [immerable] = true;
  id = emptyGuid;
  name = '';
  floor = '';
  videoConference = '';
  bookingPanel = '';
  screenAndSignage = '';
  castUnit = '';
  sharedCalendarUpn = '';
  software = '';
  additionalInformation = '';
  companyId = emptyGuid;
  locationId = emptyGuid;
}

export class LocationCompany {
  [immerable] = true;
  name = ''
  address = ''
  zipCode = ''
  postalPlace = ''
  country = '';
  companyId = emptyGuid;
}

export const detailType = {
  STRING: 'stringValue',
  INT: 'intValue',
  BOOL: 'boolValue',
  DATE: 'dateValue'
};

export const departmentType = {
  GENERAL: 'General',
  FINANCE: 'Finance',
  LOGISTICS: 'Logistics',
  IDENTITYANDMS: 'IdentityAndMs',
  NETWORK: 'Network',
  WORKPLACE: 'Workplace',
  APPLICATIONS: 'Applications'
};

export class Detail {
  [immerable] = true;
  name = '';
  intValue = 0;
  stringValue = '';
  dateValue = null;
  boolValue = false;
  valueType = '';
  department = '';
  detailsPlatformId = emptyGuid;
}

export class WorkplaceDevice {
  [immerable] = true;
  type = '';
  model = '';
  sku = '';
}

export class ApplicationFilter {
  [immerable] = true;
  filterType = '';
  parameterType = '';
  value = '';
}

export class TeamMember {
  [immerable] = true;
  id = emptyGuid;
  givenName = '';
  surname = '';
  userPrincipalName = '';
  mobilePhone = '';
  profilePhoto = '';
}

export class TeamRole {
  [immerable] = true;
  id = emptyGuid;
  name = '';
  teamId = emptyGuid;
  memberId = null;
  member = null;
  complexity = 0;
}

export class GlobalTeamRole {
  [immerable] = true;
  id = emptyGuid;
  name = '';
  teamId = emptyGuid;
}

export class MemberRole {
  [immerable] = true;
  name = '';
  isGlobal = false;
  companyId = null;
}

export class TeamObject {
  [immerable] = true;
  id = emptyGuid;
  name = '';
  isEnabled = true;
  teamManager = emptyGuid;
  teamRoles = [];
}

export class GlobalTeamObject {
  [immerable] = true;
  id = emptyGuid;
  name = '';
  teamManager = emptyGuid;
  teamRoles = [];
}

export class ApplicationContact {
  [immerable] = true;
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  contactRoles = [];
}

export class ApplicationIntegration {
  [immerable] = true;
  applicationRelationType = 0;
  parentId = emptyGuid;
  childId = emptyGuid;
}

export class Domain {
  [immerable] = true;
  name = '';
  registrar = '';
  main = false;
  isEmailDomain = false;
  isWebDomain = false;
  moveToIntility = false;
  domainContact = new Contact();
  domainStatus = new DomainStatus();
}

export class DomainStatus {
  [immerable] = true;
  domainId = emptyGuid;
  establishedDialogue = 0;
  scheduledTransfer = 0;
  comments = [];
}

export class DomainComment {
  [immerable] = true;
  comment = '';
  username = '';
  profilePhoto = '';
  domainStatusId = emptyGuid;
}

export class ApplicationStatus {
  [immerable] = true;
  applicationId = emptyGuid;
  appInfoSent = 0;
  onboardApp = true;
  installation = 0;
  serverStaged = 0;
  solutionDesignProgress = 0;
  testing = 0;
  comments = [];
}

export class ApplicationComment {
  [immerable] = true;
  comment = '';
  username = '';
  profilePhoto = '';
  applicationStatusId = emptyGuid;
}

export class Location {
  [immerable] = true;
  name = '';
  address = '';
  zipCode = '';
  postalPlace = '';
  country = '';
  office365Language = '';
  userCount = 0;
  locationContact = new Contact();
  financialContact = new Contact();
  recipientContact = new Contact();
  recipientCompany = new LocationCompany();
  legalCompany = new LocationCompany();
  floorPlans = [];
  isp = '';
  eoriNumber = '';
  lineNumber = '';
  secondaryLineNumber = '';
  leaseTime = '';
  comlinesConnection = '';
  wifiCoverage = '';
  mainPurpose = '';
  intilityManaged = 0;
  isThirdPartyShared = 0;
  hasEnoughCabledConnections = 0;
  locationSystemItems = [];
  meetingRooms = [];
  locationSystemDocumentations = [];
  locationSystemContacts = [];
  billingId = emptyGuid;
  networkSystemId = emptyGuid;
  locationPlatformId = emptyGuid;
}

export class GptLocation {
  [immerable] = true;
  name = '';
  address = '';
  zipCode = '';
  postalPlace = '';
  country = '';
  contactFirstName = '';
  contactLastName = '';
  contactEmail = '';
  contactPhoneNumber = '';
}
export class GptLocationDescriptions {
  [immerable] = true;
  name = 'Name of location';
  address = 'location address';
  zipCode = 'location zip code / postal code';
  postalPlace = 'location postal place';
  country = 'location country';
  contactFirstName = 'first name / given name of location contact or contact associated with location';
  contactLastName = 'last name / surname of location contact or contact associated with location';
  contactEmail = 'email of location contact or contact associated with location';
  contactPhoneNumber = 'phone number of location contact or contact associated with location, if no country code, add country code based on country field';
}

export class Application {
  [immerable] = true;
  applicationPlatformId = emptyGuid;
  parentApplicationId = emptyGuid;
  name = '';
  vendor = '';
  identity = '';
  environment = '';
  location = '';
  url = '';
  currentVersion = '';
  newVersion = '';
  description = '';
  comment = '';
  appSpecificCred = 0;
  browserAccess = 0;
  softwareLicense = 0;
  saasApp = 0;
  virtualClient = 0;
  serverApp = 0;
  clientApp = 0;
  readyStatus = 0;
  vendorStatus = 0;
  internalStatus = 0;
  integrationStatus = 0;
  origin = 0;
  singleSignOnToday = '';
  softwareId = 0;
  contacts = [];
  childApplications = [];
  parentApplications = [];
  applicationStatus = new ApplicationStatus();
}
export class GptApplication {
  [immerable] = true;
  name = '';
  vendor = '';
  description = '';
  environment = '';
  version = '';
  type = '';
}

export class GptApplicationDescriptions {
  [immerable] = true;
  name = 'Name of application';
  vendor = 'Application vendor, who delivers/maintains the application';
  description = 'Description of application, short text';
  environment = 'Application environment, can be Production, Development, Testing or a combination';
  version = 'Application version, typically in the form of a number';
  type = 'Type of application, can be SaaS, Client, Server or a combination of these';
}

export class CompleteApplication {
  [immerable] = true;
  applicationPlatformId = emptyGuid;
  parentApplicationId = emptyGuid;
  name = '';
  vendor = '';
  nickname = '';
  url = '';
  newVersion = '';
  currentVersion = '';
  versionIsSame = false;
  activeDirectory = false;
  appSpecificUser = false;
  azureAd = false;
  browserAccess = 0;
  virtualClient = 0;
  clientApp = 0;
  saasApp = 0;
  serverApp = 0;
  applicationRelations = [];
  internalContacts = [];
  vendorContacts = [];
  comment = '';
  softwareLicense = 0;
  readyStatus = 0;
  origin = 0;
  singleSignOnToday = '';
  softwareId = 0;
  deliveryNotRelevant = false;
  vendorStatus = 0;
  internalStatus = 0;
  integrationStatus = 0;
  loginNotRelevant = false;
  versionNotRelevant = false;
  extraNotRelevant = false;
  location = '';
  environment = '';
  productId = null;
}

export class CompanyUserApplication {
  [immerable] = true;
  softwareId = 0;
  companyUserEmail = '';
  companyId = emptyGuid;
  companyUserId = emptyGuid;
  applicationId = emptyGuid;
}

export class Communication {
  [immerable] = true;
  note = '';
}

export class ComputerSetup {
  [immerable] = true;
  iamsMacUrl = '';
  screensaverTimeout = 10;
  DefaultBackground = 0;
  IamsMacUrl = '';
}

export class Billing {
  [immerable] = true;
  address = '';
  zipCode = '';
  postalPlace = '';
  country = '';
  name = '';
  organizationNumber = '';
  email = '';
  invoiceRef = '';
  currency = '';
  main = false;
}

export class CompanyUser {
  [immerable] = true;
  billingCompany = '';
  billingId = '';
  location = '';
  language = '';
  country = '';
  firstName = '';
  lastName = '';
  userName = '';
  primaryEmailAddress = '';
  mail = '';
  mobilePhone = '';
  department = '';
  displayName = '';
  officePhone = '';
  title = '';
  physicalOffice = '';
  streetAddress = '';
  postalCode = '';
  city = '';
  state = '';
  adCompany = '';
  description = '';
  oldSam = '';
  newSam = '';
  oldSid = '';
  newSid = '';
  employeeId = '';
  locationId = '';
  comment = '';
  userType = 0;
  intilityUserType = 0;
  accountEnabled = true;
  iamsUsers = [];
}

export class GptUser {
  [immerable] = true;
  firstName = '';
  lastName = '';
  userName = '';
  primaryEmailAddress = '';
  mail = '';
  mobilePhone = '';
  location = '';
  country = '';
  department = '';
  officePhone = '';
  title = '';
  physicalOffice = '';
  streetAddress = '';
  postalCode = '';
  city = '';
  description = '';
  comment = '';
}

export class GptUserDescriptions {
  [immerable] = true;
  firstName = 'First name / given name of user';
  lastName = 'last name / surname of user';
  userName = 'username connected to user';
  primaryEmailAddress = 'users primary email address or upn / user principal name';
  mail = 'users mail';
  mobilePhone = 'users mobile phone number';
  location = 'name of location connected to user';
  country = 'name of country connected to user';
  department = 'department user is related to / works in';
  officePhone = 'users office phone number';
  title = 'users work related title';
  physicalOffice = 'physical office name';
  streetAddress = 'street address / address connected to user';
  postalCode = 'postal code / zip code connected to user';
  city = 'city / town connected to user';
  description = 'description of user, like usertype and function';
  comment = 'any comments related to the account / user';
}

export class IamsUser {
  [immerable] = true;
  computerName = '';
  oldUsername = '';
  companyId = emptyGuid;
  companyName = '';
  locationName = '';
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  comment = '';
  operatingSystem = '';
  manufacturer = '';
  model = '';
  serial = '';
  prosessor = '';
  memory = 0;
  graphicsCards = '';
  warranty = '';
  purchasedDate = '';
  companyUserMatch = '';
  locationId = emptyGuid;
  companyUserId = emptyGuid;
}

export class Printer {
  [immerable] = true;
  manufacturer = ''
  model = ''
  securePrintStatus = 0
  guestPrintStatus = 0
  serialNumber = ''
  macAddress = ''
  room = ''
  floor = ''
  applications = []
  locationId = null;
}

export class Company {
  [immerable] = true;
  name = '';
  deploymentDate = null;
  onboardedDate = null;
  iamsId = '';
}

export class CompanyLayout {
  [immerable] = true;
  billingPlatform = 0;
  domainPlatform = 0;
  locationPlatform = 0;
  userPlatform = 0;
  applicationPlatform = 0;
  communicationPlatform = 0;
  iamsUserPlatform = 0;
  computerSetupPlatform = 0;
  mobilePlatform = 0;
  printerPlatform = 0;
  documentationPlatform = 0;
  microsoft365Platform = 0;
}

export class OnboardTask {
  [immerable] = true;
  complete = false;
  taskType = 0;
  section = 1;
  request = '';
  guideUrl = '';
  comment = '';
  projectArea = '';
  responsible = 0;
  userId = null;
  intilityContactId = null;
  generalTaskId = null;
  ticketId = null;
  dueDate = null;
  taskPlatformId = emptyGuid;
}

export class OnboardTicket {
  [immerable] = true;
  title = '';
  categoryId = "11405713638944768";
  typeId = 6;
  isInternal = true;
  companyCode = '';
  ticketOwner = '';
  messageBody = '';
  taskId = emptyGuid;
  processId = '';
  processTypeGuid = "6ff1d446-0334-46d1-8144-ecb4c87185cb";
}

export class GlobalTask {
  [immerable] = true;
  priority = 0;
  taskType = 0;
  deadlineStartDays = 0;
  defaultEnabled = false;
  section = 0;
  guideUrl = '';
  request = '';
  projectArea = '';
}

export class IntilityService {
  [immerable] = true;
  isActive = false;
  isEnabled = true;
  articleId = emptyGuid;
  globalIntilityServiceId = null;
  intilityServicePlatformId = emptyGuid;
}

export class GlobalIntilityService {
  [immerable] = true;
  isActive = true;
  isEnabled = true;
  articleId = emptyGuid;
}
