import React, {useEffect, useState} from 'react';
import './smartNavigation.scss';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {setView} from 'redux/viewSlice';
import {EditPermission} from 'components/Permissions';
import {Checkbox, Icon, Nav, useApplyTheme, useNav} from '@intility/bifrost-react';
import {
  faBuilding,
  faCog,
  faCoins,
  faPeopleGroup,
  faPhoneLaptop,
  faRightFromBracket,
  faTrashCan,
  faUser
} from '@fortawesome/pro-regular-svg-icons';
import {companyReset} from '../../redux/company/companyUpdateSlice';
import PropTypes from 'prop-types';
import overviewData from '../../utils/hub/pageData/overviewData';
import tasksData from '../../utils/hub/pageData/taskData';
import contactData from '../../utils/hub/pageData/contactData';
import billingData from '../../utils/hub/pageData/billingData';
import domainsData from '../../utils/hub/pageData/domainsData';
import locationsData from '../../utils/hub/pageData/locationsData';
import microsoft365Data from '../../utils/hub/pageData/microsoft365Data';
import applicationsData from '../../utils/hub/pageData/applicationsData';
import communicationData from '../../utils/hub/pageData/communicationData';
import computersData, {CustomData} from '../../utils/hub/pageData/hardwareData';
import usersData from '../../utils/hub/pageData/usersData';
import commentData from '../../utils/hub/pageData/commentData';
import intilityServicesData from '../../utils/hub/pageData/intilityServicesData';
import printersData from '../../utils/hub/pageData/printersData';
import mobileDevicesData from 'utils/hub/pageData/mobileDevicesData';
import teamsLogo from '../../assets/images/software_logos/teams2.png';
import {IntilityPermission} from '../Permissions';
import PageState from '../../utils/hub/pageState';
import pageData from '../../utils/globalSettings';
import useIntilityPermission from '../../hooks/useIntilityPermission';
import contactUsData from '../../utils/hub/pageData/contactUsData';
import useLocalStorageState from 'use-local-storage-state';
import onboardLogo from '../../../src/assets/images/Login/Onboard-login-logo.png';


const wipeCache = () => {
  localStorage.clear();
  window.location.reload();
};

const AppName = ({name}) => {
  const {sideCollapsed} = useNav();
  const shortName = name
    ?.toUpperCase()
    ?.match(/\b(\w)/g)
    ?.join('');
  const appName = sideCollapsed ? shortName : name;
  return (
    <div className='bf-sidebar-appname' title={name}>
      {appName}
    </div>
  );
};

AppName.propTypes = {
  name: PropTypes.string
};

const SmartNavigation = ({logout, children}) => {
  const {user} = useSelector(state => state.auth);
  const {data} = useSelector(state => state.companies);
  const {info} = useSelector(state => state.company.data);
  const {data: companyData} = useSelector(state => state.company);
  const {information} = useSelector(state => state.company.data.detailsPlatform);
  const [locValue, setLocValue] = useState('');
  const view = useSelector(state => state.view);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const intilityUser = useIntilityPermission();
  const [theme, setTheme] = useLocalStorageState('bfTheme', {
    defaultValue: 'system'
  });
  const [sideCollapsed, setSideCollapsed] = useLocalStorageState('bfCollapsed', {
    defaultValue: false
  });

  useApplyTheme(theme);

  const globalPages = pageData;

  useEffect(() => {
    if (location.pathname?.startsWith('/overview/')) return;
    if (location.pathname?.startsWith('/details/') && locValue.startsWith('/details/')) {
      setLocValue(location.pathname);
    } else if (location.pathname?.startsWith(`/${info.id}/`) && locValue.startsWith(`/${info.id}/`)) {
      setLocValue(location.pathname);
    } else {
      localStorage.setItem(`backUrl${info.id}`, locValue);
      setLocValue(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === '/') {
      dispatch(companyReset());
      window.localStorage.removeItem(`backUrl${info.id}`);
    }
  }, [location, dispatch]);

  useEffect(() => {
    const backListener = history.listen((loc, action) => {
      if (action === 'POP') {
        if (location.pathname?.startsWith('/overview/')) return;
        if (location.pathname?.startsWith('/details/') && locValue.startsWith('/details/')) {
          setLocValue(location.pathname);
        } else if (location.pathname?.startsWith(`/${info.id}/`) && locValue.startsWith(`/${info.id}/`)) {
          setLocValue(location.pathname);
        } else {
          localStorage.setItem(`backUrl${info.id}`, locValue);
          setLocValue(location.pathname);
        }
      }
    });
    return () => backListener();
  }, []);

  const isActive = path => {
    if ((path === '' || undefined) && history.location.pathname.endsWith('/')) {
      return true;
    } else if (history.location.pathname.endsWith(path) && path?.length > 1) {
      return true;
    }
    return false;
  };

  const isSubActive = path => {
    if ((path === '' || undefined) && history.location.pathname.endsWith('/')) {
      return true;
    } else if (history.location.pathname.includes(path) && path?.length > 1) {
      return true;
    }
    return false;
  };

  // const fetchLogs = (setValue, id) => { TODO: Enable when ready
  //   apiFetch(`/companies/${id}/elastic/notifications`)
  //     .then(response => {
  //       if (!response.ok) {
  //         setValue([]);
  //         return [];
  //       }
  //       return response.json()
  //     })
  //     .then(logs => {
  //       setValue(Object.entries(logs).map(([key, val]) => ({
  //         key: key,
  //         value: val
  //       })))
  //     })
  // };
  //
  // useEffect(() => {
  //   if (info.id) {
  //     // fetchLogs(setLogs, info.id)
  //   }
  // }, [info.id])
  //
  // useEffect(() => {
  //   if (location.pathname === '/') {
  //     // setLogs([])
  //   }
  // }, [location.pathname])

  const topBarContent = () => {
    return (
      <>
        {data?.length > 1 && [
          information?.projectTeam && (
            <Nav.Item key='teams' className='from-small'>
              <a className='bf-button' target='_blank' href={information?.projectTeam} rel='noopener noreferrer'>
                <img
                  src={teamsLogo}
                  style={{height: '21px', verticalAlign: 'middle', paddingRight: '8px'}}
                  alt='Teams'
                />
                <span style={{verticalAlign: 'middle'}}>Contact Intility</span>
              </a>
            </Nav.Item>
          )
        ]}
        {location.pathname !== '/' && !location.pathname?.startsWith('/overview/') && (
          <IntilityPermission>
            <Link to={`/details/${info.id}/information/`} className='from-medium' title='Backoffice switch'>
              <Nav.Item>Backoffice</Nav.Item>
            </Link>
          </IntilityPermission>
        )}
        {data?.length > 1 && (
          <Link
            to='/'
            onClick={() => {
              dispatch(setView('Hub'));
            }}
            title='Company overview'
            className='from-medium'
          >
            <Nav.Item>Company Overview</Nav.Item>
          </Link>
        )}
        <Nav.Group
          name={
            user.profilePhoto ? (
              <div className='profile-photo-wrapper'>
                <img src={`data:image/png;base64,${user.profilePhoto}`} alt={user.name} />
              </div>
            ) : (
              <Icon icon={faUser} />
            )
          }
          title='User profile'
        >
          {information?.projectTeam && (
            <a className='to-small' target='_blank' href={information?.projectTeam} rel='noopener noreferrer'>
              <Nav.Item>
                <img src={teamsLogo} style={{height: '21px', marginRight: 'var(--bfs4)'}} alt='Teams' />
                <span style={{verticalAlign: 'middle'}}>Contact Intility</span>
              </Nav.Item>
            </a>
          )}
          <EditPermission>
            <Link to='#' onClick={() => wipeCache()} title='Clear cache'>
              <Nav.Item icon={faTrashCan}>Clear cache</Nav.Item>
            </Link>
          </EditPermission>
          <hr />
          <Link className='to-medium' to='#' onClick={() => dispatch(setView(otherView))} title='View switch'>
            <Nav.Item className='to-medium'>{otherView}</Nav.Item>
          </Link>
          <Link
            className='to-medium'
            to='/'
            onClick={() => {
              dispatch(setView('Hub'));
            }}
            title='Company overview'
          >
            <Nav.Item className='to-medium'>Company Overview</Nav.Item>
          </Link>
          <hr className='to-medium' />
          <p className='bf-small bfc-base-2' style={{paddingLeft: 'var(--bfs16)', marginBottom: 0}}>
            Color theme
          </p>
          <Checkbox type='radio' name='Light' checked={theme === 'light'} label='Light' onChange={(e) => {setTheme('light')}}/>
          <Checkbox type='radio' name='Dark' checked={theme === 'dark'} label='Dark' onChange={(e) => {setTheme('dark')}}/>
          <Checkbox type='radio' name='System' checked={theme === 'system'} label='System' onChange={(e) => {setTheme('system')}}/>
          <hr />
          <Link to='#' onClick={() => logout()} title='Log out'>
            <Nav.Item icon={faRightFromBracket}>Log out</Nav.Item>
          </Link>
        </Nav.Group>
      </>
    );
  };

  const sideBarContent = () => {
    if (
      !intilityUser &&
      (view === 'Wizard' ||
        location.pathname === '/' ||
        location.pathname.startsWith('/details') ||
        location.pathname.startsWith('/overview'))
    ) {
      return;
    } else if (
      view === 'Wizard' ||
      location.pathname === '/' ||
      location.pathname.startsWith('/details') ||
      location.pathname.startsWith('/overview')
    ) {
      return (
        <>
          <IntilityPermission>
            <Link
              to='/overview/finance'
              className={isActive('finance') ? 'active' : ''}
              onClick={() => {
                dispatch(setView('Hub'));
              }}
              title='Finance'
            >
              <Nav.Item icon={faCoins}>Finance</Nav.Item>
            </Link>
            <Link
              to='/overview/pmo'
              className={isActive('pmo') ? 'active' : ''}
              onClick={() => {
                dispatch(setView('Hub'));
              }}
              title='Resources'
            >
              <Nav.Item icon={faPeopleGroup}>Resources</Nav.Item>
            </Link>
            <Nav.Group icon={faCog} name='Settings'>
              {globalPages.map((g, i) => {
                return (
                  <Link key={i} to={`/overview/settings/${g.path}`} className={isActive(g.path) ? 'active' : ''}>
                    <Nav.Item>{g.title}</Nav.Item>
                  </Link>
                );
              })}
            </Nav.Group>
          </IntilityPermission>
        </>
      );
    }
    if (view === 'Hub') {
      return (
        <>
          <AppName name={info.name || ''} />
          {data?.length > 1 && (
            <Link
              to='/'
              onClick={() => {
                dispatch(setView('Hub'));
              }}
              title='Company overview'
              className='to-small'
            >
              <Nav.Item>Company Overview</Nav.Item>
            </Link>
          )}
          <Link to={`/${info.id}/${overviewData.path}`} className={isActive(overviewData.path) ? 'active' : ''}>
            <Nav.Item icon={overviewData.icon}>{overviewData.title}</Nav.Item>
          </Link>
          <Link to={`/${info.id}/${tasksData.path}`} className={isActive(tasksData.path) ? 'active' : ''}>
            <Nav.Item icon={tasksData.icon}>{tasksData.title}</Nav.Item>
          </Link>
          <Nav.Group icon={faBuilding} name='My Company'>
            {companyData.microsoft365Platform.state === PageState.ENABLED && (
              <Link
                to={`/${info.id}/${microsoft365Data.path}`}
                className={isActive(microsoft365Data.path) ? 'active' : ''}
              >
                <Nav.Item>{microsoft365Data.title}</Nav.Item>
              </Link>
            )}
            <Link to={`/${info.id}/${contactData.path}`} className={isActive(contactData.path) ? 'active' : ''}>
              <Nav.Item>{contactData.title}</Nav.Item>
            </Link>
            {companyData.billingPlatform.state === PageState.ENABLED && (
              <Link to={`/${info.id}/${billingData.path}`} className={isActive(billingData.path) ? 'active' : ''}>
                <Nav.Item>{billingData.title}</Nav.Item>
              </Link>
            )}
            {companyData.domainPlatform.state === PageState.ENABLED && (
              <Link to={`/${info.id}/${domainsData.path}`} className={isActive(domainsData.path) ? 'active' : ''}>
                <Nav.Item>{domainsData.title}</Nav.Item>
              </Link>
            )}
          </Nav.Group>
          {companyData.locationPlatform.state === PageState.ENABLED && (
            <Link to={`/${info.id}/${locationsData.path}`} className={isActive(locationsData.path) ? 'active' : ''}>
              <Nav.Item icon={locationsData.icon}>{locationsData.title}</Nav.Item>
            </Link>
          )}
          {companyData.userPlatform.state === PageState.ENABLED && (
            <Link to={`/${info.id}/${usersData.path}`} className={isActive(usersData.path) ? 'active' : ''}>
              <Nav.Item icon={usersData.icon}>{usersData.title}</Nav.Item>
            </Link>
          )}
          {companyData.applicationPlatform.state === PageState.ENABLED && (
            <Link
              to={`/${info.id}/${applicationsData.path}`}
              className={isActive(applicationsData.path) ? 'active' : ''}
            >
              <Nav.Item icon={applicationsData.icon}>{applicationsData.title}</Nav.Item>
            </Link>
          )}
          {(companyData.iamsUserPlatform.state === PageState.ENABLED ||
            companyData.mobilePlatform.state === PageState.ENABLED ||
            companyData.printerPlatform.state === PageState.ENABLED ||
            companyData.computerSetupPlatform.state === PageState.ENABLED) && (
            <Nav.Group icon={faPhoneLaptop} name='Devices'>
              {companyData.iamsUserPlatform.state === PageState.ENABLED && (
                <Link
                  to={`/${info.id}/${computersData.path}`}
                  className={isSubActive('computers/registration') ? 'active' : ''}
                >
                  <Nav.Item>{computersData.title}</Nav.Item>
                </Link>
              )}
              {companyData.computerSetupPlatform.state === PageState.ENABLED && (
                <Link
                  to={`/${info.id}/${CustomData.path}`}
                  className={isSubActive('computers/clientDesign') ? 'active' : ''}
                >
                  <Nav.Item>{CustomData.title}</Nav.Item>
                </Link>
              )}
              {companyData.mobilePlatform.state === PageState.ENABLED && (
                <Link
                  to={`/${info.id}/${mobileDevicesData.path}`}
                  className={isSubActive('mobile-devices/') ? 'active' : ''}
                >
                  <Nav.Item>{mobileDevicesData.title}</Nav.Item>
                </Link>
              )}
              {companyData.printerPlatform.state === PageState.ENABLED && (
                <Link to={`/${info.id}/${printersData.path}`} className={isActive(printersData.path) ? 'active' : ''}>
                  <Nav.Item>{printersData.title}</Nav.Item>
                </Link>
              )}
            </Nav.Group>
          )}
          {companyData.documentationPlatform.state === PageState.ENABLED && (
            <Link to={`/${info.id}/${commentData.path}`} className={isActive(commentData.path) ? 'active' : ''}>
              <Nav.Item icon={commentData.icon}>{commentData.title}</Nav.Item>
            </Link>
          )}
          {companyData.intilityServicePlatform.state === PageState.ENABLED && (
            <Link
              to={`/${info.id}/${intilityServicesData.path}`}
              className={isActive(intilityServicesData.path) ? 'active' : ''}
            >
              <Nav.Item icon={intilityServicesData.icon}>{intilityServicesData.title}</Nav.Item>
            </Link>
          )}
          <Link to={`/${info.id}/${contactUsData.path}`} className={isActive(contactUsData.path) ? 'active' : ''}>
            <Nav.Item icon={contactUsData.icon}>{contactUsData.title}</Nav.Item>
          </Link>
        </>
      );
    }
  };

  const otherView = view === 'Hub' ? 'Wizard' : 'Hub';

  return (
    <Nav
      appName={'Onboard'}
      logo={
        <a href="/" className="bf-neutral-link">
          <Nav.Logo logo={onboardLogo}>Onboard</Nav.Logo>
        </a>
      }
      id='onboard-navigation'
      className='bf-elements bf-content'
      style={{height: '100vh', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}
      top={topBarContent()}
      side={sideBarContent()}
      sideProps={{
        collapsed: sideCollapsed,
        onCollapsedChange: collapsed => setSideCollapsed(collapsed)
      }}
    >
      {children}
    </Nav>
  );
};

SmartNavigation.propTypes = {
  logout: PropTypes.func,
  children: PropTypes.any
};

export default SmartNavigation;
