import React, {useEffect, useState} from 'react';
import {
  Badge,
  Button,
  dateFormat, Drawer,
  Icon,
  Input, Label, Message,
  Pagination,
  Table,
  Tooltip
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import '../EditComponents/editComponent.scss';
import '../EditComponents/editApplicationFilters.scss';
import {useDispatch, useSelector} from 'react-redux';
import { faCheck, faTicket, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import {
  fetchGlobalTasksAsync,
  fetchOnboardTasksAsync
} from "../../../redux/company/onboardTasks/onboardTaskThunks";
import { pageDataOptions, projectAreaOptions } from "../../../utils/hub/pageData/pageDataOptions";
import { PublishArticleDrawer } from "../../Hub/Pages/Devices.MobileDevices/Articles/PublishArticle";
import { responsibleType } from "../EditComponents/EditOnboardTasks";
import { Link } from "react-router-dom";

export const OnboardTaskDetails = () => {
  const {id, name} = useSelector(state => state.company.data.info);
  const {taskList, globalTaskList} = useSelector(state => state.company.data.taskPlatform);
  const [sort, setSort] = useState({key: 'request', direction: 'asc'});
  const [searchValue, setSearchValue] = useState('');
  const [projectAreas, setProjectAreas] = useState([]);
  const [taskFilter, setTaskFilter] = useState(0);
  const [taskTypeFilter, setTaskTypeFilter] = useState(0);
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [selectedTask, setSelectedTask] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchOnboardTasksAsync(id));
    dispatch(fetchGlobalTasksAsync());
  }, [user, dispatch, id]);


  const globalList = globalTaskList.filter(g => !taskList.find(t => t.generalTaskId === g.id));
  const concatTaskList = taskList.concat(globalList);

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: concatTaskList?.length, label: 'All'});
    return tableOptions;
  };


  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const filterProjectAreaFunction = (item) => {
    if (projectAreas.length === 0) return true;
    let filtered = false;
    projectAreas.forEach(f => {
      if (item?.projectArea === f.value) {
        filtered = true;
      }
    });
    return filtered;
  }

  const sortedData = concatTaskList
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (sort.key === 'dueDate') return new Date(a[sort.key]) - new Date(b[sort.key]);
        if (sort.key === 'taskType') return a[sort.key] - b[sort.key];
        if (sort.key === 'ticketId') return a[sort.key] - b[sort.key];
        if (sort.key === 'responsible') return a[sort.key] - b[sort.key];
        if (sort.key === 'published') return a?.generalTaskId !== undefined;
        if (sort.key === 'section') return pageDataOptions[a[sort.key]]?.label.localeCompare(pageDataOptions[b[sort.key]]?.label);
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        if (sort.key === 'dueDate') return new Date(b[sort.key]) - new Date(a[sort.key]);
        if (sort.key === 'taskType') return b[sort.key] - a[sort.key];
        if (sort.key === 'ticketId') return b[sort.key] - a[sort.key];
        if (sort.key === 'responsible') return b[sort.key] - a[sort.key];
        if (sort.key === 'published') return b?.generalTaskId !== undefined;
        if (sort.key === 'section') return pageDataOptions[b[sort.key]]?.label.localeCompare(pageDataOptions[a[sort.key]]?.label);
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    }).filter(t => filterProjectAreaFunction(t))
    .filter(t => {
      if (taskTypeFilter === 1) return t?.taskType === responsibleType.CUSTOMER;
      if (taskTypeFilter === 2) return t?.taskType === responsibleType.INTILITY;
      return t;
    }).filter(t => {
      if (taskFilter === 1) return t?.generalTaskId !== undefined;
      if (taskFilter === 2) return t?.generalTaskId === undefined;
      return t;
    }).filter(s => {
      return s.request?.toLowerCase().includes(searchValue.toLowerCase());
    });

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className='backoffice-editable-container'>
      <Message header='Global vs. custom task'>
        Publish the tasks you want {name} to see in their task dashboard, if you want to give this company specific tasks relevant only for them, press edit company and add task. Custom tasks will only be visible to the current company.
        If you need to edit a global task visit <Link to='/overview/settings/globalTasks'>Global settings</Link>
      </Message>
      <div className='filter-row' style={{marginTop: 'var(--bfs24)'}}>
        <div className='filter-btn-row'>
          <Input
            className='table-search-input'
            placeholder='Search for task'
            label='Search'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
          <Select className='header-select' placeholder='Project area' label='Project area filter' isMulti={true} value={projectAreas} options={projectAreaOptions} hideLabel onChange={(e) => setProjectAreas(e)} />
          <Button.Group>
            <Button active={taskTypeFilter === 0} onClick={() => {
              setTaskTypeFilter(0)
              setPage(1);
            }}>All tasks</Button>
            <Button active={taskTypeFilter === 1} onClick={() => {
              setTaskTypeFilter(1)
              setPage(1);
            }}>External</Button>
            <Button active={taskTypeFilter === 2} onClick={() => {
              setTaskTypeFilter(2)
              setPage(1);
            }}>Internal</Button>
          </Button.Group>
          <Button.Group>
            <Button active={taskFilter === 0} onClick={() => {
              setTaskFilter(0)
              setPage(1);
            }}>All tasks</Button>
            <Button active={taskFilter === 1} onClick={() => {
              setTaskFilter(1)
              setPage(1);
            }}>Published</Button>
            <Button active={taskFilter === 2} onClick={() => {
              setTaskFilter(2)
              setPage(1);
            }}>Deactivated</Button>
          </Button.Group>
          <p className='bf-em' style={{margin: 'auto', color: 'var(--bfc-base-c-2)'}}>{sortedData.length} of {concatTaskList.length} tasks</p>
        </div>
      </div>
      <Table style={{marginTop: 'var(--bfs16)'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell/>
            <Table.HeaderCell sorting={getSortProp('request')} onClick={onSortChangeCreator('request')}>
              Request
            </Table.HeaderCell>
            <Table.HeaderCell style={{minWidth: '150px'}} sorting={getSortProp('guideUrl')} onClick={onSortChangeCreator('guideUrl')}>
              Guide Url
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('section')} onClick={onSortChangeCreator('section')}>
              Onboard Section
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('projectArea')} onClick={onSortChangeCreator('projectArea')}>
              Project Area
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('userId')} onClick={onSortChangeCreator('userId')}>
              Assigned User
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('intilityContactId')} onClick={onSortChangeCreator('intilityContactId')}>
              Intility contact
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('taskType')} onClick={onSortChangeCreator('taskType')}>
              Type
            </Table.HeaderCell>
            <Table.HeaderCell style={{minWidth: '90px'}} sorting={getSortProp('ticketId')} onClick={onSortChangeCreator('ticketId')}>
              Ticket
            </Table.HeaderCell>
            <Table.HeaderCell style={{minWidth: '120px'}} sorting={getSortProp('dueDate')} onClick={onSortChangeCreator('dueDate')}>
              Due date
            </Table.HeaderCell>
            <Table.HeaderCell style={{minWidth: '140px'}} sorting={getSortProp('published')} onClick={onSortChangeCreator('published')}>
              Published
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAndIndexedData.map((task, i) => (
            <TaskTableRow key={i} task={task} setSelectedTask={setSelectedTask}/>
          ))}
        </Table.Body>
      </Table>
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div />
        )}
        <Select
          key='Items'
          label='Table size'
          className='table-size-dropdown'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
      <Drawer isOpen={selectedTask !== null} overlay={true} onRequestClose={() => setSelectedTask(null)}>
        {selectedTask?.guideUrl?.includes('publish.intility.com') ?
          <PublishArticleDrawer articleId={selectedTask?.guideUrl.split('/articles/')[1] || null}/>
          :
          <div>
            <h5>{selectedTask?.request}</h5>
            <p>{selectedTask?.comment}</p>
            <a className='bf-link' target='_blank' rel='noopener noreferrer' href={selectedTask?.guideUrl}>{selectedTask?.guideUrl}</a>
          </div>
        }
      </Drawer>
    </div>
  );
};

export default OnboardTaskDetails;

const TaskTableRow = ({ task, setSelectedTask }) => {

  const getBadge = (task) => {
    if ((task?.generalTaskId === null) && (task?.taskPlatformId !== null)) {
      return <Tooltip content='Company specific task'>
        <Badge>Custom</Badge>
      </Tooltip>
    } else {
      return <span><Badge state={"neutral"}>Global</Badge></span>
    }
  };

  return (
    <Table.Row className='edit-task-table' limitExpandClick content={
      <div className='bfl-padding'>
        <Label>Comment</Label>
        <p>{task?.comment}</p>
      </div>
    }>
      <Table.Cell>
        <span style={{display: 'flex', justifyContent: 'space-between'}}>
          {task?.request}
          {getBadge(task)}
        </span>
      </Table.Cell>
      <Table.Cell><a href='#' className='bf-link' onClick={() => setSelectedTask(task)}>{task?.guideUrl?.includes('publish.intility.com') ? 'Publish article' : task?.guideUrl?.length > 0 ? 'Other link' : ''}</a>{
        task?.guideUrl?.length > 0 ? '' : <span style={{color: 'var(--bfc-base-c-2)'}}>No guide</span>
      }
      </Table.Cell>
      <Table.Cell>{pageDataOptions[task?.section]?.label}</Table.Cell>
      <Table.Cell>{projectAreaOptions.find(o => o.value === task?.projectArea)?.label}</Table.Cell>
      <Table.Cell>{task?.user &&
        <span style={{display: 'flex', alignItems: 'center'}}>
          {task?.user && <Badge state='success' style={{display: 'flex', alignItems: 'center'}}><img style={{height: '18px', width: '18px', borderRadius: '50%', marginRight: 'var(--bfs4)'}} src={`data:image/png;base64,${task?.user?.profilePhoto}`} alt='img' />{task?.user?.lastName}</Badge>}
        </span>
      }</Table.Cell>
      <Table.Cell>{task?.intilityContact &&
        <span style={{display: 'flex', alignItems: 'center'}}>
          {task?.intilityContact && <Badge style={{display: 'flex', alignItems: 'center', marginRight: 'var(--bfs4)'}}><img style={{height: '18px', width: '18px', borderRadius: '50%', marginRight: 'var(--bfs4)'}} src={`data:image/png;base64,${task?.intilityContact?.profilePhoto}`} alt='img' />{task?.intilityContact?.lastName}</Badge>}
        </span>
      }</Table.Cell>
      <Table.Cell>{task?.taskType === responsibleType.CUSTOMER ? 'Customer' : task?.taskType === responsibleType.INTILITY && 'Intility'}</Table.Cell>
      <Table.Cell>{task.ticketId && <a className='bf-link' target='_blank' rel='noopener noreferrer' href={`${process.env.REACT_APP_TICKET_LINK}${task?.ticketId}`}><Icon className='bfc-theme' icon={faTicket}/></a>}</Table.Cell>
      <Table.Cell>
        {new Date(task?.dueDate)?.getFullYear() > 1 ? dateFormat('no-nb', new Date(task?.dueDate), {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit'
        }) : 'Not set'}
      </Table.Cell>
      <Table.Cell>
          {((task.generalTaskId !== undefined && task.taskPlatformId === undefined) || (task.taskPlatformId !== undefined && task.isEnabled)) ?
              <Icon icon={faCheck} style={{color: 'var(--bfc-success)'}}/>
          :
              <Icon icon={faTimes} style={{color: 'var(--bfc-alert)'}}/>
          }
      </Table.Cell>
    </Table.Row>
  );
}
