import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  addUserSuccess,
  clearUserSuccess,
  deleteUsersSuccess,
  fetchInformationSuccess,
  fetchUsersListSuccess,
  fetchUsersSuccess,
  updateUsersInformationSuccess,
  updateUsersSuccess,
  updateUserSuccess
} from './usersActions';

const updateOnInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchUsersSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateUsersInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const usersInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    isRotReady: true,
    lastAzureSync: null,
    userTypes: '',
    modifiedAt: '',
    userCount: 0
  },
  updateOnInformationChangeReducer((payload, state) => {
    state.id = payload.userPlatform.information.id;
    state.companyId = payload.userPlatform.information.companyId;
    state.userCount = payload.userPlatform.information.userCount;
    state.isEnabled = payload.userPlatform.information.isEnabled;
    state.isRotReady = payload.userPlatform.information.isRotReady;
    state.lastAzureSync = payload.userPlatform.information.lastAzureSync;
    state.userTypes = payload.userPlatform.information.userTypes;
    state.modifiedAt = payload.userPlatform.information.modifiedAt;
    return state;
  })
);

const updateOnUsersChangeReducer = getState => {
  return {
    [fetchUsersSuccess]: (state, action) => getState(action.payload, state),
    [updateUsersSuccess]: (state, action) => getState(action.payload, state),
    [updateUserSuccess]: (state, action) => getState(action.payload, state),
    [deleteUsersSuccess]: (state, action) => getState(action.payload, state),
    [addUserSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const usersReducer = createReducer(
  [],
  updateOnUsersChangeReducer((payload, state) => {
    state = payload.userPlatform.userList;
    return state;
  })
);

const updateOnUserChangeReducer = getState => {
  return {
    [addUserSuccess]: (state, action) => getState(action.payload, state),
    [clearUserSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const userReducer = createReducer(
  {},
  updateOnUserChangeReducer((payload, state) => {
    state = payload.userPlatform.user;
    return state;
  })
);

const updateOnUserStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchUsersSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const userStateReducer = createReducer(
  0,
  updateOnUserStateReducer((payload, state) => {
    state = payload.userPlatform.state;
    return state;
  })
);

export const usersStatusSlice = createSlice({
  name: 'usersAction',
  initialState: {
    fetching: false,
    syncing: false,
    updating: false,
    error: false,
    uploadError: false,
    uploadErrorMessage: '',
    uploadErrorResult: []
  },
  reducers: {
    fetchUsers: state => {
      state.fetching = true;
    },
    syncGraphUsers: state => {
      state.syncing = true;
    },
    fetchUsersError: state => {
      state.fetching = false;
      state.syncing = false;
      state.error = true;
    },
    updateUsers: state => {
      state.updating = true;
      state.error = false;
    },
    addUser: state => {
      state.updating = true;
      state.error = false;
    },
    updateUsersError: state => {
      state.updating = false;
      state.error = true;
    },
    uploadUsersError: (state, action) => {
      state.updating = false;
      state.uploadError = true;
      state.uploadErrorMessage = "Upload error";
      state.uploadErrorResult = action.payload;
    },
    uploadUsersSuccess: state => {
      state.updating = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    },
    updateUsersClear: state => {
      state.updating = false;
      state.uploadError = false;
      state.fetching = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    }
  },
  extraReducers: {
    [fetchUsersSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.syncing = false;
      state.error = false;
    },
    [fetchUsersListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateUsersSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateUserSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [deleteUsersSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [addUserSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateUsersInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchUsers,
  syncGraphUsers,
  fetchUsersError,
  updateUsers,
  addUser,
  updateUsersError,
  uploadUsersSuccess,
  updateUsersClear,
  uploadUsersError
} = usersStatusSlice.actions;
