import './emptyUsersState.scss'
import {Button} from '@intility/bifrost-react';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import emptyUsers from "assets/images/users/emptyUsers.png";


const EmptyUsersState = ({newUser, isComplete}) => {

  return (
    <div className='empty-users-state'>
      <div className='empty-users-state-image-container'>
        <div>
          <img src={emptyUsers} alt='empty users image'/>
        </div>
      </div>
      <div className='empty-users-state-text-container'>
        <h1>Add your users</h1>
        <p>This is where you will be able to view users. Add users you want to establish in your new environment.</p>
        <Button icon={faPlus} pill variant='filled' onClick={() => newUser(true)}>Start adding users</Button>
        <p>You may also add multiple users, by uploading a CSV-file. Read the how-to guide before starting.</p>
      </div>
    </div>
  )

}

export default EmptyUsersState