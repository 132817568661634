import {
  Button,
  Input,
  Modal,
  TextArea,
  useBreakpoint
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { OnboardTask } from "../../../../../utils/hub/models";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "@intility/bifrost-react-datepicker";
import produce from "immer";
import {
  addOnboardTaskAsync,
  fetchOnboardTasksAsync, updateOnboardTaskAsync
} from "../../../../../redux/company/onboardTasks/onboardTaskThunks";
import apiFetch from "../../../../../utils/apiFetch";
import './taskModal.scss';
import OnboardTaskComponent from "../TaskContainer/OnboardTaskComponent";
import Flex from "../../../../Flex/Flex";
import useIntilityPermission from "../../../../../hooks/useIntilityPermission";
import { IntilityPermission } from "../../../../Permissions";

export const TaskModal = ({task, setTask, isOpen, setIsOpen}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [editTask, setEditTask] = useState(new OnboardTask());
  const [responsibleUser, setResponsibleUser] = useState();
  const [intilityContact, setIntilityContact] = useState();
  const [userOptions, setUserOptions] = useState([]);
  const [intilityUserOptions, setIntilityUserOptions] = useState([]);
  const {user} = useSelector(state => state.auth);
  const isIntilityUser = useIntilityPermission();
  const dispatch = useDispatch();
  const toSmall = useBreakpoint(null, 'small');

  const resetTasks = () => {
    setIsOpen(false);
    setTask(undefined);
  }

  useEffect(() => {
    setEditTask(task);
    setResponsibleUser(task?.user ? { label: `${task?.user?.firstName} ${task?.user?.lastName}`, value: task?.user?.id} : undefined);
    setIntilityContact(task?.intilityContact ? { label: `${task?.intilityContact?.firstName} ${task?.intilityContact?.lastName}`, value: task?.intilityContact?.id} : undefined);
  }, [task])

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchOnboardTasksAsync(id));
    fetchTaskUsers();
  }, [user, dispatch, id]);

  const fetchTaskUsers = () => {
    apiFetch(`/users/${id}`)
      .then(response => {
        if (!response.ok) {
          setUserOptions([]);
          return [];
        }
        return response.json()
      })
      .then(users => {
        setUserOptions(users?.filter(u => !u.email?.toLowerCase()?.endsWith("@intility.no"))?.map(u => ({label: `${u?.firstName} ${u?.lastName} (${u?.provider})`, value: u.id})));
        setIntilityUserOptions(users?.filter(u => u.email?.toLowerCase()?.endsWith("@intility.no"))?.map(u => ({label: `${u?.firstName} ${u?.lastName}`, value: u.id})))
      })
  };

  const onSave = (newTask) => {
    if (newTask.id === undefined || newTask.id === null) {
      if (newTask.request.length > 0 &&
        newTask.section.length > 0) {
        dispatch(addOnboardTaskAsync(newTask));
        resetTasks();
      }
    } else {
      dispatch(updateOnboardTaskAsync(newTask));
      resetTasks();
    }
  }

  const onNewTaskChange = (e, name) => {
    if (e === null && name === 'userId') {
      setEditTask(
        produce(editTask, draft => {
          draft.userId = null;
        })
      )
    } else {
      const {value} = e;

      setEditTask(
        produce(editTask, draft => {
          if (name === 'dueDate') {
            draft[name] = new Date(e?.setHours(23));
          } else if (name === 'responsible') {
            draft.responsible = value;
            draft.userId = null;
          } else if (name === 'priority') {
            const newValue = parseInt(value?.toString()) || 0;
            draft[name] = newValue > 100 ? 100 : newValue;
          } else {
            draft[name] = value;
          }
        })
      )
    }

  }

  const allUserOptions = [
    {
      label: 'Project members',
      options: userOptions
    },
    {
      label: 'Intility Users',
      options: intilityUserOptions
    }
  ]

  return (
    <Modal isOpen={isOpen} onRequestClose={() => resetTasks()} header={task ? 'Edit task' : 'Add task'}>
      <div id='task-modal'>
        <div key='general' className='task-modal-general'>
          {toSmall &&
            <OnboardTaskComponent
              task={editTask}
              style={{marginBottom: 'var(--bfs24)'}}
              taskUser={editTask?.user}
              setSelectedTask={() => {}}
              setEditTask={() => {}}
              drawerTask={true}
            />
          }
          <Flex flex={[1]}>
            <TextArea label='Task description' disabled={!isIntilityUser} rows={1} value={editTask?.request} required onChange={(e) => onNewTaskChange(e.target, 'request')}/>
          </Flex>
          <DatePicker
            selected={
              editTask?.dueDate
                ? new Date(editTask?.dueDate).getFullYear() === 1
                  ? null
                  : new Date(editTask?.dueDate)
                : null
            }
            optional onChange={(e) => onNewTaskChange(e, 'dueDate')} label='Due date'/>
          <TextArea label='Comment' value={editTask?.comment} optional onChange={(e) => onNewTaskChange(e.target, 'comment')}/>
          <Flex flex={[1,1]}>
            <Select menuPlacement={"top"} isClearable={true} label='Responsible user' value={responsibleUser} options={allUserOptions} optional onChange={(e) => {
              onNewTaskChange(e, 'userId');
              setResponsibleUser(e);
            }}/>
            <Select menuPlacement={"top"} isClearable={true} isDisabled={!isIntilityUser} label='Intility contact' value={intilityContact} options={intilityUserOptions} optional onChange={(e) => {
              onNewTaskChange(e, 'intilityContactId');
              setIntilityContact(e);
            }}/>
          </Flex>
          <IntilityPermission>
            <Input label='GuideUrl' disabled={!isIntilityUser} value={editTask?.guideUrl} optional onChange={(e) => onNewTaskChange(e.target, 'guideUrl')}/>
          </IntilityPermission>
        </div>
      </div>
      <div style={{marginTop: 'var(--bfs40)', display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onSave(editTask)}>Save</Button>
      </div>
    </Modal>
  )

}

TaskModal.propTypes = {
  task: PropTypes.object,
  setTask: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default TaskModal