import { Button, Icon, useBreakpoint } from "@intility/bifrost-react";
import './printerCard.scss';
import PropTypes from "prop-types";

const PrinterCard = ({children, header, icon, onClick, isDisabled}) => {
  const fromMedium = useBreakpoint('medium', null);

  return (
    <div className='printer-card'>
      {fromMedium &&
        <div className='printer-card-header'>
          <span><Icon icon={icon}/></span>
        </div>
      }
      <div className='printer-card-body'>
        <p>{header}</p>
        <div className='printer-body-elements'>
          {children}
        </div>
      </div>
      <Button disabled={isDisabled} variant={"outline"} small onClick={onClick}>Edit</Button>
    </div>
  )

}

PrinterCard.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  icon: PropTypes.any
}

export const PrinterCsvCard = ({children, header, icon}) => {
  const fromMedium = useBreakpoint('medium', null);

  return (
    <div className='printer-card'>
      {fromMedium &&
        <div className='printer-card-header'>
          <span><Icon icon={icon}/></span>
        </div>
      }
      <div className='printer-card-body'>
        <p>{header}</p>
        <div className='printer-body-elements'>
          {children}
        </div>
      </div>
    </div>
  )

}

PrinterCsvCard.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  icon: PropTypes.any
}

export default PrinterCard