import {combineReducers} from '@reduxjs/toolkit';
import authSlice from './authSlice';
import {companyWrapperReducer} from './company/companyReducer';
import {companiesReducer} from './companiesSlice';
import {viewReducer} from './viewSlice';
import {financialDetailsReducer} from './company/details/detailReducers';
import {resourceTeamsReducer} from './company/projectTeams/projectTeamsReducers';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  company: companyWrapperReducer,
  companies: companiesReducer,
  financialDetails: financialDetailsReducer,
  resourceTeams: resourceTeamsReducer,
  view: viewReducer
});

export default rootReducer;
