import React, {useEffect, useRef, useState} from 'react';
import './applicationCard.scss';
import PropTypes from 'prop-types';
import {Badge, Button, Modal, useFloatingMessage, Icon, Spinner} from '@intility/bifrost-react';
import ServerLight from 'assets/images/application/Onboarding_Server_light.png';
import ServerDark from 'assets/images/application/Onboarding_Server.png';
import SaasLight from 'assets/images/application/Onboarding_SaaS_light.png';
import SaasDark from 'assets/images/application/Onboarding_SaaS.png';
import {useDispatch, useSelector} from 'react-redux';
import importAppTemplate from '../../../../../assets/files/Intility_Application_Import_Template.xlsx';
import {faArrowUp, faPlus, faArrowUpFromLine} from '@fortawesome/pro-regular-svg-icons';
import {uploadCsv as uploadAppCsv} from '../../../../../redux/company/applications/applicationThunks';
import {ApplicationsGuideModal} from '../../Users/GuideModal/GuideModal';

export const ApplicationCard = ({image, header, number, children}) => {
  return (
    <div style={{backgroundImage: `url(${image})`}} className='application-card'>
      <div style={{height: '62px'}} />
      <div className='application-card-header'>
        <div>
          <p className='bf-large'>{header}</p>
        </div>
        {number && (
          <div>
            <Badge>{number} added</Badge>
          </div>
        )}
      </div>
      <div className='application-card-children'>{children}</div>
    </div>
  );
};

export const ServerCard = ({isComplete, addNewApplication}) => {
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const {showFloatingMessage} = useFloatingMessage();

  const addApplication = () => {
    if (isComplete === true) {
      showFloatingMessage('Applications page set to completed, uncheck task to edit.', {state: 'warning'});
    } else {
      addNewApplication('Server');
    }
  };

  return (
    <div className='application-card-v2'>
      <div className='app-card-image bf-dark-only' style={{backgroundColor: '#250E58'}}>
        <img src={ServerDark} alt='Server image' />
      </div>
      <div className='app-card-image bf-light-only' style={{backgroundColor: '#ECCCFF'}}>
        <img src={ServerLight} alt='Server image' />
      </div>
      <div className='app-card-content'>
        <div>
          <h5>Server applications</h5>
          <span className='bfc-chill-c-bg'>
            <p>{applicationList?.filter(a => a.serverApp === 1 && a.isEnabled)?.length}</p>
          </span>
        </div>
        <p>Applicable if your app is dependent on servers (physical servers or virtual machines).</p>
        <div className='app-card-button'>
          <Button
            state={isComplete ? 'inactive' : 'default'}
            onClick={() => addApplication()}
            pill={true}
            icon={faPlus}
          >
            Add server application
          </Button>
        </div>
      </div>
    </div>
  );
};

ServerCard.propTypes = {
  isComplete: PropTypes.bool,
  addNewApplication: PropTypes.func
};

export const SaaSCard = ({isComplete, addNewApplication}) => {
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const {showFloatingMessage} = useFloatingMessage();

  const addApplication = () => {
    if (isComplete === true) {
      showFloatingMessage('Applications page set to completed, uncheck task to edit.', {state: 'warning'});
    } else {
      addNewApplication('SaaS');
    }
  };

  return (
    <div className='application-card-v2'>
      <div className='app-card-image bf-light-only' style={{backgroundColor: '#FFCCEB'}}>
        <img src={SaasLight} alt='SaaS image' />
      </div>
      <div className='app-card-image bf-dark-only' style={{backgroundColor: '#4D002E'}}>
        <img src={SaasDark} alt='SaaS image' />
      </div>
      <div className='app-card-content'>
        <div>
          <h5>SaaS applications</h5>
          <span className='bfc-chill-c-bg'>
            <p>{applicationList?.filter(a => a.saasApp === 1 && a.isEnabled)?.length}</p>
          </span>
        </div>
        <p>SaaS is hosted by your application vendor. We will make sure to take care of access and identity (SSO).</p>
        <div className='app-card-button'>
          <Button
            state={isComplete ? 'inactive' : 'default'}
            onClick={() => addApplication()}
            pill={true}
            icon={faPlus}
          >
            Add SaaS application
          </Button>
        </div>
      </div>
    </div>
  );
};

SaaSCard.propTypes = {
  isComplete: PropTypes.bool,
  addNewApplication: PropTypes.func
};

export const ImportCard = ({isComplete, button = true}) => {
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const [replace, setReplace] = useState(false);
  const fileRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const {showFloatingMessage} = useFloatingMessage();
  const dispatch = useDispatch();

  const getFilesArray = fileList => {
    const files = [];

    for (let i = 0; i < fileList?.length; i++) {
      const file = fileList[i];
      files.push(file);
    }

    return files;
  };

  const onFileSelect = e => {
    const files = getFilesArray(e.target.files);
    if (files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  }, [showTooltip]);

  const importApplication = () => {
    if (isComplete === true) {
      showFloatingMessage('Applications page set to completed, uncheck task to edit.', {state: 'warning'});
    } else {
      setShowModal(true);
    }
  };

  const uploadSelectedFile = async () => {
    if (selectedFile) {
      setLoading(true);
      try {
        await dispatch(uploadAppCsv(selectedFile, replace));
      } catch (error) {
        showFloatingMessage('An error occurred during file upload.', {state: 'error'});
      } finally {
        setShowModal(false);
        setLoading(false);
        setSelectedFile(null);
      }
    } else {
      showFloatingMessage('No file selected. Please select a file to upload.', {state: 'warning'});
    }
  };

  return (
    <div style={button ? {} : {display: 'inline-block'}}>
      {button ? (
        <Button state={isComplete ? 'inactive' : 'default'} onClick={() => importApplication()} icon={faArrowUp}>
          Upload CSV
        </Button>
      ) : (
        <button
          onClick={importApplication}
          style={{
            background: 'none',
            border: 'none',
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'inline',
            padding: 0
          }}
        >
          uploading a CSV-file
        </button>
      )}
      <input
        key={1}
        type='file'
        ref={fileRef}
        multiple={false}
        accept={'.csv'}
        onChange={onFileSelect}
        style={{display: 'none'}}
      />
      <Modal
        width={500}
        isOpen={showModal}
        key={2}
        onRequestClose={() => {
          setShowModal(false);
          setSelectedFile(null);
        }}
      >
        <div className='upload-csv-modal-header'>
          <Icon icon={faArrowUpFromLine} style={{marginRight: '10px'}} />
          Upload (.csv)
        </div>
        <p className='upload-csv-modal-text'>
          Add multiple applications, by uploading a CSV-file. Read the{' '}
          {<ApplicationsGuideModal text={'how-to guide'} />} and{' '}
          {
            <a className='bf-link' rel='noopener noreferrer' target='_blank' href={importAppTemplate}>
              download .csv-template
            </a>
          }{' '}
          before starting.
        </p>
        <p className='upload-csv-modal-text'>
          Uploading a CSV file with applications will add them to your current application list.
        </p>
        <div className='csv-uploader-select-box'>
          <Button
            state={isComplete ? 'inactive' : 'default'}
            onClick={() => fileRef.current && fileRef.current.click()}
            small
            style={{whiteSpace: 'nowrap', overflow: 'hidden'}}
          >
            Select file...
          </Button>
          <p
            style={{
              margin: '0px 16px',
              fontStyle: selectedFile ? 'normal' : 'italic',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 'calc(100% - 122px)' // to not truncate button. 122px is button+margin width
            }}
          >
            {selectedFile ? selectedFile.name : 'No file selected'}
          </p>
        </div>
        <div className='csv-uploader-footer'>
          <div className='button-container'>
            <Button
              variant={'outlined'}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              state={isComplete ? 'inactive' : 'default'}
              onClick={() => uploadSelectedFile()}
              disabled={!selectedFile || loading}
              Spinner={loading}
              variant='filled'
            >
              {loading ? (
                <div>
                  <Spinner size={16} /> Append to list
                </div>
              ) : (
                'Append to list'
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ImportCard.propTypes = {
  isComplete: PropTypes.bool
};

export default ApplicationCard;

ApplicationCard.propTypes = {
  image: PropTypes.any,
  header: PropTypes.string,
  number: PropTypes.number,
  children: PropTypes.node
};
