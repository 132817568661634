import {faServer} from '@fortawesome/pro-regular-svg-icons';
import OnboardLayout from '../../components/BackOffice/OnboardLayout/OnboardLayout';

export default {
  title: 'OnboardLayout',
  path: 'onboardLayout/',
  icon: faServer,
  editable: false,
  component: OnboardLayout,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
