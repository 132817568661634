import {createSlice} from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    authenticating: true,
    roles: []
  },
  reducers: {
    setAuth: (state, action) => {
      const {user, authenticating, roles} = action.payload;

      state.user = user;
      state.authenticating = authenticating;
      state.roles = roles;
    }
  }
});

export const authReducer = authSlice.reducer;
export const {setAuth} = authSlice.actions;

export default authSlice;
