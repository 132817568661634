import {apiFetch, apiFetchIdCollection} from 'utils/apiFetch';
import {updateApplications, updateApplicationsError} from '../applicationReducers';
import {addAppRelationSuccess, deleteAppRelationSuccess} from '../applicationActions';

export const addApplicationRelationAsync = (integration, applicationId) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {information, applicationList} = getState().company.data.applicationPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/${integration.parentId}/relations`,
    method: 'POST',
    body: integration
  })
    .then((relation) => {
      dispatch(addAppRelationSuccess(applicationId, relation, applicationList));
    })
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const deleteApplicationRelationAsync = (relation, list, appId) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {information, applicationList} = getState().company.data.applicationPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/${relation.parentId}/relations/${relation.id}`,
    method: 'DELETE'
  })
    .then(() => {
      dispatch(deleteAppRelationSuccess(appId, relation.id, list, applicationList));
    })
    .catch(error => dispatch(updateApplicationsError(error)));
};
