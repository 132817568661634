import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  addApplicationFilterSuccess,
  addApplicationSuccess,
  addAppRelationSuccess,
  addInternalContactSuccess,
  addVendorContactSuccess,
  deleteApplicationFilterSuccess,
  deleteApplicationSuccess,
  deleteAppRelationSuccess,
  deleteInternalContactSuccess,
  deleteVendorContactSuccess,
  fetchApplicationFiltersSuccess,
  fetchApplicationsListSuccess,
  fetchApplicationsSuccess,
  fetchInformationSuccess,
  updateApplicationInformationSuccess,
  updateApplicationsSuccess,
  updateApplicationSuccess,
  updateApplicationUserSuccess,
  updateInternalContactSuccess,
  updateVendorContactSuccess
} from './applicationActions';

const updateOnInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchApplicationsSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateApplicationInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const applicationsInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    applicationCount: 0
  },
  updateOnInformationChangeReducer((payload, state) => {
    state.id = payload.applicationPlatform.information.id;
    state.companyId = payload.applicationPlatform.information.companyId;
    state.applicationCount = payload.applicationPlatform.information.applicationCount;
    state.isEnabled = payload.applicationPlatform.information.isEnabled;
    state.modifiedAt = payload.applicationPlatform.information.modifiedAt;
    return state;
  })
);

const updateOnApplicationChangeReducer = getState => {
  return {
    [fetchApplicationsSuccess]: (state, action) => getState(action.payload, state),
    [addApplicationSuccess]: (state, action) => getState(action.payload, state),
    [deleteApplicationSuccess]: (state, action) => getState(action.payload, state),
    [updateApplicationsSuccess]: (state, action) => getState(action.payload, state),
    [updateApplicationSuccess]: (state, action) => getState(action.payload, state),
    [updateApplicationUserSuccess]: (state, action) => getState(action.payload, state),
    [addVendorContactSuccess]: (state, action) => getState(action.payload, state),
    [addInternalContactSuccess]: (state, action) => getState(action.payload, state),
    [updateVendorContactSuccess]: (state, action) => getState(action.payload, state),
    [updateInternalContactSuccess]: (state, action) => getState(action.payload, state),
    [deleteVendorContactSuccess]: (state, action) => getState(action.payload, state),
    [deleteInternalContactSuccess]: (state, action) => getState(action.payload, state),
    [addAppRelationSuccess]: (state, action) => getState(action.payload, state),
    [deleteAppRelationSuccess]: (state, action) => getState(action.payload, state)
  };
};

const updateOnApplicationFilterChangeReducer = getState => {
  return {
    [fetchApplicationFiltersSuccess]: (state, action) => getState(action.payload, state),
    [deleteApplicationFilterSuccess]: (state, action) => getState(action.payload, state),
    [addApplicationFilterSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const applicationsReducer = createReducer(
  [],
  updateOnApplicationChangeReducer((payload, state) => {
    state = payload.applicationPlatform.applicationList;
    return state;
  })
);
export const applicationFiltersReducer = createReducer(
  [],
  updateOnApplicationFilterChangeReducer((payload, state) => {
    state = payload.applicationPlatform.filters;
    return state;
  })
);

const updateOnApplicationStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchApplicationsSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const applicationStateReducer = createReducer(
  0,
  updateOnApplicationStateReducer((payload, state) => {
    state = payload.applicationPlatform.state;
    return state;
  })
);

export const applicationStatusSlice = createSlice({
  name: 'applicationAction',
  initialState: {
    fetching: false,
    updating: false,
    adding: false,
    addError: false,
    seeding: false,
    seedingUsers: false,
    error: false,
    updateError: false,
    updateErrorMessage: '',
    updateErrorResult: [],
    errorMessage: '',
    uploadError: false,
    uploadErrorMessage: '',
    uploadErrorResult: [],
    fetchingFilters: false,
    fetchingFiltersError: false,
    addingFilter: false,
    addingFilterError: false,
    deletingFilter: false,
    deletingFilterError: false,
  },
  reducers: {
    fetchApplications: state => {
      state.fetching = true;
    },
    addApplication: state => {
      state.adding = true;
      state.addError = false;
    },
    addApplicationError: state => {
      state.adding = false;
      state.addError = true;
    },
    fetchApplicationsError: (state, action) => {
      state.fetching = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    updateApplications: state => {
      state.updating = true;
    },
    updateApplication: state => {
      state.updating = true;
      state.updateError = false;
    },
    seedingApplications: state => {
      state.seeding = true;
    },
    seedingApplicationsSuccess: state => {
      state.seeding = false;
    },
    seedingApplicationsError: state => {
      state.seeding = false;
    },
    seedingApplicationUsers: state => {
      state.seedingUsers = true;
    },
    seedingApplicationUsersSuccess: state => {
      state.seedingUsers = false;
    },
    seedingApplicationUsersError: state => {
      state.seedingUsers = false;
    },
    updateApplicationsError: (state, action) => {
      state.updating = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    deleteApplication: state => {
      state.updating = true;
    },
    deleteApplicationError: (state, action) => {
      state.updating = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    uploadApplicationsError: (state, action) => {
      state.updating = false;
      state.uploadError = true;
      state.uploadErrorMessage = "Upload error";
      state.uploadErrorResult = action.payload;
    },
    uploadApplicationsSuccess: state => {
      state.updating = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    },
    updateApplicationSuccess: state => {
      state.updating = false;
      state.updateError = false;
      state.updateErrorMessage = '';
      state.updateErrorResult = [];
    },
    updateApplicationError: (state, action) => {
      state.updating = false;
      state.updateError = true;
      state.updateErrorMessage = "Upload error";
      state.updateErrorResult = action.payload;
    },
    updateApplicationsClear: state => {
      state.updating = false;
      state.uploadError = false;
      state.fetching = false;
      state.error = false;
      state.errorMessage = '';
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    },
    fetchApplicationFilters: state => {
      state.fetchingFilters = true;
    },
    fetchApplicationFiltersError: (state, action) => {
      state.fetchingFilters = false;
      state.fetchingFiltersError = true;
      state.errorMessage = action.payload;
    },
    addApplicationFilter: state => {
      state.addingFilter = true;
    },
    addApplicationFilterError: (state, action) => {
      state.addingFilter = false;
      state.addingFilterError = true;
      state.errorMessage = action.payload;
    },
    deleteApplicationFilter: state => {
      state.deletingFilter = true;
    },
    deleteApplicationFilterError: (state, action) => {
      state.deletingFilter = false;
      state.deletingFilterError = true;
      state.errorMessage = action.payload;
    },
  },
  extraReducers: {
    [fetchApplicationsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [addApplicationSuccess]: state => {
      state.adding = false;
      state.addError = false;
    },
    [fetchApplicationsListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateApplicationsSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [updateApplicationUserSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [deleteApplicationSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [updateApplicationInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchApplicationFiltersSuccess]: state => {
      state.fetchingFilters = false;
      state.updatingFilters = false;
      state.filtersError = false;
    },
    [addApplicationFilterSuccess]: state => {
      state.addingFilter = false;
      state.addingFilterError = false;
      state.errorMessage = '';
    },
    [deleteApplicationFilterSuccess]: state => {
      state.deletingFilter = false;
      state.deletingFilterError = false;
      state.errorMessage = '';
    },
    [addVendorContactSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [addInternalContactSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [updateVendorContactSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [updateInternalContactSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [deleteVendorContactSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [deleteInternalContactSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [addAppRelationSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
    [deleteAppRelationSuccess]: state => {
      state.updating = false;
      state.error = false;
      state.errorMessage = '';
    },
  }
});

export const {
  fetchApplications,
  fetchApplicationsError,
  fetchApplicationFilters,
  fetchApplicationFiltersError,
  addApplication,
  addApplicationError,
  deleteApplication,
  deleteApplicationError,
  updateApplications,
  updateApplication,
  updateApplicationError,
  updateApplicationsError,
  updateApplicationsClear,
  addApplicationFilter,
  addApplicationFilterError,
  deleteApplicationFilter,
  deleteApplicationFilterError,
  uploadApplicationsError,
  uploadApplicationsSuccess,
  seedingApplications,
  seedingApplicationsError,
  seedingApplicationsSuccess,
  seedingApplicationUsers,
  seedingApplicationUsersError,
  seedingApplicationUsersSuccess,
} = applicationStatusSlice.actions;
