import {IntilityServices} from '../../../components/Hub/Pages/IntilityServices/IntilityServices';
import {faPuzzlePiece} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Services',
  path: 'services',
  icon: faPuzzlePiece,
  isEnabled: (company) => {return company?.intilityServicePlatform?.state},
  component: IntilityServices,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Intility Services';
  }
};
