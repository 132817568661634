import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchProjectTeamsAsync } from "../../../../redux/company/projectTeams/projectTeamsThunks";
import {Breadcrumbs, Icon, Spinner, Sticky} from '@intility/bifrost-react';
import './contactUs.scss';
import { faArrowRight, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import TeamCard from "./TeamCard";
import ProjectTeamCard from "./ProjectTeamCard";
import {Link} from 'react-router-dom';

export const ContactUs = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth);
  const {list, projectTeamStatus} = useSelector(state => state.company.data.projectTeams);
  const [openTeam, setOpenTeam] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchProjectTeamsAsync(id));
  }, [user, dispatch, id]);


  return (
    <div id='contact-us'>
      <div className='header-container'>
        <div className='contact-us-header'>
          <div className='bf-breadcrumbs'>
            <Breadcrumbs.Item key='home'>
              <Link to={`/${id}`}>Home</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item key='services'>
              Contact us
            </Breadcrumbs.Item>
          </div>
          <h2>Contact us</h2>
          <p>
            If you have any questions, you can contact us in several ways.
          </p>
        </div>
      </div>
      <div className='content'>
        <div className='page-content'>
          <div className='main-content'>
            <ProjectTeamCard/>
            <h4>All project teams</h4>
            <div className='contact-us-container'>
              {projectTeamStatus.fetching ?
                <Spinner icon={faSpinner} pulse /> :
                list.map(i => {
                  if (i?.teamRoles?.length > 0 && i?.teamRoles?.some(r => r.member !== null)) {
                    return <TeamCard id={i.name} team={i} openTeam={openTeam}/>
                  } else return undefined;
                })
              }
            </div>
          </div>
          <Sticky className='side-menu from-medium'>
            <p className='bf-strong'>On this page</p>
            <a className='bf-title-link' href={`#team-card`}>Contact project team</a>
            <p style={{color: 'var(--bfc-base-c-2)'}}>All project teams</p>
            {list.map(t => {
              if (t?.teamRoles?.length > 0 && t?.teamRoles?.some(r => r.member !== null)) {
                return <a className='bf-title-link' href={`#${t.name}`} onClick={() => setOpenTeam(t.id)}><Icon
                  icon={faArrowRight} style={{ marginRight: 'var(--bfs8)' }} />{t?.name}</a>
              } else return undefined;
            })}
          </Sticky>
        </div>
      </div>
    </div>
  )

}

export default ContactUs