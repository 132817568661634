import {combineReducers} from '@reduxjs/toolkit';
import companyFetchSlice from './companyFetchSlice';
import companyUpdateSlice from './companyUpdateSlice';
import {
  companyInformationReducer,
  currentVendorContactReducer,
  lockOutTimerReducer,
  onboardedDateReducer
} from './companyInformationReducers';
import {
  applicationFiltersReducer,
  applicationsInformationReducer,
  applicationsReducer,
  applicationStateReducer,
  applicationStatusSlice
} from './applications/applicationReducers';
import {
  billingInformationReducer,
  billingListReducer,
  billingStateReducer,
  billingStatusSlice
} from './billing/billingReducers';
import {
  domainInformationReducer,
  domainListReducer,
  domainStateReducer,
  domainStatusReducer
} from './domain/domainReducers';
import {
  communicationInformationReducer,
  communicationsReducer,
  communicationStateReducer,
  communicationStatusSlice
} from './communication/communicationReducers';
import {
  computerSetupStatusSlice,
  desktopSettingsReducer,
  desktopSettingsStateReducer
} from './computerSetup/computerSetupReducers';
import {
  locationsInformationReducer,
  locationsReducer,
  locationsStatusSlice,
  locationStateReducer
} from './locations/locationReducers';
import {
  userReducer,
  usersInformationReducer,
  usersReducer,
  usersStatusSlice,
  userStateReducer
} from './users/usersReducers';
import {
  iamsUsersInformationReducer,
  iamsUsersReducer,
  iamsUsersStatusSlice,
  iamsUserStateReducer,
  workplaceDeviceReducer
} from './iamsUsers/iamsUsersReducers';
import mobileDevicesSlice, {mobileStateReducer} from './mobileDevices/mobileDevicesSlice';
import {
  documentationInformationReducer,
  documentationReducer,
  documentationStateReducer,
  documentationStatusSlice
} from './documentation/documentationReducers';
import {detailInformationReducer, detailsReducer, detailsStatusSlice} from './details/detailReducers';
import {memberRolesReducer, projectTeamsReducer, projectTeamsStatusSlice} from './projectTeams/projectTeamsReducers';
import {
  printersInformationReducer,
  printersReducer,
  printersStatusSlice,
  printerStateReducer
} from './printers/printerReducers';
import {
  onboardGlobalTaskReducer,
  onboardTaskInformationReducer,
  onboardTaskReducer,
  onboardTaskStatusReducer,
  ticketManagerProjectIdReducer
} from './onboardTasks/onboardTaskReducers';
import {
  memberGlobalRolesReducer,
  projectGlobalTeamsReducer,
  projectGlobalTeamsStatusSlice
} from './projectTeams/projectGlobalTeamsReducers';
import {onboardTicketReducer, onboardTicketStatusReducer} from './onboardTickets/onboardTicketReducers';
import {
  microsoft365InformationReducer,
  microsoft365StateReducer,
  microsoft365StatusReducer
} from './ms365/ms365Reducers';
import {
  globalIntilityServiceReducer,
  intilityServiceInformationReducer,
  intilityServiceReducer,
  intilityServiceStateReducer,
  intilityServiceStatusReducer
} from './intilityServices/intilityServiceReducers';

export const companyReducer = combineReducers({
  fetch: companyFetchSlice.reducer,
  update: companyUpdateSlice.reducer,
  data: combineReducers({
    info: companyInformationReducer,
    onboardedDate: onboardedDateReducer,
    lockOutTimer: lockOutTimerReducer,
    currentVendorContact: currentVendorContactReducer,
    billingPlatform: combineReducers({
      information: billingInformationReducer,
      billingList: billingListReducer,
      state: billingStateReducer,
      billingStatus: billingStatusSlice.reducer
    }),
    domainPlatform: combineReducers({
      information: domainInformationReducer,
      domainList: domainListReducer,
      state: domainStateReducer,
      domainStatus: domainStatusReducer.reducer
    }),
    locationPlatform: combineReducers({
      information: locationsInformationReducer,
      locationList: locationsReducer,
      state: locationStateReducer,
      locationStatus: locationsStatusSlice.reducer
    }),
    documentationPlatform: combineReducers({
      information: documentationInformationReducer,
      documentList: documentationReducer,
      state: documentationStateReducer,
      documentationStatus: documentationStatusSlice.reducer
    }),
    applicationPlatform: combineReducers({
      information: applicationsInformationReducer,
      applicationList: applicationsReducer,
      state: applicationStateReducer,
      filters: applicationFiltersReducer,
      applicationStatus: applicationStatusSlice.reducer
    }),
    userPlatform: combineReducers({
      information: usersInformationReducer,
      userList: usersReducer,
      state: userStateReducer,
      user: userReducer,
      usersStatus: usersStatusSlice.reducer
    }),
    iamsUserPlatform: combineReducers({
      information: iamsUsersInformationReducer,
      iamsUserList: iamsUsersReducer,
      state: iamsUserStateReducer,
      iamsUsersStatus: iamsUsersStatusSlice.reducer,
      workplaceDevices: workplaceDeviceReducer,
    }),
    printerPlatform: combineReducers({
      information: printersInformationReducer,
      printerList: printersReducer,
      state: printerStateReducer,
      printersStatus: printersStatusSlice.reducer
    }),
    communicationPlatform: combineReducers({
      information: communicationInformationReducer,
      communicationList: communicationsReducer,
      state: communicationStateReducer,
      communicationStatus: communicationStatusSlice.reducer
    }),
    computerSetupPlatform: combineReducers({
      desktopSettings: desktopSettingsReducer,
      state: desktopSettingsStateReducer,
      computerSetupStatus: computerSetupStatusSlice.reducer
    }),
    detailsPlatform: combineReducers({
      information: detailInformationReducer,
      detailList: detailsReducer,
      detailStatus: detailsStatusSlice.reducer
    }),
    projectTeams: combineReducers({
      list: projectTeamsReducer,
      roles: memberRolesReducer,
      projectTeamStatus: projectTeamsStatusSlice.reducer
    }),
    projectGlobalTeams: combineReducers({
      list: projectGlobalTeamsReducer,
      roles: memberGlobalRolesReducer,
      projectGlobalTeamStatus: projectGlobalTeamsStatusSlice.reducer
    }),
    taskPlatform: combineReducers({
      information: onboardTaskInformationReducer,
      taskList: onboardTaskReducer,
      globalTaskList: onboardGlobalTaskReducer,
      taskStatus: onboardTaskStatusReducer.reducer
    }),
    intilityServicePlatform: combineReducers({
      information: intilityServiceInformationReducer,
      intilityServiceList: intilityServiceReducer,
      state: intilityServiceStateReducer,
      globalIntilityServiceList: globalIntilityServiceReducer,
      intilityServiceStatus: intilityServiceStatusReducer.reducer
    }),
    ticketPlatform: combineReducers({
      ticketList: onboardTicketReducer,
      ticketStatus: onboardTicketStatusReducer.reducer
    }),
    mobilePlatform: combineReducers({
      mobileDevices: mobileDevicesSlice.reducer,
      state: mobileStateReducer,
    }),
    microsoft365Platform: combineReducers({
      information: microsoft365InformationReducer,
      state: microsoft365StateReducer,
      microsoft365Status: microsoft365StatusReducer.reducer
    })
  })
});

export const companyWrapperReducer = (state, action) => {
  if (action.type === 'RESET_COMPANY') {
    state = undefined;
  }
  return companyReducer(state, action);
};
