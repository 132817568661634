import React from 'react';
import billingBoxPropTypes from './billingBoxPropTypes';
import BoxItem from 'components/BoxItem/BoxItem';
import {Icon} from '@intility/bifrost-react';
import {faEllipsisH} from '@fortawesome/pro-regular-svg-icons';
import './billingBox.scss';

const BillingBox = ({billing, toggleEdit, setBillingId, isComplete, main}) => {
  const editBilling = () => {
    setBillingId(billing.id);
    toggleEdit(true);
  };

  return (
    <div
      style={{cursor: `${main ? 'auto' : 'move'}`}}
      draggable={!main}
      onDragStart={e => {
        e.dataTransfer.setData('id', billing.id);
        e.dataTransfer.setData('name', billing.name);
        e.dataTransfer.setData('address', billing.address);
        e.dataTransfer.setData('organizationNumber', billing.organizationNumber);
        e.dataTransfer.setData('email', billing.email);
      }}
      className='billing-box-item bfc-base-3-bg'
    >
      <div className='bfl-grid billing-box-item-grid'>
        <BoxItem
          className='bfc-base-3-bg'
          label='Company'
          value={`${billing.name} (org.nr ${billing.organizationNumber || 'unknown'})`}
        />
        <BoxItem className='bfc-base-3-bg' label='Billing address' value={billing.zipCode ? `${billing.address}, ${billing?.zipCode || ''} ${billing?.postalPlace || ''}` : billing.address} />
        <div className='bfc-base-3-bg'>
          <p className='bf-strong' style={{color: 'var(--bfc-base-c-1)'}}>
            Invoice e-mail
          </p>
          {billing.email ? (
            <a className='bf-link' href={`mailto:${billing.email}`}>
              {billing.email}
            </a>
          ) : (
            <p className='bf-large' style={{color: 'var(--bfc-base-c-2)'}}>
              Not registered
            </p>
          )}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        {isComplete ? (
          <Icon icon={faEllipsisH} style={{color: 'var(--bfc-base-c-2)'}} />
        ) : (
          <Icon icon={faEllipsisH} style={{cursor: 'pointer'}} onClick={() => editBilling()} />
        )}
      </div>
    </div>
  );
};

BillingBox.propTypes = billingBoxPropTypes;

export default BillingBox;
