export default (singularWord, collectionLength) => {
  if (collectionLength !== 1) {
    const wordLength = singularWord?.length;
    const lastChar = singularWord[wordLength - 1];

    if (lastChar === 'y') {
      // remove y from word ending in y and append ies
      return singularWord.substring(0, wordLength - 1) + 'ies';
    }

    return singularWord + 's';
  }

  return singularWord;
};
