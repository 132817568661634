import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {fetchCompaniesAsync} from 'redux/companiesSlice';
import './companyOverview.scss';
import BasicPage from '../Hub/Pages/BasicPage';
import { Button, FormatDate, Label, Table, useBreakpoint } from "@intility/bifrost-react";
import OverviewProgress from './OverviewProgress';
import achievements from '../../utils/hub/achievements';
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { setView } from "../../redux/viewSlice";
import WizardPermission from "../Permissions/WizardPermission";

export const getTime = (dateTime, alternative) => {
  const currentDate = new Date(dateTime);
  const year = currentDate.getFullYear();
  return year !== 1 ? <FormatDate date={currentDate} show={'date'} /> : alternative || 'Undefined';
};

const CompanyOverview = () => {
  const {data, fetching} = useSelector(state => state.companies);
  const {update} = useSelector(state => state.company);
  const [sort, setSort] = useState({
    key: 'goLiveDate',
    direction: 'asc'
  });
  const achievementPages = achievements;
  const breakpoint = useBreakpoint('medium');
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.length !== 0) return;
    dispatch(fetchCompaniesAsync());
  }, [dispatch, data.length]);

  useEffect(() => {
    if (update.fetching === true) {
      dispatch(fetchCompaniesAsync());
    }
  }, [update, dispatch]);

  if (fetching) return <p>Fetching companies...</p>;
  // redirect to hub with the one company
  if (data?.length === 1) return <Redirect to={`/${data[0].id}/`} />;

  const getProgress = company => {
    let progressPoints = 0;
    achievementPages.forEach(achievement => {
      if (!achievement.isEnabledOverview(company)) {
        progressPoints += achievement.weight;
      }
    });
    return progressPoints;
  };

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const sortedData = [...data].sort((a, b) => {
    if (sort.key === 'progress' && sort.direction === 'asc') {
      return getProgress(a) - getProgress(b);
    } else if (sort.key === 'progress' && sort.direction === 'desc') {
      return getProgress(b) - getProgress(a);
    } else if (sort.key === 'locationPlatform' && sort.direction === 'asc') {
      return a.locationPlatform?.locationCount - b.locationPlatform?.locationCount;
    } else if (sort.key === 'locationPlatform' && sort.direction === 'desc') {
      return b.locationPlatform?.locationCount - a.locationPlatform?.locationCount;
    } else if (sort.key === 'userPlatform' && sort.direction === 'asc') {
      return a.userPlatform?.userCount - b.userPlatform?.userCount;
    } else if (sort.key === 'userPlatform' && sort.direction === 'desc') {
      return b.userPlatform?.userCount - a.userPlatform?.userCount;
    } else if (sort.direction === 'asc') {
      return a[sort.key]?.localeCompare(b[sort.key]);
    } else {
      return b[sort.key]?.localeCompare(a[sort.key]);
    }
  });

  return (
    <BasicPage id='company-overview'>
      <div className='content-main'>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 'var(--bfs12)'}}>
          <h1 style={{marginBottom: 0}}>Company overview</h1>
          <div style={{display: 'flex', alignItems: "flex-end"}}>
            <WizardPermission>
              <Button variant={"filled"} icon={faPlus} onClick={() => dispatch(setView('Wizard'))} rightIcon>Create new project</Button>
            </WizardPermission>
          </div>
        </div>
        <Table className='overview-table'>
          <Table.Header>
            <Table.Row style={{zIndex: 0}}>
              <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
                Company
              </Table.HeaderCell>
              <Table.HeaderCell
                className='from-medium'
                sorting={getSortProp('progress')}
                onClick={onSortChangeCreator('progress')}
              >
                Onboard progress
              </Table.HeaderCell>
              <Table.HeaderCell
                className='from-medium'
                sorting={getSortProp('locationPlatform')}
                onClick={onSortChangeCreator('locationPlatform')}
              >
                Locations
              </Table.HeaderCell>
              <Table.HeaderCell
                className='from-medium'
                sorting={getSortProp('userPlatform')}
                onClick={onSortChangeCreator('userPlatform')}
              >
                Users
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedData.map(company => (
              <Table.Row
                key={company.id}
                {...(!breakpoint && {
                  content: (
                    <div style={{padding: '8px'}}>
                      <OverviewProgress company={company} />
                      <div className='overview-collapsed-label'>
                        <Label>Locations</Label>
                        <p>{company?.locationPlatform?.locationCount}</p>
                      </div>
                      <div className='overview-collapsed-label'>
                        <Label>Users</Label>
                        <p>{company?.userPlatform?.userCount}</p>
                      </div>
                    </div>
                  ),
                  limitExpandClick: true
                })}
              >
                <Table.Cell>
                  <Link style={{color: 'var(--bfc-theme)'}} to={'/' + company.id + '/'}>
                    ({company?.detailsPlatform?.companyCode || 'N/A'}) {company.name}
                  </Link>
                </Table.Cell>
                <Table.Cell className='from-medium'>
                  <OverviewProgress company={company} />
                </Table.Cell>
                <Table.Cell className='from-medium'>{company?.locationPlatform?.locationCount}</Table.Cell>
                <Table.Cell className='from-medium'>{company?.userPlatform?.userCount}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </BasicPage>
  );
};

export default CompanyOverview;
