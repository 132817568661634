import EditApplications from '../../components/BackOffice/EditComponents/EditApplications';
import {faServer} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Applications',
  path: 'applications/edit',
  icon: faServer,
  editable: true,
  component: EditApplications
};
