import { Drawer, Grid, Icon, Spinner, Tooltip, useBreakpoint } from "@intility/bifrost-react";
import React, { useEffect, useState } from "react";import { useDispatch, useSelector } from "react-redux";
import './intilityBar.scss';
import teamsLogo from 'assets/images/software_logos/teams2.png';
import { fetchApplicationsAsync } from "../../../../../redux/company/applications/applicationThunks";
import { fetchIamsUsersAsync } from "../../../../../redux/company/iamsUsers/iamsUserThunks";
import { fetchLocationsAsync } from "../../../../../redux/company/locations/locationThunks";
import { checkNetwork } from "../../../../BackOffice/NetworkDetails/NetworkDetails";
import { fetchProjectTeamsAsync } from "../../../../../redux/company/projectTeams/projectTeamsThunks";
import { faAngleRight, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import IamsUserType from "../../Devices.Computers/IamsRegistration/IamsUserTypeOptions";
import { IntilityUserType } from "../../Users/Options/UserTypeOptions";
import { fetchUsersAsync } from "../../../../../redux/company/users/userThunks";

export const lineProgress = (information) => {
  const planning = new Date(information?.planningDueDate);
  const preparation = new Date(information?.preparationDueDate);
  const testing = new Date(information?.testingDueDate);
  const goLive = new Date(information?.goLiveDate);
  const today = new Date(Date.now()).getTime();

  if (planning.getFullYear() === 1 || planning.getTime() > today) return 1;
  if (preparation.getFullYear() === 1 || preparation.getTime() > today) return 2;
  if (testing.getFullYear() === 1 || testing.getTime() > today) return 3;
  if (goLive.getFullYear() === 1 || goLive.getTime() > today) return 4;
  return 1;
};

const progressBox = () => {
  const {information} = useSelector(
    state => state.company.data.detailsPlatform
  );
  const [progressNumber, setProgressNumber] = useState(1);
  const toSmall = useBreakpoint(null, 'small');
  const fromSmall = useBreakpoint('small', null);

  useEffect(() => {
    setProgressNumber(lineProgress(information))
  }, [information])


  const dateline = (text, date, numberInLine, progressNumber) => {
    const processedDate = new Date(date);
    let line;
    let dateString = <p className='bf-small'>{processedDate.getDate()}.{processedDate.getMonth() + 1}.{processedDate.getFullYear()}</p>
    if (processedDate.getFullYear() === 1) dateString = <p className='bf-small'>Not set</p>
    if (numberInLine < progressNumber) line = <div style={{background: 'var(--bfc-theme-c-1)', color: 'var(--bfc-theme-1)'}}><span><Icon icon={faCheck}/>{fromSmall && <p className='bf-small'>{text}</p>}</span>{dateString}</div>;
    if (numberInLine > progressNumber) line = <div><span>{fromSmall && <p className='bf-small'>{text}</p>}</span>{dateString}</div>;
    if (numberInLine === progressNumber) line = <div style={{background: 'var(--bfc-theme-c-2', color: 'var(--bfc-theme-2)'}} className='bf-strong'><span><Icon icon={faSpinner}/>{fromSmall && <p className='bf-small'>{text}</p>}</span>{dateString}</div>;

    return toSmall ? <Tooltip placement='bottom' content={text}>{line}</Tooltip> : line
  }


  return (
    <Grid className='progress-box visualize-box' cols={4} small={4} gap={0}>
      <Grid.Span small={1} >{dateline('Planning', information?.planningDueDate, 1, progressNumber)}</Grid.Span>
      <Grid.Span small={1} >{dateline('Preparation', information?.preparationDueDate, 2, progressNumber)}</Grid.Span>
      <Grid.Span small={1} >{dateline('Test', information?.testingDueDate, 3, progressNumber)}</Grid.Span>
      <Grid.Span small={1} >{dateline('Go live', information?.goLiveDate, 4, progressNumber)}</Grid.Span>
    </Grid>
  );
};

const applicationBox = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth);
  const {applicationList, information} = useSelector(
    state => state.company.data.applicationPlatform
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchApplicationsAsync(id));
  }, [user, dispatch, id]);

  const stagedServers = applicationList.filter(a => a.isEnabled === true).filter(a => a.applicationStatus?.serverStaged === 2).length;

  return (
    <div id='application-box' className='visualize-box'>
      <p className='bf-small bf-strong'>{stagedServers} applications</p>
      <p className='bf-small'>good to go</p>
      <h1>{stagedServers}/{information?.applicationCount}</h1>
    </div>
  );
}

const deviceBox = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth);
  const { userList } = useSelector(state => state.company.data.userPlatform);
  const {iamsUserList} = useSelector(state => state.company.data.iamsUserPlatform);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchIamsUsersAsync(id));
    dispatch(fetchUsersAsync(id));
  }, [user, dispatch, id]);

  const filteredUserList = userList && userList?.filter(u => u.isEnabled === true)?.filter(u => {
    return u.userType !== IamsUserType.NotPcUser && (u.intilityUserType === IntilityUserType.IntilityIdUser || u.intilityUserType === IntilityUserType.IntilityIdUserCustom || u.intilityUserType === IntilityUserType.ExternalUser)
  });

  const timestamp = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
  const newDevices = iamsUserList.filter(a => new Date(a.createdAt).getTime() > timestamp).length;

  return (
    <div id='device-box' className='visualize-box'>
      <p className='bf-small bf-strong'>{iamsUserList.length} devices</p>
      <p className='bf-small'>registered</p>
      <h1>{iamsUserList.length}/{filteredUserList.length}</h1>
      {newDevices > 0 &&
        <p><span style={{color: 'var(--bfc-base-c-theme)'}}>+{newDevices}</span> The last week</p>
      }
    </div>
  );
}

const teamBox = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {information} = useSelector(state => state.company.data.detailsPlatform);
  const {user} = useSelector(state => state.auth);
  const {list, projectTeamStatus} = useSelector(state => state.company.data.projectTeams);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchProjectTeamsAsync(id));
  }, [user, dispatch, id]);

  const teamList = [];

  list.forEach(t => {
    t.teamRoles.map((role, i) => {
      if (role?.member?.profilePhoto) {
        if (!teamList.find(t => t.key === role?.member?.id))
        teamList.push(
          <img
            key={role?.member?.id}
            src={`data:image/png;base64,${role?.member?.profilePhoto}`}
            alt={role?.member?.surname.slice(0,2)}
          />
        );
      }
    })
  });

  return (
    <div id='team-box' className='visualize-box'>
      <p className='bf-small bf-strong' onClick={() => setDrawerOpen(true)}>Meet the team <Icon icon={faAngleRight}/></p>
      <p className='bf-small'>We’re onboarding you to Intility!</p>
      <div className='picture-container'>
        {projectTeamStatus.fetching ?
          <Spinner icon={faSpinner} pulse /> :
          teamList.length > 21 ? teamList.splice( 0,18).map(i => i) : teamList.map(i => i)}
      </div>
      <Drawer header='Intility Team' className='team-drawer' isOpen={drawerOpen} onRequestClose={() => setDrawerOpen(false)} overlay>
        <div className='team-drawer-link'>
          <img style={{height: '14px'}} src={teamsLogo} alt={'Teams'} />
          <a href={information?.projectTeam} rel='noopener noreferrer' target='_blank' className='bf-p bf-link'>Contact us in Teams</a>
          <Icon icon={faAngleRight}/>
        </div>
        {list.map((team) => {
          return (
            <div key={team.id} className='team'>
              <h3 style={{color: 'var(--bfc-base-c-2)'}}>{team.name}</h3>
              {team.teamRoles.map((role, j) => {
                return (
                  role?.member &&
                  <div className='team-row' key={j}>
                    {role?.name && [
                      <p key={role.id}>{role.name}</p>,
                      <div key={j + "line"} className='team-row-separator' />
                    ]}
                    <img
                      src={`data:image/png;base64,${role?.member?.profilePhoto}`}
                      alt='img'
                    />
                    {role?.member && [
                      <p>{role?.member?.givenName} {role?.member?.surname}</p>,
                      <div key={j + "line"} className='team-row-separator' />,
                      <p>{role?.member?.mobilePhone}</p>

                      ]
                    }
                  </div>
                )
              })}
            </div>
            )
        })}
      </Drawer>
    </div>
  );
}

const locationBox = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth);
  const {locationList} = useSelector(
    state => state.company.data.locationPlatform
  );
  const [goLiveLocations, setGoLiveLocations] = useState(0);
  const [goLiveProgress, setGoLiveProgress] = useState(0);
  const [goLivePercentage, setGoLivePercentage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    if (!user || !id) return;
    let totalNumber = 0;
    let currentProgress = 0;
    let totalGoLive = 0;
    locationList &&
    locationList?.forEach(l => {
      const locationProgress = checkNetwork(l);
      if (locationProgress === 4) totalGoLive++;
      totalNumber += 4;
      currentProgress += locationProgress;
    });
    setGoLiveLocations(totalGoLive);
    setGoLivePercentage(Math.round((currentProgress * 100) / totalNumber) || 0);
  }, [user, locationList, id]);

  useEffect(() => {
    setTimeout(() => {
      if (goLiveProgress < goLivePercentage) {
        setGoLiveProgress(goLiveProgress + 1);
      }
    }, 30);
  }, [goLiveProgress, goLivePercentage])

  return (
    <div id='location-box' className='visualize-box'>
      <p className='bf-small bf-strong'>{goLiveLocations} locations</p>
      <p className='bf-small'>ready to go live</p>
      <h1>{goLiveProgress}%</h1>
      <p className='bf-small'>In progress</p>
    </div>
  );
}

const IntilityBar = () => {

  return (
    <div id='intility-bar'>
      <div className='intility-bar-header'>
        <p className='bf-large bf-strong'>Intility project status</p>
        {progressBox()}
      </div>
      <Grid className='intility-bar-content' cols={2} small={4} gap={8}>
        <Grid.Span small={1} >{applicationBox()}</Grid.Span>
        <Grid.Span small={1} >{deviceBox()}</Grid.Span>
        <Grid.Span small={1} >{locationBox()}</Grid.Span>
        <Grid.Span cols={2} small={1} >{teamBox()}</Grid.Span>
      </Grid>
    </div>
  )
}

export default IntilityBar