import {createAction, createSlice} from '@reduxjs/toolkit';
import normalize from 'utils/hub/normalize';

export const fetchCompanySuccess = createAction('companyFetch/fetchCompanySuccess', apiCompany => ({
  payload: normalize(apiCompany)
}));

const companyFetchSlice = createSlice({
  name: 'companyFetch',
  initialState: {
    fetching: false,
    error: false,
    data: null
  },
  reducers: {
    fetchCompany: state => {
      state.fetching = true;
    },
    fetchCompanyError: state => {
      state.fetching = false;
      state.error = true;
    }
  },
  extraReducers: {
    [fetchCompanySuccess]: (state, action) => {
      state.fetching = false;
      state.error = false;
      state.data = action.payload;
    }
  }
});

export const {fetchCompany, fetchCompanyError} = companyFetchSlice.actions;

export default companyFetchSlice;
