import {createAction} from '@reduxjs/toolkit';
import {editDomain, normalizeDomainInformation, normalizeDomainList, normalizeDomains} from './domainNormalizers';

export const fetchDomainListSuccess = createAction('domainsAction/fetchDomainListSuccess', apiDomains => ({
  payload: normalizeDomainList(apiDomains)
}));

export const fetchInformationSuccess = createAction('domainsAction/fetchDomainInformationSuccess', apiDomains => ({
  payload: normalizeDomainInformation(apiDomains)
}));

export const fetchDomainsSuccess = createAction('domainsAction/fetchDomainsSuccess', apiDomains => ({
  payload: normalizeDomains(apiDomains)
}));

export const updateDomainSuccess = createAction('domainsAction/updateDomainSuccess', (apiDomain, domainList) => ({
  payload: editDomain(domainList, apiDomain)
}));

export const updatePrimarySuccess = createAction('domainsAction/updateDomainPrimarySuccess', apiDomains => ({
  payload: normalizeDomainInformation(apiDomains)
}));

export const updateDomainInformationSuccess = createAction('domainsAction/updateInformationSuccess', information => ({
  payload: normalizeDomainInformation(information)
}));
