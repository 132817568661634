import {
  Accordion,
  Button,
  Checkbox,
  Drawer,
  Icon,
  Input,
  Label,
  TextArea,
  Tooltip
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { CreatableSelect} from "@intility/bifrost-react-select";
import PropTypes from "prop-types";
import './printerDrawer.scss';
import React, { useEffect, useState } from "react";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocationsAsync } from "../../../../redux/company/locations/locationThunks";
import {
  addPrinterAsync,
  deletePrinterAsync,
  updatePrinterAsync
} from "../../../../redux/company/printers/printerThunks";
import { emptyGuid } from "../../../../utils/guid";
import { faCircleInfo, faTimes, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import apiFetch from "../../../../utils/apiFetch";
import { Printer } from "../../../../utils/hub/models";

export const PrinterDrawer = ({isOpen, setIsOpen, isDisabled, printer, setPrinter}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const [editablePrinter, setEditablePrinter] = useState(printer);
  const {user} = useSelector(state => state.auth);
  const [appDescription, setAppDescription] = useState('');
  const [applicationOption, setApplicationOption] = useState();
  const [manufacturerList, setManufacturerList] = useState([]);
  const [modelList, setModelList] = useState();
  const [model, setModel] = useState({value: '', label: ''});
  const [manufacturer, setManufacturer] = useState({value: '', label: ''});
  const [location, setLocation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditablePrinter(printer);
    if (printer?.id) {
      const location = locationList?.find(l => l.id === printer?.locationId);
      if (location) {
        setLocation({ label: location?.name, value: location?.id });
      } else {
        setLocation(null);
      }
      setManufacturer({value: printer?.manufacturer, label: printer?.manufacturer})
      setModel({value: printer?.model, label: printer?.model});
    } else {
      setLocation(null);
    }
  }, [printer]);

  useEffect(() => {
    setModelList(manufacturerList?.find(m => m.manufacturer === editablePrinter?.manufacturer)?.models?.map(m => ({label: m, value: m})) || []);
  }, [manufacturer.value])

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    if (id) {
      apiFetch(`/companies/${id}/printers/models`)
        .then(response => {
          if (!response.ok) {
            setManufacturerList([ {manufacturer: '', models: []} ]);
            return [];
          }
          return response.json()
        })
        .then(models => setManufacturerList(models));
    }
  }, [id, dispatch])


  const savePrinter = () => {
    dispatch(addPrinterAsync(editablePrinter));
    onClose();
  }

  const editPrinter = () => {
    dispatch(updatePrinterAsync(editablePrinter));
    onClose();
  }

  const onChange = (e, name) => {
    const {value} = e;

    setEditablePrinter(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const onClose = () => {
    setIsOpen(false);
    setEditablePrinter(new Printer());
    setPrinter(new Printer());
    setManufacturer({value: '', label: ''});
    setModel({value: '', label: ''});
    setApplicationOption(null);
    setLocation(null);
    setAppDescription('');
  }

  const onCheckboxChange = (e, name) => {
    const {checked} = e.target;

    setEditablePrinter(
      produce(draft => {
        draft[name] = checked === true ? 1 : 0;
      })
    );
  };

  const addApplication = () => {
    if (!applicationOption?.value) return;
    const newApp = {
      applicationId: applicationOption?.value,
      printerId: emptyGuid,
      comment: appDescription
    };
    setEditablePrinter(
      produce(draft => {
        draft.applications = [...editablePrinter.applications, newApp];
      })
    );
    setApplicationOption(null);
    setAppDescription('');
  }

  const removeApplication = (app) => {
    const index = editablePrinter.applications.findIndex(obj => obj.applicationId === app.applicationId);
    setEditablePrinter(produce(editablePrinter, draft => {
      draft.applications.splice(index, 1);
    }));
  }

  const deletePrinter = (id) => {
    dispatch(deletePrinterAsync(id))
    setIsOpen(false);
  };

  const handleCreate = (option, name) => {
    const newOption = {
      value: option,
      label: option
    }
    if (name === 'manufacturer') {
      const listOption = {
        manufacturer: option,
        models: []
      }
      setManufacturerList(manufacturerList => [...manufacturerList, listOption]);
      setManufacturer(newOption);
    } else if (name === 'model') {
      setModelList(modelsList => [...modelsList, newOption]);
      setModel(newOption);
    }
    setEditablePrinter(
      produce(draft => {
        draft[name] = option;
      })
    );
  }

  const locationsOptions = locationList && locationList.map(l => ({label: l?.name, value: l.id}));
  const applicationOptions = applicationList && applicationList?.filter(a => a.isEnabled)?.map(a => ({ label: a?.name, value: a?.id}))

  return (
    <Drawer id='printer-drawer' header={printer?.id ? "Edit printer" : "Add printer"} isOpen={isOpen} onRequestClose={() => onClose()} overlay footer={printer?.id ?
      <div className='drawer-bottom-button-row'>
        <Button onClick={() => deletePrinter(printer.id)} icon={faTrashCan} variant={"outline"} state={"alert"}>Delete</Button>
        <div>
          <Button style={{marginRight: 'var(--bfs12)'}} onClick={() => onClose()}>Cancel</Button>
          <Button variant={"filled"} onClick={() => editPrinter()}>Save</Button>
        </div>
      </div> :
      <div className='drawer-bottom-button-row'>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button variant={"filled"} onClick={() => savePrinter()}>Add</Button>
      </div>
    }>
      <div className='printer-drawer-input bfl-grid'>
        <CreatableSelect options={manufacturerList?.map(m => ({label: m.manufacturer, value: m.manufacturer}))} value={manufacturer} label='Manufacturer' name='manufacturer' onCreateOption={option => handleCreate(option, 'manufacturer')} onChange={e => {
          onChange(e, 'manufacturer');
          setManufacturer(e);
          setModel({value: '', label: ''});
        }}/>
        <CreatableSelect options={modelList} value={model} label='Model' name='model' onCreateOption={option => handleCreate(option, 'model')} onChange={e => {
          onChange(e, 'model');
          setModel(e);
        }}/>
        <div>
          <Label>
            <span>Serial number <Tooltip content='The serial number is usually pasted on top of the printer or inside the front cover.' placement='top'>
              <Icon style={{color: 'var(--bfc-base-c-theme)'}} icon={faCircleInfo}/>
              </Tooltip>
            </span>
          </Label>
          <Input value={editablePrinter?.serialNumber} label='Serial number' hideLabel name='serialNumber' onChange={e => onChange(e.target, 'serialNumber')}/>
        </div>
        <div>
          <Label>
            <span>MAC address <Tooltip content='If you do not know how to find the mac address, please let us know and we will assist you.' placement='top'>
              <Icon style={{color: 'var(--bfc-base-c-theme)'}} icon={faCircleInfo}/>
              </Tooltip>
            </span>
          </Label>
          <Input value={editablePrinter?.macAddress} label='Mac address' hideLabel name='macAddress' onChange={e => onChange(e.target, 'macAddress')}/>
        </div>
        <Select placeholder='Select location' label='Location' value={location} options={locationsOptions} onChange={option => {
          setLocation(option);
          onChange(option, 'locationId');
        }} />
        <Input value={editablePrinter?.floor} label='Floor' name='floor' onChange={e => onChange(e.target, 'floor')}/>
        <Input value={editablePrinter?.room} label='Room' name='room' onChange={e => onChange(e.target, 'room')}/>
      </div>
      <div className='printer-drawer-checkboxes'>
        <Label>Printer settings</Label>
        <div className='bfl-grid'>
          <Checkbox button checked={editablePrinter?.securePrintStatus === 1} onChange={e => onCheckboxChange(e, 'securePrintStatus')} label='Secure print' />
          <Checkbox button checked={editablePrinter?.guestPrintStatus === 1} onChange={e => onCheckboxChange(e, 'guestPrintStatus')} label='Guest print' />
        </div>
      </div>
      <div className='application-box'>
        <Select label='Application name' description='Applications with server generated print tasks, e. g. SAP and Dynamics 365.' options={applicationOptions} value={applicationOption} onChange={option => setApplicationOption(option)} />
        <TextArea label='Description' rows={2} onChange={e => setAppDescription(e.target.value)} value={appDescription}/>
        <Button variant={"outline"} onClick={() => addApplication()}>Add application</Button>
        <Accordion>
          {editablePrinter?.applications?.map((a, i) => {
            return (
              <Accordion.Item key={i} title={applicationList?.filter(a => a.isEnabled)?.find(app => app.id === a?.applicationId)?.name || ''} actions={
                <Accordion.Action
                  icon={faTimes}
                  disabled={isDisabled}
                  onClick={() => removeApplication(a)}
                  aria-label='Delete'
                  style={{color: 'var(--bfc-alert)'}}
                />}>
                <p>{a?.comment}</p>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </div>
    </Drawer>
  )

}

PrinterDrawer.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  setPrinter: PropTypes.func,
  printer: PropTypes.object
}

export default PrinterDrawer
