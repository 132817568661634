import {faPuzzlePiece} from '@fortawesome/pro-regular-svg-icons';
import ServiceSettingsDetails from '../../components/BackOffice/ServiceSettingsDetails/ServiceSettingsDetails';

export default {
  title: 'Service settings',
  path: 'services/',
  icon: faPuzzlePiece,
  editable: false,
  component: ServiceSettingsDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Service settings';
  }
};
