import OverviewArticle from "./OverviewArticle";
import './overviewArticles.scss';
import hi5 from './../../../../../assets/images/FrontPage/Image_hi5.png';
import board from './../../../../../assets/images/FrontPage/Image_board.png';
import people from './../../../../../assets/images/FrontPage/Image_people.png';
import {useSelector} from 'react-redux';
import {AdminPermission} from '../../../../Permissions';
import React, {useEffect, useState} from 'react';
import {fetchPending, fetchUsers, RegistrationModal} from '../Settings/Settings';
import {useFloatingMessage} from '@intility/bifrost-react';
import useAdminPermission from '../../../../../hooks/useAdminPermission';

export const OverviewArticles = () => {
  const {id} = useSelector(state => state.company.data.info);
  const [users, setUsers] = useState([]);
  const [pending, setPending] = useState([]);
  const [open, setOpen] = useState(false);
  const { showFloatingMessage } = useFloatingMessage();
  const canEdit = useAdminPermission();

  useEffect(() => {
    if (id !== null && id !== undefined && id !== '') {
      fetchUsers(id, setUsers);
      fetchPending(id, setPending);
    }
  }, [id]);
  return (
    <div id='overview-articles' className='bfl-grid'>
      <OverviewArticle
        image={board}
        link={`/${id}/tasks`}
        header='Tasks'
      >
        Overview of all upcoming, current and completed tasks in order to onboard your company.
      </OverviewArticle>
      <OverviewArticle
        image={hi5}
        onClick={() => canEdit ? setOpen(true) : showFloatingMessage('This action requires admin rights.', {state: 'alert'})}
        header='Collaboration'
      >
        See and invite colleagues to help you register company information.
      </OverviewArticle>
      <OverviewArticle
        image={people}
        link={`/${id}/contactUs`}
        header='Contact project team'
      >
        Chat, discuss and ask questions to Intilitys project team.
      </OverviewArticle>
      <AdminPermission>
        <RegistrationModal pending={pending} setPending={setPending} users={users} setUsers={setUsers} showFloatingMessage={showFloatingMessage} setOpen={setOpen} open={open}/>
      </AdminPermission>
    </div>
  );
}

export default OverviewArticles