import {createReducer, createSlice} from '@reduxjs/toolkit';
import {
  fetchDetailsListSuccess,
  fetchDetailsSuccess,
  fetchFinancialDetailsSuccess,
  fetchInformationSuccess,
  updateDetailInformationSuccess,
  updateDetailSuccess
} from './detailActions';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanySuccess} from '../companyUpdateSlice';

const updateOnInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchDetailsSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateDetailInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const detailInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    detailCount: 0,
    projectTeam: '',
    rotLink: '',
    goLiveDate: null,
    goLiveConfirmed: false,
    planningDueDate: null,
    preparationDueDate: null,
    testingDueDate: null,
    vismaCompany: '',
    parentCompany: '',
    companyCode: '',
    csModule: ''
  },
  updateOnInformationChangeReducer((payload, state) => {
    state.id = payload.detailsPlatform.information.id;
    state.companyId = payload.detailsPlatform.information.companyId;
    state.detailCount = payload.detailsPlatform.information.detailCount;
    state.isEnabled = payload.detailsPlatform.information.isEnabled;
    state.modifiedAt = payload.detailsPlatform.information.modifiedAt;
    state.projectTeam = payload.detailsPlatform.information.projectTeam;
    state.rotLink = payload.detailsPlatform.information.rotLink;
    state.vismaCompany = payload.detailsPlatform.information.vismaCompany;
    state.parentCompany = payload.detailsPlatform.information.parentCompany;
    state.goLiveDate = payload.detailsPlatform.information.goLiveDate;
    state.goLiveConfirmed = payload.detailsPlatform.information.goLiveConfirmed;
    state.planningDueDate = payload.detailsPlatform.information.planningDueDate;
    state.preparationDueDate = payload.detailsPlatform.information.preparationDueDate;
    state.testingDueDate = payload.detailsPlatform.information.testingDueDate;
    state.companyCode = payload.detailsPlatform.information.companyCode;
    state.csModule = payload.detailsPlatform.information.csModule;
    return state;
  })
);

const updateOnDetailChangeReducer = getState => {
  return {
    [fetchDetailsSuccess]: (state, action) => getState(action.payload, state),
    [updateDetailSuccess]: (state, action) => getState(action.payload, state)
  };
};

const updateOnFinancialDetailChangeReducer = getState => {
  return {
    [fetchFinancialDetailsSuccess]: (state, action) => getState(action.payload, state),
  };
};

export const detailsReducer = createReducer(
  [],
  updateOnDetailChangeReducer((payload, state) => {
    state = payload.detailsPlatform.detailList;
    return state;
  })
);

export const financialDetailsReducer = createReducer(
  [],
  updateOnFinancialDetailChangeReducer((payload, state) => {
    state = payload.financialDetails;
    return state;
  })
);

export const detailsStatusSlice = createSlice({
  name: 'detailsAction',
  initialState: {
    fetching: false,
    fetchingFinancial: false,
    updating: false,
    error: false,
    financialError: false
  },
  reducers: {
    fetchDetails: state => {
      state.fetching = true;
    },
    fetchFinancialDetails: state => {
      state.fetchingFinancial = true;
    },
    fetchDetailsError: state => {
      state.fetching = false;
      state.error = true;
    },
    fetchFinancialDetailsError: state => {
      state.fetchingFinancial = false;
      state.financialError = true;
    },
    updateDetails: state => {
      state.updating = true;
    },
    updateDetailsError: state => {
      state.updating = false;
      state.error = true;
    },
    updateDetailsClear: state => {
      state.updating = false;
      state.fetching = false;
      state.error = false;
    }
  },
  extraReducers: {
    [fetchDetailsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [fetchFinancialDetailsSuccess]: state => {
      state.fetchingFinancial = false;
      state.financialError = false;
    },
    [fetchDetailsListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateDetailSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateDetailInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchDetails,
  fetchFinancialDetails,
  updateDetailsClear,
  fetchDetailsError,
  fetchFinancialDetailsError,
  updateDetails,
  updateDetailsError
} = detailsStatusSlice.actions;
