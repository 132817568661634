import {createAction} from '@reduxjs/toolkit';
import {normalizeCommunicationInformation, normalizeCommunicationItems} from './communicationNormalizers';

export const updateCommunicationItemsSuccess = createAction(
  'communicationAction/updateCommunicationItemsSuccess',
  apiDefaultItems => ({
    payload: normalizeCommunicationItems(apiDefaultItems)
  })
);
export const fetchCommunicationItemsSuccess = createAction(
  'communicationAction/fetchCommunicationItemsSuccess',
  apiDefaultItems => ({
    payload: normalizeCommunicationItems(apiDefaultItems)
  })
);

export const updateCommunicationItemsInformationSuccess = createAction(
  'communicationAction/updateInformationSuccess',
  information => ({
    payload: normalizeCommunicationInformation(information)
  })
);
