import {faServer} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 20,
  text: 'Systems and services registered',
  icon: faServer,
  isEnabled: data => {
    if (data.applicationPlatform?.information?.isEnabled) return true;
    if (data.communicationPlatform?.information?.isEnabled) return true;
    return false;
  },
  isEnabledOverview: data => {
    if (data.applicationPlatform?.isEnabled) return true;
    if (data.communicationPlatform?.isEnabled) return true;
    return false;
  },
  getStatusText: data => {
    return data.applicationPlatform.information.modifiedAt;
  }
};
