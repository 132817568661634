import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  fetchOnboardTickets,
  fetchOnboardTicketsError,
  updateOnboardTickets,
  updateOnboardTicketsError
} from './onboardTicketReducers';
import {fetchOnboardTicketsSuccess} from './onboardTicketActions';
import {fetchOnboardTasksAsync} from '../onboardTasks/onboardTaskThunks';

export const fetchOnboardTicketsAsync = (companyId, companyCode) => (dispatch, getState) => {
  const {fetching} = getState().company.data.ticketPlatform.ticketStatus;
  if (fetching) return null;
  dispatch(fetchOnboardTickets());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/tickets/range`,
    method: 'GET'
  }).then(onboardTickets => dispatch(fetchOnboardTicketsSuccess(onboardTickets)))
    .catch(error => dispatch(fetchOnboardTicketsError(error)));
};

export const addProjectAsync = newProject => (dispatch, getState) => {
  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/Tickets/project`,
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: newProject,
  });
};

export const getRotUserIdAsync = (email, id) => (dispatch, getState) => {
  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/Tickets/rotUserId?email=${email}`,
    method: 'GET',
  })
  .then(rotUserId => {
    if (!rotUserId) {
      throw new Error('Bad request');
    }
    return rotUserId;
  })
  .catch(error => {
    console.error(error);
  });
};


export const addOnboardTicketAsync = onboardTicket => (dispatch, getState) => {
  const {ticketStatus} = getState().company.data.ticketPlatform;
  if (ticketStatus.updating) return null;
  dispatch(updateOnboardTickets());

  const {id} = getState().company.data.info;
  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/tickets`,
    method: 'POST',
    body: onboardTicket
  }).then(() => dispatch(fetchOnboardTicketsAsync(id, companyCode)))
    .catch(error => dispatch(updateOnboardTicketsError(error)))
    .then(() => dispatch(fetchOnboardTasksAsync(id)))
    .catch(error => dispatch(updateOnboardTicketsError(error)));
};

export const updateOnboardTicketAsync = ticket => (dispatch, getState) => {
  const { companyCode } = getState().company.data.detailsPlatform.information;
  const {updating} = getState().company.data.ticketPlatform.ticketStatus;
  if (updating) return null;
  dispatch(updateOnboardTickets());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/tickets/${ticket.id}`,
    method: 'PUT',
    body: ticket
  }).then(() => dispatch(fetchOnboardTicketsAsync(id, companyCode)))
    .catch(error => dispatch(updateOnboardTicketsError(error)));
};

export const deleteOnboardTicketAsync = onboardTicketId => (dispatch, getState) => {
  const { companyCode } = getState().company.data.detailsPlatform.information;
  const {updating} = getState().company.data.ticketPlatform.ticketStatus;
  if (updating) return null;
  dispatch(updateOnboardTickets());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/tickets/${onboardTicketId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchOnboardTicketsAsync(id, companyCode)))
    .catch(error => dispatch(updateOnboardTicketsError(error)));
};
