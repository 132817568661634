import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  fetchComputerSetup,
  fetchComputerSetupError,
  updateComputerSetup,
  updateComputerSetupError
} from './computerSetupReducers';
import {fetchComputerSetupSuccess, updateComputerSetupSuccess} from './computerSetupActions';
import {platformType, updatePlatformAsync} from '../platformUpdateThunk';

export const fetchComputerSetupAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.update;

  if (fetching) return null;
  dispatch(fetchComputerSetup());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/computerSetup`,
    method: 'GET'
  }).then(computerSetup => dispatch(fetchComputerSetupSuccess(computerSetup)))
    .catch(error => dispatch(fetchComputerSetupError(error)));
};

export const updateComputerSetupAsync = data => (dispatch, getState) => {
  const {fetching} = getState().company.update;

  if (fetching) return null;
  dispatch(updateComputerSetup());

  const {desktopSettings} = getState().company.data.computerSetupPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${desktopSettings.companyId}/computerSetup/${desktopSettings.id}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: JSON.stringify(data)
  }).then(computerSetup => dispatch(updateComputerSetupSuccess(computerSetup)))
    .catch(error => dispatch(updateComputerSetupError(error)));
};

export const setChosenWallpaper = image => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  let updateBody = {
    defaultBackground: 2
  };
  if (image.hasCustom !== null) {
    updateBody = {
      defaultBackground: 2,
      customWallpaper: {
        isEnabled: image.isEnabled
      }
    };
  }

  if (image.type !== false) {
    updateBody.defaultBackground = image.type;
  }
  return dispatch(updatePlatformAsync(platformType.COMPUTERSETUP, id, updateBody));
};

export const uploadWallpaper = (file, id) => async dispatch => {
  const formData = new FormData();
  const {name: fileName} = file;
  formData.append(`wallpaperUploads/${fileName}`, file);

  return apiFetch(`/computerSetup/${id}/wallpaper`, {
    method: 'POST',
    body: formData
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to upload new wallpaper.');
      }
      return response.json();
    })
    .then(wallpaper => {
      const uploadedImage = {
        ...wallpaper,
        isEnabled: true
      };
      dispatch(setChosenWallpaper(uploadedImage));
      return wallpaper;
    })
    .catch(error => dispatch(updateComputerSetupError(error)));
};

export const setScreensaverTimeout = option => (dispatch, getState) => {
  const {value} = option;
  const {id} = getState().company.data.info;
  const {desktopSettings} = getState().company.data.computerSetupPlatform;

  return dispatch(
    updatePlatformAsync(platformType.COMPUTERSETUP, id, {
      screensaverTimeout: parseInt(value) || 0,
      isEnabled: desktopSettings.isEnabled
    })
  );
};

export const setSetupValue = (name, value) => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  const {desktopSettings} = getState().company.data.computerSetupPlatform;

  return dispatch(
    updatePlatformAsync(platformType.COMPUTERSETUP, id, {
      [name]: value,
      isEnabled: desktopSettings.isEnabled
    })
  );
};

export const setMacUrl = computerSetup => dispatch => {
  return dispatch(
    updatePlatformAsync(platformType.COMPUTERSETUP, computerSetup.companyId, {
      iamsMacUrl: computerSetup.iamsMacUrl,
      isEnabled: computerSetup.isEnabled
    })
  );
};
