import {createAction} from '@reduxjs/toolkit';
import {
  addApplication,
  addAppRelation,
  addInternalContact,
  addNewApplicationFilter,
  addVendorContact,
  deleteApplication,
  deleteApplicationFilter,
  deleteAppRelation,
  deleteInternalContact,
  deleteVendorContact,
  editApplication,
  editApplicationUserRelation,
  normalizeApplicationFilters,
  normalizeApplications,
  normalizeApplicationsInformation,
  normalizeApplicationsList,
  toggleApplication,
  updateInternalContact,
  updateVendorContact
} from './applicationsNormalizers';

export const fetchApplicationsListSuccess = createAction(
  'applicationAction/fetchApplicationsListSuccess',
  apiApplications => ({
    payload: normalizeApplicationsList(apiApplications)
  })
);

export const addApplicationSuccess = createAction(
  'applicationAction/addApplicationSuccess', (item, itemList) => ({
    payload: addApplication(item, itemList)
  }));

export const fetchInformationSuccess = createAction(
  'applicationAction/fetchApplicationsInformationSuccess',
  apiApplications => ({
    payload: normalizeApplicationsInformation(apiApplications)
  })
);

export const updateApplicationSuccess = createAction(
  'applicationAction/updateApplicationSuccess',
  (application, applicationList) => ({
    payload: editApplication(applicationList, application)
  })
);
export const toggleApplicationSuccess = createAction(
  'applicationAction/updateApplicationSuccess',
  (applicationId, applicationList) => ({
    payload: toggleApplication(applicationList, applicationId)
  })
);

export const fetchApplicationFiltersSuccess = createAction(
  'applicationAction/fetchApplicationFiltersSuccess',
  filters => ({
    payload: normalizeApplicationFilters(filters)
  })
);

export const deleteApplicationFilterSuccess = createAction(
  'applicationAction/deleteApplicationFilterSuccess',
  (filterId, filterList) => ({
    payload: deleteApplicationFilter(filterId, filterList)
  })
);

export const deleteApplicationSuccess = createAction(
  'applicationAction/deleteApplicationSuccess',
  (applicationId, filterList) => ({
    payload: deleteApplication(applicationId, filterList)
  })
);

export const addApplicationFilterSuccess = createAction(
  'applicationAction/addApplicationFilterSuccess', (filter, filterList) => ({
    payload: addNewApplicationFilter(filter, filterList)
  }));

export const fetchApplicationsSuccess = createAction('applicationAction/fetchApplicationsSuccess', apiApplications => ({
  payload: normalizeApplications(apiApplications)
}));

export const fetchApplicationSuccess = createAction('applicationAction/fetchApplicationsSuccess', apiApplications => ({
  payload: normalizeApplications(apiApplications)
}));

export const updateApplicationsSuccess = createAction(
  'applicationAction/updateApplicationsSuccess',
  (application, applicationList) => ({
    payload: editApplication(applicationList, application)
  })
);

export const updateApplicationInformationSuccess = createAction(
  'applicationAction/updateInformationSuccess',
  information => ({
    payload: normalizeApplicationsInformation(information)
  })
);

export const updateApplicationUserSuccess = createAction('applicationAction/updateApplicationUserSuccess', (appUserRelation, apiApplications, isDisabled) => ({
  payload: editApplicationUserRelation(appUserRelation, apiApplications, isDisabled)
}));

export const addVendorContactSuccess = createAction('applicationAction/addVendorContactSuccess', (appId, newContact, apiApplications) => ({
  payload: addVendorContact(appId, newContact, apiApplications)
}));

export const addInternalContactSuccess = createAction('applicationAction/addInternalContactSuccess', (appId, newContact, apiApplications) => ({
  payload: addInternalContact(appId, newContact, apiApplications)
}));

export const updateVendorContactSuccess = createAction('applicationAction/updateVendorContactSuccess', (appId, updatedContact, apiApplications) => ({
  payload: updateVendorContact(appId, updatedContact, apiApplications)
}));

export const updateInternalContactSuccess = createAction('applicationAction/updateInternalContactSuccess', (appId, updatedContact, apiApplications) => ({
  payload: updateInternalContact(appId, updatedContact, apiApplications)
}));

export const addAppRelationSuccess = createAction('applicationAction/addAppRelationSuccess', (appId, newRelation, apiApplications) => ({
  payload: addAppRelation(appId, newRelation, apiApplications)
}));

export const deleteVendorContactSuccess = createAction('applicationAction/deleteVendorContactSuccess', (appId, contactId, apiApplications) => ({
  payload: deleteVendorContact(appId, contactId, apiApplications)
}));

export const deleteInternalContactSuccess = createAction('applicationAction/deleteInternalContactSuccess', (appId, contactId, apiApplications) => ({
  payload: deleteInternalContact(appId, contactId, apiApplications)
}));

export const deleteAppRelationSuccess = createAction('applicationAction/deleteAppRelationSuccess', (appId, relationId, list, apiApplications) => ({
  payload: deleteAppRelation(appId, relationId, list, apiApplications)
}));
