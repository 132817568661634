import ApplicationDetails from '../../components/BackOffice/ApplicationDetails/ApplicationDetails';
import {faServer} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Applications',
  path: 'applications/',
  icon: faServer,
  editable: false,
  component: ApplicationDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
