export const IamsSearchOptions = [
  {value: 'firstName', label: 'First name'},
  {value: 'lastName', label: 'Last name'},
  {value: 'locationName', label: 'Location'},
  {value: 'billing', label: 'Billing company'},
  {value: 'model', label: 'Model'},
  {value: 'state', label: 'State'},
  {value: 'memory', label: 'Memory'},
  {value: 'operatingSystem', label: 'OS'},
  {value: 'warranty', label: 'Warranty'},
  {value: 'serial', label: 'Serial Number'}
];

export const computerOptions = [
  {value: 'Keep', label: 'Keep existing computer'},
  {value: 'Replace', label: 'Replace computer'},
  {value: 'Upgrade', label: 'Upgrade OS'},
  {value: 'edit', label: 'Edit scan'},
  {value: 'delete', label: 'Delete scan'}
];

export const osOptions = [
  {value: 'Microsoft Windows 10 Pro', label: 'Windows 10 Pro'},
  {value: 'Microsoft Windows 10 Enterprise', label: 'Windows 10 Enterprise'},
  {value: 'Microsoft Windows 10 Home', label: 'Windows 10 Home'},
  {value: 'MacOS', label: 'MacOS'},
  {value: 'Microsoft Windows 8', label: 'Windows 8'},
  {value: 'Microsoft Windows 7', label: 'Windows 7'}
];

export const recommendOptions = [
  {value: 'Replace', label: 'Replace Now'},
  {value: 'Upgrade', label: 'Upgrade OS'},
  {value: 'Consider', label: 'Consider replacing'},
  {value: 'Keep', label: 'Keep Computer'},
  {value: 'Awaiting', label: 'Awaiting review'}
];

export const replacementFilter = [
  {value: 0, label: 'Show all'},
  {value: 1, label: 'Keep existing computer'},
  {value: 2, label: 'Computer replaced'}
];

export const replacementOptions = [
  {value: 'Computer', label: 'Computer'},
  {value: 'Screen', label: 'Screen'},
  {value: 'Docking', label: 'Docking'},
  {value: 'Peripherals', label: 'Peripherals'}
];

export const filterTypeOptions = [
  {value: 0, label: 'StartsWith'},
  {value: 1, label: 'EndsWith'},
  {value: 2, label: 'Contains'},
  {value: 3, label: 'Equals'}
];

export const filterParameterOptions = [
  {value: 0, label: 'SoftwareName'},
  {value: 1, label: 'SoftwarePublisher'}
];

export const KeepComputer = 'Keep';
export const ReplaceComputer = 'Replace';
export const OsComputer = 'Upgrade';
export const ConsiderReplace = 'Consider';
export const AwaitingReview = 'Awaiting';
export const DeleteScan = 'delete';
export const EditScan = 'edit';