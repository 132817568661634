const countryOptions = [
  {value: 'Norway', label: 'Norway', type: 'country'},
  {value: 'Sweden', label: 'Sweden', type: 'country'},
  {value: 'Denmark', label: 'Denmark', type: 'country'},
  {value: 'Finland', label: 'Finland', type: 'country'},
  {value: 'United Kingdom', label: 'United Kingdom', type: 'country'},
  {value: 'United States', label: 'United States', type: 'country'},
  {value: 'Afghanistan', label: 'Afghanistan', type: 'country'},
  {value: 'Albania', label: 'Albania', type: 'country'},
  {value: 'Algeria', label: 'Algeria', type: 'country'},
  {value: 'Andorra', label: 'Andorra', type: 'country'},
  {value: 'Angola', label: 'Angola', type: 'country'},
  {value: 'Antigua & Deps', label: 'Antigua & Deps', type: 'country'},
  {value: 'Argentina', label: 'Argentina', type: 'country'},
  {value: 'Armenia', label: 'Armenia', type: 'country'},
  {value: 'Australia', label: 'Australia', type: 'country'},
  {value: 'Austria', label: 'Austria', type: 'country'},
  {value: 'Azerbaijan', label: 'Azerbaijan', type: 'country'},
  {value: 'Bahamas', label: 'Bahamas', type: 'country'},
  {value: 'Bahrain', label: 'Bahrain', type: 'country'},
  {value: 'Bangladesh', label: 'Bangladesh', type: 'country'},
  {value: 'Barbados', label: 'Barbados', type: 'country'},
  {value: 'Belarus', label: 'Belarus', type: 'country'},
  {value: 'Belgium', label: 'Belgium', type: 'country'},
  {value: 'Belize', label: 'Belize', type: 'country'},
  {value: 'Benin', label: 'Benin', type: 'country'},
  {value: 'Bhutan', label: 'Bhutan', type: 'country'},
  {value: 'Bolivia', label: 'Bolivia', type: 'country'},
  {value: 'Bosnia Herzegovina', label: 'Bosnia Herzegovina', type: 'country'},
  {value: 'Botswana', label: 'Botswana', type: 'country'},
  {value: 'Brazil', label: 'Brazil', type: 'country'},
  {value: 'Brunei', label: 'Brunei', type: 'country'},
  {value: 'Bulgaria', label: 'Bulgaria', type: 'country'},
  {value: 'Burkina', label: 'Burkina', type: 'country'},
  {value: 'Burundi', label: 'Burundi', type: 'country'},
  {value: 'Cambodia', label: 'Cambodia', type: 'country'},
  {value: 'Cameroon', label: 'Cameroon', type: 'country'},
  {value: 'Canada', label: 'Canada', type: 'country'},
  {value: 'Cape Verde', label: 'Cape Verde', type: 'country'},
  {
    value: 'Central African Rep',
    label: 'Central African Rep',
    type: 'country'
  },
  {value: 'Chad', label: 'Chad', type: 'country'},
  {value: 'Chile', label: 'Chile', type: 'country'},
  {value: 'China', label: 'China', type: 'country'},
  {value: 'Colombia', label: 'Colombia', type: 'country'},
  {value: 'Comoros', label: 'Comoros', type: 'country'},
  {value: 'Congo', label: 'Congo', type: 'country'},
  {
    value: 'Congo, Democratic Rep',
    label: 'Congo, Democratic Rep',
    type: 'country'
  },
  {value: 'Costa Rica', label: 'Costa', type: 'country'},
  {value: 'Croatia', label: 'Croatia', type: 'country'},
  {value: 'Cuba', label: 'Cuba', type: 'country'},
  {value: 'Cyprus', label: 'Cyprus', type: 'country'},
  {value: 'Czech Republic', label: 'Czech Republic', type: 'country'},

  {value: 'Djibouti', label: 'Djibouti', type: 'country'},
  {value: 'Dominica', label: 'Dominica', type: 'country'},
  {value: 'Dominican Republic', label: 'Dominican Republic', type: 'country'},
  {value: 'East Timor', label: 'East Timor', type: 'country'},
  {value: 'Ecuador', label: 'Ecuador', type: 'country'},
  {value: 'Egypt', label: 'Egypt', type: 'country'},
  {value: 'El Salvador', label: 'El Salvador', type: 'country'},
  {value: 'Equatorial Guinea', label: 'Equatorial Guinea', type: 'country'},
  {value: 'Eritrea', label: 'Eritrea', type: 'country'},
  {value: 'Estonia', label: 'Estonia', type: 'country'},
  {value: 'Ethiopia', label: 'Ethiopia', type: 'country'},
  {value: 'Fiji', label: 'Fiji', type: 'country'},

  {value: 'France', label: 'France', type: 'country'},
  {value: 'Gabon', label: 'Gabon', type: 'country'},
  {value: 'Gambia', label: 'Gambia', type: 'country'},
  {value: 'Georgia', label: 'Georgia', type: 'country'},
  {value: 'Germany', label: 'Germany', type: 'country'},
  {value: 'Ghana', label: 'Ghana', type: 'country'},
  {value: 'Greece', label: 'Greece', type: 'country'},
  {value: 'Grenada', label: 'Grenada', type: 'country'},
  {value: 'Guatemala', label: 'Guatemala', type: 'country'},
  {value: 'Guinea', label: 'Guinea', type: 'country'},
  {value: 'Guinea-Bissau', label: 'Guinea-Bissau', type: 'country'},
  {value: 'Guyana', label: 'Guyana', type: 'country'},
  {value: 'Haiti', label: 'Haiti', type: 'country'},
  {value: 'Honduras', label: 'Honduras', type: 'country'},
  {value: 'Hungary', label: 'Hungary', type: 'country'},
  {value: 'Iceland', label: 'Iceland', type: 'country'},
  {value: 'India', label: 'India', type: 'country'},
  {value: 'Indonesia', label: 'Indonesia', type: 'country'},
  {value: 'Iran', label: 'Iran', type: 'country'},
  {value: 'Iraq', label: 'Iraq', type: 'country'},
  {value: 'Ireland', label: 'Ireland', type: 'country'},
  {value: 'Israel', label: 'Israel', type: 'country'},
  {value: 'Italy', label: 'Italy', type: 'country'},
  {value: 'Ivory Coast', label: 'Ivory Coast', type: 'country'},
  {value: 'Jamaica', label: 'Jamaica', type: 'country'},
  {value: 'Japan', label: 'Japan', type: 'country'},
  {value: 'Jordan', label: 'Jordan', type: 'country'},
  {value: 'Kazakhstan', label: 'Kazakhstan', type: 'country'},
  {value: 'Kenya', label: 'Kenya', type: 'country'},
  {value: 'Kiribati', label: 'Kiribati', type: 'country'},
  {value: 'Korea North', label: 'Korea North', type: 'country'},
  {value: 'Korea South', label: 'Korea South', type: 'country'},
  {value: 'Kosovo', label: 'Kosovo', type: 'country'},
  {value: 'Kuwait', label: 'Kuwait', type: 'country'},
  {value: 'Kyrgyzstan', label: 'Kyrgyzstan', type: 'country'},
  {value: 'Laos', label: 'Laos', type: 'country'},
  {value: 'Latvia', label: 'Latvia', type: 'country'},
  {value: 'Lebanon', label: 'Lebanon', type: 'country'},
  {value: 'Lesotho', label: 'Lesotho', type: 'country'},
  {value: 'Liberia', label: 'Liberia', type: 'country'},
  {value: 'Libya', label: 'Libya', type: 'country'},
  {value: 'Liechtenstein', label: 'Liechtenstein', type: 'country'},
  {value: 'Lithuania', label: 'Lithuania', type: 'country'},
  {value: 'Luxembourg', label: 'Luxembourg', type: 'country'},
  {value: 'Macedonia', label: 'Macedonia', type: 'country'},
  {value: 'Madagascar', label: 'Madagascar', type: 'country'},
  {value: 'Malawi', label: 'Malawi', type: 'country'},
  {value: 'Malaysia', label: 'Malaysia', type: 'country'},
  {value: 'Maldives', label: 'Maldives', type: 'country'},
  {value: 'Mali', label: 'Mali', type: 'country'},
  {value: 'Malta', label: 'Malta', type: 'country'},
  {value: 'Marshall Islands', label: 'Marshall Islands', type: 'country'},
  {value: 'Mauritania', label: 'Mauritania', type: 'country'},
  {value: 'Mauritius', label: 'Mauritius', type: 'country'},
  {value: 'Mexico', label: 'Mexico', type: 'country'},
  {value: 'Micronesia', label: 'Micronesia', type: 'country'},
  {value: 'Moldova', label: 'Moldova', type: 'country'},
  {value: 'Monaco', label: 'Monaco', type: 'country'},
  {value: 'Mongolia', label: 'Mongolia', type: 'country'},
  {value: 'Montenegro', label: 'Montenegro', type: 'country'},
  {value: 'Morocco', label: 'Morocco', type: 'country'},
  {value: 'Mozambique', label: 'Mozambique', type: 'country'},
  {value: 'Myanmar', label: 'Myanmar', type: 'country'},
  {value: 'Namibia', label: 'Namibia', type: 'country'},
  {value: 'Nauru', label: 'Nauru', type: 'country'},
  {value: 'Nepal', label: 'Nepal', type: 'country'},
  {value: 'Netherlands', label: 'Netherlands', type: 'country'},
  {value: 'New Zealand', label: 'New Zealand', type: 'country'},
  {value: 'Nicaragua', label: 'Nicaragua', type: 'country'},
  {value: 'Niger', label: 'Niger', type: 'country'},
  {value: 'Nigeria', label: 'Nigeria', type: 'country'},

  {value: 'Oman', label: 'Oman', type: 'country'},
  {value: 'Pakistan', label: 'Pakistan', type: 'country'},
  {value: 'Palau', label: 'Palau', type: 'country'},
  {value: 'Panama', label: 'Panama', type: 'country'},
  {value: 'Papua New Guinea', label: 'Papua New Guinea', type: 'country'},
  {value: 'Paraguay', label: 'Paraguay', type: 'country'},
  {value: 'Peru', label: 'Peru', type: 'country'},
  {value: 'Philippines', label: 'Philippines', type: 'country'},
  {value: 'Poland', label: 'Poland', type: 'country'},
  {value: 'Portugal', label: 'Portugal', type: 'country'},
  {value: 'Qatar', label: 'Qatar', type: 'country'},
  {value: 'Romania', label: 'Romania', type: 'country'},
  {value: 'Russian Federation', label: 'Russian Federation', type: 'country'},
  {value: 'Rwanda', label: 'Rwanda', type: 'country'},
  {value: 'St Kitts & Nevis', label: 'St Kitts & Nevis', type: 'country'},
  {value: 'St Lucia', label: 'St Lucia', type: 'country'},
  {
    value: 'Saint Vincent & the Grenadines',
    label: 'Saint Vincent & the Grenadines',
    type: 'country'
  },
  {value: 'Samoa', label: 'Samoa', type: 'country'},
  {value: 'San Marino', label: 'San Marino', type: 'country'},
  {
    value: 'Sao Tome & Principe',
    label: 'Sao Tome & Principe',
    type: 'country'
  },
  {value: 'Saudi Arabia', label: 'Saudi Arabia', type: 'country'},
  {value: 'Senegal', label: 'Senegal', type: 'country'},
  {value: 'Serbia', label: 'Serbia', type: 'country'},
  {value: 'Seychelles', label: 'Seychelles', type: 'country'},
  {value: 'Sierra Leone', label: 'Sierra Leone', type: 'country'},
  {value: 'Singapore', label: 'Singapore', type: 'country'},
  {value: 'Slovakia', label: 'Slovakia', type: 'country'},
  {value: 'Slovenia', label: 'Slovenia', type: 'country'},
  {value: 'Solomon Islands', label: 'Solomon Islands', type: 'country'},
  {value: 'Somalia', label: 'Somalia', type: 'country'},
  {value: 'South Africa', label: 'South Africa', type: 'country'},
  {value: 'South Sudan', label: 'South Sudan', type: 'country'},
  {value: 'Spain', label: 'Spain', type: 'country'},
  {value: 'Sri Lanka', label: 'Sri Lanka', type: 'country'},
  {value: 'Sudan', label: 'Sudan', type: 'country'},
  {value: 'Suriname', label: 'Suriname', type: 'country'},
  {value: 'Swaziland', label: 'Swaziland', type: 'country'},

  {value: 'Switzerland', label: 'Switzerland', type: 'country'},
  {value: 'Syria', label: 'Syria', type: 'country'},
  {value: 'Taiwan', label: 'Taiwan', type: 'country'},
  {value: 'Tajikistan', label: 'Tajikistan', type: 'country'},
  {value: 'Tanzania', label: 'Tanzania', type: 'country'},
  {value: 'Thailand', label: 'Thailand', type: 'country'},
  {value: 'Togo', label: 'Togo', type: 'country'},
  {value: 'Tonga', label: 'Tonga', type: 'country'},
  {value: 'Trinidad & Tobago', label: 'Trinidad & Tobago', type: 'country'},
  {value: 'Tunisia', label: 'Tunisia', type: 'country'},
  {value: 'Turkey', label: 'Turkey', type: 'country'},
  {value: 'Turkmenistan', label: 'Turkmenistan', type: 'country'},
  {value: 'Tuvalu', label: 'Tuvalu', type: 'country'},
  {value: 'Uganda', label: 'Uganda', type: 'country'},
  {value: 'Ukraine', label: 'Ukraine', type: 'country'},
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
    type: 'country'
  },

  {value: 'Uruguay', label: 'Uruguay', type: 'country'},
  {value: 'Uzbekistan', label: 'Uzbekistan', type: 'country'},
  {value: 'Vanuatu', label: 'Vanuatu', type: 'country'},
  {value: 'Vatican City', label: 'Vatican', type: 'country'},
  {value: 'Venezuela', label: 'Venezuela', type: 'country'},
  {value: 'Vietnam', label: 'Vietnam', type: 'country'},
  {value: 'Yemen', label: 'Yemen', type: 'country'},
  {value: 'Zambia', label: 'Zambia', type: 'country'},
  {value: 'Zimbabwe', label: 'Zimbabwe', type: 'country'}
];

export default countryOptions;
