import {createAction} from '@reduxjs/toolkit';
import {
  addNewUser,
  clearUser,
  deleteMultipleUsers,
  editMultipleUsers,
  editUser,
  normalizeUsers,
  normalizeUsersInformation,
  normalizeUsersList
} from './usersNormalizers';

export const fetchUsersListSuccess = createAction('usersAction/fetchUsersListSuccess', apiUsers => ({
  payload: normalizeUsersList(apiUsers)
}));

export const fetchInformationSuccess = createAction('usersAction/fetchUsersInformationSuccess', apiUsers => ({
  payload: normalizeUsersInformation(apiUsers)
}));

export const fetchUsersSuccess = createAction('usersAction/fetchUsersSuccess', apiUsers => ({
  payload: normalizeUsers(apiUsers)
}));

export const updateUsersSuccess = createAction('usersAction/updateUsersSuccess', (users, userList) => ({
  payload: editMultipleUsers(userList, users)
}));

export const updateUserSuccess = createAction('usersAction/updateUserSuccess', (user, userList) => ({
  payload: editUser(userList, user)
}));

export const deleteUsersSuccess = createAction('usersAction/deleteUsersSuccess', (users, userList) => ({
  payload: deleteMultipleUsers(userList, users)
}));

export const addUserSuccess = createAction('usersAction/addUserSuccess', (user, userList) => ({
  payload: addNewUser(user, userList)
}));

export const clearUserSuccess = createAction('usersAction/clearUser', () => ({
  payload: clearUser()
}));

export const updateUsersInformationSuccess = createAction('usersAction/updateInformationSuccess', information => ({
  payload: normalizeUsersInformation(information)
}));
