import { useEffect, useState } from "react";
import apiFetch from "../../../../utils/apiFetch";
import ServiceArticle from "./ServiceArticle";
import './intilityServices.scss';
import { Button, Checkbox, FloatingArea, Grid, Icon, Input, Label, Modal } from "@intility/bifrost-react";
import { faSearch, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchIntilityServicesAsync } from "../../../../redux/company/intilityServices/intilityServiceThunks";

export const pricingValue = {
  ALL: 0,
  INCLUDED: 1,
  OPTIONAL: 2
}

export const articleView = {
  ALL: 0,
  ADDED: 1
}
export const IntilityServices = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {intilityServiceList} = useSelector(state => state.company.data.intilityServicePlatform);
  const [articles, setArticles] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [categories, setCategories] = useState([]);
  const [pricing, setPricing] = useState(pricingValue.ALL);
  const [view, setView] = useState(articleView.ALL);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const sortedData = articles?.length > 0 ? [...articles]
    .filter(a => {
      if (view === articleView.ALL) return a;
      if (view === articleView.ADDED) {
        return intilityServiceList?.find(i => i.articleId === a.id)?.isActive
      }
    })
    .filter(a => {
      if (pricing === pricingValue.ALL) return a;
      if (pricing === pricingValue.INCLUDED) return a?.included === true
      if (pricing === pricingValue.OPTIONAL) return a?.included === false
    })
    .filter(a => {
      if (selectedCategories.length === 0) return a;
      let found = false;
      a.categories.forEach(c => {
        if (selectedCategories.find(s => s === c?.names[0]?.name)) return found = true;
      })
      return found;
    }).filter(s => {
      return s?.textContents[0]?.title?.toLowerCase()?.includes(searchValue?.toLowerCase());
    }) : [];

  useEffect(() => {
    if (!id) return;
    apiFetch(`/Publish/services/${id}`)
      .then(response => {
        return response.json()
      }).then(value => {
      setArticles(value)
    })
    dispatch(fetchIntilityServicesAsync(id));
  }, [id]);

  useEffect(() => {
    if (articles?.length > 0) {
      const names = articles?.map(a => {
        return a?.categories?.map(c => {
          return c?.names[0]?.name;
        })
      });
      setCategories([...new Set(names?.flatMap(n => n))]);
    }

  }, [articles]);

  const checkCategories = (cat, e) => {
    if (e.target.checked) {
      // add to selectedToppings
      setSelectedCategories([...selectedCategories, cat])
    } else {
      // remove from selectedToppings
      setSelectedCategories(selectedCategories.filter(x => x !== cat))
    }
  }


  return (
    <div id='intility-services'>
      <div className='header-container'>
        <div className='service-header'>
          <h1>Services</h1>
          <p>
            Explore Intility's included services and available add-ons.
          </p>
        </div>
      </div>
      <div className='content'>
        <div className='page-content'>
          <div className='service-container'>
            {sortedData?.sort((a1, a2) => a1?.weight < a2?.weight ? 1 : -1)?.map((p, i) => {
              return (
                <ServiceArticle
                  article={p}
                  key={p.id}
                  id={p.id}
                  intilityServices={intilityServiceList}
                />
              )
            })}
          </div>
          <div className='filter-container'>
            <div className='filter-search'>
              <Input label='Search' hideLabel icon={faSearch}
                     iconButton
                     clearable
                     placeholder='Search'
                     onChange={e => setSearchValue(e.target.value)}
                     rightIcon/>
            </div>
            <div className='filter-buttons from-medium'>
              <Label>View</Label>
              <Checkbox label='All services' type={"radio"} checked={view === articleView.ALL} onChange={() => setView(articleView.ALL)}/>
              <Checkbox label='Added only' type={"radio"} checked={view === articleView.ADDED} onChange={() => setView(articleView.ADDED)}/>
              <Label style={{marginTop: 'var(--bfs24)'}}>Category</Label>
              {categories?.sort()?.map((c, i) => {
                return <Checkbox key={i} label={c} checked={selectedCategories.indexOf(c) > -1} onChange={e => checkCategories(c, e)}/>
              })}
              <Label style={{marginTop: 'var(--bfs24)'}}>Pricing</Label>
              <Checkbox label='All' type={"radio"} checked={pricing === pricingValue.ALL} onChange={() => setPricing(pricingValue.ALL)} />
              <Checkbox label='Included' type={"radio"} checked={pricing === pricingValue.INCLUDED} onChange={() => setPricing(pricingValue.INCLUDED)} />
              <Checkbox label='Optional' type={"radio"} checked={pricing === pricingValue.OPTIONAL} onChange={() => setPricing(pricingValue.OPTIONAL)} />
            </div>
          </div>
          <FloatingArea className='to-medium'>
            <Button pill rightIcon icon={faSliders} onClick={() => setShowModal(true)}>
              Filter
            </Button>
          </FloatingArea>
          <Modal className='to-medium services-filter-modal' header='Filter' isOpen={showModal} onRequestClose={() => setShowModal(false)}>
            <div className='filter-buttons'>
              <Label>View</Label>
              <Checkbox label='All services' type={"radio"} checked={view === articleView.ALL}
                        onChange={() => setView(articleView.ALL)} />
              <Checkbox label='Added only' type={"radio"} checked={view === articleView.ADDED}
                        onChange={() => setView(articleView.ADDED)} />
              <Label style={{ marginTop: 'var(--bfs24)' }}>Category</Label>
              <Grid small={2} medium={3}>
                  {categories?.sort()?.map((c, i) => {
                    return <Grid.Span key={i} small={1} medium={1}>
                      <Checkbox label={c} checked={selectedCategories.indexOf(c) > -1}
                                onChange={e => checkCategories(c, e)} />
                    </Grid.Span>
                  })}
              </Grid>
              <Label style={{ marginTop: 'var(--bfs24)' }}>Pricing</Label>
              <Checkbox label='All' type={"radio"} checked={pricing === pricingValue.ALL}
                        onChange={() => setPricing(pricingValue.ALL)} />
              <Checkbox label='Included' type={"radio"} checked={pricing === pricingValue.INCLUDED}
                        onChange={() => setPricing(pricingValue.INCLUDED)} />
              <Checkbox label='Optional' type={"radio"} checked={pricing === pricingValue.OPTIONAL}
                        onChange={() => setPricing(pricingValue.OPTIONAL)} />
            </div>
            <div className='services-filter-modal-buttons'>
              <Button pill variant='filled' onClick={() => setShowModal(false)}>Show results</Button>
            </div>
          </Modal>
        </div>
      </div>

    </div>
  )

}


export default IntilityServices