import {Button, Label} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import { SimpleBox } from "components/Box/Box";
import produce from 'immer';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {conditionalAccessStore, updateConditionalAccess} from 'redux/company/mobileDevices/mobileDevicesSlice';
import './conditionalAccess.scss';

export default function ConditionalAccess() {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const {isEnabled} = useSelector(state => state.company.data.mobilePlatform.mobileDevices);
  const conditionalAccess = useSelector(state => state.company.data.mobilePlatform.mobileDevices.conditionalAccessPolicies);
  const initialConditionalValue = useSelector(state => state.company.data.mobilePlatform.mobileDevices.conditionalAccessPolicies);

  const [conditionalAccessState, setConditionalAccessState] = useState(conditionalAccess);

  const onSaveChanges = () => {
    dispatch(updateConditionalAccess(conditionalAccessState));
    setIsEditing(false);
  };

  useEffect(() => {
    if (!isEnabled) {
      onCancel();
    }
  }, [isEnabled]);

  const onCancel = () => {
    setIsEditing(false);
    setConditionalAccessState(initialConditionalValue);
  };

  return (
    <SimpleBox
      id='conditional-access'
      title='Conditional Access'
    >
      <div className='action-button'>
        {isEditing ? (
          <>
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant='filled' onClick={onSaveChanges}>
              Save Changes
            </Button>
          </>
        ) : (
          <Button disabled={!isEnabled} onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </div>

      <div className='policy-grid'>
        {conditionalAccessState.map(({name, value}, index) => {
          const info = conditionalAccessStore.find(e => e.conditionalName === name);
          if (!info) {
            return null;
          }
          const {description, conditionalName, viewName, selectOptions} = info;
          return (
            <div className='style' key={conditionalName + index}>
              <div>
                <Label>{viewName}</Label>
                <span style={{color: 'var(--bfc-base-c-2)'}}>{description}</span>
              </div>

              <div className='select-box'>
                {isEditing ? (
                  <Select
                    label={viewName}
                    hideLabel
                    value={selectOptions.find(option => option.value === value)}
                    onChange={({value}) => {
                      const newState = produce(conditionalAccessState, draft => {
                        draft[index].value = value;
                      });
                      setConditionalAccessState(newState);
                    }}
                    options={selectOptions}
                    isDisabled={!isEditing}
                  />
                ) : (
                  <p className='view-box'>{selectOptions.find(option => option.value === value).label}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </SimpleBox>
  );
}
