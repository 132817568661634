import React, { useEffect, useState } from "react";
import { fetchBrregCompany } from "utils/brreg";
import {Button, FormatDate, Grid, Input, Message, useFloatingMessage} from '@intility/bifrost-react';
import "./company.scss";
import PropTypes from "prop-types";
import set from "lodash.set";
import registerImage from "../../../../assets/images/Wizard/Register.png";
import getStartedImage from "../../../../assets/images/Wizard/GetStarted.png";
import reviewImage from "../../../../assets/images/Wizard/Review.png";
import wentWrongImage from "../../../../assets/images/Wizard/WentWrong.png";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import apiFetch from "../../../../utils/apiFetch";
import { emptyGuid } from "../../../../utils/guid";
import useIntilityPermission from "../../../../hooks/useIntilityPermission";
import { useSelector } from "react-redux";

const getNewStateObject = (e, state) => {
  const target = e.target;
  const path = target.name;
  const value = target.value;

  return set(state, path, value);
};

const checkOrg = (companyId, orgNumber) => {
  return apiFetch(`/companies/${companyId}/billing/status/${orgNumber}`)
    .then(response => {
      return response.ok;
    })
};

const notifyOrg = (companyId, orgNumber) => {
  return apiFetch(`/companies/${companyId}/billing/status/${orgNumber}/notify`)
    .then(response => {
      return response.ok;
    })
};

const fetchFromBrreg = (state, setState) => {
  fetchBrregCompany(state.data.organizationNumber).then(company => {
    if (!company) return;

    const {data} = state;
    const address = company?.forretningsadresse
      ? `${company?.forretningsadresse?.adresse[0]}, ${company?.forretningsadresse?.postnummer} ${company?.forretningsadresse?.poststed}`
      : state.address;

    data.name = company?.navn || '';
    data.address = address;
    data.extra = company;

    setState(
      {
        ...state,
        data
      },
      'company'
    );
  });
};

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

const Company = ({state, onSubmit, updateFormState}) => {
  const {companyMemberships, tenantName, email: userMail} = useSelector(state => state.auth.user);
  const {name, organizationNumber, address, email, domainName} = state.data;
  const [neededInputs, setNeededInputs] = useState(undefined);
  const [page, setPage] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const intilityUser = useIntilityPermission();
  const { showFloatingMessage } = useFloatingMessage();

  console.log(tenantName, userMail)

  // Input onChange
  const onChange = e => {
    const alteredProperty = getNewStateObject(e, state);

    // Copy previous state, but change our property
    const newState = {
      ...state,
      ...alteredProperty
    };
    updateFormState(newState);
  };

  useEffect(() => {
    const now = new Date(Date.now());
    const orgStatus = window.localStorage['orgTimeout'] ? JSON.parse(window.localStorage['orgTimeout']) : {}
    if (orgStatus.time){
      const time = new Date(orgStatus?.time)?.addHours(3);
      setDisabled(time?.getTime() > now.getTime())
    }
  }, []);

  const getPage = (page) => {
    switch (page) {
      case 0:
        return page0();
      case 1:
        return page1();
      case 2:
        return page2();
      case -1:
        return errorPage();
    }
  };

  const getImage = (page) => {
    switch (page) {
      case -1:
        return wentWrongImage;
      case 0:
        return registerImage;
      case 1:
        return reviewImage;
      case 2:
        return getStartedImage;
    }
  };

  const errorPage = () => {
    return (
      <div className='register-content-text'>
        <h1>Something went wrong</h1>
        <p>We weren’t able to create a new company. Please check that the organization number is correct and try again, or send error report.</p>
        <div className='error-message'>
          <h5>Send error report</h5>
          <span>
            <p className='bf-strong'>Error</p>
            <p>"Failed to create company"</p>
          </span>
          <Grid large={2} medium={2} small={2}>
            <Grid.Span large={1} medium={1}>
              <p className='bf-strong'>Organization</p>
              <p>{organizationNumber}</p>
            </Grid.Span>
            <Grid.Span large={1} medium={1}>
              <p className='bf-strong'>Date</p>
              <p><FormatDate date={new Date(Date.now())}/></p>
            </Grid.Span>
          </Grid>
          <Grid large={2} medium={2} small={2}>
            <Grid.Span large={1} medium={1}>
              <p className='bf-strong'>User</p>
              <p>{userMail}</p>
            </Grid.Span>
            <Grid.Span large={1} medium={1}>
              <p className='bf-strong'>Company</p>
              <p>{tenantName || name}</p>
            </Grid.Span>
          </Grid>
          <div className='error-message-buttons'>
            <p className='bf-em bf-small'>Send the details above to Intility. You will be contacted by Intilitys project team to resolve the issue.</p>
            <Button onClick={() => {
              if (disabled) return showFloatingMessage('Intility has been notified');
              notifyOrg(emptyGuid, organizationNumber).then(() => {
                localStorage.setItem('orgTimeout', JSON.stringify({time: new Date(Date.now()), org: organizationNumber}));
                setDisabled(true);
              })

            }} state={disabled ? "inactive" : "default"} small>Send report</Button>
          </div>
        </div>
      </div>
    )
  }

  const page0 = () => {
    return (
      <div className='register-content-text'>
        <h1>Register new company</h1>
        {companyMemberships?.length === 0 &&
          <Message className='register-message' header='No company connected to your account'>No company was registered to your e-mail account, please add it by completing a few steps.</Message>
        }
        <Input
          value={organizationNumber}
          onChange={onChange}
          id='billing-organization-number'
          label='Organization number'
          name='data.organizationNumber'
          placeholder='Example: 981967070'
          state={neededInputs && (state.errors.organizationNumber ? 'alert' : 'success')}
          requiredNoLabel
        />
      </div>
    )
  }

  const page1 = () => {
    return (
      <div className='register-content-text'>
        <h1>Company information</h1>
        <Input
          className='register-input'
          value={name}
          onChange={onChange}
          id='billing-name'
          label='Company name'
          name='data.name'
          placeholder='Example: Intility AS'
          state={neededInputs && (state.errors.name ? 'alert' : 'success')}
          requiredNoLabel
        />
        <Input
          className='register-input'
          value={address}
          onChange={onChange}
          id='billing-address'
          label='Main address'
          name='data.address'
          placeholder='Example: Schweigaardsgate 39, 0191 Oslo'
          state={neededInputs && (state.errors.address ? 'alert' : 'success')}
          requiredNoLabel
        />
        <Input
          className='register-input'
          value={domainName}
          onChange={onChange}
          id='domainName'
          label='Primary Domain'
          name='data.domainName'
          placeholder='Example: intility.no'
          state={neededInputs && (state.errors.domainName ? 'alert' : 'success')}
          requiredNoLabel
        />
        <Input
          className='register-input'
          value={email}
          onChange={onChange}
          id='billing-email'
          label='Invoice email'
          name='data.email'
          placeholder='Example: billing@intility.no'
          state={neededInputs && (state.errors.email ? 'alert' : 'success')}
          requiredNoLabel
        />
      </div>
    )
  }

  const page2 = () => {
    return (
      <div className='register-content-text'>
        <h1>Get started!</h1>

        <div className='review-box' style={{marginBottom: 'var(--bfs16)'}}>
          <span><p className='bf-strong'>{name}</p>&nbsp;<p className='bf-em'>(org nr. {organizationNumber})</p></span>
          <p>{address}</p>
          <p>{domainName}</p>
          <p>{email}</p>
        </div>

        <div className='review-box'>
          <p className='bf-strong'>{state.onboardingContact.firstName + ' ' + state.onboardingContact.lastName}</p>
          <p>{state.onboardingContact.email}</p>
          <p>{state.onboardingContact.phoneNumber}</p>
          <p className='bf-small bf-em' style={{color: 'var(--bfc-base-c-2)'}}>This is added as main onboard contact, but you will be able to edit this later.</p>
        </div>

      </div>
    )
  }

  return (
    <div id='new-company'>
      <div className='company-box'>
        <div className='register-image'>
          <img src={(getImage(page))} alt='onboard-login' className='image' />
        </div>
        <div className='register-content'>
          {getPage(page)}
          <div className='register-buttons bfl-grid'>
            {page > 0 ?
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={() => setPage(page - 1)} icon={faArrowLeft}/>
              </div>
              :
              <div/>
            }
            {page < 2 ?
              <Button
                variant='filled'
                state={(page === 0 ? state.errors.organizationNumber : !state.isValid) ? 'inactive' : 'default'}
                onClick={() => {
                  if (page === 0 && !state.errors.organizationNumber) {
                    checkOrg(emptyGuid, organizationNumber).then(res => {
                      if (res === false && !intilityUser) {
                        setPage(-1);
                      } else {
                        fetchFromBrreg(state, updateFormState);
                        setPage(page + 1);
                      }
                    });
                  } else if (page === 1 && !state.isValid) {
                    setNeededInputs(true);
                  } else if (page === 1 && state.isValid){
                    setPage(page + 1);
                  } else if (page === -1){
                    setPage(0);
                  }
                }}>
                {page === -1 ? 'Back' : 'Next'}
              </Button>
            :
              <Button
                state={!state.isValid ? 'inactive' : 'default'}
                onClick={() => {
                  if (state.isValid) {
                    onSubmit();
                  } else {
                    setNeededInputs(true);
                  }
                }}
                variant='filled'
              >
                Register company
              </Button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

Company.propTypes = {
  state: PropTypes.object,
  onSubmit: PropTypes.func,
  updateFormState: PropTypes.func
};

export default Company;
