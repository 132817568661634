import {Card, Icon} from '@intility/bifrost-react';
import {Link, useHistory} from 'react-router-dom';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';

export const OverviewArticle = ({image, link, header, children, onClick}) => {
  const history = useHistory();

  return (
    <Card
      className='overview-article'
      onClick={onClick}
      tabIndex={0}
      onKeyDown={e => {
        e.keyCode !== 13 || (link ? history.push(link) : onClick());
      }}
    >
      <Link to={link ? link : '#'}>
        <div className='image-box'>
        <Card.Image url={image} />
        </div>
        <div className='card-content'>
          <span>
            <Card.Title>{header}</Card.Title>
            <Icon icon={faArrowRight} />
          </span>
          <Card.Content>{children}</Card.Content>
        </div>
      </Link>
    </Card>
  );
};

export default OverviewArticle;
