import {createAction} from '@reduxjs/toolkit';
import {
  addNewLocation,
  editLocation,
  normalizeLocationInformation,
  normalizeLocationList,
  normalizeLocations
} from './locationNormalizers';

export const fetchLocationListSuccess = createAction('locationsAction/fetchLocationListSuccess', apiLocations => ({
  payload: normalizeLocationList(apiLocations)
}));

export const fetchInformationSuccess = createAction(
  'locationsAction/fetchLocationInformationSuccess',
  apiLocations => ({
    payload: normalizeLocationInformation(apiLocations)
  })
);

export const updateLocationInformationSuccess = createAction(
  'locationsAction/updateInformationSuccess',
  information => ({
    payload: normalizeLocationInformation(information)
  })
);

export const fetchLocationsSuccess = createAction('locationsAction/fetchLocationsSuccess', apiLocations => ({
  payload: normalizeLocations(apiLocations)
}));

export const updateLocationsSuccess = createAction(
  'locationsAction/updateLocationsSuccess',
  (location, locationList) => ({
    payload: editLocation(locationList, location)
  })
);

export const addLocationSuccess = createAction(
  'locationsAction/addLocationSuccess',
  (location, locationList) => ({
    payload: addNewLocation(location, locationList)
  })
);
