import {Button, Drawer} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import React, {useEffect, useState} from 'react';
import './newContact.scss';
import produce from 'immer';
import {Application, ApplicationIntegration} from '../../../../../../utils/hub/models';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import devLog from '../../../../../../utils/devLog';
import {addApplicationRelationAsync} from '../../../../../../redux/company/applications/applicationRelations/applicationRelationThunks';
import up from '../../../../../../assets/images/Icons/Up.svg';
import down from '../../../../../../assets/images/Icons/Down.svg';
import upAndDown from '../../../../../../assets/images/Icons/UpAndDown.svg';

export const integrationOptions = [
  {
    value: 0,
    label: (
      <div className='integration-option-line'>
        <img src={down} alt='DownArrow' /> <p>Inherits data</p>
      </div>
    )
  },
  {
    value: 1,
    label: (
      <div className='integration-option-line'>
        <img src={up} alt='UpArrow' /> <p>Provides data</p>
      </div>
    )
  },
  {
    value: 2,
    label: (
      <div className='integration-option-line'>
        <img src={upAndDown} alt='UpDownArrow' /> <p>Both ways</p>
      </div>
    )
  }
];

export const NewIntegration = ({open, setIsOpen, applicationId, appStatus, setAddedElement}) => {
  const [integration, setIntegration] = useState(new ApplicationIntegration());
  const [application, setApplication] = useState(new Application());
  const [integrationType, setIntegrationType] = useState({ });
  const [applicationOption, setApplicationOption] = useState({ });
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const dispatch = useDispatch();

  const resetIntegration = () => {
    setIntegration(new ApplicationIntegration());
    setIntegrationType({});
    setApplicationOption({});
    setIsOpen(false);
  };

  useEffect(() => {
    setApplication(applicationList.find(a => a.id === applicationId));
  }, [applicationId, applicationList]);

  const onIntegrationChange = e => {
    const {value} = e;
    setIntegrationType(e);
    setIntegration(
      produce(draft => {
        draft.applicationRelationType = value;
      })
    );
  };

  const onApplicationChange = e => {
    const {value} = e;
    setApplicationOption(e);
    setIntegration(
      produce(draft => {
        draft.parentId = applicationId;
        draft.childId = value;
      })
    );
  };

  const applicationOptions = applicationList
    ?.filter(a => {
      if (a.id === applicationId) return false;
      if (a.isEnabled === false) return false;
      if (application?.childApplications?.some(i => i?.childId === a.id || i?.parentId === a.id)) return false;
      return !application?.parentApplications?.some(i => i?.childId === a.id || i?.parentId === a.id);
    })
    ?.map(u => {
      return {value: u.id, label: `${u.name}`};
    });

  const onSave = () => {
    devLog('Adding!', integration);
    if (integrationType !== null && applicationOption !== null) {
      dispatch(addApplicationRelationAsync(integration, applicationId));
      setAddedElement(true);
    }
    resetIntegration();
  };

  return (
    <Drawer
      overlay={true}
      isOpen={open}
      header={'Add new integration'}
      id='new-integration'
      onRequestClose={() => resetIntegration()}
      footer={
        <div className='drawer-bottom-button-row'>
          <Button onClick={() => resetIntegration()}>
            Cancel
          </Button>
          <Button
            variant='filled'
            disabled={!integrationType.label || !applicationOption.value}
            onClick={() => onSave()}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className='body'>
        <div className='bfl-grid integration-content'>
          <Select
            options={applicationOptions}
            value={applicationOption}
            onChange={onApplicationChange}
            label='App integration'
            description='Integrations from your application list'
          />
          <Select
            options={integrationOptions}
            value={integrationType}
            onChange={onIntegrationChange}
            isDisabled={applicationOption?.value === null}
            label='Reads information'
            description='In what way is this integrated?'
          />
        </div>
      </div>
    </Drawer>
  );
};

NewIntegration.propTypes = {
  open: PropTypes.bool,
  setIsOpen: PropTypes.func,
  applicationId: PropTypes.string,
  appStatus: PropTypes.object,
  setAddedElement: PropTypes.func
};

export default NewIntegration;
