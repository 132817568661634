import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchLocationsAsync } from "../../../redux/company/locations/locationThunks";
import apiFetch from "../../../utils/apiFetch";
import { Input, Table, useFloatingMessage } from "@intility/bifrost-react";
import produce from "immer";
import { emptyGuid } from "../../../utils/guid";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import '../EditComponents/editComponent.scss';

export const LocationDetails = () => {
  const { id, companyServiceId } = useSelector(state => state.company.data.info);
  const { locationList } = useSelector(state => state.company.data.locationPlatform);
  const {user} = useSelector(state => state.auth);
  const [rotCompany, setRotCompany] = useState(undefined);
  const [rotLocations, setRotLocations] = useState([]);
  const [listCopy, setListCopy] = useState([]);
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [searchValue, setSearchValue] = useState('');
  const { showFloatingMessage } = useFloatingMessage();

  const dispatch = useDispatch();

  const checkLocation = (location) => {
    const item = rotLocations?.find(l => {
      if (l?.label?.endsWith(location?.name) || l?.label?.startsWith(location?.name) || location?.name === l?.label) {
        return l;
      }
    })
    if (item !== undefined) {
      const newValue = produce(location, draft => {
        draft.rotLocationGuid = item.value;
      })
      const index = listCopy.findIndex(obj => obj.id === location.id);
      setListCopy(
        produce(listCopy, draft => {
          draft[index] = {...newValue};
        })
      );
    }
  }

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);


  useEffect(() => {
    if (!user || !id || rotCompany) return;
    apiFetch(`/companies/${id}/companyService/${companyServiceId}/locations`)
      .then(response => {
        if (!response.ok) {
          setRotCompany([]);
          showFloatingMessage('Could not fetch CompanyService locations, please make sure CompanyCode is connected on the Information page.', { state: 'alert' });
          return [];
        }
        return response.json()
      })
      .then(company => {
        setRotCompany(company);
        setRotLocations(company?.map(l => ({
          value: l.id,
          label: l.name
        })));
      });
  }, [user, id]);

  useEffect(() => {
    setListCopy(locationList);
  }, [locationList]);

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const sortedData = [...listCopy].sort((a, b) => {
    if (sort.direction === 'asc') {
      return a[sort.key]?.localeCompare(b[sort.key]);
    } else {
      return b[sort.key]?.localeCompare(a[sort.key]);
    }
  }).filter(s => {
    return s.name?.toLowerCase().includes(searchValue.toLowerCase());
  });

  useEffect(() => {
    if (rotLocations?.length > 0) {
      sortedData.forEach(l => l.rotLocationGuid === undefined || l.rotLocationGuid === emptyGuid && checkLocation(l))
    }
  }, [rotLocations]);

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <div>
          <h3>Locations</h3>
          <p>Requires Company code <Link className='bf-link' to={`/details/${id}/information/edit`}>information page</Link></p>
        </div>
      </div>
      <div className='backoffice-editable-switchrow bfl-autocol'>
        <Input
          className='select-group-input'
          placeholder='Search for location'
          label='search'
          hideLabel
          icon={faSearch}
          clearable
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          rightIcon
        />
      </div>
      <Table className='edit-rot-location-table'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
              Location
            </Table.HeaderCell>
            <Table.HeaderCell>
              Rot Location connection
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedData.map(location => (
            <Table.Row key={location.id} className='edit-rot-location-row'>
              <Table.Cell>{location.name}</Table.Cell>
              <Table.Cell>{rotLocations?.find(l => l.value === location.rotLocationGuid)?.label}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )


}

export default LocationDetails