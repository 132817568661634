export const UserRowEditable = {
  FALSE: 0,
  TRUE: 1
}

const UserRowOptions = [
  {value: 'firstName', label: 'First name', editable: UserRowEditable.TRUE},
  {value: 'lastName', label: 'Last name', editable: UserRowEditable.TRUE},
  {value: 'intilityUserType', label: 'Intility user type', editable: UserRowEditable.TRUE},
  {value: 'mail', label: 'Mail', editable: UserRowEditable.TRUE},
  {value: 'mobilePhone', label: 'Mobile', editable: UserRowEditable.TRUE},
  {value: 'locationId', label: 'Location', editable: UserRowEditable.TRUE},
  {value: 'billingId', label: 'Billing company', editable: UserRowEditable.TRUE},
  {value: 'accountEnabled', label: 'Entra ID status', editable: UserRowEditable.TRUE},
  {value: 'licenses', label: 'Licenses', editable: UserRowEditable.FALSE},
  {value: 'createdAt', label: 'Imported', editable: UserRowEditable.FALSE},
  {value: 'modifiedAt', label: 'Modified', editable: UserRowEditable.FALSE},
  {value: 'userName', label: 'Username', editable: UserRowEditable.TRUE},
  {value: 'comment', label: 'Comment', editable: UserRowEditable.TRUE},
  {value: 'department', label: 'Department', editable: UserRowEditable.TRUE},
  {value: 'title', label: 'Title', editable: UserRowEditable.TRUE},
  {value: 'managerId', label: 'Manager', editable: UserRowEditable.TRUE},
  {value: 'language', label: 'Language', editable: UserRowEditable.TRUE},
  {value: 'country', label: 'Country', editable: UserRowEditable.TRUE},
  {value: 'officePhone', label: 'Office phone', editable: UserRowEditable.TRUE},
  {value: 'physicalOffice', label: 'Physical office', editable: UserRowEditable.TRUE},
  {value: 'streetAddress', label: 'Street address', editable: UserRowEditable.TRUE},
  {value: 'postalCode', label: 'Postal code', editable: UserRowEditable.TRUE},
  {value: 'city', label: 'City', editable: UserRowEditable.TRUE},
  {value: 'state', label: 'State', editable: UserRowEditable.TRUE},
  {value: 'description', label: 'Description', editable: UserRowEditable.TRUE},
  {value: 'employeeId', label: 'Employee ID', editable: UserRowEditable.TRUE},
  {value: 'oldSam', label: 'Old sam', editable: UserRowEditable.TRUE},
  {value: 'oldSid', label: 'Old sid', editable: UserRowEditable.TRUE},
  {value: 'newSam', label: 'New sam', editable: UserRowEditable.TRUE},
  {value: 'newSid', label: 'New sid', editable: UserRowEditable.TRUE},
  {value: 'displayName', label: 'Display name', editable: UserRowEditable.TRUE},
  {value: 'adCompany', label: 'AAD company', editable: UserRowEditable.FALSE},
  {value: 'azureAdGuid', label: 'Entra ID Guid', editable: UserRowEditable.FALSE},
];


export default UserRowOptions