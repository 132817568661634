import { Badge, Icon } from "@intility/bifrost-react";
import PropTypes from "prop-types";
import React from "react";
import SimpleFileUploader from "../../../../../../FileUploader/SimpleFileUploader";
import {
  deleteFloorPlanAsync,
  uploadFloorPlansAsync
} from "../../../../../../../redux/company/locations/locationThunks";
import { faFileAlt, faFileImage, faFilePdf, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import '../locationEditDrawer.scss';
import { useDispatch, useSelector } from "react-redux";
import { downloadFile } from "../../../../../../../utils/downloadFile";

export const FloorPlansContent = ({ location, isDisabled }) => {
  const {id} = useSelector(state => state.company.data.info);
  const dispatch = useDispatch();

  const downloadFloorPlan = floorPlan => {
    const fileNameRegex = /\/(.*)/i;
    const fileName = floorPlan.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'
    const relativeUrl = `/companies/${id}/locations/${location.id}/floorPlans/${fileName}`;
    downloadFile(floorPlan, relativeUrl)
  };

  const getPlanIcon = (name) => {
    const ending = name?.substring(name.length - 5, name.length)?.toLowerCase()?.split('.')[1];

    if (ending === 'pdf') {
      return faFilePdf;
    } else if (ending === 'jpg' || ending === 'png') {
      return faFileImage
    } else {
      return faFileAlt
    }

  }

  return (
    <div className='floor-plans-content'>
      <SimpleFileUploader
        validContentTypes={['.pdf', '.jpg', '.png', '.jpeg', '.docx']}
        isDisabled={isDisabled}
        onChange={event => {
          dispatch(uploadFloorPlansAsync(location.id, event.target.value));
        }}
      />

      <div style={{display: 'grid', gridTemplateColumns: '1fr', rowGap: 'var(--bfs8)'}}>
        {location.floorPlans?.length > 0 ? (
          location.floorPlans.map(floorPlan => {
            const fileNameRegex = /\/(.*)/i;
            const fileName = floorPlan.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'

            return (
              <div key={floorPlan.objectName} className='floor-plan-row'>
                <Badge state={'neutral'} className='floor-plans-badge'>
                  <Icon icon={getPlanIcon(floorPlan.objectName)}/>
                  <p className='bf-link' onClick={() => downloadFloorPlan(floorPlan)}>
                    {fileName}
                  </p>
                </Badge>
                <Icon
                  style={{marginLeft: 'var(--bfs8)', cursor: 'pointer'}}
                  icon={faTrashAlt}
                  onClick={() => {
                    if (!isDisabled) dispatch(deleteFloorPlanAsync(floorPlan))
                  }}
                />
              </div>
            );
          })
        ) : (
          <p style={{color: 'var(--bfc-base-c-2)', margin: '0px'}}>
            No floor plans uploaded
          </p>
        )}
      </div>
    </div>
  )
}

FloorPlansContent.propTypes = {
  location: PropTypes.object,
  isDisabled: PropTypes.bool
};