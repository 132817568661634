import React, {useEffect, useState} from 'react';
import { Checkbox, Icon, Label } from "@intility/bifrost-react";
import '../EditComponents/editComponent.scss';
import {departmentType, Detail} from '../../../utils/hub/models';
import {useSelector} from 'react-redux';
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

export const LogisticsDetails = () => {
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const [isDeliveryReady, setIsDeliveryReady] = useState(new Detail());

  useEffect(() => {
    resetValues();
  }, [detailList]);


  const resetValues = () => {
    setIsDeliveryReady(getDetail('DeliveryReady'));
  };

  const getDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.LOGISTICS)?.find(i => i.name === name) || new Detail()
    );
  };

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Logistics</h3>
      </div>
      <div className='bfl-autocol'>
        <div className='labeled-checkbox'>
          <Label>Delivery ready:
            {isDeliveryReady?.boolValue ?
              <Icon icon={faCheckCircle} style={{color: 'var(--bfc-success)', marginLeft: 'var(--bfs8)'}}/>
              :
              <Icon icon={faTimesCircle} style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs8)'}}/>
            }
          </Label>
        </div>
      </div>
    </div>
  );
};

export default LogisticsDetails;
