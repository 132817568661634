import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  fetchBillingListSuccess,
  fetchBillingSuccess,
  fetchInformationSuccess,
  updateBillingInformationSuccess,
  updateBillingSuccess,
  updatePrimarySuccess
} from './billingActions';

const updateOnChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchBillingSuccess]: (state, action) => getState(action.payload, state),
    [updatePrimarySuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateBillingInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const billingInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    mainId: '',
    billingCount: 0
  },
  updateOnChangeReducer((payload, state) => {
    state.id = payload.billingPlatform.information.id;
    state.companyId = payload.billingPlatform.information.companyId;
    state.modifiedAt = payload.billingPlatform.information.modifiedAt;
    state.billingCount = payload.billingPlatform.information.billingCount;
    state.mainId = payload.billingPlatform.information.mainId;
    state.isEnabled = payload.billingPlatform.information.isEnabled;
    return state;
  })
);

const updateOnBillingChangeReducer = getState => {
  return {
    [fetchBillingSuccess]: (state, action) => getState(action.payload, action.type, state),
    [updateBillingSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};

export const billingListReducer = createReducer(
  [],
  updateOnBillingChangeReducer((payload, type, state) => {
    state = payload.billingPlatform.billingList;
    return state;
  })
);

const updateOnBillingStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchBillingSuccess]: (state, action) => getState(action.payload, action.type, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};

export const billingStateReducer = createReducer(
  0,
  updateOnBillingStateReducer((payload, type, state) => {
    state = payload.billingPlatform.state;
    return state;
  })
);

export const billingStatusSlice = createSlice({
  name: 'billingAction',
  initialState: {
    fetching: false,
    updating: false,
    error: false
  },
  reducers: {
    fetchBilling: state => {
      state.fetching = true;
    },
    fetchBillingError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateBilling: state => {
      state.updating = true;
    },
    updateBillingError: state => {
      state.updating = false;
      state.error = true;
    },
    updatePrimaryBilling: state => {
      state.updating = true;
    },
    updatePrimaryBillingError: state => {
      state.updating = false;
      state.error = true;
    }
  },
  extraReducers: {
    [fetchBillingSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [fetchBillingListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateBillingSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updatePrimarySuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateBillingInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchBilling,
  fetchBillingError,
  updateBilling,
  updateBillingError,
  updatePrimaryBilling,
  updatePrimaryBillingError
} = billingStatusSlice.actions;
