import {addListItem, deleteListItem, editListItem} from '../../../utils/hub/normalize';

export const editProjectTeam = (teamList, team) => {
  const newList = editListItem(teamList, team);
  return {
    projectTeams: {
      list: newList
    }
  };
};

export const addProjectTeam = (teamList, team) => {
  const newList = addListItem(teamList, team);
  return {
    projectTeams: {
      list: newList
    }
  };
};

export const deleteProjectTeam = (teamList, teamId) => {
  const newList = deleteListItem(teamList, teamId);
  return {
    projectTeams: {
      list: newList
    }
  };
};

export const addMemberRoles = (roleList, role) => {
  const newList = addListItem(roleList, role);
  return {
    projectTeams: {
      roles: newList
    }
  };
};

export const normalizeProjectTeams = detailPlatform => {
  return {
    projectTeams: {
      list: detailPlatform
    }
  };
};

export const normalizeMemberRoles = detailPlatform => {
  return {
    projectTeams: {
      roles: detailPlatform
    }
  };
};


export const normalizeResourceTeams = teams => {
  return {
    resourceTeams: teams
  };
};