import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersAsync } from "../../../../../../../redux/company/users/userThunks";
import { fetchBillingAsync } from "../../../../../../../redux/company/billing/billingThunks";
import PropTypes from "prop-types";
import { Checkbox, Input } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { CreatableSelect} from "@intility/bifrost-react-select";
import { fetchBrregCompany } from "../../../../../../../utils/brreg";
import { Contact } from "../../../../../../../utils/hub/models";
import CheckUserDeleted from "../../../../../../../utils/hub/checkUserDeleted";

export const DeliveryContent = ({ location, editLocation, editLocationChild }) => {
  const {id} = useSelector(state => state.company.data.info);
  const {billingList} = useSelector(state => state.company.data.billingPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const [eoriIrrelevant, setEoriIrrelevant] = useState(false);
  const [status, setStatus] = useState({  });
  const [recipientValue, setRecipientValue] = useState({});
  const loggedInUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedInUser || !id) return;
    dispatch(fetchUsersAsync(id));
    dispatch(fetchBillingAsync(id));
  }, [loggedInUser, dispatch, id]);

  useEffect(() => {
    if (location.eoriNumber === 'Not relevant') setEoriIrrelevant(true);
    let currentValue = recipientCompanyOptions?.find(option => option.value.name === location?.recipientCompany?.name)
    if (!currentValue && location?.recipientCompany?.name === '') {
      currentValue = { label: location.name, value: location };
      setLocationCompany('recipientCompany', currentValue);
    }
    setRecipientValue(currentValue);
  }, []);

  const setLocationContact = (type, selected) => {
    const {firstName, lastName, mobilePhone: phoneNumber, primaryEmailAddress: email} = selected.value;
    editLocationChild(type, 'firstName', firstName);
    editLocationChild(type, 'lastName', lastName);
    editLocationChild(type, 'phoneNumber', phoneNumber);
    editLocationChild(type, 'email', email);
  };

  const handleContactCreate = (option, contactType) => {
    const newOption = {
      value: option,
      label: option
    }
    setLocationContact(contactType, {value: ({ ...new Contact(), firstName: option })});
    setStatus({...status, addNewRecipientContactChecked: true})
    contactOptions.push(newOption);
  }

  const setLocationCompany = (type, selected) => {
    const {name, address, postalPlace, zipCode, country, organizationNumber} = selected.value;

    fetchBrregCompany(organizationNumber).then(res => {
      editLocationChild(type, 'name', name);
      editLocationChild(type, 'address', res?.forretningsadresse?.adresse[0] || address);
      editLocationChild(type, 'postalPlace', res?.forretningsadresse?.poststed || postalPlace);
      editLocationChild(type, 'zipCode', res?.forretningsadresse?.postnummer || zipCode);
      editLocationChild(type, 'country', res?.forretningsadresse?.land || country);
    })
  };

  const legalCompanyOptions = billingList.map(company => ({
    label: `${company.name} (${company.organizationNumber})`,
    value: company
  }));

  const recipientCompanyOptions = locationList.map(location => ({
    label: `${location.name}`,
    value: location
  }));

  const contactOptions = userList?.filter(u => u.isEnabled === true)?.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user
  }));

  return (
    <div className='delivery-content' style={{rowGap: 'var(--bfs16)'}}>

      <div>
        <Select
          label='Recipient location'
          description='Equipment will be sent to the following location:'
          placeholder='- Select location -'
          options={recipientCompanyOptions}
          value={recipientValue}
          onChange={selected => {
            if (selected && 'value' in selected) {
              setRecipientValue(selected);
              setLocationCompany('recipientCompany', selected);
            }
          }}
        />

        {location?.recipientCompany && (
          <div className='information-container'>
            <p>{location?.recipientCompany?.name}</p>
            <p>{location?.recipientCompany?.address}</p>
            <p>{location?.recipientCompany?.postalPlace}</p>
            <p>{location?.recipientCompany?.zipCode}</p>
            <p>{location?.recipientCompany?.country}</p>
          </div>
        )}
      </div>

      <div>
        <CreatableSelect
          label='Recipient contact'
          description='Contact information for person at location to receive the goods'
          placeholder='- Recipient contact -'
          options={contactOptions}
          value={contactOptions.find(option => option?.value?.firstName === location?.recipientContact?.firstName)}
          onChange={selected => {
            if (selected && 'value' in selected) {
              setStatus({...status, addNewRecipientContactChecked: false});
              setLocationContact('recipientContact', selected);
            }
          }}
          onCreateOption={option => {
            setStatus({...status, addNewRecipientContactChecked: true})
            handleContactCreate(option, 'recipientContact')
          }}
        />

        {!status.addNewRecipientContactChecked && location?.recipientContact && (
          <div className='information-container'>
            <p>{location?.recipientContact?.firstName} {location?.recipientContact?.lastName}<CheckUserDeleted userName={location?.recipientContact?.firstName + location?.recipientContact?.lastName} email={location?.recipientContact?.email} phoneNumber={location?.recipientContact?.phoneNumber}/></p>
            <p>{location?.recipientContact?.phoneNumber}</p>
            <p>{location?.recipientContact?.email}</p>
          </div>
        )}

        {status.addNewRecipientContactChecked && (
          <div className='new-information-container bfl-autocol'>
            <div className='double-row'>
              <Input onChange={(e) => editLocationChild('recipientContact', 'firstName', e.target.value)} value={location?.recipientContact?.firstName} label='First name' />
              <Input onChange={(e) => editLocationChild('recipientContact', 'lastName', e.target.value)} value={location?.recipientContact?.lastName} label='Last name' />
            </div>
            <div className='double-row'>
              <Input onChange={(e) => editLocationChild('recipientContact', 'phoneNumber', e.target.value)} value={location?.recipientContact?.phoneNumber} label='Phone number' />
              <Input onChange={(e) => editLocationChild('recipientContact', 'email', e.target.value)} value={location?.recipientContact?.email} label='E-Mail' />
            </div>
          </div>
        )}
      </div>

      <div>
        <div>
          <label className='bf-label'>EORI number</label>
          <div className='bf-label-description'>
            Identification number for businesses which undertake the import or export of goods into or out of the EU.
          </div>
        </div>
        <div className='eoriInputContainer'>
          <Input
            value={location?.eoriNumber || ''}
            onChange={e => editLocation('eoriNumber', e.target.value)}
            disabled={eoriIrrelevant}
            label='EORI number'
            hideLabel
            style={{display: 'flex'}}
          />
          <Checkbox
            type='checkbox'
            label='Not relevant'
            checked={eoriIrrelevant}
            onChange={() => {
              setEoriIrrelevant(previous => !previous);
              if (!eoriIrrelevant) editLocation('eoriNumber', 'Not relevant');
              else editLocation('eoriNumber', '');
            }}
            style={{marginTop: '6px'}}
            name='eoriNotRelevant'
          />
        </div>
      </div>

      {location?.eoriNumber !== 'Not relevant' && [
        <div key='legal'>
          <Select
            label='Legal company'
            options={legalCompanyOptions}
            placeholder='- Legal company -'
            value={legalCompanyOptions.find(option => option.value.name === location?.legalCompany?.name)}
            onChange={selected => {
              if (selected && 'value' in selected) {
                const {id} = selected.value;
                editLocation('billingId', id);
                setLocationCompany('legalCompany', selected);
              }
            }}
          />
          {location.legalCompany?.name && (
            <div className='information-container'>
              <p>{location?.legalCompany?.name}</p>
              <p>{location?.legalCompany?.address}</p>
              <p>{location?.legalCompany?.postalPlace}</p>
              <p>{location?.legalCompany?.zipCode}</p>
              <p>{location?.legalCompany?.country}</p>
            </div>
          )}
        </div>,

        <div key='financial'>
          <CreatableSelect
            label='Financial contact'
            placeholder='- Financial contact -'
            options={contactOptions}
            value={contactOptions.find(option => option?.value?.firstName === location?.financialContact?.firstName)}
            onChange={selected => {
              if (selected && 'value' in selected) {
                setStatus({...status, addNewFinancialContactChecked: false});
                setLocationContact('financialContact', selected);
              }
            }}
            onCreateOption={option => {
              setStatus({...status, addNewRecipientContactChecked: true})
              handleContactCreate(option, 'financialContact')
            }}
          />
          {!status.addNewFinancialContactChecked && location?.financialContact?.firstName && (
            <div className='information-container'>
              <p>{location?.financialContact?.firstName} {location?.financialContact?.lastName}<CheckUserDeleted userName={location?.financialContact?.firstName + location?.financialContact?.lastName} email={location?.financialContact?.email} phoneNumber={location?.financialContact?.phoneNumber}/></p>
              <p>{location?.financialContact?.phoneNumber}</p>
              <p>{location?.financialContact?.email}</p>
            </div>
          )}
          {status.addNewFinancialContactChecked && (
            <div className='new-information-container bfl-autocol'>
              <div className='double-row'>
                <Input onChange={(e) => editLocationChild('financialContact', 'firstName', e.target.value)} value={location?.financialContact?.firstName} label='First name' />
                <Input onChange={(e) => editLocationChild('financialContact', 'lastName', e.target.value)} value={location?.financialContact?.lastName} label='Last name' />
              </div>

              <div className='double-row'>
                <Input onChange={(e) => editLocationChild('financialContact', 'phoneNumber', e.target.value)} value={location?.financialContact?.phoneNumber} label='Phone number' />
                <Input onChange={(e) => editLocationChild('financialContact', 'email', e.target.value)} value={location?.financialContact?.email} label='E-Mail' />
              </div>
            </div>
          )}
        </div>

        ]
      }
    </div>
  )
}

DeliveryContent.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
  editLocationChild: PropTypes.func,
  onRequestClose: PropTypes.func
};