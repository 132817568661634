import {Dropdown, Icon, Input} from '@intility/bifrost-react';
import {faSearch, faUser} from '@fortawesome/pro-regular-svg-icons';
import React, {useEffect, useState} from 'react';
import apiFetch from '../../../utils/apiFetch';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import './memberDropdown.scss';

export const MemberDropdown = ({number, selectedMember, setSelectedMember, hideLabel}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [searchMember, setSearchMember] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const value = selectedMember?.givenName ? `${selectedMember.givenName} ${selectedMember.surname}` : '';
    setSearchValue(value);
  }, [selectedMember]);

  const setMember = member => {
    setSearchValue(`${member.givenName} ${member.surname}`);
    setSelectedMember(member, number);
    setShowDropdown(false);
    setSearchMember([]);
  };

  const onClose = () => {
    setSearchValue('');
    setShowDropdown(false);
    setSearchMember([]);
  };

  const fetchMembers = name => {
    if (name === '') {
      setSelectedMember({ member: null, memberId: null }, number);
      return;
    }
    setSearchValue(name);
    setShowDropdown(true);
    if (name.length > 2) {
      apiFetch(`/companies/${id}/projectTeams/search?name=${name}`)
        .then(response => {
          if (!response.ok) {
            setSearchMember([]);
            return [];
          }
          return response.json()
        })
        .then(users => setSearchMember(users))
    }
  };

  return (
    <Dropdown
      popperOptions={{
        modifiers: [
          {
            name: 'sameWidth',
            enabled: true,
            fn: ({state}) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            effect: ({state}) => {
              state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
            }
          }
        ]
      }}
      noPadding
      arrow={false}
      visible={searchMember.length > 0 && showDropdown}
      onClickOutside={() => onClose()}
      content={
        <div>
          {searchMember?.length > 0 &&
            searchMember.map((m, i) => {
              return (
                <div className='team-edit-modal-dropdown' key={i} onClick={() => setMember(m)}>
                  <div className='member-photo-wrapper'>
                    {m?.profilePhoto ? (
                      <img src={`data:image/png;base64,${m?.profilePhoto}`} alt={m?.surname} />
                    ) : (
                      <Icon icon={faUser} />
                    )}
                  </div>
                  <p>
                    {m?.givenName} {m?.surname}
                  </p>
                </div>
              );
            })}
        </div>
      }
    >
      <Input
        label='Team member'
        placeholder='Search for member'
        hideLabel={hideLabel}
        value={searchValue}
        onChange={e => fetchMembers(e.target.value)}
        icon={faSearch}
        rightIcon={true}
        clearable={true}
      />
    </Dropdown>
  );
};

MemberDropdown.propTypes = {
  setSelectedMember: PropTypes.func,
  selectedMember: PropTypes.object,
  hideLabel: PropTypes.bool,
  number: PropTypes.number
};

export default MemberDropdown;
