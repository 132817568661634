import EditApplicationFilters from '../../components/BackOffice/EditComponents/EditApplicationFilters';
import {faServer} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'ApplicationFilters',
  path: 'applicationFilters/edit',
  icon: faServer,
  editable: true,
  component: EditApplicationFilters
};
