import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const includes = require('array-includes');

const useRolePermission = neededRoles => {
  const {roles} = useSelector(state => state.auth);
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    neededRoles.forEach(neededRole => {
      if (includes(roles, neededRole)) {
        setHasRole(true);
      }
    });
  }, [roles, neededRoles]);

  return hasRole;
};

export default useRolePermission;
