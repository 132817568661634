import {
  fetchMemberRoles,
  fetchMemberRolesError,
  fetchProjectTeams,
  fetchProjectTeamsError,
  fetchResourceTeams,
  fetchResourceTeamsError,
  updateMemberRole,
  updateMemberRoleError,
  updateProjectTeams,
  updateProjectTeamsError
} from './projectTeamsReducers';
import apiFetch, {apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  addMemberRoleSuccess,
  addProjectTeamSuccess,
  deleteProjectTeamSuccess,
  fetchMemberRolesSuccess,
  fetchProjectTeamsSuccess,
  fetchResourceTeamsSuccess,
  updateProjectTeamSuccess
} from './projectTeamsActions';
import produce from 'immer';
import {emptyGuid} from '../../../utils/guid';

export const fetchProjectTeamsAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.projectTeams.projectTeamStatus;
  if (fetching) return null;
  dispatch(fetchProjectTeams());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/projectTeams`,
    method: 'GET'
  }).then(teams => dispatch(fetchProjectTeamsSuccess(teams)))
    .catch(error => dispatch(fetchProjectTeamsError(error)));
};

export const addMembershipRoleAsync = (companyId, role) => (dispatch, getState) => {
  const {updatingRoles} = getState().company.data.projectTeams.projectTeamStatus;
  if (updatingRoles) return null;
  const {roles} = getState().company.data.projectTeams;
  dispatch(updateMemberRole());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/projectTeams/roles`,
    method: 'POST',
    body: role
  }).then(role => dispatch(addMemberRoleSuccess(role, roles)))
    .catch(error => dispatch(updateMemberRoleError(error)));
};

export const addProjectTeamAsync = (companyId, team) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectTeams.projectTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectTeams;
  dispatch(updateProjectTeams());

  const body = produce(team, draft => {
    draft.teamRoles = draft.teamRoles.map(r =>
      produce(r, draft => {
        draft.member = null;
        draft.teamMemberId = r.teamMemberId === emptyGuid ? null : r.teamMemberId;
      })
    );
  });
  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/projectTeams`,
    method: 'POST',
    body: body
  }).then(team => dispatch(addProjectTeamSuccess(team, list)))
    .catch(error => dispatch(updateProjectTeamsError(error)));
};

export const deleteProjectTeamAsync = (companyId, teamId) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectTeams.projectTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectTeams;
  dispatch(updateProjectTeams());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/projectTeams/${teamId}`,
    method: 'DELETE'
  })
    .then(res => {
      if (res.ok) dispatch(deleteProjectTeamSuccess(teamId, list));
      else throw new Error('Bad request.');
    })
    .catch(error => dispatch(updateProjectTeamsError(error)));
};

export const updateProjectTeamAsync = (companyId, team) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectTeams.projectTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectTeams;
  dispatch(updateProjectTeams());

  const body = produce(team, draft => {
    draft.teamRoles = draft.teamRoles.map(r =>
      produce(r, draft => {
        draft.member = null;
        draft.teamMemberId = r.teamMemberId === emptyGuid ? null : r.teamMemberId;
      })
    );
  });
  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/projectTeams/${team.id}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: body
  }).then(team => dispatch(updateProjectTeamSuccess(team, list)))
    .catch(error => dispatch(updateProjectTeamsError(error)));
};

export const updateProjectTeamLeadAsync = (companyId, team, roleId) => (dispatch, getState) => {
  const {updating} = getState().company.data.projectTeams.projectTeamStatus;
  if (updating) return null;
  const {list} = getState().company.data.projectTeams;
  dispatch(updateProjectTeams());

  const body = produce(team, draft => {
    draft.teamManager = roleId;
  });
  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/projectTeams/${team.id}/projectLead`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: body
  }).then(team => dispatch(updateProjectTeamSuccess(team, list)))
    .catch(error => dispatch(updateProjectTeamsError(error)));
};

export const fetchMembershipRoleAsync = companyId => (dispatch, getState) => {
  const {fetchingRoles} = getState().company.data.projectTeams.projectTeamStatus;
  if (fetchingRoles) return null;
  dispatch(fetchMemberRoles());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/projectTeams/roles`,
    method: 'GET'
  }).then(roles => dispatch(fetchMemberRolesSuccess(roles)))
    .catch(error => dispatch(fetchMemberRolesError(error)));
};

export const multiPutTeamRolesAsync = roleList => (dispatch, getState) => {
  const {updating} = getState().company.data.projectTeams.projectTeamStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;
  dispatch(updateProjectTeams());

  const body = roleList.map(r =>
    produce(r, draft => {
      draft.teamMemberId = r.teamMemberId === emptyGuid ? null : r.teamMemberId;
    })
  );

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/projectTeams/bulk`,
    method: 'PUT',
    body: body
  })
    .then(() => dispatch(fetchProjectTeamsAsync(id)))
    .catch(error => dispatch(updateProjectTeamsError(error)));
};


export const fetchResourceTeamsAsync = () => (dispatch, getState) => {
  const {fetchingFinancial} = getState().company.data.detailsPlatform.detailStatus;
  if (fetchingFinancial) return null;
  dispatch(fetchResourceTeams());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/allTeams`,
    method: 'GET'
  }).then(teams => dispatch(fetchResourceTeamsSuccess(teams)))
      .catch(error => dispatch(fetchResourceTeamsError(error)));
};

export const multiPutTeamRoleComplexityAsync = roleList => (dispatch, getState) => {
  const {updating} = getState().company.data.projectTeams.projectTeamStatus;
  if (updating) return null;
  dispatch(updateProjectTeams());

  const body = roleList.map(r =>
    produce(r, draft => {
      draft.teamMemberId = r.teamMemberId === emptyGuid ? null : r.teamMemberId;
    })
  );

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/projectTeams/bulkComplexity`,
    method: 'PUT',
    body: body
  })
    .then(() => dispatch(fetchResourceTeamsAsync()))
    .catch(error => dispatch(updateProjectTeamsError(error)));
};
