import './domainCard.scss';
import PropTypes from "prop-types";
import React from 'react';
import { Icon } from "@intility/bifrost-react";

export const DomainCard = ({icon, header, button, children}) => {

  return (
    <div className='domain-card'>
      <div className='domain-card-icon'>
        <Icon icon={icon}/>
      </div>
      <div className='domain-card-body'>
        <p className='bf-strong bf-large'>{header}</p>
        {children}
      </div>
      <div className='domain-card-button'>
        {button}
      </div>
    </div>
  )

}

DomainCard.propTypes = {
  icon: PropTypes.any,
  header: PropTypes.string,
  button: PropTypes.node,
  children: PropTypes.node
}

export default DomainCard