import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  addIamsUserSuccess,
  addWorkplaceDeviceSuccess,
  deleteIamsUsersSuccess,
  deleteWorkplaceDeviceSuccess,
  fetchIamsUsersListSuccess,
  fetchIamsUsersSuccess,
  fetchInformationSuccess,
  fetchWorkplaceDevicesSuccess,
  updateIamsUsersInformationSuccess,
  updateIamsUsersSuccess,
  updateIamsUserSuccess
} from './iamsUsersActions';

const updateOnInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchIamsUsersSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateIamsUsersInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const iamsUsersInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    userCount: 0
  },
  updateOnInformationChangeReducer((payload, state) => {
    state.id = payload.iamsUserPlatform.information.id;
    state.companyId = payload.iamsUserPlatform.information.companyId;
    state.userCount = payload.iamsUserPlatform.information.userCount;
    state.isEnabled = payload.iamsUserPlatform.information.isEnabled;
    state.modifiedAt = payload.iamsUserPlatform.information.modifiedAt;
    return state;
  })
);

const updateOnIamsUsersChangeReducer = getState => {
  return {
    [fetchIamsUsersSuccess]: (state, action) => getState(action.payload, state),
    [updateIamsUsersSuccess]: (state, action) => getState(action.payload, state),
    [updateIamsUserSuccess]: (state, action) => getState(action.payload, state),
    [deleteIamsUsersSuccess]: (state, action) => getState(action.payload, state),
    [addIamsUserSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const iamsUsersReducer = createReducer(
  [],
  updateOnIamsUsersChangeReducer((payload, state) => {
    state = payload.iamsUserPlatform.iamsUserList;
    return state;
  })
);

const updateOnIamsUserStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchIamsUsersSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const iamsUserStateReducer = createReducer(
  0,
  updateOnIamsUserStateReducer((payload, state) => {
    state = payload.iamsUserPlatform.state;
    return state;
  })
);


const updateOnWorkplaceDevicesChangeReducer = getState => {
  return {
    [fetchWorkplaceDevicesSuccess]: (state, action) => getState(action.payload, state),
    [deleteWorkplaceDeviceSuccess]: (state, action) => getState(action.payload, state),
    [addWorkplaceDeviceSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const workplaceDeviceReducer = createReducer(
  [],
  updateOnWorkplaceDevicesChangeReducer((payload, state) => {
    state = payload.iamsUserPlatform.workplaceDevices;
    return state;
  })
);

const updateOnUserAddedReducer = getState => {
  return {
    [addIamsUserSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const iamsUserReducer = createReducer(
  {},
  updateOnUserAddedReducer((payload, state) => {
    state = payload.iamsUserPlatform.iamsUser;
    return state;
  })
);

export const iamsUsersStatusSlice = createSlice({
  name: 'iamsUsersAction',
  initialState: {
    fetching: false,
    fetchingDevices: false,
    error: false,
    deviceError: false,
    adding: false,
    seeding: false,
    addError: false,
    addingDevice: false,
    addDeviceError: false,
    updating: false,
    updatingDevices: false,
    uploadError: false,
    uploadErrorMessage: '',
    uploadErrorResult: []
  },
  reducers: {
    fetchIamsUsers: state => {
      state.fetching = true;
    },
    fetchWorkplaceDevices: state => {
      state.fetchingDevices = true;
    },
    fetchIamsUsersError: state => {
      state.fetching = false;
      state.error = true;
    },
    fetchWorkplaceDevicesError: state => {
      state.fetchingDevices = false;
      state.deviceError = true;
    },
    addIamsUser: state => {
      state.adding = true;
    },
    addIamsUserError: state => {
      state.adding = false;
      state.addError = true;
    },
    addWorkplaceDevice: state => {
      state.addingDevice = true;
    },
    addWorkplaceDeviceError: state => {
      state.addingDevice = false;
      state.addDeviceError = true;
    },
    updateIamsUsers: state => {
      state.updating = true;
    },
    updateWorkplaceDevices: state => {
      state.updatingDevices = true;
    },
    updateIamsUsersError: state => {
      state.updating = false;
      state.error = true;
    },
    updateWorkplaceDevicesError: state => {
      state.updatingDevices = false;
      state.deviceError = true;
    },
    uploadIamsUsersError: (state, action) => {
      state.updating = false;
      state.uploadError = true;
      state.uploadErrorMessage = "Upload error";
      state.uploadErrorResult = action.payload;
    },
    seedingIamsUsers: state => {
      state.seeding = true;
    },
    seedingIamsUsersSuccess: state => {
      state.seeding = false;
    },
    seedingIamsUsersError: state => {
      state.seeding = false;
    },
    uploadIamsUsersSuccess: state => {
      state.updating = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    },
    updateIamsUsersClear: state => {
      state.updating = false;
      state.uploadError = false;
      state.adding = false;
      state.addError = false;
      state.fetching = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    }
  },
  extraReducers: {
    [fetchIamsUsersSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [fetchWorkplaceDevicesSuccess]: state => {
      state.fetchingDevices = false;
      state.deviceError = false;
    },
    [fetchIamsUsersListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateIamsUsersSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateIamsUserSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [deleteIamsUsersSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [addIamsUserSuccess]: state => {
      state.adding = false;
      state.addError = false;
    },
    [addWorkplaceDeviceSuccess]: state => {
      state.addingDevice = false;
      state.addDeviceError = false;
    },
    [deleteWorkplaceDeviceSuccess]: state => {
      state.updatingDevices = false;
      state.deviceError = false;
    },
    [updateIamsUsersInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchIamsUsers,
  fetchIamsUsersError,
  addIamsUser,
  addIamsUserError,
  updateIamsUsers,
  updateIamsUsersError,
  uploadIamsUsersSuccess,
  updateIamsUsersClear,
  uploadIamsUsersError,
  seedingIamsUsers,
  seedingIamsUsersSuccess,
  seedingIamsUsersError,
  fetchWorkplaceDevices,
  fetchWorkplaceDevicesError,
  updateWorkplaceDevices,
  updateWorkplaceDevicesError,
  addWorkplaceDevice,
  addWorkplaceDeviceError
} = iamsUsersStatusSlice.actions;
