import {emptyGuid} from '../guid';

export const compareIsEmptyOrNullGuid = (guidOne, guidTwo) => {
  return ((guidOne === emptyGuid || guidOne === null) && (guidTwo === emptyGuid || guidTwo === null))
}

export const compareValues = (a, b) => {
  if ((a === null || a?.length === 0 || a === emptyGuid) && (b === null || b?.length === 0 || b === emptyGuid)) return true;
  if (a === null || a === undefined || b === null || b === undefined) return false;
  if (a === emptyGuid || b === emptyGuid) return false;
  if (a.length === 0 && b.length === 0) return true;
  if (a.length <= 0 || b.length <= 0) return false;
  return (
    a
      ?.toString()
      ?.trim()
      ?.toLowerCase() ===
    b
      ?.toString()
      ?.trim()
      ?.toLowerCase()
  );
};

const isEmptyOrNull = (value) => {
  return (value === emptyGuid || value === null || value === undefined || value?.length === 0)
}

export default isEmptyOrNull