import Microsoft365Details from '../../components/BackOffice/Microsoft365Details/Microsoft365Details';
import {faNetworkWired} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Microsoft365',
  path: 'microsoft365/',
  icon: faNetworkWired,
  editable: false,
  component: Microsoft365Details,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
