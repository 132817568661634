import {useEffect, useState} from 'react';
import useRolePermission from './useRolePermission';
import {useSelector} from 'react-redux';

const useWizardPermission = () => {
  const hasRole = useRolePermission(['Admin', 'Writer', 'Reader']);
  const {data} = useSelector(state => state.companies);
  const {id} = useSelector(state => state.company.data.info);
  const [accessToCompany, setAccessToCompany] = useState(false);

  useEffect(() => {
    let currentCompanyOnUser = true;
    if (data.some(userCompanyId => new Date(userCompanyId?.lockOutTimer).getTime() > new Date(Date.now()).getTime()))
      currentCompanyOnUser = false;

    if (currentCompanyOnUser) {
      setAccessToCompany(true);
    }
  }, [data, id]);

  return hasRole || accessToCompany;
};

export default useWizardPermission;
