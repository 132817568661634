import { Button, Checkbox, Message } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import AppStepCard from "../AppStepCard";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import produce from "immer";
import { internalRoleOptions } from "../../NewApplication/NewContent/NewContact";
import { ApplicationContact } from "../../../../../../utils/hub/models";
import { getInternalTitle } from "../../NewApplication/ContactTitles";
import { ContactList } from "../../NewApplication/ContactList";

export const StepSeven = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, totalSteps, setIsOpen}) => {
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const [contactRoles, setContactRoles] = useState([]);
  const [notRelevant, setNotRelevant] = useState(appObject.internalStatus === 2);
  const [internalContact, setInternalContact] = useState(null);
  const [internalOption, setInternalOption] = useState(null);
  const userOptions = userList?.filter(u => u.isEnabled === true)
    ?.filter(a => {
      return !appObject?.internalContacts?.some(i => i?.email === a.primaryEmailAddress);
    })
    .map(u => {
      return {value: u, label: `${u.firstName || ''} ${u.lastName || ''}`};
    });

  const onNotRelevant = (e) => {
    const { checked } = e.target;
    setAppObject(
      produce(draft => {
        draft.internalContacts = [];
        draft.internalStatus = checked ? 2 : 0;
      })
    );
    setNotRelevant(checked);
  }

  const onUserChange = e => {
    const {value} = e;
    setInternalOption(e);
    setInternalContact(
      produce(new ApplicationContact(), draft => {
        draft.firstName = value.firstName;
        draft.lastName = value.lastName;
        draft.email = value.primaryEmailAddress;
        draft.phoneNumber = value.mobilePhone;
      })
    );
  };

  const onSave = () => {
    if (internalContact !== undefined && internalContact !== null) {
      setAppObject(
        produce(draft => {
          draft.internalContacts?.length > 0 ?
            draft.internalContacts = [...draft?.internalContacts, internalContact] :
            draft.internalContacts = [internalContact]
        })
      );
      resetContact();
    }
  };

  const onRoleChange = e => {
    setContactRoles(e);
    setInternalContact(
      produce(draft => {
        draft.contactRoles = e.map(r => ({contactRole: r.value}));
      }));
  };

  const resetContact = () => {
    setContactRoles([]);
    setInternalContact(null);
    setInternalOption(null);
  };

  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} progress={progress} stepReady={notRelevant || appObject?.internalContacts?.length > 0} setProgress={setProgress} header='Who knows this application in your company?' totalSteps={totalSteps}>
      <Checkbox label='Not relevant for this application' className='step-not-relevant-check' checked={notRelevant} onChange={(e) => onNotRelevant(e)}/>
      <Message header='How to complete this step'>In order to select contacts from your company, you need to add them to the users list first. If you have not done this yet you can skip this step and return later.</Message>
      <div className='step-six-card'>
        <div className='bfl-grid step-six-select-row'>
          <Select
            options={userOptions}
            value={internalOption}
            onChange={onUserChange}
            label='Internal contact'
            placeholder='Search for user'
            isDisabled={notRelevant}
          />
          <Select
            options={internalRoleOptions}
            value={contactRoles}
            onChange={onRoleChange}
            isMulti
            label='Contact Role(s)'
            placeholder='- Select role(s) -'
            isDisabled={notRelevant || internalContact === null}
          />
        </div>
        <div>
          <Button disabled={contactRoles?.length === 0 || internalContact === null} onClick={() => onSave()}>Add</Button>
        </div>
      </div>
      <div className='application-contact-box'>
        <ContactList
          contacts={appObject?.internalContacts}
          getTitle={getInternalTitle}
          editing={false}
          deletable={true}
          deleteObject={appObject}
          setDeleteObject={setAppObject}
        />
      </div>
    </AppStepCard>
  );
};

export default StepSeven

StepSeven.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  newAppType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number
}