import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Icon, Label} from '@intility/bifrost-react';
import './editComponent.scss';
import produce from 'immer';
import {departmentType, Detail, detailType} from '../../../utils/hub/models';
import {multiPutAsync} from '../../../redux/company/details/detailThunks';
import {useDispatch, useSelector} from 'react-redux';
import {faUndo} from '@fortawesome/pro-regular-svg-icons';

export const EditLogistics = () => {
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const [isDeliveryReady, setIsDeliveryReady] = useState(new Detail());
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    resetValues();
  }, [detailList]);

  const onChange = (value, name, setItem, currentItem, type) => {
    const newValue = produce(currentItem, draft => {
      draft.name = name;
      draft[type] = value;
      draft.valueType = type;
      draft.department = departmentType.LOGISTICS;
    });

    const i = itemsToEdit.findIndex(i => i.name === name);
    if (i > -1)
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    else
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    setItem(newValue);
  };

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutAsync(itemsToEdit));
    }
    setItemsToEdit([]);
  };

  const resetValues = () => {
    setIsDeliveryReady(getDetail('DeliveryReady'));
    setItemsToEdit([]);
  };

  const getDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.LOGISTICS)?.find(i => i.name === name) || new Detail()
    );
  };

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Logistics</h3>
        <div className='backoffice-button-group'>
          {itemsToEdit.length > 0 && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button variant={'filled'} disabled={itemsToEdit.length === 0} onClick={() => saveDetails()}>
            Save
          </Button>
        </div>
      </div>
      <div className='bfl-autocol'>
        <div className='labeled-checkbox'>
          <Label>Delivery ready</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={isDeliveryReady?.boolValue}
            onChange={e =>
              onChange(e.target.checked, 'DeliveryReady', setIsDeliveryReady, isDeliveryReady, detailType.BOOL)
            }
            type={'switch'}
            label='Delivery ready'
          />
        </div>
      </div>
    </div>
  );
};

export default EditLogistics;
