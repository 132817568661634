import PropTypes from "prop-types";
import './sidebarCards.scss';
import { Badge, Button, Icon } from "@intility/bifrost-react";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import GoogleMapsPane from "../../GoogleMapsPane/GoogleMapsPane";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersAsync } from "../../../../../../../redux/company/users/userThunks";
import NotRegisteredField from "../../../../../../NotRegisteredField/NotRegisteredField";
import { imagePane } from "../../GoogleMapsPane/imagePane";
import { hasContactInfo } from "../accordion/DeliveryAccordionContent";

const GeneralInfoCard = ({ location, editLocation, editUsers, isComplete }) => {
  const { id } = useSelector(state => state.company.data.info);
  const { userList } = useSelector(state => state.company.data.userPlatform);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(fetchUsersAsync(id));
  }, [dispatch, id]);

  const userCount = userList?.filter(b => b.locationId === location.id)?.length;

  return (
    <div className='general-info-card'>
      <div className='image-container' style={{backgroundImage: `url(${imagePane(location)})`}}>
        <GoogleMapsPane location={location} uploadButton={true}/>
      </div>
      <div className='info-content'>
        <div className='info-header'>
          <p className='bf-large bf-strong'>Location</p>
          <Button pill noPadding small state={isComplete ? 'inactive' : 'default'} onClick={editLocation}><Icon icon={faPencil} /></Button>
        </div>
        <div>
          <NotRegisteredField field={location?.address}/>
          <NotRegisteredField field={location?.zipCode} fieldTwo={location?.postalPlace}/>
          <NotRegisteredField field={location?.country}/>
        </div>
        <div>
          <p className='bf-strong'>Intility managed</p>
          <NotRegisteredField field={location?.intilityManaged === 1 ? 'Yes' : location?.intilityManaged === 2 ? 'No' : null}/>
        </div>
        {hasContactInfo(location?.locationContact) ?
          <div>
            <p className='bf-strong'>Location contact</p>
            <NotRegisteredField field={location?.locationContact?.firstName}
                                fieldTwo={location?.locationContact?.lastName} />
            <NotRegisteredField field={location?.locationContact?.email} />
            <NotRegisteredField field={location?.locationContact?.phoneNumber} />
          </div>
          :
          <div>
            <p className='bf-strong'>Location contact</p>
            <div>
              <Badge state={"warning"}>Missing information</Badge>
            </div>
          </div>
        }
        <div>
          <Button state={isComplete ? 'inactive' : 'default'} onClick={editUsers}>{userCount} Users at location</Button>
        </div>
      </div>
    </div>
  )
}

GeneralInfoCard.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
  editUsers: PropTypes.func,
  isComplete: PropTypes.bool
}

export default GeneralInfoCard