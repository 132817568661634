import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchOnboardTicketsSuccess, updateOnboardTicketSuccess} from './onboardTicketActions';

const updateOnOnboardTicketChangeReducer = getState => {
  return {
    [fetchOnboardTicketsSuccess]: (state, action) => getState(action.payload, action.type, state),
    [updateOnboardTicketSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};

export const onboardTicketReducer = createReducer(
  [],
  updateOnOnboardTicketChangeReducer((payload, type, state) => {
    state = payload.ticketPlatform.ticketList;
    return state;
  })
);

export const onboardTicketStatusReducer = createSlice({
  name: 'onboardTicketsAction',
  initialState: {
    fetching: false,
    updating: false,
    error: false,
  },
  reducers: {
    fetchOnboardTickets: state => {
      state.fetching = true;
    },
    fetchOnboardTicketsError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateOnboardTickets: state => {
      state.updating = true;
    },
    updateOnboardTicketsError: state => {
      state.updating = false;
      state.error = true;
    }
  },
  extraReducers: {
    [fetchOnboardTicketsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateOnboardTicketSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchOnboardTickets,
  fetchOnboardTicketsError,
  updateOnboardTicketsError,
  updateOnboardTickets,
} = onboardTicketStatusReducer.actions;
