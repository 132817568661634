import {Users} from '../../../components/Hub/Pages';
import {faUser} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Users',
  path: 'users',
  icon: faUser,
  isEnabled: (company) => {
    return company?.userPlatform?.state
  },
  component: Users,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional users';
  }
};
