import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import './wallpaperoption.scss';
import {setChosenWallpaper} from 'redux/company/computerSetup/computerSetupThunks';
import {checkWallpaperProperties} from 'utils/wallpapers';
import {Link} from 'react-router-dom';
import {Checkbox} from '@intility/bifrost-react';
import { downloadFile } from "../../../../../../../utils/downloadFile";

const WallpaperOption = ({wallpaper, isDefault, type, title, canEdit, disabled}) => {
  const [errors, setErrors] = useState([]);
  const {id} = useSelector(state => state.company.data.info);
  const {customWallpaper} = useSelector(state => state.company.data.computerSetupPlatform.desktopSettings);
  const {desktopSettings} = useSelector(state => state.company.data.computerSetupPlatform);

  useEffect(() => {
    checkWallpaperProperties(wallpaper, setErrors);
  }, [wallpaper]);

  const dispatch = useDispatch();

  const onSelect = () => {
    if (!canEdit || disabled) return; // Returns if the user does not have edit permissions
    if (isDefault) {
      return dispatch(
        setChosenWallpaper({
          isEnabled: false,
          type: type,
          hasCustom: customWallpaper
        })
      );
    }
    dispatch(setChosenWallpaper({isEnabled: true, type: false}));
  };

  const getImageClasses = () => {
    let classes = 'wallpaper-option-image';

    if (!canEdit) {
      // Adds no-access class if the use does not have permission to change the wallpaper.
      classes = classes + ' no-access';
    }

    return classes;
  };

  return (
    <div className='wallpaper-option'>
      <div className='icon-container'>
        {errors?.length > 0 && (
          <div className='error-message-container'>
            <i className='fas fa-exclamation-triangle wallpaper-option-warning' />
            <span className='error-message'>
              {errors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </span>
          </div>
        )}
      </div>
      <div className='wallpaper-option-badge'>
        {isDefault ? (
          <Link target='_blank' to={wallpaper}>
            <p>Preview</p>
          </Link>
        ) : (
          <p onClick={() => downloadFile(customWallpaper, `/computerSetup/${id}/wallpaper`)}>
            Preview
          </p>
        )}
      </div>
      <img src={wallpaper.imageUri || wallpaper} alt={wallpaper.objectName || ''} className={getImageClasses()} />
      <Checkbox
        onChange={onSelect}
        checked={
          (isDefault && !customWallpaper?.isEnabled && desktopSettings.type === type) ||
          (!isDefault && customWallpaper?.isEnabled)
        }
        disabled={disabled}
        label={title}
        type='radio'
      />
    </div>
  );
};

WallpaperOption.propTypes = {
  title: PropTypes.string,
  wallpaper: PropTypes.oneOfType([
    PropTypes.shape({
      imageUri: PropTypes.string,
      objectName: PropTypes.string
    }),
    PropTypes.string
  ]),
  isDefault: PropTypes.bool,
  type: PropTypes.number,
  canEdit: PropTypes.bool,
  disabled: PropTypes.bool
};

export default WallpaperOption;
