import { Input } from "@intility/bifrost-react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

export const GoogleAddressAutocomplete = ({ location, setLocation, selectLocation }) => {

  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
  };

  const input = document.getElementById("autocomplete");

  const autocomplete = new google.maps.places.Autocomplete(input, options);
  autocomplete.addListener("place_changed", fillInAddress);

  function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let address = "";
    let streetNumber = "";
    let postcode = "";
    let postalPlace = "";
    let country = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          streetNumber = component.long_name;
          break;
        }
        case "route": {
          address += component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality":
          postalPlace = component.short_name;
          break;
        case "postal_town": {
          postalPlace = component.short_name;
          break;
        }
        case "country":
          country = component.long_name
          break;
      }
      selectLocation(({
        address: `${address} ${streetNumber}`,
        postalPlace: postalPlace,
        zipCode: postcode,
        country: country,
      }))
    }
  }

  return (
    <Input
      label='Address'
      value={location?.address}
      name='address'
      onChange={e => {
        setLocation(produce(location, draft => {
         draft.address = e.target.value
        }));
      }}
      type="text"
      placeholder="Enter your address"
      id="autocomplete"
    />
  )

}

GoogleAddressAutocomplete.propTypes = {
  location: PropTypes.object,
  setLocation: PropTypes.func,
  selectLocation: PropTypes.func
}

export default GoogleAddressAutocomplete

export const GoogleAddressCompanyService = ({ location, setLocation }) => {
  const [value, setValue] = useState('');

  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
  };

  const input = document.getElementById("autocomplete");

  const autocomplete = new google.maps.places.Autocomplete(input, options);
  autocomplete.addListener("place_changed", fillInAddress);

  function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();

    let streetName = "";
    let streetNumber = "";
    let postcode = "";
    let postalPlace = "";
    let municipality = "";
    let country = "";
    let region = "";
    let longitude = place.geometry.location.lng();
    let latitude = place.geometry.location.lat();

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          streetNumber = component.long_name;
          break;
        }
        case "route": {
          streetName += component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality":
          postalPlace = component.short_name;
          break;
        case "postal_town": {
          postalPlace = component.short_name;
          break;
        }
        case "administrative_area_level_2": {
          municipality = component.short_name;
          break;
        }
        case "administrative_area_level_1": {
          region = component.short_name;
          break;
        }
        case "country":
          country = component.long_name
          break;
      }
    }

    let name = `${streetName} ${streetNumber}, ${postcode} ${postalPlace}, ${country}`;
    setLocation(produce(location, draft => {
      draft.address.name = name;
      draft.address.streetName = streetName;
      draft.address.streetNumber = streetNumber;
      draft.address.zipCode = postcode;
      draft.address.municipality = municipality;
      draft.address.region = region;
      draft.address.country = country;
      draft.address.city = postalPlace;
      draft.address.longitude = longitude;
      draft.address.latitude = latitude;
    }));
  }

  return (
    <Input
      label='Search for address'
      description='Google maps lookup for autocomplete (replaces existing address values)'
      onChange={e => setValue(e.target.value)}
      value={value}
      type="text"
      placeholder="Enter your address"
      id="autocomplete"
      icon={faSearch}
      rightIcon={true}
    />
  )

}

GoogleAddressCompanyService.propTypes = {
  location: PropTypes.object,
  setLocation: PropTypes.func,
  selectLocation: PropTypes.func
}
