export const DeviceSettings = ({}) => {


  return (
    <div>

    </div>
  )
}

export default DeviceSettings
