import { FieldGroup, Icon, Input, Pagination, Table } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import {faSearch} from '@fortawesome/pro-solid-svg-icons';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateIamsUserAsync} from '../../../../../redux/company/iamsUsers/iamsUserThunks';
import produce from 'immer';
import {fetchUsersAsync} from '../../../../../redux/company/users/userThunks';
import PropTypes from 'prop-types';
import { faRedo } from "@fortawesome/pro-regular-svg-icons";

export const DeletedScans = ({deletedScans, isComplete}) => {
  const {information} = useSelector(state => state.company.data.iamsUserPlatform);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [searchCriterea, setSearchCriterea] = useState({
    value: 'firstName',
    label: 'First name'
  });
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState({key: 'firstName', direction: 'asc'});
  const dispatch = useDispatch();

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const updateScan = user => {
    if (!isComplete) {
      const updatedUser = produce(user, draft => {
        draft.isEnabled = true;
        draft.companyUserMatch = null;
      });
      dispatch(updateIamsUserAsync(updatedUser)).then(() => dispatch(fetchUsersAsync(information.companyId)));
    }
  };

  const sortedData = deletedScans
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key].toString()?.localeCompare(b[sort.key].toString());
      } else {
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key].toString()?.localeCompare(a[sort.key].toString());
      }
    })
    .filter(s => {
      if (s[searchCriterea.value] === null) return s;
      return s[searchCriterea.value]?.toLowerCase().includes(searchValue.toLowerCase());
    });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: deletedScans?.length, label: 'All'});
    return tableOptions;
  };

  const SearchOptions = [
    {value: 'firstName', label: 'First name'},
    {value: 'lastName', label: 'Last name'},
    {value: 'email', label: 'Email'},
    {value: 'computerName', label: 'Computer name'},
    {value: 'oldUsername', label: 'Old username'},
    {value: 'manufacturer', label: 'Manufacturer'}
  ];

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div>
      <div className='content-top-bar bfl-grid'>
        <FieldGroup label='Search'>
          <Select
            label='search criterea'
            hideLabel
            className='select-group-select'
            value={searchCriterea}
            options={SearchOptions}
            onChange={item => setSearchCriterea(item)}
          />
          <Input
            className='select-group-input'
            placeholder={`Search for ${searchCriterea.label}`}
            label='search input'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />

        </FieldGroup>
      </div>
      <Table>
        <colgroup>
          <col className='from-large' style={{width: '15%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '15%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '33%'}} />
          <col className='from-large' style={{width: '7%'}} />
        </colgroup>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('firstName')} onClick={onSortChangeCreator('firstName')}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('email')} onClick={onSortChangeCreator('email')}>
              Email
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('computerName')} onClick={onSortChangeCreator('computerName')}>
              Computer Name
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('oldUsername')} onClick={onSortChangeCreator('oldUsername')}>
              Old Username
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('manufacturer')} onClick={onSortChangeCreator('manufacturer')}>
              Manufacturer
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('comment')} onClick={onSortChangeCreator('comment')}>
              IAMS Comment
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAndIndexedData.map((u, i) => {
            return (
              <Table.Row key={u?.id + i}>
                <Table.Cell>{`${u?.firstName || ''} ${u?.lastName || ''}`}</Table.Cell>
                <Table.Cell>{u?.email || '-'}</Table.Cell>
                <Table.Cell>{u?.computerName || '-'}</Table.Cell>
                <Table.Cell>{u?.oldUsername || '-'}</Table.Cell>
                <Table.Cell>{u?.manufacturer || '-'}</Table.Cell>
                <Table.Cell>{u?.comment || '-'}</Table.Cell>
                <Table.Cell>{!u?.isEnabled && <span className='bf-link' style={isComplete ? {color: "grey"} : {}} onClick={() => updateScan(u)}><Icon style={{marginRight: 'var(--bfs8)'}} icon={faRedo}/>Restore</span>}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <div className='bottom-line'>
        <div className='row-box' />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
    </div>
  );
};

DeletedScans.propTypes = {
  deletedScans: PropTypes.array,
  isComplete: PropTypes.bool
};

export default DeletedScans;
