import React, { useEffect, useState } from "react";
import { Badge, Checkbox, Input, TextArea } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import PropTypes from "prop-types";

const networkStatusOptions = [
  {value: 0, label: <Badge state={"warning"}>Not set</Badge>},
  {value: 1, label: 'Yes'},
  {value: 2, label: 'No'}
];
const options = ['No coverage', 'Full coverage', 'Custom'];

export const NetworkContent = ({ location, editLocation }) => {
  const [thirdParty, setThirdParty] = useState({label: '', value: ''});
  const [cabledConnections, setCabledConnections] = useState({label: '', value: ''});

  useEffect(() => {
    setThirdParty(networkStatusOptions.find(i => i.value === location?.isThirdPartyShared) || {label: '', value: ''});
    setCabledConnections(networkStatusOptions.find(i => i.value === location?.hasEnoughCabledConnections) || {label: '', value: ''});
  }, []);

  return (
    <div className='info-content'>
      <Input label='Expected number of users at location' description='In order to best possible scale the network according to your needs, we would like an estimate of expected users on the location.'
             onChange={(e) => editLocation('userCount', e.target.value)} value={location?.userCount}/>
      <div className='separator'/>
      <Select value={thirdParty}
              options={networkStatusOptions}
              label='Network is shared with other companies'
              onChange={(e) => {
                editLocation('isThirdPartyShared', e.value)
                setThirdParty(e);
              }} />
      <Select value={cabledConnections}
              options={networkStatusOptions}
              label='Location has enough cabled network points'
              onChange={(e) => {
                editLocation('hasEnoughCabledConnections', e.value)
                setCabledConnections(e);
              }} />
      <p className='bf-large bf-strong'>Wifi coverage</p>
      {options.map(label => (
        <Checkbox
          type='radio'
          key={label}
          label={label}
          checked={location.wifiCoverage === label || label === 'Custom' && (location.wifiCoverage !== 'No coverage' && location.wifiCoverage !== 'Full coverage')}
          onChange={() => {
            editLocation('wifiCoverage', label);
          }}
        />
      ))}
      {(location.wifiCoverage !== 'No coverage' && location.wifiCoverage !== 'Full coverage') && (
        <TextArea
          value={location.wifiCoverage}
          onChange={(e) => {
            editLocation('wifiCoverage', e.target.value);
          }}
          label='Comment to custom WiFi coverage'
          required
          placeholder='Describe your custom wifi coverage'
          rows={4}
        />
      )}
      <TextArea
        value={location.mainPurpose}
        onChange={(e) => {
          editLocation('mainPurpose', e.target.value);
        }}
        label='Main purpose and requirement of WiFi'
        placeholder='Describe the main purpose'
        rows={4}
      />
    </div>
  )
}

NetworkContent.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
};