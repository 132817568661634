import { useSelector } from "react-redux";
import { Icon } from "@intility/bifrost-react";
import './appStepCard.scss';
import up from "../../../../../assets/images/Icons/Up.svg";
import down from "../../../../../assets/images/Icons/Down.svg";
import upAndDown from "../../../../../assets/images/Icons/UpAndDown.svg";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";
import React from "react";
import produce from "immer";


export const NewStepIntegration = ({integrations, appObject, setAppObject}) => {
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);

  const deleteIntegration = integration => {
    const index = integrations.findIndex(a => a?.relationId === integration?.relationId && a.applicationRelationType === integration.applicationRelationType)
    setAppObject(produce(appObject, draft => {
      draft?.applicationRelations.splice(index, 1);
    }));
  };

  return (
    <div className='application-integration-box'>
      <div className='box-content'>
        {integrations?.map((c, i) => {
          const details = applicationList?.find(i => i?.id === c?.relationId);
          return (
            <div key={i} className='integration-item-content'>
              <img
                src={c?.applicationRelationType === 0 ? down : c?.applicationRelationType === 1 ? up : upAndDown}
                alt='UpDownArrow'
              />
              <div className='integration-item-content-text'>
                <p>{details?.name}</p>
                <Icon onClick={() => deleteIntegration(c)} icon={faTrashAlt} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

NewStepIntegration.propTypes = {
  integrations: PropTypes.array,
  integrationType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func
};