import EditUserAdmin from '../../components/BackOffice/EditComponents/EditUserAdmin';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Useradmin',
  path: 'useradmin/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditUserAdmin
};
