import React from 'react';
import './communicationBox.scss';
import PropTypes from 'prop-types';

const CommunicationBox = ({type, title, services, serviceOptions}) => {
  const isChecked = staticService =>
    services.some(
      service =>
        service.name?.toLowerCase() === staticService.name?.toLowerCase() &&
        service.type?.toLowerCase() === type?.toLowerCase()
    );

  const items = serviceOptions.filter(staticService => isChecked(staticService));

  return (
    <div className='communication-box-item'>
      <div className='communication-header'>
        <p className='bf-strong'>{title}</p>
      </div>
      {services &&
        items.map(staticService => (
          <div key={staticService.name} className='communication-row'>
            <div className='communication-row-info'>
              <img src={staticService.logo} alt={staticService.name} />
            </div>
            <div style={{width: '100%'}}>
              <p>{staticService.name}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

CommunicationBox.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  services: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  serviceOptions: PropTypes.array
};

export const CommunicationBoxNote = ({note, title}) => {
  return (
    <div className='communication-box-item'>
      <div className='communication-header'>
        <p className='bf-strong'>{title}</p>
      </div>
      <div className='communication-row'>
        <div style={{width: '100%'}}>
          <p>{note}</p>
        </div>
      </div>
    </div>
  );
};

CommunicationBoxNote.propTypes = {
  title: PropTypes.string,
  note: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default CommunicationBox;
