import React, {useEffect, useState} from 'react';
import { AutoCol, Button, CheckboxCard, Icon } from "@intility/bifrost-react";
import './editComponent.scss';
import {useDispatch, useSelector} from 'react-redux';
import {faUndo} from '@fortawesome/pro-regular-svg-icons';
import { LayoutPageOptions } from "../OnboardLayout/layoutOptions";
import PageState from "../../../utils/hub/pageState";
import { updateCompanyLayout } from "../../../redux/company/companyThunks";
import { CompanyLayout } from "../../../utils/hub/models";
import produce from "immer";
import { fetchMs365PlatformAsync } from "../../../redux/company/ms365/ms365Thunks";

export const EditOnboardLayout = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {data} = useSelector(state => state.company);
  const [layoutPages, setLayoutPages] = useState([]);
  const [initialLayout, setInitialLayout] = useState([]);
  const [change, setChange] = useState(true);
  const [editCompany, setEditCompany] = useState(new CompanyLayout());
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(fetchMs365PlatformAsync(id));
  }, [id]);

  useEffect(() => {
    const userTypes = LayoutPageOptions.filter(p => {
      if (data[p.value]?.state !== PageState.DISABLED) return p;
    }).map(u => u.value)
    setLayoutPages(userTypes);
    setInitialLayout(userTypes);
    LayoutPageOptions.forEach(p => {
      setEditCompany(produce(draft => {
        draft[p?.value] = data[p?.value]?.state;
      }))
    });
  }, [data]);

  const saveUserAdmin = () => {
    if (!change) {
      dispatch(updateCompanyLayout(id, editCompany));
      setChange(true);
    }
  };

  const resetValues = () => {
    const userTypes = LayoutPageOptions.filter(p => {
      if (data[p.value]?.state !== PageState.DISABLED) return p;
    }).map(u => u.value)
    setLayoutPages(userTypes);
    setChange(initialLayout?.every(item => userTypes?.includes(item)) && initialLayout.length === userTypes.length);
  };

  const toggle = (option, e) => {
    let newTypes = [];
    if (e.target.checked) {
      newTypes = [...layoutPages, option.value];
    } else {
      newTypes = layoutPages?.filter(x => x !== option.value);
    }
    setLayoutPages(newTypes);
    setEditCompany(produce(draft => {
      draft[option?.value] = e.target.checked ? PageState.ENABLED : PageState.DISABLED;
    }));
    setChange(initialLayout?.every(item => newTypes?.includes(item)) && initialLayout.length === newTypes.length);
  }

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <div>
          <h5>Project page settings</h5>
          <p>Decide which project pages in Onboard that should be visible (for both customer and Intility users).</p>
        </div>
        <div className='backoffice-button-group'>
          {!change && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button variant={'filled'} disabled={change} onClick={() => saveUserAdmin()}>
            Save
          </Button>
        </div>
      </div>
      <AutoCol>
          {LayoutPageOptions.map(option => (
            <CheckboxCard
              key={option.label}
              label={option.label}
              checked={layoutPages?.includes(option.value)}
              onChange={e => toggle(option, e)}
            >
              {option.description}
            </CheckboxCard>
          ))}
      </AutoCol>
    </div>
  );
};

export default EditOnboardLayout;
