import {Contact} from '../../../components/Hub/Pages';
import {faBuilding} from '@fortawesome/pro-regular-svg-icons';
import PageState from '../pageState';

const contactData = {
  title: 'Contact',
  path: 'contact',
  icon: faBuilding,
  isEnabled: () => {return PageState.ENABLED},
  component: Contact,
  getStatus: company => {
    if (company.info.onboardingContact && company.info.productionContact) {
      return 'complete';
    }

    return 'error';
  },
  getStatusText: status => {
    if (status === 'error') {
      return 'Missing fields';
    }

    if (status === 'complete') {
      return 'Complete!';
    }

    return null;
  }
};

export default contactData;
