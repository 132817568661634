import React, {Suspense, useEffect} from 'react';
import '@intility/publish-article/Article.css';
import {Route, Switch} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Login from 'components/Login/Login';
import useAuth from 'hooks/useAuth';
import {setAuth} from 'redux/authSlice';
import {fetchCompaniesAsync} from 'redux/companiesSlice';
import apiFetch from 'utils/apiFetch';
import './main.scss';
import Error500 from './errorpages/error500';
import SmartNavigation from './components/Navigation/SmartNavigation';
import Hub from './components/Hub/Hub';
import Wizard from './components/Wizard/Wizard';
import BackOffice from './components/BackOffice/BackOffice';
import CompanyOverviewNew from './components/CompanyOverviewNew/CompanyOverviewNew';
import useIntilityPermission from './hooks/useIntilityPermission';
import CompanyOverview from './components/CompanyOverview/CompanyOverview';
import {FloatingMessage} from '@intility/bifrost-react';
import GlobalSettings from './components/GlobalSettings/GlobalSettings';
import Finance from './components/Finance/Finance';
import RedirectCloseWindow from './utils/RedirectCloseWindow';
import ProjectManagementOffice from './components/ProjectManagementOffice/ProjectManagementOffice';
import PdfPrinter from './components/BackOffice/WorkplaceDetails/PdfPrinter';

const App = () => {
  const dispatch = useDispatch();
  const {user, authenticating} = useSelector(state => state.auth, shallowEqual);
  const {fetching} = useSelector(state => state.companies);
  const view = useSelector(state => state.view);
  const auth = useAuth();
  const intilityUser = useIntilityPermission();


  useEffect(() => {
    dispatch(
      setAuth({
        user: auth.user,
        authenticating: auth.authenticating,
        roles: auth.roles
      })
    );
  }, [dispatch, auth.user, auth.authenticating, auth.roles]);

  useEffect(() => {
    if (!user) return;

    dispatch(fetchCompaniesAsync());
  }, [dispatch, user]);

  if (authenticating || fetching) return null;
  if (!user) {
    return (
      <Switch>
        <Route path='/redirect/close' exact>
          <RedirectCloseWindow/>
        </Route>
        <Route exact path='/error'>
          <Error500 />
        </Route>
        <Route path='/'>
          <Login />
        </Route>
      </Switch>
    );
  }

  return (
    <>
      <SmartNavigation logout={auth.logout}>
        <Suspense fallback={<div />}>
          <Switch>
            {view === 'Wizard' && <Wizard apiFetch={apiFetch} user={user} />}
            {(view === 'Hub' && intilityUser) && (
              <Route path='/details/:companyId/workplace/pdf'>
                <PdfPrinter/>
              </Route>
            )}
            {view === 'Hub' && (
              <Route path='/details/:companyId'>
                <FloatingMessage>
                  <BackOffice />
                </FloatingMessage>
              </Route>
            )}
            {view === 'Hub' && (
              <Route path='/redirect/close'>
                <RedirectCloseWindow/>
              </Route>
            )}
            {view === 'Hub' && (
              <Route path='/overview/settings'>
                <FloatingMessage>
                  <GlobalSettings />
                </FloatingMessage>
              </Route>
            )}
            {view === 'Hub' && (
              <Route path='/overview/finance'>
                <FloatingMessage>
                  <Finance />
                </FloatingMessage>
              </Route>
            )}
            {(view === 'Hub' && intilityUser) && (
              <Route path='/overview/pmo'>
                <FloatingMessage>
                  <ProjectManagementOffice />
                </FloatingMessage>
              </Route>
            )}
            {view === 'Hub' && (
              <Route path='/:companyId'>
                <Hub />
              </Route>
            )}
            {view === 'Hub' && (
              <Route path='/' exact>
                {intilityUser ?
                  <CompanyOverviewNew />
                  :
                  <CompanyOverview />
                }
              </Route>
            )}
          </Switch>
        </Suspense>
      </SmartNavigation>
    </>
  );
};

export default App;
