import {editListItem} from '../../../utils/hub/normalize';

export const editDomain = (domainList, domain) => {
  const newList = editListItem(domainList, domain);

  return {
    domainPlatform: {
      domainList: newList
    }
  };
};

export const normalizeDomains = domainPlatform => {
  return {
    domainPlatform: {
      domainList: domainPlatform.domainList,
      information: domainPlatform,
      state: domainPlatform.state
    }
  };
};

export const normalizeDomainInformation = domainPlatform => {
  return {
    domainPlatform: {
      information: domainPlatform,
      state: domainPlatform.state
    }
  };
};

export const normalizeDomainList = domainPlatform => {
  return {
    domainPlatform: {
      domainList: domainPlatform.domainList
    }
  };
};
