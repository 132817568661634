import produce from 'immer';
import {useDispatch} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {VendorContact} from '../../../../../utils/hub/models';
import devLog from '../../../../../utils/devLog';
import {
  addCurrentVendorContactAsync,
  deleteCurrentVendorContactAsync,
  updateCurrentVendorContactAsync
} from '../../../../../redux/company/companyThunks';
import {Button, CheckboxCard, Drawer} from '@intility/bifrost-react';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import PropTypes from 'prop-types';
import './editContactSlider.scss';
import {emptyGuid} from '../../../../../utils/guid';
import {ValidateEmail, ValidatePhonenumber} from '../../../../../utils/wizard/stateValidations';

const onChange = (e, setState) => {
  const {name, value} = e.target;

  setState(
    produce(draft => {
      draft[name] = value;
    })
  );
};

const EditVendorContactSlider = ({closeModal, isOpen, contact}) => {
  const dispatch = useDispatch();
  const [editedContact, setEditedContact] = useState(new VendorContact());
  const [contactable, setContactable] = useState(false);
  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (contact !== undefined && contact !== null && isOpen === true) {
      setEditedContact(contact);
      setContactable(contact?.contactable);
    }
  }, [isOpen, contact]);

  const onSave = () => {
    const errors = {
      email: ValidateEmail(editedContact.email),
      phoneNumber: ValidatePhonenumber(editedContact.phoneNumber)
    };

    setValidation(errors);

    console.log('Validation errors:', errors);

    if (Object.values(errors).some(error => error)) {
      console.log('Validation failed. Errors exist.');
      return; // Stop saving if there are validation errors
    }

    if (contact?.id === undefined || contact?.id === emptyGuid) {
      devLog('Adding!', contact);
      dispatch(addCurrentVendorContactAsync(editedContact));
    } else {
      devLog('Updating!', contact);
      dispatch(updateCurrentVendorContactAsync(contact.id, editedContact));
    }
    onClose();
  };

  const onDelete = contactId => {
    dispatch(deleteCurrentVendorContactAsync(contactId));
    onClose();
  };

  const onClose = () => {
    setEditedContact(new VendorContact());
    setContactable(false);
    setValidation({});
    closeModal();
  };

  const contactOnChange = e => {
    onChange(e, setEditedContact);
  };

  return (
    <Drawer
      header='Edit vendor contact'
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlay
      footer={
        contact?.id !== undefined || contact?.id !== emptyGuid ? (
          <div className='contact-drawer-bottom-button-row'>
            <Button variant='outline' state='alert' onClick={() => onDelete(contact.id)}>
              Delete
            </Button>
            <div className='button-row-collection'>
              <Button onClick={() => onClose()} key={0}>
                Cancel
              </Button>
              <Button variant='filled' onClick={() => onSave()} key={1}>
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div className='contact-drawer-bottom-button-row'>
            <Button style={{marginRight: 'var(--bfs16)'}} onClick={() => onClose()} key={0}>
              Cancel
            </Button>
            <Button variant='filled' onClick={() => onSave()} key={1}>
              Save
            </Button>
          </div>
        )
      }
    >
      <form
        onSubmit={event => {
          event.preventDefault();
          onSave();
        }}
      >
        <InputWithFeedback
          onChange={contactOnChange}
          value={editedContact?.vendorName}
          name='vendorName'
          label='Vendor name'
        />
        <div className='edit-contact bfl-grid'>
          <InputWithFeedback
            onChange={contactOnChange}
            value={editedContact?.firstName}
            name='firstName'
            label='First name'
          />
          <InputWithFeedback
            onChange={contactOnChange}
            value={editedContact?.lastName}
            name='lastName'
            label='Last name'
          />
          <InputWithFeedback
            onChange={contactOnChange}
            value={editedContact?.phoneNumber}
            name='phoneNumber'
            label='Phone Number'
            otherProps={
              validation?.phoneNumber && {
                state: 'warning',
                feedback: 'Invalid phone number'
              }
            }
          />
          <InputWithFeedback
            onChange={contactOnChange}
            value={editedContact.email}
            name='email'
            label='E-mail'
            otherProps={
              validation?.email && {
                state: 'warning',
                feedback: 'Invalid email address'
              }
            }
          />
        </div>
        <CheckboxCard
          label='Intility can contact this vendor'
          type={'switch'}
          checked={contactable}
          onChange={() => {
            setEditedContact(
              produce(draft => {
                draft.contactable = !contactable;
              })
            );
            setContactable(!contactable);
          }}
        >
          You might want to inform your current vendor about this process before we contact them.
        </CheckboxCard>
      </form>
    </Drawer>
  );
};

EditVendorContactSlider.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  contact: PropTypes.object
};

export default EditVendorContactSlider;
