import globalTask from './globalTaskSettings';
import globalServices from './globalServiceSettings';
import devices from './deviceSettings';
import projectTeams from './projectTeamSettings';

export default [
  globalTask,
  globalServices,
  devices,
  projectTeams,
];
