import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {platformType, updatePlatformAsync} from '../platformUpdateThunk';
import {
  fetchMicrosoft365s,
  fetchMicrosoft365sError,
  updateMicrosoft365s,
  updateMicrosoft365sError
} from './ms365Reducers';
import {fetchInformationSuccess, updateMicrosoft365InformationSuccess} from './ms365Actions';

export const fetchMs365PlatformAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.update;
  if (fetching) return null;
  dispatch(fetchMicrosoft365s());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/microsoft365`,
    method: 'GET'
  }).then(computerSetup => dispatch(fetchInformationSuccess(computerSetup)))
    .catch(error => dispatch(fetchMicrosoft365sError(error)));
};

export const updateMs365PlatformAsync = data => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  const {fetching} = getState().company.update;
  if (fetching) return null;
  dispatch(updateMicrosoft365s());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/microsoft365`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: JSON.stringify(data)
  }).then(computerSetup => dispatch(updateMicrosoft365InformationSuccess(computerSetup)))
    .catch(error => dispatch(updateMicrosoft365sError(error)));
};

export const setSetupValue = (name, value) => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  const {desktopSettings} = getState().company.data.computerSetupPlatform;

  return dispatch(
    updatePlatformAsync(platformType.MS365, id, {
      [name]: value,
      isEnabled: desktopSettings.isEnabled
    })
  );
};
