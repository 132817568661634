import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import './communicationEditable.scss';
import {Accordion, Checkbox} from '@intility/bifrost-react';
import PropTypes from 'prop-types';

const CommunicationEditable = ({
  type,
  title,
  addAction,
  deleteAction,
  services,
  serviceOptions,
  isAllCollapsed,
  isComplete
}) => {
  const [isThisCollapsed, setIsThisCollapsed] = useState(false);
  const dispatch = useDispatch();

  const allItems = serviceOptions.filter(item => item)?.length;

  useEffect(() => {
    setIsThisCollapsed(isAllCollapsed);
  }, [isAllCollapsed]);

  const sumServices = () => {
    let num = 0;
    services.forEach(service => {
      if (service.type?.toLowerCase() === type?.toLowerCase()) {
        num++;
      }
    });
    return num;
  };

  const isChecked = staticService =>
    services.some(
      service =>
        service.name?.toLowerCase() === staticService.name?.toLowerCase() &&
        service.type?.toLowerCase() === type?.toLowerCase()
    );

  const onChange = e => {
    const {name} = e.target;
    const value = e.target.checked;

    if (value === true) {
      dispatch(addAction(name));
    } else {
      const item = services.find(
        service =>
          service.name?.toLowerCase() === name?.toLowerCase() && service?.type.toLowerCase() === type?.toLowerCase()
      );
      dispatch(deleteAction(item.id));
    }
  };

  return (
    <Accordion.Item
      onClick={() => setIsThisCollapsed(!isThisCollapsed)}
      active={!isThisCollapsed}
      title={`${type} (${sumServices()}/${allItems})`}
    >
      <div className='communication-content'>
        <div className='communication-content-main'>
          <p>{title}</p>
          {services &&
            serviceOptions.map(staticService => (
              <div key={staticService.name} className='communication-row'>
                <div className='communication-row-info'>
                  <img src={staticService.logo} alt={staticService.name} />
                </div>
                <div style={{width: '100%', display: 'flex'}}>
                  <Checkbox
                    disabled={isComplete}
                    type='switch'
                    style={{flex: '1'}}
                    label={staticService.name}
                    checked={isChecked(staticService)}
                    onChange={onChange}
                    name={staticService.name}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Accordion.Item>
  );
};

CommunicationEditable.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  addAction: PropTypes.func,
  deleteAction: PropTypes.func,
  services: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  serviceOptions: PropTypes.array,
  isAllCollapsed: PropTypes.bool,
  isComplete: PropTypes.bool,
  canEdit: PropTypes.bool
};

export default CommunicationEditable;
