import React, {useEffect, useState} from 'react';
import { Button, Dropdown, FieldGroup, Icon, Input, Pagination, Table } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import './editComponent.scss';
import './editApplicationFilters.scss';
import {useDispatch, useSelector} from 'react-redux';
import { ApplicationFilter } from "../../../utils/hub/models";
import produce from 'immer';
import {
  addApplicationFilterAsync, deleteApplicationFilterAsync,
  fetchApplicationFiltersAsync
} from "../../../redux/company/applications/applicationThunks";
import {
  filterParameterOptions,
  filterTypeOptions
} from "../../Hub/Pages/Devices.Computers/IamsRegistration/IamsSelectOptions";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";

export const EditApplications = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {filters} = useSelector(state => state.company.data.applicationPlatform);
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [type, setType] = useState({ value: '', label: '' });
  const [parameter, setParameter] = useState({ value: '', label: '' });
  const [listCopy, setListCopy] = useState([]);
  const [newFilter, setNewFilter] = useState(new ApplicationFilter());
  const [searchValue, setSearchValue] = useState('');
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchApplicationFiltersAsync());
  }, [user, dispatch, id]);

  useEffect(() => {
    setListCopy(filters);
  }, [filters]);


  // eslint-disable-next-line no-unused-vars
  const onChange = (filter) => {

    const index = listCopy.findIndex(obj => obj.id === filter.id);
    setListCopy(
      produce(listCopy, draft => {
        draft[index] = {...filter};
      })
    );

    const i = itemsToEdit.findIndex(obj => obj?.id === filter.id);
    if (i > -1) {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...filter};
        })
      );
    } else {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(filter);
        })
      );
    }
  };

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: filters?.length, label: 'All'});
    return tableOptions;
  };


  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const getTypeValue = (type) => {
    switch (type) {
      case 0: return 'Starts with';
      case 1: return 'Ends with';
      case 2: return 'Contains';
      case 3: return 'Equals';
      default: return 'Unknown';
    }
  };

  const getParameterValue = (parameter) => {
    switch (parameter) {
      case 0: return 'Software Name';
      case 1: return 'Software Publisher';
      default: return 'Unknown';
    }
  };

  const saveFilter = () => {
    if (newFilter.value.length > 0) {
      dispatch(addApplicationFilterAsync(newFilter));
      setType({ value: '', label: '' });
      setParameter({ value: '', label: '' });
    }
    setNewFilter(new ApplicationFilter());
  };


  const sortedData = [...listCopy]
    .sort((a, b) => {
      if (sort.key === 'filterType' && sort.direction === 'asc') {
        return a.filterType >= b.filterType;
      } else if (sort.key === 'filterType' && sort.direction === 'desc') {
        return b.filterType >= a.filterType;
      } else if (sort.key === 'parameterType' && sort.direction === 'asc') {
        return a.parameterType >= b.parameterType;
      } else if (sort.key === 'parameterType' && sort.direction === 'desc') {
        return b.parameterType >= a.parameterType;
      } else if (sort.direction === 'asc') {
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    }).filter(s => {
      return s.value?.toLowerCase().includes(searchValue.toLowerCase());
    });

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>IAMS Application filters</h3>
      </div>
      <div className='filter-row'>
        <div className='filter-btn-row'>
          <Select placeholder='Select parameter' label='Parameter' value={parameter} options={filterParameterOptions} onChange={e => {
            setParameter(e);
            setNewFilter(
              produce(newFilter, draft => {
                draft.parameterType = e.value
              })
            );
          }}/>
          <Select placeholder='Select type' label='Filter type' value={type} options={filterTypeOptions} onChange={e => {
            setType(e);
            setNewFilter(
              produce(newFilter, draft => {
                draft.filterType = e.value
              })
            );
          }}/>
          <Input label='Value' value={newFilter.value} onChange={e => setNewFilter(
            produce(newFilter, draft => {
              draft.value = e.target.value
            })
          )}/>
          <Button variant={"filled"} disabled={newFilter.filterType.length === 0 || newFilter.parameterType.length === 0 || newFilter.value.length === 0} onClick={() => saveFilter()}>Add filter</Button>
        </div>
        <Input
          className='table-search-input'
          placeholder='Search for filter'
          label='Search'
          icon={faSearch}
          clearable
          value={searchValue}
          onChange={e => {
            setSearchValue(e.target.value);
            setPage(1);
          }}
          rightIcon
        />
      </div>
      <div className='backoffice-editable-table'></div>
      <Table className='edit-app-filter-table'>
        <colgroup>
          <col className='from-large' style={{width: '20%'}} />
          <col className='from-large' style={{width: '20%'}} />
          <col className='from-large' style={{width: '60%'}} />
        </colgroup>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('filterType')} onClick={onSortChangeCreator('filterType')}>
              Filter type
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('parameterType')} onClick={onSortChangeCreator('parameterType')}>
              Parameter
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('value')} onClick={onSortChangeCreator('value')}>
              Filter value
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAndIndexedData.map(filter => (
            <Table.Row key={filter?.id}>
              <Table.Cell>{getTypeValue(filter.filterType)}</Table.Cell>
              <Table.Cell>{getParameterValue(filter.parameterType)}</Table.Cell>
              <Table.Cell>{filter.value}</Table.Cell>
              <Table.Cell>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Dropdown content={<Button variant={"outline"} state={"alert"} onClick={() => dispatch(deleteApplicationFilterAsync(filter.id))}>Delete</Button>} variant='border'>
                    <Icon icon={faEllipsisH} style={{cursor: 'pointer'}}/>
                  </Dropdown>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div />
        )}
        <Select
          key='Items'
          label='Table size'
          className='table-size-dropdown'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
    </div>
  );
};

export default EditApplications;
