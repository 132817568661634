import { Button, Drawer, useFloatingMessage } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import React, {useEffect, useState} from 'react';
import produce from 'immer';
import './newContact.scss';
import {ApplicationContact} from '../../../../../../utils/hub/models';
import PropTypes from 'prop-types';
import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import devLog from '../../../../../../utils/devLog';
import {
  addApplicationContactAsync,
  deleteApplicationContactAsync,
  updateApplicationContactAsync
} from '../../../../../../redux/company/applications/applicationContacts/applicationContactThunks';
import {contactType} from '../../ApplicationTypes';
import {fetchUsersAsync} from '../../../../../../redux/company/users/userThunks';
import InputWithFeedback from '../../../../../InputWithFeedback/InputWithFeedback';

export const NewContact = ({editContact, contactOpen, setIsOpen, type, setEditContact, applicationId, appStatus, setAddedElement}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const [contact, setContact] = useState(new ApplicationContact());
  const [validation, setValidation] = useState(undefined);
  const [contactRoles, setContactRoles] = useState([]);
  const [userOption, setUserOption] = useState(null);
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { showFloatingMessage } = useFloatingMessage();

  useEffect(() => {
    if (appStatus.error) {
      showFloatingMessage('Ups, an error occurred, but our developers are notified, please try again later', { state: 'alert' });
    }
  }, [appStatus.error])

  const onContactChange = e => {
    const {name, value} = e.target;
    setContact(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const application = applicationList?.find(app => app.id === applicationId);
  const userOptions = userList
    ?.filter(a => {
      return !application?.internalContacts?.some(i => i?.email === a.primaryEmailAddress);
    })
    .map(u => {
      return {value: u, label: `${u.firstName || ''} ${u.lastName || ''}`};
    });

  const onSave = type => {
    const isValid = contact?.phoneNumber?.length > 0 || contact?.email?.length > 0 || type === contactType.INTERNAL
    setValidation(isValid);
    if (isValid && editContact !== undefined && editContact !== null) {
      devLog('Updating!', contact);
      dispatch(updateApplicationContactAsync(contact, contactRoles, type));
      resetContact();
      setAddedElement(true);
    } else if (isValid) {
      devLog('Adding!', contact);
      dispatch(addApplicationContactAsync(applicationId, contact, contactRoles, type));
      resetContact();
      setAddedElement(true);
    }
  };

  const resetContact = () => {
    setEditContact(null);
    setUserOption(null);
    setContactRoles([]);
    setContact(new ApplicationContact());
    setIsOpen(false);
  };

  const onUserChange = e => {
    const {value} = e;
    setUserOption(e);
    setContact(
      produce(draft => {
        draft.firstName = value.firstName;
        draft.lastName = value.lastName;
        draft.email = value.email || value.primaryEmailAddress;
        draft.phoneNumber = value.phoneNumber || value.mobilePhone;
      })
    );
  };

  let vendorOptions = applicationList.map(a => ({i: a.email, value: a})).flatMap(a => a.value.vendorContacts)
    vendorOptions = [...new Map(vendorOptions.map(item =>
      [item.email, item])).values()]
    .map(u => {
      return {value: u, label: `${u.firstName || ''} ${u.lastName || ''}`};
    });

  const getRoles = (contactroles, type) => {
    if (type === contactType.INTERNAL) {
      return contactroles.map(r => {
        return {value: r.contactRole, label: GetInternalRole(r.contactRole)};
      });
    } else {
      return contactroles.map(r => {
        return {value: r.contactRole, label: GetVendorRole(r.contactRole)};
      });
    }
  };

  useEffect(() => {
    if (editContact !== undefined && editContact !== null) {
      setContact(editContact);
      setContactRoles(getRoles(editContact.contactRoles, type));
      let user;
      if (type === contactType.INTERNAL) {
        user = userList?.find(
          u => u.primaryEmailAddress === editContact.email || u.mobilePhone === editContact.phoneNumber
        );
        setUserOption({ value: user, label: `${user?.firstName || ''} ${user?.lastName || ''}` });
      } else {
        user = vendorOptions?.find(
          u => u?.value?.email === editContact.email || u?.value?.phoneNumber === editContact.phoneNumber
        );
        setUserOption(user);
      }
    } else {
      setContact(new ApplicationContact());
    }
    // eslint-disable-next-line
  }, [editContact]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchUsersAsync(id));
  }, [editContact, dispatch, user, id]);

  return (
    <Drawer
      isOpen={contactOpen}
      overlay={true}
      header={editContact !== undefined && editContact !== null ? `Edit contact` : 'Add new contact'}
      onRequestClose={() => resetContact()}
      id='new-contact'
      footer={
        <div className='drawer-bottom-button-row'>
          {editContact !== undefined && editContact !== null ? (
            <Button
              variant='outline'
              state='alert'
              onClick={() => {
                setIsOpen(false);
                setEditContact();
                dispatch(deleteApplicationContactAsync(editContact, type));
              }}
              icon={faTimes}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <div className='button-row-collection'>
            <Button onClick={() => resetContact()} key={0}>
              Cancel
            </Button>
            <Button variant='filled' onClick={() => onSave(type)} key={1}>
              Save
            </Button>
          </div>
        </div>
      }
    >
      <div className='body'>
        {type === contactType.INTERNAL ? (
          <div className='bfl-grid internal-content'>
            <Select
              label='Company user'
              value={userOption}
              options={userOptions}
              name='contact'
              onChange={e => onUserChange(e)}
            />
            <Select
              label='Role(s)'
              placeholder='- Select contact roles -'
              options={internalRoleOptions}
              onChange={setContactRoles}
              value={contactRoles}
              isMulti
            />
          </div>
        ) : (
          <div>
            <Select
              label='Choose from existing vendors'
              value={userOption}
              options={vendorOptions}
              name='contact'
              onChange={e => onUserChange(e)}
            />
            <div className='bfl-grid vendor-content'>
              <InputWithFeedback
                onChange={onContactChange}
                value={contact?.firstName}
                name='firstName'
                label='First Name'
              />
              <InputWithFeedback onChange={onContactChange} value={contact?.lastName} name='lastName' label='Last Name' />
              <InputWithFeedback
                onChange={onContactChange}
                value={contact?.phoneNumber}
                name='phoneNumber'
                label='Phone Number'
                otherProps={
                  validation === false && {
                    state: 'warning',
                    feedback: 'Need email or phone number'
                  }
                }
              />
              <InputWithFeedback
                onChange={onContactChange}
                value={contact.email}
                name='email'
                label='E-mail'
                otherProps={
                  validation === false && {
                    state: 'warning',
                    feedback: 'Need email or phone number'
                  }
                }
              />
              <Select
                label='Role(s)'
                placeholder='- Select contact roles -'
                options={externalRoleOptions}
                onChange={setContactRoles}
                value={contactRoles}
                isMulti
              />
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export const GetVendorRole = role => {
  switch (role) {
    case 0:
      return 'ApplicationProvider';
    case 1:
      return 'SupportProvider';
    case 2:
      return 'LicenseProvider';
    case 3:
      return 'ProjectManager';
    case 4:
      return 'Implementor';
    default:
      return 'Undefined';
  }
};

export const GetInternalRole = role => {
  switch (role) {
    case 0:
      return 'SuperUser';
    case 1:
      return 'SystemOwner';
    case 2:
      return 'Tester';
    default:
      return 'Undefined';
  }
};

export const internalRoleOptions = [
  {value: 0, label: 'SuperUser'},
  {value: 1, label: 'SystemOwner'},
  {value: 2, label: 'Tester'}
];

export const externalRoleOptions = [
  {value: 0, label: 'ApplicationProvider'},
  {value: 1, label: 'SupportProvider'},
  {value: 2, label: 'LicenseProvider'},
  {value: 3, label: 'ProjectManager'},
  {value: 4, label: 'Implementor'}
];

NewContact.propTypes = {
  editContact: PropTypes.object,
  setEditContact: PropTypes.func,
  contactOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  type: PropTypes.string,
  applicationId: PropTypes.string,
  appStatus: PropTypes.object,
  setAddedElement: PropTypes.func
};

export default NewContact;
