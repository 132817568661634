import NetworkDetails from '../../components/BackOffice/NetworkDetails/NetworkDetails';
import {faNetworkWired} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Network',
  path: 'network/',
  icon: faNetworkWired,
  editable: false,
  component: NetworkDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
