import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Line, Polygon } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import apiFetch from "../../../utils/apiFetch";
import onboardLogo from '../../../../src/assets/images/icon/Onboard.png';
import pdfBlocks from '../../../../src/assets/images/pdf/pdfBlocks.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  section: {
    padding: '5 20',
    width: '100%'
  },
  header: {
    display: "flex",
    flexDirection: 'row',
    color: 'white',
    backgroundColor: '#0f2033',
    padding: 6,
    alignItems: 'flex-end',
    marginBottom: '20px'
  },
  text: {
    color: 'grey',
    width: '30%',
    //display: "flex",
  },
  logo: {
    height: '40px',
    width: '40px',
    margin: '6px'
  },
  headerBoxes: {
    height: '52px',
    width: '100px',
    marginLeft: 'auto'
  }
});

// Create Document Component
export const PdfPrinter = () => {
  const {id} = useSelector(state => state.company.data.info);
  const [printUsers, setPrintUsers] = useState([]);

  useEffect(() => {
    if (id) {
      fetchPdfUserComputers(id);
    }
  }, []);

  const fetchPdfUserComputers = () => {
    apiFetch(`/companies/${id}/companyUsers/fetchPrintUsers`)
      .then(response => {
        if (!response.ok) {
          setPrintUsers([]);
          return [];
        }
        return response.json()
      })
      .then(res => {
        setPrintUsers(res);
      })
  };

  return (
    <div style={{ height: "100%", display: 'flex', justifyContent: 'center' }}>
      <PDFViewer height={1200} width={1000}>
        <Document>
          {printUsers.map(u => {
            return (
              <Page size="A4" break={false} style={styles.page}>
                <View style={styles.header}>
                  <Image src={onboardLogo} style={styles.logo}/>
                  <Text style={{fontSize: '20', marginBottom: 6, marginLeft: '10px'}}>{u.location}</Text>
                  <Image src={pdfBlocks} style={styles.headerBoxes}/>
                </View>
                {u.priority && <Text style={{color: 'red', marginLeft: '20px', fontSize: '26'}}>PRIORITERT</Text>}
                <Text style={{fontSize: '30', marginLeft: '20px'}}>{u.firstName} {u.lastName}</Text>
                <Text style={{color: 'grey', margin: '0 20px 10px 20px', wordBreak: 'break-word'}}>{u?.userComment}</Text>
                <Text style={{color: 'grey', margin: '0 20px 10px 20px', wordBreak: 'break-word'}}>{u?.deviceComment}</Text>
                <Text style={{borderBottom: '2px solid var(--bfc-base-dimmed)', marginTop: '20px'}}/>
                <Text style={{marginLeft: '20px', marginTop: '40px'}}>USER INFORMATION</Text>
                <View style={styles.section}>
                  <View style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>AD:</Text>
                    <Text style={{width: '70%'}}>{u.newSam}</Text>
                  </View>
                  <View style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>Mail:</Text>
                    <Text style={{width: '70%'}}>{u.primaryEmailAddress}</Text>
                  </View>
                </View>
                <Text style={{marginLeft: '20px', marginTop: '40px'}}>HARDWARE INFORMATION</Text>
                <View style={styles.section}>
                  <View style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>Install type:</Text>
                    <Text style={{width: '70%'}}>{u.computerAction}</Text>
                  </View>
                  <View style={{display: "flex", flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>Model:</Text>
                    <Text style={{width: '70%'}}>{u?.device}</Text>
                  </View>
                  <View style={{display: "flex", flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>License:</Text>
                    <Text style={{width: '70%'}}>{u?.os}</Text>
                  </View>
                  <View style={{display: "flex", flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>Docking:</Text>
                    <Text style={{width: '70%'}}>{u?.docking}</Text>
                  </View>
                  <View style={{display: "flex", flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>Screen:</Text>
                    <Text style={{width: '70%'}}>{u?.screen}</Text>
                  </View>
                  <View style={{display: "flex", flexDirection: 'row', width: '100%'}}>
                    <Text style={styles.text}>Peripherals:</Text>
                    <Text style={{width: '70%'}}>{u?.peripherals}</Text>
                  </View>
                </View>
              </Page>
            )
          })}
        </Document>
      </PDFViewer>
    </div>
  );
};

export default PdfPrinter