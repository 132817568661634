import {Printers} from '../../../components/Hub/Pages';
import {faPrint} from '@fortawesome/pro-regular-svg-icons';

const printersData = {
  title: 'Printers',
  path: 'printers',
  icon: faPrint,
  isEnabled: (company) => {return company?.printerPlatform?.state},
  component: Printers,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return company.info.name;
  }
};

export default printersData;
