import {apiFetch, apiFetchIdCollection} from 'utils/apiFetch';
import {
  addUser,
  fetchUsers,
  fetchUsersError,
  syncGraphUsers,
  updateUsers,
  updateUsersError,
  uploadUsersError
} from './usersReducers';
import {
  addUserSuccess,
  deleteUsersSuccess,
  fetchUsersSuccess,
  updateUsersInformationSuccess,
  updateUsersSuccess,
  updateUserSuccess
} from './usersActions';
import devLog from '../../../utils/devLog';
import {platformType, updatePlatformAsync} from '../platformUpdateThunk';
import {UserTableType} from '../../../components/Hub/Pages/Users/UserRegistration/UserRegistration';
import useIntilityPermission from '../../../hooks/useIntilityPermission';


export const updateRotReadyness = data => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  const {information} = getState().company.data.userPlatform;
  return dispatch(
    updatePlatformAsync(platformType.COMPANYUSERS, id, {
      isRotReady: data,
      isEnabled: information.isEnabled
    })
  );
};

export const setValidUserTypesAsync = data => (dispatch, getState) => {
  const {updating} = getState().company.data.userPlatform.usersStatus;
  if (updating) return null;
  dispatch(updateUsers());

  const {id} = getState().company.data.info;

  return apiFetch(`/companies/${id}/companyUsers/userType`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to update company.');
      }
      return response.json();
    })
    .then(platform => {
      dispatch(updateUsersInformationSuccess(platform));
      return platform;
    })
    .catch((error) => dispatch(updateUsersError(error)));
};

export const fetchUsersAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.userPlatform.usersStatus;
  if (fetching) return null;
  dispatch(fetchUsers());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/companyUsers`,
    method: 'GET'
  }).then(users => dispatch(fetchUsersSuccess(users)))
    .catch(error => dispatch(fetchUsersError(error)));
};

export const populateGraphUsersAsync = companyId => (dispatch, getState) => {
  const {syncing} = getState().company.data.userPlatform.usersStatus;
  if (syncing) return null;
  dispatch(syncGraphUsers());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/companyUsers/graphUsers`,
    method: 'GET'
  }).then(() => dispatch(fetchUsersAsync(companyId)))
    .catch(error => dispatch(fetchUsersError(error)));
};

export const testAdminAccess = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.userPlatform.usersStatus;
  if (fetching) return null;
  dispatch(fetchUsers());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/companyUsers/testAdminConsent`,
    method: 'GET'
  }).then(result => result)
    .catch(error => dispatch(fetchUsersError(error)));
};

export const addCompanyUserAsync = user => (dispatch, getState) => {
  const {usersStatus, information, userList} = getState().company.data.userPlatform;
  const {id} = getState().company.data.info;
  if (usersStatus.updating) return null;
  dispatch(addUser());

  const body = {
    ...user,
    companyUserPlatformId: information.id
  };

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/companyUsers`,
    method: 'POST',
    body: body
  }).then(user => dispatch(addUserSuccess(user, userList)))
    .catch(error => dispatch(updateUsersError(error)));
};

export const changeUserTypeAsync = (userId, type) => (dispatch, getState) => {
  const {usersStatus, userList} = getState().company.data.userPlatform;
  const {id} = getState().company.data.info;
  if (usersStatus.updating) return null;
  dispatch(updateUsers());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/companyUsers/${userId}/userType`,
    method: 'POST',
    body: type
  }).then(user => dispatch(updateUserSuccess(user, userList)))
    .catch(error => dispatch(updateUsersError(error)));
};

export const setIamsUserAsync = (userId, isEnabled) => (dispatch, getState) => {
  const {usersStatus, userList} = getState().company.data.userPlatform;
  const {id} = getState().company.data.info;
  if (usersStatus.updating) return null;
  dispatch(updateUsers());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/companyUsers/${userId}/iamsUser?iamsEnabled=${isEnabled}`,
    method: 'PATCH'
  }).then(user => dispatch(updateUserSuccess(user, userList)))
    .catch(error => dispatch(updateUsersError(error)));
};

export const updateUserAsync = user => (dispatch, getState) => {
  const {updating} = getState().company.data.userPlatform.usersStatus;
  if (updating) return null;
  dispatch(updateUsers());

  const {information, userList} = getState().company.data.userPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/companyUsers/${user.id}`,
    method: 'PUT',
    body: user
  }).then(user => dispatch(updateUserSuccess(user, userList)))
    .catch(error => dispatch(updateUsersError(error)));
};

export const patchUserAsync = (userId, locationId, locationName) => (dispatch, getState) => {
  const {updating} = getState().company.data.userPlatform.usersStatus;
  if (updating) return null;
  dispatch(updateUsers());

  const {information, userList} = getState().company.data.userPlatform;
  const body = {locationId: locationId, location: locationName}

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/companyUsers/${userId}`,
    method: 'PATCH',
    body: body
  }).then(user => dispatch(updateUserSuccess(user, userList)))
    .catch(error => dispatch(updateUsersError(error)));
};

export const deleteCompanyUserAsync = id => (dispatch, getState) => {
  const {updating} = getState().company.data.userPlatform.usersStatus;
  if (updating) return null;
  dispatch(updateUsers());

  const {information} = getState().company.data.userPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/companyUsers/${id}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchUsersAsync(information.companyId)))
    .catch(error => dispatch(updateUsersError(error)));
};

export const uploadCsv = (file, replace) => (dispatch, getState) => {
  const {updating} = getState().company.data.userPlatform.usersStatus;
  if (updating) return null;
  dispatch(updateUsers());

  const {id} = getState().company.data.info;
  const formData = new FormData();
  formData.append(`csv/${id}`, file);

  return apiFetch(`/companies/${id}/companyUsers/csv?replace=${replace}`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchUsersAsync(id)))
    .catch(error => {
      console.log(error);
      dispatch(uploadUsersError(error));
    });
};

export const downloadCsv = (tabSelected) => (dispatch, getState) => {
  const {updating} = getState().company.data.userPlatform.usersStatus;
  if (updating) return null;
  dispatch(updateUsers());

  const {information} = getState().company.data.userPlatform;
  const {name} = getState().company.data.info;

  const usersVerb = tabSelected === UserTableType.USERS ? 'users_to_be_onboarded' : tabSelected === UserTableType.ONBOARDED ? 'onboarded_users' : 'deleted_users';
  const simplifiedName = name.replace(/\s/g, "_");

  return apiFetch(`/companies/${information.companyId}/companyUsers/csv?filter=${tabSelected}`, {
    method: 'GET'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to download CSV.');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${usersVerb}_${simplifiedName}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .then(res => res.flush())
    .catch(error => dispatch(updateUsersError(error)));
};

export const bulkDelete = users => (dispatch, getState) => {
  const {usersStatus, information, userList} = getState().company.data.userPlatform;
  if (usersStatus.updating) return null;
  dispatch(updateUsers());

  return apiFetch(`/companies/${information.companyId}/companyUsers/deleteBulk`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(users)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .then(users => {
      dispatch(deleteUsersSuccess(users, userList));
    })
    .catch(error => dispatch(updateUsersError(error)));
};

export const multiPut = (users, intilityUser) => (dispatch, getState) => {
  const {usersStatus, information, userList} = getState().company.data.userPlatform;
  if (usersStatus.updating) return null;
  dispatch(updateUsers());
  let body;

  if (intilityUser) {
    body = users;
  } else {
    body = users?.filter(u => (new Date(u?.rotMigrated).getFullYear() === 1 || u?.rotMigrated === null))
  }

  devLog('PUT', users);
  devLog('PUT filtered', body);

  return apiFetch(`/companies/${information.companyId}/companyUsers/${information.id}/bulk`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .then(users => {
      dispatch(updateUsersSuccess(users, userList));
    })
    .catch(error => dispatch(updateUsersError(error)));
};
