import {createSlice} from '@reduxjs/toolkit';
import {setView} from 'redux/viewSlice';
import apiFetch from 'utils/apiFetch';
import devLog from '../utils/devLog';

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    fetching: false,
    error: null,
    data: []
  },
  reducers: {
    clearCompanies: state => {
      state.companies = [];
    },
    fetchCompanies: state => {
      state.fetching = true;
      state.error = null;
    },
    fetchCompaniesSuccess: (state, action) => {
      state.fetching = false;
      state.data = action.payload;
    },
    fetchCompaniesError: (state, action) => {
      state.fetching = false;
      state.error = action.payload;
    }
  }
});

export const {clearCompanies, fetchCompanies, fetchCompaniesSuccess, fetchCompaniesError} = companiesSlice.actions;
export const companiesReducer = companiesSlice.reducer;

export const fetchCompaniesAsync = () => (dispatch, getState) => {
  const {fetching} = getState().company;
  if (fetching) return null;

  dispatch(fetchCompanies());
  devLog('Fetching companies.');

  return apiFetch('/companies')
    .then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then(companies => {
      dispatch(fetchCompaniesSuccess(companies));
      devLog('Done fetching');
      if (companies?.length > 0) {
        dispatch(setView('Hub'));
      } else {
        dispatch(setView('Wizard'));
      }

      return companies;
    })
    .catch(error => dispatch(fetchCompaniesError(error)));
};

export const fetchFinanceCompaniesAsync = () => (dispatch, getState) => {
  const {fetching} = getState().company;
  if (fetching) return null;

  dispatch(fetchCompanies());
  devLog('Fetching companies.');

  return apiFetch('/companies')
    .then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then(companies => {
      dispatch(fetchCompaniesSuccess(companies));
      return companies;
    })
    .catch(error => dispatch(fetchCompaniesError(error)));
};
