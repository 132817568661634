import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  FieldGroup,
  Icon,
  Input,
  Label,
  Modal,
  Pagination,
  Table,
  TextArea
} from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import './editComponent.scss';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationComment, departmentType, Detail, detailType} from '../../../utils/hub/models';
import produce from 'immer';
import {multiPutAsync} from '../../../redux/company/details/detailThunks';
import {faUndo} from '@fortawesome/pro-regular-svg-icons';
import {
  addApplicationCommentAsync,
  editApplicationCommentAsync,
  fetchApplicationsAsync,
  multiPutAppStatusAsync
} from '../../../redux/company/applications/applicationThunks';
import devLog from '../../../utils/devLog';
import {faSearch} from '@fortawesome/pro-solid-svg-icons';
import {AppFilterOptions} from '../ApplicationDetails/ApplicationDetails';
import EditApplicationsTableRow from './EditApplicationsTableRow';

export const countRowChecks = application => {
  let checked = 0;
  checked += application?.applicationStatus?.appInfoSent;
  checked += application?.applicationStatus?.solutionDesignProgress;
  checked += application?.applicationStatus?.serverStaged;
  checked += application?.applicationStatus?.installation;
  checked += application?.applicationStatus?.testing;
  return checked;
};

export const EditApplications = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const [totalServerCount, setTotalServerCount] = useState(new Detail());
  const [stagedServerCount, setStagedServerCount] = useState(new Detail());
  const [backupComplete, setBackupComplete] = useState(new Detail());
  const [wsusComplete, setWsusComplete] = useState(new Detail());
  const [useAppInfo, setUseAppInfo] = useState(new Detail());
  const [sharefileLink, setSharefileLink] = useState(new Detail());
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [detailsToEdit, setDetailsToEdit] = useState([]);
  const [typeFilter, setTypeFilter] = useState({value: '', label: 'All'});
  const [listCopy, setListCopy] = useState([]);
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [currentStatus, setCurrentStatus] = useState();
  const [editComment, setEditComment] = useState(new ApplicationComment());
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchApplicationsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    setListCopy(applicationList);
  }, [applicationList]);

  useEffect(() => {
    setNewComment(editComment?.comment);
  }, [editComment]);

  useEffect(() => {
    resetValues();
  }, [detailList]);

  const onDetailChange = (value, name, setItem, currentItem, type) => {
    const newValue = produce(currentItem, draft => {
      draft.name = name;
      draft[type] = value;
      draft.valueType = type;
      draft.department = departmentType.APPLICATIONS;
    });

    const i = detailsToEdit.findIndex(i => i.name === name);
    if (i > -1)
      setDetailsToEdit(
        produce(detailsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    else
      setDetailsToEdit(
        produce(detailsToEdit, draft => {
          draft.push(newValue);
        })
      );
    devLog(detailsToEdit);
    setItem(newValue);
  };

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutAppStatusAsync(itemsToEdit));
      setItemsToEdit([]);
    }
    if (detailsToEdit.length > 0) {
      dispatch(multiPutAsync(detailsToEdit));
      setDetailsToEdit([]);
    }
  };

  const saveComment = () => {
    if (editComment.id) {
      const comment = produce(editComment, draft => {
        draft.comment = newComment;
      });
      dispatch(editApplicationCommentAsync(comment));
    } else {
      const comment = produce(new ApplicationComment(), draft => {
        draft.comment = newComment;
        draft.domainStatusId = currentStatus?.id;
      });
      dispatch(addApplicationCommentAsync(comment, currentStatus?.id));
    }
    setNewComment('');
    setEditComment(new ApplicationComment());
    setCurrentStatus(null);
    setOpenModal(false);
  };

  const resetValues = () => {
    setTotalServerCount(getDetail('TotalServerCount'));
    setStagedServerCount(getDetail('StagedServerCount'));
    setBackupComplete(getDetail('BackupComplete'));
    setWsusComplete(getDetail('WsusComplete'));
    setUseAppInfo(getDetail('UseAppInfo'));
    setSharefileLink(getWorkplaceDetail('SharefileLink'));
    setItemsToEdit([]);
    setDetailsToEdit([]);
    setListCopy(applicationList);
  };

  const getDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.APPLICATIONS)?.find(i => i.name === name) || new Detail()
    );
  };

  const getWorkplaceDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.WORKPLACE)?.find(i => i.name === name) || new Detail()
    );
  };

  const sortedData = [...listCopy]
    .filter(a => a.isEnabled === true)
    .filter(a => {
      if (typeFilter.label === 'Server') return a.serverApp === 1;
      if (typeFilter.label === 'SaaS') return a.saasApp === 1;
      if (typeFilter.label === 'Client') return a.clientApp === 1;
      else return a;
    })
    .sort((a, b) => {
      if (sort.key === 'readyStatus' && sort.direction === 'asc') {
        return a.readyStatus - b.readyStatus;
      } else if (sort.key === 'readyStatus' && sort.direction === 'desc') {
        return b.readyStatus - a.readyStatus;
      } else if (sort.key === 'appInfoReady' && sort.direction === 'asc') {
        return a.applicationStatus?.appInfoReady - b.applicationStatus?.appInfoReady;
      } else if (sort.key === 'appInfoReady' && sort.direction === 'desc') {
        return b.applicationStatus?.appInfoReady - a.applicationStatus?.appInfoReady;
      } else if (sort.key === 'status' && sort.direction === 'asc') {
        return countRowChecks(a) - countRowChecks(b);
      } else if (sort.key === 'status' && sort.direction === 'desc') {
        return countRowChecks(b) - countRowChecks(a);
      } else if (sort.direction === 'asc') {
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    })
    .filter(s => {
      return s.name?.toLowerCase().includes(searchValue.toLowerCase());
    });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData &&
      sortedData.forEach((a, i) => {
        if (i >= counter) {
          tableOptions.push({value: counter, label: `${counter}`});
          counter = counter * 2;
        }
      });
    tableOptions.push({value: applicationList?.length, label: 'All'});
    return tableOptions;
  };

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Applications</h3>
        <div className='backoffice-button-group'>
          {(itemsToEdit.length > 0 || detailsToEdit.length > 0) && (
            <span onClick={() => resetValues()} className='icon-text bf-link'>
              <Icon icon={faUndo} />
              <span> Undo changes</span>
            </span>
          )}
          <Button
            variant={'filled'}
            disabled={itemsToEdit.length === 0 && detailsToEdit.length === 0}
            onClick={() => saveDetails()}
          >
            Save
          </Button>
        </div>
      </div>
      <div className='bfl-autocol'>
        <FieldGroup label='Search'>
          <Input
            className='table-search-input'
            placeholder='Search for application'
            label='Search'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
          <Select
            key='Filter'
            label='Filter type'
            hideLabel
            placeholder='Filter type'
            className='table-size-dropdown'
            value={typeFilter}
            options={AppFilterOptions}
            onChange={setTypeFilter}
          />
        </FieldGroup>
        <Input
          label='Total number of servers'
          value={totalServerCount?.intValue}
          onChange={e =>
            onDetailChange(e.target.value, 'TotalServerCount', setTotalServerCount, totalServerCount, detailType.INT)
          }
        />
        <FieldGroup label='Number of staged servers'>
          <Input
            label='Number of staged servers'
            hideLabel
            value={stagedServerCount?.intValue}
            onChange={e =>
              onDetailChange(
                e.target.value,
                'StagedServerCount',
                setStagedServerCount,
                stagedServerCount,
                detailType.INT
              )
            }
          />
          <FieldGroup.Item>of {getDetail('TotalServerCount').intValue}</FieldGroup.Item>
        </FieldGroup>
        <div className='labeled-checkbox'>
          <Label>Backup complete</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={backupComplete?.boolValue}
            onChange={e =>
              onDetailChange(e.target.checked, 'BackupComplete', setBackupComplete, backupComplete, detailType.BOOL)
            }
            type={'switch'}
            label='Backup complete'
          />
        </div>
        <div className='labeled-checkbox'>
          <Label>WSUS enabled</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={wsusComplete?.boolValue}
            onChange={e =>
              onDetailChange(e.target.checked, 'WsusComplete', setWsusComplete, wsusComplete, detailType.BOOL)
            }
            type={'switch'}
            label='WSUS enabled'
          />
        </div>
        <div className='labeled-checkbox'>
          <Label>Use AppInfo</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={useAppInfo?.boolValue}
            onChange={e => onDetailChange(e.target.checked, 'UseAppInfo', setUseAppInfo, useAppInfo, detailType.BOOL)}
            type={'switch'}
            label='Use AppInfo'
          />
        </div>
      </div>
      <div className='backoffice-editable-table'>
        <Table>
          <colgroup>
            <col className='from-large' style={{width: '3%'}} />
            <col className='from-large' style={{width: '27%'}} />
            <col className='from-large' style={{width: '25%'}} />
            <col className='from-large' style={{width: '10%'}} />
            <col className='from-large' style={{width: '15%'}} />
            <col className='from-large' style={{width: '20%'}} />
          </colgroup>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
                Application
              </Table.HeaderCell>
              <Table.HeaderCell className='from-large'>Latest comment</Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('appInfoReady')} onClick={onSortChangeCreator('appInfoReady')}>
                AppInfo ready
              </Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('readyStatus')} onClick={onSortChangeCreator('readyStatus')}>
                Onboard status
              </Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('status')} onClick={onSortChangeCreator('status')}>
                Backoffice status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedAndIndexedData.map((application, i) => (
              <EditApplicationsTableRow
                key={i}
                application={application}
                listCopy={listCopy}
                setListCopy={setListCopy}
                itemsToEdit={itemsToEdit}
                setItemsToEdit={setItemsToEdit}
                setOpenModal={setOpenModal}
                setCurrentStatus={setCurrentStatus}
                setEditComment={setEditComment}
                sharefileLink={sharefileLink}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        header={editComment?.id ? 'Edit comment' : 'Add comment'}
      >
        <TextArea label='Comment' value={newComment} onChange={e => setNewComment(e.target.value)} rows={6} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 'var(--bfs12)',
            minWidth: '400px'
          }}
        >
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button variant={'filled'} onClick={() => saveComment()}>
            {editComment?.id ? 'Save' : 'Add'}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditApplications;
