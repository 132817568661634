import WorkplaceDetails from '../../components/BackOffice/WorkplaceDetails/WorkplaceDetails';
import {faServer} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Workplace',
  path: 'workplace/',
  icon: faServer,
  editable: false,
  component: WorkplaceDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
