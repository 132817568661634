import {addListItem, deleteListItem, editListItem, toggleListItem} from '../../../utils/hub/normalize';
import produce from 'immer';

export const editApplication = (applicationList, application) => {
  const newList = editListItem(applicationList, application);

  return {
    applicationPlatform: {
      applicationList: newList
    }
  };
};

export const toggleApplication = (applicationList, applicationId) => {
  const newList = toggleListItem(applicationList, applicationId);

  return {
    applicationPlatform: {
      applicationList: newList
    }
  };
};

export const editApplicationUserRelation = (appUserRelation, applicationList, isDisabled) => {
  const index = applicationList.findIndex(obj => obj.id === appUserRelation[0].applicationId);
  const app = applicationList[index];

  const users = produce(app.companyUsers, draft => {
    appUserRelation.forEach(r => {
      const user = draft.findIndex(u => u?.companyUserId === r.companyUserId);
      if (user === -1) {
        draft.push(r);
      } else {
        draft[user].isDisabled = isDisabled
      }
    })
  })

  return {
    applicationPlatform: {
      applicationList: produce(applicationList, draft => {
        draft[index] = {
          ...app,
          companyUsers: users
        }})
    }
  };
};

export const deleteApplication = (itemId, filterList) => {
  const newList = deleteListItem(filterList, itemId);
  return {
    applicationPlatform: {
      applicationList: newList
    }
  };
};

export const addApplication = (item, filterList) => {
  const newList = addListItem(filterList, item);
  return {
    applicationPlatform: {
      applicationList: newList
    }
  };
};

export const normalizeApplications = applicationPlatform => {
  return {
    applicationPlatform: {
      applicationList: applicationPlatform.applicationList,
      information: applicationPlatform,
      state: applicationPlatform.state
    }
  };
};

export const normalizeApplicationsInformation = applicationPlatform => {
  return {
    applicationPlatform: {
      information: applicationPlatform,
      state: applicationPlatform.state
    }
  };
};

export const normalizeApplicationFilters = filters => {
  return {
    applicationPlatform: {
      filters: filters
    }
  };
};

export const addNewApplicationFilter = (filter, filterList) => {
  const newList = addListItem(filterList, filter);
  return {
    applicationPlatform: {
      filters: newList
    }
  };
};

export const deleteApplicationFilter = (filterId, filterList) => {
  const newList = deleteListItem(filterList, filterId);

  return {
    applicationPlatform: {
      filters: newList
    }
  };
};

export const normalizeApplicationsList = applicationPlatform => {
  return {
    applicationPlatform: {
      applicationList: applicationPlatform.applicationList
    }
  };
};

export const addVendorContact = (appId, newContact, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  const editedApp = produce(currentApp, draft => {
    draft.vendorContacts = addListItem(currentApp.vendorContacts, newContact);
  });
  return editApplication(appList, editedApp);
};

export const addInternalContact = (appId, newContact, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  const editedApp = produce(currentApp, draft => {
    draft.internalContacts = addListItem(currentApp.internalContacts, newContact);
  });
  return editApplication(appList, editedApp);
};

export const updateVendorContact = (appId, newContact, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  const editedApp = produce(currentApp, draft => {
    draft.vendorContacts = editListItem(currentApp.vendorContacts, newContact);
  });
  return editApplication(appList, editedApp);
};

export const updateInternalContact = (appId, newContact, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  const editedApp = produce(currentApp, draft => {
    draft.internalContacts = editListItem(currentApp.internalContacts, newContact);
  });
  return editApplication(appList, editedApp);
};

export const addAppRelation = (appId, newRelation, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  const editedApp = produce(currentApp, draft => {
    draft.childApplications = addListItem(currentApp.childApplications, newRelation);
  });
  return editApplication(appList, editedApp);
};

export const deleteVendorContact = (appId, contactId, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  const editedApp = produce(currentApp, draft => {
    draft.vendorContacts = deleteListItem(currentApp.vendorContacts, contactId);
  });
  return editApplication(appList, editedApp);
};

export const deleteInternalContact = (appId, contactId, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  const editedApp = produce(currentApp, draft => {
    draft.internalContacts = deleteListItem(currentApp.internalContacts, contactId);
  });
  return editApplication(appList, editedApp);
};

export const deleteAppRelation = (appId, relationId, list, appList) => {
  const currentApp = appList.find(a => a.id === appId);
  let editedApp;
  if (list === 'parent') {
    editedApp = produce(currentApp, draft => {
      draft.parentApplications = deleteListItem(currentApp.parentApplications, relationId);
    });
  } else {
    editedApp = produce(currentApp, draft => {
      draft.childApplications = deleteListItem(currentApp.childApplications, relationId);
    });
  }
  return editApplication(appList, editedApp);
};
