import React, {useEffect, useState} from 'react';
import './NetworkDetails.scss';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLocationsAsync} from '../../../redux/company/locations/locationThunks';
import { Button, FieldGroup, Input, Pagination, ProgressBar } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import NetworkProgressBox from '../Components/NetworkProgressBox';

export const checkNetwork = location => {
  const surveyDate = new Date(location?.locationNetwork?.siteSurvey);
  const lineDate = new Date(location?.locationNetwork?.lineDate);
  const currentDate = new Date(Date.now());
  let number = 0;

  if (location?.locationNetwork?.locationNetworkCreated) number++;
  if (surveyDate.getFullYear() !== 1 && surveyDate <= currentDate) number++;
  if (location?.locationNetwork?.equipmentOfferSent) number++;
  if (lineDate.getFullYear() !== 1 && lineDate <= currentDate) number++;
  return number;
};

export const NetworkDetails = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const [sort, setSort] = useState(0);
  const [search, setSearch] = useState('');
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const dispatch = useDispatch();
  let totalNumber = 0;
  let currentNumber = 0;

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);

  const sortedData = locationList
    .filter(s => {
      if (sort === 0) {
        return s;
      } else if (sort === 1) {
        return checkNetwork(s) < 4;
      } else if (sort === 2) {
        return checkNetwork(s) === 4;
      }
      return s;
    })
    .filter(s => s.name?.toLowerCase().includes(search.toLowerCase()));

  locationList &&
    locationList?.forEach(l => {
      totalNumber += 4;
      currentNumber += checkNetwork(l);
    });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: locationList?.length, label: 'All'});
    return tableOptions;
  };

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div id='backoffice-network-details'>
      <p className='bf-strong'>Total progress:</p>
      <p style={{color: 'var(--bfc-base-c-2)'}}>
        Showing the total progress of all locations
      </p>
      <div className='progress-bar'>
        <ProgressBar size={'large'} value={Math.round((currentNumber * 100) / totalNumber)} />
      </div>
      <div className='button-bar'>
        <FieldGroup className='search-section'>
          <Input
            label='Search'
            placeholder='Search for location'
            hideLabel={true}
            icon={faSearch}
            onChange={e => {
              setSearch(e.target.value);
              setPage(1);
            }}
          />
          <Select
            key='Items'
            label='Table size'
            className='table-size-dropdown'
            hideLabel
            value={pageSize}
            options={tableSizeOptions()}
            onChange={item => {
              setPage(1);
              setPageSize(item);
            }}
          />
        </FieldGroup>
        <div>
          <Button.Group>
            <Button active={sort === 0} onClick={() => setSort(0)}>
              Show all
            </Button>
            <Button active={sort === 1} onClick={() => setSort(1)}>
              In progress
            </Button>
            <Button active={sort === 2} onClick={() => setSort(2)}>
              Ready
            </Button>
          </Button.Group>
        </div>
      </div>
      <div className='network-details-content'>
        {sortedAndIndexedData.map(location => {
          return <NetworkProgressBox key={location.id} location={location} />;
        })}
      </div>
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default NetworkDetails;
