import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  addLocation,
  addLocationError,
  fetchLocations,
  fetchLocationsError,
  updateLocations,
  updateLocationsError
} from './locationReducers';
import {addLocationSuccess, fetchLocationsSuccess, updateLocationsSuccess} from './locationActions';
import produce from 'immer';
import {updateDocumentation} from '../documentation/documentationReducers';

export const updateLocationAsync = (location, contact) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const {information, locationList} = getState().company.data.locationPlatform;

  const locationWithContact = produce(location, draft => {
    draft.locationContact = contact;
    draft.billingId = draft.billingId ? draft.billingId : null;
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/locations/${location.id}`,
    method: 'PUT',
    body: locationWithContact
  }).then(location => dispatch(updateLocationsSuccess(location, locationList)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const updateLocationNewAsync = locationData => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const {information, locationList} = getState().company.data.locationPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/locations/${locationData.id}`,
    method: 'PUT',
    body: locationData
  }).then(location => dispatch(updateLocationsSuccess(location, locationList)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const multiPutNetworkAsync = locationList => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;
  dispatch(updateLocations());

  const body = locationList.map(location => location.locationNetwork);

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/locationsNetwork/bulk`,
    method: 'PUT',
    body: body
  })
    .then(() => dispatch(fetchLocationsAsync(id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const multiPutLocationsAsync = (locationList, platformId) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;
  dispatch(updateLocations());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/locations/${platformId}/bulk`,
    method: 'PUT',
    body: locationList
  })
    .then(() => dispatch(fetchLocationsAsync(id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const addLocationAsync = locationData => (dispatch, getState) => {
  const {locationStatus, information, locationList} = getState().company.data.locationPlatform;
  const {id} = getState().company.data.info;
  if (locationStatus.adding) return null;
  dispatch(addLocation());

  const newLocationsData = produce(locationData, draft => {
    draft.locationPlatformId = information.id;
    draft.userCount = parseInt(draft.userCount, 10) || 0;
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/locations`,
    method: 'POST',
    body: newLocationsData
  }).then((location) => dispatch(addLocationSuccess(location, locationList)))
    .catch(error => dispatch(addLocationError(error)));
};

export const addLocationSystemsAsync = (locationId, locationItems) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${locationId}/systemItems`,
    method: 'PUT',
    body: locationItems
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const deleteLocationSystemCustomItemAsync = (locationId, itemId) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${locationId}/systemItems/${itemId}`,
    method: 'DELETE',
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const addLocationMeetingRoomsAsync = (locationId, meetingRooms) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${locationId}/meetingRooms`,
    method: 'PUT',
    body: meetingRooms
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const deleteLocationMeetingRoomAsync = (locationId, roomId) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${locationId}/meetingRooms/${roomId}`,
    method: 'DELETE',
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const addLocationSystemContactAsync = (locationId, locationContact) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${locationId}/systemContact`,
    method: 'PUT',
    body: locationContact
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const deleteLocationSystemContactAsync = (locationId, contactId) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${locationId}/systemContact/${contactId}`,
    method: 'DELETE',
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const fetchLocationsAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.locationPlatform.locationStatus;
  if (fetching) return null;
  dispatch(fetchLocations());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/locations`,
    method: 'GET'
  }).then(locations => dispatch(fetchLocationsSuccess(locations)))
    .catch(error => dispatch(fetchLocationsError(error)));
};

export const deleteLocationAsync = id => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;
  const location = company.locationPlatform.locationList.find(l => l.id === id);

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${location?.id}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const uploadFloorPlansAsync = (id, files) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;
  const floorPlans = new FormData();

  files.forEach(floorPlanFile => {
    floorPlans.append(id + '/' + floorPlanFile.name, floorPlanFile);
  });

  return apiFetch(`/companies/${company.info.id}/locations/${id}/floorPlans`, {
    method: 'POST',
    body: floorPlans
  })
    .then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const uploadFloorPlanAsync = (id, file) => uploadFloorPlansAsync(id, [file]);

export const uploadLocationDocumentationAsync = (locationId, files, documentationType) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;
  const documentation = new FormData();

  files.forEach(floorPlanFile => {
    documentation.append(locationId + '/' + floorPlanFile.name, floorPlanFile);
  });

  return apiFetch(`/companies/${company.info.id}/locations/${locationId}/file/${documentationType}`, {
    method: 'POST',
    body: documentation
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => {
      console.log(error);
      dispatch(updateLocationsError(error));
    });
};

export const deleteLocationDocumentationAsync = (locationId, documentationId) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/locations/${locationId}/file/${documentationId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const deleteFloorPlanAsync = floorPlan => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateLocations());

  const company = getState().company.data;

  return apiFetch(`/companies/${company.info.id}/locations/${floorPlan.locationId}/floorPlans/${floorPlan.id}`, {
    method: 'DELETE'
  })
    .then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => dispatch(updateLocationsError(error)));
};

export const uploadLocationImageAsync = (locationId, file) => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  dispatch(updateDocumentation());

  const company = getState().company.data;
  const image = new FormData();

  file.forEach(imageFile => {
    image.append(locationId + '/' + imageFile.name, imageFile);
  });

  return apiFetch(`/companies/${company.info.id}/locations/${locationId}/image`, {
    method: 'POST',
    body: image
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchLocationsAsync(company.info.id)))
    .catch(error => {
      console.log(error);
    });
};


export const downloadLocationCsv = () => (dispatch, getState) => {
  const {updating} = getState().company.data.locationPlatform.locationStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;

  return apiFetch(`/companies/${id}/locations/csv`, {
    method: 'GET'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to download CSV.');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `locations_${id}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .then(res => res.flush())
    .catch(error => console.log(error));
};