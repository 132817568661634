import {Billing} from '../../../components/Hub/Pages';
import pluralize from '../../../utils/pluralize';
import {faBuilding} from '@fortawesome/pro-solid-svg-icons';

export default {
  title: 'Billing',
  path: 'billing',
  icon: faBuilding,
  component: Billing,
  isEnabled: (company) => { return company?.billingPlatform?.state},
  getStatus: company => {
    if (company.billingPlatform.information.billingCount > 0) {
      return '';
    }
    return 'error';
  },
  getStatusText: (status, company) => {
    if (status === 'error') {
      return 'No billing companies registered';
    }

    return `${company.billingPlatform.information.billingCount} ${pluralize(
      'company',
      company.billingPlatform.information.billingCount
    )} registered`;
  }
};
