import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Icon, Tooltip} from '@intility/bifrost-react';
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons';

export const CheckUserDeleted = ({ userName, email, phoneNumber }) => {
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const isEnabled = userList?.filter(u => u.isEnabled === false)?.find(u => (((u.firstName + u.lastName) === userName) || (u.primaryEmailAddress === email && u.mobilePhone === phoneNumber)))
  if (isEnabled === undefined) return '';
  return <Tooltip content='User deleted, if incorrect restore in Users page.'><Icon icon={faExclamationTriangle} style={{color: 'var(--bfc-warning)'}}/></Tooltip>
}

CheckUserDeleted.propTypes = {
  userName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
}

export const CheckUserDeletedById = ({ id }) => {
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const isEnabled = userList?.filter(u => u.isEnabled === false)?.find(u => u.id === id)
  if (isEnabled === undefined) return '';
  return <Tooltip content='User deleted, if incorrect restore in Users page.'><Icon icon={faExclamationTriangle} style={{color: 'var(--bfc-warning)'}}/></Tooltip>
}

CheckUserDeletedById.propTypes = {
  id: PropTypes.string
}

export default CheckUserDeleted