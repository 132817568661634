import PropTypes from "prop-types";
import './sidebarCards.scss';
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon } from "@intility/bifrost-react";
import NotRegisteredField from "../../../../../../NotRegisteredField/NotRegisteredField";
import { getLocationEnumValue } from "../accordion/ComlinesAccordionContent";
import React from "react";

const NetworkCard = ({ location, editLocation, isComplete }) => {
  return (
    <div className='wifi-card'>
      {(location?.wifiCoverage !== null || location?.wifiCoverage?.length !== 0 || location?.isThirdPartyShared !== 0 || location?.hasEnoughCabledConnections !== 0) ?
      <>
        <div className='wifi-header'>
          <p className='bf-large bf-strong'>Network coverage</p>
          <Button pill noPadding small state={isComplete ? 'inactive' : 'default'} onClick={editLocation}><Icon icon={faPencil} /></Button>
        </div>
        <div>
          <p className='bf-strong'>Estimated user count at location</p>
          <NotRegisteredField field={location?.userCount} />
        </div>
        <div>
          <p className='bf-strong'>Network is shared with third party</p>
          <NotRegisteredField field={getLocationEnumValue(location?.isThirdPartyShared)} />
        </div>
        <div>
          <p className='bf-strong'>Location have enough cabled connections</p>
          <NotRegisteredField field={getLocationEnumValue(location?.hasEnoughCabledConnections)} />
        </div>
        <div>
          <p className='bf-strong'>Coverage</p>
          <NotRegisteredField field={location?.wifiCoverage}/>
        </div>
        <div>
          <p className='bf-strong'>Main purpose and requirement of WiFi</p>
          <NotRegisteredField field={location?.mainPurpose}/>
        </div>
      </>
        :
        <>
          <p className='bf-large bf-strong' style={{marginBottom: 'var(--bfs4)'}}>Network coverage</p>
          <p style={{marginTop: '0'}}>Please add network information to this location.</p>
          <div style={{display: 'flex'}}>
            <Button disabled={isComplete} variant={"filled"} style={{flex: '1'}} onClick={editLocation}>Add information</Button>
          </div>
        </>
      }
    </div>
  )
}

NetworkCard.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
  isComplete: PropTypes.bool,
}

export default NetworkCard