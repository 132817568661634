import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from "@intility/bifrost-react";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

const BoxItem = ({label, value, customDefault, black = true, required = false, ...props}) => {
  return (
    <div {...props} className='bf-content'>
      {label && (
        <p className='bf-strong' style={{color: 'var(--bfc-base-c-1)'}}>
          {label} {(required === true && (value === undefined || value === '' || value === null)) && <Tooltip content='Field required'><Icon style={{color: 'var(--bfc-warning)'}} icon={faExclamationTriangle}/></Tooltip>}
        </p>
      )}
      {(value !== undefined && value !== '' && value !== null) ?
        <p className='bf-large'
          style={value && black ? {color: 'var(--bfc-base-c-1)'} : {color: 'var(--bfc-base-c-2)'}}
        >
          {value}
        </p> :
        (customDefault !== undefined) ?
          customDefault :
          <p className='bf-em'
            style={value && black ? {color: 'var(--bfc-base-c-1)'} : {color: 'var(--bfc-base-c-2)'}}
          >
            Not registered
          </p>
      }
    </div>
  );
};

BoxItem.propTypes = {
  label: PropTypes.string,
  black: PropTypes.bool,
  customDefault: PropTypes.node,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool, PropTypes.node])
};

export default BoxItem;
