import {Badge, Card} from '@intility/bifrost-react';
import PropTypes from 'prop-types';
import React from 'react';
import {recommendedValues} from '../mobileCompliancePolicyData';

function translatePolicyValue(value) {
  const map = {
    false: 'No',
    true: 'Yes',
    NUMERIC: 'Numeric Complex',
    ALPHANUMERIC: 'Alphanumeric',
    MANAGEDAPPS: ' Policy Managed Apps',
    ANYAPP: 'Any App',
    LATESTONE: 'Latest-1',
    LATESTTWO: 'Latest-2',
    LATEST: 'Latest',
    NO: 'No',
    BOTH: 'Both',
    IMMEDIATELY: 'Immediately',
    CalendarAndContactsOnly: 'Calendar And Contacts Only',
    AllData: 'All data',
    CalenderOnly: 'Calender only',
    EmailOnly: 'Email only',
    ContactsOnly: 'Contacts only',
    OneDriveForBusiness: 'One Drive for Business'
  };

  return map[value] || String(value);
}

export default function ViewPolicies({compliancePolicy}) {
  return (
    <div className='accordion-cards-container'>
      {compliancePolicy.policies.map((policy, i) => {
        const isRecommended = recommendedValues[compliancePolicy.policy][policy.name] === policy.value;

        return (
          <Card key={policy.title + i} className='bfl-autocol'>
            <Card.Title key={policy.title}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <p className='bf-strong' style={{margin: 0, color: 'var(--bfc-base-c-1)'}}>
                    {policy.title}
                  </p>
                  <p style={{margin: 0, color: 'var(--bfc-base-c-2)'}}>
                    {policy.description}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '4px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  <span>{translatePolicyValue(policy.value)}</span>

                  {isRecommended && (
                    <div>
                      <Badge state='success'>Recommended</Badge>
                    </div>
                  )}
                </div>
              </div>
            </Card.Title>
          </Card>
        );
      })}
    </div>
  );
}

ViewPolicies.propTypes = {
  compliancePolicy: PropTypes.object,
  formState: PropTypes.object
};
