import React from 'react';
import './microsoft365box.scss';
import {Icon} from '@intility/bifrost-react';
import PropTypes from 'prop-types';

const Microsoft365Box = ({step, icon, link, backgroundC, iconColor, children, ...props}) => {
  return (
    <div className='microsoft365-box-item'>
      <div className='microsoft365-box-item-logo' style={{backgroundColor: backgroundC}}>
        <Icon icon={icon} style={{color: iconColor}} />
      </div>
      <div className='microsoft365-box-item-body'>{children}</div>
      <div className='microsoft365-box-item-footer'>{link}</div>
    </div>
  );
};

Microsoft365Box.propTypes = {
  step: PropTypes.number,
  icon: PropTypes.object,
  link: PropTypes.object,
  backgroundC: PropTypes.string,
  iconColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string])
};

export default Microsoft365Box;
