import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Checkbox, Icon, Label, Modal } from "@intility/bifrost-react";
import { faAngleRight, faPen, faPlus, faStar, faUser } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faSolidStar } from "@fortawesome/pro-solid-svg-icons";
import './team.scss';
import { GlobalTeamObject, TeamMember, TeamObject, TeamRole } from "../../../utils/hub/models";
import produce from 'immer';
import MemberDropdown from '../Components/MemberDropdown';
import {emptyGuid} from '../../../utils/guid';
import { useDispatch, useSelector } from "react-redux";
import devLog from '../../../utils/devLog';
import Flex from "../../Flex/Flex";
import { updateProjectGlobalTeamLeadAsync } from "../../../redux/company/projectTeams/projectGlobalTeamsThunks";
import { updateProjectTeamLeadAsync } from "../../../redux/company/projectTeams/projectTeamsThunks";

export const TeamRow = ({role, listPosition, edit, editRole, teamManagerId, patchTeamLead}) => {
  const isManager = role.id === teamManagerId;

  return (
    <div className='team-row'>
      {(isManager || edit) &&
        <span style={{marginRight: 'var(--bfs8)'}}>
          <Icon style={edit ? {cursor: 'pointer'} : undefined} icon={isManager ? faSolidStar : faStar} onClick={() => patchTeamLead(role.id)}/>
        </span>
      }
      {role?.name?.length > 0 && [
        <p key={1}>
          {role?.name}
        </p>,
        <div key={2} className='team-row-separator' />
      ]}
      {role?.member && (
        <div className='member-photo-wrapper'>
          {role?.member?.profilePhoto ? (
            <img
              src={`data:image/png;base64,${role?.member?.profilePhoto}`}
              alt={role?.member?.surname}
            />
          ) : (
            <Icon icon={faUser} />
          )}
        </div>
      )}
      {role?.member ? (
        <a
          className='team-row-name bf-link'
          href={`MSTeams:/l/chat/0/0?users=${role?.member?.userPrincipalName}`}
        >
          <div>
            <p>
              {role?.member?.givenName + ' ' + role?.member?.surname}
            </p>
            <Icon style={{color: 'var(--bfc-base-c-theme)'}} icon={faAngleRight} />
          </div>
        </a>
      ) : (
        <div className='team-row-badge'>
          <Badge state='warning'>Not selected</Badge>
          {edit && (
            <Icon
              style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}
              icon={faPlus}
              onClick={() => editRole(role, listPosition, true)}
            />
          )}
        </div>
      )}
    </div>
  );
};

TeamRow.propTypes = {
  role: PropTypes.object,
  edit: PropTypes.bool,
  editRole: PropTypes.func,
  listPosition: PropTypes.number,
  teamManagerId: PropTypes.string,
  patchTeamLead: PropTypes.func
};

export const GlobalTeamRow = ({role, teamManagerId, patchTeamLead}) => {
  const isManager = role.id === teamManagerId;

  return (
    <div className='team-row'>
      {role?.name?.length > 0 &&
        <span style={{marginRight: 'var(--bfs8)'}}>
          <Icon style={{cursor: 'pointer'}} icon={isManager ? faSolidStar : faStar} onClick={() => patchTeamLead(role.id)}/>
        </span>
      }
      {role?.name?.length > 0 ?
        <p key={1}>
          {role?.name}
        </p> :
        <div className='team-row-badge'>
          <Badge state='warning'>Nameless slot</Badge>
        </div>
      }
    </div>
  );
};

GlobalTeamRow.propTypes = {
  role: PropTypes.object,
  patchFavorite: PropTypes.func
};

export const Team = ({team, listPosition, edit, openDrawer, teamsToEdit, setTeamsToEdit, reset}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(new TeamMember());
  const [editRole, setEditRole] = useState(new TeamRole());
  const [rolePosition, setRolePosition] = useState(undefined);
  const [editTeam, setEditTeam] = useState(new TeamObject());
  const dispatch = useDispatch();

  const patchTeamLead = (roleId) => {
    dispatch(updateProjectTeamLeadAsync(id, team, roleId))
  }

  useEffect(() => {
    if (reset) {
      setSelectedMember(new TeamMember());
      setEditRole(new TeamRole());
      setEditTeam(team);
    }
  }, [reset]);

  useEffect(() => {
    setEditTeam(team);
  }, [team]);

  const editRow = (role, listPosition, listed) => {
    setShowModal(true);
    setRolePosition(listed ? listPosition : undefined);
    setEditRole(role);
  };

  const saveMember = () => {

    const newValue = produce(editRole, draft => {
      draft.teamId = team.id;
      draft.member = selectedMember;
      draft.memberId = selectedMember.id;
      draft.thing = team.id + selectedMember.id;
    });

    if (rolePosition >= 0) {
      setEditTeam(
        produce(editTeam, draft => {
          draft.teamRoles[rolePosition] = {...newValue};
          devLog('Replace editTeam: ', rolePosition, newValue);
        })
      );
    } else {
      setEditTeam(
        produce(editTeam, draft => {
          draft.teamRoles.push(newValue);
          devLog('Push editTeam: ', newValue);
        })
      );
    }

    const i =
      newValue.id === emptyGuid
        ? teamsToEdit.findIndex(obj => obj?.thing === newValue.thing)
        : teamsToEdit.findIndex(obj => obj?.id === editRole.id);

    if (i > -1) {
      setTeamsToEdit(
        produce(teamsToEdit, draft => {
          draft[i] = {...newValue};
          devLog('Replace teamsToEdit: ', i, newValue);
        })
      );
    } else {
      setTeamsToEdit(
        produce(teamsToEdit, draft => {
          draft.push(newValue);
          devLog('Push teamsToEdit: ', newValue);
        })
      );
    }

    onModalClose();
    setSelectedMember(new TeamMember());
  };

  const onModalClose = () => {
    setShowModal(false);
    setRolePosition(undefined);
    setEditRole(new TeamRole());
  };

  const setRole = (complexity) => {
    const newValue = produce(editRole, draft => {
      draft.complexity = complexity;
    });

    setEditRole(newValue);
  }

  return (
    <div className='backoffice-project-team-container'>
      {edit ? (
        <div className='edit-header'>
          <h3 style={{color: 'var(--bfc-base-c-1)'}}>
            {editTeam?.name}
          </h3>
          <Icon
            style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}
            onClick={() => openDrawer(editTeam, editTeam.id)}
            icon={faPen}
          />
        </div>
      ) : (
        <h3>{editTeam?.name}</h3>
      )}
      <div className='team-main-content'>
        {editTeam?.teamRoles?.toSorted((a, b) => {
          if (a?.id === editTeam?.teamManager) {
            return -1;
          } else if (b?.id === editTeam?.teamManager) {
            return 1;
          }
          return 0;
        }).map((l, i) => {
          return <TeamRow key={i} listPosition={i} role={l} edit={edit} editRole={editRow} teamManagerId={team.teamManager} patchTeamLead={patchTeamLead}/>;
        })}
      </div>
      {edit && (
        <div className='edit-footer' onClick={() => setShowModal(true)}>
          <div>
            <Icon icon={faPlus} />
            <p>Add member</p>
          </div>
        </div>
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={() => onModalClose()}
        header='Add new member'
      >
        <div className='team-edit-modal'>
          <MemberDropdown setSelectedMember={setSelectedMember} selectedMember={selectedMember} />
          <div className='complexity-radio'>
            <Label>Complexity</Label>
            <p className='bf-small bf-em' style={{margin: 0}}>Optional</p>
            <Flex flex={[1,1,1]}>
              <div>
                <p className='bf-small'>Easy</p>
                <Checkbox
                  label='Easy'
                  hideLabel
                  type='radio'
                  name='easy'
                  checked={editRole?.complexity === 1}
                  onChange={e => setRole(e.target.checked ? 1 : 0)}
                />
              </div>
              <div>
                <p className='bf-small'>Average</p>
                <Checkbox
                  label='Average'
                  hideLabel
                  type='radio'
                  name='average'
                  checked={editRole?.complexity === 2}
                  onChange={e => setRole(e.target.checked ? 2 : 0)}
                />
              </div>
              <div>
                <p className='bf-small'>Hard</p>
                <Checkbox
                  label='Hard'
                  hideLabel
                  type='radio'
                  name='hard'
                  checked={editRole?.complexity === 3}
                  onChange={e => setRole(e.target.checked ? 3 : 0)}
                />
              </div>
            </Flex>
          </div>
          <div className='team-edit-modal-buttons'>
            <Button variant='outline' onClick={() => onModalClose()}>
              Cancel
            </Button>
            <Button variant='filled' onClick={() => saveMember()}>
              Add new member
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Team.propTypes = {
  team: PropTypes.object,
  edit: PropTypes.bool,
  reset: PropTypes.bool,
  openDrawer: PropTypes.func,
  teamsToEdit: PropTypes.array,
  setTeamsToEdit: PropTypes.func,
  listPosition: PropTypes.number
};

export default Team;

export const GlobalTeam = ({team, openDrawer}) => {
  const [editTeam, setEditTeam] = useState(new GlobalTeamObject());
  const dispatch = useDispatch();

  const patchTeamLead = (roleId) => {
    dispatch(updateProjectGlobalTeamLeadAsync(team, roleId))
  }

  useEffect(() => {
    setEditTeam(team);
  }, [team]);

  return (
    <div className='backoffice-project-team-container'>
      <div className='edit-header'>
        <h3 style={{color: 'var(--bfc-base-c-1)'}}>
          {editTeam?.name}
        </h3>
        <Icon
          style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}
          onClick={() => openDrawer(editTeam, editTeam.id)}
          icon={faPen}
        />
      </div>
      <div className='team-main-content'>
        {editTeam?.teamRoles?.toSorted((a, b) => {
          if (a?.id === editTeam?.teamManager) {
            return -1;
          } else if (b?.id === editTeam?.teamManager) {
            return 1;
          }
          return 0;
        })?.map((l, i) => {
          return <GlobalTeamRow key={i} role={l} teamManagerId={team.teamManager} patchTeamLead={patchTeamLead}/>;
        })}
      </div>
    </div>
  )
}

GlobalTeam.propTypes = {
  team: PropTypes.object,
  reset: PropTypes.bool,
  openDrawer: PropTypes.func,
};