import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  fetchGlobalTasks,
  fetchGlobalTasksError,
  fetchOnboardTasks,
  fetchOnboardTasksError,
  updateGlobalTasks,
  updateGlobalTasksError,
  updateOnboardTasks,
  updateOnboardTasksError
} from './onboardTaskReducers';
import {fetchGlobalTasksSuccess, fetchOnboardTasksSuccess} from './onboardTaskActions';
import produce from 'immer';
import {emptyGuid} from '../../../utils/guid';

export const fetchOnboardTasksAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.taskPlatform.taskStatus;
  if (fetching) return null;
  dispatch(fetchOnboardTasks());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/tasks`,
    method: 'GET'
  }).then(onboardTasks => dispatch(fetchOnboardTasksSuccess(onboardTasks)))
    .catch(error => dispatch(fetchOnboardTasksError(error)));
};
export const fetchGlobalTasksAsync = () => (dispatch, getState) => {
  const {fetchingGlobal} = getState().company.data.taskPlatform.taskStatus;
  if (fetchingGlobal) return null;
  dispatch(fetchGlobalTasks());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/tasks/global`,
    method: 'GET'
  }).then(onboardTasks => dispatch(fetchGlobalTasksSuccess(onboardTasks)))
    .catch(error => dispatch(fetchGlobalTasksError(error)));
};

export const addOnboardTaskAsync = onboardTask => (dispatch, getState) => {
  const {taskStatus, information} = getState().company.data.taskPlatform;
  if (taskStatus.updating) return null;
  dispatch(updateOnboardTasks());

  const {id} = getState().company.data.info;

  const body = produce(onboardTask, draft => {
    draft.taskPlatformId = information.id
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/tasks`,
    method: 'POST',
    body: body
  })
    .then(() => dispatch(fetchOnboardTasksAsync(id)))
    .catch(error => dispatch(updateOnboardTasksError(error)));
};

export const addGlobalTaskAsync = globalTask => (dispatch, getState) => {
  const { updatingGlobal } = getState().company.data.taskPlatform.taskStatus;
  if (updatingGlobal) return null;
  dispatch(updateGlobalTasks());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/tasks/global`,
    method: 'POST',
    body: globalTask
  })
    .then(() => dispatch(fetchGlobalTasksAsync(emptyGuid)))
    .catch(error => dispatch(updateGlobalTasksError(error)));
};

export const updateOnboardTaskAsync = task => (dispatch, getState) => {
  const {updating} = getState().company.data.taskPlatform.taskStatus;
  if (updating) return null;
  dispatch(updateOnboardTasks());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/tasks/${task.id}`,
    method: 'PUT',
    body: task
  }).then(() => dispatch(fetchOnboardTasksAsync(id)))
    .catch(error => dispatch(updateOnboardTasksError(error)));
};

export const updateGlobalTaskAsync = state => (dispatch, getState) => {
  const {updatingGlobal} = getState().company.data.taskPlatform.taskStatus;
  if (updatingGlobal) return null;
  dispatch(updateGlobalTasks());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/tasks/global/${state.id}`,
    method: 'PUT',
    body: state
  }).then(() => dispatch(fetchGlobalTasksAsync(emptyGuid)))
    .catch(error => dispatch(updateGlobalTasksError(error)));
};

export const deleteOnboardTaskAsync = onboardTaskId => (dispatch, getState) => {
  const {updating} = getState().company.data.taskPlatform.taskStatus;
  if (updating) return null;
  dispatch(updateOnboardTasks());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/tasks/${onboardTaskId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchOnboardTasksAsync(id)))
    .catch(error => dispatch(updateOnboardTasksError(error)));
};

export const deleteGlobalTaskAsync = globalTaskId => (dispatch, getState) => {
  const {updatingGlobal} = getState().company.data.taskPlatform.taskStatus;
  if (updatingGlobal) return null;
  dispatch(updateGlobalTasks());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/tasks/global/${globalTaskId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchGlobalTasksAsync(emptyGuid)))
    .catch(error => dispatch(updateGlobalTasksError(error)));
};
