import billingData from './billingData';
import domainsData from './domainsData';
import locationsData from './locationsData';
import applicationsData from './applicationsData';
import hardwareData from './hardwareData';
import contactsData from './contactData';
import usersData from './usersData';
import documentationData from './documentationData';
import printerData from './printerData';

export default [
  contactsData,
  locationsData,
  billingData,
  usersData,
  applicationsData,
  hardwareData,
  domainsData,
  printerData,
  documentationData
];
