import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Dropdown, Icon, Input, Label, Table} from '@intility/bifrost-react';
import DatePicker from "@intility/bifrost-react-datepicker";
import './editComponent.scss';
import {useDispatch, useSelector} from 'react-redux';
import produce from 'immer';
import {faCheck, faUndo} from '@fortawesome/pro-regular-svg-icons';
import {fetchLocationsAsync, multiPutNetworkAsync} from '../../../redux/company/locations/locationThunks';
import {departmentType, Detail, detailType} from '../../../utils/hub/models';
import {multiPutAsync} from '../../../redux/company/details/detailThunks';

export const EditNetwork = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [detailsToEdit, setDetailsToEdit] = useState([]);
  const [listCopy, setListCopy] = useState([]);
  const [vpnNetworkCreated, setVpnNetworkCreated] = useState(new Detail());
  const [serverNetworkCreated, setServerNetworkCreated] = useState(new Detail());
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    setListCopy(locationList);
  }, [locationList]);

  useEffect(() => {
    setVpnNetworkCreated(getDetail('VpnNetworkCreated'));
    setServerNetworkCreated(getDetail('ServerNetworkCreated'));
  }, [detailList]);

  const onDetailChange = (value, name, setItem, currentItem, type) => {
    const newValue = produce(currentItem, draft => {
      draft.name = name;
      draft[type] = value;
      draft.valueType = type;
      draft.department = departmentType.NETWORK;
    });

    const i = detailsToEdit.findIndex(i => i.name === name);
    if (i > -1)
      setDetailsToEdit(
        produce(detailsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    else
      setDetailsToEdit(
        produce(detailsToEdit, draft => {
          draft.push(newValue);
        })
      );
    setItem(newValue);
  };

  const getDetail = name => {
    return detailList?.filter(d => d.department === departmentType.NETWORK)?.find(i => i.name === name) || new Detail();
  };

  const onChange = (name, value, location) => {
    const newValue = produce(location, draft => {
      if (name === 'siteSurvey' || name === 'lineDate') {
        draft.locationNetwork[name] = new Date(value?.setHours(12));
      }
      draft.locationNetwork[name] = value;
    });

    const index = listCopy.findIndex(obj => obj.id === location.id);
    setListCopy(
      produce(listCopy, draft => {
        draft[index] = {...newValue};
      })
    );

    const i = itemsToEdit.findIndex(obj => obj?.id === location.id);
    if (i > -1) {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    } else {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    }
  };

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutNetworkAsync(itemsToEdit));
      setItemsToEdit([]);
    }
    if (detailsToEdit.length > 0) {
      dispatch(multiPutAsync(detailsToEdit));
      setDetailsToEdit([]);
    }
  };

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const resetValues = () => {
    setListCopy(locationList);
    setVpnNetworkCreated(getDetail('VpnNetworkCreated'));
    setServerNetworkCreated(getDetail('ServerNetworkCreated'));
    setItemsToEdit([]);
    setDetailsToEdit([]);
  };

  const countRowChecks = location => {
    let checked = 0;
    if (location?.locationNetwork?.locationNetworkCreated) checked++;
    if (location?.locationNetwork?.equipmentOfferSent) checked++;
    return checked;
  };

  const getRowValue = location => {
    const count = countRowChecks(location);
    return count < 2 ? `${count}/2 steps completed` : 'Ready to go live';
  };

  const sortedData = [...listCopy].sort((a, b) => {
    if (sort.key === 'siteSurvey' && sort.direction === 'asc') {
      return a.locationNetwork[sort.key]?.localeCompare(b.locationNetwork[sort.key]);
    } else if (sort.key === 'siteSurvey' && sort.direction === 'desc') {
      return b.locationNetwork[sort.key]?.localeCompare(a.locationNetwork[sort.key]);
    } else if (sort.key === 'lineDate' && sort.direction === 'asc') {
      return a.locationNetwork[sort.key]?.localeCompare(b.locationNetwork[sort.key]);
    } else if (sort.key === 'lineDate' && sort.direction === 'desc') {
      return b.locationNetwork[sort.key]?.localeCompare(a.locationNetwork[sort.key]);
    } else if (sort.key === 'status' && sort.direction === 'asc') {
      return countRowChecks(a) - countRowChecks(b);
    } else if (sort.key === 'status' && sort.direction === 'desc') {
      return countRowChecks(b) - countRowChecks(a);
    } else if (sort.direction === 'asc') {
      return a[sort.key]?.localeCompare(b[sort.key]);
    } else {
      return b[sort.key]?.localeCompare(a[sort.key]);
    }
  });

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Network</h3>
        <div className='backoffice-button-group'>
          {(itemsToEdit.length > 0 || detailsToEdit.length > 0) && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button
            variant={'filled'}
            disabled={itemsToEdit.length === 0 && detailsToEdit.length === 0}
            onClick={() => saveDetails()}
          >
            Save
          </Button>
        </div>
      </div>
      <div className='backoffice-editable-switchrow bfl-autocol'>
        <div className='labeled-checkbox'>
          <Label>VPN network created</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={vpnNetworkCreated?.boolValue}
            onChange={e =>
              onDetailChange(
                e.target.checked,
                'VpnNetworkCreated',
                setVpnNetworkCreated,
                vpnNetworkCreated,
                detailType.BOOL
              )
            }
            type={'switch'}
            label='O365-Tenant CSP enabled:'
          />
        </div>
        <div className='labeled-checkbox'>
          <Label>Server network created</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={serverNetworkCreated?.boolValue}
            onChange={e =>
              onDetailChange(
                e.target.checked,
                'ServerNetworkCreated',
                setServerNetworkCreated,
                serverNetworkCreated,
                detailType.BOOL
              )
            }
            type={'switch'}
            label='User registration complete:'
          />
        </div>
      </div>
      <Table className='edit-network-table'>
        <colgroup>
          <col className='from-large' style={{width: '60%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '20%'}} />
        </colgroup>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
              Location
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('siteSurvey')} onClick={onSortChangeCreator('siteSurvey')}>
              Site survey
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('lineDate')} onClick={onSortChangeCreator('lineDate')}>
              Line date
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('status')} onClick={onSortChangeCreator('status')}>
              Status
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedData.map(location => (
            <Table.Row className='edit-network-row' key={location.id}>
              <Table.Cell>{location.name}</Table.Cell>
              <Table.Cell>
                <DatePicker
                  label='Site survey'
                  hideLabel={true}
                  selected={
                    new Date(location?.locationNetwork?.siteSurvey).getFullYear() === 1
                      ? null
                      : new Date(location?.locationNetwork?.siteSurvey)
                  }
                  onChange={e => onChange('siteSurvey', e, location)}
                />
              </Table.Cell>
              <Table.Cell>
                <DatePicker
                  label='Line date'
                  hideLabel={true}
                  selected={
                    new Date(location?.locationNetwork?.lineDate).getFullYear() === 1
                      ? null
                      : new Date(location?.locationNetwork?.lineDate)
                  }
                  onChange={e => onChange('lineDate', e, location)}
                />
              </Table.Cell>
              <Table.Cell className='backoffice-weird-input'>
                <Dropdown
                  popperOptions={{
                    modifiers: [
                      {
                        name: 'sameWidth',
                        enabled: true,
                        fn: ({state}) => {
                          state.styles.popper.width = `${state.rects.reference.width}px`;
                        },
                        phase: 'beforeWrite',
                        requires: ['computeStyles'],
                        effect: ({state}) => {
                          state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                        }
                      }
                    ]
                  }}
                  noPadding
                  content={
                    <>
                      <Checkbox
                        label='Location network created'
                        checked={location?.locationNetwork?.locationNetworkCreated}
                        onChange={e => onChange('locationNetworkCreated', e.target.checked, location)}
                      />
                      <Checkbox
                        label='Equipment offer sent'
                        checked={location?.locationNetwork?.equipmentOfferSent}
                        onChange={e => onChange('equipmentOfferSent', e.target.checked, location)}
                      />
                    </>
                  }
                >
                  <Input
                    {...(countRowChecks(location) === 2 && {
                      icon: faCheck
                    })}
                    readOnly
                    value={getRowValue(location)}
                    label={'test'}
                    hideLabel={true}
                  />
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default EditNetwork;
