import React from 'react';
import loginImage from '../../assets/images/Login/Onboard-login-logo.png';
import './login.scss';
import {create} from 'simple-oauth2';
import { microsoftCredentials, linkedInCredentials } from "../../utils/authConfig";
import {Button, useApplyTheme} from '@intility/bifrost-react';
import {faLinkedin, faMicrosoft} from '@fortawesome/free-brands-svg-icons';
import lightImage from "../../assets/images/Login/Default-background-wrapper.svg";
import darkImage from "../../assets/images/Login/Default-background-wrapper-dark.svg";
import serviceImage from "../../assets/images/Login/Intility-service.svg";
import useCurrentTheme from '../Navigation/useCurrentTheme';
import useLocalStorageState from 'use-local-storage-state';

const login = (credentials, authorizeParams) => {
  const oauth2 = create(credentials);
  window.location.href = oauth2.authorizationCode.authorizeURL(authorizeParams);
};

export const loginMicrosoftAzureAd = (userTenant) => {
  const loginWindow = window.open(`https://login.microsoftonline.com/${userTenant}/adminconsent?client_id=${process.env.REACT_APP_GRAPH_MICROSOFT_APP_CLIENT_ID}`,'_blank','height=600,width=500');

  const timer = setInterval(function() {
    if(loginWindow.closed) {
      clearInterval(timer);
      alert('closed');
    }
  }, 1000);
};

const loginMicrosoft = () => {
  login(microsoftCredentials, {
    redirect_uri: process.env.REACT_APP_API_URL + '/auth/microsoft',
    scope: 'openid profile email User.Read'
  });
};

const loginLinkedIn = () => {
  login(linkedInCredentials, {
    redirect_uri: process.env.REACT_APP_API_URL + '/auth/linkedin',
    scope: ['r_liteprofile', 'r_emailaddress']
  });
};

/**
 * Login Page
 */
const Login = () => {
  const [theme, setTheme] = useLocalStorageState("bfTheme", {
    defaultValue: "dark",
  });

  useApplyTheme(theme);
  const currentTheme = useCurrentTheme();

  return (
    <main id='login-main'>
      <div
        style={
          currentTheme === 'light'
            ? {
                backgroundImage: `url(${lightImage})`,
                backgroundColor: '#eeeef1'
              }
            : {
                backgroundImage: `url(${darkImage})`,
                backgroundColor: 'var(--bfc-base-1)'
              }
        }
        id='login'
      >
        <div id='login-box'>
          <div className='login-header'>
            <div className='login-image'>
              <img src={loginImage} alt='onboard-login' className='image' />
            </div>
            <h3>Onboard</h3>
          </div>
          <div className="login-content">
            <Button
              pill
              variant="filled"
              onClick={loginMicrosoft}
              icon={faMicrosoft}
            >
              Sign in with Microsoft
            </Button>
            <Button pill onClick={loginLinkedIn} icon={faLinkedin}>
              Sign in with LinkedIn
            </Button>
            <img src={serviceImage} alt="Intility-footer" className='intility-footer'/>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
