import {faUser} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 20,
  text: 'All company users registered',
  icon: faUser,
  isEnabled: data => {
    return data.userPlatform?.information?.isEnabled;
  },
  isEnabledOverview: data => {
    return data.userPlatform?.isEnabled;
  },
  getStatusText: data => {
    return data.userPlatform.information.modifiedAt;
  }
};
