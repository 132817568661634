import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import {
  Badge, Button,
  Checkbox,
  Dropdown,
  FieldGroup,
  Icon,
  Input, Modal,
  Pagination,
  Table, Tooltip, useBreakpoint
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import './registeredContent.scss';
import { faCaretCircleDown, faSearch } from "@fortawesome/pro-solid-svg-icons";
import {IamsRegistrationRow} from './IamsRegistrationRow';
import useIntilityPermission from '../../../../../hooks/useIntilityPermission';
import IamsRegistrationModal from './IamsRegistrationModal';
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkplaceDevicesAsync } from "../../../../../redux/company/iamsUsers/workplaceDevice/workplaceDeviceThunks";
import {
  updateIamsUserAsync,
  updateIamsUsersDevicesBulkAsync
} from "../../../../../redux/company/iamsUsers/iamsUserThunks";
import { replacementFilter, replacementOptions, IamsSearchOptions } from "./IamsSelectOptions";
import { useOutsideClicker } from "../../../../../hooks/useOutsideClicker";
import InputWithFeedback, { InputType } from "../../../../InputWithFeedback/InputWithFeedback";
import produce from "immer";
import { fetchUsersAsync } from "../../../../../redux/company/users/userThunks";
import {
  faArrowsFromLine,
  faArrowsToLine, faExclamationTriangle,
  faExpand,
  faExpandArrows,
  faExpandWide
} from "@fortawesome/pro-regular-svg-icons";

export const checkWarranty = date => {
  if (date === undefined || date === null) return null;
  const newDate = new Date(date);
  if (newDate.getFullYear() === 1) {
    return null;
  }
  const today = new Date(Date.now());
  let months;
  months = (newDate.getFullYear() - today.getFullYear()) * 12;
  months -= today.getMonth();
  months += newDate.getMonth();
  return months;
};

export const checkWarrantyByStart = date => {
  if (date === undefined || date === null) return null;
  const newDate = new Date(date);
  if (newDate.getFullYear() === 1) {
    return null;
  }
  const today = new Date(Date.now());
  let months;
  months = ((newDate.getFullYear() + 3) - today.getFullYear()) * 12;
  months -= today.getMonth();
  months += newDate.getMonth();
  return months;
};

export const checkMemory = memory => {
  if (memory === undefined || memory === null || memory === 0) return null;
  return memory / 1024;
};

export const checkOS = os => {
  if (os === undefined || os === null || os.length === 0) return null;
  return os?.toLowerCase()?.includes('microsoft windows 10') || os?.toLowerCase()?.includes('macos');
};

export const getMemoryBadge = memory => {
  if (memory === null)
    return (
      <Badge state='warning'>
        Memory unknown
      </Badge>
    );
  return memory >= 8 ? (
    <span>
      {memory} GB
      <Badge style={{marginLeft: 'var(--bfs4)'}} state='success'>Ok</Badge>
    </span>
  ) : (
    <span>
      {memory} GB
      <Badge style={{marginLeft: 'var(--bfs4)'}} state='alert'>Low</Badge>
    </span>
  );
};

export const RegisteredContent = ({userList, isComplete, setCsvFilter}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth);
  const allUsers = useSelector(state => state.company.data.userPlatform.userList);
  const { workplaceDevices } = useSelector(state => state.company.data.iamsUserPlatform);
  const [searchCriterea, setSearchCriterea] = useState({
    value: 'firstName',
    label: 'First name'
  });
  const [recommendationsFilter, setRecommendationsFilter] = useState(0);
  const [editUser, setEditUser] = useState(null);
  const [editType, setEditType] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [replacementsFilter, setReplacementsFilter] = useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({key: 'firstName', direction: 'asc'});
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [searchValue, setSearchValue] = useState('');
  const [numberSelected, setNumberSelected] = useState(0);
  const [userEditList, setUserEditList] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [bulkReady, setBulkReady] = useState(false);
  const [bulkItem, setBulkItem] = useState(undefined);
  const [comment, setComment] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [commentState, setCommentState] = useState('');
  const [selectedIamsUser, setSelectedIamsUser] = useState(undefined);
  const dispatch = useDispatch();
  const intilityUser = useIntilityPermission();
  const ref = useRef(null);

  const toLarge = useBreakpoint(null, 'large');

  useOutsideClicker(ref, setDropdownOpen);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchWorkplaceDevicesAsync(id));
  }, [user, dispatch, id]);

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setIsAllSelected(false);
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };


  useEffect(() => {
    setUserOptions(allUsers?.map(u => {
      if (u.isEnabled === false) return {value: u, label: <span>{u?.firstName} {u?.lastName} <Tooltip content='User deleted, if incorrect restore from Users page.'><Badge state={"alert"}>Deleted</Badge></Tooltip></span>, data: `${u?.firstName} ${u?.lastName}`};
      return {value: u, label: `${u?.firstName} ${u?.lastName}`, data: `${u?.firstName} ${u?.lastName}`};
    }));
  }, [allUsers]);

  useEffect(() => {
    if (editUser !== null) {
      setModalOpen(true);
    }
  }, [editUser]);

  useEffect(() => {
    if (modalOpen === false) {
      setEditUser(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (numberSelected === 0 && !isAllSelected) {
      setUserEditList([]);
      setBulkReady(false);
      setBulkItem(null);
      setSaving(false);
      setBulkItem(null);
    }
  }, [numberSelected, isAllSelected]);

  useEffect(() => {
    if(isAllSelected) {
      setNumberSelected(sortedAndIndexedData.length);
    } else {
      setNumberSelected(0);
    }
  }, [isAllSelected])

  const onBulkSelect = item => {
    if (item === null) return;
    setBulkReady(true);
    setSaving(true);
    setBulkItem(item);
  };

  const handleBulkAction = () => {
    if (bulkReady && bulkItem !== null) {
      let filteredUserlist
      if(bulkItem?.value?.type !== "Computer") {
        filteredUserlist = userEditList.filter(i => i.id !== bulkItem?.value?.id)
      } else {
        filteredUserlist = userEditList;
      }
      const newUsers = filteredUserlist.map(u => {
        const item = {...u};
        if (bulkItem?.value?.type === "Computer") {
          item.devices = item.devices?.filter(d => d.type !== "Computer")
        }
        if (item.devices.some(d => d.id === bulkItem?.value?.id)) {
          return null;
        }
        item.devices = [...item.devices, bulkItem.value];
        return item;
      });
      dispatch(updateIamsUsersDevicesBulkAsync(newUsers));
      setSubmitted(true);
      setIsAllSelected(false);
      setNumberSelected(0);
    }
  };

  const saveComment = state => {
    const updatedIamsUser = produce(selectedIamsUser, draft => {
      draft.comment = state;
    });
    dispatch(updateIamsUserAsync(updatedIamsUser)).then(() => dispatch(fetchUsersAsync(id)));
    setComment(false);
    setCommentState('');
  };

  const editComment = user => {
    setSelectedIamsUser(user);
    setCommentState(user?.comment);
    setComment(true);
  };

  const checkAll = e => {
    const {checked} = e.target;
    setIsAllSelected(checked);
    if (!checked) {
      setNumberSelected(0);
    }
  };

  const filteredData = userList
    .filter(u => {
      if (recommendationsFilter === 0) {
        return u;
      } else if (recommendationsFilter === 1) {
        return u.iamsUser?.intilityRecommendation?.startsWith('Awaiting');
      } else if (recommendationsFilter === 2) {
        return u.iamsUser?.intilityRecommendation?.startsWith('Keep');
      } else if (recommendationsFilter === 3) {
        return u.iamsUser?.intilityRecommendation?.startsWith('Consider');
      } else if (recommendationsFilter === 4) {
        return u.iamsUser?.intilityRecommendation?.startsWith('Replace');
      } else if (recommendationsFilter === 5) {
        return u.iamsUser?.intilityRecommendation?.startsWith('Upgrade');
      } else {
        return u.iamsUser?.intilityRecommendation?.startsWith('Verify');
      }
    })
    .filter(u => {
      if (replacementsFilter === 0) {
        return u;
      } else if (replacementsFilter === 1) {
        return (u.iamsUser?.computerAction === 'Keep' && u.iamsUser?.devices.every(d => d.type !== 'Computer'));
      } else if (replacementsFilter === 2) {
        return u.iamsUser?.devices.some(d => d.type === 'Computer');
      }
    })
    .filter(s => {
      if (!s.iamsUser[searchCriterea.value] && searchValue.length === 0) return s;
      if (searchCriterea.value === 'state') {
        return s.companyUser[searchCriterea.value]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase());
      } else
      return s.iamsUser[searchCriterea.value]
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchValue?.toLowerCase());
    })
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (sort.key === 'userComment') {
          if (!a.companyUser?.comment) return 1;
          if (!b.companyUser?.comment) return -1;
          return a.companyUser?.comment?.toString()?.localeCompare(b.companyUser?.comment?.toString());
        }
        if (sort.key === 'computerAction') {
          return a.iamsUser?.devices?.length >= b.iamsUser?.devices?.length
        }
        if (sort.key === 'state') {
          if (!a.companyUser?.state) return 1;
          if (!b.companyUser?.state) return -1;
          return a.companyUser?.state?.toString()?.localeCompare(b.companyUser?.state?.toString());
        }
        if (!a.iamsUser[sort.key] || a.iamsUser[sort.key] === null) return 1;
        if (!b.iamsUser[sort.key] || b.iamsUser[sort.key] === null) return -1;
        return a.iamsUser[sort.key].toString()?.localeCompare(b.iamsUser[sort.key].toString());
      } else {
        if (sort.key === 'userComment') {
          if (!b.companyUser?.comment) return 1;
          if (!a.companyUser?.comment) return -1;
          return b.companyUser?.comment?.toString()?.localeCompare(a.companyUser?.comment?.toString());
        }
        if (sort.key === 'computerAction') {
          return b.iamsUser?.devices?.length >= a.iamsUser?.devices?.length
        }
        if (sort.key === 'state') {
          if (!b.companyUser?.state) return 1;
          if (!a.companyUser?.state) return -1;
          return b.companyUser?.state?.toString()?.localeCompare(a.companyUser?.state?.toString());
        }
        if (!b.iamsUser[sort.key] || b.iamsUser[sort.key] === null) return 1;
        if (!a.iamsUser[sort.key] || a.iamsUser[sort.key] === null) return -1;
        return b.iamsUser[sort.key].toString()?.localeCompare(a.iamsUser[sort.key].toString());
      }
    });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    filteredData && filteredData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: userList?.length, label: 'All'});
    return tableOptions;
  };

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = filteredData && filteredData.slice(indexOfFirstUser, indexOfLastUser);

  const categoryOptions = workplaceDevices.filter(d => d.type === editType).map(i => ({value: i, label: i.model}))

  return (
    <div className='iams-content'>
      <div className='simple-top-bar'>
        <Button variant={expandAll ? 'filled' : 'basic'} style={{marginRight: 'var(--bfs8)'}} icon={expandAll ? faArrowsToLine : faArrowsFromLine} onClick={() => setExpandAll(!expandAll)}/>
        <FieldGroup label='Select users to bulk edit'>
          <Select label='Type' hideLabel options={replacementOptions} placeholder='Select device type' onChange={option => {
            setEditType(option.value)
            setBulkItem({ value: '', label: `Select ${option.value}` })
          }} isDisabled={!(numberSelected > 0  || isAllSelected) || isComplete} />
          <Select label='Item' hideLabel options={categoryOptions} value={bulkItem} placeholder='Select device'
                  onChange={item => onBulkSelect(item)} isDisabled={!(numberSelected > 0  || isAllSelected) || isComplete}/>
          <Button
            onClick={() => handleBulkAction()}
            variant='filled'
            style={{marginLeft: 'var(--bfs8)'}}
            state={bulkReady ? undefined : 'inactive'}
          >
            Apply ({numberSelected})
          </Button>
        </FieldGroup>

        <div style={{marginLeft: 'auto', marginRight: 'var(--bfs8)'}}>
          <Select
            key='Replacements'
            label='Computer replacements'
            value={replacementFilter.find(i => i.value === replacementsFilter)}
            options={replacementFilter}
            onChange={item => {
              setPage(1);
              setCsvFilter(item.value);
              setReplacementsFilter(item.value);
            }}
          />
        </div>

        <FieldGroup label='Search'>
          <Select
            label='Search'
            hideLabel
            options={IamsSearchOptions}
            placeholder='First name'
            onChange={item => setSearchCriterea(item)}
          />
          <Dropdown visible={searchValue.length > 0} disabled={filteredData.length === 0} content={`${filteredData.length} scans found.`}>
            <Input
              label='Search'
              hideLabel
              placeholder={`Search for ${searchCriterea.label}`}
              clearable
              icon={faSearch}
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value);
                setIsAllSelected(false);
                setPage(1);
              }}
              rightIcon
            />
          </Dropdown>
        </FieldGroup>
      </div>
      <Table>
        {(id === '260cccd3-d982-4708-c6e0-08dae3f8f162' || id === '2233e8d5-b5d2-47eb-74b7-08db090ab058') ?
          <colgroup>
            <col className='from-large' style={{width: '3%'}} />
            <col className='from-large' style={{width: '18%'}} />
            <col className='from-large' style={{width: '8%'}} />
            <col className='from-large' style={{width: '8%'}} />
            <col className='from-large' style={{width: '10%'}} />
            <col className='from-large' style={{width: '10%'}} />
            <col className='from-large' style={{width: '10%'}} />
            <col className='from-large' style={{width: '11%'}} />
            <col className='from-large' style={{width: '22%'}} />
          </colgroup> :
          <colgroup>
            <col className='from-large' style={{width: '3%'}} />
            <col className='from-large' style={{width: '18%'}} />
            <col className='from-large' style={{width: '9%'}} />
            <col className='from-large' style={{width: '9%'}} />
            <col className='from-large' style={{width: '14%'}} />
            <col className='from-large' style={{width: '9%'}} />
            <col className='from-large' style={{width: '6%'}} />
            <col className='from-large' style={{width: '10%'}} />
            <col className='from-large' style={{width: '22%'}} />
          </colgroup>
        }
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox label={'Full name'} hideLabel={true} checked={isAllSelected} onChange={checkAll} />
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('firstName')} onClick={onSortChangeCreator('firstName')}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('locationName')} onClick={onSortChangeCreator('locationName')}>
              Location
            </Table.HeaderCell>
            {(id === '260cccd3-d982-4708-c6e0-08dae3f8f162' || id === '2233e8d5-b5d2-47eb-74b7-08db090ab058') &&
              <Table.HeaderCell sorting={getSortProp('state')} onClick={onSortChangeCreator('state')}>
                State
              </Table.HeaderCell>
            }
            <Table.HeaderCell sorting={getSortProp('model')} onClick={onSortChangeCreator('model')}>
              Model
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('comment')} onClick={onSortChangeCreator('comment')}>
              IAMS Comment
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('userComment')} onClick={onSortChangeCreator('userComment')}>
              User Comment
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('priority')} onClick={onSortChangeCreator('priority')}>
              Priority
            </Table.HeaderCell>
            <Table.HeaderCell ref={ref} onClick={() => setDropdownOpen(!dropdownOpen)}>
              {toLarge ? 'Rec.' : 'Recommendation'} <Dropdown visible={dropdownOpen} noPadding content={<div style={{display: 'flex', flexDirection: 'column'}}>
              <Checkbox
                label={`Awaiting review (${userList?.filter(u => u.iamsUser?.intilityRecommendation?.startsWith('Awaiting'))?.length})`}
                checked={recommendationsFilter === 1}
                onChange={() => {
                  recommendationsFilter === 1 ?
                    setRecommendationsFilter(0) :
                    setRecommendationsFilter(1);
                  setPage(1);
                }}
              />
              <Checkbox
                label={`Keep computer (${userList?.filter(u => u.iamsUser?.intilityRecommendation?.startsWith('Keep'))?.length})`}
                checked={recommendationsFilter === 2}
                onChange={() => {
                  recommendationsFilter === 2 ?
                    setRecommendationsFilter(0) :
                    setRecommendationsFilter(2);
                  setPage(1);
                }}
              />
              <Checkbox
              label={`Replace soon (${userList?.filter(u => u.iamsUser?.intilityRecommendation?.startsWith("Consider"))?.length})`}
              checked={recommendationsFilter === 3}
              onChange={() => {
                recommendationsFilter === 3 ?
                  setRecommendationsFilter(0) :
                  setRecommendationsFilter(3);
                setPage(1);
              }}
            />
              <Checkbox
              label={`Replace now (${userList?.filter(u => u.iamsUser?.intilityRecommendation?.startsWith("Replace"))?.length})`}
              checked={recommendationsFilter === 4}
              onChange={() => {
                recommendationsFilter === 4 ?
                  setRecommendationsFilter(0) :
                  setRecommendationsFilter(4);
                setPage(1);
              }}
            />
              <Checkbox
              label={`Upgrade OS (${userList?.filter(u => u.iamsUser?.intilityRecommendation?.startsWith("Upgrade"))?.length})`}
              checked={recommendationsFilter === 5}
              onChange={() => {
                recommendationsFilter === 5 ?
                  setRecommendationsFilter(0) :
                  setRecommendationsFilter(5);
                setPage(1);
              }}
            />
            </div>}>
              <Icon icon={faCaretCircleDown} />
            </Dropdown>
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('computerAction')} onClick={onSortChangeCreator('computerAction')}>
              Replacement
            </Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAndIndexedData.map((u, i) => (
            <IamsRegistrationRow
              key={u?.iamsUser?.id + u.number + u.total + i}
              user={u}
              intilityUser={intilityUser}
              setEditUser={setEditUser}
              setNumberSelected={setNumberSelected}
              numberSelected={numberSelected}
              userEditList={userEditList}
              setUserEditList={setUserEditList}
              submited={submitted}
              isComplete={isComplete}
              editComment={editComment}
              isAllSelected={isAllSelected}
              saving={saving}
              bulk={bulkReady}
              expandAll={expandAll}
            />
          ))}
        </Table.Body>
      </Table>
      <Modal isOpen={comment} onRequestClose={() => setComment(false)} header='Edit comment'>
        <InputWithFeedback
          onChange={e => setCommentState(e.target.value)}
          maxLength={200}
          label='Comment'
          name='comment'
          value={commentState}
          type={InputType.TextArea}
        />
        <Button onClick={() => saveComment(commentState)}>Save</Button>
      </Modal>
      <div className='bottom-line'>
        <div className='row-box' />
        {Math.ceil(filteredData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(filteredData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
      <IamsRegistrationModal user={editUser} isOpen={modalOpen} setEditUser={setEditUser} setIsOpen={setModalOpen} userOptions={userOptions} />
    </div>
  );
};

RegisteredContent.propTypes = {
  userList: PropTypes.any,
  isComplete: PropTypes.bool,
  setCsvFilter: PropTypes.func,
};

export default RegisteredContent;
