import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchInformationSuccess, updateMicrosoft365InformationSuccess} from './ms365Actions';

const updateOnMicrosoft365StateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const microsoft365StateReducer = createReducer(
  0,
  updateOnMicrosoft365StateReducer((payload, state) => {
    state = payload.microsoft365Platform.state;
    return state;
  })
);

const updateOnChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateMicrosoft365InformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const microsoft365InformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
  },
  updateOnChangeReducer((payload, state) => {
    state.id = payload.microsoft365Platform.information.id;
    state.companyId = payload.microsoft365Platform.information.companyId;
    state.modifiedAt = payload.microsoft365Platform.information.modifiedAt;
    state.isEnabled = payload.microsoft365Platform.information.isEnabled;
    return state;
  })
);

export const microsoft365StatusReducer = createSlice({
  name: 'ms365Action',
  initialState: {
    fetching: false,
    updating: false,
    error: false,
  },
  reducers: {
    fetchMicrosoft365s: state => {
      state.fetching = true;
    },
    fetchMicrosoft365sError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateMicrosoft365s: state => {
      state.updating = true;
    },
    updateMicrosoft365sError: state => {
      state.updating = false;
      state.error = true;
    }
  },
  extraReducers: {
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateMicrosoft365InformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchMicrosoft365s,
  fetchMicrosoft365sError,
  updateMicrosoft365sError,
  updateMicrosoft365s,
  updatePrimaryMicrosoft365,
  updatePrimaryMicrosoft365Error,
  uploadMicrosoft365sError,
  updateMicrosoft365sClear
} = microsoft365StatusReducer.actions;
