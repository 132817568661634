import React from "react";
import './appStepCard.scss';
import PropTypes from "prop-types";
import { Button, Icon } from "@intility/bifrost-react";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { addCompleteApplicationAsync } from "../../../../../redux/company/applications/applicationThunks";
import { validateCompleteApp } from "../../../../../utils/wizard/stateValidations";
import devLog from "../../../../../utils/devLog";
import { CompleteApplication } from "../../../../../utils/hub/models";


const AppStepCard = ({appObject, setAppObject, step, totalSteps, setStep, newAppType, progress, setProgress, header, children, stepReady, setIsOpen}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {error} = useSelector(state => state.company.data.applicationPlatform.applicationStatus);
  const dispatch = useDispatch();

  const verifyApp = () => {
    return validateCompleteApp(appObject); // TODO fix validation
  }

  const resetApp = () => {
    window.localStorage.removeItem(`${newAppType}application${id}`);
    window.localStorage.removeItem(`${newAppType}applicationProgress${id}`);
    setAppObject(new CompleteApplication());
    setStep(1);
    setProgress(0);
    setIsOpen(false);
  }

  const saveApp = () => {
    dispatch(addCompleteApplicationAsync(appObject));
    if (!error) {
      verifyApp();
      resetApp();
    }
  };

  const nextStep = () => {
    setStep(++step);
    devLog(`Step: ${step}`, appObject)
    localStorage.setItem(`${newAppType}application${id}`, JSON.stringify(appObject))
    if ((progress + 1) < step) {
      setProgress(++progress)
      localStorage.setItem(`${newAppType}applicationProgress${id}`, progress);
    }
  }

  return (
    <div className='app-step-card'>
      <h3>{step}. {header}<span>{step}/{totalSteps}</span></h3>
      <div className='app-step-card-content'>
        {children}
      </div>
      <div className='step-card-button-row'>
        <Button variant={"outline"} onClick={() => resetApp()}>Cancel</Button>
        <div>
          {step > 1 ?
            <span className='bf-link' onClick={() => setStep(--step)}><Icon icon={faArrowLeft} /><p> Previous</p></span> : <span/>
          }
          {totalSteps === step ?
            <Button variant={'filled'} disabled={stepReady === false} onClick={() => saveApp()}>Add application</Button>
            :
            <Button variant={'filled'} disabled={stepReady === false} onClick={() => nextStep()}>Next step</Button>
          }
        </div>
      </div>
    </div>
  )

}

export default AppStepCard

AppStepCard.propTypes = {
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  setIsOpen: PropTypes.func,
  step: PropTypes.number,
  totalSteps: PropTypes.number,
  setStep: PropTypes.func,
  progress: PropTypes.number,
  setProgress: PropTypes.func,
  header: PropTypes.string,
  newAppType: PropTypes.string,
  stepReady: PropTypes.bool,
  children: PropTypes.node
}