import React from 'react';
import {Input, TextArea} from '@intility/bifrost-react';
import PropTypes from 'prop-types';

export const InputType = {
  Input: 0,
  TextArea: 1
};

export const InputWithFeedback = ({
  maxLength = 100,
  feedBack = 'Too many characters',
  value,
  onChange,
  name,
  label,
  hideLabel = false,
  checkMissingValue = false,
  disabled = false,
  optional = false,
  required = false,
  type = InputType.Input,
  description = undefined,
  className,
  placeholder,
  otherProps
}) => {
  switch (type) {
    case InputType.Input:
      return (
        <Input
          {...((value?.length || 0) > maxLength ? {
            state: 'warning',
            feedback: feedBack
          } : ((value?.length === 0 || value === null) && checkMissingValue) ? {
            state: 'alert',
            feedback: 'Missing info'
          } :
            {
              state: 'default',
              feedback: ''
            })}
          {...otherProps}
          value={value || ''}
          onChange={onChange}
          name={name}
          label={label}
          hideLabel={hideLabel}
          disabled={disabled}
          optional={optional}
          required={required}
          description={description}
          placeholder={placeholder}
          className={className}
        />
      );
    case InputType.TextArea:
      return (
        <TextArea
          {...((value?.length || 0) > maxLength ? {
            state: 'warning',
            feedback: feedBack
          } : ((value?.length === 0 || value === null) && checkMissingValue) ? {
            state: 'alert',
            feedback: 'Missing info'
          } :
            {
              state: 'default',
              feedback: ''
          })}
          {...otherProps}
          value={value || ''}
          onChange={onChange}
          name={name}
          label={label}
          hideLabel={hideLabel}
          disabled={disabled}
          optional={optional}
          required={required}
          description={description}
          className={className}
        />
      );
    default:
      return (
        <Input
          {...((value?.length || 0) > maxLength ? {
            state: 'warning',
            feedback: feedBack
          } : ((value?.length === 0 || value === null) && checkMissingValue) && {
            state: 'alert',
            feedback: 'Missing info'
          })}
          {...otherProps}
          value={value || ''}
          onChange={onChange}
          name={name}
          label={label}
          hideLabel={hideLabel}
          disabled={disabled}
          optional={optional}
          required={required}
          description={description}
          className={className}
        />
      );
  }
};

InputWithFeedback.propTypes = {
  maxLength: PropTypes.number,
  feedBack: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  checkMissingValue: PropTypes.bool,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  otherProps: PropTypes.any,
  className: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf([InputType.Input, InputType.TextArea])
};

export default InputWithFeedback;
