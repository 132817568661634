import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {fetchProjectTeamsAsync} from '../../../redux/company/projectTeams/projectTeamsThunks';
import Team from './Team';
import './projectTeam.scss';
import {useBreakpoint} from '@intility/bifrost-react';

export const ProjectTeam = () => {
  const {id, name} = useSelector(state => state.company.data.info);
  const {list} = useSelector(state => state.company.data.projectTeams);
  const {user} = useSelector(state => state.auth);
  const [mailList, setMailList] = useState([]);
  const dispatch = useDispatch();
  const toMedium = useBreakpoint(null, 'medium');
  const fromMediumToXl = useBreakpoint('medium', 'xl');

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchProjectTeamsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    if (list.length === 0) return;
    const newList = [];
    list.forEach(p => {
      p.teamRoles.forEach(r => {
        newList.push(r?.member?.userPrincipalName)
      })
    })
    setMailList([...new Set(newList)]);
  }, [list]);

  const chunk = list.length / (toMedium ? 1 : fromMediumToXl ? 2 : 4);
  const rest = list.length % (toMedium ? 1 : 2);

  const getRows = () => {
    let i, j, temporary;
    const test = [];
    for (i = 0, j = list.length; i < j; i += chunk) {
      if (i === 0 && rest > 0) {
        temporary = list.slice(i, ++i + chunk);
      } else {
        temporary = list.slice(i, i + chunk);
      }
      test.push(
        <div key={i} className='teams-row'>
          {temporary.map((l, i) => {
            return <Team key={i} team={l} />;
          })}
        </div>
      );
    }
    return test;
  };

  return (
    <div>
      <div style={{marginLeft: '20px', display: 'flex', flexDirection: 'row'}}>
        <a style={{marginRight: 'var(--bfs16)'}}
          href={`mailto:${mailList?.join(
            ';'
          )}?subject=${name} onboarding&body=Information information`}
        >
          <h5 className='bf-link'>Draft E-mail to all</h5>
        </a>
        <a href={`https://teams.microsoft.com/l/chat/0/0?users=${mailList?.join(',')}`}>
          <h5 className='bf-link'>Create teams chat group with all</h5>
        </a>
      </div>
      <div id='backoffice-project-team' className='bfl-grid'>
        {getRows().map(i => i)}
      </div>
    </div>
  );
};

export default ProjectTeam;
