import {faTasks} from '@fortawesome/pro-regular-svg-icons';
import {LogisticsDetails} from '../../components/BackOffice/LogisticsDetails/LogisticsDetails';

export default {
  title: 'Logistics',
  path: 'logistics/',
  icon: faTasks,
  editable: false,
  component: LogisticsDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
