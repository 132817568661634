import EditDomains from '../../components/BackOffice/EditComponents/EditDomains';
import {faServer} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Domains',
  path: 'domains/edit',
  icon: faServer,
  editable: true,
  component: EditDomains
};
