const getAccessToken = () => window.localStorage.accessToken;

export const apiFetch = (relativeUrl, options = {}) => {
  const url = process.env.REACT_APP_API_URL + relativeUrl;

  options.headers = {
    ...options.headers,
    Authorization: 'Bearer ' + getAccessToken()
  };

  return fetch(url, options);
};

export const apiFetchIdCollection = (apiFetch, {url, method, body}) =>
  apiFetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => {
      switch (response.status) {
        case 400:
          throw new Error('Bad request.');
        case 401:
          throw new Error('Unauthorized access.');
        case 403:
          throw new Error('Forbidden access.');
        case 500:
          throw new Error('Server error.');
        case 501:
          throw new Error('Not implemented.');
      }
      return response.text();
    })
    .then(text => (text ? JSON.parse(text) : null))
    .catch(error => {
      console.log(error);
      throw error;
    });

export default apiFetch;
