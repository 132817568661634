import React from 'react';
import Select from '@intility/bifrost-react-select';
import {TextArea, Grid} from '@intility/bifrost-react';

const OptionalFields = ({appObject, setAppObject}) => {
  const handleEnvironmentChange = selectedOptions => {
    const environmentString = selectedOptions.map(option => option.value).join(',');
    setAppObject(prevAppObject => ({...prevAppObject, environment: environmentString}));
  };

  const handleCommentChange = event => {
    setAppObject(prevAppObject => ({...prevAppObject, comment: event.target.value}));
  };

  return (
    <div className='basic-detail-box'>
      <div className='basic-detail-box-header'>
        <h5>Optional fields</h5>
      </div>
      <Grid className='optional-fields-box-content'>
        <Select
          label='Application environment'
          description='Select environment(s) for the application'
          isMulti
          optional
          options={[
            {
              label: 'Environments',
              options: [
                {value: 'prod', label: 'Production'},
                {value: 'dev', label: 'Development'},
                {value: 'test', label: 'Testing'}
              ]
            }
          ]}
          value={
            typeof appObject.environment === 'string' && appObject.environment
              ? appObject.environment
                  .split(',')
                  .map(value => ({value, label: value.charAt(0).toUpperCase() + value.slice(1)}))
              : []
          }
          onChange={handleEnvironmentChange}
        />
        <TextArea
          textAreaStyle={{
            height: '64px',
            minHeight: '38px',
            padding: '8px 0px 6px 12px'
          }}
          label='Comment'
          description='The previous steps summarize the information we need about the application, but maybe you have more to add?'
          value={appObject.comment}
          onChange={handleCommentChange}
          optional
        />
      </Grid>
    </div>
  );
};

export default OptionalFields;
