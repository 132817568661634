import {createAction} from '@reduxjs/toolkit';
import {editBilling, normalizeBilling, normalizeBillingInformation, normalizeBillingList} from './billingNormalizers';

export const fetchBillingListSuccess = createAction('billingAction/fetchBillingListSuccess', apiBillings => ({
  payload: normalizeBillingList(apiBillings)
}));

export const fetchInformationSuccess = createAction('billingAction/fetchBillingInformationSuccess', apiBillings => ({
  payload: normalizeBillingInformation(apiBillings)
}));

export const fetchBillingSuccess = createAction('billingAction/fetchBillingSuccess', apiBillings => ({
  payload: normalizeBilling(apiBillings)
}));

export const updateBillingSuccess = createAction('billingAction/updateBillingSuccess', (apiBilling, billingList) => ({
  payload: editBilling(billingList, apiBilling)
}));

export const updatePrimarySuccess = createAction('billingAction/updateBillingPrimarySuccess', apiBilling => ({
  payload: normalizeBillingInformation(apiBilling)
}));

export const updateBillingInformationSuccess = createAction('billingAction/updateInformationSuccess', apiLocations => ({
  payload: normalizeBillingInformation(apiLocations)
}));
