import EditOnboardLayout from '../../components/BackOffice/EditComponents/EditOnboardLayout';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'OnboardLayout',
  path: 'onboardLayout/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditOnboardLayout
};
