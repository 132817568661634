import darkHeader from "../../../../assets/images/overview/BannerDark.png";
import darkHeaderHover from "../../../../assets/images/overview/BannerDarkHover.png";
import darkHeaderComplete from "../../../../assets/images/overview/BannerDarkComplete.png";
import lightHeader from "../../../../assets/images/overview/BannerLight.png";
import lightHeaderHover from "../../../../assets/images/overview/BannerLightHover.png";
import lightHeaderComplete from "../../../../assets/images/overview/BannerLightComplete.png";
import { Button, dateFormat, Drawer, Grid, Icon } from "@intility/bifrost-react";
import TaskContainer from "./TaskContainer/TaskContainer";
import IntilityBar from "../Overview/IntilityBar/IntilityBar";
import './tasks.scss';
import React, { useEffect, useState } from "react";
import { fetchOnboardTasksAsync } from "../../../../redux/company/onboardTasks/onboardTaskThunks";
import { fetchUsersAsync } from "../../../../redux/company/users/userThunks";
import { useDispatch, useSelector } from "react-redux";
import { SettingsCard } from "../Overview/Settings/Settings";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { PublishArticleDrawer } from "../Devices.MobileDevices/Articles/PublishArticle";
import { pageDataOptions } from "../../../../utils/hub/pageData/pageDataOptions";
import { useHistory } from "react-router-dom";
import OnboardTaskComponent from "./TaskContainer/OnboardTaskComponent";
import useCurrentTheme from '../../../Navigation/useCurrentTheme';

export const findNextTask = (taskList) => {
  const task = [...taskList]?.sort((t1, t2) => {
    const dateA = new Date(t1.dueDate);
    const dateB = new Date(t2.dueDate);
    if (dateA.getFullYear() === 1) return 1;
    if (dateB.getFullYear() === 1) return -1;
    return dateA.getTime() - dateB.getTime();
  }).sort((t1, t2) => {
    const dateA = new Date(t1.dueDate);
    const dateB = new Date(t2.dueDate);
    if (t1.complete) return 1;
    if (t2.complete) return -1;
    if (dateA.getFullYear() !== 1) return 0;
    if (dateB.getFullYear() !== 1) return 0;
    return task;
  }).find(t => !t?.complete);
  if (task) {
    return task;
  }
  return false;
};

const Tasks = () => {
  const { id } = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth);
  const {taskList} = useSelector(state => state.company.data.taskPlatform);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskHighight, setTaskHighight] = useState(false);
  const [colabOpen, setColabOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [nextTask, setNextTask] = useState();
  const theme = useCurrentTheme();

  const enabledTasks = taskList && taskList?.filter(t => t.isEnabled === true);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchOnboardTasksAsync(id));
    dispatch(fetchUsersAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    if (taskHighight) {
      setTimeout(() => {
        setTaskHighight(false);
      }, 1000);
    }
  }, [taskHighight])


  useEffect(() => {
    if (enabledTasks.length > 0) {
      setNextTask(findNextTask(enabledTasks));
    }
  }, [enabledTasks])

  const superHighlight = () => {
    setSelectedTask(null);
    setTaskHighight(true);
    setTimeout(() => {
      setColabOpen(true);
    }, 1000);
  }

  const getBannerImage = () => {
    if (enabledTasks.some(t => t.complete === false)) {
      if (theme === 'light') {
        return hover ? lightHeaderHover : lightHeader
      } else if (theme === 'dark') {
        return hover ? darkHeaderHover : darkHeader
      }
      return theme === 'light' ? lightHeader : darkHeader;
    } else {
      return theme === 'light' ? lightHeaderComplete : darkHeaderComplete;
    }
  }

  return (
    <div id='tasks'>
      <div className='content'>
        <div className='content-main'>
          <div className='content-main-header'>
            <Grid small={4} medium={5} large={7} gap={24}>
              <Grid.Span small={2} medium={3} large={5}>
                <div className='header-task' onMouseEnter={() => setHover(true)}
                     onMouseLeave={() => setHover(false)} onClick={() => {
                  if (nextTask?.request === 'Invite project team to Onboard') {
                    superHighlight();
                  } else {
                    nextTask ? history.push(`/${id}/${pageDataOptions[nextTask?.section]?.path}`) : undefined}}
                  }
                >
                  <div className='header-task-image' style={{ backgroundImage: `url(${getBannerImage()})` }}>
                    <div className='header-icon'>
                      <Icon icon={faCalendarAlt} />
                    </div>
                    <p>
                      Next task:
                    </p>
                    <h5>{nextTask?.request}</h5>
                  </div>
                  <div className='header-task-info'>
                    <span className='bf-small'>
                      Assignee:
                      {!nextTask?.user ?
                        <span className='bf-em'>None</span> :
                        <span><img src={`data:image/png;base64,${nextTask?.user?.profilePhoto}`} alt='img' />{nextTask?.user?.firstName} {nextTask?.user?.lastName}</span>
                      }
                    </span>
                    <p className='bf-small bf-em' style={{color: 'var(--bfc-base-c-2)'}}>
                      {nextTask?.complete ? 'Completed: ' : 'Due date: '}
                      {new Date(nextTask?.complete ? nextTask?.modifiedAt : nextTask?.dueDate)?.getFullYear() > 1 ? dateFormat('no-nb', new Date(nextTask?.complete ? nextTask?.modifiedAt : nextTask?.dueDate), {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit'
                      }) : 'Not set'}
                    </p>
                  </div>
                </div>
              </Grid.Span>
              <Grid.Span small={2} medium={2} large={2}>
                <div className='topbar-collaborate'>
                  <SettingsCard highlight={taskHighight} isOpen={colabOpen} setIsOpen={setColabOpen}/>
                </div>
              </Grid.Span>
            </Grid>
          </div>
          <TaskContainer selectedTask={selectedTask} setSelectedTask={setSelectedTask} setTaskHighight={setTaskHighight} setIsOpen={setColabOpen}/>
        </div>
        <div className='overview-bottom-bar'>
          <Grid small={4} large={7} gap={24}>
            <Grid.Span small={4} large={7} className='bfc-base-3-bg'>
              <IntilityBar/>
            </Grid.Span>
          </Grid>
        </div>
      </div>
      <Drawer header={selectedTask?.request} isOpen={selectedTask !== null} overlay={true} onRequestClose={() => setSelectedTask(null)}
      footer={
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button onClick={() => setSelectedTask(null)}>Close guide</Button>
          <Button onClick={() => {
            if (selectedTask?.request === 'Invite project team to Onboard') {
              superHighlight();
            } else if (history.location.pathname === `/${id}/${pageDataOptions[selectedTask?.section]?.path}`) {
              showFloatingMessage('Task can be completed on the current page');
              setSelectedTask(null);
            } else history.push(`/${id}/${pageDataOptions[selectedTask?.section]?.path}`)
          }} variant={"filled"}>Go to task</Button>
        </div>
      }
      >
        <OnboardTaskComponent
          task={selectedTask}
          taskUser={selectedTask?.user}
          setSelectedTask={setSelectedTask}
          drawerTask
        />
        {selectedTask?.guideUrl?.includes('publish.intility.com') ?
          <PublishArticleDrawer articleId={selectedTask?.guideUrl.split('/articles/')[1] || null}/>
          :
          <div>
            <p>{selectedTask?.comment}</p>
            <a className='bf-link' target='_blank' rel='noopener noreferrer' href={selectedTask?.guideUrl}>{selectedTask?.guideUrl}</a>
          </div>
        }
      </Drawer>
    </div>
  )

}

export default Tasks