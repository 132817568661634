import { Button, Input } from "@intility/bifrost-react";
import AppStepCard from "../AppStepCard";
import React, { useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { getVendorTitle } from "../../NewApplication/ContactTitles";
import { ContactList } from "../../NewApplication/ContactList";
import NewStepVendorContact from "../NewStepVendorContact";
import { ApplicationType } from "../../ApplicationOptions";
import { contactType } from "../../ApplicationTypes";

export const StepFour = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, totalSteps, setIsOpen}) => {
  const [contactOpen, setContactOpen] = useState(false);
  const [editContact, setEditContact] = useState(undefined);

  const onChange = e => {
    const {name, value} = e.target;
    setAppObject(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const VendorContacts = () => {

    return (
      <div className='application-contact-box'>
        <div className='box-header'>
          <p>Please list your support provider and other relevant contact from the vendor.</p>
          <Button
            small={true}
            onClick={() => {
              setContactOpen(true);
            }}
          >
            Add contact
          </Button>
        </div>
        <ContactList
          contacts={appObject?.vendorContacts}
          setOpen={setContactOpen}
          setEditContact={setEditContact}
          getTitle={getVendorTitle}
          editing={true}
          setType={() => {}}
        />
        <NewStepVendorContact
          isOpen={contactOpen}
          setEditContact={setEditContact}
          editContact={editContact}
          setIsOpen={setContactOpen}
          type={contactType.VENDOR}
          setAppObject={setAppObject}
          appObject={appObject}
        />
      </div>
    );
  };


  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} header={newAppType !== ApplicationType.SaaSApp ? "Who is responsible for installing and supporting your app?" : "Who is responsible for supporting your SaaS?"} totalSteps={totalSteps} stepReady={appObject?.vendor?.length > 0}>
      <Input label='Vendor name' required name='vendor' value={appObject?.vendor} onChange={onChange}/>
      {VendorContacts()}
    </AppStepCard>
  );
};

export default StepFour

StepFour.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  newAppType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number
}