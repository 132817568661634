import {
  faBuilding,
  faChairOffice,
  faCommentsAlt,
  faHouse,
  faPhoneLaptop,
  faPrint,
  faServer,
  faTasks,
  faUser
} from '@fortawesome/pro-regular-svg-icons';

export const pageDataOptions = [
  {label: 'Home', shortLabel: 'Home', value: 0, path: '', icon: faHouse, type: 'section'},
  {label: 'Tasks', shortLabel: 'Tasks', value: 1, path: 'tasks', icon: faTasks, type: 'section'},
  {label: 'Contact', shortLabel: 'Contact', value: 2, path: 'contact', icon: faBuilding, type: 'section'},
  {label: 'Billing', shortLabel: 'Billing', value: 3, path: 'billing', icon: faBuilding, type: 'section'},
  {label: 'Domains', shortLabel: 'Domains', value: 4, path: 'domains', icon: faBuilding, type: 'section'},
  {label: 'Locations', shortLabel: 'Locations', value: 5, path: 'locations', icon: faChairOffice, type: 'section'},
  {label: 'Users', shortLabel: 'Users', value: 6, path: 'users', icon: faUser, type: 'section'},
  {label: 'Microsoft 365 and Azure', shortLabel: 'MS365', value: 7, path: 'microsoft365', icon: faServer, type: 'section'},
  {label: 'Applications', shortLabel: 'Applications', value: 8, path: 'applications', icon: faServer, type: 'section'},
  {label: 'Communication', shortLabel: 'Communication', value: 9, path: 'communication', icon: faServer, type: 'section'},
  {label: 'Computers', shortLabel: 'Computers', value: 10, path: 'computers/registration', icon: faPhoneLaptop, type: 'section'},
  {label: 'Client Design', shortLabel: 'Client Design', value: 11, path: 'computers/clientDesign', icon: faPhoneLaptop, type: 'section'},
  {label: 'Mobile Compliance', shortLabel: 'Compliance', value: 12, path: 'mobile-devices/Compliance Policies', icon: faPhoneLaptop, type: 'section'},
  {label: 'Mobile Conditional', shortLabel: 'Conditional', value: 13, path: 'mobile-devices/Conditional Access', icon: faPhoneLaptop, type: 'section'},
  {label: 'Mobile Apps', shortLabel: 'Mobile Apps', value: 14, path: 'mobile-devices/Apps', icon: faPhoneLaptop, type: 'section'},
  {label: 'Printers', shortLabel: 'Printers', value: 15, path: 'printers', icon: faPrint, type: 'section'},
  {label: 'Documentation', shortLabel: 'Documentation', value: 16, path: 'documentation', icon: faCommentsAlt, type: 'section'},
];

export const projectAreaOptions = [
  {label: 'Workplace', value: 'Workplace', type: 'projectArea'},
  {label: 'Network', value: 'Network', type: 'projectArea'},
  {label: 'Application', value: 'Application', type: 'projectArea'},
  {label: 'Cloud App & Identity', value: 'CloudAppIdentity', type: 'projectArea'},
  {label: 'Project Management', value: 'ProjectManagement', type: 'projectArea'},
];

export const taskType = {
  HOME: 0,
  TASKS: 1,
  CONTACT: 2,
  BILLING: 3,
  DOMAINS: 4,
  LOCATIONS: 5 ,
  USERS: 6,
  MS365: 7,
  APPLICATIONS: 8,
  COMMUNICATION: 9,
  COMPUTERS: 10,
  CLIENTDESIGN: 11,
  MOBILECOMPLIANCE: 12,
  MOBILECONDITIONAL: 13,
  MOBILEAPPS: 14,
  PRINTERS: 15,
  DOCUMENTATION: 16
};

export const publishDataOptions = [
  {label: 'Home', shortLabel: 'Home', value: 0, subPage: 'home'},
  {label: 'Tasks', shortLabel: 'Tasks', value: 1, subPage: 'tasks'},
  {label: 'Contact', shortLabel: 'Contact', value: 2, subPage: 'contact'},
  {label: 'Billing', shortLabel: 'Billing', value: 3, subPage: 'billing'},
  {label: 'Domains', shortLabel: 'Domains', value: 4, subPage: 'domains'},
  {label: 'Locations', shortLabel: 'Locations', value: 5, subPage: 'locations'},
  {label: 'Users', shortLabel: 'Users', value: 6, subPage: 'users'},
  {label: 'Microsoft 365 and Azure', shortLabel: 'MS365', value: 7, subPage: 'microsoft365andazure'},
  {label: 'Applications', shortLabel: 'Applications', value: 8, subPage: 'applications'},
  {label: 'Communication', shortLabel: 'Communication', value: 9, subPage: 'communication'},
  {label: 'Computers', shortLabel: 'Computers', value: 10, subPage: 'computers'},
  {label: 'Client Design', shortLabel: 'Client Design', value: 11, subPage: 'computers'},
  {label: 'Mobile Compliance', shortLabel: 'Compliance', value: 12, subPage: 'mobile'},
  {label: 'Mobile Conditional', shortLabel: 'Conditional', value: 13, subPage: 'mobile'},
  {label: 'Mobile Apps', shortLabel: 'Mobile Apps', value: 14, subPage: 'mobile'},
  {label: 'Printers', shortLabel: 'Printers', value: 15, subPage: 'printers'},
  {label: 'Documentation', shortLabel: 'Documentation', value: 16, subPage: 'documentation'},
];