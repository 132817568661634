import {editListItem} from '../../../utils/hub/normalize';

export const editDetail = (detailList, detailPlatform) => {
  const newList = editListItem(detailList, detailPlatform);

  return {
    detailsPlatform: {
      detailList: newList
    }
  };
};

export const normalizeDetails = detailPlatform => {
  return {
    detailsPlatform: {
      detailList: detailPlatform.detailList,
      information: detailPlatform
    }
  };
};

export const normalizeFinancialDetails = detailPlatforms => {
  return {
    financialDetails: detailPlatforms
  };
};

export const normalizeDetailsInformation = detailPlatform => {
  return {
    detailsPlatform: {
      information: detailPlatform
    }
  };
};

export const normalizeDetailsList = detailPlatform => {
  return {
    detailsPlatform: {
      detailList: detailPlatform.detailList
    }
  };
};
