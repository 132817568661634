import React, {useEffect, useState} from 'react';
import './office365Language.scss';
import { Table, useFloatingMessage } from "@intility/bifrost-react";
import {useDispatch, useSelector} from 'react-redux';
import {fetchLocationsAsync, updateLocationAsync} from '../../../../../../../redux/company/locations/locationThunks';
import Select from "@intility/bifrost-react-select";
import PropTypes from 'prop-types';
import produce from 'immer';

const Office365Language = ({isComplete}) => {
  const {locationPlatform, info} = useSelector(state => state.company.data);
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { showFloatingMessage } = useFloatingMessage();
  const [sort, setSort] = useState({
    key: 'name',
    direction: 'asc'
  });

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const sortedData = [...locationPlatform.locationList].sort((a, b) => {
    if (sort.direction === 'asc') {
      return a[sort.key]?.localeCompare(b[sort.key]);
    } else {
      return b[sort.key]?.localeCompare(a[sort.key]);
    }
  });

  const options = [
    {value: 'Norwegian', label: 'Norwegian'},
    {value: 'English', label: 'English'},
    {value: 'Swedish', label: 'Swedish'},
    {value: 'Danish', label: 'Danish'}
  ];

  useEffect(() => {
    if (!user || !info.id) return;
    dispatch(fetchLocationsAsync(info.id));
  }, [user, dispatch, info.id]);

  const onChange = (location, item) => {
    const locationWithLanguage = produce(location, draft => {
      draft.office365Language = item.value;
    });
    dispatch(updateLocationAsync(locationWithLanguage, location.locationContact)).then(() => showFloatingMessage("Value saved"));
  };

  return (
    <div className='office365-table'>
      <p>
        Microsoft Office will be installed in the local language and proofing tools for each location. If another
        language is desired, please select your prefered language for each location.
      </p>
      {locationPlatform.locationList.length > 0 ?
        <Table noBorder={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
                Location
              </Table.HeaderCell>
              <Table.HeaderCell>Office 365 Language</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {locationPlatform.locationList &&
              sortedData.map(location => {
                const value = options.find(v => v.value === location.office365Language);
                return (
                  <Table.Row key={location.id}>
                    <Table.Cell>
                      {location.name}, {location.address}, {location.zipCode + ' ' + location.postalPlace},{' '}
                      {location.country}
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        label='Office365 language'
                        isDisabled={isComplete}
                        hideLabel
                        placeholder='- Default location -'
                        options={options}
                        value={value}
                        onChange={item => onChange(location, item)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        :
        <p>You hav no locations yet, add locations at the locations page.</p>
      }
    </div>
  );
};

Office365Language.propTypes = {
  isComplete: PropTypes.bool
};

export default Office365Language;
