import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Icon, Input, Label} from '@intility/bifrost-react';
import './editComponent.scss';
import {useDispatch, useSelector} from 'react-redux';
import {departmentType, Detail, detailType} from '../../../utils/hub/models';
import produce from 'immer';
import {multiPutAsync} from '../../../redux/company/details/detailThunks';
import {faUndo} from '@fortawesome/pro-regular-svg-icons';

export const EditMicrosoft365 = () => {
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const [O365TenantCspEnabled, setO365TenantCspEnabled] = useState(new Detail());
  const [userRegComplete, setUserRegComplete] = useState(new Detail());
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setO365TenantCspEnabled(getDetail('O365TenantCspEnabled'));
    setUserRegComplete(getDetail('UserRegistrationComplete'));
  }, [detailList]);

  const onChange = (value, name, setItem, currentItem, type) => {
    const newValue = produce(currentItem, draft => {
      draft.name = name;
      draft[type] = value;
      draft.valueType = type;
      draft.department = departmentType.IDENTITYANDMS;
    });

    const i = itemsToEdit.findIndex(i => i.name === name);
    if (i > -1)
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    else
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    setItem(newValue);
  };

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutAsync(itemsToEdit));
    }
    setItemsToEdit([]);
  };

  const resetValues = () => {
    setO365TenantCspEnabled(getDetail('O365TenantCspEnabled'));
    setUserRegComplete(getDetail('UserRegistrationComplete'));
    setItemsToEdit([]);
  };

  const getDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.IDENTITYANDMS)?.find(i => i.name === name) || new Detail()
    );
  };

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Identity and Microsoft 365</h3>
        <div className='backoffice-button-group'>
          {itemsToEdit.length > 0 && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button variant={'filled'} disabled={itemsToEdit.length === 0} onClick={() => saveDetails()}>
            Save
          </Button>
        </div>
      </div>
      <div className='bfl-autocol'>
        <div className='labeled-checkbox'>
          <Label>O365-Tenant CSP enabled:</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={O365TenantCspEnabled?.boolValue}
            onChange={e =>
              onChange(
                e.target.checked,
                'O365TenantCspEnabled',
                setO365TenantCspEnabled,
                O365TenantCspEnabled,
                detailType.BOOL
              )
            }
            type={'switch'}
            label='O365-Tenant CSP enabled:'
          />
        </div>
        <div className='labeled-checkbox'>
          <Label>User registration complete:</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={userRegComplete?.boolValue}
            onChange={e =>
              onChange(
                e.target.checked,
                'UserRegistrationComplete',
                setUserRegComplete,
                userRegComplete,
                detailType.BOOL
              )
            }
            type={'switch'}
            label='User registration complete:'
          />
        </div>
      </div>
    </div>
  );
};

export default EditMicrosoft365;
