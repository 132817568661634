import { Badge, FieldGroup, Icon, Input } from "@intility/bifrost-react";
import PropTypes from "prop-types";
import React from "react";
import './locationSystemAccordion.scss';
import { LocationSystemItem } from "../../../../../../../utils/hub/models";
import produce from "immer";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";


export const LocationSystemAccordionItemNew = ({icon, onChange, item, onDelete}) => {

  return (
    <div className='location-system-accordion-item'>
      <div>
        <Icon icon={icon}/>
        <Input label='name' hideLabel value={item?.name || ''} onChange={e => onChange(e.target.value, 'name', item)}/>
      </div>
      <FieldGroup>
        <Input label='amount' hideLabel value={item?.amount || 0} onChange={e => onChange(parseInt(e.target.value) || 0, 'amount', item)}/>
        <FieldGroup.Item>items</FieldGroup.Item>
      </FieldGroup>
      <button onClick={() => onDelete(item)}><Icon icon={faTimes}/></button>
    </div>
  )

}

LocationSystemAccordionItemNew.propTypes = {
  icon: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  item: PropTypes.object
}


export const LocationSystemAccordionItemEditable = ({location, title, icon, type, onChange, item}) => {
  let sysItem = item !== undefined ? item : location.locationSystemItems?.find(i => i.name === title);
  if (sysItem === undefined) {
    sysItem = produce(new LocationSystemItem(), draft => {
      draft.name = title;
      draft.systemType = type;
    });
  }

  return (
    <div className='location-system-accordion-item'>
      <div>
        <Icon icon={icon}/>
        <p>{title.length > 35 ? title.substring(0, 35) + '...' : title}</p>
      </div>
        <FieldGroup>
          <Input label='amount' hideLabel value={sysItem?.amount || 0} onChange={e => onChange(parseInt(e.target.value) || 0, 'amount', sysItem)}/>
          <FieldGroup.Item>items</FieldGroup.Item>
        </FieldGroup>
    </div>
  )

}

LocationSystemAccordionItemEditable.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  icon: PropTypes.object,
  type: PropTypes.number,
  onChange: PropTypes.func,
  item: PropTypes.object
}

const LocationSystemAccordionItem = ({location, title, icon}) => {
  const item = location.locationSystemItems?.find(i => i.name === title);

  return (
    <div className='location-system-accordion-item'>
      <div>
        <Icon icon={icon}/>
        <p>{title}</p>
      </div>
      <div>
        <Badge state={item?.amount > 0 ? "chill" : "neutral"}>{item?.amount || 0} items</Badge>
      </div>
    </div>
  )

}

LocationSystemAccordionItem.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  icon: PropTypes.object
}

export default LocationSystemAccordionItem