import { FieldGroup } from "@intility/bifrost-react";
import { departmentType } from "../../../utils/hub/models";
import React, { useEffect, useState } from "react";
import BoxItem from "../../BoxItem/BoxItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillingAsync } from "../../../redux/company/billing/billingThunks";

export const FinancialDetails = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {billingList, information} = useSelector(state => state.company.data.billingPlatform);
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const [predictedRev, setPredictedRev] = useState(0);
  const [estimatedPIAS, setEstimatedPIAS] = useState(0);
  const [estimatedComlines, setEstimatedComlines] = useState(0);
  const [estimatedLicenses, setEstimatedLicenses] = useState(0);
  const [estimatedNAAS, setEstimatedNAAS] = useState(0);
  const [actualPIAS, setActualPIAS] = useState(0);
  const [actualComlines, setActualComlines] = useState(0);
  const [actualLicenses, setActualLicenses] = useState(0);
  const [actualNAAS, setActualNAAS] = useState(0);
  const [establishmentCost, setEstablishmentCost] = useState(0);
  const [mainBilling, setMainBilling] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(fetchBillingAsync(id));
  }, [id]);

  useEffect(() => {
    const billing = billingList?.find(i => i.id === information?.mainId);
    setMainBilling(billing);
  }, [billingList, information]);

  useEffect(() => {
    resetValues();
  }, [detailList]);

  const resetValues = () => {
    setEstimatedPIAS(parseInt(getDetail('EstimatedPIAS')?.stringValue) || 0);
    setEstimatedComlines(parseInt(getDetail('EstimatedComlines')?.stringValue) || 0);
    setEstimatedLicenses(parseInt(getDetail('EstimatedLicenses')?.stringValue) || 0);
    setEstimatedNAAS(parseInt(getDetail('EstimatedRevenue')?.stringValue) || 0);
    setActualPIAS(parseInt(getDetail('ActualPIAS')?.stringValue) || 0);
    setActualComlines(parseInt(getDetail('ActualComlines')?.stringValue) || 0);
    setActualLicenses(parseInt(getDetail('ActualLicenses')?.stringValue) || 0);
    setActualNAAS(parseInt(getDetail('ActualRevenue')?.stringValue) || 0);
    setEstablishmentCost(parseInt(getDetail('EstablishmentCost')?.stringValue) || 0);
    setPredictedRev(parseInt(getDetail('PredictedRev')?.stringValue) || 0);
  };

  const getDetail = name => {
    return detailList?.filter(d => d.department === departmentType.FINANCE)?.find(i => i.name === name) || 0;
  };
  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Finance</h3>
      </div>
      <div className='bfl-autocol'>
        <BoxItem value={mainBilling?.name} label='Billing company'/>
        <BoxItem value={getDetail('VismaCompany')?.stringValue} label='Visma Company'/>
        <BoxItem value={`kr ${predictedRev?.toLocaleString()}`} label='Predicted revenue'/>
        <div style={{border: '1px solid var(--bfc-base-c-dimmed)', padding: 'var(--bfs12)'}}>
          <h3 style={{marginTop: '0'}}>Contractual revenue</h3>
          <p>Total kr {(estimatedPIAS + estimatedComlines + estimatedLicenses + estimatedNAAS).toLocaleString()}</p>
          <FieldGroup label='Platform Infrastructure & Services'>
            <p>kr {estimatedPIAS?.toLocaleString()}</p>
          </FieldGroup>
          <FieldGroup label='Comlines'>
            <p>kr {estimatedComlines?.toLocaleString()}</p>
          </FieldGroup>
          <FieldGroup label='Licenses'>
            <p>kr {estimatedLicenses?.toLocaleString()}</p>
          </FieldGroup>
          <FieldGroup label='Network-as-a-Service'>
            <p>kr {estimatedNAAS?.toLocaleString()}</p>
          </FieldGroup>
        </div>
        <div style={{border: '1px solid var(--bfc-base-c-dimmed)', padding: 'var(--bfs12)'}}>
          <h3 style={{marginTop: '0'}}>Actual revenue</h3>
          <p>Total kr {(actualPIAS + actualComlines + actualLicenses + actualNAAS).toLocaleString()}</p>
          <FieldGroup label='Platform Infrastructure & Services'>
            <p>kr {actualPIAS?.toLocaleString()}</p>
          </FieldGroup>
          <FieldGroup label='Comlines'>
            <p>kr {actualComlines?.toLocaleString()}</p>
          </FieldGroup>
          <FieldGroup label='Licenses'>
            <p>kr {actualLicenses?.toLocaleString()}</p>
          </FieldGroup>
          <FieldGroup label='Network-as-a-Service'>
            <p>kr {actualNAAS?.toLocaleString()}</p>
          </FieldGroup>
        </div>
        <FieldGroup label='Establishment Cost'>
          <p>kr {establishmentCost?.toLocaleString()}</p>
        </FieldGroup>
      </div>
    </div>
  );

}

export default FinancialDetails
