import {Overview} from '../../../components/Hub/Pages';
import {faHouse} from '@fortawesome/pro-regular-svg-icons';
import PageState from '../pageState';

const overviewData = {
  title: 'Home',
  path: '',
  icon: faHouse,
  isEnabled: () => {return PageState.ENABLED},
  component: Overview,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return company.info.name;
  }
};

export default overviewData;
