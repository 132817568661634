import React, {useEffect, useState} from 'react';
import './computerCustomization.scss';
import './SoftwarePicker/softwarepicker.scss';
import BasicPage from '../../BasicPage';
import { SimpleBox } from "../../../../Box/Box";
import {useDispatch, useSelector} from 'react-redux';
import {fetchCustomWallpaper} from '../../../../../utils/wallpapers';
import { Accordion, Badge, Checkbox, Message, useFloatingMessage } from "@intility/bifrost-react";
import WallpaperPicker from './DesktopSettings/WallpaperPicker/WallpaperPicker';
import useEditPermission from '../../../../../hooks/useEditPermission';
import Office365Language from './DesktopSettings/Office365Language/Office365Language';
import accordionTitle from './SoftwarePicker/accordionTitle';
import produce from "immer";
import { fetchComputerSetupAsync, setSetupValue } from "../../../../../redux/company/computerSetup/computerSetupThunks";
import PropTypes from "prop-types";
import { taskType } from "../../../../../utils/hub/pageData/pageDataOptions";
import { toggleService } from "../../../../../redux/company/companyThunks";
import { platformType } from "../../../../../redux/company/platformUpdateThunk";

export const checkListOptions = [
  {
    label: 'Lock computer after 5 minutes of inactivity',
    value: 5
  },
  {
    label: 'Lock computer after 10 minutes of inactivity',
    value: 10
  },
  {
    label: 'Lock computer after 15 minutes of inactivity',
    value: 15
  }
];

const ComputerCustomization = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {desktopSettings} = useSelector(state => state.company.data.computerSetupPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const [isComplete, setIsComplete] = useState(false);
  const [isAllCollapsed, setIsAllCollapsed] = useState(false);
  const [customWallpaper, setCustomWallpaper] = useState(null);
  const [editSettings, setEditSettings] = useState({});
  const [active, setActive] = useState('');
  const osOptions = ['32-bit', '64-bit']
  const osLanguage = ['Norwegian', 'English']
  const [selectedOs, setSelectedOs] = useState()
  const [selectedLanguage, setSelectedLanguage] = useState()
  const [selectedLockScreen, setSelectedLockScreen] = useState(0);
  const dispatch = useDispatch();
  const canEdit = useEditPermission();
  const { showFloatingMessage } = useFloatingMessage()

  useEffect(() => {
    if (id) {
      dispatch(fetchComputerSetupAsync(id));
    }
  }, [id]);

  useEffect(() => {
    fetchCustomWallpaper(desktopSettings).then(r => setCustomWallpaper(r));
  }, [desktopSettings]);

  useEffect(() => {
    setIsComplete(!desktopSettings.isEnabled);
  }, [desktopSettings.isEnabled])

  const toggleComputerCustomization = value => {
    dispatch(toggleService(platformType.COMPUTERSETUP, isComplete));
    setIsComplete(value);
  };

  useEffect(() => {
    setIsComplete(!desktopSettings.isEnabled);
    if (desktopSettings) {
      setEditSettings(desktopSettings);
      const selectedOs = osOptions.find(o => o === desktopSettings?.officeBitVersion);
      const selectedLanguage = osLanguage.find(o => o === desktopSettings?.osLanguage);
      const selectedLockScreen = checkListOptions.find(o => o.value === desktopSettings?.screensaverTimeout);
      setSelectedOs(selectedOs);
      setSelectedLanguage(selectedLanguage);
      setSelectedLockScreen(selectedLockScreen?.value || 0);
    }
  }, [desktopSettings]);

  const handleChange = key => {
    setActive(key === active ? null : key);
  };

  const anyLanguagesChanged = () => {
    return locationList.some(location => location.office365Language?.length > 0);
  };

  const anyClientHardening = () => {
    let hardening = 0;
    if(editSettings?.hasAppLocker) hardening++;
    if(editSettings?.hasHardenedOffice) hardening++;
    if(editSettings?.hasHardenedBrowser) hardening++;
    if(editSettings?.hasHardenedWindows) hardening++;
    if(editSettings?.hasBitLockerPin) hardening++;
    return `${hardening}/5`;
  };

  const anyTechSpec = () => {
    let techSpec = 0;
    if(editSettings?.has4G5G) techSpec++;
    if(editSettings?.hasTouch) techSpec++;
    if(editSettings?.hasPrivacy) techSpec++;
    if(editSettings?.hasNumpad) techSpec++;
    if(editSettings?.hasWarrantyUpgrade) techSpec++;
    return `${techSpec}/5`;
  };

  const toggleSwitch = (e, name, value) => {
    const {checked} = e.target;
    const stat = value !== undefined ? value : checked;
    setEditSettings(
      produce(draft => {
        draft[name] = stat;
      })
    );
    dispatch(setSetupValue(name, stat)).then(() => showFloatingMessage("Value saved"))
  };

  const switchLine = (name, description, value, objectName) => {
    return (
      <div className='accordion-switch-line bfl-grid'>
        <p className='bf-strong'>{name}</p>
        {description ?
          <p>{description}</p>
          :
          <div/>
        }
        <Checkbox label={name} hideLabel disabled={isComplete} type={"switch"} onChange={e => toggleSwitch(e, objectName)} checked={value}/>
      </div>
    )
  }

  return (
    <BasicPage id='computer-customization' taskType={taskType.CLIENTDESIGN}
               isComplete={isComplete}
               setIsComplete={toggleComputerCustomization}>
      <div className='content-main'>
        <SimpleBox
          className='computer-customization-box'
          title='Client design'
        >
          <Message state='warning' header='Effective on all users'>
            All settings will be the same for all users
          </Message>
          <div className='content-main-search'>
            <p>Please specify default settings.</p>
            <p
              className='bf-link'
              onClick={() => {
                handleChange('');
                setIsAllCollapsed(!isAllCollapsed);
              }}
            >
              {isAllCollapsed ? 'Collapse' : 'Expand'} all
            </p>
          </div>

          <div>
            <p className='bf-strong'>Office settings</p>
            <Accordion className='accordion-box'>
              <Accordion.Item
                className='office-box'
                title={accordionTitle('Office 365 Language', anyLanguagesChanged())}
                active={active === 'Office365' || isAllCollapsed}
                onClick={() => handleChange('Office365')}
              >
                <Office365Language isComplete={isComplete} />
              </Accordion.Item>
              <Accordion.Item
                className='office-box'
                title={accordionTitle('Office Bit-version', selectedOs !== '64-bit')}
                active={active === 'OfficeBit' || isAllCollapsed}
                onClick={() => handleChange('OfficeBit')}
              >
                <div className='accordion-default-content'>
                  <p className='bf-strong'>Office Bit version</p>
                  <p style={{color: 'var(--bfc-base-c-2)'}}>The default version of Office is 64-bit, but if you know that you are using Office addons that require the 32-bit version this needs to be changed.</p>
                  {osOptions.map(label => (
                    <Checkbox
                      type='radio'
                      label={label === '64-bit' ? '64-bit (default)' : label}
                      checked={selectedOs === label}
                      disabled={isComplete}
                      onChange={e => {
                        setSelectedOs(label)
                        toggleSwitch(e, 'officeBitVersion', label)
                      }}
                      name='radioDemo'
                      key={label}
                    />
                  ))}
                </div>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <p className='bf-strong'>Operating system settings</p>
            <Accordion className='accordion-box'>
              <Accordion.Item
                title={accordionTitle('Operating system language', selectedLanguage !== "English")}
                active={active === 'OsLanguage' || isAllCollapsed}
                onClick={() => handleChange('OsLanguage')}
              >
                <div className='accordion-default-content'>
                  <p className='bf-strong'>Operating system language</p>
                  <p style={{color: 'var(--bfc-base-c-2)'}}>The default language of the operating system is english. If you prefer norwegian this needs to be changed.</p>
                  {osLanguage.map(label => (
                    <Checkbox
                      type='radio'
                      label={label === "English" ? "English (default)" : label}
                      checked={selectedLanguage === label}
                      disabled={isComplete}
                      onChange={e => {
                        setSelectedLanguage(label)
                        toggleSwitch(e, 'osLanguage', label)
                      }}
                      key={label}
                    />
                  ))}
                </div>
              </Accordion.Item>
              <Accordion.Item
                title={accordionTitle('Lock screen activation', true)}
                active={active === 'LockScreen' || isAllCollapsed}
                onClick={() => handleChange('LockScreen')}
              >
                <div className='accordion-default-content'>
                  <p className='bf-strong'>Lock screen activation</p>
                  <p style={{color: 'var(--bfc-base-c-2)'}}>Time before the lock screen is activated.</p>
                  {checkListOptions.map(element => {
                    return (
                      <Checkbox
                        type='radio'
                        disabled={isComplete}
                        label={element.label}
                        checked={selectedLockScreen === element.value}
                        onChange={e => {
                          setSelectedLockScreen(e)
                          toggleSwitch(e, 'screensaverTimeout', parseInt(element.value) || 0)
                        }}
                        key={element.label}
                      />
                    );
                  })}
                </div>
              </Accordion.Item>
              <Accordion.Item
                title={accordionTitle('Wallpaper', editSettings?.type !== 0)}
                active={active === 'Wallpaper' || isAllCollapsed}
                onClick={() => handleChange('Wallpaper')}
              >
                <WallpaperPicker customWallpaper={customWallpaper} wallpaperType={editSettings?.type} canEdit={canEdit} disabled={isComplete} />
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <p className='bf-strong'>Computer technical specifications</p>
            <Accordion className='accordion-box'>
              <Accordion.Item
                className='office-box'
                title={
                  <div className='accordion-title'>
                    <div className='accordion-title-content'>
                      <p style={{marginRight: 'var(--bfs4)'}}>Critical specifications</p>
                      <p>{anyTechSpec()}</p>
                    </div>
                  </div>}
                active={active === 'CriticalSpecifications' || isAllCollapsed}
                onClick={() => handleChange('CriticalSpecifications')}
              >
                <div className='accordion-default-content'>
                  {switchLine('4G/5G', null, editSettings?.has4G5G, 'has4G5G')}
                  {switchLine('Touch', null, editSettings?.hasTouch, 'hasTouch')}
                  {switchLine('Privacy', null, editSettings?.hasPrivacy, 'hasPrivacy')}
                  {switchLine('Numpad', null,  editSettings?.hasNumpad, 'hasNumpad')}
                  {switchLine('4 Years warranty upgrade', null, editSettings?.hasWarrantyUpgrade, 'hasWarrantyUpgrade')}
                </div>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <p className='bf-strong'>Security settings</p>
            <Accordion className='accordion-box'>
              <Accordion.Item
                className='office-box'
                title={
                  <div className='accordion-title'>
                    <div className='accordion-title-content'>
                      <p style={{marginRight: 'var(--bfs4)'}}>Client hardening</p>
                      <p>{anyClientHardening()}</p>
                    </div>
                  </div>}
                active={active === 'ClientHardening' || isAllCollapsed}
                onClick={() => handleChange('ClientHardening')}
              >
                <div className='accordion-default-content'>
                  <p className='bf-strong'>Client hardening</p>
                  <p style={{color: 'var(--bfc-base-c-2)'}}>To further secure computers please select desired security options below.</p>
                  <Badge state={"warning"}>These options may trigger additional monthly costs</Badge>
                  {switchLine('AppLocker', 'Only approved applications can run on computers', editSettings?.hasAppLocker, 'hasAppLocker')}
                  {switchLine('Hardened Office', 'Reduces attack surface in MS Office even further (i.e Macros)', editSettings?.hasHardenedOffice, 'hasHardenedOffice')}
                  {switchLine('Hardened browser (Microsoft Edge)', 'Reduces the attack surface in the web browser ever further', editSettings?.hasHardenedBrowser, 'hasHardenedBrowser')}
                  {switchLine('Hardened Windows', 'Reduces the attack surface on the operating system even further', editSettings?.hasHardenedWindows, 'hasHardenedWindows')}
                  {switchLine('BitLocker PIN', 'Secures the computer when shut down, requiring a PIN before startup', editSettings?.hasBitLockerPin, 'hasBitLockerPin')}
                </div>
              </Accordion.Item>
              <Accordion.Item
                className='office-box'
                title={accordionTitle('Biometric authentication', editSettings?.hasBiometricAuth, 'Not selected')}
                active={active === 'BiometricAuth' || isAllCollapsed}
                onClick={() => handleChange('BiometricAuth')}
              >
                <div className='accordion-default-content'>
                  <p className='bf-strong'>Client hardening</p>
                  <p style={{color: 'var(--bfc-base-c-2)'}}>To further secure computers please select desired security options below.</p>
                  {switchLine('Windows Hello for Business', '', editSettings?.hasBiometricAuth, 'hasBiometricAuth')}
                </div>
              </Accordion.Item>
            </Accordion>
          </div>
        </SimpleBox>
      </div>
    </BasicPage>
  );
};


ComputerCustomization.propTypes = {
  setTask: PropTypes.func
}

export default ComputerCustomization;
