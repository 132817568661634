import { Checkbox, Input } from "@intility/bifrost-react";
import {AsyncSelect} from "@intility/bifrost-react-select";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import '../locationEditDrawer.scss';
import { fetchAddress } from "../../../../../../../utils/kartverket";
import NewContact from "../../../../../../NewContact/NewContact";

export const InfoContent = ({ location, editLocation, editLocationChild }) => {
  const [addressOption, setAddressOption] = useState({});

  useEffect(() => {
    setAddressOption({label: `${location?.address}, ${location?.zipCode} ${location?.postalPlace}, ${location?.country}`,
      value: {adressetekst: location?.address, postnummer: location?.zipCode, poststed: location?.postalPlace, country: location?.country}
    })
  }, []);


  function getAsyncOptions(inputValue) {
    return new Promise((resolve, reject) => {
      const addresses = fetchAddress(inputValue).then(r => {
        return r?.adresser?.map(a => ({value: a, label: `${a?.adressetekst}, ${a?.postnummer}, ${a?.poststed}`}));
      }).then((a) => {
        const input = inputValue?.split(", ")
        a.unshift(({value: { adressetekst: input.length > 0 ? input[0] : inputValue, postnummer: input.length > 1 ? input[1] : '', poststed: input.length > 2 ? input[2] : '', country: input.length > 3 ? input[3] : ''}, label: inputValue}))
        return a;
      });
      resolve(addresses)
    })
  }

  const setAddress = (item) => {
    setAddressOption(item);
    editLocation('address', item?.value?.adressetekst || '');
    editLocation('zipCode', item?.value?.postnummer || '');
    editLocation('postalPlace', item?.value?.poststed || '');
    editLocation('country', 'Norway');
  }

  return (
    <div className='info-content'>
      <AsyncSelect
        maxMenuHeight={120}
        value={addressOption}
        loadOptions={inputValue => getAsyncOptions(inputValue)}
        onChange={item => setAddress(item)} label='Address'/>
      <div className='double-row'>
        <Input value={location?.zipCode} label='Zip code' onChange={(e) => editLocation('zipCode', e.target.value)} />
        <Input value={location?.postalPlace} label='Postal place' onChange={(e) => editLocation('postalPlace', e.target.value)} />
      </div>
      <Input value={location?.country} label='Country' onChange={(e) => editLocation('country', e.target.value)} />
      <div className='separator'/>
      <div className='checkbox-row'>
        <p className='bf-strong'>Do you want Intility to manage this location?</p>
        <Checkbox type='radio' label='Yes' onChange={(e) => editLocation('intilityManaged', e.target.checked && 1)} checked={location?.intilityManaged === 1} />
        <Checkbox type='radio' label='No' onChange={(e) => editLocation('intilityManaged', e.target.checked && 2)} checked={location?.intilityManaged === 2} />
      </div>
      <div className='separator'/>
      <div className='info-contact-box'>
        <p className='bf-large'>Location Contact</p>
        <NewContact contact={location?.locationContact} editContact={editLocationChild} validation={false}/>
      </div>
    </div>
  )
}

InfoContent.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
  editLocationChild: PropTypes.func,
};