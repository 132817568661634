import {createAction} from '@reduxjs/toolkit';
import {
  addNewUser,
  addNewWorkplaceDevice,
  deleteMultipleIamsUsers,
  deleteWorkplaceDevice,
  editIamsUser,
  editMultipleIamsUsers,
  normalizeIamsUsers,
  normalizeIamsUsersInformation,
  normalizeIamsUsersList,
  normalizeWorkplaceDevices
} from './iamsUsersNormalizers';

export const fetchIamsUsersListSuccess = createAction('iamsUsersAction/fetchIamsUsersListSuccess', apiIamsUsers => ({
  payload: normalizeIamsUsersList(apiIamsUsers)
}));

export const fetchInformationSuccess = createAction(
  'iamsUsersAction/fetchIamsUsersInformationSuccess',
  apiIamsUsers => ({
    payload: normalizeIamsUsersInformation(apiIamsUsers)
  })
);

export const fetchIamsUsersSuccess = createAction('iamsUsersAction/fetchIamsUsersSuccess', apiIamsUsers => ({
  payload: normalizeIamsUsers(apiIamsUsers)
}));

export const updateIamsUsersSuccess = createAction(
  'iamsUsersAction/updateIamsUsersSuccess',
  (iamsUsers, iamsUserList) => ({
    payload: editMultipleIamsUsers(iamsUserList, iamsUsers)
  })
);

export const updateIamsUserSuccess = createAction(
  'iamsUsersAction/updateIamsUserSuccess',
  (iamsUser, iamsUserList) => ({
    payload: editIamsUser(iamsUser, iamsUserList)
  })
);

export const deleteIamsUsersSuccess = createAction(
  'iamsUsersAction/deleteIamsUsersSuccess',
  (iamsUsers, iamsUserList) => ({
    payload: deleteMultipleIamsUsers(iamsUserList, iamsUsers)
  })
);

export const addIamsUserSuccess = createAction('iamsUsersAction/addIamsUserSuccess', (iamsUser, iamsUserList) => ({
  payload: addNewUser(iamsUser, iamsUserList)
}));

export const fetchWorkplaceDevicesSuccess = createAction(
  'workplaceDevicesAction/fetchWorkplaceDevicesSuccess', apiWorkplaceDevices => ({
  payload: normalizeWorkplaceDevices(apiWorkplaceDevices)
}));

export const deleteWorkplaceDeviceSuccess = createAction(
  'workplaceDevicesAction/deleteWorkplaceDeviceSuccess',
  (deviceId, deviceList) => ({
    payload: deleteWorkplaceDevice(deviceId, deviceList)
  })
);

export const addWorkplaceDeviceSuccess = createAction(
  'workplaceDevicesAction/addWorkplaceDeviceSuccess', (device, deviceList) => ({
  payload: addNewWorkplaceDevice(device, deviceList)
}));

export const updateIamsUsersInformationSuccess = createAction(
  'iamsUsersAction/updateInformationSuccess',
  information => ({
    payload: normalizeIamsUsersInformation(information)
  })
);
