import {faAndroid, faApple} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faArrowUp,
  faBan,
  faCloud,
  faCog,
  faCopy,
  faCut,
  faDesktop,
  faEllipsisH,
  faEnvelope,
  faFingerprint,
  faKey,
  faListOl,
  faLock,
  faSave,
  faSignalSlash,
  faStickyNote,
  faText
} from '@fortawesome/pro-regular-svg-icons';

// front-end

export const recommendedValues = {
  android: {
    requirePasscodeToUnlock: true,
    minimumPasscodeLength: '4',
    requiredPasscodeType: 'NUMERIC',
    inactivityBeforeScreenLock: '1',
    allowRooted: false,
    blockAppsFromUnknown: true,
    minimumAndroidVersion: 'LATESTONE'
  },
  apple: {
    requirePasscodeToUnlock: true,
    minimumPasscodeLength: '4',
    requiredPasscodeType: 'NUMERIC',
    nativeSynchronization: 'Calendar',
    inactivityBeforeScreenLock: '1',
    requireManagedEmailProfile: true,
    minimumIOSVersion: 'LATEST',
    nativeMailSynchronization: 'CalendarAndContactsOnly'
  },
  appConfig: {
    preventAndroidBackups: true,
    requirePin: false,
    allowSimplePin: false,
    minimumPinLength: '4',
    attemptsBeforePinReset: '5',
    allowAppToAppDataTransfer: 'MANAGEDAPPS',
    restrictCopyPasteWithOtherApps: 'MANAGEDAPPS',
    allowAppToReceiveDataFromOtherApps: 'ANYAPP',
    preventSaveAs: true,
    storageServiceForCorporateData: 'BOTH',
    offlineIntervalBeforeAppDataIsWiped: '30'
  }
};

export const compliancePolicies = [
  {
    policy: 'android',
    tabName: 'Android',
    icon: faAndroid,
    text: 'Review your settings',
    description: 'Settings will be applied to all android devices in your company',
    policies: [
      {
        title: 'Require a passcode to unlock mobile devices',
        value: true,
        name: 'requirePasscodeToUnlock',
        icon: faKey,
        description: 'Requires password to unlock devices',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Minimum passcode length',
        value: '4',
        name: 'minimumPasscodeLength',
        icon: faEllipsisH,
        description: 'Specifies the minimum allowed password length',
        options: [
          {
            label: '4',
            value: '4'
          },
          {
            label: '8',
            value: '8'
          }
        ]
      },
      {
        title: 'Required passcode type',
        value: 'NUMERIC',
        name: 'requiredPasscodeType',
        icon: faText,
        description:
          'Specifies wether passwords are allowed to be only numeric or alphanumeric. Complex requirement block simple password sequences, such as 1234 or 1111 ',
        options: [
          {
            label: 'Numeric Complex',
            value: 'NUMERIC'
          },
          {
            label: 'Alphanumeric',
            value: 'ALPHANUMERIC'
          }
        ]
      },
      {
        title: 'Minutes of inactivity before screen is locked',
        value: '1',
        name: 'inactivityBeforeScreenLock',
        icon: faDesktop,
        description:
          'Specifies the length of time without user input which the device is locked. This setting is automatically set during enrollment.',
        options: [
          {
            label: '1',
            value: '1'
          },
          {
            label: '10',
            value: '10'
          },
          {
            label: '15',
            value: '15'
          },
          {
            label: '30',
            value: '30'
          },
          {
            label: 'Never',
            value: 'NEVER'
          }
        ]
      },

      {
        title: 'Allow jailbroken or rooted devices',
        value: false,
        name: 'allowRooted',
        icon: faLock,
        description: 'Prevents jailbroken or rooted devices access to company resources',
        options: [
          {
            label: 'No',
            value: false
          },
          {
            label: 'Yes',
            value: true
          }
        ]
      },
      {
        title: 'Block apps from unknown sources',
        value: true,
        name: 'blockAppsFromUnknown',
        icon: faBan,
        description:
          'This setting specifies whether to block devices with "Security > Unknown Sources" enabled. Enable this compliance policy only if you are not side-loading Android apps.',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Minimum Android version',
        value: 'LATEST -1',
        name: 'minimumAndroidVersion',
        icon: faAndroid,
        description:
          'Latest - 1 at the point of configuration. We are unable to dynamically update this, but can do so at request. Prevents Android version X and previous access to company resources. ',
        options: [
          {
            label: 'Latest',
            value: 'LATEST'
          },
          {
            label: 'Latest-1',
            value: 'LATESTONE'
          },
          {
            label: 'Latest-2',
            value: 'LATESTTWO'
          }
        ]
      }
    ]
  },
  {
    policy: 'apple',
    tabName: 'Apple iOS',
    icon: faApple,
    text: 'Review your settings',
    description: 'Settings will be applied to all iOS devices in your company',
    policies: [
      {
        title: 'Require a passcode to unlock mobile devices',
        value: true,
        name: 'requirePasscodeToUnlock',
        icon: faKey,
        description: 'Requires password to unlock devices',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Native Synchronization',
        value: true,
        name: 'nativeMailSynchronization',
        icon: faBan,
        description:
          'Allow for synchronization to native application (Calendar, Notes, Contacts, E-mail). Our recommendation is to allowed only Calendar and Contacts, and to standardize on Outlook for E-mail. ',
        options: [
          {
            label: 'Block',
            value: 'Block'
          },
          {
            label: 'Calendar And Contacts Only',
            value: 'CalendarAndContactsOnly'
          },
          {
            label: 'All data',
            value: 'AllData'
          },
          {
            label: 'Calender only',
            value: 'CalenderOnly'
          },
          {
            label: 'Email only',
            value: 'EmailOnly'
          },
          {
            label: 'Contacts only',
            value: 'ContactsOnly'
          }
        ]
      },
      {
        title: 'Minimum passcode length',
        value: '4',
        name: 'minimumPasscodeLength',
        icon: faEllipsisH,
        description: 'Specifies the minimum allowed password length',
        options: [
          {
            label: '4',
            value: '4'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '8',
            value: '8'
          }
        ]
      },
      {
        title: 'Required passcode type',
        value: 'NUMERIC',
        name: 'requiredPasscodeType',
        icon: faText,
        description:
          'Specifies wether passwords are allowed to be only numeric, or wether they must contain characters other than numbers',
        options: [
          {
            label: 'Alphanumeric',
            value: 'ALPHANUMERIC'
          },
          {
            label: 'Numeric Complex',
            value: 'NUMERIC'
          }
        ]
      },
      {
        title: 'Minutes of inactivity before screen is locked',
        value: '1',
        name: 'inactivityBeforeScreenLock',
        icon: faDesktop,
        description: 'Specifies the length of time without user input which the device is locked',
        options: [
          {
            label: 'Immediately',
            value: 'IMMEDIATELY'
          },
          {
            label: '1',
            value: '1'
          },
          {
            label: '10',
            value: '10'
          },
          {
            label: '15',
            value: '15'
          },
          {
            label: '60',
            value: '60'
          },
          {
            label: '240',
            value: '240'
          }
        ]
      },

      {
        title: 'Require mobile devices to have a managed email profile',
        value: true,
        name: 'requireManagedEmailProfile',
        icon: faEnvelope,
        description:
          'Intune can push the e-mail configuration to Apple Mail. We are then able to delete access and data, but unable to apply App Protection (only available for Outlook). Enforce Outlook for further control. ',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Minimum iOS version',
        value: 'LATEST',
        name: 'minimumIOSVersion',
        icon: faCog,
        description:
          'Latest at the point of setup. We are unable to dynamically update this, but can do so at request. Prevents iOS version X and previous access to company resources. ',
        options: [
          {
            label: 'Latest',
            value: 'LATEST'
          },
          {
            label: 'Latest-1',
            value: 'LATESTONE'
          },
          {
            label: 'Latest-2',
            value: 'LATESTTWO'
          }
        ]
      }
    ]
  },
  {
    policy: 'appConfig',
    tabName: 'App Config',
    icon: faCog,
    text: 'Review your settings',
    description: 'Please specify the App Config policies. This applies to both iOS and Android devices',
    policies: [
      {
        title: 'Prevent backup (Android & iOS)',
        value: true,
        name: 'preventAndroidBackups',
        icon: faSave,
        description:
          'Select Yes to disable, or No to allow backup of any information from the app (iCloud Backup, for instance)',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Require PIN for Access',
        value: false,
        name: 'requirePin',
        icon: faFingerprint,
        description:
          'Select Yes to require a PIN number to use this app. The user is prompted to set this up the first time they run the app in a work context',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Allow Simple PIN',
        value: false,
        name: 'allowSimplePin',
        icon: faStickyNote,
        description: 'This setting specifies wether to allow users to use simple PIN sequences such as 1234 or 1111',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Minimum PIN Length',
        value: '4',
        name: 'minimumPinLength',
        icon: faEllipsisH,
        description: 'This settings specifies the minimum number of digits in a PIN. Only relevant if PIN is required.',
        options: [
          {
            label: '4',
            value: '4'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '10',
            value: '10'
          }
        ]
      },
      {
        title: 'Number of attempts before PIN reset',
        value: '5',
        name: 'attemptsBeforePinReset',
        icon: faListOl,
        description:
          'Specify the number of failed PIN entry attempts that can be made before the user must reset the PIN. Only relevant if PIN is required.',
        options: [
          {
            label: '5',
            value: '5'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '10',
            value: '10'
          }
        ]
      },
      {
        title: 'Allow apps to transfer data to other apps',
        value: 'MANAGEDAPPS',
        name: 'allowAppToAppDataTransfer',
        icon: faArrowUp,
        description:
          'Specifies which apps this app can transfer data to. "Any app" include personal/un-managed applications. ',
        options: [
          {
            label: 'Policy Managed Apps',
            value: 'MANAGEDAPPS'
          },
          {
            label: 'Any App',
            value: 'ANYAPP'
          },
          {
            label: 'No',
            value: 'NO'
          }
        ]
      },
      {
        title: 'Restrict cut, copy and paste with other apps',
        value: 'MANAGEDAPPS',
        name: 'restrictCopyPasteWithOtherApps',
        icon: faCut,
        description:
          'Specifies how cut, copy and paste can be used with the app. "Paste in" means that you allow data from any app to be pasted into this app. ',
        options: [
          {
            label: 'Any app',
            value: 'ANYAPP'
          },
          {
            label: 'Policy Managed Apps',
            value: 'MANAGEDAPPS'
          },
          {
            label: 'No',
            value: 'NO'
          }
        ]
      },
      {
        title: 'Allow app to receive data from other apps',
        value: 'ANYAPP',
        name: 'allowAppToReceiveDataFromOtherApps',
        icon: faArrowDown,
        description:
          'Specifies which apps Policy Managed Apps can receive data from. "Any app" include personal/un-managed applications. ',
        options: [
          {
            label: 'Any app',
            value: 'ANYAPP'
          },
          {
            label: 'Policy Managed Apps',
            value: 'MANAGEDAPPS'
          },
          {
            label: 'No',
            value: 'NO'
          }
        ]
      },
      {
        title: 'Prevent "Save As"',
        value: true,
        name: 'preventSaveAs',
        icon: faCopy,
        description:
          'Disables the "Save As" option in the app. Exceptions to OneDrive for Business and SharePoint can be set.',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Select which storage services corporate data can be saved to',
        value: 'BOTH',
        name: 'storageServiceForCorporateData',
        icon: faCloud,
        description: 'Users will be able to save to the selected services - all other services will be blocked',
        options: [
          {
            label: 'Both',
            value: 'BOTH'
          },
          {
            label: 'One Drive for Business',
            value: 'OneDriveForBusiness'
          },
          {
            label: 'SharePoint',
            value: 'SharePoint'
          },
          {
            label: 'No',
            value: 'NO'
          }
        ]
      },

      {
        title: 'Offline interval before app data is wiped (days)',
        value: '30',
        name: 'offlineIntervalBeforeAppDataIsWiped',
        icon: faSignalSlash,
        description:
          'You can specify a number of days that a device can be offline before company data is wiped from the device',
        options: [
          {
            label: '10',
            value: '10'
          },
          {
            label: '15',
            value: '15'
          },
          {
            label: '30',
            value: '30'
          },
          {
            label: '60',
            value: '60'
          },
          {
            label: '90',
            value: '90'
          }
        ]
      }
    ]
  }
];
