import './favoriteCard.scss';
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "@intility/bifrost-react";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import OverviewProgress from "../CompanyOverview/OverviewProgress";
import { Link } from "react-router-dom";

export const getShortName = (name, length) => {
  if (name?.length > length) {
    return name?.toString().slice(0, length) + '...';
  }
  return name;
};

export const FavoriteCard = ({favorite, toggleFavorite}) => {

  return (
    <div className='overview-favorite-card'>
      <div className='card-header'>
        <div>
          <Icon icon={faStar} onClick={toggleFavorite} style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}} />
          <p className='bf-large'>{getShortName(favorite?.name, 15)}</p>
        </div>
        <OverviewProgress company={favorite} size={'small'}/>
      </div>
      <div className='card-body'>
        <div>
          <Link className='bf-title-link bf-strong' to={'/' + favorite.id + '/'}>
            Onboard
          </Link>
        </div>
        <div>
          <Link className='bf-title-link bf-strong' to={'details/' + favorite.id + '/information/'}>
            Read more
          </Link>
        </div>
      </div>
    </div>
  )

}

FavoriteCard.propTypes = {
  favorite: PropTypes.object,
  toggleFavorite: PropTypes.func
}

export default FavoriteCard
