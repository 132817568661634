import {
  Badge, Button,
  Checkbox,
  Icon,
  Label,
  Message,
  Tooltip,
  useFloatingMessage
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import './newApplication.scss';
import PropTypes from 'prop-types';
import {
  fetchApplicationsAsync, toggleApplicationAsync,
  updateApplicationAsync, uploadApplicationFilesAsync
} from "../../../../../redux/company/applications/applicationThunks";
import {faAngleLeft, faTimes, faTrashAlt} from '@fortawesome/pro-light-svg-icons';
import React, {useEffect, useState} from 'react';
import devLog from '../../../../../utils/devLog';
import {
  updateApplicationsError
} from '../../../../../redux/company/applications/applicationReducers';
import {useDispatch, useSelector} from 'react-redux';
import { Application, Detail } from "../../../../../utils/hub/models";
import produce from 'immer';
import NewContact from './NewContent/NewContact';
import NewIntegration from './NewContent/NewIntegration';
import InputWithFeedback, {InputType} from '../../../../InputWithFeedback/InputWithFeedback';
import {
  appOptions,
  deliveryOptions, environmentOptions,
  identityOptions,
  ssoOptions,
  userReadyStatusOptions
} from "../ApplicationOptions";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { faFile, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { fetchUsersAsync } from "../../../../../redux/company/users/userThunks";
import { fetchLocationsAsync } from "../../../../../redux/company/locations/locationThunks";
import { ApplicationBoxItem } from "../../../../BoxItem/ApplicationBoxItem";
import { ApplicationFileUploader } from "../../../../FileUploader/FileUploader";
import { updateDocumentationClear } from "../../../../../redux/company/documentation/documentationReducers";
import { DocumentationError, ErrorDropdown } from "../../Users/ErrorModal/ErrorModal";
import {
  deleteDocumentationAsync,
  fetchDocumentationAsync
} from "../../../../../redux/company/documentation/documentationThunks";
import apiFetch from "../../../../../utils/apiFetch";
import { InternalContacts, VendorContacts } from "./ContactList";
import { AppIntegrations } from "./AppIntegrations";
import { downloadFile } from "../../../../../utils/downloadFile";

export const EditApplication = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {applicationList, applicationStatus, information} = useSelector(state => state.company.data.applicationPlatform);
  const {documentList, documentationStatus} = useSelector(
    state => state.company.data.documentationPlatform
  );
  const [errorOpen, setErrorOpen] = useState(false);
  const {user} = useSelector(state => state.auth);
  const {applicationId} = useParams();
  const [application, setApplication] = useState(new Application());
  const [editing, setEditing] = useState(false);
  const [auth, setAuth] = useState([]);
  const [environment, setEnvironment] = useState([]);
  const [softwareLicense, setSoftwareLicense] = useState({value: '', label: ''});
  const [readyStatus, setReadyStatus] = useState({value: '', label: ''});
  const [applicationType, setApplicationType] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [baseApplication, setBaseApplication] = useState(new Application());
  const [sharefile, setSharefileLink] = useState(new Detail());
  const [contactOpen, setContactOpen] = useState(false);
  const [contactType, setContactType] = useState(undefined);
  const [editContact, setEditContact] = useState(undefined);
  const [edited, setEdited] = useState(false);
  const [addedElement, setAddedElement] = useState(false);
  const [integrationOpen, setIntegrationOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { showFloatingMessage } = useFloatingMessage();

  const onApplicationChange = (value, name) => {
    setApplication(
      produce(draft => {
        draft[name] = value;
      })
    );
    setEdited(true);
  };

  useEffect(() => {
    if (id) {
      apiFetch(`/companies/${id}/details/single?name=SharefileLink`)
        .then(response => {
          if (!response.ok) {
            setSharefileLink(new Detail());
            return [];
          }
          return response.json()
        })
        .then(detail => setSharefileLink(detail));
    }
  }, [id, dispatch])

  useEffect(() => {
    setErrorOpen(documentationStatus.uploadError);
  }, [documentationStatus.uploadError]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchApplicationsAsync(id));
    dispatch(fetchUsersAsync(id));
    dispatch(fetchLocationsAsync(id));
    dispatch(fetchDocumentationAsync(id));
  }, [user, dispatch, id]);

  const onCheckChange = e => {
    setApplicationType(e);
    setApplication(
      produce(draft => {
        draft.serverApp = e.find(a => a.value === 'serverApp') ? 1 : 0;
        draft.clientApp = e.find(a => a.value === 'clientApp') ? 1 : 0;
        draft.saasApp = e.find(a => a.value === 'saasApp') ? 1 : 0;
      })
    );
    setEdited(true);
  };

  const onDeliveryChange = e => {
    setDeliveryMethod(e);
    setApplication(
      produce(draft => {
        draft.clientApp = e.find(a => a.value === 'clientApp') ? 1 : 0;
        draft.browserAccess = e.find(a => a.value === 'browserAccess') ? 1 : 0;
        draft.virtualClient = e.find(a => a.value === 'virtualClient') ? 1 : 0;
      })
    );
    setEdited(true);
  };

  const getAppReadyStatus = (status, link) => {
    switch (status) {
      case 0:
        return <Badge state={"warning"}>Missing info</Badge>
      case 1:
        return <Badge state={"success"}>Ready</Badge>
      case 2:
        if (link) return <Badge state={"warning"}>Need installation files (<a href={sharefile.stringValue} rel='noopener noreferrer' target='_blank'>Link</a>)</Badge>
        return <Badge state={"warning"}>Need files</Badge>
      case 3:
        return <Tooltip content='Need files in order to onboard the application. If found, upload here and change status to ready.'><Badge state={"alert"}>Files not available</Badge></Tooltip>
    }
  };

  const onRelevantChange = (e, name, enableValue, disableValue) => {
    const {checked} = e.target;

    setApplication(
      produce(draft => {
        if (name === 'deliveryMethod') {
          draft.browserAccess = checked === true ? disableValue : enableValue;
          draft.virtualClient = checked === true ? disableValue : enableValue;
          draft.clientApp = checked === true ? disableValue : enableValue;
        } else {
          draft[name] = checked === true ? disableValue : enableValue;
        }
      })
    );
    if (name === 'identity') setAuth([]);
    if (name === 'environment') setEnvironment([]);
    if (name === 'deliveryMethod') setDeliveryMethod([]);
    setEdited(true);
  };

  useEffect(() => {
    if (applicationId !== undefined && applicationId !== null && applicationId !== false) {
      const newApplication = applicationList?.find(app => app?.id === applicationId);
      if (newApplication !== undefined && edited === false) {
        setApplication(newApplication);
        setBaseApplication({...newApplication});
        setDropdowns(newApplication);
      }
      if (application !== undefined && edited === true) {
        setApplication(produce(application, draft => {
          draft.vendorContacts = newApplication?.vendorContacts
          draft.internalContacts = newApplication?.internalContacts
          draft.childApplications = newApplication?.childApplications
          draft.parentApplications = newApplication?.parentApplications
        }));
        setBaseApplication(produce(baseApplication, draft => {
          draft.vendorContacts = newApplication?.vendorContacts
          draft.internalContacts = newApplication?.internalContacts
          draft.childApplications = newApplication?.childApplications
          draft.parentApplications = newApplication?.parentApplications
        }));
      }
    } else {
      setApplication(new Application());
    }
  }, [applicationId, applicationList]);

  const setDropdowns = (application) => {
    const appArr = [];
    application?.clientApp === 1 && appArr.push({value: 'clientApp', label: 'Client'})
    application?.serverApp === 1 && appArr.push({value: 'serverApp', label: 'Server'})
    application?.saasApp === 1 && appArr.push({value: 'saasApp', label: 'SaaS'})
    setApplicationType(appArr);
    const deliveryArr = [];
    application?.clientApp === 1 && deliveryArr.push({value: 'clientApp', label: 'Local client'})
    application?.browserAccess === 1 && deliveryArr.push({value: 'browserAccess', label: 'Browser access'})
    application?.virtualClient === 1 && deliveryArr.push({value: 'virtualClient', label: 'Virtual client (Citrix, VDI, etc.)'})
    setDeliveryMethod(deliveryArr);
    const identityValues = application?.identity?.split(',');
    const identityArr = [];
    if (identityValues?.includes('azureAd')) identityArr.push({value: 'azureAd', label: 'Entra ID / ADFS'});
    if (identityValues?.includes('activeDirectory')) identityArr.push({value: 'activeDirectory', label: 'Active directory'});
    if (identityValues?.includes('appSpecificUser')) identityArr.push({value: 'appSpecificUser', label: 'App specific user'});
    setAuth(identityArr);
    const environmentValues = application?.environment?.split(',');
    const environmentArr = [];
    if (environmentValues?.includes('prod')) environmentArr.push({value: 'prod', label: 'Production'});
    if (environmentValues?.includes('dev')) environmentArr.push({value: 'dev', label: 'Development'});
    if (environmentValues?.includes('test')) environmentArr.push({value: 'test', label: 'Testing'});
    setEnvironment(environmentArr);
    setReadyStatus({value: application?.readyStatus, label: getAppReadyStatus(application?.readyStatus)});
    application?.softwareLicense === 1 ? setSoftwareLicense(  {value: 'Yes', label: 'Yes'}) : setSoftwareLicense({value: 'No', label: 'No'})
  }

  const handleFileUpload = (e) => {
    const filesAreAttached = e.target.value?.length > 0;
    if (filesAreAttached) {
      dispatch(uploadApplicationFilesAsync(applicationId, e.target.value));
      setAddedElement(true);
    }
  };

  const onSave = () => {
    devLog('Updating!', application);
    if (edited) {
      dispatch(updateApplicationAsync(application)).then(s => {
        s?.type === updateApplicationsError?.type && showFloatingMessage(`Error occurred, please try again.`, {state: 'alert'});
      });
    }
    setEditing(false);
    setAddedElement(false);
    setEdited(false);
  };

  return (
    <div className='content'>
      <div className='content-main'>
        <div className='edit-application-form'>
          <div style={{display: 'flex'}}>
            <NavLink className='bf-link' to={`/${id}/applications`}>
              <p style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}>
                <Icon icon={faAngleLeft} /> Applications
              </p>
            </NavLink>
          </div>
          <div className='edit-application-header'>
            <h2>{application?.name}</h2>
            <div className='button-row'>
              {editing ?
                <Button
                  variant='outline'
                  state='alert'
                  disabled={!information.isEnabled}
                  onClick={() => {
                    dispatch(toggleApplicationAsync(applicationId));
                    history.push(`/${information.companyId}/applications`);
                  }}
                  icon={faTimes}
                >
                  Deactivate
                </Button>
                :
                <Button
                  variant='filled'
                  disabled={!information.isEnabled}
                  onClick={() => setEditing(true)}
                >
                  Edit application
                </Button>
              }
              {editing && (
                <div>
                  <Button
                    icon={faUndo}
                    onClick={() => {
                      setApplication(baseApplication);
                      setDropdowns(baseApplication);
                      setEdited(false);
                      setEditing(false);
                    }}
                    key={0}
                  >
                    {edited ? 'Undo changes' : 'cancel'}
                  </Button>
                  <Button variant='filled' disabled={!edited && !addedElement} onClick={() => onSave()} key={1}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className='edit-application-app'>
            {editing ?
              <div className='bfl-grid edit-application-app-top'>
                <InputWithFeedback
                  onChange={e => onApplicationChange(e.target.value, 'name')}
                  value={application?.name}
                  label='Application name'
                  disabled={(application.origin === 2 || application.origin === 3)}
                  required={true}
                />
                <div>
                  <InputWithFeedback
                    onChange={e => onApplicationChange(e.target.value, 'description')}
                    value={application?.description}
                    label='Nickname'
                    disabled={application?.description === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.description === 'N/A'} onChange={(e) => onRelevantChange(e,'description', '', 'N/A')}/>
                </div>
                <InputWithFeedback
                  onChange={e => onApplicationChange(e.target.value, 'vendor')}
                  value={application?.vendor}
                  checkMissingValue={true}
                  label='Vendor'
                  required={true}
                />
                <div>
                  <InputWithFeedback
                    onChange={e => onApplicationChange(e.target.value, 'newVersion')}
                    value={application?.newVersion}
                    checkMissingValue={application?.newVersion !== 'N/A'}
                    label='New version'
                    disabled={application?.newVersion === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.newVersion === 'N/A'} onChange={(e) => onRelevantChange(e,'newVersion', '', 'N/A')}/>
                </div>
                <div>
                  <InputWithFeedback
                    onChange={e => onApplicationChange(e.target.value, 'currentVersion')}
                    value={application?.currentVersion}
                    checkMissingValue={application?.currentVersion !== 'N/A'}
                    label='Current version'
                    disabled={application?.currentVersion === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.currentVersion === 'N/A'} onChange={(e) => onRelevantChange(e,'currentVersion', '', 'N/A')}/>
                </div>
                <Select
                  label={'Application type'}
                  isMulti={true}
                  options={appOptions}
                  value={applicationType}
                  onChange={(e) => onCheckChange(e)}
                />
                <div>
                  <Select
                    label={'Delivery method to end-users'}
                    isMulti={true}
                    options={deliveryOptions}
                    value={deliveryMethod}
                    state={(deliveryMethod.length > 0 || application?.browserAccess === 2 && application?.virtualClient === 2 && application?.clientApp === 2) ? 'default' : 'alert'}
                    feedback={(deliveryMethod.length > 0 || application?.browserAccess === 2 && application?.virtualClient === 2 && application?.clientApp === 2) ? '' : 'Missing value'}
                    onChange={(e) => onDeliveryChange(e)}
                    isDisabled={application?.browserAccess === 2 && application?.virtualClient === 2 && application?.clientApp === 2}
                  />
                  <Checkbox label='Not relevant' checked={application?.browserAccess === 2 && application?.virtualClient === 2 && application?.clientApp === 2} onChange={(e) => onRelevantChange(e, 'deliveryMethod', 0, 2)}/>
                </div>
                <div>
                  <Select
                    options={identityOptions}
                    label='Log in identity'
                    value={auth}
                    isMulti={true}
                    state={(auth.length > 0 || application?.identity === 'N/A') ? 'default' : 'alert'}
                    feedback={(auth.length > 0 || application?.identity === 'N/A') ? '' : 'Missing value'}
                    onChange={e => {
                      setAuth(e);
                      const values = e.map(v => v.value);
                      if (values?.includes('azureAd') || values?.includes('activeDirectory')) {
                        onApplicationChange('Yes', 'singleSignOnToday')
                      } else {
                        onApplicationChange('No', 'singleSignOnToday')
                      }
                      onApplicationChange(e.map(i => i.value).join(','), 'identity');
                    }}
                    isDisabled={application?.identity === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.identity === 'N/A'} onChange={(e) => onRelevantChange(e,'identity', '', 'N/A')}/>
                </div>
                <Select
                  options={ssoOptions}
                  label='Software license'
                  value={softwareLicense}
                  onChange={e => {
                    setSoftwareLicense(e)
                    onApplicationChange(e.value, 'softwareLicense')
                  }}
                />
                <div>
                  <InputWithFeedback
                    onChange={e => onApplicationChange(e.target.value, 'url')}
                    value={application?.url}
                    checkMissingValue={application?.url !== 'N/A'}
                    label='Url'
                    disabled={application?.url === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.url === 'N/A'} onChange={(e) => onRelevantChange(e,'url', '', 'N/A')}/>
                </div>
                <div>
                  <Select
                    options={environmentOptions}
                    label='Application environment'
                    value={environment}
                    isMulti={true}
                    state={(environment.length > 0 || application?.environment === 'N/A') ? 'default' : 'alert'}
                    feedback={(environment.length > 0 || application?.environment === 'N/A') ? '' : 'Missing value'}
                    onChange={e => {
                      setEnvironment(e);
                      onApplicationChange(e.map(i => i.value).join(','), 'environment');
                    }}
                    isDisabled={application?.environment === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.environment === 'N/A'} onChange={(e) => onRelevantChange(e,'environment', '', 'N/A')}/>
                </div>
                <div>
                  <InputWithFeedback
                    onChange={e => onApplicationChange(e.target.value, 'location')}
                    value={application?.location}
                    label='Location'
                    disabled={application?.location === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.location === 'N/A'} onChange={(e) => onRelevantChange(e,'location', '', 'N/A')}/>
                </div>
                {(baseApplication?.readyStatus === 2 || baseApplication?.readyStatus === 3) &&
                  <Select label='File status' options={userReadyStatusOptions} value={readyStatus}
                          onChange={e => {
                            setReadyStatus(e);
                            onApplicationChange(e.value, 'readyStatus');
                          }} />
                }
              </div>
              :
              <div className='edit-application-app-top-container'>
                <div className='bfl-grid edit-application-app-top'>
                  <ApplicationBoxItem name='Application name' value={application?.name}/>
                  <ApplicationBoxItem name='Nickname' value={application?.description || true}/>
                  <ApplicationBoxItem name='Vendor' value={application?.vendor}/>
                  <ApplicationBoxItem name='New version' value={application?.newVersion}/>
                  <ApplicationBoxItem name='Current version' value={application?.currentVersion}/>
                  <ApplicationBoxItem name='Application type' value={applicationType.map(a => a.label).join(', ')}/>
                  <ApplicationBoxItem name='Delivery method to end-users' value={(application?.browserAccess === 2 && application?.virtualClient === 2 && application?.clientApp === 2) ? 'N/A' : deliveryMethod.map(a => a.label).join(', ')}/>
                  <ApplicationBoxItem name='Log in identity' value={auth.length > 0 ? auth.map(a => a.label).join(', ') : application?.identity}/>
                  <ApplicationBoxItem name='Does the app require a license?' value={application?.softwareLicense === 1 ? 'Yes' : 'No'}/>
                  <ApplicationBoxItem name='Is the app accessible via url?' value={application?.url}/>
                  <div id='application-box-item'>
                    <Label>Application environment</Label>
                    {application?.environment ?
                      application?.environment?.length > 0 ? application?.environment?.split(',').map((b, i) => <Badge key={i} style={{marginRight: 'var(--bfs4)'}}>{b}</Badge>) : ''
                      :
                      <Badge state={"warning"}>Missing information</Badge>
                    }
                  </div>
                  <ApplicationBoxItem name='Location' value={application?.location || true}/>
                  {baseApplication?.readyStatus === 3 &&
                    <div>
                      <Label>File status</Label>
                      <Message state='alert'>
                        We need installation files in order to onboard this application. If found, upload <a href={sharefile.stringValue} rel='noopener noreferrer' target='_blank'>here</a> and change status to ready.
                      </Message>
                    </div>

                  }
                  {baseApplication?.readyStatus === 2 &&
                    <ApplicationBoxItem name='File status' value={getAppReadyStatus(application?.readyStatus, true)}/>
                  }
                </div>
                <div>
                  <Label>Documentation</Label>
                  <div className='edit-application-app-documentation'>
                    {documentList.filter(f => (f.bucketName.startsWith(applicationId) && f.origin === 1)).map(file => {
                      const fileNameRegex = /\/(.*)/i;
                      const fileName = file.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'

                      return (
                        <span key={file.objectName}>
                          <span>
                            <Icon icon={faFile}/>
                            <p className='bf-link' onClick={() => downloadFile(file, `/companies/${id}/documentation/${file.id}/file`)}>
                              {fileName}
                            </p>
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            }
            {editing ?
              <div className='edit-application-app-bottom'>
                <div>
                  <InputWithFeedback
                    onChange={e => onApplicationChange(e.target.value, 'comment')}
                    maxLength={4000}
                    value={application?.comment}
                    checkMissingValue={application?.comment !== 'N/A'}
                    label='Add a description / comment'
                    description='Please provide us with a brief system description'
                    type={InputType.TextArea}
                    disabled={application?.comment === 'N/A'}
                  />
                  <Checkbox label='Not relevant' checked={application?.comment === 'N/A'} onChange={(e) => onRelevantChange(e,'comment', '', 'N/A')}/>
                </div>
                <div className='application-file-upload'>
                  <Label>Documentation</Label>
                  <p style={{color: 'var(--bfc-base-c-2)'}}>Upload relevant application documents, installation guides, topology drawings, etc.</p>
                  <div className='bfl-grid'>
                    <ApplicationFileUploader
                      multiple={true}
                      buttonText='Upload files'
                      onChange={handleFileUpload}
                      validContentTypes={['.pdf', '.jpeg', '.png', '.jpg', '.docx', '.xlsx', '.xls','.pptx', '.txt', '.vsdx']}
                    />
                    <div className='application-file-list'>
                      {documentList.filter(f => (f.bucketName.startsWith(applicationId) && f.origin === 1)).map(file => {
                        const fileNameRegex = /\/(.*)/i;
                        const fileName = file.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'

                        return (
                          <span key={file.objectName}>
                            <span>
                              <Icon icon={faFile}/>
                              <p className='bf-link' onClick={() => downloadFile(file, `/companies/${id}/documentation/${file.id}/file`)}>
                                {fileName}
                              </p>
                            </span>
                            <Icon icon={faTrashAlt} onClick={() => dispatch(deleteDocumentationAsync(file.id))} />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className='edit-application-app-bottom'>
                <ApplicationBoxItem name='Add a description, a comment or ask a question to Intility' value={application?.comment}/>
              </div>
            }
          </div>

          {applicationId !== undefined && applicationId !== null && applicationId !== false ? (
            <div className='bfl-grid contacts-container'>
              <VendorContacts
                contacts={application?.vendorContacts}
                relevant={application?.vendorStatus}
                setEditContact={setEditContact}
                setOpen={setContactOpen}
                setType={setContactType}
                onRelevantChange={onRelevantChange}
                editing={editing}
              />
              <InternalContacts
                contacts={application?.internalContacts}
                relevant={application?.internalStatus}
                setEditContact={setEditContact}
                setOpen={setContactOpen}
                setType={setContactType}
                onRelevantChange={onRelevantChange}
                editing={editing}
              />
              <AppIntegrations
                appName={application?.name}
                relevant={application?.integrationStatus}
                appId={applicationId}
                childApps={application?.childApplications}
                parentApps={application?.parentApplications}
                setOpen={setIntegrationOpen}
                onRelevantChange={onRelevantChange}
                editing={editing}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <NewContact
        applicationId={applicationId}
        editContact={editContact}
        setEditContact={setEditContact}
        contactOpen={contactOpen}
        setIsOpen={setContactOpen}
        type={contactType}
        appStatus={applicationStatus}
        setAddedElement={setAddedElement}
      />
      <NewIntegration
        open={integrationOpen}
        setIsOpen={setIntegrationOpen}
        applicationId={applicationId}
        appStatus={applicationStatus}
        setAddedElement={setAddedElement}
      />
      <ErrorDropdown
        isOpen={errorOpen}
        onRequestClose={() => dispatch(updateDocumentationClear())}
        type={DocumentationError}
      />
    </div>
  );
};

EditApplication.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  applicationId: PropTypes.string,
  setApplicationId: PropTypes.func,
  newAppType: PropTypes.string,
  setNewAppType: PropTypes.func
};

export default EditApplication;
