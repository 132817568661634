import './googleMapsPane.scss';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DefaultImage from "../../../../../../assets/images/location/ImagecardDef.svg";
import { fetchLocationImage } from "../../../../../../utils/wallpapers";

export const imagePane = (location) => {
  const [locationImage, setLocationImage] = useState();

  useEffect(() => {
    let isCancelled = false;
    fetchLocationImage(location).then(r => {
      if (!isCancelled) setLocationImage(r);
    });
    return () => {
      isCancelled = true;
    };
  }, [location?.locationImage?.objectName]);

  return (
    !locationImage?.imageUri ? DefaultImage : locationImage.imageUri
  )

}

imagePane.propTypes = {
  location: PropTypes.object
}

