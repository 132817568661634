import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  Icon,
  Spinner,
  Table,
  dateFormat, Checkbox
} from "@intility/bifrost-react";
import PropTypes from 'prop-types';
import {faEllipsisH} from '@fortawesome/pro-solid-svg-icons';
import BoxItem from '../../../../BoxItem/BoxItem';
import { faCheck, faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  toggleApplicationAsync, updateApplicationAsync
} from "../../../../../redux/company/applications/applicationThunks";
import { useDispatch, useSelector } from "react-redux";
import { getOrigin, PortfolioApps } from "../Applications";
import { NavLink } from "react-router-dom";
import produce from "immer";
import { ApplicationRowOptions } from "../ApplicationOptions";

export const ToggleApp = (application, isChecked, dispatch) => {
  if (isChecked && application.inPortfolio === 0) {
    const app = produce(application, draft => {
      draft.url = 'N/A';
      draft.identity = 'N/A';
      draft.browserAccess = 2;
      draft.virtualClient = 2;
      draft.comment = 'N/A';
      draft.vendorStatus = 2;
      draft.internalStatus = 2;
      draft.integrationStatus = 2;
      draft.appSpecificCred = 2;
      draft.readyStatus = 1;
      draft.isEnabled = true;
      draft.inPortfolio = 1;
    });
    dispatch(updateApplicationAsync(app));
  } else if (isChecked && application.inPortfolio === 2) {
    const app = produce(application, draft => {
      draft.isEnabled = true;
      draft.inPortfolio = 1;
    });
    dispatch(updateApplicationAsync(app));
  } else if (isChecked) {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 0;
    });
    dispatch(updateApplicationAsync(app));
  } else if (!isChecked && application.inPortfolio === 1) {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 2;
    });
    dispatch(updateApplicationAsync(app));
  } else if (!isChecked && application.inPortfolio === 0) {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 2;
    });
    dispatch(updateApplicationAsync(app));
  } else {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 0;
    });
    dispatch(updateApplicationAsync(app));
  }
};

export const getResponsibility = (e) => {
  switch (e) {
    case 0:
      return 'Customer';
    case 1:
      return 'Intility';
    case 2:
      return 'Customer';
    case 3:
      return 'Intility';
    case 4:
      return 'Intility';
    case 5:
      return 'Intility';
    default:
      return '';
  }
};

const getValue = (e) => {
  return e === 1 ? <Icon icon={faCheck}/> : '-';
}

const getBoxItem = (application, i, r, className) => {
  if (r.value === 'environment') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={application?.environment?.length > 0 ? application?.environment?.split(',').map((b, i) => <Badge key={b} style={{marginRight: 'var(--bfs4)'}}>{b}</Badge>) : ''} label='Environment' />
    )
  } else if (r.value === 'serverApp' || r.value === 'clientApp' || r.value === 'saasApp' || r.value === 'virtualClient') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={getValue(application[r.value])} label={r.label} />
    )
  } else if (r.value === 'singleSignOnToday') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={getValue(application[r.value] === 'Yes' ? 1 : 0)} label={r.label} />
    )
  } else if (r.value === 'softwareId') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={application[r.value] > 0 ? 'IAMS' : 'User added'} label={r.label} />
    )
  } else if (r.value === 'readyStatus') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={getResponsibility(application[r.value])} label={r.label} />
    )
  } else if (r.value === 'origin') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={getOrigin(application[r.value])} label={r.label} />
    )
  } else if (r.value === 'responsible') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={getResponsibility(application.readyStatus)} label={r.label} />
    )
  } else if (r.value === 'companyUsers') {
    return;
  } else if (r.value === 'modifiedAt') {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={application?.modifiedAt ? dateFormat('nb-no', new Date(application.modifiedAt), {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }) : 'Not modified'} label={r.label} />
    )
  } else {
    return (
      <BoxItem key={i} className={className ? className : ''} customDefault={<Badge state={"warning"}>Missing info</Badge>} value={application[r.value]} label={r.label} />
    )
  }
}

const ApplicationBox = ({application, active, setApplicationId, isComplete, openUserSlider, tableRows}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {seedingUsers} = useSelector(
    state => state.company.data.applicationPlatform.applicationStatus);
  const [isChecked, setIsChecked] = useState(application.isEnabled);
  const [status, setStatus] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === undefined) {
      const badge = application.readyStatus === 0 ? (<Badge state='warning'>Missing info</Badge>) : (<Badge state={"success"}>Ready</Badge>)
      setStatus({ value: application.readyStatus, label: badge})
    }
  }, [application])

  const openUserDrawer = () => {
    setApplicationId(application.id);
    openUserSlider(true);
  };

  const getReadyStatusBadge = (e) => {
    switch (e) {
      case 0:
        return <Badge state='warning'>Missing info</Badge>;
      case 1:
        return <Badge state={"success"}>Ready</Badge>;
      case 2:
        return <Badge state='warning'>Need files</Badge>;
      case 3:
        return <Badge state='warning'>Files not available</Badge>;
      case 4:
        return <Badge state='chill'>Awaiting review</Badge>;
      case 5:
        return <Badge state='warning'>Packaging</Badge>;
      default:
        return '';
    }
  }

  return (
    <Table.Row
      className='joban'
      content={
      <div className='application-row-dropdown'>
        {!tableRows.find(i => i.value === 'companyUsers') ?
          <div className='button-element'>
            {(application.origin === 3 && application.name !== 'Make me admin') ?
              <p className='bf-em' style={{ color: 'var(--bfc-base-c-2)', margin: '5px 0' }}>All users</p>
              :
              <Button style={{ minWidth: '136px' }}
                      onClick={() => openUserDrawer()}>{seedingUsers ? <Spinner size={14}/> : application.companyUsers?.filter(u => u.isDisabled === false)?.length || '0'} users</Button>
            }
          </div>
          :
          <div/>
        }
        <div className='application-row bfl-grid'>
          {ApplicationRowOptions.filter(i => !tableRows.includes(i)).map((r, i) => {
            return getBoxItem(application, i, r);
          })}
          {tableRows.map((r, i) => {
            return getBoxItem(application, i, r, i < 3 ? 'to-small' : i < 6 ? 'to-medium' : i < 9 ? 'to-large' : i < 12 ? 'to-xl' : 'to-xxl');
          })}
        </div>
        {active !== PortfolioApps &&
          <div className='toggle-element bfl-grid to-medium'>
            <Checkbox
              button
              label='Add to portfolio'
              checked={isChecked && application?.inPortfolio === 1}
              onChange={() => {
                setIsChecked(true);
                ToggleApp(application, true, dispatch);
              }}
            />
            <Checkbox
              button
              label='Exclude from portfolio'
              checked={!isChecked  && application?.inPortfolio === 2}
              onChange={() => {
                setIsChecked(false);
                ToggleApp(application, false, dispatch);
              }}
            />
          </div>
        }
      </div>
      }
      limitExpandClick
    >
      {active === PortfolioApps ?
        [tableRows.map((r, i) => {
          if (r.value === 'name') {
            return (
              <Table.Cell key={i}>
                <NavLink className='bf-link' to={`/${id}/applications/${application.id}`}>
                  {application.name || <Badge state={"warning"}>Missing info</Badge>}
                </NavLink>
              </Table.Cell>
            )
          } else if (r.value === 'modifiedAt') {
            return (
              <Table.Cell className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'}  key={i} style={{minWidth: '120px'}}>{application?.modifiedAt ? dateFormat('nb-no', new Date(application.modifiedAt), {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }) : 'Not modified'}</Table.Cell>
            )
          } else if (r.value === 'readyStatus') {
            return (
              <Table.Cell style={{minWidth: '120px'}} key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'}>{getReadyStatusBadge(application.readyStatus)}</Table.Cell>
            )
          } else if (r.value === 'origin') {
            return (
              <Table.Cell key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'} style={{minWidth: '120px'}}>{getOrigin(application.origin)}</Table.Cell>
            )
          } else if (r.value === 'responsible') {
            return (
              <Table.Cell key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'} style={{minWidth: '120px'}}>{getResponsibility(application.readyStatus)}</Table.Cell>
            )
          } else if (r.value === 'companyUsers') {
            return (
              <Table.Cell key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'}>
                {(application.origin === 3 && application.name !== 'Make me admin') ?
                  <p className='bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '5px 0'}}>All users</p>
                  :
                  <Button style={{minWidth: '136px'}} small onClick={() => openUserDrawer()}>{seedingUsers ? <Spinner size={14}/> : application.companyUsers?.filter(u => u.isDisabled === false)?.length || '0'} users</Button>
                }
              </Table.Cell>
            )
          } else if (r.value === 'singleSignOnToday') {
            return (
              <Table.Cell key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'}>{getValue(application?.singleSignOnToday === 'Yes' ? 1 : 0)}</Table.Cell>
            )
          } else if (r.value === 'environment') {
            return (
              <Table.Cell key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'}>
                {application?.environment?.length > 0 ? application?.environment?.split(',').map((b, i) => <Badge key={b} style={{marginRight: 'var(--bfs4)'}}>{b}</Badge>) : ''}
              </Table.Cell>
            )
          } else if (r.value === 'vendor' || r.value === 'currentVersion' || r.value === 'newVersion' || r.value === 'location') {
            return (
              <Table.Cell key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'}>{application[r.value] || ''}</Table.Cell>
            )
          }
          return (
            <Table.Cell key={i} className={i < 3 ? 'from-small' : i < 6 ? 'from-medium' : i < 9 ? 'from-large' : i < 12 ? 'from-xl' : 'from-xxl'}>
              {getValue(application[r.value])}
            </Table.Cell>
          )
        }),
          <Table.Cell key='dropdown' className='application-dropdown'>
            <Dropdown
              noPadding
              disabled={isComplete}
              content={
                <div className='application-dropdown-content'>
                  <NavLink to={`/${id}/applications/${application.id}`}>
                    <p className='bf-title-link'>
                      Edit
                    </p>
                  </NavLink>
                  <p className='bf-title-link' style={{color: 'var(--bfc-alert)'}} onClick={() => dispatch(toggleApplicationAsync(application.id))}>
                    Remove from portfolio
                  </p>
                </div>
              }
            >
              <Icon style={isComplete ? {cursor: 'pointer', color: "grey"} : {cursor: 'pointer'}} icon={faEllipsisH} />
            </Dropdown>
          </Table.Cell>
        ]
        : [
          <Table.Cell key='name'>
            <span style={{margin: 'auto'}} className='bf-p'>
              {application.name || <Badge state={"warning"}>Missing info</Badge>} {application.isEnabled && <Badge state={"success"}>Added to portfolio</Badge>}
            </span>
          </Table.Cell>,
          <Table.Cell key='companyUsers' className='from-small'>
            {(application.origin === 3 && application.name !== 'Make me admin') ?
              <p className='bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '5px 0'}}>All users</p>
              :
              <Button style={{minWidth: '136px'}} small onClick={() => openUserDrawer()}>{seedingUsers ? <Spinner size={14}/> : application.companyUsers?.filter(u => u.isDisabled === false)?.length || '0'} users</Button>
            }
          </Table.Cell>,
          <Table.Cell key='inPortfolio' className='from-medium application-button-row'>
            <Checkbox
              button
              label='Yes'
              checked={isChecked && application?.inPortfolio === 1}
              onChange={() => {
                setIsChecked(true);
                ToggleApp(application, true, dispatch);
              }}
            />
            <Checkbox
              button
              label='No'
              checked={!isChecked  && application?.inPortfolio === 2}
              onChange={() => {
                setIsChecked(false);
                ToggleApp(application, false, dispatch);
              }}
            />
          </Table.Cell>
        ]
      }
    </Table.Row>
  );
};

ApplicationBox.propTypes = {
  application: PropTypes.object,
  active: PropTypes.string,
  toggleEdit: PropTypes.func,
  setApplicationId: PropTypes.func,
  openModal: PropTypes.func,
  isComplete: PropTypes.bool,
  openUserSlider: PropTypes.func,
  tableRows: PropTypes.array
};

export default ApplicationBox;
