import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchApplicationsAsync} from '../../../redux/company/applications/applicationThunks';
import {Button, FieldGroup, Input, Pagination, ProgressBar, useFloatingMessage} from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import {faSearch, faAdd} from '@fortawesome/pro-regular-svg-icons';
import './applicationDetails.scss';
import ApplicationProgressBox from '../Components/ApplicationProgressBox';
import {IntilityPermission} from '../../Permissions';
import EditModal from './EditModal';
import apiFetch from '../../../utils/apiFetch';

export const checkApplication = (application, percentage) => {
  let number = 0;
  if (!application?.applicationStatus?.onboardApp) {
    return percentage ? 0 : -1;
  }
  number += application?.applicationStatus?.appInfoSent;
  number += application?.applicationStatus?.solutionDesignProgress;
  number += application?.applicationStatus?.serverStaged;
  number += application?.applicationStatus?.installation;
  number += application?.applicationStatus?.testing;
  return number;
};

export const AppFilterOptions = [
  {value: '', label: 'All'},
  {value: 'serverApp', label: 'Server'},
  {value: 'saasApp', label: 'SaaS'},
  {value: 'cleintApp', label: 'Client'}
];

export const OnboardReadyStatusOptions = [
  {value: 0, label: 'Not Ready'},
  {value: 1, label: 'Ready'},
  {value: 2, label: 'Need Files'},
  {value: 4, label: 'Awaiting review'},
  {value: 5, label: 'Packaging'}
];

export const ApplicationDetails = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {applicationList, information} = useSelector(state => state.company.data.applicationPlatform);
  const [sort, setSort] = useState(0);
  const [search, setSearch] = useState('');
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [typeFilter, setTypeFilter] = useState({value: '', label: 'All'});
  const [gptSearchValue, setGptSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [gptResponse, setGptResponse] = useState('');
  const {showFloatingMessage} = useFloatingMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableData, setEditableData] = useState([]);
  const dispatch = useDispatch();
  let totalNumber = 0;
  let currentNumber = 0;
  let inProgress = 0;
  let ready = 0;
  let notRelevant = 0;

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchApplicationsAsync(id));
  }, [user, dispatch, id]);

  const sortedData = applicationList
    .filter(a => a.isEnabled === true)
    .filter(a => {
      if (typeFilter.label === 'Server') return a.serverApp === 1;
      if (typeFilter.label === 'SaaS') return a.saasApp === 1;
      if (typeFilter.label === 'Client') return a.clientApp === 1;
      else return a;
    })
    .filter(s => s.name?.toLowerCase().includes(search.toLowerCase()));

  applicationList &&
    sortedData?.forEach(l => {
      const test = checkApplication(l);
      totalNumber += 10;
      currentNumber += test >= 0 ? test : 0;
      if (test === 10) {
        ready++;
      } else if (test >= 0 && test < 10) {
        inProgress++;
      } else if (test === -1) {
        notRelevant++;
      }
    });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData &&
      sortedData.forEach((a, i) => {
        if (i >= counter) {
          tableOptions.push({value: counter, label: `${counter}`});
          counter = counter * 2;
        }
      });
    tableOptions.push({value: applicationList?.length, label: 'All'});
    return tableOptions;
  };

  const filteredData =
    sortedData &&
    sortedData.filter(s => {
      if (sort === 0) {
        return s;
      } else if (sort === 1) {
        return checkApplication(s) < 10 && checkApplication(s) >= 0;
      } else if (sort === 2) {
        return checkApplication(s) === 10;
      } else if (sort === 3) {
        return checkApplication(s) === -1;
      }
      return s;
    });
  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = filteredData && filteredData.slice(indexOfFirstUser, indexOfLastUser);

  const onboardGptSearch = query => {
    setLoading(true);
    return apiFetch(`/IntilityGpt/applicationExtractor?applicationPlatformId=${information.id}&companyId=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(query)
    })
      .then(response => {
        if (response.ok) return response.json();
        return response.json().then(response => {
          throw response;
        });
      })
      .then(res => {
        setGptResponse(res);
        if (res[0]?.message?.toolCalls.length === 0) {
          showFloatingMessage('GPT did not manage to extract applications', {state: 'warning'});
        }
        const args = JSON.parse(res[0]?.message?.toolCalls[0]?.arguments);
        setEditableData(args.applications);
        setIsModalOpen(true);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        if (error.status === 504) {
          showFloatingMessage('GPT used to long to answer.', {state: 'warning'});
        } else if (error.status === 500) {
          showFloatingMessage('An error occured on the server.', {state: 'error'});
        }
        setLoading(false);
      });
  };

  return (
    <div id='backoffice-application-details'>
      <IntilityPermission>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '24px'}}>
          <Input
            value={gptSearchValue}
            iconButton={true}
            icon={faAdd}
            rightIcon={true}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onboardGptSearch(gptSearchValue);
              }
            }}
            onIconClick={() => onboardGptSearch(gptSearchValue)}
            tabIndex={0}
            onChange={e => setGptSearchValue(e.target.value)}
            placeholder='Paste your application data here. Response may take some time depending on the amount of data.'
            label='Application GPT (experimental feature)'
            description='Send in your application data to add applications, this can be copy pasted from Excel, a CSV, or json. You are free to try other formats as well.'
            loading={loading}
            style={{width: '100%'}}
          />
          <div
            style={{
              borderRadius: '0 0 var(--bfs12) var(--bfs12)',
              backgroundColor: 'var(--bfc-theme-c-1)',
              marginTop: '10px'
            }}
          ></div>
        </div>
        <EditModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={editableData} />
      </IntilityPermission>
      <p className='bf-strong'>Total progress:</p>
      <p style={{color: 'var(--bfc-base-c-2)'}}>Showing the total progress of all applications</p>
      <div className='progress-bar'>
        <ProgressBar size={'large'} value={Math.round((currentNumber * 100) / totalNumber)} />
      </div>
      <div className='button-bar'>
        <FieldGroup className='search-section'>
          <Input
            label='Search'
            placeholder='Search for application'
            hideLabel={true}
            icon={faSearch}
            onChange={e => {
              setSearch(e.target.value);
              setPage(1);
            }}
          />
          <Select
            key='Filter'
            label='Filter type'
            hideLabel
            placeholder='Filter type'
            className='table-size-dropdown'
            value={typeFilter}
            options={AppFilterOptions}
            onChange={setTypeFilter}
          />
        </FieldGroup>
        <div className='button-bar-group'>
          <Button.Group>
            <Button active={sort === 0} onClick={() => setSort(0)}>
              Show all ({totalNumber / 10})
            </Button>
            <Button active={sort === 1} onClick={() => setSort(1)}>
              In progress ({inProgress})
            </Button>
            <Button active={sort === 2} onClick={() => setSort(2)}>
              Ready ({ready})
            </Button>
            <Button active={sort === 3} onClick={() => setSort(3)}>
              Not relevant ({notRelevant})
            </Button>
          </Button.Group>
        </div>
      </div>
      <div className='network-details-content'>
        {sortedAndIndexedData.map(application => {
          return <ApplicationProgressBox key={application.id} application={application} />;
        })}
      </div>
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
    </div>
  );
};

export default ApplicationDetails;
