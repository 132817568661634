export const normalizeCommunicationItems = communicationItems => {
  return {
    communicationPlatform: {
      communicationList: communicationItems.communicationList,
      information: communicationItems,
      state: communicationItems.state
    }
  };
};

export const normalizeCommunicationInformation = communicationPlatform => {
  return {
    communicationPlatform: {
      information: communicationPlatform,
      state: communicationPlatform.state
    }
  };
};
