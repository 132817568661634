import React, { useEffect, useState } from "react";
import { Input } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { locationTypeOptions } from "../../NewLocationModal/NewLocationModal";
import PropTypes from "prop-types";
import '../locationEditDrawer.scss';

export const HeaderContent = ({ location, editLocation }) => {
  const [locationTypes, setLocationTypes] = useState([]);

  useEffect(() => {
    const identityValues = location?.locationTypes?.split(',');
    const identityArr = [];
    if (identityValues?.includes('Office')) identityArr.push({ value: 'Office', label: 'Office' });
    if (identityValues?.includes('Warehouse')) identityArr.push({ label: 'Warehouse', value: 'Warehouse' });
    if (identityValues?.includes('Workshop')) identityArr.push({ label: 'Workshop', value: 'Workshop' });
    if (identityValues?.includes('Production facility')) identityArr.push({ label: 'Production facility', value: 'Production facility' });
    if (identityValues?.includes('Real estate')) identityArr.push({ label: 'Real estate', value: 'Real estate' });
    if (identityValues?.includes('Unmanned station')) identityArr.push({ label: 'Unmanned station', value: 'Unmanned station' });
    if (identityValues?.includes('Public network')) identityArr.push({ label: 'Public network', value: 'Public network' });
    if (identityValues?.includes('Other')) identityArr.push({ label: 'Other', value: 'Other' });
    setLocationTypes(identityArr);
  }, []);


  return (
    <div className='header-content'>
      <Input value={location?.name} label='Name' onChange={(e) => editLocation('name', e.target.value)} />
      <Select label='Location type(s)' isMulti options={locationTypeOptions} value={locationTypes} onChange={e => {
        setLocationTypes(e);
        editLocation('locationTypes', e.map(t => t.value)?.join(','))
      }}/>
    </div>
  )
};

HeaderContent.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func
};

export default HeaderContent