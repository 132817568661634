import './teamCard.scss';
import PropTypes from "prop-types";
import { Icon } from "@intility/bifrost-react";
import React from "react";
import teamLogo from '../../../../assets/images/ContactUs/Microsoft_Office_Teams.png';
import assistanceImg from '../../../../assets/images/ContactUs/assistance.png';
import assistanceDarkImg from '../../../../assets/images/ContactUs/assistancedark.png';
import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";

export const ProjectTeamCard = ({team}) => {
  const {information} = useSelector(state => state.company.data.detailsPlatform);

  return (
    <div id='team-card' className='contact-team-card'>
      <div className='card-header'>
        <h5>Contact project team</h5>
      </div>
      <div className='card-body project-team'>
        <div className='card-text'>
          <div className='card-text-header'>
            <div>
              <img
                src={teamLogo}
                alt="img"
              />
            </div>
            <div>
              <p className="bf-large">Communicate with the team</p>
              <p className='bf-small'>Ask your questions in real time, in your own personal team.</p>
            </div>
          </div>
          <div>
            <a className='bf-button bf-button-pill' style={{background: 'var(--bfc-theme)', color:'var(--bfc-theme-c-base-3)', fontWeight: '600', border: '1px solid transparent'}} target='_blank' href={information?.projectTeam} rel='noopener noreferrer'>
              Create a post in teams <Icon style={{marginLeft: 'var(--bfs4)'}} icon={faArrowUpRight}/>
            </a>
          </div>
        </div>
        <div className='card-image'>
          <img className="bf-dark-only" src={assistanceDarkImg} alt="img"/>
          <img className="bf-lighgt-only" src={assistanceImg} alt="img"/>
        </div>
      </div>
    </div>
  )

}

export default ProjectTeamCard


ProjectTeamCard.propTypes = {
  team: PropTypes.object
}