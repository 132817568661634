import {Accordion, Button, Table} from '@intility/bifrost-react';
import {produce} from 'immer';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {updateBusinessApplication} from 'redux/company/mobileDevices/mobileDevicesSlice';
import '../apps.scss';
import CustomAppDrawer from './CustomAppDrawer';
import EditApps, {APP_OPTIONS} from './EditApps';

function sortApps(apps, sort) {
  return apps.sort((a, b) => {
    if (sort.direction === 'asc') {
      return a[sort.key].localeCompare(b[sort.key]);
    } else {
      return b[sort.key].localeCompare(a[sort.key]);
    }
  });
}

export const AppsAccordion = ({apps, name, ...props}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const initialApps = apps.filter(app => app.type === name);
  const [appsState, setAppsState] = useState(initialApps);
  const {isEnabled} = useSelector(state => state.company.data.mobilePlatform.mobileDevices);

  const [sort, setSort] = useState({
    key: 'name',
    direction: 'asc'
  });

  useEffect(() => {
    if (!isEnabled) {
      onCancel()
    }
  }, [isEnabled])

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      // sort by descending if current key is sorted by ascending
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const sortedApps = sortApps([...appsState], sort);

  const onCancel = () => {
    setIsEditing(false);
    setAppsState(initialApps);
  };

  const handleSave = () => {
    const newState = produce(apps, draft => {
      const oldApps = draft.filter(app => app.type === name);

      oldApps.forEach(app => {
        const index = draft.findIndex(a => a.name === app.name);
        draft.splice(index, 1);
      });

      appsState.forEach(app => {
        draft.push(app);
      });
    });

    dispatch(updateBusinessApplication(newState));
    setIsEditing(prev => !prev);
  };

  const handleCustomAppSubmit = values => {
    const newState = [...apps, values];
    setAppsState(newState.filter(app => app.type === name));
    dispatch(updateBusinessApplication(newState));
  };

  return (
    <Accordion {...props} variant={"styled"}>
      <Accordion.Item title={name}>
        <div id='apps-accordion-custom'>
          <div>
            {name === 'Custom' ? (
              <p>
                If your business has other business applications you prefer to have managed, please notify us through
                this form.<br/>
                Intility can not guarantee that they are supported (or to which extent) through the management tool
                Intune, as this is defined by the application’s support and capabilities.
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='action-button'>
            {name === 'Custom' && !isEditing && <Button onClick={() => setDrawerIsOpen(true)} variant='filled'>Add custom app</Button>}

            {isEditing ? (
              <>
                <Button onClick={onCancel}>Cancel</Button>
                <Button variant='filled' onClick={handleSave}>
                  Save Changes
                </Button>
              </>
            ) : (
              <Button disabled={!isEnabled} onClick={() => setIsEditing(true)}>Edit</Button>
            )}
          </div>
        </div>
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
                App name
              </Table.HeaderCell>
              <Table.HeaderCell>Available in Company portal</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isEditing ? (
              <EditApps
                isComplete={!apps.isEnabled}
                appsState={sortedApps}
                setAppsState={setAppsState}
                appType={name}
                onChange={setAppsState}
                getSortProp={getSortProp}
                onSortChangeCreator={onSortChangeCreator}
              />
            ) : (
              sortedApps.map((app, i) => {
                const companyPortalValue = APP_OPTIONS.find(({value}) => value === app.deploymentType);

                return (
                  <Table.Row key={app.name + i}>
                    <Table.Cell>
                      <div style={{display: 'flex'}}>
                        <span style={{marginTop: '10px'}}>{app.displayName}</span>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>{companyPortalValue.label}</div>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </Accordion.Item>
      {name === 'Custom' && (
        <CustomAppDrawer
          open={drawerIsOpen}
          onRequestClose={() => setDrawerIsOpen(false)}
          onSubmit={handleCustomAppSubmit}
        />
      )}
    </Accordion>
  );
};

AppsAccordion.propTypes = {
  tableData: PropTypes.object
};
