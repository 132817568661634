import PropTypes from "prop-types";
import { Accordion, Badge, Button, Message } from "@intility/bifrost-react";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import NotRegisteredField from "../../../../../../NotRegisteredField/NotRegisteredField";
import React, { useEffect, useState } from "react";
import { DrawerType } from "../LocationInformation";

export const hasComlinesInfo = (location) => {
  if (location === null) return false;
  if (location?.comlinesConnection !== null && location?.comlinesConnection?.length > 0) return true;
  if (location?.leaseTime !== null && location?.leaseTime?.length > 0) return true;
  if (location?.lineNumber !== null && location?.lineNumber?.length > 0) return true;
  if (location?.secondaryLineNumber !== null && location?.secondaryLineNumber?.length > 0) return true;
  return (location?.isp !== null && location?.isp?.length > 0);
};

export const hasMissingComlinesInfo = (location) => {
  let progress = 5;
  if (location === null) return true;
  if (location?.comlinesConnection !== null && location?.comlinesConnection?.length > 0) progress--;
  if (location?.leaseTime !== null && location?.leaseTime?.length > 0) progress--;
  if (location?.lineNumber !== null && location?.lineNumber?.length > 0) progress--;
  if (location?.secondaryLineNumber !== null && location?.lineNumber?.length > 0) progress--;
  if (location?.isp !== null && location?.isp?.length > 0) progress--;
  return progress > 0;
};

export const getLocationEnumValue = (value) => {
  return value === 0 ? null : value === 1 ? 'Yes' : 'No'
}

const ComlinesAccordionContent = ({location, isDisabled, activeAccordion, handleChange, editLocation}) => {
  const [hasComInfo, setHasComInfo] = useState(false);
  const [hasMissingComInfo, setHasMissingComInfo] = useState(false);

  useEffect(() => {
    if (location === null) return;
    let isCancelled = false;
    const hasInfo = hasComlinesInfo(location)
    const hasMissingInfo = hasMissingComlinesInfo(location)
    if (!isCancelled) {
      setHasComInfo(hasInfo);
      setHasMissingComInfo(hasMissingInfo);
    }
    return () => {
      isCancelled = true;
    };
  }, [location]);

  return (
    <Accordion.Item
      actions={!hasComInfo ?
        <Button onClick={editLocation}
                state={isDisabled ? 'inactive' : 'default'}
                style={{marginTop: '8px', marginRight: '8px'}}
                variant='filled' small >Add information</Button>
        :
        <Accordion.Action
          icon={faPencil}
          style={isDisabled ? { color: 'var(--bfc-neutral)' } : undefined}
          onClick={editLocation}
          aria-label='Edit'
        />}
      title={
        <span style={{display: 'flex', justifyContent: 'space-between'}}>Comlines
          <span>{(hasComInfo && hasMissingComInfo) && <Badge state={"warning"}>Missing information</Badge>}</span>
        </span>
      }
      active={activeAccordion.includes(DrawerType.COMLINES)}
      onClick={() => handleChange(DrawerType.COMLINES)}>
      {hasComInfo ?
        <div className='comlines-accordion bfl-grid'>
          <div>
            <p className='bf-strong'>Comline type</p>
            <NotRegisteredField field={location?.comlinesConnection} />
          </div>
          <div>
            <p className='bf-strong'>Lease time/Contract term</p>
            <NotRegisteredField field={location?.leaseTime} />
          </div>
          <div>
            <p className='bf-strong'>Primary line number/circuit ID</p>
            <NotRegisteredField field={location?.lineNumber} />
          </div>
          <div>
            <p className='bf-strong'>Secondary line number/Circuit ID</p>
            <NotRegisteredField field={location?.secondaryLineNumber} />
          </div>
          <div>
            <p className='bf-strong'>Internet Service Provider (ISP)</p>
            <NotRegisteredField field={location?.isp} />
          </div>
        </div>
        :
        <div>
          <Message>General comlines information</Message>
        </div>
      }
    </Accordion.Item>
  )

}

ComlinesAccordionContent.propTypes = {
  location: PropTypes.object,
  isDisabled: PropTypes.bool,
  activeAccordion: PropTypes.array,
  handleChange: PropTypes.func,
  editLocation: PropTypes.func,
}

export default ComlinesAccordionContent