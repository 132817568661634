import {createReducer, createSlice} from '@reduxjs/toolkit';
import {
  fetchGlobalTasksSuccess,
  fetchInformationSuccess,
  fetchOnboardTaskListSuccess,
  fetchOnboardTasksSuccess,
  updateGlobalTaskSuccess,
  updateOnboardTaskInformationSuccess,
  updateOnboardTaskSuccess
} from './onboardTaskActions';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanySuccess} from '../companyUpdateSlice';

const updateOnOnboardTaskChangeReducer = getState => {
  return {
    [fetchOnboardTasksSuccess]: (state, action) => getState(action.payload, action.type, state),
    [updateOnboardTaskSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};
const updateOnGlobalTaskChangeReducer = getState => {
  return {
    [fetchGlobalTasksSuccess]: (state, action) => getState(action.payload, action.type, state),
    [updateGlobalTaskSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};

export const onboardTaskReducer = createReducer(
  [],
  updateOnOnboardTaskChangeReducer((payload, type, state) => {
    state = payload.taskPlatform.taskList;
    return state;
  })
);
export const onboardGlobalTaskReducer = createReducer(
  [],
  updateOnGlobalTaskChangeReducer((payload, type, state) => {
    state = payload.taskPlatform.globalTaskList;
    return state;
  })
);

const updateOnChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchOnboardTasksSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateOnboardTaskInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const onboardTaskInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    listCount: 0,
    ticketManagerProjectId: ''
  },
  updateOnChangeReducer((payload, state) => {
    state.id = payload.taskPlatform.information.id;
    state.companyId = payload.taskPlatform.information.companyId;
    state.modifiedAt = payload.taskPlatform.information.modifiedAt;
    state.listCount = payload.taskPlatform.information.listCount;
    state.isEnabled = payload.taskPlatform.information.isEnabled;
    state.ticketManagerProjectId = payload.taskPlatform.information.ticketManagerProjectId;
    return state;
  })
);

export const onboardTaskStatusReducer = createSlice({
  name: 'onboardTasksAction',
  initialState: {
    fetching: false,
    fetchingGlobal: false,
    updatingGlobal: false,
    error: false,
    errorGlobal: false
  },
  reducers: {
    fetchOnboardTasks: state => {
      state.fetching = true;
    },
    fetchOnboardTasksError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateOnboardTasks: state => {
      state.updating = true;
    },
    updateOnboardTasksError: state => {
      state.updating = false;
      state.error = true;
    },
    fetchGlobalTasks: state => {
      state.fetchingGlobal = true;
    },
    fetchGlobalTasksError: state => {
      state.fetchingGlobal = false;
      state.errorGlobal = true;
    },
    updateGlobalTasks: state => {
      state.updatingGlobal = true;
    },
    updateGlobalTasksError: state => {
      state.updatingGlobal = false;
      state.errorGlobal = true;
    },
  },
  extraReducers: {
    [fetchOnboardTasksSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateOnboardTaskSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchGlobalTasksSuccess]: state => {
      state.fetchingGlobal = false;
      state.updatingGlobal = false;
      state.errorGlobal = false;
    },
    [updateGlobalTaskSuccess]: state => {
      state.updatingGlobal = false;
      state.errorGlobal = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchOnboardTaskListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateOnboardTaskInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchOnboardTasks,
  fetchOnboardTasksError,
  fetchGlobalTasks,
  fetchGlobalTasksError,
  updateGlobalTasks,
  updateGlobalTasksError,
  updateOnboardTasksError,
  updateOnboardTasks,
} = onboardTaskStatusReducer.actions;
