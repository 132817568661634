import {useEffect, useState} from 'react';
import produce from 'immer';

/**
 * Copies the sent in state, and returns an object that can be used to handle editing.
 * @param {*} state The state to copy when editing
 */
const useEditable = state => {
  const [editing, setEditing] = useState(false);
  const [editingState, setEditingState] = useState(null);
  const toggleEditing = isEditing => {
    if (isEditing !== undefined) {
      setEditing(isEditing);
    } else {
      setEditing(!editing);
    }
  };

  useEffect(() => {
    setEditingState(state);
  }, [editing, state]);

  const onChange = e => {
    const {name, value} = e.target;

    setEditingState(
      produce(draft => {
        if (draft === Object(draft)) {
          // if draft is an object, set its key to new value
          draft[name] = value;
        } else {
          // else, just return the new value
          return value;
        }

        return draft;
      })
    );
  };

  return {
    editing,
    toggleEditing,
    state: editing ? editingState : state,
    onChange
  };
};

export default useEditable;
