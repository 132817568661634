import React from 'react';
import PropTypes from 'prop-types';

export const LabelBox = ({left, right}) => {
  return (
    <div className='label-box'>
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
};

LabelBox.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node
};

export default LabelBox;
