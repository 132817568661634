import './googleMapsPane.scss';
import PropTypes from "prop-types";
import { uploadLocationImageAsync } from "../../../../../../redux/company/locations/locationThunks";
import { ButtonFileUploader } from "../../../../../FileUploader/SimpleFileUploader";
import React from "react";
import { useDispatch } from "react-redux";

export const GoogleMapsPane = ({ uploadButton, location }) => {
  const dispatch = useDispatch();

  return (
    <div className="google-map-code">
      {(location?.address?.length > 0 && location?.zipCode?.length > 0) && !location?.locationImage &&
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA12MKdpW_niZ6GeHpuNrFSrB1o-F2CS9k&q=${location.address}+${location.zipCode}+${location.postalPlace}+${location.country}&zoom=4`}
          style={{ border: '0' }}
          referrerPolicy="no-referrer-when-downgrade"
          loading="lazy"
          allowFullScreen={true}
          aria-hidden="false"
          tabIndex="0"></iframe>
      }
      {(uploadButton) &&
        <ButtonFileUploader
          name='Upload image'
          onChange={e => {
            dispatch(uploadLocationImageAsync(location.id, e.target.value));
          }}
        />
      }
    </div>
  )

}

GoogleMapsPane.propTypes = {
  location: PropTypes.object,
  uploadButton: PropTypes.bool
}

export default GoogleMapsPane