import PropTypes from "prop-types";
import { Badge, Input, Modal, Table } from "@intility/bifrost-react";
import React, { useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import './licenseModal.scss';

export const LicenseModal = ({user, setUser, modalOpen, setModalOpen}) => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    key: 'name',
    direction: 'asc'
  });

  const closeModal = () => {
    setUser({});
    setModalOpen(false);
    setSearch('');
  }

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const sortedLicenses = user?.licenses && [...user?.licenses]?.sort((a, b) => {
    if (sort.direction === 'asc') {
      return a?.license[sort.key]?.localeCompare(b?.license[sort.key]);
    } else {
      return b?.license[sort.key]?.localeCompare(a?.license[sort.key]);
    }
  }).filter(s => {
    return s?.license?.name?.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Modal isOpen={modalOpen} onRequestClose={closeModal} header={`Licenses for ${user?.firstName || ''} ${user?.lastName || ''}`}>
      <Input style={{marginBottom: 'var(--bfs24)'}} label='License search' hideLabel placeholder='Search for license' icon={faMagnifyingGlass} value={search} onChange={e => setSearch(e.target.value)}/>
      <div className='license-modal'>
        {sortedLicenses?.length < user?.licenses?.length &&
          <p className='bf-small' style={{marginBottom: 'var(--bfs4)', marginTop: 0}}>Showing {sortedLicenses?.length}/{user?.licenses?.length} licenses</p>
        }
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>License</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedLicenses?.map(l => {
              return (
                <Table.Row>
                  <Table.Cell>{l.license?.name}</Table.Cell>
                  <Table.Cell><Badge state={"success"}>Active</Badge></Table.Cell>
                </Table.Row>
                );
            })}
          </Table.Body>
        </Table>
      </div>
    </Modal>
  )

}

LicenseModal.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func
}

export default LicenseModal