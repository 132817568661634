import React, {useState, useEffect} from 'react';
import './waitingContent.scss';
import {
  Badge,
  Button,
  Checkbox,
  Dropdown, FieldGroup,
  Icon,
  Input,
  Modal,
  Pagination,
  Spinner,
  Table, Tooltip
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import {faPlus, faSearch} from '@fortawesome/pro-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import { CompanyUser, IamsUser } from "../../../../../utils/hub/models";
import {
  fetchUsersAsync,
  setIamsUserAsync,
  updateUserAsync
} from "../../../../../redux/company/users/userThunks";
import {clearUserSuccess} from '../../../../../redux/company/users/usersActions';
import {WaitingContentModal} from './WaitingContentModal';
import PropTypes from 'prop-types';
import produce from 'immer';
import InputWithFeedback, {InputType} from '../../../../InputWithFeedback/InputWithFeedback';
import { addIamsUserAsync } from "../../../../../redux/company/iamsUsers/iamsUserThunks";
import { emptyGuid } from "../../../../../utils/guid";
import IamsUserType from "./IamsUserTypeOptions";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

export const SearchOptions = [
  {value: 'firstName', label: 'First name'},
  {value: 'lastName', label: 'Last name'},
  {value: 'model', label: 'Computer'}
];

export const WaitingContent = ({waitingList, isComplete}) => {
  const {userList, user} = useSelector(state => state.company.data.userPlatform);
  const {iamsUsersStatus} = useSelector(state => state.company.data.iamsUserPlatform);
  const {id} = useSelector(state => state.company.data.info);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [searchCriterea, setSearchCriterea] = useState({
    value: 'firstName',
    label: 'First name'
  });
  const [filter, setFilter] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [selectedIamsUser, setSelectedIamsUser] = useState({});
  const [sort, setSort] = useState({key: 'firstName', direction: 'asc'});
  const [selectedUser, setSelectedUser] = useState({
    value: new CompanyUser(),
    label: 'None selected'
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [newIams, setNewIams] = useState(false);
  const [comment, setComment] = useState(false);
  const [commentState, setCommentState] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [userMatch, setUserMatch] = useState({
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    location: null,
    existingMatch: null
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const user = userList.find(
      u =>
        compareValues(u.primaryEmailAddress, selectedIamsUser?.email) &&
        compareValues(u.mobilePhone, selectedIamsUser?.phoneNumber)
    );
    if (user !== null && user !== undefined) {
      setSelectedUser({
        value: user,
        label: `${user.firstName} ${user.lastName}`
      });
    }
  }, [userList]);

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  useEffect(() => {
    if (user?.id) {
      setSelectedUser({
        value: user,
        label: `${user.firstName} ${user.lastName}`
      });
      dispatch(clearUserSuccess());
    }
  }, [user]);

  useEffect(() => {
    setCommentState(selectedUser?.value?.comment);
  }, [selectedUser]);

  useEffect(() => {
    if (newIams) {
      dispatch(addIamsUserAsync(selectedIamsUser)).then(() => dispatch(fetchUsersAsync(id))).catch(error => console.log(error));
      setNewIams(false);
    }
  }, [newIams]);

  useEffect(() => {
    const firstName = compareValues(selectedUser?.value?.firstName, selectedIamsUser?.firstName);
    const lastName = compareValues(selectedUser?.value?.lastName, selectedIamsUser?.lastName);
    const email = compareValues(selectedUser?.value?.primaryEmailAddress, selectedIamsUser?.email);
    const mobile = compareValues(selectedUser?.value?.mobilePhone, selectedIamsUser?.phoneNumber);
    const location = compareValues(selectedUser?.value?.locationId, selectedIamsUser?.locationId);
    const userVars = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mobile,
      location: location,
      existingMatch: selectedIamsUser.companyUserMatch
    };
    setUserMatch(userVars);
  }, [selectedUser]);

  const sortedData = waitingList
    .filter(s => {
      if (filter === 0) {
        return s;
      } else if (filter === 1) {
        return s.companyUserMatch === undefined;
      } else if (filter === 2) {
        return s.companyUserMatch > 0;
      } else if (filter === 3) {
        return s.companyUserMatch === null && s.companyUserId === null;
      }
      return s;
    })
    .filter(s => {
      if (s[searchCriterea.value] === null) return s;
      return s[searchCriterea.value]?.toLowerCase()?.includes(searchValue?.toLowerCase());
    })
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key]?.toString()?.localeCompare(b[sort.key]?.toString());
      } else {
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key]?.toString()?.localeCompare(a[sort.key]?.toString());
      }
    });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: waitingList?.length, label: 'All'});
    return tableOptions;
  };

  useEffect(() => {
    setUserOptions(userList?.map(u => {
      if (u.isEnabled === false) return {value: u, label: <span>{u?.firstName} {u?.lastName} <Tooltip content='User deleted, if incorrect restore from Users page.'><Badge state={"alert"}>Deleted</Badge></Tooltip></span>, data: `${u?.firstName} ${u?.lastName}`};
      return {value: u, label: `${u?.firstName} ${u?.lastName}`, data: `${u?.firstName} ${u?.lastName}`};
    }));
  }, [userList]);

  useEffect(() => {
    userOptions.splice(userOptions.length, 0, {
      value: '201',
      label: (
        <span style={{color: 'var(--bfc-base-c-theme)'}}>
          <Icon icon={faPlus} /> Add new user
        </span>
      )
    });
  }, [userOptions]);


  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  const compareValues = (a, b) => {
    if ((a === null || a?.length === 0 || a === emptyGuid) && (b === null || b?.length === 0 || b === emptyGuid)) return true;
    if (a === null || a === undefined || b === null || b === undefined) return false;
    if (a === emptyGuid || b === emptyGuid) return false;
    if (a.length === 0 && b.length === 0) return true;
    if (a.length <= 0 || b.length <= 0) return false;
    return a?.toString() === b?.toString();
  };

  const openModal = user => {
    setSelectedIamsUser(user);
    if (user.companyUserMatch) {
      setSelectedUser({
        value: user.companyUser,
        label: `${user.companyUser.firstName} ${user.companyUser.lastName}`
      });
    } else {
      setSelectedUser({value: new CompanyUser(), label: 'None selected'});
    }
    setModalOpen(true);
  };

  const createNewIamsEntry = (user, userType) => {
    const result = confirm('Only use this option if the person is prevented from running the IAMS scan.');
    if (result) {
      setSelectedIamsUser(produce(new IamsUser(), draft => {
        draft.companyUserId = user.id === null ? emptyGuid : user.id;
        draft.companyId = user.companyId === null ? emptyGuid : user.companyId;
        draft.locationId = user.locationId === null ? emptyGuid : user.locationId;
        draft.locationName = user.location === null ? draft.locationName : user.location;
        draft.email = user.primaryEmailAddress === null ? draft.email : user.primaryEmailAddress;
        draft.firstName = user.firstName === null ? draft.firstName : user.firstName;
        draft.lastName = user.lastName === null ? draft.lastName : user.lastName;
        draft.phoneNumber = user.mobilePhone === null ? draft.phoneNumber : user.mobilePhone;
        draft.recommendation = 'Verify';
        draft.intilityRecommendation = 'Awaiting';
        draft.companyUserMatch = 0;
        draft.warranty = new Date();
        draft.purchasedDate = new Date();
        if (userType === IamsUserType.PcUser) {
          draft.userType = IamsUserType.PcUser;
        } else if (userType === IamsUserType.MacUser) {
          draft.userType = IamsUserType.MacUser;
        } else if (userType === IamsUserType.NotPcUser) {
          draft.userType = IamsUserType.NotPcUser;
        } else if (userType === IamsUserType.SharedUser) {
          draft.userType = IamsUserType.SharedUser;
        }
      }));
      setNewIams(true);
    }
  };

  const saveComment = state => {
    const updatedCompanyUser = produce(selectedUser.value, draft => {
      draft.comment = state;
    });
    dispatch(updateUserAsync(updatedCompanyUser)).then(() => dispatch(fetchUsersAsync(id)));
    setComment(false);
  };

  const editComment = user => {
    setSelectedUser({
      value: user,
      label: `${user?.firstName || ''} ${user?.lastName || ''}`
    });
    setComment(true);
  };

  return (
    iamsUsersStatus.seeding ? <div style={{display: 'flex', justifyContent: 'center'}}>
      <Spinner size={100} label='Synchronizing users…'/>
    </div> :
    <div>
      <div className='content-top-bar bfl-grid'>
        <FieldGroup label='Search'>
          <Select
            label='Search'
            hideLabel
            value={searchCriterea}
            options={SearchOptions}
            onChange={item => setSearchCriterea(item)}
          />
          <Input
            placeholder={`Search for ${searchCriterea.label}`}
            label='search'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
        </FieldGroup>
        <div className='top-bar-button-group'>
          <div>
            <label style={{marginBottom: 'var(--bfs4)'}} className='bf-label'>
              Filter
            </label>
            <Button.Group>
              <Button
                active={filter === 0}
                onClick={() => {
                  setFilter(0);
                  setPage(1);
                }}
              >
                Show all ({waitingList.length})
              </Button>
              <Button
                active={filter === 1}
                onClick={() => {
                  setFilter(1);
                  setPage(1);
                }}
              >
                Not scanned ({waitingList.filter(u => u.companyUserMatch === undefined).length})
              </Button>
              <Button
                active={filter === 2}
                onClick={() => {
                  setFilter(2);
                  setPage(1);
                }}
              >
                Possible matches ({waitingList.filter(u => u.companyUserMatch > 0).length})
              </Button>
              <Button
                active={filter === 3}
                onClick={() => {
                  setFilter(3);
                  setPage(1);
                }}
              >
                Unmatched scans (
                {waitingList.filter(u => u.companyUserMatch === null && u.companyUserId === null).length})
              </Button>
            </Button.Group>
          </div>
        </div>
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('firstName')} onClick={onSortChangeCreator('firstName')}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('comment')} onClick={onSortChangeCreator('comment')}>
              Comment
            </Table.HeaderCell>
            <Table.HeaderCell
              sorting={getSortProp('companyUserMatch')}
              onClick={onSortChangeCreator('companyUserMatch')}
            >
              User/IAMS match
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAndIndexedData.map((u, i) => {
            return (
              <Table.Row key={u?.id + i}>
                <Table.Cell>{`${u?.firstName || ''} ${u?.lastName || ''}`}</Table.Cell>
                <Table.Cell>{u?.comment || '-'}</Table.Cell>
                <Table.Cell>
                  {u?.companyUserMatch > 0 ? (
                    <Badge>Possible match</Badge>
                  ) : u?.companyUserMatch === null ? (
                    <Badge state={'alert'}>No usermatch</Badge>
                  ) : (
                    <Badge state={'warning'}>Not scanned yet</Badge>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {u?.companyUserMatch > 0 ? (
                    <Button small disabled={isComplete} onClick={() => openModal(u)}>
                      Review scan
                    </Button>
                  ) : u?.companyUserMatch === null ? (
                    <Button small disabled={isComplete} onClick={() => openModal(u)}>
                      Review scan
                    </Button>
                  ) : (
                    <Dropdown
                      className='waiting-dropdown'
                      content={
                        <div>
                          <Checkbox onChange={() => dispatch(setIamsUserAsync(u.id, false))} label={'No computer'} />
                          <Checkbox
                            onChange={() => createNewIamsEntry(u, IamsUserType.MacUser)}
                            label={'Mac user'}
                          />
                          <Checkbox
                            onChange={() => createNewIamsEntry(u, IamsUserType.SharedUser)}
                            label={'Shared user'}
                          />
                          <Checkbox onChange={() => editComment(u)} label={'Edit comment'} />
                          <Checkbox onChange={() => createNewIamsEntry(u, IamsUserType.PcUser)} label={'Create IAMS entry'} />
                        </div>
                      }
                      variant='border'
                    >
                      <Button disabled={isComplete} small>Review user</Button>
                    </Dropdown>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Modal isOpen={comment} onRequestClose={() => setComment(false)} header='Edit comment'>
        <InputWithFeedback
          onChange={e => setCommentState(e.target.value)}
          maxLength={200}
          label='Comment'
          name='comment'
          value={commentState}
          type={InputType.TextArea}
        />
        <Button onClick={() => saveComment(commentState)}>Save</Button>
      </Modal>
      <WaitingContentModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        userMatch={userMatch}
        selectedUser={selectedUser}
        selectedIamsUser={selectedIamsUser}
        setSelectedUser={setSelectedUser}
        userOptions={userOptions}
      />
      <div className='bottom-line'>
        <div className='row-box' />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
    </div>
  );
};

WaitingContent.propTypes = {
  waitingList: PropTypes.array,
  isComplete: PropTypes.bool
};

export default WaitingContent;
