import EditFinance from '../../components/BackOffice/EditComponents/EditFinance';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Finance',
  path: 'finance/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditFinance
};
