import './globalServiceSettings.scss';
import React, { useEffect, useState } from "react";
import apiFetch from "../../../utils/apiFetch";
import { Input, Table } from "@intility/bifrost-react";
import {
  addGlobalIntilityServiceAsync, addIntilityServiceAsync,
  deleteGlobalIntilityServiceAsync,
  fetchGlobalIntilityServicesAsync, updateGlobalIntilityServiceAsync, updateIntilityServiceAsync
} from "../../../redux/company/intilityServices/intilityServiceThunks";
import { useDispatch, useSelector } from "react-redux";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import produce from "immer";
import { GlobalIntilityService, IntilityService } from "../../../utils/hub/models";
import GlobalServiceSettingsRow from "./GlobalServiceSettingsRow";


export const GlobalServiceSettings = () => {
  const {globalIntilityServiceList} = useSelector(state => state.company.data.intilityServicePlatform);
  const [articles, setArticles] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const dispatch = useDispatch();

  const publishService = (service) => {
    const existingArticle = globalIntilityServiceList.find(s => s.articleId === service.id)

    if (existingArticle) {
      dispatch(deleteGlobalIntilityServiceAsync(existingArticle.id))
    } else {
      const editService = produce(new GlobalIntilityService(), draft => {
        draft.isEnabled = true;
        draft.isActive = false;
        draft.articleId = service?.id;
      });

      dispatch(addGlobalIntilityServiceAsync(editService))
    }

  }

  const activateService = (service, checked) => {
    const existingArticle = globalIntilityServiceList.find(s => s.articleId === service.id)

    if (existingArticle) {
      const editService = produce(existingArticle, draft => {
        draft.isActive = checked;
      });
      dispatch(updateGlobalIntilityServiceAsync(editService))

    } else if (!existingArticle) {
      const editService = produce(new GlobalIntilityService(), draft => {
        draft.isEnabled = true;
        draft.isActive = true;
        draft.articleId = service?.id;
      });

      dispatch(addGlobalIntilityServiceAsync(editService))
    }

  }

  useEffect(() => {
    dispatch(fetchGlobalIntilityServicesAsync());
    apiFetch(`/Publish/services`)
      .then(response => {
        return response.json()
      }).then(value => {
      setArticles(value)
    })
  }, []);

  const sortedData = articles?.value?.length > 0 ? [...articles?.value]
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (sort.key === 'name') return a?.textContents[0]?.title?.localeCompare(b?.textContents[0]?.title);
        if (sort.key === 'isActive' || sort.key === 'isEnabled') {
          const aValue = globalIntilityServiceList.find(s => s.articleId === a.id)
          return sort.key === 'isActive' ? aValue?.isActive === true : aValue?.isEnabled === true;
        }
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        if (sort.key === 'name') return b?.textContents[0]?.title?.localeCompare(a?.textContents[0]?.title);
        if (sort.key === 'isActive' || sort.key === 'isEnabled') {
          const bValue = globalIntilityServiceList.find(s => s.articleId === b.id)
          return sort.key === 'isActive' ? bValue?.isActive === true : bValue?.isEnabled === true;
        }
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    })
    .filter(s => {
      return s?.textContents[0]?.title?.toLowerCase()?.includes(searchValue?.toLowerCase());
    }) : [];

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  return (
    <div className='global-editable-container'>
      <div className='global-service-settings-container'>
        <div className='global-service-settings-header'>
          <h5>Services</h5>
          <p>Select default enabled services for new customers</p>
        </div>
        <div className='global-service-settings-body'>
          <Input placeholder='Search' label='Search' hideLabel variant='outline' rightIcon icon={faSearch} onChange={e => setSearchValue(e.target.value)}/>
          <Table noBorder>
            <colgroup>
              <col className='from-large' style={{ width: '60%' }} />
              <col className='from-large' style={{ width: '24%' }} />
              <col className='from-large' style={{ width: '8%' }} />
              <col className='from-large' style={{ width: '8%' }} />
            </colgroup>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>Service name</Table.HeaderCell>
                <Table.HeaderCell>Categories</Table.HeaderCell>
                <Table.HeaderCell sorting={getSortProp('isEnabled')} onClick={onSortChangeCreator('isEnabled')}>Default Published</Table.HeaderCell>
                <Table.HeaderCell sorting={getSortProp('isActive')} onClick={onSortChangeCreator('isActive')}>Default Active</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedData?.map((a, i) => (
                <GlobalServiceSettingsRow article={a} id={a.id} key={i} editable={true} publishService={publishService} activateService={activateService}/>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  )

}

export default GlobalServiceSettings