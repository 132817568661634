import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './communication.scss';
import BasicPage from '../BasicPage';
import CommunicationEditable from './CommunicationEditable/CommunicationEditable';
import teamsLogo from 'assets/images/microsoft-teams.svg';
import telenorLogo from 'assets/images/telenor.ico';
import teliaLogo from 'assets/images/telia.ico';
import intilityLogoDark from 'assets/images/intility_favicon_dark-mode_1024px.png';
import intilityLogoLight from 'assets/images/intility_favicon_light-mode_1024px.png';
import Note from './Note/Note';
import {
  addMoblie,
  addTelephony,
  deleteMoblie,
  deleteTelephony,
  fetchCommunicationItemsAsync
} from 'redux/company/communication/communicationThunks';
import { SimpleBox } from "../../../Box/Box";
import {toggleService} from '../../../../redux/company/companyThunks';
import { Accordion, Drawer, useFloatingMessage } from "@intility/bifrost-react";
import {updateCommunicationItemsClear} from '../../../../redux/company/communication/communicationReducers';
import CommunicationBox, {CommunicationBoxNote} from './CommunicationBox/CommunicationBox';
import {platformType} from '../../../../redux/company/platformUpdateThunk';
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";
import useCurrentTheme from '../../../Navigation/useCurrentTheme';

const teams = {
  key: 0,
  name: 'Microsoft Teams',
  logo: teamsLogo
};

const telenor = {
  key: 4,
  name: 'Telenor',
  logo: telenorLogo
};

const telia = {
  key: 5,
  name: 'Telia',
  logo: teliaLogo
};

const intilityLight = {
  key: 6,
  name: 'Intility',
  logo: intilityLogoLight
};
const intilityDark = {
  key: 6,
  name: 'Intility',
  logo: intilityLogoDark
};

const Communication = () => {
  const {communicationList, information, communicationStatus} = useSelector(
    state => state.company.data.communicationPlatform
  );
  const {id} = useSelector(state => state.company.data.info);
  const [isComplete, setIsComplete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { showFloatingMessage } = useFloatingMessage();
  const theme = useCurrentTheme();

  useEffect(() => {
    if (communicationStatus.error) {
      showFloatingMessage('Ups, an error occurred, but our developers are notified, please try again later', { state: 'alert' });
    }
    dispatch(updateCommunicationItemsClear())
  }, [communicationStatus.error])

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchCommunicationItemsAsync(id));
  }, [user, dispatch, id]);

  const toggleCommunication = value => {
    setIsComplete(value);
    dispatch(toggleService(platformType.COMMUNICATION, isComplete));
  };

  const content = [
    <CommunicationEditable
      key={1}
      type={'Telephony'}
      services={communicationList}
      serviceOptions={[teams]}
      addAction={addTelephony}
      deleteAction={deleteTelephony}
      isComplete={isComplete}
      title='These are the services you have selected in terms of telephony services.'
    />,
    <CommunicationEditable
      key={2}
      type={'Mobile'}
      services={communicationList}
      serviceOptions={[telenor, telia, theme === 'light' ? intilityLight : intilityDark]}
      addAction={addMoblie}
      deleteAction={deleteMoblie}
      isComplete={isComplete}
      title='These are the services you have selected in terms of mobile services.'
    />,
    <Note key={3} note={information.note} isComplete={isComplete} />
  ];

  const boxContent = [
    <CommunicationBox
      key={1}
      type={'Telephony'}
      title='Registered telephony services.'
      services={communicationList}
      serviceOptions={[teams]}
    />,
    <CommunicationBox
      key={2}
      type={'Mobile'}
      title='Registered mobile services.'
      services={communicationList}
      serviceOptions={[telenor, telia, theme === 'light' ? intilityLight : intilityDark]}
    />,
    <CommunicationBoxNote key={3} title='Note' note={information.note} />
  ];

  return (
    <BasicPage id='communication' taskType={taskType.COMMUNICATION}
               isComplete={isComplete}
               setIsComplete={toggleCommunication}>
      <div className='content-main'>
        <SimpleBox
          className='communication-box'
          title='Collaboration and Telco Services'
          buttonText='Edit'
          hideButtonIcon={true}
          buttonClick={() => setIsOpen(true)}
        >
          <div className='bfl-grid box-list'>{boxContent.map(service => service)}</div>
        </SimpleBox>
      </div>
      <Drawer overlay header='Edit communication tools' isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div className='drawer-content'>
          <Accordion variant='styled'>{content.map(service => service)}</Accordion>
        </div>
      </Drawer>
    </BasicPage>
  );
};

export default Communication;
