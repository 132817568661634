import './teamCard.scss';
import PropTypes from "prop-types";
import { Button, Icon, SlideDown } from "@intility/bifrost-react";
import React, { useEffect, useState } from "react";
import { faArrowDown, faArrowUp, faEnvelope, faMessage, faPhone } from "@fortawesome/pro-regular-svg-icons";

export const TeamCard = ({id, team, openTeam}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (openTeam === team.id) setExpanded(true);
    else setExpanded(false);
  }, [openTeam]);

  const sortedTeam = team.teamRoles?.toSorted((a, b) => {
    if (a?.id === team?.teamManager) {
      return -1;
    } else if (b?.id === team?.teamManager) {
      return 1;
    }
    return 0;
  })
  const firstUser = sortedTeam[0];

  return (
    <div id={id} className='contact-team-card'>
      <div className='card-header'>
        <h5>{team.name}</h5>
      </div>
      <div className='card-body'>
        {firstUser ?
          <div className='team-member-row'>
            <div className='img-container'>
              <img
                src={`data:image/png;base64,${firstUser?.member?.profilePhoto}`}
                alt='img'
              />
              <div className='contact-info-box'>
                <p>{firstUser?.member?.givenName} {firstUser?.member?.surname}</p>
                <p key={firstUser.id} className='bf-small'>{firstUser.name}</p>
              </div>

            </div>

            <div className="contact-info">
              <a className='bf-title-link' href={`mailto:${firstUser?.member?.userPrincipalName}`}>
                <Icon icon={faEnvelope} />
                <p className="bf-small">{firstUser?.member?.userPrincipalName}</p>
              </a>
              <span>
                <Icon icon={faPhone} />
                <p className="bf-small">{firstUser?.member?.mobilePhone}</p>
              </span>
              <a className="bf-title-link" target="_blank" rel="noopener noreferrer"
                 href={`https://teams.microsoft.com/l/chat/0/0?users=${firstUser?.member?.userPrincipalName}`}>
                <Icon icon={faMessage} />
                <p className="bf-small">Start chat (teams)</p>
              </a>
            </div>

          </div>
            :
            undefined
        }
        <SlideDown autoScroll open={expanded}>
          {sortedTeam?.slice(1)?.map((role, j) => {
            return (role.member ?
                <div className='team-member-row' key={j}>
                  <div className='img-container'>
                    <img
                      src={`data:image/png;base64,${role?.member?.profilePhoto}`}
                      alt='img'
                    />
                    <div className='contact-info-box'>
                      <p>{role?.member?.givenName} {role?.member?.surname}</p>
                      <p key={role.id} className='bf-small'>{role.name}</p>
                    </div>

                  </div>

                  <div className="contact-info">
                    <a className='bf-title-link' href={`mailto:${role?.member?.userPrincipalName}`}>
                      <Icon icon={faEnvelope} />
                      <p className='bf-small'>{role?.member?.userPrincipalName}</p>
                    </a>
                    <span>
                    <Icon icon={faPhone} />
                    <p className='bf-small'>{role?.member?.mobilePhone}</p>
                    </span>
                    <a className="bf-title-link" target="_blank" rel="noopener noreferrer"
                       href={`https://teams.microsoft.com/l/chat/0/0?users=${role?.member?.userPrincipalName}`}>
                      <Icon icon={faMessage} />
                      <p className='bf-small'>Start chat (teams)</p>
                    </a>
                  </div>

                </div>
                :
                undefined
            )
          })}
        </SlideDown>
      </div>
        {(sortedTeam?.length > 0 && sortedTeam?.slice(1)?.some(r => r.member !== null)) &&
          <div className='card-footer'>
            <Button className='bf-title-link' icon={expanded ? faArrowUp : faArrowDown} onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Hide resources' : 'More resources'}
            </Button>
          </div>
        }
    </div>
  )

}


export default TeamCard

TeamCard.propTypes = {
  team: PropTypes.object,
  id: PropTypes.string,
  openTeam: PropTypes.string
}