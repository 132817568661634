import './userApplicationSlider.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Application, CompanyUserApplication } from "../../../../../utils/hub/models";
import { Checkbox, Drawer, FieldGroup, Icon, Input, Tabs, Table, Tooltip } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import PropTypes from "prop-types";
import { faCheckCircle, faExclamationTriangle, faSearch, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import produce from "immer";
import {
  addCompanyUserApplicationAsync,
  deleteCompanyUserApplicationAsync
} from "../../../../../redux/company/applications/applicationThunks";

const userSearchOptions = [
  { value: 'firstName', label: 'First name' },
  { value: 'lastName', label: 'Last name' },
  { value: 'department', label: 'Department' },
  { value: 'locationId', label: 'Location' }
];

const UserRow = ({relation, locationList, isEnabled, toggleSwitch, allChecked, isComplete}) => {
  const [enabled, setEnabled] = useState(isEnabled || allChecked);

  return (
    <Table.Row>
      <Table.Cell style={{minWidth: '150px', maxWidth: '180px', wordBreak: 'break-word', paddingRight: 'var(--bfs8)', paddingLeft: 'var(--bfs8)'}}>{(relation?.user?.isEnabled === false && isEnabled) && <Tooltip content='User deleted, restore in Users page or remove access.'><Icon icon={faExclamationTriangle} style={{color: 'var(--bfc-warning)'}}/></Tooltip>} {(relation.user?.firstName + ' ' + relation.user?.lastName)}</Table.Cell>
      <Table.Cell style={{maxWidth: '150px', wordBreak: 'break-word', paddingRight: 'var(--bfs8)'}}>{locationList?.find(b => b.id === relation.user?.locationId)?.name || 'Unknown'}</Table.Cell>
      <Table.Cell style={{maxWidth: '150px', wordBreak: 'break-word', paddingRight: 'var(--bfs8)'}}>{relation.user?.department}</Table.Cell>
      <Table.Cell style={{width: '125px', wordBreak: 'break-word', paddingRight: 'var(--bfs8)'}}><Checkbox label={enabled ? 'Have access' : "Grant access"} disabled={isComplete} checked={enabled} onChange={() => {
        setEnabled(!enabled);
        toggleSwitch(relation, isEnabled)
      }}/></Table.Cell>
    </Table.Row>
  )
};

UserRow.propTypes = {
  relation: PropTypes.object,
  locationList: PropTypes.array,
  isEnabled: PropTypes.bool,
  allChecked: PropTypes.bool,
  isComplete: PropTypes.bool,
  toggleSwitch: PropTypes.func,
}

const UserApplicationSlider = ({applicationId, setApplicationId, isOpen, setIsOpen, isComplete}) => {
  const { id } = useSelector(state => state.company.data.info);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const [application, setApplication] = useState(new Application());
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [notSelectedUserList, setNotSelectedUserList] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [userSearchValue, setUserSearchValue] = useState({ value: 'firstName', label: 'First name' });
  const [searchValue, setSearchValue] = useState('');
  const HasAccess = 'HasAccess';
  const HasNotAccess = 'HasNotAccess';
  const [active, setActive] = useState(HasAccess);
  const dispatch = useDispatch();
  const [sort, setSort] = useState({
    key: 'firstName',
    direction: 'asc'
  });

  const handleChange = (e, item) => {
    e.preventDefault();
    setActive(item);
  };

  const close = () => {
    setIsOpen(false);
    setSelectedUserList([]);
    setNotSelectedUserList([]);
    setApplication(new Application());
    setSearchValue('');
    setApplicationId(false);
    setActive(HasAccess);
  };

  const toggleUser = (connection, isEnabled) => {
    if (isEnabled) {
      dispatch(deleteCompanyUserApplicationAsync([connection?.connection]))
    } else {
      const relation = produce(new CompanyUserApplication(), draft => {
        draft.softwareId = application?.softwareId;
        draft.companyUserEmail = connection?.user?.primaryEmailAddress;
        draft.companyUserId = connection?.user?.id;
        draft.applicationId = application?.id;
        draft.companyId = id;
      });
      dispatch(addCompanyUserApplicationAsync([relation]));
    }

  }

  const toggleSelectedUsers = (list, isEnabled) => {
    if (isEnabled) {
      dispatch(deleteCompanyUserApplicationAsync(list.map(c => c?.connection)))
    } else {
      const appList = list.map(relation => {
        return produce(new CompanyUserApplication(), draft => {
          draft.softwareId = application?.softwareId;
          draft.companyUserEmail = relation?.user?.primaryEmailAddress;
          draft.companyUserId = relation?.user?.id;
          draft.applicationId = application?.id;
          draft.companyId = id;
        });
      });
      dispatch(addCompanyUserApplicationAsync(appList));
    }
    setAllChecked(true);
  }

  useEffect(() => {
    setAllChecked(false);
  }, [applicationList])

  const showTabContent = activeTab => {
    switch (activeTab) {
      case HasAccess:
        return <div className='tab-bar-body'>{hasAccess(selectedUserList)}</div>;
      case HasNotAccess:
        return <div className='tab-bar-body'>{hasNotAccess(notSelectedUserList)}</div>;
    }
  };

  useEffect(() => {
    const application = applicationList?.find(app => app.id === applicationId);
    if (application !== undefined) {
      setApplication(application);
      const newUserList = [];
      const notNewUserList = [];
      application?.companyUsers?.forEach(u => {
        const item = userList.find(i => i.id === u.companyUserId);
        if (item !== undefined && u?.isDisabled === false) {
          newUserList.splice(0, 0, { user: item, connection: u });
        }
      });
      setSelectedUserList(newUserList);
      userList?.forEach(i => {
        const item = newUserList?.find(u => u?.user?.id === i.id);
        if (!item || item?.isDisabled === true) {
          notNewUserList.push({ user: i, connection: {} });
        }
      });
      setNotSelectedUserList(notNewUserList);
    }
  }, [applicationId, applicationList, userList]);

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');


  const userTable = (users, isEnabled) => {
    const sortedData = users
      .filter(s => {
        if (userSearchValue.value === 'locationId') {
          return locationList?.find(b => b.id === s.user?.locationId)?.name.toLowerCase()?.includes(searchValue?.toLowerCase());
        } else {
          return s.user[userSearchValue.value]?.toLowerCase()?.includes(searchValue?.toLowerCase());
        }
      })
      .sort((a, b) => {
        if (sort.direction === 'asc') {
          return a.user[sort.key]?.toString()?.localeCompare(b.user[sort.key]?.toString());
        } else {
          return b.user[sort.key]?.toString()?.localeCompare(a.user[sort.key]?.toString());
        }
      });

    return (
      <div className='selected-list'>
        <div>
          <FieldGroup className='search-bar'>
            <Input
              label='Search'
              hideLabel
              placeholder='Search'
              clearable
              icon={faSearch}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              rightIcon
            />
            <Select
              label='Search'
              hideLabel
              placeholder={userSearchValue.label}
              options={userSearchOptions}
              onChange={e => setUserSearchValue(e)}
            />
          </FieldGroup>
        </div>
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{paddingRight: 'var(--bfs8)', paddingLeft: 'var(--bfs8)'}} sorting={getSortProp('firstName')} onClick={onSortChangeCreator('firstName')}>User</Table.HeaderCell>
              <Table.HeaderCell style={{paddingRight: 'var(--bfs8)', paddingLeft: 'var(--bfs8)'}} sorting={getSortProp('locationId')} onClick={onSortChangeCreator('locationId')}>Location</Table.HeaderCell>
              <Table.HeaderCell style={{paddingRight: 'var(--bfs8)', paddingLeft: 'var(--bfs8)'}} sorting={getSortProp('department')} onClick={onSortChangeCreator('department')}>Department</Table.HeaderCell>
              <Table.HeaderCell style={{paddingRight: 'var(--bfs8)', paddingLeft: 'var(--bfs8)'}}><Checkbox checked={allChecked} disabled={isComplete} onChange={() => toggleSelectedUsers(sortedData, isEnabled)} label={!isEnabled ? "Check all" : 'Uncheck all'}/></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {sortedData.length > 0 ?
            <Table.Body>
              {sortedData.map((u, i) => {
                return <UserRow key={i + u.user?.id} isEnabled={isEnabled} relation={u} locationList={locationList} allChecked={allChecked} toggleSwitch={toggleUser} isComplete={isComplete}/>
              })}
            </Table.Body> :
            <div/>
          }
        </Table>
      </div>
    );
  }


  const hasAccess = sortedData => {
    return userTable(sortedData, true);
  };

  const hasNotAccess = sortedData => {
    return userTable(sortedData, false);
  };


  return (
    <Drawer
      overlay
      noPadding
      header={'Users with access to ' + application.name}
      isOpen={isOpen}
      onRequestClose={() => close()}
      className='application-user-slider'
    >
      <Tabs variant={'styled'} className='slider-tabs'>
        <a href='#' onClick={e => handleChange(e, HasAccess)} className={active === HasAccess ? 'active' : ''}>
          <Icon icon={faCheckCircle} style={{color: 'var(--bfc-success)', marginRight: 'var(--bfs4)'}}/>Users with access: {selectedUserList.length}
        </a>
        <a
          href='#'
          onClick={e => handleChange(e, HasNotAccess)}
          className={active === HasNotAccess ? 'active' : ''}
        >
          <Icon icon={faTimesCircle} style={{color: 'var(--bfc-neutral)', marginRight: 'var(--bfs4)'}}/>Users without access: {notSelectedUserList.length}
        </a>
      </Tabs>
      <div className='tab-bar-content'>
        {showTabContent(active)}
      </div>
    </Drawer>
  );
};

UserApplicationSlider.propTypes = {
  setApplicationId: PropTypes.func,
  applicationId: PropTypes.any,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  isComplete: PropTypes.bool
};

export default UserApplicationSlider;