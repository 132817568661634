import EditNetwork from '../../components/BackOffice/EditComponents/EditNetwork';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Network',
  path: 'network/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditNetwork
};
