import MobileDevices from '../../../components/Hub/Pages/Devices.MobileDevices';
import {faPhoneLaptop} from '@fortawesome/pro-solid-svg-icons';

export default {
  title: 'Mobile devices',
  path: 'mobile-devices/Compliance Policies',
  isEnabled: (company) => {return company?.mobilePlatform?.state},
  component: MobileDevices
};

export const ConditionalData = {
  title: 'Conditional data',
  path: 'mobile-devices/Conditional Access',
  icon: faPhoneLaptop,
  isEnabled: (company) => {return company?.mobilePlatform?.state},
  component: MobileDevices
};

export const AppsData = {
  title: 'Apps data',
  path: 'mobile-devices/Apps',
  icon: faPhoneLaptop,
  isEnabled: (company) => {return company?.mobilePlatform?.state},
  component: MobileDevices
};