import {faBuilding} from '@fortawesome/pro-regular-svg-icons';
import EditRotLocations from '../../components/BackOffice/EditComponents/EditRotLocations';

export default {
  title: 'CompanyService Locations',
  path: 'locations/edit',
  icon: faBuilding,
  editable: true,
  component: EditRotLocations
};
