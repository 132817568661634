import { Badge, Button, StepBar, Tooltip } from "@intility/bifrost-react";
import './networkProgressBox.scss';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommentRow, { commentTypes } from "./CommentRow";

export const DomainProgressBox = ({domain}) => {
  const {information} = useSelector(state => state.company.data.domainPlatform);
  const [visible, setVisible] = useState(false);

  const isColored = () => {
    let number = 0;
    if (domain?.domainStatus?.establishedDialogue === 1) number = 1;
    if (domain?.domainStatus?.establishedDialogue === 2) number = 2;
    if (domain?.domainStatus?.scheduledTransfer === 1) number = 3;
    if (domain?.domainStatus?.scheduledTransfer === 2) number = 4;
    return number;
  };

  const isComplete = () => {
    return (
      domain?.domainStatus?.establishedDialogue === 2 &&
      domain?.domainStatus?.scheduledTransfer === 2
    );
  };

  const getClass = (domainValue, nextLimmit, itemValue) => {
    let classes = '';
    if (domainValue > nextLimmit) classes += 'next-checked ';
    if (domainValue === nextLimmit || domainValue === nextLimmit - 1) classes += 'current-checked ';
    if (itemValue === 1) classes += 'current-half-checked ';
    if (classes === '') classes += 'prev-checked ';
    return classes;
  };

  const getLastClass = (appValue, lastLimmit, itemValue, complete) => {
    let classes = '';
    if (appValue > lastLimmit && !complete) classes += 'next-checked current-checked ';
    if (appValue > lastLimmit && complete) classes += 'next-checked ';
    if (itemValue === 1) classes += 'current-half-checked ';
    if (classes === '') classes += 'prev-checked ';
    return classes;
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 2000);
    }
  }, [visible])

  const domainValue = isColored();
  const complete = isComplete();

  const sortedComments = domain?.domainStatus?.comments && [...domain?.domainStatus?.comments]?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <div className='network-progress-box'>
      <span><p>{domain?.name} {domain?.registrar && `(${domain?.registrar})`}</p>{information.primaryId === domain.id && <Badge>Main domain</Badge>}</span>
      <div className='box-stepbar'>
        {domain?.moveToIntility ? (
          <StepBar variant='circle'>
            <StepBar.Item
              active={domain?.domainStatus?.establishedDialogue > 0}
              className={getClass(domainValue, 2, domain?.domainStatus?.establishedDialogue)}
            >
              {domain?.domainStatus?.establishedDialogue === 1 ? 'Appinfo sent' : 'Established dialogue with current admin'}
            </StepBar.Item>
            <StepBar.Item
              active={domain?.domainStatus?.scheduledTransfer > 0}
              className={getLastClass(domainValue, 2, domain?.domainStatus?.scheduledTransfer, complete)}
            >
              Scheduled transfer to Intility
            </StepBar.Item>
            <StepBar.Item active={complete} className={complete ? 'current-checked last-element ' : 'prev-checked '}>
              Completed (transferred to Intility)
            </StepBar.Item>
          </StepBar>
        ) : (
        <p className='bf-em' style={{margin: '0', color: 'var(--bfc-base-c-2)'}}>
          Domain will not be transferred to Intility
        </p>
        )}
      </div>
      <div className='box-details' style={domain?.domainStatus?.comments?.length === 0 ? {padding: 0, borderTop: 0} : undefined}>
        <div className='domain-details'>
          {sortedComments?.map(c => {
            return <CommentRow key={c.id} comment={c} notEditable={true} commentType={commentTypes.DOMAIN} />
          })}
        </div>
      </div>
    </div>
  );
};

DomainProgressBox.propTypes = {
  domain: PropTypes.object
};

export default DomainProgressBox;
