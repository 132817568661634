import {Card} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import {produce} from 'immer';
import PropTypes from 'prop-types';
import React from 'react';
import {recommendedValues} from '../mobileCompliancePolicyData';

const formatOptionLabel = (label, isRecommended) => {
  return (
    <div style={{display: 'flex'}}>
      <div>{label}</div>
      {isRecommended && <div style={{color: '#969696'}}>&nbsp;(recommended)</div>}
    </div>
  );
};

export default function EditPolicies({compliancePolicy, formState, setFormState, isComplete, policyType}) {
  return (
    <div className='accordion-cards-container'>
      {compliancePolicy.policies.map((policy, i) => {
        const specificPolicyValue = formState[policy.name];
        if (specificPolicyValue === undefined) return null;
        const value = policy.options.find(option => option.value === specificPolicyValue);

        return (
          <Card key={policy.title + i} className='bfl-autocol'>
            <Card.Content>
              <div>
                <p className='bf-strong' style={{margin: 0, color: 'var(--bfc-base-c-1)'}}>
                  {policy.title}
                </p>
                <p style={{marginTop: 0}}>{policy.description}</p>
              </div>
              <Select
                label={policy.name}
                hideLabel
                options={policy.options}
                isSearchable={false}
                value={value}
                isDisabled={isComplete}
                formatOptionLabel={({label, value}) =>
                  formatOptionLabel(label, recommendedValues[policyType][policy.name] === value)
                }
                onChange={option => {
                  const newState = produce(formState, draft => {
                    draft[policy.name] = option.value;
                  });
                  setFormState(newState);
                }}
              />
            </Card.Content>
          </Card>
        );
      })}
    </div>
  );
}

EditPolicies.propTypes = {
  compliancePolicy: PropTypes.object,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  isComplete: PropTypes.bool
};
