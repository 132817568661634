import React from 'react';
import PropTypes from 'prop-types';
import { publishDataOptions, taskType } from "../../../utils/hub/pageData/pageDataOptions";
import TaskTopBar from "./TaskTopBar";

const BasicPage = ({children, id, taskType, isComplete, togglePrompt, setIsComplete, fullScreen}) => {
  return (
    <div id={id}>
      {(taskType && !fullScreen) &&
        <TaskTopBar taskType={publishDataOptions[taskType]} isComplete={isComplete} setIsComplete={setIsComplete} togglePrompt={togglePrompt}/>
      }
      <div className='content'>{children}</div>
    </div>
  );
};

BasicPage.propTypes = {
  children: PropTypes.any,
  taskType: PropTypes.number,
  isComplete: PropTypes.bool,
  fullScreen: PropTypes.bool,
  togglePrompt: PropTypes.bool,
  setIsComplete: PropTypes.func,
  id: PropTypes.string
};

export default BasicPage;
