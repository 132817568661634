import FinancialDetails from '../../components/BackOffice/FinancialDetails/FinancialDetails';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Finance',
  path: 'finance/',
  icon: faMoneyBill,
  editable: false,
  component: FinancialDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
