import React, {useEffect, useState} from 'react';
import './editContactSlider.scss';
import {useDispatch} from 'react-redux';
import produce from 'immer';
import {Contact} from 'utils/hub/models';
import Flex from 'components/Flex/Flex';
import {Button, Drawer} from '@intility/bifrost-react';
import {updateContact} from '../../../../../redux/company/companyThunks';
import devLog from '../../../../../utils/devLog';
import PropTypes from 'prop-types';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import {isStateValid, validateContact} from '../../../../../utils/wizard/stateValidations';

const onChange = (e, setState) => {
  const {name, value} = e.target;

  setState(
    produce(draft => {
      draft[name] = value;
    })
  );
};

const EditContactSlider = ({closeModal, isOpen, contact}) => {
  const dispatch = useDispatch();
  const [editedContact, setEditedContact] = useState(new Contact());
  const [validation, setValidation] = useState(undefined);

  useEffect(() => {
    if (contact !== undefined && contact !== null && isOpen === true) {
      setEditedContact(contact);
    }
  }, [isOpen]);

  const onSave = () => {
    const errors = validateContact(editedContact);
    setValidation(errors);
    const isValid = isStateValid(errors);
    if (isValid && editedContact !== undefined && editedContact !== null) {
      devLog('Updating!', contact);
      closeModal();
      dispatch(updateContact(contact.id, editedContact));
    }
  };

  const contactOnChange = e => {
    onChange(e, setEditedContact);
  };

  return (
    <Drawer
      header='Edit contact'
      isOpen={isOpen}
      onRequestClose={() => {
        setEditedContact(new Contact())
        setValidation(undefined);
        closeModal();
      }}
      overlay
      footer={
        <div className='contact-drawer-bottom-button-row'>
          <Button
            style={{marginRight: 'var(--bfs16)'}}
            onClick={() => {
              setEditedContact(new Contact())
              setValidation(undefined);
              closeModal();
            }}
            key={0}
          >
            Cancel
          </Button>
          <Button variant='filled' onClick={() => onSave()} key={1}>
            Save
          </Button>
        </div>
      }
    >
      <form
        onSubmit={event => {
          event.preventDefault();
          onSave();
        }}
        className='edit-contact bfl-grid'
      >
        <InputWithFeedback
          onChange={contactOnChange}
          value={editedContact.firstName}
          name='firstName'
          label='First name'
          required={true}
        />
        <InputWithFeedback
          onChange={contactOnChange}
          value={editedContact.lastName}
          name='lastName'
          label='Last name'
          required={true}
        />
        <InputWithFeedback
          onChange={contactOnChange}
          value={editedContact.phoneNumber}
          name='phoneNumber'
          label='Phone Number'
          required={true}
          otherProps={
            validation?.phoneNumber && {
              state: 'warning',
              feedback: 'Valid example: +4745454545'
            }
          }
        />
        <InputWithFeedback
          onChange={contactOnChange}
          value={editedContact.email}
          name='email'
          label='E-mail'
          required={true}
          otherProps={
            validation?.email && {
              state: 'warning',
              feedback: 'Missing value'
            }
          }
        />
      </form>
    </Drawer>
  );
};

EditContactSlider.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  contact: PropTypes.object
};

export default EditContactSlider;
