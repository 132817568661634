import React, { useEffect, useState } from "react";
import "./information.scss";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Icon, Input, Modal, Table, TextArea } from "@intility/bifrost-react";
import { faCastle, faCoins, faLaptop, faLink, faSearch } from "@fortawesome/pro-regular-svg-icons";
import InformationBox from "../Components/InformationBox";
import teamsLogo from "assets/images/software_logos/teams2.png";
import { fetchDomainsAsync } from "../../../redux/company/domain/domainThunks";
import {
  departmentType,
  GptApplication,
  GptApplicationDescriptions,
  GptLocation, GptLocationDescriptions,
  GptUser, GptUserDescriptions
} from "../../../utils/hub/models";
import apiFetch from "../../../utils/apiFetch";
import Markdown from "react-markdown";

export const GptImportType = {
  USERS: 'user',
  APPLICATIONS: 'application',
  LOCATIONS: 'location'
}

export const GptImportQuery = (type, object) => {
  return `Find ${type} objects in this data, convert it to a json array of objects in this format ${JSON.stringify(object)}. If you cannot find a value to insert from data, use empty string. Do not insert Unknown, N/A, Ukjent. data: `
}

export const ModalTable = ({ dataType, response }) => {
  let keys;
  switch (dataType) {
    case GptImportType.USERS:
      keys = Object.keys(new GptUser());
      break;
    case GptImportType.APPLICATIONS:
      keys = Object.keys(new GptApplication());
      break;
    case GptImportType.LOCATIONS:
      keys = Object.keys(new GptLocation());
      break;
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {keys?.map(k =>
            <Table.HeaderCell>{k[0]?.toUpperCase() + k?.substring(1)}</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {response && response?.map(c => {
            return (
              <Table.Row>
                {keys?.map(k =>
                  <Table.Cell>{c[k]}</Table.Cell>
                )}
              </Table.Row>
            )
          }
        )}
      </Table.Body>
    </Table>
  )
}


export const Information = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {domainList} = useSelector(state => state.company.data.domainPlatform);
  const {primaryId} = useSelector(state => state.company.data.domainPlatform.information);
  const {detailList, information: detailsInformation} = useSelector(state => state.company.data.detailsPlatform);
  const [primaryDomain, setPrimaryDomain] = useState('');
  const [gptResponse, setGptResponse] = useState('');
  const [gptMarkdown, setGptMarkdown] = useState('');
  const [currentDataType, setCurrentDataType] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const {user} = useSelector(state => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchDomainsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    const domain = domainList?.find(i => i.id === primaryId);
    setPrimaryDomain(domain);
  }, [domainList, primaryId]);

  const importGptData = (dataType, object) => {
    setCurrentDataType(dataType);
    setModalContent(dataType);
    const query = GptImportQuery(dataType, object);
    console.log(query + searchValue)
    return search(query, searchValue, dataType);
  }

  const openContentModal = (dataType) => {
    setOpenModal(true);
    setModalContent(dataType);
  }


  const search = (query, input, dataType) => {
    setLoading(true);
    const queryInput = ({
      role: 'user',
      content: input,
      query: query
    })
    return apiFetch(`/IntilityGpt/formatUploadChunked`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryInput)
    }).then(response => {
      if (response.ok) return response.json();
      return response.json().then(response => {throw response})
    }).then(res => {
      const result = res?.content?.replaceAll('][', ',');
      setGptMarkdown(result);
      const test1 = res?.content.substring(7);
      if (test1.startsWith('json')) {
        const test2 = test1.slice(0, -3);
        setGptResponse(JSON?.parse(test2));
        setGptMarkdown(res?.content);
      } else {
        setGptResponse(JSON?.parse(result));
        setGptMarkdown(result);
      }
      setLoading(false);
      openContentModal(dataType);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });

  }

  const onboardGptSearch = (query) => {
    setLoading(true);
    return apiFetch(`/IntilityGpt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(query)
    }).then(response => {
      if (response.ok) return response.json();
      return response.json().then(response => {throw response})
    }).then(res => {
      setGptResponse(res);
      setGptMarkdown(res[0]?.message?.content);
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });

  }

  useEffect(() => {
    console.log(gptResponse)
  }, [gptResponse]);

  const getDetail = (name, department) => {
    return detailList?.filter(d => d.department === department)?.find(i => i.name === name);
  };

  const getMonney = new Intl.NumberFormat('no', {
    style: 'currency',
    currency: 'NOK'
  });

  const estimatedSum = () => {
    const EstimatedRevenue = getDetail('EstimatedRevenue', departmentType.FINANCE)?.stringValue || 0;
    const EstimatedPIAS = getDetail('EstimatedPIAS', departmentType.FINANCE)?.stringValue || 0;
    const EstimatedComlines = getDetail('EstimatedComlines', departmentType.FINANCE)?.stringValue || 0;
    const EstimatedLicenses = getDetail('EstimatedLicenses', departmentType.FINANCE)?.stringValue || 0;
    return getMonney.format(+EstimatedRevenue + +EstimatedPIAS + +EstimatedComlines + +EstimatedLicenses);
  }

  return (
    <div id='backoffice-information'>
      <div className='content-section'>
        <h3>Basic information</h3>
        <div className='bfl-grid container-box'>
          <InformationBox image={<Icon icon={faLaptop} />} leftText={'Domain'} value={primaryDomain?.name} />
          <InformationBox
            image={<img src={teamsLogo} alt={'Teams'} />}
            leftText={'Project team'}
            value={detailsInformation?.projectTeam}
            link={true}
          />
          <InformationBox
            image={<Icon icon={faLink} />}
            leftText={'Contract'}
            value={getDetail('ContractLink', departmentType.GENERAL)?.stringValue}
            link={true}
          />
          <InformationBox image={<Icon icon={faCastle} />} leftText={'Existing customer'} emptyState={
            <Badge state='neutral'>
              New customer
            </Badge>
          } value={detailsInformation?.parentCompany} />
          <InformationBox
            image={<Icon icon={faCoins} />}
            leftText={'Estimated Revenue'}
            value={estimatedSum()}
          />
          <InformationBox
            image={<Icon icon={faCoins} />}
            leftText={'Establishment Cost'}
            value={getMonney.format(getDetail('EstablishmentCost', departmentType.FINANCE)?.stringValue || 0)}
          />
        </div>
      </div>
      <div style={{marginTop: 'var(--bfs12)'}}>
        <Input value={searchValue} iconButton={true} icon={faSearch} rightIcon={true} onKeyDown={(event) => {
          if (event.key==="Enter") {
            onboardGptSearch(searchValue)
          }
        }} onIconClick={() => onboardGptSearch(searchValue)} tabIndex={0} onChange={e => setSearchValue(e.target.value)} label='OnboardGPT' description='Ask OnboardGPT about Onboard'
               loading={loading} />
        <div style={{borderRadius: '0 0 var(--bfs12) var(--bfs12)', backgroundColor: 'var(--bfc-theme-c-1)'}}>
          <Markdown className='bfl-padding'>{gptMarkdown}</Markdown>
        </div>
        <Button.Group style={{marginTop: 'var(--bfs12)'}}>
          <Button onClick={() => importGptData(GptImportType.USERS, new GptUserDescriptions())}>Translate query to User import</Button>
          <Button onClick={() => importGptData(GptImportType.APPLICATIONS, new GptApplicationDescriptions())}>Translate query to Application import</Button>
          <Button onClick={() => importGptData(GptImportType.LOCATIONS, new GptLocationDescriptions())}>Translate query to Location import</Button>
        </Button.Group>
      </div>
      <Modal isOpen={openModal} header={`Import ${modalContent} content`} onRequestClose={() => setOpenModal(false)}>
        <Table>
          <ModalTable dataType={currentDataType} response={gptResponse}/>
        </Table>
      </Modal>
    </div>
  );
};

export default Information;
