import {Spinner, Tabs} from '@intility/bifrost-react';
import React, { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { NavLink, Route, Switch } from "react-router-dom";
import {getMobileDevicesData} from 'redux/company/mobileDevices/mobileDevicesSlice';
import Apps from './Apps';
import CompliancePolicies from './CompliancePolicies';
import ConditionalAccess from './ConditionalAccess';
import './mobileDevices.scss';
import BasicPage from "../BasicPage";
import { publishDataOptions, taskType } from "../../../../utils/hub/pageData/pageDataOptions";
import TaskTopBar from "../TaskTopBar";

export default function MobileDevices() {
  const companyID = useSelector(store => store.company.data.info.id);
  const {mobileDevices} = useSelector(state => state.company.data.mobilePlatform);
  const dispatch = useDispatch();

  const isLoading =
    (mobileDevices.loading === false && (mobileDevices.apple || mobileDevices.android) === null) ||
    mobileDevices.loading;

  useEffect(() => {
    if (companyID) dispatch(getMobileDevicesData(companyID));
  }, [companyID]);

  if (isLoading) return <Spinner />;

  return (
    <div>
      <TaskTopBar taskType={publishDataOptions[taskType.MOBILECOMPLIANCE]} extraTypes={[taskType.MOBILEAPPS, taskType.MOBILECONDITIONAL]}/>
      <Tabs variant='styled'>
        <NavLink style={{zIndex: '101'}} to={`/${companyID}/mobile-devices/Compliance Policies`}>
          Compliance Policies
        </NavLink>
        <NavLink style={{zIndex: '101'}} to={`/${companyID}/mobile-devices/Conditional Access`}>
          Conditional Access
        </NavLink>
        <NavLink style={{zIndex: '101'}} to={`/${companyID}/mobile-devices/Apps`}>
          Apps
        </NavLink>
      </Tabs>
      <BasicPage id='mobile-content'>
        <div className='content-main'>
          <Switch>
            <Route path={`/${companyID}/mobile-devices/Compliance Policies`}>
              <CompliancePolicies/>
            </Route>
            <Route path={`/${companyID}/mobile-devices/Conditional Access`}>
              <ConditionalAccess/>
            </Route>
            <Route path={`/${companyID}/mobile-devices/Apps`}>
              <Apps/>
            </Route>
          </Switch>
        </div>
      </BasicPage>
    </div>
  );
}
