import {createAction} from '@reduxjs/toolkit';
import {normalizeMicrosoft365} from './ms365Normalizers';

export const fetchInformationSuccess = createAction(
  'microsoft365Action/fetchInformationSuccess',
  apiMicrosoft365Platform => ({
    payload: normalizeMicrosoft365(apiMicrosoft365Platform)
  })
);

export const updateMicrosoft365InformationSuccess = createAction(
  'microsoft365Action/updateInformationSuccess',
  apiMicrosoft365Platform => ({
    payload: normalizeMicrosoft365(apiMicrosoft365Platform)
  })
);