import {faPrint} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 5,
  text: 'All printers registered',
  icon: faPrint,
  isEnabled: data => {
    return data.printerPlatform?.information?.isEnabled;
  },
  isEnabledOverview: data => {
    return data.printerPlatform?.isEnabled;
  },
  getStatusText: data => {
    return data.printerPlatform.information.modifiedAt;
  }
};
