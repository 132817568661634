import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BasicPage from '../BasicPage';
import { downloadLocationCsv, fetchLocationsAsync } from "../../../../redux/company/locations/locationThunks";
import { SimpleBox } from "../../../Box/Box";
import './locations.scss';
import { Button, Input, Message } from "@intility/bifrost-react";
import {faSearch} from '@fortawesome/pro-solid-svg-icons';
import {toggleService} from '../../../../redux/company/companyThunks';
import {fetchBillingAsync} from '../../../../redux/company/billing/billingThunks';
import {platformType} from '../../../../redux/company/platformUpdateThunk';
import {fetchUsersAsync} from 'redux/company/users/userThunks';
import { faFileCsv, faFileZipper, faPlus } from "@fortawesome/pro-regular-svg-icons";
import LocationCard from "./components/LocationCard/LocationCard";
import NewLocationModal from "./components/NewLocationModal/NewLocationModal";
import { hasCompanyInfo, hasContactInfo } from "./components/LocationInformation/accordion/DeliveryAccordionContent";
import { IntilityPermission } from "../../../Permissions";
import apiFetch from "../../../../utils/apiFetch";
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";

export const fetchLocationProgress = (location) => {
  const weight = 100 / 22;
  let progress = 100;

  if (location?.name === '') progress -= weight;
  if (location?.address === '') progress -= weight;
  if (location?.zipCode === '') progress -= weight;
  if (location?.postalPlace === '') progress -= weight;
  if (location?.country === '') progress -= weight;
  if (location?.locationTypes === null || location?.locationTypes === '') progress -= weight;
  if (!hasContactInfo(location?.locationContact)) progress -= weight;

  // Delivery
  if (!hasContactInfo(location?.recipientContact)) progress -= weight;
  if (!hasCompanyInfo(location?.recipientCompany)) progress -= weight;

  if (location?.eoriNumber !== 'Not relevant') {
    if (!hasContactInfo(location?.financialContact)) progress -= weight;
    if (!hasCompanyInfo(location?.legalCompany)) progress -= weight;
  }

  if (location?.eoriNumber === null || location?.eoriNumber === '') progress -= weight;
  if (location?.billingId === null || location?.billingId === '') progress -= weight;

  // COMLINES
  if (location?.comlinesConnection === null || location?.comlinesConnection === '') progress -= weight;
  if (location?.leaseTime === null || location?.leaseTime === '') progress -= weight;
  if (location?.intilityManaged === 0) progress -= weight;
  if (location?.lineNumber === null || location?.lineNumber === '') progress -= weight;
  if (location?.secondaryLineNumber === null || location?.secondaryLineNumber === '') progress -= weight;
  if (location?.isp === null || location?.isp === '') progress -= weight;

  // NETWORK
  if (location?.isThirdPartyShared === 0) progress -= weight;
  if (location?.hasEnoughCabledConnections === 0) progress -= weight;

  // WIFI
  if (location?.wifiCoverage === null || location?.wifiCoverage === '') progress -= weight;

  return Math.round(progress);
}

export const downloadFloorPlanZip = (id) => {
  const relativeUrl = `/companies/${id}/floorPlans`;
  apiFetch(relativeUrl)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${id}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.log('Error while fetching file: ', error);
    });
};

const Locations = () => {
  const {information, locationList} = useSelector(state => state.company.data.locationPlatform);
  const {id} = useSelector(state => state.company.data.info);
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [search, setSearch] = useState('');
  const [activeBtn, setActiveBtn] = useState('all');

  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  const toggleLocation = value => {
    setIsComplete(value);
    dispatch(toggleService(platformType.LOCATIONS, isComplete));
  };
  const addNewLocation = () => {
    setIsOpen(true);
  };

  // fetch locations
  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);


  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchBillingAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchUsersAsync(id));
  }, [user, dispatch, id]);

  const sortedData = locationList
    .filter(a => a.isEnabled === true)
    .filter(a => {
      if (activeBtn === 'complete') return fetchLocationProgress(a) === 100;
      if (activeBtn === 'incomplete') return fetchLocationProgress(a) < 100;
      else return a;
    })
    .filter(s => s.name?.toLowerCase()?.includes(search?.toLowerCase()));

  return (
    <>
      <BasicPage id='locations' taskType={taskType.LOCATIONS}
                 isComplete={isComplete}
                 setIsComplete={toggleLocation}>
        <div className='content-main'>
          <SimpleBox
            className='locations-box'
            title='Locations'
          >
            <div key='Search' className='content-main-search'>
              <div>
                <Input
                  label=''
                  placeholder='Search for location'
                  icon={faSearch}
                  rightIcon
                  onChange={e => setSearch(e.target.value)}
                />
                <div style={{marginTop: 'auto'}}>
                  <Button.Group>
                    <Button
                      active={activeBtn === 'all'}
                      onClick={() => setActiveBtn('all')}
                    >Show all</Button>
                    <Button
                      active={activeBtn === 'complete'}
                      onClick={() => setActiveBtn('complete')}
                    >Complete</Button>
                    <Button
                      active={activeBtn === 'incomplete'}
                      onClick={() => setActiveBtn('incomplete')}
                    >Incomplete</Button>
                  </Button.Group>
                </div>
              </div>
              <div>
                <IntilityPermission>
                  <Button style={{marginRight: 'var(--bfs8)'}} onClick={() => downloadFloorPlanZip(id)} icon={faFileZipper} >Download files</Button>
                  <Button style={{marginRight: 'var(--bfs8)'}} onClick={() => dispatch(downloadLocationCsv())} icon={faFileCsv} >Download csv</Button>
                </IntilityPermission>
                <Button icon={faPlus} variant={"filled"} disabled={isComplete} onClick={() => addNewLocation()}>Add new location</Button>
              </div>
            </div>
            <div className='location-cards bfl-autocol'>
              {locationList && locationList?.length > 0 ? (
                sortedData.map(location => {
                  if (location.name?.toLowerCase()?.includes(search?.toLowerCase())) {
                    return (
                      <LocationCard
                        key={location.id}
                        location={location}
                        isComplete={isComplete}
                      />
                    );
                  }
                  return null;
                })
              ) : (
                <Message header='No locations to show'>Create a location to get started</Message>
              )}
            </div>
          </SimpleBox>
        </div>
        <NewLocationModal setIsOpen={setIsOpen} isOpen={isOpen}/>
      </BasicPage>
    </>
  );
};

export default Locations;
