import './finance.scss';
import { Badge, Button, FormatDate, Grid, Icon, Spinner } from "@intility/bifrost-react";
import {
  faArrowLeft, faArrowRight,
  faBrowser,
  faBuilding,
  faCoins,
  faTimes,
  faUser
} from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "@intility/bifrost-react-datepicker";
import { Redirect, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useIntilityPermission from "../../hooks/useIntilityPermission";
import { useDispatch, useSelector } from "react-redux";
import { CustomerCard, MoneyCard } from "../CompanyOverviewNew/OverviewCard";
import { useMeasure } from "../CompanyOverviewNew/CompanyOverviewNew";
import { fetchFinancialDetailsAsync } from "../../redux/company/details/detailThunks";
import { emptyGuid } from "../../utils/guid";

export const Finance = () => {
  const {data} = useSelector(state => state.companies);
  const {detailStatus} = useSelector(state => state.company.data.detailsPlatform);
  const {financialDetails} = useSelector(state => state);
  const {user} = useSelector(state => state.auth);
  const [estimatedRev, setEstimatedRev] = useState('0');
  const [actualRev, setActualRev] = useState('0');
  const [topMonthly, setTopMothly] = useState(0);
  const [startWeek, setStartWeek] = useState(1);
  const [roundedMonthly, setRoundedMonthly] = useState(0);
  const [accumulated, setAccumulated] = useState(0);
  const [weeklySums, setWeeklySums] = useState([]);
  const [activeGraph, setActiveGraph] = useState([]);
  const [activeWeeks, setActiveWeeks] = useState([]);
  const [biggestComp, setBiggestComp] = useState({});
  const [year, setYear] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [completeYear, setCompleteYear] = useState([]);
  const intilityUser = useIntilityPermission();
  const dispatch = useDispatch();
  const history = useHistory();
  const canvas = document.getElementById("canvas");
  const [bind, {width: viewWidth, height: viewHeight}] = useMeasure();
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const currentDate = new Date(Date.now())


  useEffect(() => {
    if (canvas === null) return;
    const ctx = canvas.getContext("2d");
    const { width, height } = canvas.getBoundingClientRect()

    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width
      canvas.height = height
      setCanvasHeight(height);
      setCanvasWidth(width);
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath(); // Start a new path
    ctx.strokeStyle = '#FF5842';
    ctx.lineWidth = 3;
    let strokeWidth = (canvas.width - 150) / 52;
    if (strokeWidth < 24) strokeWidth = 24
    let initX = 75;
    let initY = 738;


    ctx.moveTo(initX, initY); // Move the pen to (30, 50)
    ctx.lineTo(initX, initY);
    ctx.stroke();

    completeYear.forEach(w => {
      if (w.companies.length > 0) {
        w.companies.forEach(c => {
          const value = (((Math.round((c.monthlyValue * 100) / accumulated) * 7)) || 0);
          initY -= value;
        });

      }
      initX += strokeWidth;
      ctx.lineTo(initX, initY);
      ctx.stroke();
    });


  }, [canvas, activeWeeks, completeYear, activeGraph, viewWidth])

  useEffect(() => {
    dispatch(fetchFinancialDetailsAsync(emptyGuid));
  }, [intilityUser]);

  useEffect(() => {
    let estimatedRev = 0;
    let actualRev = 0;
    const money = [];

    completeYear?.forEach(w => {

      w.companies.forEach(c => {
        const index = money.findIndex(m => m.week === w.week)
        const activeWeek = (activeWeeks.some(a => a?.week === w.week) || activeWeeks.length === 0)

        if (activeWeek) {
          actualRev += c.actual;
        }

        if (c.actual > 0) {
          if (activeWeek) estimatedRev += c.actual;
          if (index !== -1) {
            money[index].sum += c.actual;
          } else {
            money.push({week: w.week, sum: c.actual})
          }
        } else if (c.estimated > 0) {
          if (activeWeek) estimatedRev += c.estimated;
          if (index !== -1) {
            money[index].sum += c.estimated;
          } else {
            money.push({week: w.week, sum: c.estimated})
          }
        } else {
          if (activeWeek) estimatedRev += c.contractual;
          if (index !== -1) {
            money[index].sum += c.contractual;
          } else {
            money.push({week: w.week, sum: c.contractual})
          }
        }

      })

    })

    const topDollar = money.sort((m, m1) => m.sum < m1.sum ? 1 : -1)[0]?.sum || 0;

    setTopMothly(topDollar)
    setWeeklySums(money);
    setEstimatedRev(estimatedRev.toLocaleString());
    setActualRev(actualRev.toLocaleString());
  }, [year, activeWeeks, data, financialDetails, canvas, completeYear]);

  useEffect(() => {
    const axis = [];
    let totSum = 0;
    const initLimit = 10000000;
    const roundedSum = Math.round(topMonthly / 100000) * 100000
    setRoundedMonthly(roundedSum);
    completeYear?.map(c => c.companies)?.flatMap(w => w)?.forEach(s => totSum += s.monthlyValue)
    if (totSum > initLimit) {
      setAccumulated((initLimit * 1.5));
    } else {
      setAccumulated(initLimit);
    }
    const tenth = roundedSum / 10;
    for (let i = 10; i >= 0; i--) {
      let sum = tenth * i;
      axis.push(sum);
    }
    setActiveGraph(axis);
  }, [topMonthly])

  useEffect(() => {
    const yearData = [];
    let biggestCompany = {};
    let smallestCompany = {};

    let week =  startWeek;
    let weekYear = year?.getFullYear();

    for (let i = startWeek; i < (startWeek + 52); i++) {
      const weeklyCompanies = [];
      financialDetails.filter(d => {
        const goLiveDate = new Date(d?.goLiveDate);
        if (goLiveDate?.getFullYear() !== weekYear) return;
        const goLiveWeek = goLiveDate?.getWeek();
        if (week === goLiveWeek) {
          let predicted = 0;
          let estimated = 0;
          let actual = 0;

          d?.detailList?.forEach(d => {
            if (d?.name.startsWith("Predicted")) {
              predicted += +d.stringValue;
            } else if (d?.name.startsWith("Actual")) {
              actual += +d.stringValue;
            } else if (d?.name.startsWith("Estimated")) {
              estimated += +d.stringValue;
            }
          });

          const comp = {...d, monthlyValue: actual > 0 ? actual : predicted > 0 ? predicted : estimated, estimated: predicted, actual: actual, contractual: estimated}
          if (biggestCompany?.monthlyValue < comp.monthlyValue || biggestCompany?.monthlyValue === undefined) biggestCompany = comp;
          if (comp.monthlyValue !== 0 && (smallestCompany?.monthlyValue > comp.monthlyValue || smallestCompany?.monthlyValue === undefined)) smallestCompany = comp;

          weeklyCompanies.push(comp)
        }

      })
      yearData.push({week: week, year: weekYear, companies: weeklyCompanies})
      if (week === 52) {
        week = 1
        weekYear++;
      } else {
        week++;
      }
    }

    setBiggestComp(biggestCompany);
    setCompleteYear(yearData);
  }, [year, data, financialDetails, canvas, startWeek]);


  const toggleActiveWeek = (week) => {
    const weekInList = activeWeeks.find(c => c?.week === week?.week)
    if (weekInList !== undefined) {
      setActiveWeeks(current => current.filter(c => c?.week !== week?.week));
    } else {
      setActiveWeeks([...activeWeeks, week]);
    }
  }

  const checkDate = (date) => {
    const currentDate = new Date(Date.now());
    const createdDate = new Date(date);
    const diffTime = Math.abs(createdDate - currentDate);
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 30;
  }

  const getYearSpan = () => {
    let yearOne = 53 - startWeek;
    let yearTwo = (startWeek - 1);

    if (startWeek === 1) {
      return <span className='bfc-base-1-bg' style={{ width: `${((canvasWidth / 52) * yearOne) || (24 * yearOne)}px`, color: 'var(--bfc-base-c-1)' }} onClick={() => {
        setYear(new Date(new Date().getFullYear(), 0, 1));
        setStartWeek(1);
      }}><p className='bf-large'>{year.getFullYear()}</p></span>
    } else {
     return [
       <span id='first' className='bfc-base-1-bg' style={{ width: `${((canvasWidth / 52) * yearOne) || (24 * yearOne)}px`, color: 'var(--bfc-base-c-1)' }} onClick={() => {
         setYear(new Date(year.getFullYear(), 0, 1));
         setStartWeek(1);
       }}><p className='bf-large'>{year.getFullYear()}</p></span>,
       <span id='second' className='bfc-base-2-bg' style={{ width: `${((canvasWidth / 52) * yearTwo) || (24 * yearTwo)}px`, color: 'var(--bfc-base-c-1)' }} onClick={() => {
         setYear(new Date(year.getFullYear() + 1, 0, 1));
         setStartWeek(1);
       }}><p className='bf-large'>{(year.getFullYear() + 1)}</p></span>
     ]
    }
  }

  if (detailStatus?.financialError && !intilityUser) return <Redirect to='/' />;

  return intilityUser ? (
    <>
      <div id='finance'>
        <div className='finance-header'>
          <div className='header-row'>
            <h2>Finance</h2>
            <div className='bfl-autocol date-picker-row'>
              <DatePicker
                label='Year Select'
                hideLabel
                selected={year}
                onChange={d => {
                  setYear(new Date(d.getFullYear(), 0, 1));
                  setStartWeek(1);
                  setActiveWeeks([]);
                }}
                placeholderText='- Select year -'
                showYearPicker
              />
            </div>
          </div>
        </div>
        <div className='finance-content'>
          <Grid className='finance-info-boxes' small={2} gap={24}>
            <Grid.Span small={1}>
              <MoneyCard header='Finance' icon={faCoins} actual={actualRev} estimated={estimatedRev} range={completeYear}/>
            </Grid.Span>
            <Grid.Span small={1}>
              <CustomerCard icon={faBuilding} header='Largest customer' company={data?.find(c => c.id === biggestComp?.companyId)} money={biggestComp?.monthlyValue}/>
            </Grid.Span>
          </Grid>
          <div className='finance-graph-box'>
            <div className='finance-graph bf-scrollbar-small'>
              <div className='finance-graph-topbar'>
                <p className='bf-small bf-em'>Monthly income (NOK)</p>
                <span>
                  <span>
                    <span style={{backgroundColor: 'var(--bfc-base-c-theme)'}}></span>
                    <p className='bf-small'>New</p>
                  </span>
                  <span>
                    <span style={{backgroundColor: 'var(--bfc-warning)'}}></span>
                    <p className='bf-small'>Existing</p>
                  </span>
                  <span>
                    <span style={{backgroundColor: 'var(--bfc-chill)'}}></span>
                    <p className='bf-small'>Newly added projects</p>
                  </span>
                  <span>
                    <span style={{backgroundColor: 'var(--bfc-alert)'}}></span>
                    <p className='bf-small'>Accumulated</p>
                  </span>
                </span>
              </div>
              <div className='finance-graph-content'>

                <div className='vertical-content bf-scrollbar-small'>
                  <canvas id="canvas" height={canvasHeight} width={canvasWidth} style={{position: 'absolute', width: '95vw', height: '738px', zIndex: '1'}} {...bind}>
                  </canvas>
                  <Grid xl={1} className='monthly-income-bar'>
                    {activeGraph?.map((g, i) => {
                      return <Grid.Span xl={1} key={i}>
                        <div>
                          <p>{g.toLocaleString()}</p>
                          <p>{((accumulated - ((accumulated / 10) * i))).toLocaleString()}</p>
                        </div>
                      </Grid.Span>
                    })}
                  </Grid>
                </div>

                <div className='horizontal-content' id='financebox'>
                  <div className='finance-year-count'>
                    {completeYear.map(w => {
                        let buffer = 0;
                        const filteredCompanies = w.companies.filter(i => i.monthlyValue > 0);
                        return <div className='week-block' key={w.week} style={{ width: `${(canvasWidth / 52) || 24}px` }}>
                          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <span style={{ position: "relative", top: 0 }}>
                              {filteredCompanies?.map((c, i) => {
                                let height = ((Math.round((c.monthlyValue * 100) / roundedMonthly) * 7) || 0);
                                const company = data?.find(d => d?.id === c?.companyId);
                                const newBar = <div key={i} aria-label='bar' tabIndex={0} onKeyDown={(e) => {e.keyCode !== 13 || toggleActiveWeek(w)}} onClick={() => toggleActiveWeek(w)}
                                                    className={activeWeeks.some(a => a?.week === w?.week) ? 'money-bar money-bar-active' : "money-bar"}
                                                    style={{
                                                      borderTopRightRadius: (i + 1) === filteredCompanies.length ? '4px' : 0,
                                                      borderTopLeftRadius: (i + 1) === filteredCompanies.length ? '4px' : 0,
                                                      borderTop: (i + 1) === filteredCompanies.length ? 0 : '1px solid var(--bfc-theme-c)',
                                                      height: `${height}px`,
                                                      bottom: `${i > 0 ? buffer : 0}px`,
                                                      backgroundColor: `${c?.parentCompany?.length > 0 ? 'var(--bfc-warning)' : checkDate(company?.createdAt) ? 'var(--bfc-chill)' : 'var(--bfc-theme)'}`
                                                    }}></div>
                                buffer += height
                                return newBar;
                              })}
                            </span>
                            <p onClick={() => {
                              setStartWeek(w.week);
                              year.setFullYear(w.year);
                              setYear(year);
                              setActiveWeeks([]);
                            }} className={currentDate?.getWeek() === w.week && year?.getFullYear() === currentDate?.getFullYear() ? "bf-p bf-small bf-strong bfc-theme" : "bf-p bf-small"}>{w.week}</p>
                          </div>
                        </div>
                      })
                    }
                  </div>
                  <div className='year-count'>
                    <Button small={true} icon={faArrowLeft} onClick={() => {
                      if (startWeek === 1) {
                        setYear(new Date(year.getFullYear() - 1, 0, 1));
                        setStartWeek(1);
                      } else {
                        setYear(new Date(year.getFullYear(), 0, 1));
                        setStartWeek(1);
                      }
                    }} />
                    {getYearSpan()}
                    <Button small={true} icon={faArrowRight} onClick={() => {
                      setYear(new Date(year.getFullYear() + 1, 0, 1));
                      setStartWeek(1);
                    }}/>
                  </div>
                </div>
              </div>

            </div>

            {activeWeeks.length > 0 &&
              <div className='finance-graph-details bf-scrollbar-small'>
                <div className='graph-details-content'>
                  <span>
                    <p className='bf-large'>Details</p>
                    <Button pill onClick={() => setActiveWeeks([])}><Icon icon={faTimes}/></Button>
                  </span>

                  {activeWeeks.map((w, i) => {
                    return (
                      <div className='detail-week' key={i + w?.week}>
                        <h3>Week {w?.week}</h3>
                        <div>
                          <p>{(weeklySums?.find(m => m.week === w.week)?.sum || 0)?.toLocaleString()} (NOK)</p>
                        </div>
                        {w.companies?.filter(i => i.monthlyValue > 0)?.map((comp, i) => {
                          const company = data?.find(c => c.id === comp?.companyId);
                          return (
                            <div className="detail-company" key={company?.id}>
                              <div className="detail-header">
                                <p className="bf-strong bf-link" onClick={() => history.push(`/${company.id}`)}>{company?.name} ({company?.detailsPlatform?.companyCode || 'N/A'})</p>
                                <span><FormatDate date={new Date(company?.detailsPlatform?.goLiveDate)}/></span>
                              </div>
                              <p className='bf-small bf-em'>{comp?.parentCompany ? `Existing customer: ${comp?.parentCompany}` : ''}</p>
                              <div>
                                <Icon icon={faCoins} />
                                <p>{comp?.monthlyValue?.toLocaleString()} kr/mo</p>
                              </div>
                              <div className="detail-badges">
                                <Badge state="neutral"><Icon
                                  icon={faBuilding} />{company?.locationPlatform?.locationCount}</Badge>
                                <Badge state="neutral"><Icon
                                  icon={faBrowser} />{company?.applicationPlatform?.applicationCount}</Badge>
                                <Badge state="neutral"><Icon icon={faUser} />{company?.userPlatform?.userCount}</Badge>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )
                  })}

                </div>

              </div>
            }

          </div>

        </div>
      </div>
    </>
  ) : (
    <Spinner />
  );

}

export default Finance