import React, {useEffect, useState} from 'react';
import {Button, Tooltip} from '@intility/bifrost-react';
import PropTypes from 'prop-types';

export const ButtonWithTooltip = ({
  btnOnClick,
  children,
  isComplete,
  icon,
  filled,
  pill,
  tooltipText = 'Page set to complete, un-toggle to edit.',
  tooltipPlacement = 'bottom',
  tooltipDuration = 2000,
  buttonSpecs
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, tooltipDuration);
    }
  }, [showTooltip]);

  return (
    <Tooltip content={tooltipText} visible={showTooltip} placement={tooltipPlacement}>
      <Button
        {...buttonSpecs}
        icon={icon}
        pill={pill}
        variant={filled ? 'filled' : 'default'}
        state={isComplete ? 'inactive' : 'default'}
        onClick={() => (isComplete ? setShowTooltip(true) : btnOnClick())}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

ButtonWithTooltip.propTypes = {
  btnOnClick: PropTypes.func,
  icon: PropTypes.object,
  children: PropTypes.any,
  isComplete: PropTypes.bool,
  pill: PropTypes.bool,
  filled: PropTypes.bool,
  tooltipText: PropTypes.string,
  btnText: PropTypes.any,
  tooltipPlacement: PropTypes.string,
  tooltipDuration: PropTypes.number,
  buttonSpecs: PropTypes.any
};

export default ButtonWithTooltip;
