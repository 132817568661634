import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import './newApplication.scss';
import { Badge, Button, CheckboxCard, Icon, Modal, ProgressBar, useBreakpoint } from "@intility/bifrost-react";
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons";
import AppProgressCard from "../AppProgressCard/AppProgressCard";
import StepOne from "../AppStepCard/Steps/StepOne";
import StepThree from "../AppStepCard/Steps/StepThree";
import StepTwo from "../AppStepCard/Steps/StepTwo";
import StepFour from "../AppStepCard/Steps/StepFour";
import StepFive from "../AppStepCard/Steps/StepFive";
import StepSix from "../AppStepCard/Steps/StepSix";
import StepSeven from "../AppStepCard/Steps/StepSeven";
import StepEight from "../AppStepCard/Steps/StepEight";
import { useSelector } from "react-redux";
import produce from "immer";
import { faBrowser, faInfoCircle, faServer } from "@fortawesome/pro-regular-svg-icons";
import { ApplicationType } from "../ApplicationOptions";
import { CompleteApplication } from "../../../../../utils/hub/models";

const NewAppSteps = ({setNewAppType, newAppType, isOpen, setIsOpen, switchToPage}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [step, setStep] = useState(1);
  const [editAppType, setEditAppType] = useState(false);
  const [currentAppType, setCurrentAppType] = useState(null);
  const [progress, setProgress] = useState(0);
  const [appObject, setAppObject] = useState(new CompleteApplication());
  const toSmall = useBreakpoint(null, 'medium');

  useEffect(() => {
    setAppObject(window.localStorage[`${newAppType}application${id}`] ? JSON.parse(window.localStorage[`${newAppType}application${id}`]) : {});
    setProgress(parseInt(window.localStorage[`${newAppType}applicationProgress${id}`]) || 0);
    setStep(parseInt(window.localStorage[`${newAppType}applicationProgress${id}`]) || 1)
  }, []);

  useEffect(() => {
    if (newAppType === ApplicationType.SaaSApp) {
      setAppObject(
        produce(draft => {
          draft.currentVersion = 'N/A';
          draft.newVersion = 'N/A';
          draft.browserAccess = 1;
        })
      );
    }
  }, [newAppType])

  useEffect(() => {
    if (!isOpen) {
      setNewAppType('');
    } else if (!window.localStorage[`${newAppType}application${id}`]) {
      setAppObject(
        produce(draft => {
          draft.saasApp = newAppType === 'SaaS' ? 1 : 0;
          draft.serverApp = newAppType === 'Server' ? 1 : 0;
        })
      );
    }
  }, [isOpen]);

  const onChange = (e, name) => {
    const {checked} = e.target;
    setAppObject(
      produce(draft => {
        draft[name] = checked === true ? 1 : 0;
      })
    );
  };

  const getApps = () => {
    if (newAppType === 'SaaS') {
      switch (step) {
        case 1:
          return <StepOne step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={6} switchToPage={switchToPage}/>;
        case 2:
          return <StepFour step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={6}/>;
        case 3:
          return <StepFive step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={6}/>;
        case 4:
          return <StepSix step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={6}/>;
        case 5:
          return <StepSeven step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={6}/>;
        case 6:
          return <StepEight step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={6}/>;
      }
    } else {
      switch (step) {
        case 1:
          return <StepOne step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8} switchToPage={switchToPage}/>;
        case 2:
          return <StepTwo step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8}/>;
        case 3:
          return <StepThree step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8}/>;
        case 4:
          return <StepFour step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8}/>;
        case 5:
          return <StepFive step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8}/>;
        case 6:
          return <StepSix step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8}/>;
        case 7:
          return <StepSeven step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8}/>;
        case 8:
          return <StepEight step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} totalSteps={8}/>;
      }
    }
  }

  return (
    <div className='new-step-app'>
      <div className='new-step-app-header'>
        <p onClick={() => setIsOpen(false)}>
          <Icon icon={faAngleLeft} /> Applications
        </p>
        <div className='new-app-progress'>
          <div>
            <h3>Add application{appObject.name && ` | ${appObject.name}`}</h3>
            <p className='bf-small'>Please complete as many steps as possible before adding the application.</p>
            <ProgressBar value={newAppType !== ApplicationType.SaaSApp ? (progress * 13) : (progress * 17)}/>
          </div>
        </div>
      </div>
      <div className='new-step-app-body'>
        {toSmall ?
          <div className=''>
            <AppProgressCard step={step} setStep={setStep} progress={progress} appObject={appObject} setEditAppType={setEditAppType} setCurrentAppType={setCurrentAppType} newAppType={newAppType} />
            {getApps()}
          </div> :
          <div className=''>
            {getApps()}
            <AppProgressCard step={step} setStep={setStep} progress={progress} appObject={appObject} setEditAppType={setEditAppType} setCurrentAppType={setCurrentAppType} newAppType={newAppType} />
          </div>
        }
      </div>
      <Modal isOpen={editAppType} header='Edit application types' onRequestClose={() => {
        setEditAppType(false);
        setAppObject(
          produce(draft => {
            draft.serverApp = currentAppType.serverApp;
            draft.saasApp = currentAppType.saasApp;
          })
        );
      }}>
        <p>You can’t edit the main application type, but you may add other relevant application types, if relevant.</p>
        <div className='app-base-progress-modal bfl-autocol'>
          <CheckboxCard
            label='Server application'
            checked={appObject?.serverApp === 1}
            onChange={(e) => onChange(e, 'serverApp')}
            header={
              <div
                style={{
                  height: '95px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon
                  icon={faServer}
                  fixedWidth
                  style={{
                    fontSize: 'var(--bf-font-size-h2)',
                    color: 'var(--bfc-base-c-2)'
                  }}
                />
              </div>
            }
          />
          <CheckboxCard
            label='Saas application'
            checked={appObject?.saasApp === 1}
            onChange={(e) => onChange(e, 'saasApp')}
            header={
              <div
                style={{
                  height: '95px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon
                  icon={faBrowser}
                  fixedWidth
                  style={{
                    fontSize: 'var(--bf-font-size-h2)',
                    color: 'var(--bfc-base-c-2)'
                  }}
                />
              </div>
            }
          />
        </div>
        <Badge state={"warning"} style={{marginTop: 'var(--bfs12)'}}><Icon icon={faInfoCircle}/> Please select at least one application type</Badge>
        <div className='app-base-progress-modal-buttons'>
          <div/>
          <Button onClick={() => {
            setEditAppType(false);
            localStorage.setItem(`${newAppType}application${id}`, JSON.stringify(appObject));
          }} variant={"filled"} disabled={appObject?.saasApp === 0 && appObject?.serverApp === 0}>Save</Button>
        </div>
      </Modal>
    </div>
  )

}

export default NewAppSteps

NewAppSteps.propTypes = {
  setNewAppType: PropTypes.func,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  newAppType: PropTypes.string,
  switchToPage: PropTypes.func
}
