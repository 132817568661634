import React from 'react';
import './error.scss';
import {useHistory} from 'react-router-dom';
import {Button} from '@intility/bifrost-react';

export const Error500 = () => {
  const history = useHistory();

  return (
    <div className='error-500 bf-elements'>
      <div style={{maxWidth: '50%'}}>
        <h5>Error code: 500</h5>
        <h1>You have encountered an error</h1>
        <p className='bf-large'>Did you approve the application consent prompt on login?</p>
        <p className='bf-large'>
          If your company has restrictions on which applications you are allowed to consent to, you will have to use
          either a personal Microsoft account, or a LinkedIn account.
        </p>
        <Button onClick={() => history.push(`/`)}>To frontpage</Button>
      </div>
    </div>
  );
};

export default Error500;
