import React from 'react';
import './overview.scss';
import {useBreakpoint} from '@intility/bifrost-react';
import icon from '../../../../assets/images/FrontPage/onboard.png';
import banner from '../../../../assets/images/FrontPage/BG-wrapper.png';
import OverviewArticles from './OverviewArticles/OverviewArticles';
import OverviewBoxes from './OverviewBoxes/OverviewBoxes';
import OverviewPages from './OverviewPages/OverviewPages';

const Overview = () => {
  const fromMedium = useBreakpoint('medium', null);
  return (
    <div id='overview'>
      <div className='image-container'>
        <img alt='background' src={banner} />
      </div>
      <div className='content'>
        <div className='content-main'>
          <div className='first-page' style={fromMedium ? {height: 'calc(100vh - 80px)'} : undefined}>
            <div className='text-content'>
              <div>
                <img src={icon} style={{height: '64px', width: '64px'}} alt='Onboard icon' />
              </div>
              <div>
                <h5>Welcome to</h5>
                <h1>Intility Onboard</h1>
              </div>
            </div>
            <OverviewBoxes />
            <OverviewArticles />
            <OverviewPages />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
