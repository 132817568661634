import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Checkbox, Icon, useFloatingMessage } from "@intility/bifrost-react";
import {
  deleteApplicationRelationAsync
} from "../../../../../redux/company/applications/applicationRelations/applicationRelationThunks";
import { updateApplicationsError } from "../../../../../redux/company/applications/applicationReducers";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { NavLink } from "react-router-dom";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import React from "react";
import up from "../../../../../assets/images/Icons/Up.svg";
import down from "../../../../../assets/images/Icons/Down.svg";
import upAndDown from "../../../../../assets/images/Icons/UpAndDown.svg";

const IntegrationLogo = (relationType, list) => {
  let imageType;
  if (list === 'parent'){
    imageType = relationType === 0 ? up : relationType === 1 ? down : upAndDown
  } else if (list === 'child') {
    imageType = relationType === 0 ? down : relationType === 1 ? up : upAndDown
  }
  return (
    <img
      src={imageType}
      alt='UpDownArrow'
    />
  )
}

const IntegrationTitle = (relationType, relationName, appName, list) => {
  const title = relationName;
  let text = ' two-way relationship';
  if (list === 'parent'){
    if (relationType === 0) {
      text = ' provides data';
    } else if (relationType === 1) {
      text = ' inherits data';
    }
  } else if (list === 'child') {
    if (relationType === 0) {
      text = ' inherits data';
    } else if (relationType === 1) {
      text = ' provides data';
    }
  }
  return (
    <p>
      <span className='bf-strong'>
        {title}:
      </span>
      <span className='bf-em' style={{color: 'var(--bfc-base-c-2)'}}>
        {text}
      </span>
    </p>
  )
}

export const AppIntegrations = ({appName, appId, childApps, parentApps, setOpen, editing, relevant, onRelevantChange}) => {
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const { showFloatingMessage } = useFloatingMessage();
  const dispatch = useDispatch();

  const deleteIntegration = (integration, list) => {
    dispatch(deleteApplicationRelationAsync(integration, list, appId)).then(s => {
      s?.type === updateApplicationsError.type && showFloatingMessage('This action requires admin rights.', {state: 'alert'});
    });
  };

  const IntegrationLine = (app, relation) => {
    const details = relation === 'parent' ?
      applicationList?.find(i => i?.id === app?.parentId) :
      applicationList?.find(i => i?.id === app?.childId)
    return (
      <div key={app?.id} className='integration-item-content'>
        {IntegrationLogo(app?.applicationRelationType, relation)}
        <div className='integration-item-content-text'>
          {IntegrationTitle(app?.applicationRelationType, details?.name, appName, relation)}
          {editing ?
            <Icon onClick={() => deleteIntegration(app, relation)} icon={faTrashAlt} />
            :
            <NavLink className='bf-link' to={`${details?.id}`}>
              <p style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}>
                Go to application
              </p>
            </NavLink>
          }
        </div>
      </div>
    )
  }

  return (
    <div className='application-integration-box'>
      <div className='box-header'>
        <h3>App integrations {(editing && relevant !== 2 && (childApps?.length === 0 && parentApps?.length === 0)) &&
          <Badge state="warning">Missing info</Badge>}</h3>
        {(editing && relevant !== 2) ?
          <Button small={true} icon={faPlus} onClick={() => setOpen(true)}>
            Add new integration
          </Button> : (!editing && relevant !== 2 && (childApps?.length === 0 && parentApps?.length === 0)) && <Badge state='warning'>Missing info</Badge>
        }
      </div>
      {relevant !== 2 ?
        <p>How is this application integrated in other applications?</p> :
        <p className='bf-large' style={{color: 'var(--bfc-base-c)'}}>N/A</p>
      }
      {editing &&
        <Checkbox style={{ marginBottom: 'var(--bfs8)' }} label='Not relevant' checked={relevant === 2} onChange={e => onRelevantChange(e, 'integrationStatus', 0, 2)} />
      }
      {relevant !== 2 &&
        <div className='box-content'>
          {childApps?.map(c => IntegrationLine(c, 'child'))}
          {parentApps?.map(c => IntegrationLine(c, 'parent'))}
        </div>
      }
    </div>
  );
};

AppIntegrations.propTypes = {
  appName: PropTypes.string,
  appId: PropTypes.string,
  childApps: PropTypes.array,
  parentApps: PropTypes.array,
  setOpen: PropTypes.func,
  setError: PropTypes.func,
  relevant: PropTypes.number,
  onRelevantChange: PropTypes.func,
  editing: PropTypes.bool
};