import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  addPrinterSuccess,
  deletePrintersSuccess,
  deletePrinterSuccess,
  fetchInformationSuccess,
  fetchPrintersListSuccess,
  fetchPrintersSuccess,
  updatePrintersInformationSuccess,
  updatePrintersSuccess,
  updatePrinterSuccess
} from './printerActions';

const updateOnInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchPrintersSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updatePrintersInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const printersInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    supplierName: '',
    supplierEmail: '',
    supplierPhoneNumber: '',
    securePrintType: '',
    securePrintSupplier: '',
    printerCount: 0
  },
  updateOnInformationChangeReducer((payload, state) => {
    state.id = payload.printerPlatform.information.id;
    state.companyId = payload.printerPlatform.information.companyId;
    state.printerCount = payload.printerPlatform.information.printerCount;
    state.isEnabled = payload.printerPlatform.information.isEnabled;
    state.modifiedAt = payload.printerPlatform.information.modifiedAt;
    state.supplierName = payload.printerPlatform.information.supplierName;
    state.supplierEmail = payload.printerPlatform.information.supplierEmail;
    state.supplierPhoneNumber = payload.printerPlatform.information.supplierPhoneNumber;
    state.securePrintType = payload.printerPlatform.information.securePrintType;
    state.securePrintSupplier = payload.printerPlatform.information.securePrintSupplier;
    return state;
  })
);

const updateOnPrintersChangeReducer = getState => {
  return {
    [fetchPrintersSuccess]: (state, action) => getState(action.payload, state),
    [updatePrintersSuccess]: (state, action) => getState(action.payload, state),
    [updatePrinterSuccess]: (state, action) => getState(action.payload, state),
    [deletePrintersSuccess]: (state, action) => getState(action.payload, state),
    [deletePrinterSuccess]: (state, action) => getState(action.payload, state),
    [addPrinterSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const printersReducer = createReducer(
  [],
  updateOnPrintersChangeReducer((payload, state) => {
    state = payload.printerPlatform.printerList;
    return state;
  })
);

const updateOnPrinterStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchPrintersSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
  };
};

export const printerStateReducer = createReducer(
  0,
  updateOnPrinterStateReducer((payload, state) => {
    state = payload.printerPlatform.state;
    return state;
  })
);


const updateOnPrinterAddedReducer = getState => {
  return {
    [addPrinterSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const printerReducer = createReducer(
  {},
  updateOnPrinterAddedReducer((payload, state) => {
    state = payload.printerPlatform.printer;
    return state;
  })
);

export const printersStatusSlice = createSlice({
  name: 'printersAction',
  initialState: {
    fetching: false,
    fetchingDevices: false,
    error: false,
    deviceError: false,
    adding: false,
    seeding: false,
    addError: false,
    addingDevice: false,
    addDeviceError: false,
    updating: false,
    updatingDevices: false,
    uploadError: false,
    uploadErrorMessage: '',
    uploadErrorResult: []
  },
  reducers: {
    fetchPrinters: state => {
      state.fetching = true;
    },
    fetchPrintersError: state => {
      state.fetching = false;
      state.error = true;
    },
    addPrinter: state => {
      state.adding = true;
      state.addError = false;
    },
    addPrinterError: state => {
      state.adding = false;
      state.addError = true;
    },
    updatePrinters: state => {
      state.updating = true;
    },
    updatePrintersError: state => {
      state.updating = false;
      state.error = true;
    },
    uploadPrintersError: (state, action) => {
      state.updating = false;
      state.uploadError = true;
      state.uploadErrorMessage = "Upload error";
      state.uploadErrorResult = action.payload;
    },
    seedingPrinters: state => {
      state.seeding = true;
    },
    seedingPrintersSuccess: state => {
      state.seeding = false;
    },
    seedingPrintersError: state => {
      state.seeding = false;
    },
    uploadPrintersSuccess: state => {
      state.updating = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    },
    updatePrintersClear: state => {
      state.updating = false;
      state.uploadError = false;
      state.adding = false;
      state.addError = false;
      state.fetching = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    }
  },
  extraReducers: {
    [fetchPrintersSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [fetchPrintersListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updatePrintersSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updatePrinterSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updatePrintersInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [deletePrintersSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [deletePrinterSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [addPrinterSuccess]: state => {
      state.adding = false;
      state.addError = false;
    }
  }
});

export const {
  fetchPrinters,
  fetchPrintersError,
  addPrinter,
  addPrinterError,
  updatePrinters,
  updatePrintersError,
  uploadPrintersSuccess,
  updatePrintersClear,
  uploadPrintersError,
  seedingPrinters,
  seedingPrintersSuccess,
  seedingPrintersError
} = printersStatusSlice.actions;
