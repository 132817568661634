import {createAction, createSlice} from '@reduxjs/toolkit';
import normalize from 'utils/hub/normalize';
import {
  addCurrentVendorContact,
  deleteCurrentVendorContact,
  updateCurrentVendorContact
} from './companyCurrentVendorContactNormalizers';
import normalizeCompanyState from '../../utils/hub/normalizeCompanyState';

export const updateCompanySuccess = createAction('companyUpdate/updateCompanySuccess', apiCompany => ({
  payload: normalize(apiCompany)
}));

export const updateCompanyStateSuccess = createAction('companyStateUpdate/updateCompanyStateSuccess', apiCompany => ({
  payload: normalizeCompanyState(apiCompany)
}));

export const updateCurrentVendorContactSuccess = createAction('companyUpdate/updateCurrentVendorContactSuccess', contact => ({
  payload: updateCurrentVendorContact(contact)
}));

export const deleteCurrentVendorContactSuccess = createAction('companyUpdate/deleteCurrentVendorContactSuccess', contact => ({
  payload: deleteCurrentVendorContact()
}));

export const addCurrentVendorContactSuccess = createAction('companyUpdate/addCurrentVendorContactSuccess', contact => ({
  payload: addCurrentVendorContact(contact)
}));

export const updateCompanyInformationSuccess = createAction('companyUpdate/updateCompanyInformationSuccess', apiCompany => ({
  payload: apiCompany
}));

export const companyReset = createAction('RESET_COMPANY');

const companyUpdateSlice = createSlice({
  name: 'companyUpdate',
  initialState: {
    fetching: false,
    error: null,
    vendorError: false,
    updatingVendor: false,
    data: null
  },
  reducers: {
    updateCurrentVendor: state => {
      state.updatingVendor = true;
      state.vendorError = false;
    },
    updateCurrentVendorError: state => {
      state.updatingVendor = false;
      state.vendorError = true;
    },
    updateCompany: state => {
      state.fetching = true;
    },
    removeCompanySuccess: state => {
      state.fetching = false;
    },
    updateCompanyError: (state, action) => {
      state.fetching = false;
      state.error = action.payload;
    }
  },
  extraReducers: {
    [updateCompanySuccess]: (state, action) => {
      state.fetching = false;
      state.data = action.payload;
    },
    [updateCompanyStateSuccess]: (state, action) => {
      state.fetching = false;
      state.data = action.payload;
    },
    [updateCurrentVendorContactSuccess]: (state) => {
      state.updatingVendor = false;
      state.vendorError = false;
    },
    [addCurrentVendorContactSuccess]: (state) => {
      state.updatingVendor = false;
      state.vendorError = false;
    },
    [deleteCurrentVendorContactSuccess]: (state) => {
      state.updatingVendor = false;
      state.vendorError = false;
    }
  }
});

export const {updateCompany, updateCompanyError, removeCompanySuccess, updateCurrentVendor, updateCurrentVendorError} = companyUpdateSlice.actions;

export default companyUpdateSlice;
