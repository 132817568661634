import { OverviewPage } from "./OverviewPage";
import React from "react";
import './overviewPages.scss';
import finance from './../../../../../assets/images/FrontPage/finance.png';
import network from './../../../../../assets/images/FrontPage/locations.png';
import useradmin from './../../../../../assets/images/FrontPage/useradmin.png';
import workplace from './../../../../../assets/images/FrontPage/workplace.png';
import onboard from './../../../../../assets/images/FrontPage/onboard.png';
import application from './../../../../../assets/images/FrontPage/applications.png';
import {useSelector} from 'react-redux';
import PageState from '../../../../../utils/hub/pageState';


export const OverviewPages = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {data: companyData} = useSelector(state => state.company);

  return (
    <div className='pages-box-content bfl-grid'>
      {companyData.locationPlatform.state === PageState.ENABLED && (
        <OverviewPage header='Locations' link={`/${id}/locations`} image={network}>Add/edit company and network locations</OverviewPage>
      )}
      {companyData.userPlatform.state === PageState.ENABLED && (
        <OverviewPage header='Users' link={`/${id}/users`} image={useradmin}>Add/edit company users</OverviewPage>
      )}
      {companyData.iamsUserPlatform.state === PageState.ENABLED && (
        <OverviewPage header='Devices' link={`/${id}/computers/registration`} image={workplace}>Register computers, printers and more</OverviewPage>
      )}
      {companyData.applicationPlatform.state === PageState.ENABLED && (
        <OverviewPage header='Applications' link={`/${id}/applications`} image={application}>Add/edit application portfolio</OverviewPage>
      )}
      <OverviewPage header='Services' link={`/${id}/services`} image={finance}>Explore Intility services and add-ons</OverviewPage>
      {companyData.documentationPlatform.state === PageState.ENABLED && (
        <OverviewPage header='Documentation' link={`/${id}/documentation`} image={onboard}>View all Onboard guides</OverviewPage>
      )}
    </div>
  )
}

export default OverviewPages