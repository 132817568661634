import EditProjectTeam from '../../components/BackOffice/EditComponents/EditProjectTeam';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Project Team',
  path: 'projectTeam/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditProjectTeam
};
