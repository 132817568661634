
const RedirectCloseWindow = () => {

  return (
    <div style={{height: '100%', display: 'flex'}}>
      <div style={{margin: 'auto'}}>
        <h5>Authorization complete</h5>
        <p>You may safely close this window.</p>
      </div>
    </div>
  )


}

export default RedirectCloseWindow