import React, {useState} from 'react';
import produce from 'immer';
import { Badge, Button, FormatDate, Icon } from "@intility/bifrost-react";
import DatePicker from "@intility/bifrost-react-datepicker";
import {faExclamationTriangle, faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

export const IamsRegistrationWarrantyDate = ({ date }) => {
  if (date === undefined || date === null) {
    return <Badge state='alert'>Warranty unknown</Badge>
  }
  const newDate = new Date(date);
  if (newDate.getFullYear() === 1) {
    return <Badge state='alert'>Warranty unknown</Badge>
  }

  const today = new Date(Date.now());
  let months;
  months = (today.getFullYear() - newDate.getFullYear()) * 12;
  months += today.getMonth();
  months -= newDate.getMonth();

  return (
    months >= 36 ? <Badge state='alert'><FormatDate date={newDate}/></Badge> : <FormatDate date={newDate}/>
  );
};

IamsRegistrationWarrantyDate.propTypes = {
  date: PropTypes.any
};


export const IamsRegistrationDatePicker = ({ date, setSaved, setValue }) => {
  const [warranty, setWarranty] = useState(new Date(Date.now()));
  const [editing, setEditing] = useState(false);

  const save = () => {
    setSaved(true);
    setValue(
      produce(draft => {
        draft.warranty = new Date(warranty?.setHours(12));
      })
    );
    setEditing(false);
  };

  const cancel = () => {
    setWarranty(new Date(Date.now()));
    setEditing(false);
  };

  const picker = () => {
    return editing ? (
      <div className='date-edit-row'>
        <DatePicker
          key={'Date'}
          label='Start Date'
          hideLabel={true}
          selected={warranty}
          onChange={d => setWarranty(d)}
        />
        <Button.Group>
          <Button onClick={() => save()}>Save</Button>
          <Button onClick={() => cancel()}>Cancel</Button>
        </Button.Group>
      </div>
    ) : (
      <Button
        className='button-edit-row-warn'
        variant={'basic'}
        icon={faInfoCircle}
        onClick={() => setEditing(true)}
        small={true}
      >
        Missing
      </Button>
    );
  };

  if (date === null) return picker();
  if (date <= 0)
    return (
      <Badge state='alert'>
        <Icon icon={faExclamationTriangle} /> Warranty expired!
      </Badge>
    );
  if (date <= 6)
    return (
      <Badge state='warning'>
        <Icon icon={faInfoCircle} /> Expires in {date} month(s)
      </Badge>
    );
  if (date > 6)
    return (
      <span>
        <Icon style={{color: 'var(--bfc-success)'}} icon={faCheck} /> Expires in {date} months
      </span>
    );
};

IamsRegistrationDatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  setSaved: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};

export default IamsRegistrationDatePicker;
