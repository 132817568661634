import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchLocationsAsync, multiPutLocationsAsync } from "../../../redux/company/locations/locationThunks";
import apiFetch from "../../../utils/apiFetch";
import { Button, Icon, Input, Modal, Table, TextArea, useFloatingMessage } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import produce from "immer";
import { faUndo } from "@fortawesome/pro-regular-svg-icons";
import { emptyGuid } from "../../../utils/guid";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import Flex from "../../Flex/Flex";
import LocationStateOptions, {LocationState} from "./RotLocationOptions";
import {
  GoogleAddressCompanyService
} from "../../Hub/Pages/Locations/components/GoogleMapsPane/GoogleAddressAutocomplete";


export const EditRotLocatins = () => {
  const { id, companyServiceId, name } = useSelector(state => state.company.data.info);
  const {information: detailsInformation} = useSelector(state => state.company.data.detailsPlatform);
  const { locationList, information } = useSelector(state => state.company.data.locationPlatform);
  const {user} = useSelector(state => state.auth);
  const [rotCompany, setRotCompany] = useState(undefined);
  const [rotLocations, setRotLocations] = useState([]);
  const [listCopy, setListCopy] = useState([]);
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [searchValue, setSearchValue] = useState('');
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [rotLocation, setRotLocation] = useState(null);
  const [onboardLocationId, setOnboardLocationId] = useState(null);
  const [code, setCode] = useState(0);
  const { showFloatingMessage } = useFloatingMessage();

  const dispatch = useDispatch();

  const onGuidChange = (selected, location) => {
    onChange(selected.value, 'rotLocationGuid', location);
  }

  const onUserChange = (e, location) => {
    const {name, value} = e.target;
    onChange(value, name, location);
  }

  useEffect(() => {
    const numbers = [];
    rotCompany?.forEach(c => {
      const currentCode = c?.code?.split('-')[1];
      const integ = parseInt(currentCode?.substring(0, currentCode?.length - 1));
      numbers.push(integ);
    });
    numbers.sort((a, b) => a - b);
    setCode(numbers.length > 0 ? (numbers[numbers?.length - 1] + 1) : 0);
  }, [rotCompany]);

  const onChange = (value, name, location) => {
    const newValue = produce(location, draft => {
      draft[name] = value;
    });

    const index = listCopy.findIndex(obj => obj.id === location.id);
    setListCopy(
      produce(listCopy, draft => {
        draft[index] = {...newValue};
      })
    );

    const i = itemsToEdit.findIndex(obj => obj?.id === location.id);
    if (i > -1) {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    } else {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    }
  };

  const newRotLocation = (location) => {
    const templateLoc = {
      name: `${name} - ${location?.name}`,
      code: `${detailsInformation.companyCode}-${code}D`,
      companyCode: detailsInformation.companyCode,
      companyID: companyServiceId,
      shortName: `${location?.name}`,
      state: LocationState.INDEPLOYMENT,
      address: {
        name: `${location?.address}, ${location?.zipCode} ${location?.postalPlace}, ${location?.country}`,
        streetName: location?.address || '',
        streetNumber: "",
        zipCode: location?.zipCode || '',
        municipality: "",
        region: location?.postalPlace || '',
        longitude: 0,
        latitude: 0,
        additionalInfo: location?.comment || '',
        city: location?.postalPlace || '',
        country: location?.country || '',
      },
    }
    setRotLocation(templateLoc);
    setOnboardLocationId(location.id);
  }

  const checkLocation = (location) => {
    const item = rotLocations?.find(l => {
      if (l?.label?.endsWith(location?.name) || l?.label?.startsWith(location?.name) || location?.name === l?.label) {
        return l;
      }
    })
    if (item !== undefined) {
      const newValue = produce(location, draft => {
        draft.rotLocationGuid = item.value;
      })
      const index = listCopy.findIndex(obj => obj.id === location.id);
      setListCopy(
        produce(listCopy, draft => {
          draft[index] = {...newValue};
        })
      );
    }
  }

  const saveLocations = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutLocationsAsync(itemsToEdit, information.id));
      setItemsToEdit([]);
    }
  };

  const onRotChange = (e, address) => {
    const {value, name} = e.target;

    setRotLocation(
      produce(draft => {
        if (address) {
          draft.address[name] = value;
        } else {
          draft[name] = value;
        }
      })
    );
  };

  const onRotStateChange = (value, name) => {
    setRotLocation(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const resetValues = () => {
    setListCopy(locationList);
    setItemsToEdit([]);
  };

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);


  useEffect(() => {
    if (!user || !id || rotCompany) return;
    apiFetch(`/companies/${id}/companyService/${companyServiceId}/locations`)
      .then(response => {
        if (!response.ok) {
          setRotCompany([]);
          showFloatingMessage('Could not fetch CompanyService locations, please make sure CompanyCode is connected on the Information page.', { state: 'alert' });
          return [];
        }
        return response.json()
      })
      .then(company => {
        setRotCompany(company);
        setRotLocations(company?.map(l => ({
          value: l.id,
          label: l.name
        })));
      });
  }, [user, dispatch, id]);

  useEffect(() => {
    setListCopy(locationList);
  }, [locationList]);

  const postLocation = (newRotLocation, onboardLocationId) => {
    apiFetch(`/companies/${id}/locations/${onboardLocationId}/companyServiceLocation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newRotLocation)
    })
      .then(response => {
        if (!response.ok) {
          setRotCompany([]);
          showFloatingMessage('Could not fetch CompanyService locations, please make sure CompanyCode is connected on the Information page.', { state: 'alert' });
          return [];
        }
        return response.json()
      })
      .then(loc => {
        setRotCompany([...rotCompany, loc]);
        setRotLocations([...rotLocations, {value: loc.id, label: loc.name}]);
        setOpenModal(false);
        setRotLocation(null);
      });
  }

  const putCompanyServiceLocation = (newRotLocation, rotLocationId) => {
    apiFetch(`/companies/${id}/companyService/locations/${rotLocationId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newRotLocation)
    })
      .then(response => {
        if (!response.ok) {
          setRotCompany([]);
          showFloatingMessage('Could not update CompanyService location, we have been notified, but please try again to verify error.', { state: 'alert' });
          return [];
        }
        const index = rotCompany.findIndex(obj => obj.id === rotLocationId);
        setRotCompany(produce(rotCompany, draft => {
          draft[index] = {...newRotLocation};
        }));
        const optionIndex = rotLocations.findIndex(obj => obj.value === rotLocationId);
        setRotLocations(produce(rotLocations, draft => {
          draft[optionIndex] = {value: rotLocationId, label: rotLocation.name};
        }));
        setOpenModal(false);
        setRotLocation(null);
        return response.json()
      });
  }

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const sortedData = [...listCopy].sort((a, b) => {
    if (sort.direction === 'asc') {
      return a[sort.key]?.localeCompare(b[sort.key]);
    } else {
      return b[sort.key]?.localeCompare(a[sort.key]);
    }
  }).filter(s => {
    return s.name?.toLowerCase().includes(searchValue.toLowerCase());
  });

  useEffect(() => {
    if (rotLocations?.length > 0) {
      sortedData.forEach(l => l.rotLocationGuid === undefined || l.rotLocationGuid === emptyGuid && checkLocation(l))
    }
  }, [rotLocations]);

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <div>
          <h3>Locations</h3>
          <p>Requires Company code <Link className='bf-link' to={`/details/${id}/information/edit`}>information page</Link></p>
        </div>
        <div className='backoffice-button-group'>
          {itemsToEdit.length > 0 && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button
            variant={'filled'}
            disabled={itemsToEdit.length === 0}
            onClick={() => saveLocations()}
          >
            Save
          </Button>
        </div>
      </div>
      <div className='backoffice-editable-switchrow bfl-autocol'>
        <Input
          className='select-group-input'
          placeholder='Search for location'
          label='search'
          hideLabel
          icon={faSearch}
          clearable
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          rightIcon
        />
      </div>
      <Table className='edit-rot-location-table'>
        <colgroup>
          <col className='from-large' style={{width: '40%'}} />
          <col className='from-large' style={{width: '30%'}} />
          <col className='from-large' style={{width: '30%'}} />
        </colgroup>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
              Location
            </Table.HeaderCell>
            <Table.HeaderCell>
              Rot Location connection
            </Table.HeaderCell>
            <Table.HeaderCell>
              Create or Edit Rot location
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedData.map(location => (
            <Table.Row key={location.id} className='edit-rot-location-row'>
              <Table.Cell>{location.name}</Table.Cell>
              <Table.Cell>
                {location?.rotLocationGuid === emptyGuid ?
                <Select label='Location'
                        value={rotLocations?.find(l => l.value === location.rotLocationGuid)}
                        hideLabel
                        options={rotLocations}
                        onChange={(e) => onGuidChange(e, location)}/>
                  :
                  rotLocations?.find(l => l.value === location.rotLocationGuid)?.label
                }
              </Table.Cell>
              <Table.Cell>
                {location?.rotLocationGuid === emptyGuid ?
                  <Button onClick={() => {
                    setOpenModal(true);
                    newRotLocation(location);
                  }} disabled={companyServiceId === null || companyServiceId === emptyGuid}>Create</Button>
                  :
                  <Button onClick={() => {
                    setOpenModal(true);
                    setRotLocation(rotCompany?.find(l => l.id === location.rotLocationGuid));
                  }} disabled={companyServiceId === null || companyServiceId === emptyGuid}>Edit</Button>
                }
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal onRequestClose={() => {
        setOpenModal(false);
        setRotLocation(null);
      }} isOpen={openModal} header={rotLocation?.id === undefined ? 'Create new Rot location' : `Edit ${rotLocation?.name}`}>
        <Flex flex={[3,1]}>
          <Input label='Name' required={true} value={rotLocation?.name} name='name' onChange={e => onRotChange(e)}/>
          <Input label='Location Code' value={rotLocation?.code} name='code' onChange={e => onRotChange(e)}/>
        </Flex>
        <Flex flex={[1,1]}>
          <Input label='Short name' required={true} value={rotLocation?.shortName} name='shortName' onChange={e => onRotChange(e)}/>
          <Select label='State' options={LocationStateOptions} value={LocationStateOptions.find(l => l.value === rotLocation?.state)} onChange={e => onRotStateChange(e.value, 'state')}/>
        </Flex>
          <p className='bf-large'>Location address:</p>
        <div style={{backgroundColor: 'var(--bfc-base-c-dimmed)', padding: 'var(--bfs12)', alignItems: 'flex-end'}}>
          <Flex flex={[1]}>
            <GoogleAddressCompanyService setLocation={setRotLocation} label='Street name' location={rotLocation} name='streetName' onChange={e => onRotChange(e, true)}/>
          </Flex>
          <Flex flex={[1,1,1]}>
            <Input label='Name' value={rotLocation?.address?.name} name='name' onChange={e => onRotChange(e, true)}/>
            <Input label='Street name' value={rotLocation?.address?.streetName} name='streetName' onChange={e => onRotChange(e, true)}/>
            <Input label='Street number' value={rotLocation?.address?.streetNumber} name='streetNumber' onChange={e => onRotChange(e, true)}/>
          </Flex>
          <Flex flex={[1,1,1]}>
            <Input label='Zip code' value={rotLocation?.address?.zipCode} name='zipCode' onChange={e => onRotChange(e, true)}/>
            <Input label='Municipality' value={rotLocation?.address?.municipality} name='municipality' onChange={e => onRotChange(e, true)}/>
            <Input label='Region' value={rotLocation?.address?.region} name='region' onChange={e => onRotChange(e, true)}/>
          </Flex>
          <Flex flex={[1,1,1,1]}>
            <Input label='City' value={rotLocation?.address?.city} name='city' onChange={e => onRotChange(e, true)}/>
            <Input label='Country' value={rotLocation?.address?.country} name='country' onChange={e => onRotChange(e, true)}/>
            <Input label='Longitude' value={rotLocation?.address?.longitude} name='longitude' onChange={e => onRotChange(e, true)}/>
            <Input label='Latitude' value={rotLocation?.address?.latitude} name='latitude' onChange={e => onRotChange(e, true)}/>
          </Flex>
          <Flex flex={[1]}>
            <TextArea label='Additional info' value={rotLocation?.address?.additionalInfo} name='additionalInfo' onChange={e => onRotChange(e, true)}/>
          </Flex>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 'var(--bfs12)'}}>
          <Button onClick={() => {
            setOpenModal(false);
            setRotLocation(null);
          }}>Cancel</Button>
          <Button onClick={() => {
            if (rotLocation?.id === undefined) {
              postLocation(rotLocation, onboardLocationId);
            } else {
              putCompanyServiceLocation(rotLocation, rotLocation.id);
            }
            }} variant={"filled"}>Save</Button>
        </div>
      </Modal>
    </div>
  )


}

export default EditRotLocatins