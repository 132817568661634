import './serviceSettingsDetails.scss';
import React, { useEffect, useState } from "react";
import apiFetch from "../../../utils/apiFetch";
import { Input, Table } from "@intility/bifrost-react";
import ServiceRow from "./ServiceRow";
import {
  fetchGlobalIntilityServicesAsync,
  fetchIntilityServicesAsync
} from "../../../redux/company/intilityServices/intilityServiceThunks";
import { useDispatch, useSelector } from "react-redux";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";


export const ServiceSettingsDetails = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {intilityServiceList, globalIntilityServiceList} = useSelector(state => state.company.data.intilityServicePlatform);
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [articles, setArticles] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== undefined && id?.length !== 0) {
      dispatch(fetchIntilityServicesAsync(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(fetchGlobalIntilityServicesAsync());
    apiFetch(`/Publish/services`)
      .then(response => {
        return response.json()
      }).then(value => {
      setArticles(value)
    })
  }, []);

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const sortedData = articles?.value?.length > 0 ? [...articles?.value]
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (sort.key === 'name') return a?.textContents[0]?.title?.localeCompare(b?.textContents[0]?.title);
        if (sort.key === 'isActive' || sort.key === 'isEnabled') {
          const aValue = intilityServiceList.find(s => s.articleId === a.id)
          return sort.key === 'isActive' ? aValue?.isActive === true : aValue?.isEnabled === true;
        }
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        if (sort.key === 'name') return b?.textContents[0]?.title?.localeCompare(a?.textContents[0]?.title);
        if (sort.key === 'isActive' || sort.key === 'isEnabled') {
          const bValue = intilityServiceList.find(s => s.articleId === b.id)
          return sort.key === 'isActive' ? bValue?.isActive === true : bValue?.isEnabled === true;
        }
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    })
    .filter(s => {
      return s?.textContents[0]?.title?.toLowerCase()?.includes(searchValue?.toLowerCase());
    }) : [];

  return (
    <div className='backoffice-editable-container'>
      <div className='service-settings-container'>

        <div className='service-settings-header'>
          <h5>Services</h5>
          <p>Select which services the customer has picked</p>
        </div>
        <div className='service-settings-body'>
          <Input placeholder='Search' label='Search' hideLabel variant='outline' rightIcon icon={faSearch} onChange={e => setSearchValue(e.target.value)}/>
          <Table noBorder>
            <colgroup>
              <col className='from-large' style={{ width: '58%' }} />
              <col className='from-large' style={{ width: '25%' }} />
              <col className='from-large' style={{ width: '7%' }} />
              <col className='from-large' style={{ width: '10%' }} />
            </colgroup>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>Service name</Table.HeaderCell>
                <Table.HeaderCell>Categories</Table.HeaderCell>
                <Table.HeaderCell sorting={getSortProp('isActive')} onClick={onSortChangeCreator('isActive')}>Activated</Table.HeaderCell>
                <Table.HeaderCell sorting={getSortProp('isEnabled')} onClick={onSortChangeCreator('isEnabled')}>Visible to company</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedData?.map(a => (
                <ServiceRow article={a} id={a.id} editable={false} activateService={null} />
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  )

}

export default ServiceSettingsDetails