import PropTypes from "prop-types";
import './commentRow.scss';
import React from "react";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FormatDate, Icon } from "@intility/bifrost-react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDomainCommentAsync } from "../../../redux/company/domain/domainThunks";
import { deleteApplicationCommentAsync } from "../../../redux/company/applications/applicationThunks";

export const commentTypes = {
  APPLICATION: 'Application',
  DOMAIN: 'Domain'
}

const CommentRow = ({comment, editComment, notEditable, commentType}) => {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth);

  return (
    <div className='domain-comment' style={notEditable ? {borderTop: 0, marginTop: 0} : undefined}>
      <div className='comment-header'>
        <div className='comment-user'>
          <img src={`data:image/png;base64,${comment?.profilePhoto}`} alt='img' />
          <p className='bf-small'>{comment?.username}</p>
          <span className='bf-p bf-small'><Icon icon={faCalendar}/><FormatDate show="datetime" date={new Date(comment?.createdAt)} /></span>
        </div>
        {notEditable ?
          <div/>
        : user.email === comment?.createdBy ?
          <div className='comment-buttons'>
            <span className='bf-link' onClick={() => {
              if (commentType === commentTypes.DOMAIN) {
                dispatch(deleteDomainCommentAsync(comment.domainStatusId, comment.id))
              } else if (commentType === commentTypes.APPLICATION) {
                dispatch(deleteApplicationCommentAsync(comment.applicationStatusId, comment.id))
              }
            }}><Icon icon={faCalendar}/>Delete</span>
            <span className='bf-link' onClick={() => editComment(comment)}><Icon icon={faCalendar}/>Edit</span>
          </div>
            :
            <div/>
        }
      </div>
      <p>
        {comment?.comment}
      </p>
    </div>
  )

}

CommentRow.propTypes = {
  comment: PropTypes.object,
  editComment: PropTypes.func,
  notEditable: PropTypes.bool,
  commentType: PropTypes.string,
}

export default CommentRow