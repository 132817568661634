import {Button, Drawer, Input} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import React, {useState} from 'react';
import './CustomAppDrawer.scss';
import {APP_OPTIONS, APP_PROTECTION_OPTIONS, CUSTOM_OPTIONS} from './EditApps';

const INITIAL_VALUES = {
  name: '',
  appProtected: APP_PROTECTION_OPTIONS[0].value,
  deploymentType: APP_OPTIONS[1].value
};
const CustomAppDrawer = ({onSubmit, onRequestClose, open}) => {
  const [values, setValues] = useState(INITIAL_VALUES);

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({
      name: values.name,
      displayName: values.name,
      appProtected: values.appProtected,
      deploymentType: values.deploymentType,
      type: 'Custom'
    });
    setValues(INITIAL_VALUES);
    onRequestClose();
  };

  return (
    <Drawer header='Add a new custom application' isOpen={open} onRequestClose={onRequestClose} overlay
    footer={
      <Button variant='filled' type='submit' onClick={handleSubmit}>
        Submit
      </Button>
    }>
      <form onSubmit={handleSubmit} className='custom-app-form'>
        <Input
          label='Custom App'
          onChange={event => setValues(s => ({...s, name: event.target.value}))}
          placeholder='Example: Microsoft Teams'
          value={values.name}
        />
        <Select
          label='Available In Company Portal'
          isSearchable={false}
          value={APP_OPTIONS.find(option => option.value === values.deploymentType)}
          options={CUSTOM_OPTIONS}
          onChange={selected => setValues(s => ({...s, deploymentType: selected.value}))}
        />
      </form>
    </Drawer>
  );
};

export default CustomAppDrawer;
