import React, { useEffect, useState } from "react";
import './adminAccessSlider.scss';
import Flex from '../../../../Flex/Flex';
import { Badge, Button, Label, TextArea } from "@intility/bifrost-react";
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import apiFetch from "../../../../../utils/apiFetch";
import { useSelector } from "react-redux";
export const getLinkStatus = (status) => {
  switch (status) {
    case 'approvalPending': return 'Approval pending';
    case 'active': return 'Active';
    case 'activating': return 'Activating';
    case 'approved': return 'Approved';
    case 'expired': return 'Expired';
    case 'expiring': return 'Expiring';
    case 'terminated': return 'Terminated';
    case 'terminating': return 'Terminating';
    case 'terminationRequested': return 'Termination requested';
    case 'unknownFutureValue': return 'Unknown value';
    default: return 'Unknown status';
  }
}

export const fetchAdminRelationship = (companyId, setValue) => {
  apiFetch(`/companies/${companyId}/officeAdminRelationship`)
    .then(response => {
      if (!response.ok) {
        setValue([]);
        return [];
      }
      return response.json()
    })
    .then(status => setValue(status));
};

const AdminAccessSlider = () => {
  const {id} = useSelector(state => state.company.data.info);
  const [office365Status, setOffice365Status] = useState();

  const tempLink =
    'https://admin.microsoft.com/Adminportal/Home' +
    '?invType=ResellerRelationship&partnerId=8181060f-d7c3-438c-812e-0c363c05dae0&msppId=0&DAP=false#/BillingAccounts/partner-invitation';


  useEffect(() => {
    if (!id) return;
    fetchAdminRelationship(id, setOffice365Status)
  }, [id])

  return (
    <div className='admin-slider'>
      <p>
        For Intility to manage your cloud environment you need to associate your tenant with Intility's Microsoft Partner Center.
        This will grant Intility:
      </p>
      <ul className='bf-ul'>
        <li>Access through secure admin accounts for Intility's technical staff</li>
        <li>The ability to activate services and licenses on your behalf in Azure, Microsoft 365, Dynamics and Power Platform</li>
      </ul>
      <p>
        To connect your tenant to Intility's management you need to follow both activation links below.
        You need to sign in with an account that has global admin privileges in Azure Active Directory to confirm your relationship with Intility.
      </p>
      <p>
        After you have established Intility as a reseller of Microsoft Cloud services and consented administrative access, this page will be updated with a status confirming the successful connection to Intility's cloud management service.
      </p>
      <ul className='bf-ul'>
        <li><Label>Cloud reseller</Label></li>
      </ul>
      <Flex flex={[1, 1]} style={{margin: 'var(--bfs8) 0'}}>
        <Button onClick={() => window.open(tempLink)} variant='filled'>
          Cloud reseller relationship
        </Button>
      </Flex>
      <div style={{display: 'flex'}}>
        <TextArea style={{flex: 1}} className='admin-input' readOnly disabled={true} value={tempLink} label='link' hideLabel={true} />
        <div style={{display: "flex"}}>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(tempLink);
            }}
            variant='filled'
            icon={faCopy}
          />
        </div>
      </div>
      <ul className='bf-ul'>
        <li><Label style={{marginTop: 'var(--bfs12)'}}>Administrative access</Label></li>
      </ul>
      <Flex flex={[1, 1]} style={{margin: 'var(--bfs8) 0'}}>
        <Button onClick={() => window.open(office365Status?.inviteUrl)} disabled={office365Status?.status === 'active'} variant='filled'>
          Consent to Intility Management on my Cloud Environment ({getLinkStatus(office365Status?.status)})
        </Button>
      </Flex>
      {office365Status?.status === 'active' ?
        <Badge>
          Consent given, no further action needed
        </Badge>
        :
        <div style={{display: 'flex'}}>
          <TextArea style={{flex: 1}} className='admin-input' readOnly disabled={true} value={office365Status?.inviteUrl} label='link' hideLabel={true} />
          <div style={{display: "flex"}}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(office365Status?.inviteUrl);
              }}
              variant='filled'
              icon={faCopy}
            />
          </div>
        </div>
      }
      <div className='slider-ps'>
        <p className='bf-strong'>Invite your administrator</p>
        <p>
          If you’re not an administrator in your Office 365 tenant, please forward these links to an administrator.
        </p>
      </div>
    </div>
  );
};

AdminAccessSlider.propTypes = {};

export default AdminAccessSlider;
