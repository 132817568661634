import React from 'react';
import { Grid } from '@intility/bifrost-react';

const ContactDetails = ({ contact }) => {
  const ContactInfo = ({ label, value }) => (
    <div>
      <div className='new-internal-contact-info-label'>{label}</div>
      <div className='new-internal-contact-info-value'>{value || ''}</div>
    </div>
  );

  return (
    <div className='add-new-internal-contact-details'>
      {contact ? (
        <Grid small={3}>
          <ContactInfo label="First Name" value={contact.firstName} />
          <ContactInfo label="Last Name" value={contact.lastName} />
          <ContactInfo label="Phone Number" value={contact.mobilePhone || contact.phoneNumber} />
          <ContactInfo label="E-mail" value={contact.primaryEmailAddress || contact.email} />
        </Grid>
      ) : (
        <div className='add-new-internal-contact-details-placeholder'>No user selected</div>
      )}
    </div>
  );
};

export default ContactDetails;