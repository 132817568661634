import useRolePermission from 'hooks/useRolePermission';
import permissionPropTypes from './permissionPropTypes';

/**
 * Checks if the user has the specified permissions in the access token
 */
const Permission = ({children, neededRoles}) => {
  const hasRole = useRolePermission(neededRoles);

  if (!hasRole) return null;

  return children;
};

Permission.propTypes = permissionPropTypes;

export default Permission;
