import React from 'react';
import globalLoaderPropTypes from './globalLoaderPropTypes';
import './globalLoader.scss';
import Loader from 'components/Loader/Loader';

const GlobalLoader = ({loading}) => {
  if (!loading) return null;

  return (
    <div className='global-loader'>
      <Loader />
    </div>
  );
};

GlobalLoader.propTypes = globalLoaderPropTypes;

export default GlobalLoader;
