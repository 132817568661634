import apiFetch from './apiFetch';

export const downloadFile = (file, relativeUrl) => {

  const fileNameRegex = /\/(.*)/i;
  const fileName = file.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'

  apiFetch(relativeUrl)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      // https://stackoverflow.com/questions/3749231/download-file-using-javascript-jquery
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.log('Error while fetching file: ', error);
    });
};