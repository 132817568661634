import React, {useState, useEffect} from 'react';
import {Modal, Table, Checkbox, Button, Icon, Tooltip, Input, TextArea} from '@intility/bifrost-react';
import {faTrash, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {
  fetchApplicationsAsync,
  addApplicationsAsync
} from '../../../redux/company/applications/applicationThunks';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import './editModal.css';
import classNames from 'classnames';

const EditModal = ({isModalOpen, setIsModalOpen, data}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [editableData, setEditableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkUpdateMode, setBulkUpdateMode] = useState(false);
  const [bulkUpdateField, setBulkUpdateField] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const dataWithIds = data.map(item => ({
      ...item,
      id: uuidv4(),
      clientApp: item.clientApp || '0',
      saasApp: item.saasApp || '0',
      serverApp: item.serverApp || '0'
    }));
    setEditableData(dataWithIds);
  }, [data]);

  const toggleBulkUpdateMode = () => {
    setBulkUpdateMode(!bulkUpdateMode);
    if (bulkUpdateMode) {
      setBulkUpdateField(null);
      setSelectedRows([]);
    }
  };

  const handleFieldClick = field => {
    if (bulkUpdateMode) {
      setBulkUpdateField(field);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setBulkUpdateMode(false);
    setBulkUpdateField(null);
    setEditableData([]);
  };

  const handleFieldChange = (index, field, value) => {
    const newEditableData = [...editableData];
    newEditableData[index][field] = value;

    if (bulkUpdateMode) {
      newEditableData.forEach(item => {
        if (selectedRows.includes(item.id)) {
          item[field] = value;
        }
      });
    }

    setEditableData(newEditableData);
  };

  const handleDelete = () => {
    const newEditableData = editableData.filter(dataItem => !selectedRows.includes(dataItem.id));
    setEditableData(newEditableData);
    setSelectedRows([]);
  };

  const onSave = async () => {
    try {
      const actionResult = await dispatch(addApplicationsAsync(editableData));
      if (actionResult) {
        setIsModalOpen(false);
        dispatch(fetchApplicationsAsync(id));
      }
    } catch (error) {
      console.error(error);
      setIsModalOpen(true);
    }
  };

  const handleAddApplication = () => {
    const newApplication = {
      id: uuidv4(),
      name: '',
      vendor: '',
      vendorContactFirstName: '',
      vendorContactLastName: '',
      vendorContactEmail: '',
      vendorContactPhone: '',
      comment: '',
      serverApp: '0',
      saasApp: '0',
      clientApp: '0'
    };
    const newEditableData = [...editableData, newApplication];
    setEditableData(newEditableData);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      header={`Edit the applications (${editableData.length}) before submitting`}
    >
      <div style={{maxHeight: '80vh', overflow: 'auto'}}>
        <Table className='Table'>
          <thead style={{position: 'sticky', top: 0, backgroundColor: 'inherit', zIndex: 1}}>
            <tr>
              <th>
                <Checkbox
                  hideLabel
                  checked={selectedRows.length === editableData.length && editableData.length !== 0}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedRows(editableData.map(item => item.id));
                    } else {
                      setSelectedRows([]);
                    }
                  }}
                />
              </th>
              <th>No. </th>
              <th>Name</th>
              <th>Vendor</th>
              <th>Vendor Contact First Name</th>
              <th>Vendor Contact Last Name</th>
              <th>Vendor Contact Email</th>
              <th>Vendor Contact Phone</th>
              <th>Comment</th>
              <th>Server App</th>
              <th>SaaS App</th>
              <th>Client App</th>
            </tr>
          </thead>
          <tbody>
            {editableData.map((item, index) => (
              <tr key={item.id}>
                <td>
                  <Checkbox
                    hideLabel
                    checked={selectedRows.includes(item.id)}
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedRows(prevRows => [...prevRows, item.id]);
                      } else {
                        setSelectedRows(prevRows => prevRows.filter(id => id !== item.id));
                      }
                    }}
                  />
                </td>
                <td style={{fontWeight: 'bold', color: 'var(--bfc-base-c-theme)'}}>{index + 1}</td>
                <td
                  onClick={() => handleFieldClick('name')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'name' && selectedRows.includes(item.id) ? 'var(--bfc-theme-c)' : 'initial'
                  }}
                >
                  <Input
                    value={item.name}
                    onChange={e => handleFieldChange(index, 'name', e.target.value)}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    hideLabel
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('vendor')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'vendor' && selectedRows.includes(item.id) ? 'var(--bfc-theme-c)' : 'initial'
                  }}
                >
                  <Input
                    value={item.vendor}
                    onChange={e => handleFieldChange(index, 'vendor', e.target.value)}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    hideLabel
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('vendorContactFirstName')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'vendorContactFirstName' && selectedRows.includes(item.id)
                        ? 'var(--bfc-theme-c)'
                        : 'initial'
                  }}
                >
                  <Input
                    value={item.vendorContactFirstName}
                    onChange={e => handleFieldChange(index, 'vendorContactFirstName', e.target.value)}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    hideLabel
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('vendorContactLastName')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'vendorContactLastName' && selectedRows.includes(item.id)
                        ? 'var(--bfc-theme-c)'
                        : 'initial'
                  }}
                >
                  <Input
                    value={item.vendorContactLastName}
                    onChange={e => handleFieldChange(index, 'vendorContactLastName', e.target.value)}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    hideLabel
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('vendorContactEmail')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'vendorContactEmail' && selectedRows.includes(item.id)
                        ? 'var(--bfc-theme-c)'
                        : 'initial'
                  }}
                >
                  <Input
                    value={item.vendorContactEmail}
                    onChange={e => handleFieldChange(index, 'vendorContactEmail', e.target.value)}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    hideLabel
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('vendorContactPhoneNumber')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'vendorContactPhoneNumber' && selectedRows.includes(item.id)
                        ? 'var(--bfc-theme-c)'
                        : 'initial'
                  }}
                >
                  <Input
                    value={item.vendorContactPhoneNumber}
                    onChange={e => handleFieldChange(index, 'vendorContactPhoneNumber', e.target.value)}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    hideLabel
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('comment')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'comment' && selectedRows.includes(item.id) ? 'var(--bfc-theme-c)' : 'initial'
                  }}
                >
                  <TextArea
                    value={item.comment}
                    onChange={e => {
                      e.target.style.height = `${e.target.scrollHeight}px`;
                      handleFieldChange(index, 'comment', e.target.value);
                    }}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    textAreaStyle={{
                      height: '20px',
                      minHeight: '40px'
                    }}
                    onFocus={e => (e.target.style.height = e.target.scrollHeight + 'px')}
                    onBlur={e => (e.target.style.height = '20px')}
                    hideLabel
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('serverApp')}
                  className={classNames({
                    'bfc-theme-fade-bg': bulkUpdateField === 'serverApp' && selectedRows.includes(item.id)
                  })}
                >
                  <Checkbox
                    button
                    label='Server App'
                    checked={item.serverApp === '1'}
                    onChange={() => handleFieldChange(index, 'serverApp', item.serverApp === '1' ? '0' : '1')}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    style={{whiteSpace: 'nowrap'}}
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('saasApp')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'saasApp' && selectedRows.includes(item.id) ? 'var(--bfc-theme-c)' : 'initial'
                  }}
                >
                  <Checkbox
                    button
                    label='SaaS App'
                    checked={item.saasApp === '1'}
                    onChange={() => handleFieldChange(index, 'saasApp', item.saasApp === '1' ? '0' : '1')}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    style={{whiteSpace: 'nowrap'}}
                  />
                </td>
                <td
                  onClick={() => handleFieldClick('clientApp')}
                  style={{
                    backgroundColor:
                      bulkUpdateField === 'clientApp' && selectedRows.includes(item.id)
                        ? 'var(--bfc-theme-c)'
                        : 'initial'
                  }}
                >
                  <Checkbox
                    button
                    label='Client App'
                    checked={item.clientApp === '1'}
                    onChange={() => handleFieldChange(index, 'clientApp', item.clientApp === '1' ? '0' : '1')}
                    disabled={bulkUpdateMode && !selectedRows.includes(item.id)}
                    style={{whiteSpace: 'nowrap'}}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'var(--bfc-base)',
          padding: '10px',
          borderTop: '1px solid #dee2e6'
        }}
      >
        <div>
          <Button variant='outline' style={{marginTop: '10px'}} onClick={onSave}>
            Add Applications
          </Button>
          <Button
            variant={bulkUpdateMode ? 'filled' : 'outline'}
            onClick={toggleBulkUpdateMode}
            style={{marginLeft: '10px'}}
          >
            {bulkUpdateMode ? 'Save Bulk Edit' : 'Bulk Edit'}
          </Button>
        </div>
        <div>
          <Tooltip content='Add empty application' placement='bottom'>
            <Button onClick={handleAddApplication}>
              <Icon icon={faPlus} />
            </Button>
          </Tooltip>
          <Tooltip content='Select applications to remove' placement='bottom'>
            <div style={{display: "inline-block"}}>
            <Button
              variant='outline'
              state='alert'
              onClick={() => handleDelete()}
              disabled={selectedRows.length === 0}
              style={{marginLeft: '10px'}}
            >
              <Icon icon={faTrash} />{' '}
              {selectedRows.length > 1 ? `Remove Applications (${selectedRows.length})` : 'Remove Application'}
            </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
