export const linkedInCredentials = {
  client: {
    id: process.env.REACT_APP_AUTH_LINKEDIN_CLIENT_ID
  },
  auth: {
    tokenHost: 'https://www.linkedin.com',
    tokenPath: '/oauth/v2/token',
    authorizePath: '/oauth/v2/authorization'
  }
};

export const microsoftCredentials = {
  client: {
    id: process.env.REACT_APP_AUTH_MICROSOFT_CLIENT_ID
  },
  auth: {
    tokenHost: 'https://login.microsoftonline.com',
    tokenPath: '/common/oauth2/v2.0/token',
    authorizePath: '/common/oauth2/v2.0/authorize'
  }
};
