
export const LayoutPages = {
  Billing: "billingPlatform",
  Domains: "domainPlatform",
  Locations: "locationPlatform",
  Users: "userPlatform",
  Microsoft365AndAzure: 'microsoft365Platform',
  Applications: "applicationPlatform",
  Communication: "communicationPlatform",
  Iams: "iamsUserPlatform",
  ComputerSetup: "computerSetupPlatform",
  MobileDevices: "mobilePlatform",
  Printers: "printerPlatform",
  Documentation: "documentationPlatform"
};

export const LayoutPageOptions = [
  {value: LayoutPages.Billing, label: 'Billing', description: 'Billing companies visible and editable'},
  {value: LayoutPages.Domains, label: 'Domains', description: 'Domains visible and editable'},
  {value: LayoutPages.Locations, label: 'Locations', description: 'Locations visible and editable'},
  {value: LayoutPages.Users, label: 'Users', description: 'Users visible and editable'},
  {value: LayoutPages.Microsoft365AndAzure, label: 'Microsoft365AndAzure', description: 'Office365 and Azure services visible'},
  {value: LayoutPages.Applications, label: 'Applications', description: 'Applications visible and editable'},
  {value: LayoutPages.Communication, label: 'Communication', description: 'Communication and telco services visible and editable'},
  {value: LayoutPages.Iams, label: 'IAMSusers', description: 'IAMS services visible and editable'},
  {value: LayoutPages.ComputerSetup, label: 'ComputerSetup', description: 'Computer setup settings visible and editable'},
  {value: LayoutPages.MobileDevices, label: 'MobileDevices', description: 'Compliance policies for mobile devices visible and editable'},
  {value: LayoutPages.Printers, label: 'Printers', description: 'Printer services visible and editable'},
  {value: LayoutPages.Documentation, label: 'Documentation', description: 'Documentation files visible and editable'},
];