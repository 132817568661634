import React, {useEffect, useState} from 'react';
import { Button, Checkbox, Dropdown, Icon, Input, Label, Table } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import './editComponent.scss';
import './editWorkplace.scss';
import {useDispatch, useSelector} from 'react-redux';
import { departmentType, Detail, detailType, WorkplaceDevice } from "../../../utils/hub/models";
import produce from 'immer';
import {multiPutAsync} from '../../../redux/company/details/detailThunks';
import {
  faComputerMouse,
  faDesktopAlt,
  faEllipsisH,
  faHdd,
  faLaptop, faSync,
  faUndo
} from "@fortawesome/pro-regular-svg-icons";
import {
  // eslint-disable-next-line no-unused-vars
  addWorkplaceDeviceAsync, deleteWorkplaceDeviceAsync,
  fetchWorkplaceDevicesAsync
} from "../../../redux/company/iamsUsers/workplaceDevice/workplaceDeviceThunks";
import { replacementOptions } from "../../Hub/Pages/Devices.Computers/IamsRegistration/IamsSelectOptions";
import SimpleFileUploader from "../../FileUploader/SimpleFileUploader";
import {
  fetchApplicationsAsync,
  mapUserAppsCsv,
  uploadClientAppsCsv
} from "../../../redux/company/applications/applicationThunks";
import { fetchIamsUsersAsync, uploadIamsCsv } from "../../../redux/company/iamsUsers/iamsUserThunks";
import importAppTemplate from "../../../assets/files/Intility_IAMS_application_Import_Template.xlsx";
import importCompTemplate from "../../../assets/files/Intility_Computer_Import_Template.xlsx";
import { IamsOrigin } from "../../Hub/Pages/SystemsAndServices.Applications/Applications";

const pcManagementOptions = [
  {value: 'Not Decided', label: 'Not Decided'},
  {value: 'Software Center', label: 'Software Center'},
  {value: 'Intune', label: 'Intune'}
];
const printManagementOptions = [
  {value: 'Not Decided', label: 'Not Decided'},
  {value: 'Intility Print', label: 'Intility Print'},
  {value: 'Intility Secure Print', label: 'Intility Secure Print'},
  {value: '3p Print', label: '3p Print'}
];

export const EditWorkplace = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {information, workplaceDevices, iamsUserList} = useSelector(state => state.company.data.iamsUserPlatform);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const {user} = useSelector(state => state.auth);
  const [sccmTenantCreated, setSccmTenantCreated] = useState(new Detail());
  const [pcAnalysisComplete, setPcAnalysisComplete] = useState(new Detail());
  const [gpoPoliciesCreated, setGpoPoliciesCreated] = useState(new Detail());
  const [sharefileLink, setSharefileLink] = useState(new Detail());
  const [pcManagement, setPcManagement] = useState(new Detail());
  const [printManagement, setPrintManagement] = useState(new Detail());
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [newDevice, setNewDevice] = useState(new WorkplaceDevice());
  const [type, setType] = useState({ value: '', label: '' });
  const dispatch = useDispatch();

  useEffect(() => {
    resetValues();
  }, [detailList]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchWorkplaceDevicesAsync(id));
    dispatch(fetchApplicationsAsync(id));
    dispatch(fetchIamsUsersAsync(id));
  }, [user, dispatch, id]);


  useEffect(() => {
    setSccmTenantCreated(
      getDetail(
        pcManagement.stringValue === 'Software Center'
          ? 'SccmTenantCreated'
          : pcManagement.stringValue === 'Intune'
          ? 'FirmaportalCreated'
          : 'Not set'
      )
    );
  }, [pcManagement]);

  const onChange = (value, name, setItem, currentItem, type) => {
    const newValue = produce(currentItem, draft => {
      draft.name = name;
      draft[type] = value;
      draft.valueType = type;
      draft.department = departmentType.WORKPLACE;
    });

    const i = itemsToEdit.findIndex(i => i.name === name);
    if (i > -1)
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    else
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    setItem(newValue);
  };

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutAsync(itemsToEdit));
    }
    setItemsToEdit([]);
  };

  const resetValues = () => {
    const managementType = getDetail('PcManagement');
    setPcManagement(managementType);
    setSccmTenantCreated(
      getDetail(
        managementType.stringValue === 'Software Center'
          ? 'SccmTenantCreated'
          : managementType.stringValue === 'Intune'
          ? 'FirmaportalCreated'
          : 'Not set'
      )
    );
    setPcAnalysisComplete(getDetail('PcAnalysisComplete'));
    setGpoPoliciesCreated(getDetail('GpoPoliciesCreated'));
    setSharefileLink(getDetail('SharefileLink'));
    setPrintManagement(getDetail('PrintManagement'));
    setItemsToEdit([]);
  };

  const getDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.WORKPLACE)?.find(i => i.name === name) || new Detail()
    );
  };

  const getDeviceType = type => {
    if (type === 'Computer') return <span><Icon icon={faLaptop} style={{marginRight: 'var(--bfs4)'}}/>Computer</span>;
    if (type === 'Screen') return <span><Icon icon={faDesktopAlt} style={{marginRight: 'var(--bfs4)'}}/>Screen</span>;
    if (type === 'Docking') return <span><Icon icon={faHdd} style={{marginRight: 'var(--bfs4)'}}/>Docking</span>;
    if (type === 'Peripherals') return <span><Icon icon={faComputerMouse} style={{marginRight: 'var(--bfs4)'}}/>Peripherals</span>;
    return "Unknown";
  };

  const saveDevice = () => {
    if (newDevice.type.length > 0) {
      dispatch(addWorkplaceDeviceAsync(newDevice));
      setType({ value: '', label: '' });
    }
    setNewDevice(new WorkplaceDevice());
  };

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Workplace</h3>
        <div className='backoffice-button-group'>
          {itemsToEdit.length > 0 && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button variant={'filled'} disabled={itemsToEdit.length === 0} onClick={() => saveDetails()}>
            Save
          </Button>
        </div>
      </div>
      <div className='bfl-autocol'>
        <Select
          label='PC management'
          placeholder={'- Select something -'}
          value={{label: pcManagement?.stringValue, value: pcManagement?.stringValue}}
          options={pcManagementOptions}
          onChange={e => onChange(e.value, 'PcManagement', setPcManagement, pcManagement, detailType.STRING)}
        />
        {pcManagement?.stringValue === 'Software Center' ? (
          <div className='labeled-checkbox'>
            <Label>SCCM Tenant created</Label>
            <Checkbox
              hideLabel
              align={'left'}
              checked={sccmTenantCreated?.boolValue}
              onChange={e =>
                onChange(
                  e.target.checked,
                  'SccmTenantCreated',
                  setSccmTenantCreated,
                  sccmTenantCreated,
                  detailType.BOOL
                )
              }
              type={'switch'}
              label='SCCM Tenant created'
            />
          </div>
        ) : pcManagement?.stringValue === 'Intune' ? (
          <div className='labeled-checkbox'>
            <Label>Firmaportal created</Label>
            <Checkbox
              hideLabel
              align={'left'}
              checked={sccmTenantCreated?.boolValue}
              onChange={e =>
                onChange(
                  e.target.checked,
                  'FirmaportalCreated',
                  setSccmTenantCreated,
                  sccmTenantCreated,
                  detailType.BOOL
                )
              }
              type={'switch'}
              label='SCCM Tenant created'
            />
          </div>
        ) : undefined
        }
        <Input label='Registered IAMS' value={information.userCount} disabled={true} />
        <Select
          label='Print management'
          placeholder={'- Select something -'}
          value={{label: printManagement.stringValue, value: printManagement.stringValue}}
          options={printManagementOptions}
          onChange={e => onChange(e.value, 'PrintManagement', setPrintManagement, printManagement, detailType.STRING)}
        />
        <div className='labeled-checkbox'>
          <Label>PC Analysis complete</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={pcAnalysisComplete?.boolValue}
            onChange={e =>
              onChange(
                e.target.checked,
                'PcAnalysisComplete',
                setPcAnalysisComplete,
                pcAnalysisComplete,
                detailType.BOOL
              )
            }
            type={'switch'}
            label='PC Analysis complete'
          />
        </div>
        <div className='labeled-checkbox'>
          <Label>GPO policies created</Label>
          <Checkbox
            hideLabel
            align={'left'}
            checked={gpoPoliciesCreated?.boolValue}
            onChange={e =>
              onChange(
                e.target.checked,
                'GpoPoliciesCreated',
                setGpoPoliciesCreated,
                gpoPoliciesCreated,
                detailType.BOOL
              )
            }
            type={'switch'}
            label='GPO policies created'
          />
        </div>
        <Input
          label='Sharefile link'
          value={sharefileLink?.stringValue}
          onChange={e => onChange(e.target.value, 'SharefileLink', setSharefileLink, sharefileLink, detailType.STRING)}
        />
      </div>

      <div className='workplace-import-btns'>
        <div>
          <Label>1. Alternative IAMS upload</Label>
          <p className='bf-small bf-em'>
            Use {
              <a className='bf-link' rel='noopener noreferrer' target='_blank' href={importCompTemplate}>
                provided document
              </a>
            } to upload computers instead of IAMS scan</p>
          <SimpleFileUploader
              name={"Import \"IAMS\" computers " + `(${iamsUserList.length} in company)`}
              small={false}
              validContentTypes={['.csv']}
              multiple={false}
              onChange={e => {
                dispatch(uploadIamsCsv(e.target.value[0]));
              }}
          />
        </div>
        <div>
          <Label>2. Alternative IAMS client app upload</Label>
          <p className='bf-small bf-em'>
            Use {
              <a className='bf-link' rel='noopener noreferrer' target='_blank' href={importAppTemplate}>
                 provided document
              </a>
            } to upload client apps instead of IAMS scan</p>
          <SimpleFileUploader
              name={"Import client apps " + `(${applicationList?.filter(a => a.origin === IamsOrigin)?.length} in company)`}
              small={false}
              validContentTypes={['.csv']}
              multiple={false}
              onChange={e => {
                dispatch(uploadClientAppsCsv(e.target.value[0]));
              }}
          />
        </div>
        <div>
          <Label>3. Sync to map Users to client apps after upload</Label>
          <p className='bf-small bf-em'>Provide the same list here as in "IAMS" client app upload, <br/>requires users in company to map apps</p>
          <SimpleFileUploader
              name='Map user apps'
              small={false}
              icon={faSync}
              validContentTypes={['.csv']}
              multiple={false}
              onChange={e => {
                dispatch(mapUserAppsCsv(e.target.value[0]));
              }}
          />
        </div>
      </div>

      <div className='workplace-replacement'>
        <h4>Replacement alternatives</h4>
        <div className='workplace-btn-row'>
          <Select placeholder='Select type' label='Type' value={type} options={replacementOptions} onChange={e => {
            setType(e);
            setNewDevice(
              produce(newDevice, draft => {
                draft.type = e.value
              })
            );
          }}/>
          <Input label='Model' value={newDevice.model} onChange={e => setNewDevice(
            produce(newDevice, draft => {
              draft.model = e.target.value
            })
          )}/>
          <Input label='SKU' value={newDevice.sku} onChange={e => setNewDevice(
            produce(newDevice, draft => {
              draft.sku = e.target.value
            })
          )}/>
          <Button variant={"filled"} disabled={newDevice.type.length === 0} onClick={() => saveDevice()}>Add device</Button>
        </div>
        <div className='workplace-table'>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Model</Table.HeaderCell>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell>Count</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {workplaceDevices && workplaceDevices.map(d => {
                return (
                  <Table.Row key={d.id}>
                    <Table.Cell>{getDeviceType(d.type)}</Table.Cell>
                    <Table.Cell>{d.model}</Table.Cell>
                    <Table.Cell>{d.sku}</Table.Cell>
                    <Table.Cell>{d.count}</Table.Cell>
                    <Table.Cell>
                      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown content={<Button variant={"outline"} state={"alert"} onClick={() => dispatch(deleteWorkplaceDeviceAsync(d.id))}>Delete</Button>} variant='border'>
                          <Icon icon={faEllipsisH} style={{cursor: 'pointer'}}/>
                        </Dropdown>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </div>
      </div>

    </div>
  );
};

export default EditWorkplace;
