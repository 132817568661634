import {Message, Modal} from '@intility/bifrost-react';
import React, {useEffect, useState} from 'react';
import './errorModal.scss';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

export const CompanyUserError = 'CompanyUserError';
export const ApplicationError = 'ApplicationError';
export const LocationError = 'LocationError';
export const DocumentationError = 'DocumentationError';
export const PrinterError = 'PrinterError';
export const DomainError = 'DomainError';

const getErrorstatus = type => {
  switch (type) {
    case CompanyUserError:
      return useSelector(state => state.company.data.userPlatform.usersStatus);
    case ApplicationError:
      return useSelector(state => state.company.data.applicationPlatform.applicationStatus);
    case DocumentationError:
      return useSelector(state => state.company.data.documentationPlatform.documentationStatus);
    case LocationError:
      return useSelector(state => state.company.data.locationPlatform.locationStatus);
    case PrinterError:
      return useSelector(state => state.company.data.printerPlatform.printersStatus);
    case DomainError:
      return useSelector(state => state.company.data.domainPlatform.domainStatus);
  }
};

export const ErrorDropdown = ({isOpen, onRequestClose, type}) => {
  const errorStatus = getErrorstatus(type);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowError(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
        onRequestClose();
      }, 10000);
    }
  }, [showError]);

  return (
    showError && (
      <div className='error-dropdown'>
        <Message
          state='warning'
          header='An error occurred'
          onClose={() => {
            setShowError(false);
            onRequestClose();
          }}
        >
          {errorStatus?.uploadErrorMessage}
        </Message>
      </div>
    )
  );
};

ErrorDropdown.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  type: PropTypes.string.isRequired
};

export const ErrorModal = ({isOpen, onClose, type}) => {
  const errorStatus = getErrorstatus(type);

  return (
    <Modal
      className='guide-modal'
      key='modal'
      onRequestClose={onClose}
      isOpen={isOpen}
      icon={faExclamationTriangle}
      header={errorStatus?.uploadErrorMessage}
    >
      <h4>Errors:</h4>
      {errorStatus?.uploadErrorResult?.length > 0 ? (
        errorStatus?.uploadErrorResult?.map((err, i) => {
          return (
            <p key={i} className='bf-em'>
              {err}
            </p>
          );
        })
      ) : (
        <p className='bf-em'>No content errors found!</p>
      )}
      <h4>Possible other issues may be:</h4>
      <p>1. The list is not comma or semicolon separated.</p>
      <p>2. There are duplicates in the list.</p>
      <p>3. The list is empty.</p>
      <br />
      <p>
        Please check your list again, if it looks good please contact us and we will sort things out.
      </p>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string.isRequired
};

export default ErrorModal;
