import {Button, Icon, Modal} from '@intility/bifrost-react';
import React, {useState} from 'react';
import {faLightbulb} from '@fortawesome/pro-light-svg-icons';
import importTemplate from '../../../../../assets/files/Intility_User_Import_Template.xlsx';
import importApplicationTemplate from '../../../../../assets/files/Intility_Application_Import_Template.xlsx';
import importPrinterTemplate from '../../../../../assets/files/Intility_Printer_Import_template.xlsx';
import importDomainTemplate from '../../../../../assets/files/Intility_Domain_Import_Template.xlsx';
import guideImage from 'assets/images/docTest.png';
import guideApplicationsImage from 'assets/images/how_to_applications.png';
import guidePrintersImage from '../../../../../assets/images/how_to_printers.png';
import guideDomainsImage from '../../../../../assets/images/domains/DomainImportGuide.png';
import './guideModal.scss';
import PropTypes from 'prop-types';

export const GuideModal = ({text}) => {
  const [isOpen, setIsOpen] = useState(false);

  return [
    <span style={{display: 'inline'}} key='modal-link' className='bf-link' onClick={() => setIsOpen(true)}>
      {text}
    </span>,
    <Modal
      className='guide-modal'
      key='modal'
      onRequestClose={() => setIsOpen(false)}
      isOpen={isOpen}
      header={<><Icon icon={faLightbulb}/>Guide: Uploading CSV User List</>}
    >
      <p>
        In order to upload an existing list of users to Intility Onboard, you need to fill your user information into
        our{' '}
        {
          <a className='bf-link' rel='noopener noreferrer' target='_blank' href={importTemplate}>
            Excel user template
          </a>
        }
        , and save the file in CSV format.
      </p>
      <br />
      <div className='modal-item'>
        <div className='modal-point'>
          <span>1</span>
        </div>
        <p>
          Our Excel template is pre-formatted and contains the exact columns that Intility Onboard accepts. Please do
          not edit, delete or add any columns or adjust the formatting of any cells. (You may, however, adjust the
          column widths, as this will not affect the exported CSV file.)
        </p>
      </div>

      <div className='modal-item'>
        <div className='modal-point'>
          <span>2</span>
        </div>
        <p>Fields marked with * and in bold in the template are required</p>
        <p>(FirstName, LastName, MobilePhone and UserPrincipalName)</p>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>3</span>
        </div>
        <div>
          <p>
            When you are ready to upload the file to Intility Onboard, you need to save the file in CSV UTF-8 format. To
            do this, select File &gt; Save As, and select a folder. Then, just below the name of the file, select CSV
            UTF-8 (Comma delimited) from the dropdown list, and press Save.
          </p>
          <img src={guideImage} alt='guide' />
        </div>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>4</span>
        </div>
        <p>That’s it! You’re all set to upload your user list to Intility Onboard.</p>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => setIsOpen(false)} variant='filled'>
          Close
        </Button>
      </div>
    </Modal>
  ];
};

GuideModal.propTypes = {
  text: PropTypes.string
};

export const ApplicationsGuideModal = ({text}) => {
  const [isOpen, setIsOpen] = useState(false);

  return [
    <span style={{display: 'inline'}} key='modal-link' className='bf-link' onClick={() => setIsOpen(true)}>
      {text}
    </span>,
    <Modal
      className='guide-modal'
      key='modal'
      onRequestClose={() => setIsOpen(false)}
      isOpen={isOpen}
      header={<><Icon icon={faLightbulb}/>Guide: Uploading CSV Application List</>}
    >
      <p>
        In order to upload an existing list of applications to Intility Onboard, you need to fill your application
        information into our{' '}
        {
          <a className='bf-link' rel='noopener noreferrer' target='_blank' href={importApplicationTemplate}>
            Excel application template
          </a>
        }
        , and save the file in CSV (UTF-8) format.
      </p>
      <br />
      <div className='modal-item'>
        <div className='modal-point'>
          <span>1</span>
        </div>
        <p>
          Our Excel template is pre-formatted and contains the exact columns that Intility Onboard accepts. Please do
          not edit, delete or add any columns or adjust the formatting of any cells. (You may, however, adjust the
          column widths, as this will not affect the exported CSV file.)
        </p>
      </div>

      <div className='modal-item'>
        <div className='modal-point'>
          <span>2</span>
        </div>
        <p>Fields &quot;Name&quot; and &quot;Vendor&quot; are required</p>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>3</span>
        </div>
        <div>
          <p>
            When you are ready to upload the file to Intility Onboard, you need to save the file in CSV UTF-8 format. To
            do this, select File &gt; Save As, and select a folder. Then, just below the name of the file, select CSV
            UTF-8 (Comma delimited) from the dropdown list, and press Save.
          </p>
          <img src={guideApplicationsImage} alt='guide' />
        </div>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>4</span>
        </div>
        <p>That’s it! You’re all set to upload your application list to Intility Onboard.</p>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => setIsOpen(false)} variant='filled'>
          Close
        </Button>
      </div>
    </Modal>
  ];
};

ApplicationsGuideModal.propTypes = {
  text: PropTypes.string
};

export const PrintGuideModal = ({text}) => {
  const [isOpen, setIsOpen] = useState(false);

  return [
    <span style={{display: 'inline', marginLeft: 'var(--bfs2)', marginRight: 'var(--bfs2)'}} key='modal-link' className='bf-link' onClick={() => setIsOpen(true)}>
      {text}
    </span>,
    <Modal
      className='guide-modal'
      key='modal'
      onRequestClose={() => setIsOpen(false)}
      isOpen={isOpen}
      header={<><Icon icon={faLightbulb}/>Guide: Uploading CSV Printer List</>}
    >
      <p>
        In order to upload an existing list of printers to Intility Onboard, you need to fill your printer
        information into our{' '}
        {
          <a className='bf-link' rel='noopener noreferrer' target='_blank' href={importPrinterTemplate}>
            Excel printer template
          </a>
        }
        , and save the file in CSV (UTF-8) format.
      </p>
      <br />
      <div className='modal-item'>
        <div className='modal-point'>
          <span>1</span>
        </div>
        <p>
          Our Excel template is pre-formatted and contains the exact columns that Intility Onboard accepts. Please do
          not edit, delete or add any columns or adjust the formatting of any cells. (You may, however, adjust the
          column widths, as this will not affect the exported CSV file.)
        </p>
      </div>

      <div className='modal-item'>
        <div className='modal-point'>
          <span>2</span>
        </div>
        <p>Fields &quot;Manufacturer&quot;, &quot;MacAddress&quot; and &quot;SerialNumber&quot; are required</p>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>3</span>
        </div>
        <div>
          <p>
            When you are ready to upload the file to Intility Onboard, you need to save the file in CSV UTF-8 format. To
            do this, select File &gt; Save As, and select a folder. Then, just below the name of the file, select CSV
            UTF-8 (Comma delimited) from the dropdown list, and press Save.
          </p>
          <img src={guidePrintersImage} alt='guide' />
        </div>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>4</span>
        </div>
        <p>That’s it! You’re all set to upload your printer list to Intility Onboard.</p>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => setIsOpen(false)} variant='filled'>
          Close
        </Button>
      </div>
    </Modal>
  ];
};

PrintGuideModal.propTypes = {
  text: PropTypes.string
};

export const DomainGuideModal = ({text}) => {
  const [isOpen, setIsOpen] = useState(false);

  return [
    <span style={{display: 'inline', marginLeft: 'var(--bfs2)', marginRight: 'var(--bfs2)'}} key='modal-link' className='bf-link' onClick={() => setIsOpen(true)}>
      {text}
    </span>,
    <Modal
      className='guide-modal'
      key='modal'
      onRequestClose={() => setIsOpen(false)}
      isOpen={isOpen}
      header={<><Icon icon={faLightbulb}/>Guide: Uploading CSV Domain List</>}
    >
      <p>
        In order to upload an existing list of domains to Intility Onboard, you need to fill your domain
        information into our{' '}
        {
          <a className='bf-link' rel='noopener noreferrer' target='_blank' href={importDomainTemplate}>
            Excel domain template
          </a>
        }
        , and save the file in CSV (UTF-8) format.
      </p>
      <br />
      <div className='modal-item'>
        <div className='modal-point'>
          <span>1</span>
        </div>
        <p>
          Our Excel template is pre-formatted and contains the exact columns that Intility Onboard accepts. Please do
          not edit, delete or add any columns or adjust the formatting of any cells. (You may, however, adjust the
          column widths, as this will not affect the exported CSV file.)
        </p>
      </div>

      <div className='modal-item'>
        <div className='modal-point'>
          <span>2</span>
        </div>
        <p>The field &quot;Name&quot; is required</p>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>3</span>
        </div>
        <div>
          <p>
            When you are ready to upload the file to Intility Onboard, you need to save the file in CSV UTF-8 format. To
            do this, select File &gt; Save As, and select a folder. Then, just below the name of the file, select CSV
            UTF-8 (Comma delimited) from the dropdown list, and press Save.
          </p>
          <img src={guideDomainsImage} alt='guide' />
        </div>
      </div>
      <div className='modal-item'>
        <div className='modal-point'>
          <span>4</span>
        </div>
        <p>That’s it! You’re all set to upload your domain list to Intility Onboard.</p>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => setIsOpen(false)} variant='filled'>
          Close
        </Button>
      </div>
    </Modal>
  ];
};

DomainGuideModal.propTypes = {
  text: PropTypes.string
};

export default GuideModal;
