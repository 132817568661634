import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Billing} from 'utils/hub/models';
import {useDispatch, useSelector} from 'react-redux';
import './addBillingSlider.scss';
import produce from 'immer';
import {fetchBrregCompany} from 'utils/brreg';
import {addBillingAsync, deleteBillingAsync, updateBillingAsync} from 'redux/company/billing/billingThunks';
import Flex from '../../../../Flex/Flex';
import {Button, Checkbox, Drawer, Input, Tooltip, useFloatingMessage} from '@intility/bifrost-react';
import {faTimes} from '@fortawesome/pro-light-svg-icons';
import devLog from '../../../../../utils/devLog';
import {faSearch} from '@fortawesome/pro-solid-svg-icons';
import {updateBillingError} from '../../../../../redux/company/billing/billingReducers';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import {isStateValid, validateBilling} from '../../../../../utils/wizard/stateValidations';
import SelectWithFeedback from "../../../../SelectWithFeedback/SelectWithFeedback";
import countryOptions from "../../Users/Options/CountryOptions";
import currencyOptions from "../CurrencyOptions";

const setCompanyFromBrreg = (organizationNumber, setBillingCompany, setBrRegError) => {
  fetchBrregCompany(organizationNumber).then(company => {
    if (!company) {
      setBrRegError(true);
      return;
    }

    setBillingCompany(prevBillingCompany =>
      produce(prevBillingCompany, draft => {
        draft.name = company?.navn;
        draft.address = company?.forretningsadresse?.adresse[0] || '';
        draft.zipCode = company?.forretningsadresse?.postnummer || '';
        draft.postalPlace = company?.forretningsadresse?.poststed || '';
        draft.country = 'Norway';
      })
    );
  });
};

const AddBillingSlider = ({setIsOpen, isOpen, billingId}) => {
  const dispatch = useDispatch();
  const {billingList, information, billingStatus} = useSelector(state => state.company.data.billingPlatform);
  const [billingCompany, setBillingCompany] = useState(new Billing());
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [validation, setValidation] = useState(undefined);
  const [brRegError, setBrRegError] = useState(false);
  const [selected, setSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  const [numberOk, setNumberOk] = useState(undefined);
  const test = billingList.find(b => b.organizationNumber === billingCompany.organizationNumber);
  const { showFloatingMessage } = useFloatingMessage();

  useEffect(() => {
    if (billingStatus.error) {
      showFloatingMessage('Ups, an error occurred, but our developers are notified, please try again later', { state: 'alert' });
    }
  }, [billingStatus.error])

  useEffect(() => {
    setSelected(information.mainId === billingId);
  }, [information.mainId, billingId]);

  useEffect(() => {
    if (brRegError) {
      setTimeout(() => {
        setBrRegError(false);
      }, 3000);
    }
  }, [brRegError]);

  useEffect(() => {
    setNumberOk(test && test.id !== billingId);
    if (saving) {
      setValidation(validateBilling(billingCompany));
    }
  }, [billingCompany]);

  useEffect(() => {
    if (billingId !== undefined && billingId !== null && billingId !== false) {
      const billing = billingList?.find(billing => billing.id === billingId);
      if (billing !== undefined) {
        setBillingCompany(billing);
        setSelectedCountry({value: billing.country, label: billing.country, type: 'country'})
        setSelectedCurrency({value: billing.currency, label: billing.currency, type: 'currency'})
      }
    } else {
      setBillingCompany(new Billing());
      setNumberOk(undefined);
    }
  }, [billingId, billingList]);

  const onChange = e => {
    const {name, value} = e.target;
    setBillingCompany(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const updateSelect = item => {
    if (item === null) return;
    if (item.value === 'Norway') {
      setBillingCompany(
        produce(draft => {
          draft[item.type] = item.value;
          draft.currency = 'NOK'
        })
      );
      setSelectedCurrency({value: 'NOK', label: 'NOK', type: 'currency'})
    } else {

    }
    setBillingCompany(
      produce(draft => {
        draft[item.type] = item.value;
      })
    );
  };

  const changeMain = e => {
    const {checked} = e.target;
    setSelected(checked);
    setBillingCompany(
      produce(draft => {
        draft.main = checked;
      })
    );
  };

  const onClose = () => {
    setIsOpen(false);
    setSaving(false);
    setValidation(undefined);
    setSelected(false);
    setBrRegError(false);
  };

  const onSave = () => {
    setSaving(true);
    const errors = validateBilling(billingCompany);
    setValidation(errors);
    const isValid = isStateValid(errors);
    if (isValid && billingId !== undefined && billingId !== null && billingId !== false) {
      devLog('Updating!', billingCompany);
      dispatch(updateBillingAsync(billingCompany)).then(s => {
        setIsOpen(s?.type === updateBillingError.type);
      });
      onClose();
    } else if (isValid) {
      devLog('Adding!', billingCompany);
      dispatch(addBillingAsync(billingCompany)).then(s => {
        setIsOpen(s?.type === updateBillingError.type);
      });
      onClose();
    }
  };

  return (
    <Drawer
      header={billingId !== null ? 'Edit billing company' : 'Add billing company'}
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlay
      footer={
        <div className='drawer-bottom-button-row'>
          {billingId !== undefined && billingId !== null && billingId !== false ? (
            <Button
              variant='outline'
              state='alert'
              onClick={() => {
                onClose();
                dispatch(deleteBillingAsync(billingId));
              }}
              disabled={information.mainId === billingId}
              icon={faTimes}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <div className='button-row-collection'>
            <Button
              onClick={() => {
                setSaving(false);
                onClose();
              }}
              key={0}
            >
              Cancel
            </Button>
            <Button variant='filled' onClick={() => onSave()} key={1}>
              Save
            </Button>
          </div>
        </div>
      }
    >
      <form
        onSubmit={event => {
          event.preventDefault();
          onSave();
        }}
        className='billing-slider'
      >
        <Flex style={{flexWrap: 'wrap'}}>
          <Checkbox
            button
            disabled={information.mainId === billingId}
            label='Set as main billing company'
            checked={selected}
            onChange={changeMain}
            style={{minWidth: '250px'}}
          />
          {information.mainId === billingId ? (
            <p className='bf-small bf-em' style={{minWidth: '40%', whiteSpace: 'break-spaces'}}>
              Warning: You can&apos;t leave the main-company section empty.
            </p>
          ) : (
            <p className='bf-small bf-em' style={{minWidth: '40%', whiteSpace: 'break-spaces'}}>
              Warning: The current main billing company will be moved to the additional-section.
            </p>
          )}
        </Flex>
        <Flex>
          <Tooltip content='Registration not found' visible={brRegError} placement='top'>
            <div className='edit-billing-input-btn'>
              <Input
                value={billingCompany.organizationNumber || ''}
                {...(numberOk ? {
                  state: 'warning',
                  feedback: 'Need to be unique'
                } : validation?.name && {
                  state: 'warning',
                  feedback: 'Missing value'
                })}
                placeholder='e.g 123 456 789'
                onChange={e => {
                  setBrRegError(false);
                  onChange(e);
                }}
                label='Organization number'
                required={true}
                name='organizationNumber'
                icon={faSearch}
                iconButton
                rightIcon
                onIconClick={e => {
                  e.preventDefault();
                  setCompanyFromBrreg(billingCompany.organizationNumber, setBillingCompany, setBrRegError);
                }}
              />
            </div>
          </Tooltip>
          <InputWithFeedback
            onChange={onChange}
            value={billingCompany.name}
            label='Company Name'
            required={true}
            name='name'
            otherProps={
              validation?.name && {
                state: 'warning',
                feedback: 'Missing value'
              }
            }
          />
        </Flex>
        <InputWithFeedback
          onChange={onChange}
          value={billingCompany.email}
          label='Invoice e-mail'
          name='email'
          required={true}
          otherProps={
            validation?.email && {
              state: 'warning',
              feedback: 'Missing value'
            }
          }
        />
        <InputWithFeedback
          onChange={onChange}
          value={billingCompany.invoiceRef}
          label='Invoice reference'
          name='invoiceRef'
          optional={true}
          description='Would you like a default reference on your invoices?'
        />
        <InputWithFeedback
          onChange={onChange}
          value={billingCompany.address}
          label='Billing address'
          required={true}
          name='address'
          otherProps={
            validation?.address && {
              state: 'warning',
              feedback: 'Missing value'
            }
          }
        />
        <Flex>
          <InputWithFeedback
            onChange={onChange}
            value={billingCompany.zipCode}
            label='Zip code'
            required={true}
            name='zipCode'
            otherProps={
              validation?.zipCode && {
                state: 'warning',
                feedback: 'Missing value'
              }
            }
          />
          <InputWithFeedback
            onChange={onChange}
            value={billingCompany.postalPlace}
            label='Postal place'
            required={true}
            name='postalPlace'
            otherProps={
              validation?.postalPlace && {
                state: 'warning',
                feedback: 'Missing value'
              }
            }
          />
        </Flex>
        <Flex>
          <SelectWithFeedback
            onChange={item => {
              updateSelect(item);
              setSelectedCountry(item);
            }}
            options={countryOptions}
            value={selectedCountry}
            required={true}
            label='Country'
            otherProps={
              validation?.country && {
                state: 'warning',
                feedback: 'Missing value'
              }
            }
          />
          <SelectWithFeedback
            onChange={item => {
              updateSelect(item);
              setSelectedCurrency(item);
            }}
            value={selectedCurrency}
            required={true}
            disabled={billingCompany?.country === 'Norway' && billingCompany?.currency === 'NOK'}
            options={currencyOptions}
            label='Currency'
            otherProps={
              validation?.currency && {
                state: 'warning',
                feedback: 'Missing value'
              }
            }/>
        </Flex>
      </form>
    </Drawer>
  );
};

AddBillingSlider.propTypes = {
  billingId: PropTypes.any,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default AddBillingSlider;
