import EditWorkplace from '../../components/BackOffice/EditComponents/EditWorkplace';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Workplace',
  path: 'workplace/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditWorkplace
};
