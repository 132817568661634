import {editPermissionPropTypes} from './permissionPropTypes';
import useEditPermission from 'hooks/useEditPermission';

const EditPermission = ({children}) => {
  const hasEditPermission = useEditPermission();

  if (!hasEditPermission) return null;

  return children;
};

EditPermission.propTypes = editPermissionPropTypes;

export default EditPermission;
