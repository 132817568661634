import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Badge, Checkbox, Table, useBreakpoint } from "@intility/bifrost-react";
import {
  addApplicationAsync,
  updateApplicationAsync
} from "../../../../../redux/company/applications/applicationThunks";
import { FreewareOrigin, Microsoft365Origin } from "../Applications";
import produce from "immer";
import { Application } from "../../../../../utils/hub/models";
import PropTypes from "prop-types";


export const AddSimpleApp = (name, origin, enabled, dispatch) => {
  const app = produce(new Application(), draft => {
    draft.name = name;
    draft.origin = origin;
    draft.url = 'N/A';
    draft.browserAccess = 2;
    draft.virtualClient = 2;
    draft.currentVersion = 'N/A';
    draft.newVersion = 'Latest';
    draft.comment = 'N/A';
    draft.vendorStatus = 2;
    draft.internalStatus = 2;
    draft.integrationStatus = 2;
    draft.appSpecificCred = 2;
    draft.readyStatus = 1;
    draft.inPortfolio = enabled ? 1 : 2;
    draft.isEnabled = enabled;
    if (origin === FreewareOrigin) {
      draft.clientApp = 1;
      draft.vendor = 'Intility';
      draft.identity = 'N/A';
      draft.readyStatus = 2;
      draft.identity = "N/A";
      draft.readyStatus = 4;
    } if (origin === Microsoft365Origin) {
      draft.saasApp = 1;
      draft.clientApp = 2;
      draft.vendor = 'Microsoft';
      draft.identity = 'azureAd';
      draft.vendorContacts = [];
      draft.singleSignOnFuture = 'Yes';
      draft.readyStatus = 4;
    }
  });
  dispatch(addApplicationAsync(app));
};
export const ToggleSimpleApp = (application, isChecked, origin, dispatch) => {
  if (application.id === undefined) {
    AddSimpleApp(application.name, origin, isChecked, dispatch);
  } else if (isChecked && application.inPortfolio === 0) {
    const app = produce(application, draft => {
      draft.isEnabled = true;
      draft.inPortfolio = 1;
    });
    dispatch(updateApplicationAsync(app));
  } else if (isChecked && application.inPortfolio === 2) {
    const app = produce(application, draft => {
      draft.isEnabled = true;
      draft.inPortfolio = 1;
    });
    dispatch(updateApplicationAsync(app));
  } else if (isChecked) {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 0;
    });
    dispatch(updateApplicationAsync(app));
  } else if (!isChecked && application.inPortfolio === 1) {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 2;
    });
    dispatch(updateApplicationAsync(app));
  } else if (!isChecked && application.inPortfolio === 0) {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 2;
    });
    dispatch(updateApplicationAsync(app));
  } else {
    const app = produce(application, draft => {
      draft.isEnabled = false;
      draft.inPortfolio = 0;
    });
    dispatch(updateApplicationAsync(app));
  }
}

const SimpleApplicationBox = ({application, origin, isComplete}) => {
  const [isChecked, setIsChecked] = useState(false);
  const fromMedium = useBreakpoint('medium', null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!application.id) {
      setIsChecked(false);
    } else if (application.isEnabled) {
      setIsChecked(true);
    } else if (!application.isEnabled) {
      setIsChecked(false);
    }
  }, [application])

  return (
    <Table.Row className='joban'>
      <Table.Cell>
        <div className='application-name-row'>
          <img src={application.image} height={21} alt={name} /> <span>{application.name}</span> {application.isEnabled && <Badge state={"success"}>Added to portfolio</Badge>}
        </div>
      </Table.Cell>
      {(fromMedium && origin === FreewareOrigin && application.name !== 'Make me admin') &&
        <Table.Cell>All users will have access to this application</Table.Cell>
      }
      {application.name === 'Make me admin' &&
        <Table.Cell/>
      }
      <Table.Cell>
        <div className='application-button-row bfl-grid'>
          <Checkbox
            button
            label='Yes'
            checked={isChecked && application?.inPortfolio === 1}
            onChange={() => {
              setIsChecked(true);
              ToggleSimpleApp(application, true, origin, dispatch);
            }}
          />
          <Checkbox
            button
            label='No'
            checked={!isChecked && application?.inPortfolio === 2}
            onChange={() => {
              setIsChecked(false);
              ToggleSimpleApp(application, false, origin, dispatch);
            }}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

SimpleApplicationBox.propTypes = {
  application: PropTypes.object,
  origin: PropTypes.number,
  isComplete: PropTypes.bool
};

export default SimpleApplicationBox;