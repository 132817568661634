import { SimpleBox } from "components/Box/Box";
import React from "react";
import {useSelector} from 'react-redux';
import './apps.scss';
import {AppsAccordion} from './AppsAccordion';

export default function Apps() {
  const {businessApplications} = useSelector(state => state.company.data.mobilePlatform.mobileDevices);

  return (
    <SimpleBox
      title='Business Applications'
      className='mobile-apps-box'
    >
      <div className='mobile-apps-accordions'>
        {['Basic', 'Optional', 'Custom'].map((deploymentType, i) => {
          return (
            <AppsAccordion
              key={i}
              apps={businessApplications}
              name={deploymentType}
            />
          );
        })}
      </div>
    </SimpleBox>
  );
}
