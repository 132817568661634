import devLog from 'utils/devLog';
import {fetchCompany, fetchCompanyError, fetchCompanySuccess} from './companyFetchSlice';
import {
  addCurrentVendorContactSuccess,
  companyReset,
  deleteCurrentVendorContactSuccess,
  removeCompanySuccess,
  updateCompany,
  updateCompanyError,
  updateCompanyInformationSuccess,
  updateCompanyStateSuccess,
  updateCompanySuccess,
  updateCurrentVendor,
  updateCurrentVendorContactSuccess,
  updateCurrentVendorError
} from './companyUpdateSlice';
import apiFetch, {apiFetchIdCollection} from 'utils/apiFetch';
import {togglePlatformAsync, updatePlatformAsync} from './platformUpdateThunk';

export const fetchCompanyAsync = (companyId, forceFetch = false) => (dispatch, getState) => {
  const {fetching} = getState().company.fetch;
  if (fetching) return null;

  dispatch(fetchCompany());
  dispatch(companyReset());

  return apiFetch('/companies/' + companyId)
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to fetch company.');
      }

      return response.json();
    })
    .then(company => {
      dispatch(fetchCompanySuccess(company));
      return company;
    })
    .catch(error => {
      devLog('ERROR', error);
      dispatch(fetchCompanyError(error));
    });
};

export const updateCompanyAsync = (companyId, data) => (dispatch, getState) => {
  const {fetching} = getState().company.update;

  if (fetching) return null;

  dispatch(updateCompany());

  return apiFetch(`/companies/${companyId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to update company.');
      }

      return response.json();
    })
    .then(company => {
      devLog('Update company response', company);
      dispatch(updateCompanySuccess(company));

      return company;
    })
    .catch(error => dispatch(updateCompanyError(error)));
};

export const updateCompanyLayout = (companyId, data) => (dispatch, getState) => {
  const {fetching} = getState().company.update;

  if (fetching) return null;

  dispatch(updateCompany());

  return apiFetch(`/companies/${companyId}/layout`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to update company.');
      }

      return response.json();
    })
    .then(company => {
      devLog('Update company response', company);
      dispatch(updateCompanyStateSuccess(company));

      return company;
    })
    .catch(error => dispatch(updateCompanyError(error)));
};

export const updateCurrentVendorContactAsync = (contactId, data) => (dispatch, getState) => {
  const {updatingVendor} = getState().company.update;

  if (updatingVendor) return null;
  dispatch(updateCurrentVendor());

  const {info} = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${info.id}/VendorContacts/${contactId}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: data
  }).then(contact => {
      dispatch(updateCurrentVendorContactSuccess(contact));
    })
    .catch(error => dispatch(updateCurrentVendorError(error)));
};

export const addCurrentVendorContactAsync = (contact) => (dispatch, getState) => {
  const {updatingVendor} = getState().company.update;

  if (updatingVendor) return null;
  dispatch(updateCurrentVendor());

  const {info} = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${info.id}/VendorContacts`,
    method: 'POST',
    body: contact
  }).then(contact => {
      dispatch(addCurrentVendorContactSuccess(contact));
    })
    .catch(error => dispatch(updateCurrentVendorError(error)));
};

export const deleteCurrentVendorContactAsync = (contactId) => (dispatch, getState) => {
  const {updatingVendor} = getState().company.update;

  if (updatingVendor) return null;
  dispatch(updateCurrentVendor());

  const {info} = getState().company.data;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${info.id}/VendorContacts/${contactId}`,
    method: 'DELETE'
  }).then(() => {
      dispatch(deleteCurrentVendorContactSuccess());
    })
    .catch(error => dispatch(updateCurrentVendorError(error)));
};

export const unlockCompanyAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.update;

  if (fetching) return null;

  dispatch(updateCompany());

  return apiFetch(`/companies/${companyId}/unlock`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {}
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to update company.');
      }

      return response.json();
    })
    .then(company => {
      devLog('Update company response', company);
      dispatch(updateCompanySuccess(company));

      return company;
    })
    .catch(error => dispatch(updateCompanyError(error)));
};

export const deactivateCompanyAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.update;

  if (fetching) return null;

  dispatch(updateCompany());

  return apiFetch(`/companies/${companyId}/disable`, {
    method: 'PATCH'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to delete company.');
      }
      return response.json();
    })
    .then(company => {
      devLog('Delete company response', company);
      dispatch(removeCompanySuccess(company));

      return company;
    })
    .catch(error => dispatch(updateCompanyError(error)));
};

export const renameCompanyAsync = (id, newName) => (dispatch, getState) => {
  const {fetching} = getState().company.update;

  if (fetching) return null;

  dispatch(updateCompany());

  return apiFetch(`/companies/${id}/rename`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newName)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to rename company.');
      }
      return response?.json();
    })
    .then(company => {
      devLog('Rename company response', company);
      dispatch(updateCompanyInformationSuccess(company));

      return company;
    })
    .catch(error => dispatch(updateCompanyError(error)));
};

export const toggleService = (type, isEnabled) => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  return dispatch(togglePlatformAsync(type, id, isEnabled));
};

export const updateService = (type, values) => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  return dispatch(updatePlatformAsync(type, id, values));
};

export const updateContact = (id, data) => (dispatch, getState) => {
  const company = getState().company.data;

  const updateBody = {};

  if (company.info.onboardingContact.id === id) {
    updateBody.onboardingContact = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber
    };
  } else if (company.info.productionContact.id === id) {
    updateBody.productionContact = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber
    };
  }

  return dispatch(updateCompanyAsync(company.info.id, updateBody));
};

export const updateLockoutTimer = id => dispatch => {
  return dispatch(unlockCompanyAsync(id));
};

const checkDigits = number => {
  if (number < 10) {
    return '0' + number;
  }
  return number;
};

export const updateOnboardedDate = (id, date) => dispatch => {
  const newDate = date;

  const formatedDate = `${newDate.getUTCFullYear()}-${checkDigits(newDate.getUTCMonth() + 1)}-${checkDigits(
    newDate.getUTCDate()
  )}T${checkDigits(newDate.getUTCHours())}:${checkDigits(newDate.getUTCMinutes())}:${checkDigits(
    newDate.getUTCSeconds()
  )}.${newDate.getUTCMilliseconds()}Z`;

  const updateBody = {
    onboardedDate: formatedDate
  };

  return dispatch(updateCompanyAsync(id, updateBody));
};
