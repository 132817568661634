import React, {useEffect, useState} from 'react';
import { Icon, Label } from "@intility/bifrost-react";
import '../EditComponents/editComponent.scss';
import {useSelector} from 'react-redux';
import {departmentType, Detail} from '../../../utils/hub/models';
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

export const Microsoft365Details = () => {
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const {tenantId} = useSelector(state => state.company.data.info);
  const [O365TenantCspEnabled, setO365TenantCspEnabled] = useState(new Detail());
  const [userRegComplete, setUserRegComplete] = useState(new Detail());
  const [tenantGuid, setTenantGuid] = useState(new Detail());

  useEffect(() => {
    setO365TenantCspEnabled(getDetail('O365TenantCspEnabled'));
    setUserRegComplete(getDetail('UserRegistrationComplete'));
    setTenantGuid(getDetail('TenantGuid'));
  }, [detailList]);

  const getDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.IDENTITYANDMS)?.find(i => i.name === name) || new Detail()
    );
  };

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Identity and Microsoft 365</h3>
      </div>
      <div >
        <div className='labeled-checkbox'>
          <Label>O365-Tenant CSP enabled:
            {O365TenantCspEnabled?.boolValue ?
              <Icon icon={faCheckCircle} style={{color: 'var(--bfc-success)', marginLeft: 'var(--bfs8)'}}/>
              :
              <Icon icon={faTimesCircle} style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs8)'}}/>
            }
          </Label>
        </div>
        <div className='labeled-checkbox'>
          <Label>User registration complete:
            {userRegComplete?.boolValue ?
              <Icon icon={faCheckCircle} style={{color: 'var(--bfc-success)', marginLeft: 'var(--bfs8)'}}/>
              :
              <Icon icon={faTimesCircle} style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs8)'}}/>
            }
          </Label>
        </div>
        <div className='labeled-checkbox'>
          <Label>Tenant guid: {tenantId || tenantGuid?.stringValue}
          </Label>
        </div>
      </div>
    </div>
  );
};

export default Microsoft365Details;
