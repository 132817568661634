import PropTypes from 'prop-types';

const domainBoxPropTypes = {
  domain: PropTypes.object.isRequired,
  primaryId: PropTypes.string,
  toggleEdit: PropTypes.func,
  setDomainId: PropTypes.func,
  title: PropTypes.string
};

export default domainBoxPropTypes;
