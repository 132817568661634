import React, {useEffect, useState} from 'react';
import './note.scss';
import {useDispatch} from 'react-redux';
import useEditable from 'hooks/useEditable';
import {updateNote} from 'redux/company/communication/communicationThunks';
import {Accordion, Button} from '@intility/bifrost-react';
import PropTypes from 'prop-types';
import InputWithFeedback, {InputType} from '../../../../InputWithFeedback/InputWithFeedback';

const Note = ({note, isAllCollapsed, isComplete}) => {
  const [isThisCollapsed, setIsThisCollapsed] = useState(false);
  const dispatch = useDispatch();
  const editable = useEditable(note);

  useEffect(() => {
    setIsThisCollapsed(isAllCollapsed);
  }, [isAllCollapsed]);

  return (
    <Accordion.Item
      onClick={() => setIsThisCollapsed(!isThisCollapsed)}
      active={!isThisCollapsed}
      title={`Note (${note ? 'yes' : 'none'})`}
    >
      <div className='communication-content'>
        {!isThisCollapsed && (
          <div className='communication-note'>
            <div className='communication-note-row'>
              {editable.editing ? (
                <div>
                  <div className='communication-note-row-text'>
                    <InputWithFeedback
                      onChange={editable.onChange}
                      value={editable.state}
                      maxLength={4000}
                      label='Note'
                      hideLabel={true}
                      type={InputType.TextArea}
                    />
                  </div>
                  <div className='communication-note-row-buttons'>
                    <Button onClick={() => editable.toggleEditing(false)}>Cancel</Button>
                    <Button
                      variant='filled'
                      onClick={() => {
                        editable.toggleEditing(false);
                        dispatch(updateNote(editable.state));
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <p style={{flex: 1}}>
                    {note || 'No note'}
                  </p>
                  <Button disabled={isComplete} style={{margin: 'auto'}} onClick={() => editable.toggleEditing(true)}>
                    Add note
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Accordion.Item>
  );
};

Note.propTypes = {
  note: PropTypes.string,
  isAllCollapsed: PropTypes.bool,
  isComplete: PropTypes.bool
};

export default Note;
