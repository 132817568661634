import {Documentation} from '../../../components/Hub/Pages';
import {faFile} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Documents',
  path: 'documentation',
  icon: faFile,
  isEnabled: (company) => {return company?.documentationPlatform?.state},
  component: Documentation,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional documentation';
  }
};
