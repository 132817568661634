import React, { useEffect, useRef, useState } from "react";
import './replacementDropdown.scss';
import { Badge, Checkbox, Icon } from "@intility/bifrost-react";
import PropTypes from "prop-types";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { useOutsideClicker } from "../../../../../hooks/useOutsideClicker";
import produce from "immer";
import { updateIamsUserDevicesAsync } from "../../../../../redux/company/iamsUsers/iamsUserThunks";
import { useDispatch, useSelector } from "react-redux";
import { KeepComputer, OsComputer, ReplaceComputer } from "./IamsSelectOptions";

export const ReplacementDropdown = ({userId, isComplete}) => {
  const { iamsUserList, workplaceDevices } = useSelector(state => state.company.data.iamsUserPlatform);
  const [isOpen, setOpen] = useState(false);
  const [upgradeOs, setUpgradeOs] = useState(undefined);
  const [keepComputer, setKeepComputer] = useState(undefined);
  const [edited, setEdited] = useState(false);
  const [editDevices, setEditDevices] = useState(undefined);
  const [editIamsUser, setEditIamsUser] = useState(undefined);
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    const user = iamsUserList.find(i => i.id === userId)
    setEditIamsUser(user);
    setEditDevices(user?.devices)
    setKeepComputer(user?.computerAction === 'Keep')
    setUpgradeOs(user?.computerAction === 'Upgrade')
  }, [iamsUserList, userId])

  useOutsideClicker(ref, setOpen);

  useEffect(() => {
    if ((edited && editIamsUser) && !isOpen) {
      const updatedUser = produce(editIamsUser, draft => {
        draft.devices = editDevices;
        if (editDevices?.some(i => i.type === 'Computer')) draft.computerAction = ReplaceComputer;
      });
      dispatch(updateIamsUserDevicesAsync([updatedUser]));
      setEdited(false);
    }
  }, [edited, isOpen])

  const onComputerStatusChange = (value, status) => {
    if (value) {
      setEditDevices(editDevices?.filter(d => d.type !== 'Computer'));
      setEditIamsUser(
        produce(editIamsUser, draft => {
          draft.computerAction = status === OsComputer ? OsComputer : KeepComputer;
          draft.devices = editDevices;
        })
      );
    } else {
      setEditIamsUser(
        produce(editIamsUser, draft => {
          draft.computerAction = "";
        })
      );
    }
    if (status === OsComputer) {
      setUpgradeOs(value);
    } else if (status === KeepComputer) {
      setKeepComputer(value)
    }
    setEdited(true);
  }
  const onChange = (value) => {
    const i = editDevices.findIndex(i => i.model === value.model);

    if (i > -1)
      setEditDevices(
        produce(editDevices, draft => {
          draft.splice(i, 1);
        })
      );
    else
      setEditDevices(
        produce(editDevices, draft => {
          draft.push(value);
        })
      );
    setEdited(true);
  }

  const computerDevices = editDevices && editDevices?.filter(d => d.type === 'Computer')

  return (
    <div className='replacement-dropdown' ref={ref}>
      <div className={isOpen ? 'replacement-dropdown-input active-replacement-dropdown' : 'replacement-dropdown-input inactive-replacement-dropdown'} aria-label="Open location" tabIndex={0} onKeyDown={(e) => {e.keyCode !== 13 || setOpen(!isOpen)}} onClick={() => setOpen(!isOpen)}>
        {editDevices?.length === 0 && (editIamsUser?.computerAction?.length <= 0 || editIamsUser?.computerAction === null) ?
          <p style={{margin: '0', color: 'var(--bfc-base-c-2)'}}>Would you like to replace?</p> :
          <>
            {editIamsUser?.computerAction?.startsWith(KeepComputer) ?
              <Badge state={"success"}>Keep computer</Badge>
              : editIamsUser?.computerAction?.startsWith(OsComputer) ?
              <Badge state={"default"}>Upgrade OS</Badge> :
              <Badge state={(upgradeOs || computerDevices?.length > 0 || editIamsUser?.intilityRecommendation?.startsWith('Keep')) ? "default" : "warning"}>Computer: {computerDevices?.length}</Badge>
            }
            <Badge>Dock: {editDevices?.filter(d => d.type === 'Docking').length}</Badge>
            <Badge>Peripherals: {editDevices?.filter(d => (d.type === 'Peripherals' || d.type === 'Screen')).length}</Badge>
            <div className='dropdown-icon'>
              <Icon icon={isOpen ? faCaretUp : faCaretDown}/>
            </div>
          </>
        }
      </div>
      {isOpen &&
        <div className='replacement-dropdown-body'>
          <p className='bf-em'>Keep computer as is</p>
          <Checkbox className='upgrade-os-switch' label='Keep computer unchanged' onChange={e => onComputerStatusChange(e.target.checked, KeepComputer)} checked={keepComputer} disabled={upgradeOs || isComplete}/>
          <p className='bf-em'>Replace computer or upgrade OS:</p>
          {workplaceDevices.filter(d => d.type === 'Computer').map(d => {
            return <Checkbox key={d.id + editIamsUser?.id} label={d.model} onChange={() => onChange(d)} checked={editDevices?.find(i => i.id === d.id) !== undefined} disabled={(upgradeOs || keepComputer) || isComplete}/>
          })}
          <Checkbox className='upgrade-os-switch' label='Upgrade OS' type={"switch"} onChange={e => onComputerStatusChange(e.target.checked, OsComputer)} checked={upgradeOs} disabled={keepComputer || isComplete}/>
          <p className='bf-em'>Select dock:</p>
          {workplaceDevices.filter(d => d.type === 'Docking').map(d => {
            return <Checkbox key={d.id + editIamsUser?.id} label={d.model} onChange={() => onChange(d)} checked={editDevices?.find(i => i.id === d.id) !== undefined} disabled={isComplete}/>
          })}
          <p className='bf-em'>Peripherals:</p>
          {workplaceDevices.filter(d => (d.type === 'Screen' || d.type === 'Peripherals')).map(d => {
            return <Checkbox key={d.id + editIamsUser?.id} label={d.model} onChange={() => onChange(d)} checked={editDevices?.find(i => i.id === d.id) !== undefined} disabled={isComplete}/>
          })}
        </div>
      }
    </div>

  )

}

ReplacementDropdown.propTypes = {
  userId: PropTypes.string,
  allDevices: PropTypes.array,
  isComplete: PropTypes.bool,
  userDevices: PropTypes.array
}

export default ReplacementDropdown