import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Contact, Domain } from "utils/hub/models";
import produce from 'immer';
import './addDomainSlider.scss';
import {addDomainAsync, deleteDomainAsync, updateDomainAsync} from 'redux/company/domain/domainThunks';
import Flex from '../../../../Flex/Flex';
import { Button, Drawer, Input, Label, Checkbox, useFloatingMessage } from "@intility/bifrost-react";
import {faTimes} from '@fortawesome/pro-light-svg-icons';
import devLog from '../../../../../utils/devLog';
import {updateDomainsError} from '../../../../../redux/company/domain/domainReducers';
import PropTypes from 'prop-types';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import {ValidateDomain} from '../../../../../utils/wizard/stateValidations';

const AddDomainSlider = ({setIsOpen, isOpen, domainId}) => {
  const dispatch = useDispatch();
  const {domainList, information, domainStatus} = useSelector(state => state.company.data.domainPlatform);
  const [domain, setDomain] = useState(new Domain());
  const [moveToIntility, setMoveToIntility] = useState(false);
  const [selected, setSelected] = useState(false);
  const [validation, setValidation] = useState(undefined);
  const [existing, setExisting] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const { showFloatingMessage } = useFloatingMessage();

  useEffect(() => {
    if (domainStatus.error) {
      showFloatingMessage('Ups, an error occurred, but our developers are notified, please try again later', { state: 'alert' });
    }
  }, [domainStatus.error])


  useEffect(() => {
    setSelected(domainId === information.primaryId);
  }, [domainId, information.primaryId]);

  useEffect(() => {
    if (saving) {
      if (!validation && domainId !== undefined && domainId !== null && domainId !== false) {
        devLog('Updating!', domain);
        dispatch(updateDomainAsync(domain)).then(s => {
          setIsOpen(s?.type === updateDomainsError.type);
        });
        onClose();
      } else if (!validation && existing === undefined) {
        devLog('Adding!', domain);
        dispatch(addDomainAsync(domain)).then(s => {
          setIsOpen(s?.type === updateDomainsError.type);
        });
        onClose();
      }
    }
    setSaving(false);
  }, [saving]);

  useEffect(() => {
    if (domainId !== undefined && domainId !== null && domainId !== false) {
      const domain = domainList?.find(dom => dom.id === domainId);
      if (domain !== undefined) {
        setDomain(domain);
        setMoveToIntility(domain.moveToIntility);
      }
    } else {
      setMoveToIntility(false);
      setDomain(new Domain());
    }
  }, [domainId, domainList]);

  const changeSwitch = (e, name, setState) => {
    const {checked} = e.target;
    setState(checked);
    setDomain(
      produce(draft => {
        draft[name] = checked;
      })
    );
  };

  const changeMain = e => {
    const {name, checked} = e.target;
    setDomain(
      produce(draft => {
        draft[name] = checked;
      })
    );
  };

  const onChange = e => {
    const {name, value} = e.target;
    setDomain(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const onContactChange = (e) => {
    const {name, value} = e.target;
    if (domain.domainContact === null) {
      setDomain(
        produce(draft => {
          draft.domainContact = new Contact();
        })
      );
    }
    setDomain(
      produce(draft => {
        draft.domainContact[name] = value;
      })
    );
  }

  const onClose = () => {
    setIsOpen(false);
    setSaving(false);
    setValidation(undefined);
    setSelected(false);
    setExisting(undefined);
  };

  const onSave = () => {
    setSaving(true);
    setValidation(ValidateDomain(domain.name));
    setExisting(domainList?.find(d => d.name?.toLowerCase() === domain.name?.toLowerCase()));
  };

  return (
    <Drawer
      header={domainId !== null ? 'Edit domain' : 'Add domain'}
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlay
      footer={
        <div className='drawer-bottom-button-row'>
          {domainId !== undefined && domainId !== null && domainId !== false ? (
            <Button
              variant='outline'
              state='alert'
              onClick={() => {
                setIsOpen(false);
                dispatch(deleteDomainAsync(domainId));
              }}
              disabled={information.primaryId === domainId}
              icon={faTimes}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <div className='button-row-collection'>
            <Button onClick={() => onClose()} key={0}>
              Cancel
            </Button>
            <Button variant='filled' onClick={() => onSave()} key={1}>
              Save
            </Button>
          </div>
        </div>
      }
    >
      <form
        onSubmit={event => {
          event.preventDefault();
          onSave();
        }}
        className='domain-slider'
      >
        <Flex style={{marginBottom: 'var(--bfs24)'}}>
          <InputWithFeedback
            onChange={onChange}
            label={'Domain name'}
            name={'name'}
            required={true}
            value={domain?.name || ''}
            otherProps={
              validation ? {
                state: 'warning',
                feedback: 'Needs to be correct domain format, example: intility.com'
              } : existing && {
                state: 'warning',
                feedback: 'This domain already exist'
              }
            }
          />
        </Flex>
        <div className='domain-slider-checkbox'>
          <Checkbox
            label="Transfer to Intility's administration"
            checked={moveToIntility}
            type={'switch'}
            onChange={e => changeSwitch(e, 'moveToIntility', setMoveToIntility)}
            style={{minWidth: '250px'}}
          />
          <p className='bf-small bf-em' style={{minWidth: '40%', whiteSpace: 'break-spaces', margin: 0}}>
            Enables Intility to manage the domain and DNS records on your behalf
          </p>
        </div>
        <hr style={{margin: 'var(--bfs24) 0'}}/>
        <div className='domain-slider-checkbox'>
          <Checkbox
            disabled={information.primaryId === domainId}
            label="Set as primary domain"
            checked={selected}
            type={'switch'}
            onChange={e => {
              changeSwitch(e, 'main', setSelected)
            }}
            style={{minWidth: '250px'}}
          />
          <p className='bf-small bf-em' style={information.primaryId === domainId ? { whiteSpace: 'break-spaces', margin: 0, color: 'var(--bfc-base-c-disabled)'} : { whiteSpace: 'break-spaces', margin: 0}}>
            The organization&apos;s primary domain, usually the domain emails are sent from
          </p>
        </div>
        {information.primaryId === domainId &&
          <p className='bf-small bf-em' style={{ whiteSpace: 'break-spaces', marginTop: 'var(--bfs4)'}}>
            You can&apos;t leave the primary domain section empty.
          </p>
        }
        <Label style={{marginTop: 'var(--bfs24)'}}>Domain type</Label>
        <Flex style={{marginBottom: 'var(--bfs24)'}}>
          <Checkbox
            label='E-mail domain'
            checked={domain?.isEmailDomain}
            name='isEmailDomain'
            onChange={changeMain}
          />
          <Checkbox label='Web domain' checked={domain?.isWebDomain} name='isWebDomain' onChange={changeMain} />
        </Flex>
        <Input label='Registrar' name='registrar' value={domain?.registrar || ''} onChange={onChange} description='The company or website where the domain is currently registered and DNS records are set.'/>
        <hr style={{margin: 'var(--bfs24) 0'}}/>
        <h4>Domain administrator</h4>
        <Flex>
          <Input label='First name' name='firstName' onChange={onContactChange} value={domain?.domainContact?.firstName || ''}/>
          <Input label='Last name' name='lastName' onChange={onContactChange} value={domain?.domainContact?.lastName || ''}/>
        </Flex>
        <Flex>
          <Input label='E-mail' name='email' onChange={onContactChange} value={domain?.domainContact?.email || ''}/>
          <Input label='Phone' name='phoneNumber' onChange={onContactChange} value={domain?.domainContact?.phoneNumber || ''}/>
        </Flex>
      </form>
    </Drawer>
  );
};

AddDomainSlider.propTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  domainId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default AddDomainSlider;
