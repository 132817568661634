import {addListItem, deleteListItems, editListItem, editListItems} from '../../../utils/hub/normalize';

export const editUser = (userList, user) => {
  const newList = editListItem(userList, user);

  return {
    userPlatform: {
      userList: newList
    }
  };
};

export const editMultipleUsers = (userList, userPlatform) => {
  const newList = editListItems(userList, userPlatform);

  return {
    userPlatform: {
      userList: newList
    }
  };
};

export const addNewUser = (user, userList) => {
  const newList = addListItem(userList, user);
  return {
    userPlatform: {
      userList: newList,
      user: user
    }
  };
};

export const clearUser = () => {
  return {
    userPlatform: {
      user: null
    }
  };
};

export const deleteMultipleUsers = (userList, userPlatform) => {
  const newList = deleteListItems(userList, userPlatform);

  return {
    userPlatform: {
      userList: newList
    }
  };
};

export const normalizeUsers = userPlatform => {
  return {
    userPlatform: {
      userList: userPlatform.userList,
      information: userPlatform,
      state: userPlatform.state
    }
  };
};

export const normalizeUsersInformation = userPlatform => {
  return {
    userPlatform: {
      information: userPlatform,
      state: userPlatform.state
    }
  };
};

export const normalizeUsersList = userPlatform => {
  return {
    userPlatform: {
      userList: userPlatform.userList
    }
  };
};
