import {Button, Label, Grid, Icon} from '@intility/bifrost-react';
import React from 'react';
import {faAdd, faUndo} from '@fortawesome/free-solid-svg-icons';
import Contact from './Contact';
import AddNewVendorContact from './AddNewVendorContact';
import AddNewInternalContact from './AddNewInternalContact';
import {useState, useRef} from 'react';
import {useFloatingMessage} from '@intility/bifrost-react';
import EditInternalContact from './EditInternalContact';
import EditVendorContact from './EditVendorContact';

const Contacts = ({appObject, setAppObject}) => {
  const [showAddNewVendorContact, setShowAddNewVendorContact] = useState(false);
  const [showAddNewInternalContact, setShowAddNewInternalContact] = useState(false);
  const [contactToEdit, setContactToEdit] = useState(null);
  const [vendorContactToEdit, setVendorContactToEdit] = useState(null);
  const [editConfig, setEditConfig] = useState({visible: false, contactId: null, isInternal: false});
  const {showFloatingMessage} = useFloatingMessage();

  const handleEdit = (contactId, isInternal) => {
    const contacts = isInternal ? appObject.internalContacts : appObject.vendorContacts;
    const contact = contacts.find(contact => contact.id === contactId);
    isInternal ? setContactToEdit(contact) : setVendorContactToEdit(contact);
    setEditConfig({visible: true, contactId, isInternal});
  };

  const lastDeletedContact = useRef(null);

  const deleteContact = (contactId, isInternal) => {
    const contactsKey = isInternal ? 'internalContacts' : 'vendorContacts';
    const deletedContact = appObject[contactsKey].find(contact => contact.id === contactId);
    setAppObject(prevAppObject => ({
      ...prevAppObject,
      [contactsKey]: prevAppObject[contactsKey].filter(contact => contact.id !== contactId)
    }));
    lastDeletedContact.current = {contact: deletedContact, isInternal};
    showFloatingMessage(
      <div>
        <span>Contact deleted. </span>
        <button
          onClick={e => {
            e.preventDefault();
            undoDelete();
          }}
          className='delete-contact-undo-button'
        >
          <span>Undo </span>
          <Icon icon={faUndo} />
        </button>
      </div>,
      {state: 'alert', noIcon: true}
    );
  };

  const undoDelete = () => {
    if (!lastDeletedContact.current) {
      return;
    }

    const {contact, isInternal} = lastDeletedContact.current;
    if (isInternal) {
      setAppObject(prevAppObject => ({
        ...prevAppObject,
        internalContacts: [...prevAppObject.internalContacts, contact]
      }));
    } else {
      setAppObject(prevAppObject => ({
        ...prevAppObject,
        vendorContacts: [...prevAppObject.vendorContacts, contact]
      }));
    }

    lastDeletedContact.current = null;
  };

  const addContact = (contact, isInternal) => {
    if (isInternal) {
      setAppObject(prevAppObject => ({
        ...prevAppObject,
        internalContacts: [...prevAppObject.internalContacts, contact]
      }));
    } else {
      setAppObject(prevAppObject => ({
        ...prevAppObject,
        vendorContacts: [...prevAppObject.vendorContacts, contact]
      }));
    }
    isInternal ? setShowAddNewInternalContact(false) : setShowAddNewVendorContact(false);
  };

  const cancelAddContact = isInternal => {
    isInternal ? setShowAddNewInternalContact(false) : setShowAddNewVendorContact(false);
  };

  const updateVendorContact = updatedContact => {
    const updatedVendorContacts = appObject.vendorContacts.map(contact =>
      contact.id === updatedContact.id ? updatedContact : contact
    );
    setAppObject({...appObject, vendorContacts: updatedVendorContacts});

    setEditConfig({visible: false});
  };

  const updateInternalContact = (id, updatedContact) => {
    const updatedInternalContacts = appObject.internalContacts.map(contact =>
      contact.id === id ? updatedContact : contact
    );
    setAppObject({...appObject, internalContacts: updatedInternalContacts});

    setEditConfig({visible: false});
  };

  return (
    <div className='basic-detail-box'>
      <div className='basic-detail-box-header'>
        <h5>Contacts</h5>
      </div>
      <Grid className='contacts-box-content'>
        <div className='add-application-contact-container'>
          <div>
            <Label>Vendor contact</Label>
            <div className='bf-label-description'>Add one or more vendor contacts</div>
          </div>
          <Button
            className='add-contact-button'
            pill
            onClick={() => setShowAddNewVendorContact(!showAddNewVendorContact)}
          >
            <Icon icon={faAdd} marginRight />
            Add vendor contact
          </Button>
        </div>
        {appObject.vendorContacts.map((contact, index) => {
          if (editConfig.visible && editConfig.isInternal === false && editConfig.contactId === contact.id) {
            return (
              <EditVendorContact
                key={contact.id}
                contact={vendorContactToEdit}
                updateContact={updateVendorContact}
                deleteContact={contactId => deleteContact(contactId, false)}
                handleCancel={() => setEditConfig({visible: false, contactId: null, isInternal: false})}
              />
            );
          } else {
            return (
              <Contact
                key={contact.id}
                badgeList={contact.contactRoles}
                firstName={contact.firstName}
                lastName={contact.lastName}
                phoneNumber={contact.phoneNumber}
                email={contact.email}
                onEdit={() => handleEdit(contact.id, false)}
                isInternal={false}
              />
            );
          }
        })}
        {showAddNewVendorContact && <AddNewVendorContact addContact={addContact} cancelAddContact={cancelAddContact} />}
        <div className='add-application-contact-container' style={{paddingTop: '16px'}}>
          <div>
            <Label>Internal contact</Label>
            <div className='bf-label-description'>Add one or more internal contacts that knows the application</div>
          </div>
          <Button
            className='add-contact-button'
            pill
            onClick={() => setShowAddNewInternalContact(!showAddNewInternalContact)}
          >
            <Icon icon={faAdd} marginRight />
            Add internal contact
          </Button>
        </div>
        {appObject.internalContacts.map(contact => {
          if (editConfig.visible && editConfig.isInternal === true && editConfig.contactId === contact.id) {
            return (
              <EditInternalContact
                key={contact.id}
                contact={contactToEdit}
                updateContact={updateInternalContact}
                deleteContact={contactId => deleteContact(contactId, true)}
                handleCancel={() => setEditConfig({visible: false, contactId: null, isInternal: true})}
              />
            );
          } else {
            return (
              <Contact
                key={contact.id}
                badgeList={contact.contactRoles}
                firstName={contact.firstName}
                lastName={contact.lastName}
                phoneNumber={contact.phoneNumber}
                email={contact.email}
                onEdit={() => handleEdit(contact.id, true)}
                isInternal={true}
              />
            );
          }
        })}
        {showAddNewInternalContact && (
          <AddNewInternalContact addContact={addContact} cancelAddContact={cancelAddContact} />
        )}
      </Grid>
    </div>
  );
};

export default Contacts;
