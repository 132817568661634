
export const updateCurrentVendorContact = (contact) => {

  return {
    currentVendorContact: contact
  };
};

export const addCurrentVendorContact = (contact) => {
  return {
    currentVendorContact: contact
  };
};

export const deleteCurrentVendorContact = () => {
  return {
    currentVendorContact: null
  };
};