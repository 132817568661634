import PropTypes from "prop-types";
import { Accordion, Badge, Button, Message } from "@intility/bifrost-react";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import NotRegisteredField from "../../../../../../NotRegisteredField/NotRegisteredField";
import React, { useEffect, useState } from "react";
import { DrawerType } from "../LocationInformation";

export const hasContactInfo = (contact) => {
  if (contact === null) return false;
  return !((contact?.email === null || contact?.email === '') &&
    (contact?.phoneNumber === null || contact?.phoneNumber === ''));
}

export const hasCompanyInfo = (company) => {
  if (company === null) return false;
  if (company?.name === null || company?.name === '') return false;
  if (company?.address === null || company?.address === '') return false;
  // if (company?.zipCode === null || company?.zipCode === '') return false;
  // if (company?.postalPlace === null || company?.postalPlace === '') return false;
  // if (company?.country === null || company?.country === '') return false;
  return true;
}

export const hasMissingCompanyInfo = (location) => {
  let progress = 2;

  if (hasCompanyInfo(location.recipientCompany)) progress--;
  if (hasContactInfo(location.recipientContact)) progress--;

  if (location?.eoriNumber !== 'Not relevant') {
    progress += 2;
    if (hasCompanyInfo(location.legalCompany)) progress--;
    if (hasContactInfo(location.financialContact)) progress--;
  }
  return progress > 0;
};

const DeliveryAccordionContent = ({location, isDisabled, activeAccordion, handleChange, editLocation}) => {
  const [hasDeliveryInfo, setHasDeliveryInfo] = useState(false);
  const [hasMissingDeliveryInfo, setHasMissingDeliveryInfo] = useState(false);

  useEffect(() => {
    if (location === null) return;
    let isCancelled = false;
    const hasRecipientContact = hasContactInfo(location?.recipientContact)
    const hasFinancialContact = hasContactInfo(location?.financialContact)
    const hasLegalCompany = hasCompanyInfo(location?.legalCompany)
    const hasRecipientCompany = hasCompanyInfo(location?.recipientCompany)
    const hasMissingInfo = hasMissingCompanyInfo(location);

    if (!isCancelled) {
      setHasDeliveryInfo(hasRecipientContact === true
        || hasFinancialContact === true
        || hasLegalCompany === true
        || hasRecipientCompany === true)
      setHasMissingDeliveryInfo(hasMissingInfo);
    }
    return () => {
      isCancelled = true;
    };
  }, [location]);

  return (
    <Accordion.Item
      actions={!hasDeliveryInfo ?
        <Button onClick={editLocation}
                small state={isDisabled ? 'inactive' : 'default'}
                style={{marginTop: '8px', marginRight: '8px'}}
                variant='filled' >Add information</Button>
        :
        <Accordion.Action
          icon={faPencil}
          style={isDisabled ? { color: 'var(--bfc-neutral)' } : undefined}
          onClick={editLocation}
          aria-label='Edit'
        />}
      title={<span style={{display: 'flex', justifyContent: 'space-between'}}>Delivery
          <span>{(hasDeliveryInfo && hasMissingDeliveryInfo) && <Badge state={"warning"}>Missing information</Badge>}</span>
        </span>
      }
      active={activeAccordion.includes(DrawerType.DELIVERY)}
      onClick={() => handleChange(DrawerType.DELIVERY)}
    >
      {hasDeliveryInfo ?
        <div className='delivery-accordion bfl-grid'>
          {hasCompanyInfo(location?.recipientCompany) ?
            <div>
              <p className='bf-strong'>Recipient location</p>
              <NotRegisteredField field={location?.recipientCompany?.name} />
              <NotRegisteredField field={location?.recipientCompany?.address} />
              <p style={{ color: 'var(--bfc-base-c-1)' }}>
                {location?.recipientCompany?.zipCode} {location?.recipientCompany?.postalPlace && ` ${location?.recipientCompany?.postalPlace}`}
              </p>
              <p style={{ color: 'var(--bfc-base-c-1)' }}>
                {location?.recipientCompany?.country}
              </p>
            </div>
            :
            <div>
              <p className='bf-strong'>Recipient location</p>
              <div>
                <Badge state={"warning"}>Missing information</Badge>
              </div>
            </div>
          }
          {hasContactInfo(location?.recipientContact) ?
            <div>
              <p className='bf-strong'>Recipient contact</p>
              <NotRegisteredField field={location?.recipientContact?.firstName}
                                  fieldTwo={location?.recipientContact?.lastName} />
              <NotRegisteredField field={location?.recipientContact?.phoneNumber} />
              <NotRegisteredField field={location?.recipientContact?.email} />
            </div>
            :
            <div>
              <p className='bf-strong'>Recipient contact</p>
              <div>
                <Badge state={"warning"}>Missing information</Badge>
              </div>
            </div>
          }
          {location?.eoriNumber !== 'Not relevant' &&
            (hasCompanyInfo(location?.legalCompany) ?
              <div>
                <p className='bf-strong'>Legal company</p>
                <NotRegisteredField field={location?.legalCompany?.name} />
                <NotRegisteredField field={location?.legalCompany?.address} />
                <p style={{ color: 'var(--bfc-base-c-1)' }}>
                  {location?.legalCompany?.zipCode} {location?.legalCompany?.postalPlace && ` ${location?.legalCompany?.postalPlace}`}
                </p>
                <p style={{ color: 'var(--bfc-base-c-1)' }}>
                  {location?.legalCompany?.country}
                </p>
              </div>
              :
              <div>
                <p className='bf-strong'>Legal company</p>
                <div>
                  <Badge state={"warning"}>Missing information</Badge>
                </div>
              </div>)
          }
          {location?.eoriNumber !== 'Not relevant' &&
            (hasContactInfo(location?.financialContact) ?
              <div>
                <p className='bf-strong'>Financial contact</p>
                <NotRegisteredField field={location?.financialContact?.firstName}
                                    fieldTwo={location?.financialContact?.lastName} />
                <NotRegisteredField field={location?.financialContact?.phoneNumber} />
                <NotRegisteredField field={location?.financialContact?.email} />
              </div>
              :
              <div>
                <p className='bf-strong'>Financial contact</p>
                <div>
                  <Badge state={"warning"}>Missing information</Badge>
                </div>
              </div>)
          }
          <div>
            <p className='bf-strong'>EORI number</p>
            <NotRegisteredField field={location?.eoriNumber === null ? false : location?.eoriNumber} />
          </div>
        </div>
        :
        <div>
          <Message>
            General delivery information
          </Message>
        </div>
      }
    </Accordion.Item>
  )

}

DeliveryAccordionContent.propTypes = {
  location: PropTypes.object,
  isDisabled: PropTypes.bool,
  activeAccordion: PropTypes.array,
  handleChange: PropTypes.func,
  editLocation: PropTypes.func
}

export default DeliveryAccordionContent