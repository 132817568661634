import React, {useEffect, useState} from 'react';
import './editComponent.scss';
import {
  Button,
  Drawer,
  FieldGroup,
  Icon,
  Input,
  Label,
  Modal,
  useBreakpoint
} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import { faPlus, faStar, faTimes, faUndo } from "@fortawesome/pro-regular-svg-icons";
import {useDispatch, useSelector} from 'react-redux';
import Team from '../ProjectTeam/Team';
import {
  addMembershipRoleAsync,
  addProjectTeamAsync,
  deleteProjectTeamAsync,
  fetchMembershipRoleAsync,
  fetchProjectTeamsAsync,
  multiPutTeamRolesAsync,
  updateProjectTeamAsync
} from '../../../redux/company/projectTeams/projectTeamsThunks';
import {MemberRole, TeamObject, TeamRole} from '../../../utils/hub/models';
import produce from 'immer';
import MemberDropdown from '../Components/MemberDropdown';
import { Link } from "react-router-dom";

export const complexityOptions = [
  {value: 0, label: 'Not set'},
  {value: 1, label: 'Easy'},
  {value: 2, label: 'Moderate'},
  {value: 3, label: 'Hard'},
]

export const EditProjectTeam = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {list, roles} = useSelector(state => state.company.data.projectTeams);
  const [listCopy, setListCopy] = useState([]);
  const {user} = useSelector(state => state.auth);
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [options, setOptions] = useState([{value: 1, label: 'Add role'}]);
  const [team, setTeam] = useState(new TeamObject());
  const [teamId, setTeamId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addingRole, setAddingRole] = useState(false);
  const [newRole, setNewRole] = useState(new MemberRole());
  const dispatch = useDispatch();
  const toMedium = useBreakpoint(null, 'medium');
  const fromMediumToXl = useBreakpoint('medium', 'xl');

  useEffect(() => {
    setListCopy(list);
  }, [list]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchProjectTeamsAsync(id));
    dispatch(fetchMembershipRoleAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    const roleOptions = roles.map(i => ({label: i.name, value: i}));
    roleOptions.splice(roleOptions.length, 0, {
      value: '201',
      label: (
        <span style={{color: 'var(--bfc-base-c-theme)'}}>
          <Icon icon={faPlus} /> Add new role
        </span>
      )
    });
    setOptions(roleOptions);
  }, [roles]);

  const resetValues = () => {
    setItemsToEdit([]);
    setTeam(new TeamObject());
    dispatch(fetchProjectTeamsAsync(id));
  };

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutTeamRolesAsync(itemsToEdit));
      setItemsToEdit([]);
    }
  };

  const openDrawer = (team, teamId) => {
    setTeam(team);
    setTeamId(teamId);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setTeam(new TeamObject());
    setTeamId(null);
    setDrawerOpen(false);
  };

  const onChange = (name, value, setState) => {
    setState(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const onRoleChange = (value, number) => {
    setTeam(
      produce(team, draft => {
        draft.teamRoles[number].name = value.name;
      })
    );
  };

  const onComplexityChange = (value, number) => {
    setTeam(
      produce(team, draft => {
        draft.teamRoles[number].complexity = value;
      })
    );
  };

  const onMemberChange = (member, number) => {
    setTeam(
      produce(team, draft => {
        draft.teamRoles[number].member = member;
        draft.teamRoles[number].memberId = member.id;
      })
    );
  };

  const addRole = () => {
    const newValue = produce(team, draft => {
      draft.teamRoles.push(new TeamRole());
    });
    setTeam(newValue);
  };

  const addRoleOption = role => {
    const newOpt = produce(new MemberRole(), draft => {
      draft.name = role.name;
      draft.companyId = id;
    });
    dispatch(addMembershipRoleAsync(id, newOpt));
    resetRoleModal();
  };

  const resetRoleModal = () => {
    setAddingRole(false);
    setNewRole(new MemberRole());
  };

  const removeRole = role => {
    const i = team.teamRoles.findIndex(
      r => r.id === role.id && r.teamMemberId === role.teamMemberId && r.name === role.name
    );
    const newValue = produce(team, draft => {
      draft.teamRoles.splice(i, 1);
    });
    setTeam(newValue);
  };

  const saveProjectTeam = teamId => {
    if (teamId) {
      dispatch(updateProjectTeamAsync(id, team));
    } else {
      dispatch(addProjectTeamAsync(id, team));
    }
    closeDrawer();
  };

  const deleteTeam = (teamId) => {
    const result = confirm('Are you sure you wish to delete this team?');
    if (result) {
      dispatch(deleteProjectTeamAsync(id, teamId));
      setDrawerOpen(false);
    }
  };

  const chunk = list.length / (toMedium ? 1 : fromMediumToXl ? 2 : 4);
  const rest = list.length % (toMedium ? 1 : 2);

  const getRows = () => {
    let i, j, temporary;
    const test = [];
    for (i = 0, j = listCopy.length; i < j; i += chunk) {
      if (i === 0 && rest > 0) {
        temporary = list.slice(i, ++i + chunk);
      } else {
        temporary = list.slice(i, i + chunk);
      }
      test.push(
        <div key={i} className='teams-row'>
          {temporary.map((l, i) => {
            return (
              <Team
                key={i}
                listPosition={i}
                team={l}
                edit={true}
                openDrawer={openDrawer}
                teamsToEdit={itemsToEdit}
                setTeamsToEdit={setItemsToEdit}
              />
            );
          })}
        </div>
      );
    }
    return test;
  };

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <div className='backoffice-button-group-left'>
          <span style={{display: 'flex', flexDirection: 'column'}}>
            <h3>Project team</h3>
            <Label><Icon icon={faStar}/> = Project team manager</Label>
          </span>
          <div>
            <Button variant='outline' onClick={() => openDrawer(new TeamObject(), null)}>
              Add custom team
            </Button>
          </div>
        </div>
        <div className='backoffice-button-group'>
          {itemsToEdit.length > 0 && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button variant={'filled'} disabled={itemsToEdit.length === 0} onClick={() => saveDetails()}>
            Save
          </Button>
        </div>
      </div>
      <div className='backoffice-editable-project-teams bfl-grid'>{getRows().map(i => i)}</div>
      <Drawer
        header={teamId ? 'Edit team' : 'Add custom team'}
        isOpen={drawerOpen}
        overlay
        disableFocusTrap={true}
        onRequestClose={() => closeDrawer()}
        className='backoffice-editable-container-drawer'
        footer={
          <div className='team-drawer-footer'>
            {teamId && (
              <Button
                variant={'outline'}
                disabled={team?.name === "General"}
                onClick={() => deleteTeam(team.id)}
                state={'alert'}
              >
                Delete team
              </Button>
            )}
            <Button onClick={() => setDrawerOpen(false)}>Cancel</Button>
            <Button variant={'filled'} onClick={() => saveProjectTeam(teamId)}>
              {teamId ? 'Save' : 'Add'}
            </Button>
          </div>
        }
      >
        <span className='bf-p bf-em'>Changes only applies to this project, to edit global teams navigate to the <Link className='bf-link' to='/overview/settings/projectTeams'>Settings page</Link></span>
        <Input disabled={team?.name === "General"} label='Team name' value={team?.name || ''} onChange={e => onChange('name', e.target.value, setTeam)} />
        <div className='input-group'>
          <Label>Team members</Label>
          {team.teamRoles.map((m, i) => {
            return (
              <div className='input-group-role-row' key={i}>
                <FieldGroup>
                  <Select
                    label='Role'
                    hideLabel={i !== 0}
                    placeholder='Role'
                    isDisabled={m?.name === "Project Manager"}
                    value={m?.name ? {label: m?.name, value: m} : null}
                    onChange={item => {
                      if (item.value === '201') {
                        setAddingRole(true);
                      } else {
                        onRoleChange(item.value, i);
                      }
                    }}
                    options={options}
                  />
                  <Select label='Complexity' hideLabel={i !== 0} options={complexityOptions} value={complexityOptions[m.complexity]} onChange={e => onComplexityChange(e.value, i)}/>
                  <MemberDropdown
                    number={i}
                    setSelectedMember={onMemberChange}
                    selectedMember={m?.member}
                    hideLabel={i !== 0}
                  />
                </FieldGroup>
                <Button icon={faTimes} disabled={m?.name === "Project Manager"} onClick={() => removeRole(m)} />
              </div>
            );
          })}
          <div className='input-group-footer'>
            <Button icon={faPlus} style={{flex: '1'}} onClick={() => addRole()} >
              Add member
            </Button>
          </div>
        </div>
        <Modal isOpen={addingRole} header='Add new role' onRequestClose={() => resetRoleModal()}>
          <Input
            label='Role name'
            value={newRole?.name || ''}
            onChange={e => onChange('name', e.target.value, setNewRole)}
          />
          <div style={{display: 'flex', marginTop: 'var(--bfs24)', minWidth: '300px'}}>
            <Button style={{flex: '1', marginRight: 'var(--bfs12)'}} onClick={() => resetRoleModal()}>
              Cancel
            </Button>
            <Button style={{flex: '1'}} variant={'filled'} onClick={() => addRoleOption(newRole)}>
              Add new role
            </Button>
          </div>
        </Modal>
      </Drawer>
    </div>
  );
};

export default EditProjectTeam;
