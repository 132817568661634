import {faTasks} from '@fortawesome/pro-regular-svg-icons';
import OnboardTaskDetails from '../../components/BackOffice/OnboardTaskDetails/OnboardTaskDetails';

export default {
    title: 'Tasks',
    path: 'tasks/',
    icon: faTasks,
    editable: false,
    component: OnboardTaskDetails,
    getStatus: () => {
        return '';
    },
    getStatusText: (status, company) => {
        return 'Additional details';
    }
};
