import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  fetchDocumentation,
  fetchDocumentationError,
  updateDocumentation,
  updateDocumentationError,
  uploadDocumentationError
} from './documentationReducers';
import {fetchDocumentationSuccess, updateDocumentationSuccess} from './documentationActions';
import produce from 'immer';

export const updateDocumentationAsync = documentation => (dispatch, getState) => {
  const {updating} = getState().company.data.documentationPlatform.documentationStatus;
  if (updating) return null;
  dispatch(updateDocumentation());

  const {information, documentList} = getState().company.data.documentationPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/documentation/${documentation.id}`,
    method: 'PUT',
    body: documentation
  }).then(documentation => dispatch(updateDocumentationSuccess(documentation, documentList)))
    .catch(error => dispatch(updateDocumentationError(error)));
};

export const patchDocumentationPlatformAsync = documentationPlatform => (dispatch, getState) => {
  const {updating} = getState().company.data.documentationPlatform.documentationStatus;
  if (updating) return null;
  dispatch(updateDocumentation());

  const {id} = getState().company.data.info;

  const body = produce(documentationPlatform, draft => {
    draft.documentList = [];
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/documentation`,
    method: 'PATCH',
    body: body
  }).then(() => dispatch(fetchDocumentationAsync(id)))
    .catch(error => dispatch(updateDocumentationError(error)));
};

export const fetchDocumentationAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.documentationPlatform.documentationStatus;
  if (fetching) return null;
  dispatch(fetchDocumentation());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/documentation`,
    method: 'GET'
  }).then(documentation => dispatch(fetchDocumentationSuccess(documentation)))
    .catch(error => dispatch(fetchDocumentationError(error)));
};

export const deleteDocumentationAsync = id => (dispatch, getState) => {
  const {updating} = getState().company.data.documentationPlatform.documentationStatus;
  if (updating) return null;
  dispatch(updateDocumentation());

  const company = getState().company.data;
  const documentation = company.documentationPlatform.documentList.find(l => l.id === id);

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${company.info.id}/documentation/${documentation?.id}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchDocumentationAsync(company.info.id)))
    .catch(error => dispatch(updateDocumentationError(error)));
};

export const uploadDocumentationAsync = (id, files) => (dispatch, getState) => {
  const {updating} = getState().company.data.documentationPlatform.documentationStatus;
  if (updating) return null;
  dispatch(updateDocumentation());

  const company = getState().company.data;
  const floorPlans = new FormData();

  files.forEach(floorPlanFile => {
    floorPlans.append(id + '/' + floorPlanFile.name, floorPlanFile);
  });

  return apiFetch(`/companies/${company.info.id}/documentation/files`, {
    method: 'POST',
    body: floorPlans
    }).then(response => {
      if (response.ok) return response.json();
      return response.json().then(response => {throw response})
    }).then(() => dispatch(fetchDocumentationAsync(company.info.id)))
    .catch(error => {
      console.log(error);
      dispatch(uploadDocumentationError(error));
    });
};

export const uploadFloorPlanAsync = (id, file) => uploadDocumentationAsync(id, [file]);
