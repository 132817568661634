import React, {useEffect, useState} from 'react';
import {
  Badge,
  Button,
  Checkbox,
  FormatDate,
  Icon,
  Input,
  Table,
  Tooltip
} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import './userRow.scss';
import produce from 'immer';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import CountryOptions from '../Options/CountryOptions';
import LanguageOptions from '../Options/LanguageOptions';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {
  getIntilityUserType,
  getIntilityUserOptions,
  UserStatusTypes,
  IntilityUserType
} from '../Options/UserTypeOptions';
import UserAccountEnabledOptions from "../Options/UserAccountEnabledOptions";
import { diffDateDays } from "./DeletedUserRow";
import {
  faArrowRight,
  faBan,
  faCheck
} from '@fortawesome/pro-regular-svg-icons';
import {multiPut} from '../../../../../redux/company/users/userThunks';
import useCurrentTheme from '../../../../Navigation/useCurrentTheme';

export const getManagerName = (user) => {
  let print = ''
  if (user?.firstName !== undefined && user?.firstName !== null) print += user.firstName;
  if (user?.lastName !== undefined && user?.lastName !== null)  print += ` ${user.lastName}`;
  return print;
};

export const getUserName = (user) => {
  if (user.firstName === null && user.lastName === null) return 'Undefined user';

  const shortFirst = user.firstName === null ? '' : user.firstName
    ?.toUpperCase()
    ?.split(/[\s-]+/)
    ?.map(w => w.charAt(0))
    .join('. ');
  const shortLast = user.lastName === null ? '' : user.lastName
    ?.toUpperCase()
    ?.split(/[\s-]+/)
    ?.map(w => w.charAt(0))
    .join('. ');
  const namesum = user?.lastName?.length + user?.firstName?.length;

  if (user?.firstName?.length < 17 && user?.lastName === null) return user?.firstName;
  if (user?.firstName === null) return user?.lastName;
  if (namesum < 17) return user?.firstName + ' ' + user?.lastName;
  if (user?.lastName?.length < 16 && namesum > 18) return shortFirst + '. ' + user?.lastName;
  if (user?.firstName?.length < 16 && namesum > 18) return user?.firstName + ' ' + shortLast + '.';
  if (user?.lastName?.length > 17 || user?.firstName?.length > 17) return shortFirst + '. ' + shortLast + '.';
  if (user?.firstName?.length > user?.lastName?.length) {
    return shortFirst + '. ' + user?.lastName;
  } else {
    return user?.firstName + ' ' + shortLast + '.';
  }
}

export const checkImmutableMatch = (user, value, boolean) => {
  if (user?.immutableCopy === null || user?.immutableCopy === undefined) return true;
  if ((user[value] === null || user[value] === undefined || user[value]?.length === 0) && (user?.immutableCopy[value] === null || user?.immutableCopy[value] === undefined || user?.immutableCopy[value]?.length === 0)) return true;
  if (boolean) return user[value] === user?.immutableCopy[value];
  return user[value]?.toLowerCase()?.replace(/ /g,'') === user?.immutableCopy[value]?.toLowerCase()?.replace(/ /g,'');
};

export const getStatusBadge = (statusType) => {
  switch (statusType) {
    case UserStatusTypes.Attention:
      return <Badge pill state='alert'>Error</Badge>;
    case UserStatusTypes.Warning:
      return <Badge pill state='warning'>Attention</Badge>;
    case UserStatusTypes.Ready:
      return <Badge pill state='success'>Ready</Badge>;
    default:
      return <Badge pill state='success'>Ready</Badge>;
  }
};

export const UserRow = ({
  user,
  index,
  guideStep,
  setGuideStep,
  isEditing,
  userEditList,
  save,
  bulk,
  managers,
  isAllSelected,
  setIsAllSelected,
  setAnySelected,
  setIsEditingAll,
  anySelected,
  submited,
  setUserId,
  setNewUser,
  openLicenseModal,
  isComplete,
  userRows,
  added
}) => {
  const {locationList, locationStatus} = useSelector(state => state.company.data.locationPlatform);
  const {billingList} = useSelector(state => state.company.data.billingPlatform);
  const {userList, information} = useSelector(state => state.company.data.userPlatform);
  const [selectableBilling, setSelectableBilling] = useState([]);
  const [selectableLocation, setSelectableLocation] = useState([]);
  const [selected, setSelected] = useState(false);
  const [editable, setEditable] = useState(user);
  const [lineStatus, setLineStatus] = useState(UserStatusTypes.Ready);
  const [edited, setEdited] = useState(false);
  const validEmailCharsRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validPhoneNumberRegex = /^\+?[0-9\s\-()]{7,15}$/;
  const dispatch = useDispatch();
  const theme = useCurrentTheme();

  const onChange = e => {
    const {name, value} = e.target;
    setEdited(true);
    setEditable(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const handleDelete = (user) => {
      const disableUser = [user].map(u => {
        const item = {...u};
        item.isEnabled = false;
        return item;
      });
      dispatch(multiPut(disableUser));

  };

  const updateSelect = item => {
    if (item === null) return;
    setEdited(true);
    setEditable(
      produce(draft => {
        if (item.type === 'manager') {
          draft.managerId = item.value.id;
          draft.manager = item.value;
        } else {
          draft[item.type] = item.value;
        }
      })
    );
  };

  useEffect(() => {
    if (!locationStatus.fetching) setLineStatus(getStatus())
  }, [locationStatus.fetching, user]);

  // eslint-disable-next-line no-unused-vars
  const getStatus = () => {
    const inBilling = billingList.some(b => b.id === user.billingId);
    const inLocation = locationList.some(b => b.id === user.locationId);
    const validPrimaryEmail = validEmailCharsRegex.test(user.primaryEmailAddress);
    const validEmail = validEmailCharsRegex.test(user.mail);
    const validPhoneNumber = user.intilityUserType === IntilityUserType.IntilityIdUser && !validPhoneNumberRegex.test(user.mobilePhone);
    const emailMatch = (user?.mail?.length > 0 && user?.primaryEmailAddress?.toLowerCase()?.trim() !== user?.mail?.toLowerCase()?.trim());

    if (!inBilling || !inLocation || user?.primaryEmailAddress?.length === 0) return UserStatusTypes.Attention;
    if (emailMatch) return UserStatusTypes.Warning;
    if (validPhoneNumber) return UserStatusTypes.Warning;
    if (!validPrimaryEmail || ((user.mail !== null && user.mail !== '') && !validEmail)) return UserStatusTypes.Warning;

    return UserStatusTypes.Ready;
  };

  useEffect(() => {
    setEditable(user);
  }, [user]);

  useEffect(() => {
    if (save && (edited || (bulk && selected))) {
      const index = userEditList.findIndex(obj => obj.id === editable.id);
      if (index === -1) {
        userEditList.splice(0, 0, editable);
      }
    }
  }, [save, userEditList, editable, edited, bulk, selected]);

  useEffect(() => {
    if (anySelected === 0 || isAllSelected) {
      setSelected(isAllSelected);
    }
  }, [isAllSelected]);

  useEffect(() => {
    if (anySelected === 0 && !isAllSelected) {
      setSelected(false);
      setEdited(false);
    }
  }, [anySelected, isAllSelected]);

  useEffect(() => {
    if (submited) {
      setSelected(false);
    }
  }, [submited]);

  useEffect(() => {
    if (!selected) {
      setIsEditingAll(false);
      const index = userEditList.indexOf(editable);
      if (index > -1) {
        userEditList.splice(index, 1);
      }
    }
  }, [selected, editable, userEditList]);

  useEffect(() => {
    setSelectableBilling(
      billingList?.map(billing => ({
        label: billing.name,
        value: billing.id,
        type: 'billingId'
      }))
    );
  }, [billingList]);

  useEffect(() => {
    setSelectableLocation(
      locationList?.map(location => ({
        label: location.name,
        value: location.id,
        type: 'locationId'
      }))
    );
  }, [locationList]);

  const locationName = locationList?.find(b => b.id === user.locationId)?.name;
  const billingName = billingList?.find(b => b.id === user.billingId)?.name;
  //const lineStatus = getStatus();

  const changeSelection = checked => {
    setSelected(checked);
    if (checked) {
      setAnySelected(i => i + 1);
    } else {
      setAnySelected(i => i - 1);
    }
  };

  const lineColor = () => {
    switch (lineStatus) {
      case UserStatusTypes.Ready:
        return 'var(--bfc-base-3)';
      case UserStatusTypes.Warning:
        return theme === 'dark' ? '#292b24' : '#fffbf0';
      case UserStatusTypes.Attention:
        return theme === 'dark' ? '#262533' : '#fff4f3';
    }
  }

  return isEditing && selected ? (
    <Table.Row style={lineStatus === UserStatusTypes.Attention ? {background: 'hsla(var(--bfc-alert-fade-hsl), 0.4)'} : lineStatus === UserStatusTypes.Warning ? {background: 'hsla(var(--bfc-warning-fade-hsl), 0.3)'} : undefined} className={'user-row'}>
      <Table.Cell style={{background: lineColor()}}>
        <div className='user-row-first-cell'>
          <Checkbox
            id={`row_${user.id}`}
            label={'Check'}
            hideLabel={true}
            checked={selected}
            onChange={e => changeSelection(e.target.checked)}
          />
        </div>
      </Table.Cell>
      <Table.Cell style={{position: 'sticky', left: '67.5px', overflow: 'hidden', zIndex: 1, background: lineColor(), borderRight: '1px solid var(--bfc-base-dimmed)', minWidth: '175px'}}>
        <Input name='primaryEmailAddress' label='Username (UPN)' hideLabel={true} value={editable.primaryEmailAddress || ''} onChange={onChange} state={!validEmailCharsRegex.test(editable.primaryEmailAddress) ? 'alert' : (editable?.mail?.length > 0 && editable.primaryEmailAddress?.toLowerCase()?.trim() !== editable?.mail?.toLowerCase()?.trim()) ? 'warning' : undefined} feedback={
          !validEmailCharsRegex.test(editable.primaryEmailAddress) ? 'Email invalid, please review' : (editable?.mail?.length > 0 && editable.primaryEmailAddress?.toLowerCase()?.trim() !== editable?.mail?.toLowerCase()?.trim()) ?
            'Mismatch between UPN and e-mail' : undefined
        }/>
      </Table.Cell>
      <Table.Cell>
        {getStatusBadge(lineStatus)}
      </Table.Cell>
      {userRows.map((r, i) => {
        if (r.value === 'createdAt' || r.value === 'modifiedAt' || r.value === 'licenses') {
          if (isAllSelected) return;
          return <Table.Cell key={i}/>
        } else if (r.value === 'accountEnabled') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}}>
              <Select name={r.value}
                      label={r.value}
                      hideLabel={true}
                      placeholder={editable[r.value] === false ? 'Disabled' : 'Enabled'}
                      options={UserAccountEnabledOptions}
                      onChange={item => updateSelect(item)} />
            </Table.Cell>
          )
        } else if (r.value === 'intilityUserType') {
          return (
            <Table.Cell key={i} style={{minWidth: '200px'}}>
              <Select
                maxMenuHeight={200}
                label={r.label}
                isClearable={true}
                className='users-box-select'
                hideLabel={true}
                placeholder={getIntilityUserType(user[r.value])}
                options={getIntilityUserOptions(information.userTypes)}
                onChange={item => updateSelect(item)}
              />
            </Table.Cell>
          )
        } else if (r.value === 'locationId') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}}>
              <Select
                maxMenuHeight={200}
                label={'New value'}
                isClearable={true}
                className='users-box-select'
                hideLabel={true}
                placeholder={!locationName ? 'Missing value' : locationName}
                options={selectableLocation}
                state={!locationName ? 'alert' : undefined}
                onChange={item => updateSelect(item)}
              />
            </Table.Cell>
          )
        } else if (r.value === 'billingId') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}}>
              <Select
                maxMenuHeight={200}
                label={'New value'}
                isClearable={true}
                className='users-box-select'
                hideLabel={true}
                placeholder={!billingName ? 'Missing value' : billingName}
                options={selectableBilling}
                state={!billingName ? 'alert' : undefined}
                onChange={item => updateSelect(item)}
              />
            </Table.Cell>
          )
        } else if (r.value === 'managerId') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}}>
              <Select
                maxMenuHeight={200}
                label={r.label}
                isClearable={true}
                className='users-box-select'
                hideLabel={true}
                options={managers}
                placeholder={user?.manager === null ? undefined : `${user?.manager}`}
                onChange={item => updateSelect(item)}
              />
            </Table.Cell>
          )
        } else if (r.value === 'language') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}}>
              <Select
                maxMenuHeight={200}
                label={r.label}
                isClearable={true}
                className='users-box-select'
                hideLabel={true}
                placeholder={user[r.value]}
                options={LanguageOptions}
                onChange={item => updateSelect(item)}
              />
            </Table.Cell>
          )
        } else if (r.value === 'country') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}}>
              <Select
                maxMenuHeight={200}
                label={r.label}
                isClearable={true}
                className='users-box-select'
                hideLabel={true}
                placeholder={user[r.value]}
                options={CountryOptions}
                onChange={item => updateSelect(item)}
              />
            </Table.Cell>
          )
        } else if (r.value === 'mail') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}}>
              <Input name={r.value} label={r.label} style={{minWidth: '155px'}} hideLabel={true} value={editable[r.value] || ''} onChange={onChange} state={(user[r.value] !== null && user[r.value] !== '') && !validEmailCharsRegex.test(editable[r.value]) ? 'alert' : undefined}
                     feedback={(user[r.value] !== null && user[r.value] !== '') && !validEmailCharsRegex.test(editable[r.value]) ? 'Email invalid, please review' : undefined
              }/>
            </Table.Cell>
          )
        } else if (r.value === 'mobilePhone') {
          return (
            <Table.Cell key={i} style={{minWidth: '175px'}} >
              <Input name={r.value} label={r.label} hideLabel={true} value={editable[r.value] || ''} onChange={onChange} state={((editable?.mobilePhone?.length === 0 || editable?.mobilePhone === null) && editable.intilityUserType === IntilityUserType.IntilityIdUser) ? 'alert' : undefined}
                     feedback={((editable?.mobilePhone?.length === 0 || editable?.mobilePhone === null) && editable.intilityUserType === IntilityUserType.IntilityIdUser) ? 'Missing mobile'
                       : ((editable?.mobilePhone?.length !== 0 && editable?.mobilePhone !== null) && !validPhoneNumberRegex.test(editable[r.value])) ? 'Phone number invalid, please review' : undefined
              }/>
            </Table.Cell>
          )
        }
        return (
          <Table.Cell key={i} style={{minWidth: '175px'}}>
            <Input name={r.value} label={r.label} hideLabel={true} value={editable[r.value] || ''} onChange={onChange} />
          </Table.Cell>
        )
      })}
    </Table.Row>
  ) : (
    <Table.Row style={lineStatus === UserStatusTypes.Attention ? {background: 'hsla(var(--bfc-alert-fade-hsl), 0.4)'} : lineStatus === UserStatusTypes.Warning ? {background: 'hsla(var(--bfc-warning-fade-hsl), 0.3)'} : undefined} className={'user-row'}>
      <Table.Cell style={{background: lineColor()}}>
        <div className='user-row-first-cell'>
          <Checkbox
            id={`row_${user.id}`}
            label='Check'
            hideLabel={true}
            checked={selected}
            onChange={e => changeSelection(e.target.checked)}
          />
        </div>
      </Table.Cell>
      <Table.Cell style={{position: 'sticky', left: '67.5px', overflow: 'hidden', zIndex: 1, background: lineColor(), borderRight: '1px solid var(--bfc-base-dimmed)'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <span style={{display: 'flex', flexDirection: 'column', marginRight: 'var(--bfs4)'}}>
            <span>
              {user.primaryEmailAddress}
              {!validEmailCharsRegex.test(user.primaryEmailAddress) ?
                <Tooltip content='Email invalid, please review' placement='right'><Icon style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
                :
                (user?.mail?.length > 0 && user.primaryEmailAddress?.toLowerCase()?.trim() !== user?.mail?.toLowerCase()?.trim()) &&
                <Tooltip content='Mismatch between UPN and e-mail' placement='right'><Icon style={{color: 'var(--bfc-warning)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
              }
            </span>
            {!checkImmutableMatch(user, 'primaryEmailAddress') &&
              <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy?.primaryEmailAddress}</span>
            }
          </span>
            <Button small variant='flat' onClick={() => {
              setUserId(user.id);
              setNewUser(true);
            }} icon={faArrowRight}/>
        </div>
      </Table.Cell>
      <Table.Cell>
        {getStatusBadge(lineStatus)}
      </Table.Cell>
      {userRows.map((r, i) => {
          if (r.value === 'createdAt' || r.value === 'modifiedAt') {
            return (
              <Table.Cell key={i}>
                {
                  diffDateDays(user[r.value]) === 0 ?
                    <Badge>Today</Badge>
                    : user[r.value] ?
                    <FormatDate date={new Date(user[r.value])} /> :
                      <Badge>Never</Badge>
                }
              </Table.Cell>
            )
          } else if (r.value === 'lastName') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  <span>
                    {user[r.value]}
                  </span>
                  {!checkImmutableMatch(user, r.value) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy[r.value]}</span>
                  }
                </span>
              </Table.Cell>
            )
          } else if (r.value === 'accountEnabled') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  {editable[r.value] === false ?
                    <span><Icon icon={faBan} /> Disabled user</span> :
                    <span><Icon icon={faCheck} style={{color: 'var(--bfc-base-c-success)'}} /> Enabled user</span>}
                  <span>
                  </span>
                  {!checkImmutableMatch(user, r.value, true) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>
                      Entra ID: {editable?.immutableCopy[r.value] === false ? <span style={{color: 'var(--bfc-alert)'}}>Disabled</span> : <span style={{color: 'var(--bfc-success)'}}>Enabled</span>}
                    </span>
                  }
                </span>
              </Table.Cell>
            )
          } else if (r.value === 'mail') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  <span>
                    {user[r.value]}
                    {(user[r.value] !== null && user[r.value] !== '') && !validEmailCharsRegex.test(user[r.value]) &&
                      <Tooltip content='Email invalid, please review' placement='right'><Icon style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
                    }
                  </span>
                  {!checkImmutableMatch(user, r.value) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy[r.value]}</span>
                  }
                </span>
              </Table.Cell>
            )
          } else if (r.value === 'mobilePhone') {
            return (
              <Table.Cell key={i}>
                  {(user[r.value]) ?
                    <span style={{display: 'flex', flexDirection: 'column'}}>
                      <span>
                        {user[r.value]}
                        {user.intilityUserType === IntilityUserType.IntilityIdUser && !validPhoneNumberRegex.test(user[r.value]) &&
                          <Tooltip content='Phone number invalid, please review' placement='right'><Icon style={{color: 'var(--bfc-alert)', marginLeft: 'var(--bfs4)'}} icon={faExclamationTriangle}/></Tooltip>
                        }
                      </span>
                      {!checkImmutableMatch(user, r.value) &&
                        <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {user?.immutableCopy[r.value]}</span>
                      }
                    </span>
                    : user.intilityUserType === IntilityUserType.IntilityIdUser &&
                    <Badge pill state='alert'><Icon style={{color: 'var(--bfc-base-c-alert)'}} icon={faExclamationTriangle}/> Missing</Badge>
                  }
              </Table.Cell>
            )
          } else if (r.value === 'intilityUserType') {
            return (
              <Table.Cell key={i}>{getIntilityUserType(user.intilityUserType)}</Table.Cell>
            )
          } else if (r.value === 'licenses') {
            return (
              <Table.Cell key={i}>
                {(user.immutableCopy === null || user.immutableCopy === undefined) ?
                  <Tooltip content='Only available on Imported Entra ID Users'>
                    <Button state={"inactive"}>View licenses ({user[r.value]?.length})</Button>
                  </Tooltip>
                  :
                  <Button disabled={user[r.value]?.length === 0} onClick={() => openLicenseModal(user)}>View licenses ({user[r.value]?.length})</Button>
                }
              </Table.Cell>
            )
          } else if (r.value === 'locationId' || r.value === 'billingId') {
            const value = r.value === 'locationId' ? locationName : billingName;
            return (
              <Table.Cell key={i}>
                {value || <Badge pill state='alert'><Icon style={{color: 'var(--bfc-base-c-alert)'}} icon={faExclamationTriangle}/> Missing</Badge>}
              </Table.Cell>
            )
          } else if (r.value === 'managerId') {
            return (
              <Table.Cell key={i}>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                  <span>{user.manager}</span>
                  {!checkImmutableMatch(user, r.value) &&
                    <span className='bf-p bf-em' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>Entra ID: {getManagerName(userList.find(u => u.id === user?.immutableCopy[r.value]))}</span>
                  }
                </span>
              </Table.Cell>
            )
          }
          return (
            <Table.Cell key={i}>
              {user[r.value]}
            </Table.Cell>
          )
        })
      }
    </Table.Row>
  );
};

UserRow.propTypes = {
  user: PropTypes.object,
  index: PropTypes.number,
  guideStep: PropTypes.number,
  setGuideStep: PropTypes.func,
  setCurrentUserId: PropTypes.func,
  isEditing: PropTypes.bool,
  bulk: PropTypes.bool,
  managers: PropTypes.array,
  isAllSelected: PropTypes.bool,
  setIsAllSelected: PropTypes.func,
  setIsEditingAll: PropTypes.func,
  userEditList: PropTypes.array,
  save: PropTypes.bool,
  setAnySelected: PropTypes.func,
  anySelected: PropTypes.number,
  submited: PropTypes.bool,
  setUserId: PropTypes.func,
  setNewUser: PropTypes.func,
  isComplete: PropTypes.bool,
  userRows: PropTypes.array,
  added: PropTypes.bool
};

export default UserRow;
