import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {fetchBilling, fetchBillingError, updateBilling, updateBillingError} from './billingReducers';
import {fetchBillingSuccess} from './billingActions';
import produce from 'immer';

export const fetchBillingAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.billingPlatform.billingStatus;
  if (fetching) return null;
  dispatch(fetchBilling());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/billing`,
    method: 'GET'
  }).then(billing => dispatch(fetchBillingSuccess(billing)))
    .catch(error => dispatch(fetchBillingError(error)));
};

export const addBillingAsync = billing => (dispatch, getState) => {
  const {updating} = getState().company.data.billingPlatform.billingStatus;

  if (updating) return null;
  dispatch(updateBilling());

  const {id} = getState().company.data.info;
  const {information} = getState().company.data.billingPlatform;

  const body = produce(billing, draft => {
    draft.billingPlatformId = information.id
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/billing`,
    method: 'POST',
    body: body
  })
    .then(() => dispatch(fetchBillingAsync(id)))
    .catch(error => dispatch(updateBillingError(error)));
};

export const updateBillingAsync = state => (dispatch, getState) => {
  const {updating} = getState().company.data.billingPlatform.billingStatus;

  if (updating) return null;
  dispatch(updateBilling());

  const {information} = getState().company.data.billingPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/billing/${state.id}`,
    method: 'PUT',
    body: state
  }).then(() => dispatch(fetchBillingAsync(information.companyId)))
    .catch(error => dispatch(updateBillingError(error)));
};

export const deleteBillingAsync = id => (dispatch, getState) => {
  const {updating} = getState().company.data.billingPlatform.billingStatus;

  if (updating) return null;
  dispatch(updateBilling());

  const information = getState().company.data.billingPlatform.information;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/billing/${id}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchBillingAsync(information.companyId)))
    .catch(error => dispatch(updateBillingError(error)));
};
