import React from 'react';
import BasicDetails from './BasicDetails';
import Contacts from './Contacts';
import OptionalFields from './OptionalFields';
import {Breadcrumbs, Button, Grid, useFloatingMessage} from '@intility/bifrost-react';
import {useParams, Link} from 'react-router-dom';
import './addApplicationPage.scss';
import {CompleteApplication} from '../../../../../utils/hub/models';
import {addCompleteApplicationAsync} from '../../../../../redux/company/applications/applicationThunks';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

function AddApplicationPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {showFloatingMessage} = useFloatingMessage();
  const {companyId} = useParams();
  const [appObject, setAppObject] = useState(new CompleteApplication());
  const [submitted, setSubmitted] = useState(false);

  const createApplication = async () => {
    setSubmitted(true);
    if (!appObject.name) {
      showFloatingMessage('Application name is required', {
        state: 'alert',
        noIcon: false
      });
      return;
    }

    const response = await dispatch(addCompleteApplicationAsync(appObject));
    if (!response || !response.id) {
      console.log('Failed to add application');
      showFloatingMessage('Failed to add application', {
        state: 'alert',
        noIcon: false
      });
    } else {
      history.push(`/${companyId}/applications`);
    }
  };

  return (
    <>
      <div className='bfc-base-2-bg bf-page-padding'>
        <div className='add-application-page'>
          <Breadcrumbs>
            <Breadcrumbs.Item>
              <Link to='/home'>Home</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>
              <Link to={`/${companyId}/applications`}>Applications</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>Add Application</Breadcrumbs.Item>
          </Breadcrumbs>
          <h1>Add Application</h1>
        </div>
      </div>
      <div className='bf-page-padding'>
        <div className='add-application-page add-application-page-content'>
          <BasicDetails appObject={appObject} setAppObject={setAppObject} submitted={submitted} />
          <Contacts appObject={appObject} setAppObject={setAppObject} />
          <OptionalFields appObject={appObject} setAppObject={setAppObject} />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button style={{width: 'fit-content'}} pill onClick={() => history.push(`/${companyId}/applications`)}>
              Cancel
            </Button>
            <Button style={{width: 'fit-content'}} variant='filled' pill onClick={createApplication}>
              Create Application
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddApplicationPage;
