import {faFilePdf} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 5,
  text: 'All extra documentation provided',
  icon: faFilePdf,
  isEnabled: data => {
    return data.documentationPlatform?.information?.isEnabled;
  },
  isEnabledOverview: data => {
    return data.documentationPlatform?.isEnabled;
  },
  getStatusText: data => {
    return data.computerSetupPlatform?.information?.modifiedAt;
  }
};
