import EditMicrosoft365 from '../../components/BackOffice/EditComponents/EditMicrosoft365';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Microsoft365',
  path: 'microsoft365/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditMicrosoft365
};
