import {
  addMemberRoleSuccess,
  addProjectTeamSuccess,
  deleteProjectTeamSuccess,
  fetchMemberRolesSuccess,
  fetchProjectTeamsSuccess,
  fetchResourceTeamsSuccess,
  updateProjectTeamSuccess
} from './projectTeamsActions';
import {createReducer, createSlice} from '@reduxjs/toolkit';

const updateOnProjectTeamChangeReducer = getState => {
  return {
    [fetchProjectTeamsSuccess]: (state, action) => getState(action.payload, state),
    [addProjectTeamSuccess]: (state, action) => getState(action.payload, state),
    [deleteProjectTeamSuccess]: (state, action) => getState(action.payload, state),
    [updateProjectTeamSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const projectTeamsReducer = createReducer(
  [],
  updateOnProjectTeamChangeReducer((payload, state) => {
    state = payload.projectTeams.list;
    return state;
  })
);

const updateOnMemberRolesChangeReducer = getState => {
  return {
    [fetchMemberRolesSuccess]: (state, action) => getState(action.payload, state),
    [addMemberRoleSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const memberRolesReducer = createReducer(
  [],
  updateOnMemberRolesChangeReducer((payload, state) => {
    state = payload.projectTeams.roles;
    return state;
  })
);

const updateOnResourceTeamsChangeReducer = getState => {
  return {
    [fetchResourceTeamsSuccess]: (state, action) => getState(action.payload, state),
  };
};

export const resourceTeamsReducer = createReducer(
    [],
    updateOnResourceTeamsChangeReducer((payload, state) => {
      state = payload.resourceTeams;
      return state;
    })
);

export const projectTeamsStatusSlice = createSlice({
  name: 'projectTeamsAction',
  initialState: {
    fetching: false,
    fetchingResource: false,
    updating: false,
    error: false,
    fetchingRoles: false,
    updatingRoles: false,
    resourceError: false,
    rolesError: false
  },
  reducers: {
    fetchProjectTeams: state => {
      state.fetching = true;
    },
    fetchMemberRoles: state => {
      state.fetchingRoles = true;
    },
    fetchResourceTeams: state => {
      state.fetchingResource = true;
      state.updating = false;
    },
    fetchProjectTeamsError: state => {
      state.fetching = false;
      state.error = true;
    },
    fetchMemberRolesError: state => {
      state.fetchingRoles = false;
      state.rolesError = true;
    },
    fetchResourceTeamsError: state => {
      state.fetchingResource = false;
      state.resourceError = true;
    },
    updateProjectTeams: state => {
      state.updating = true;
    },
    updateMemberRole: state => {
      state.updatingRoles = true;
    },
    updateProjectTeamsError: state => {
      state.updating = false;
      state.error = true;
    },
    updateMemberRoleError: state => {
      state.updatingRoles = false;
      state.rolesError = true;
    },
    updateProjectTeamsClear: state => {
      state.updating = false;
      state.fetching = false;
      state.error = false;
    },
    updateMemberRolesClear: state => {
      state.fetchingRoles = false;
      state.updatingRoles = false;
      state.rolesError = false;
    }
  },
  extraReducers: {
    [fetchProjectTeamsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateProjectTeamSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchMemberRolesSuccess]: state => {
      state.fetchingRoles = false;
      state.updatingRoles = false;
      state.rolesError = false;
    },
    [fetchResourceTeamsSuccess]: state => {
      state.fetchingResource = false;
      state.resourceError = false;
    },
    [addMemberRoleSuccess]: state => {
      state.updatingRoles = false;
      state.rolesError = false;
    },
    [addProjectTeamSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [deleteProjectTeamSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchProjectTeams,
  fetchMemberRoles,
  fetchResourceTeams,
  fetchResourceTeamsError,
  updateProjectTeamsClear,
  updateMemberRolesClear,
  fetchProjectTeamsError,
  fetchMemberRolesError,
  updateProjectTeams,
  updateMemberRole,
  updateProjectTeamsError,
  updateMemberRoleError
} = projectTeamsStatusSlice.actions;
