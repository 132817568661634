import React, {useEffect, useState} from 'react';
import './hub.scss';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useParams, Redirect, useHistory} from 'react-router-dom';
import pageData from 'utils/hub/pageData';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import {fetchCompanyAsync} from 'redux/company/companyThunks';
import {FloatingMessage} from '@intility/bifrost-react';
import PageState from '../../utils/hub/pageState';
import AddApplicationPage from './Pages/SystemsAndServices.Applications/AddApplication/AddApplicationPage';

const pagesInSideNav = pageData;

const Hub = () => {
  const {user} = useSelector(state => state.auth);
  const {data} = useSelector(state => state.company);
  const {fetching: updateLoading} = useSelector(state => state.company.update);
  const {error} = useSelector(state => state.company.fetch);
  const dispatch = useDispatch();
  const {companyId} = useParams();
  const [route, setRoute] = useState({
    //--> it can be replaced with useRef or localStorage
    to: location.pathname,
    from: location.pathname
  });

  useEffect(() => {
    setRoute(prev => ({to: location.pathname, from: prev.to}));
  }, [location]);

  // fetch company
  useEffect(() => {
    if (!user || !companyId) return;

    dispatch(fetchCompanyAsync(companyId));
  }, [user, dispatch, companyId]);

  if (error) return <Redirect to='/' />;

  return (
    <>
      {/* render all the pages' routes */}
      <FloatingMessage>
        <Route exact path='/:companyId/addApplication' component={AddApplicationPage} />
        {data &&
          pagesInSideNav
            .filter(p => p?.isEnabled(data) === PageState.ENABLED)
            .map(page => (
              <Route key={page.title} exact path={`/${companyId}/${page.path}`} component={page.component} />
            ))}
      </FloatingMessage>
      <GlobalLoader loading={updateLoading} />
    </>
  );
};

export default Hub;
