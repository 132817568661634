import OnboardTaskComponent from "./OnboardTaskComponent";
import React, { useEffect, useState } from "react";
import './taskContainer.scss';
import { useSelector } from "react-redux";
import { Button } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import useIntilityPermission from "../../../../../hooks/useIntilityPermission";
import apiFetch from "../../../../../utils/apiFetch";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import TaskModal from "../TaskModal/TaskModal";
import { pageDataOptions, projectAreaOptions } from "../../../../../utils/hub/pageData/pageDataOptions";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { responsibleType } from "../../../../BackOffice/EditComponents/EditOnboardTasks";

const TaskContainer = ({selectedTask, setSelectedTask, setTaskHighight, setIsOpen}) => {
  const { id } = useSelector(state => state.company.data.info);
  const {taskList} = useSelector(state => state.company.data.taskPlatform);
  const [taskUsers, setTaskUsers] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editTask, setEditTask] = useState(undefined);
  const {user} = useSelector(state => state.auth);
  const [sort, setSort] = useState(1);
  const intilityUser = useIntilityPermission();
  const location = useLocation();
  const history = useHistory();

  const pageTasks = taskList && taskList?.filter(s => s.taskType === responsibleType.CUSTOMER)?.filter(t => t.isEnabled === true);

  useEffect(() => {
    if (intilityUser && id) {
      fetchIntilityMembers();
    }
  }, [intilityUser, location, history, id]);


  const fetchIntilityMembers = () => {
    if (intilityUser) {
      apiFetch(`/users/${id}`)
        .then(response => {
          if (!response.ok) {
            setTaskUsers([]);
            return [];
          }
          return response.json()
        })
        .then(users => setTaskUsers(users))
    }
  };

  const filteredTaskList = pageTasks && pageTasks.filter(t => {
    let test = true;
    filterList.forEach(f => {
      if (f.value === 'due') {
        if (t.dueDate === null || t.dueDate === undefined) return test = false;
        const dueDate = new Date(t.dueDate);
        const currentDate = new Date(Date.now());
        if (dueDate?.getFullYear() === 1) return test = false;
        const diffTime = Math.abs(dueDate - currentDate);
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        test = diffDays <= 3 || currentDate > dueDate;
      }
    });
    return test;
  }).filter(t => {
    if (filterList.find(f => f.value === 'me')) {
      return t.userId === user.id;
    } else return t;
  }).filter(t => {
    let test = true;
    filterList.forEach(f => {
      if (f?.type === 'section' && test) {
        test = f.value === pageDataOptions[t.section].value;
      }
      if (f?.type === 'projectArea'&& test) {
        test = f.value === t?.projectArea
      }
    });
    return test;
  }).filter(t => {
    if (sort === 1) return !t.complete;
    return t.complete;
  })?.sort((t1, t2) => {
    const dateA = new Date(t1.dueDate);
    const dateB = new Date(t2.dueDate);
    if (dateA.getFullYear() === 1) return 1;
    if (dateB.getFullYear() === 1) return -1;
    return dateA.getTime() - dateB.getTime();
  }).sort((t1, t2) => {
    const dateA = new Date(t1.dueDate);
    const dateB = new Date(t2.dueDate);
    if (t1.complete) return 1;
    if (t2.complete) return -1;
    if (dateA.getFullYear() !== 1) return 0;
    if (dateB.getFullYear() !== 1) return 0;
  });

  const editCurrentTask = (task) => {
    setEditTask(task);
    setModalOpen(true);
  };


  const testOptions = [
    {
      label: 'Personal',
      options: [{value: 'me', label: 'Assigned to me', type: 'personal'}]
    },
    {
      label: 'Project Area',
      options: projectAreaOptions
    },
    {
      label: 'Page',
      options: pageDataOptions
    }
  ]

  return(
    <div id='task-container' className='bf-scrollbar-small'>
      <div style={{flex: '1'}} className='content-box'>
        <div className='content-box-header'>
          <div className='content-box-header-topbar'>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Select className='header-select' style={{minWidth: '250px'}} isMulti value={filterList} label='area filter' placeholder='Add Filter' options={testOptions} hideLabel onChange={f => setFilterList(f)} />
            </div>
            <div className='header-buttonbar'>
              <Button.Group>
                <Button active={sort === 1} onClick={() => setSort(1)}>To do ({pageTasks?.filter(t => t.complete === false)?.length})</Button>
                <Button active={sort === 2} onClick={() => setSort(2)}>Complete ({pageTasks?.filter(t => t.complete === true)?.length})</Button>
              </Button.Group>
              {intilityUser &&
                <Button icon={faCog} onClick={() => {
                  localStorage.setItem(`backUrl${id}`, location.pathname);
                  history.push(`/details/${id}/tasks/edit`)
                }}>
                </Button>
              }
            </div>
          </div>
        </div>
        <div className='task-container-content'>
          <div className='task-container-tasks'>
            <div className='task-list bf-scrollbar-small'>
              {filteredTaskList.length > 0 ? filteredTaskList.map((task, key) => {
                return (
                  <OnboardTaskComponent
                    key={key}
                    task={task}
                    taskUser={task?.user}
                    setSelectedTask={setSelectedTask}
                    setEditTask={editCurrentTask}
                    setTaskHighight={setTaskHighight}
                    setIsOpen={setIsOpen}
                  />
                );
              }) : pageTasks?.length === 0 ?
                <div>
                  <p>No tasks added yet, check back soon, or explore Onboard while waiting</p>
                </div>
                :
                <div>
                  <p>No tasks matching filter</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <TaskModal task={editTask} setTask={setEditTask} isOpen={modalOpen} setIsOpen={setModalOpen}/>
    </div>
  )

}

TaskContainer.propTypes = {
  level: PropTypes.number,
  setLevel: PropTypes.func,
  setTaskHighight: PropTypes.func,
  maxLevel: PropTypes.number,
  selectedTask: PropTypes.object,
  setSelectedTask: PropTypes.func
}

export default TaskContainer