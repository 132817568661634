import {useDispatch, useSelector} from 'react-redux';
import {NavLink, Redirect, Route, useHistory, useLocation, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
  deactivateCompanyAsync,
  fetchCompanyAsync,
  updateLockoutTimer,
  updateOnboardedDate
} from '../../redux/company/companyThunks';
import GlobalLoader from '../GlobalLoader/GlobalLoader';
import pageData from 'utils/backOffice/index';
import useIntilityPermission from '../../hooks/useIntilityPermission';
import { Button, Checkbox, Dropdown, Icon, Spinner, Tabs } from "@intility/bifrost-react";
import {
  faAngleLeft,
  faArrowRight,
  faBuilding,
  faPencil,
  faUser,
  faEllipsisH,
  faTimes
} from "@fortawesome/pro-regular-svg-icons";
import './backoffice.scss';
import {LabelBox} from './Components/LabelBox';
import {faPencilAlt} from '@fortawesome/pro-light-svg-icons';
import {fetchDetailsAsync} from '../../redux/company/details/detailThunks';
import { getTime } from "../CompanyOverview/CompanyOverview";
import RenameCompanyModal from "./RenameCompanyModal";

const pages = pageData;

const BackOffice = () => {
  const {user} = useSelector(state => state.auth);
  const {information} = useSelector(state => state.company.data.locationPlatform);
  const userInformation = useSelector(state => state.company.data.userPlatform.information);
  const {information: detailsInformation, detailStatus} = useSelector(state => state.company.data.detailsPlatform);
  const {info} = useSelector(state => state.company.data);
  const {fetching: updateLoading} = useSelector(state => state.company.update);
  const {error} = useSelector(state => state.company.fetch);
  const [isEditing, setIsEditing] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const intilityUser = useIntilityPermission();
  const {companyId} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname.endsWith('/edit')) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [location, companyId]);

  // fetch company
  useEffect(() => {
    if (!user || !companyId) return;
    dispatch(fetchCompanyAsync(companyId));
  }, [user, dispatch, companyId]);

  useEffect(() => {
    dispatch(fetchDetailsAsync(companyId));
  }, [intilityUser]);

  if (error || detailStatus?.error) return <Redirect to='/' />;

  const daysToOnboarding = date => {
    const now = new Date(Date.now());
    const onboardedDate = new Date(date);
    if (onboardedDate.getFullYear() === 1) return null;

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(onboardedDate.getFullYear(), onboardedDate.getMonth(), onboardedDate.getDate());
    const utc2 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());

    return Math.floor((utc1 - utc2) / _MS_PER_DAY);
  };

  const compareTime = onboard => {
    const onboardTime = new Date(onboard);
    const comparator = new Date(Date.now());

    if (onboardTime.getFullYear() === 1) return true;
    return onboardTime.getTime() > comparator.getTime();
  };

  const lock = () => {
    return new Date(Date.now());
  };

  const unLock = () => {
    const newDate = new Date(Date.now());
    newDate.setMonth(newDate.getMonth() + 3);
    return newDate;
  };

  const lockUnLockCompany = () => {
    return dispatch(updateOnboardedDate(info.id, compareTime(info.onboardedDate) ? lock() : unLock()));
  };

  const deactivateCompany = () => {
    const result = confirm('Are you sure you wish to deactivate company?');
    if (result) {
      dispatch(deactivateCompanyAsync(info.id));
      return <Redirect to='/' />;
    }
  };

  return intilityUser ? (
    <>
      <div id='backoffice'>
        {!isEditing ? (
          <div className='backoffice-top-bar'>
            <div className='top-bar-header'>
              <span className='bf-link bf-p' onClick={() => history.push('/')}>
                <Icon icon={faAngleLeft} /> Back
              </span>
              <Button small={true} icon={faArrowRight} rightIcon={true} onClick={() => {
                history.push(`/${companyId}`)
                window.localStorage.removeItem(`backUrl${companyId}`);
              }}>
                Go to company in Onboard
              </Button>
            </div>
            <div className='top-bar-content bfl-grid'>
              <div className='left-container'>
                <div className='header'>
                  <h2>
                    {info.name} ({detailsInformation?.companyCode || 'N/A'})
                  </h2>
                  <Button variant={'filled'} icon={faPencil} onClick={() => history.push(`edit`)}>
                    Edit company
                  </Button>
                </div>
              </div>
              <div className='right-container'>
                <div className='bfl-autocol'>
                  <LabelBox
                    left={
                      <span>
                        Go live
                        <br />
                        {getTime(detailsInformation?.goLiveDate, 'Not set')}
                      </span>
                    }
                    right={
                      daysToOnboarding(detailsInformation?.goLiveDate) >= 0 ? (
                        <span>
                          In<h3>&nbsp;{daysToOnboarding(detailsInformation?.goLiveDate)}&nbsp;</h3>
                          days
                        </span>
                      ) : (
                        <span>Live</span>
                      )
                    }
                  />
                  <LabelBox
                    left={
                      <span>
                        <Icon icon={faBuilding} />
                        &nbsp;Locations
                      </span>
                    }
                    right={
                      <span>
                        <h3>{information.locationCount}</h3>
                      </span>
                    }
                  />
                  <LabelBox
                    left={
                      <span>
                        <Icon icon={faUser} />
                        &nbsp;Users
                      </span>
                    }
                    right={
                      <span>
                        <h3>{userInformation.userCount}</h3>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='backoffice-top-bar'>
            <div className='left-container'>
              <div
                className='bf-link bf-p'
                style={{marginBottom: 'var(--bfs24)', marginTop: '0'}}
                onClick={() => history.push(`/details/${companyId}/information/`)}
              >
                <Icon icon={faAngleLeft} /> Back to company details
              </div>
              <div className='bfl-autocol container-box'>
                <Icon icon={faPencilAlt} />
                <h2>Edit </h2>
                <h2 className='bf-em'>
                  {info.name} ({detailsInformation?.companyCode || 'N/A'})
                </h2>
                <Dropdown
                  visible={dropdownOpen}
                  onClickOutside={() => setDropdownOpen(false)}
                  content={
                    <div className='container-dropdown-content'>
                      <Checkbox
                        disabled={!compareTime(info.lockOutTimer)}
                        label={'Lock admin from adding new companies'}
                        type={'switch'}
                        checked={compareTime(info.lockOutTimer)}
                        onChange={() => dispatch(updateLockoutTimer(info.id))}
                      />
                      <Checkbox
                        label={'Lock company for edit'}
                        type={'switch'}
                        checked={!compareTime(info.onboardedDate)}
                        onChange={() => lockUnLockCompany()}
                      />
                      <RenameCompanyModal/>
                      <p style={{margin: '0', padding: '7px 0'}} onClick={() => deactivateCompany()}>
                        Deactivate company
                      </p>
                    </div>
                  }
                >
                  <Icon onClick={() => setDropdownOpen(!dropdownOpen)} style={{cursor: 'pointer'}} icon={faEllipsisH} />
                </Dropdown>
                <Button variant={'outline'} icon={faTimes} onClick={() => history.replace(location.pathname.split('edit')[0])}>
                  Cancel edit
                </Button>
              </div>
            </div>
          </div>
        )}
        <Tabs variant={'styled'}>
          {pages
            .filter(p => p.editable === isEditing)
            .map((page, i) => (
              <NavLink key={i} to={`/details/${companyId}/${page.path}`}>
                {page.title}
              </NavLink>
            ))}
        </Tabs>
        <div className='backoffice-content'>
          {pages.map((page, i) => (
            <Route key={i} exact path={`/details/${companyId}/${page.path}`} component={page.component} />
          ))}
          <GlobalLoader loading={updateLoading} />
        </div>
      </div>
    </>
  ) : (
    <Spinner />
  );
};

export default BackOffice;
