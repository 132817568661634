import {faBuilding} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 30,
  text: 'All locations added',
  icon: faBuilding,
  isEnabled: data => {
    return data.locationPlatform?.information?.isEnabled;
  },
  isEnabledOverview: data => {
    return data.locationPlatform?.isEnabled;
  },
  getStatusText: data => {
    return data.locationPlatform?.information?.modifiedAt;
  }
};
