import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  fetchGlobalIntilityServices,
  fetchGlobalIntilityServicesError,
  fetchIntilityServices,
  fetchIntilityServicesError,
  updateGlobalIntilityServices,
  updateGlobalIntilityServicesError,
  updateIntilityServices,
  updateIntilityServicesError
} from './intilityServiceReducers';
import {fetchGlobalIntilityServicesSuccess, fetchIntilityServicesSuccess} from './intilityServiceActions';
import produce from 'immer';
import {emptyGuid} from '../../../utils/guid';

export const fetchIntilityServicesAsync = (companyId) => (dispatch, getState) => {
  const {fetching} = getState().company.data.intilityServicePlatform.intilityServiceStatus;
  if (fetching) return null;
  dispatch(fetchIntilityServices());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/intilityServices`,
    method: 'GET'
  }).then(intilityServices => dispatch(fetchIntilityServicesSuccess(intilityServices)))
    .catch(error => dispatch(fetchIntilityServicesError(error)));
};
export const fetchGlobalIntilityServicesAsync = () => (dispatch, getState) => {
  const {fetchingGlobal} = getState().company.data.intilityServicePlatform.intilityServiceStatus;
  if (fetchingGlobal) return null;
  dispatch(fetchGlobalIntilityServices());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/intilityServices/global`,
    method: 'GET'
  }).then(intilityServices => dispatch(fetchGlobalIntilityServicesSuccess(intilityServices)))
    .catch(error => dispatch(fetchGlobalIntilityServicesError(error)));
};

export const addIntilityServiceAsync = intilityService => (dispatch, getState) => {
  const {intilityServiceStatus, information} = getState().company.data.intilityServicePlatform;
  if (intilityServiceStatus.updating) return null;
  dispatch(updateIntilityServices());

  const {id} = getState().company.data.info;

  const body = produce(intilityService, draft => {
    draft.intilityServicePlatformId = information.id
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/intilityServices`,
    method: 'POST',
    body: body
  })
    .then(() => dispatch(fetchIntilityServicesAsync(id)))
    .catch(error => dispatch(updateIntilityServicesError(error)));
};

export const addGlobalIntilityServiceAsync = globalIntilityService => (dispatch, getState) => {
  const { updatingGlobal } = getState().company.data.intilityServicePlatform.intilityServiceStatus;
  if (updatingGlobal) return null;
  dispatch(updateGlobalIntilityServices());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/intilityServices/global`,
    method: 'POST',
    body: globalIntilityService
  })
    .then(() => dispatch(fetchGlobalIntilityServicesAsync(emptyGuid)))
    .catch(error => dispatch(updateGlobalIntilityServicesError(error)));
};

export const updateIntilityServiceAsync = intilityService => (dispatch, getState) => {
  const {updating} = getState().company.data.intilityServicePlatform.intilityServiceStatus;
  if (updating) return null;
  dispatch(updateIntilityServices());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/intilityServices/${intilityService.id}`,
    method: 'PUT',
    body: intilityService
  }).then(() => dispatch(fetchIntilityServicesAsync(id)))
    .catch(error => dispatch(updateIntilityServicesError(error)));
};

export const updateGlobalIntilityServiceAsync = state => (dispatch, getState) => {
  const {updatingGlobal} = getState().company.data.intilityServicePlatform.intilityServiceStatus;
  if (updatingGlobal) return null;
  dispatch(updateGlobalIntilityServices());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/intilityServices/global/${state.id}`,
    method: 'PUT',
    body: state
  }).then(() => dispatch(fetchGlobalIntilityServicesAsync(emptyGuid)))
    .catch(error => dispatch(updateGlobalIntilityServicesError(error)));
};

export const deleteIntilityServiceAsync = intilityServiceId => (dispatch, getState) => {
  const {updating} = getState().company.data.intilityServicePlatform.intilityServiceStatus;
  if (updating) return null;
  dispatch(updateIntilityServices());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/intilityServices/${intilityServiceId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchIntilityServicesAsync(id)))
    .catch(error => dispatch(updateIntilityServicesError(error)));
};

export const deleteGlobalIntilityServiceAsync = globalIntilityServiceId => (dispatch, getState) => {
  const {updatingGlobal} = getState().company.data.intilityServicePlatform.intilityServiceStatus;
  if (updatingGlobal) return null;
  dispatch(updateGlobalIntilityServices());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${emptyGuid}/intilityServices/global/${globalIntilityServiceId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchGlobalIntilityServicesAsync(emptyGuid)))
    .catch(error => dispatch(updateGlobalIntilityServicesError(error)));
};
