import {createAction} from '@reduxjs/toolkit';
import {
  editGlobalIntilityService,
  editIntilityService,
  normalizeGlobalIntilityServices,
  normalizeIntilityServiceInformation,
  normalizeIntilityServiceList,
  normalizeIntilityServices
} from './intilityServiceNormalizers';

export const fetchIntilityServiceListSuccess = createAction('intilityServicesAction/fetchIntilityServiceListSuccess', apiIntilityServices => ({
  payload: normalizeIntilityServiceList(apiIntilityServices)
}));

export const fetchInformationSuccess = createAction('intilityServicesAction/fetchIntilityServiceInformationSuccess', apiIntilityServices => ({
  payload: normalizeIntilityServiceInformation(apiIntilityServices)
}));

export const fetchIntilityServicesSuccess = createAction('intilityServicesAction/fetchIntilityServicesSuccess', apiIntilityServices => ({
  payload: normalizeIntilityServices(apiIntilityServices)
}));

export const updateIntilityServiceSuccess = createAction('intilityServicesAction/updateIntilityServiceSuccess', (apiIntilityService, intilityServiceList) => ({
  payload: editIntilityService(intilityServiceList, apiIntilityService)
}));

export const fetchGlobalIntilityServicesSuccess = createAction('intilityServicesAction/fetchGlobalIntilityServicesSuccess', globalIntilityServiceList => ({
  payload: normalizeGlobalIntilityServices(globalIntilityServiceList)
}));

export const updateGlobalIntilityServiceSuccess = createAction('intilityServicesAction/updateGlobalIntilityServiceSuccess', (apiIntilityService, globalIntilityServiceList) => ({
  payload: editGlobalIntilityService(globalIntilityServiceList, apiIntilityService)
}));

export const updateIntilityServiceInformationSuccess = createAction('intilityServicesAction/updateInformationSuccess', information => ({
  payload: normalizeIntilityServiceInformation(information)
}));
