import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {fetchCommunicationItemsSuccess} from './communicationActions';
import {
  fetchCommunicationItems,
  fetchCommunicationItemsError,
  updateCommunicationItems,
  updateCommunicationItemsError
} from './communicationReducers';
import {platformType, updatePlatformAsync} from '../platformUpdateThunk';

export const updateNote = data => (dispatch, getState) => {
  const {id} = getState().company.data.info;
  const {information} = getState().company.data.communicationPlatform;
  return dispatch(
    updatePlatformAsync(platformType.COMMUNICATION, id, {
      note: data,
      isEnabled: information.isEnabled
    })
  );
};

export const fetchCommunicationItemsAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.communicationPlatform.communicationStatus;
  if (fetching) return null;
  dispatch(fetchCommunicationItems());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/communication`,
    method: 'GET'
  }).then(items => dispatch(fetchCommunicationItemsSuccess(items)))
    .catch(error => dispatch(fetchCommunicationItemsError(error)));
};

export const addCommunicationService = fieldName => {
  return data => (dispatch, getState) => {
    const {updating} = getState().company.data.communicationPlatform.communicationStatus;
    if (updating) return null;
    dispatch(updateCommunicationItems());

    const {communicationPlatform, info} = getState().company.data;

    const body = {
      name: data,
      type: fieldName,
      communicationPlatformId: communicationPlatform.information.id
    };

    return apiFetchIdCollection(apiFetch, {
      url: `/companies/${info.id}/communication`,
      method: 'POST',
      body: body
    })
      .then(() => dispatch(fetchCommunicationItemsAsync(info.id)))
      .catch(error => dispatch(updateCommunicationItemsError(error)));
  };
};

export const deleteCommunicationService = () => {
  return id => (dispatch, getState) => {
    const {updating} = getState().company.data.communicationPlatform.communicationStatus;
    if (updating) return null;
    dispatch(updateCommunicationItems());

    const {info} = getState().company.data;

    return apiFetchIdCollection(apiFetch, {
      url: `/companies/${info.id}/communication/${id}`,
      method: 'DELETE'
    })
      .then(() => dispatch(fetchCommunicationItemsAsync(info.id)))
      .catch(error => dispatch(updateCommunicationItemsError(error)));
  };
};

export const addCollaboration = addCommunicationService('Collaboration');
export const deleteCollaboration = deleteCommunicationService();

export const addTelephony = addCommunicationService('Telephony');
export const deleteTelephony = deleteCommunicationService();

export const addMoblie = addCommunicationService('Mobile');
export const deleteMoblie = deleteCommunicationService();
