import {faUser} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 5,
  text: 'Main contact information added',
  icon: faUser,
  isEnabled: data => {
    return false;
  },
  isEnabledOverview: data => {
    return false;
  },
  getStatusText: data => {
    return data.info.createdAt;
  }
};
