import BasicPage from "../BasicPage";
import { SimpleBox } from "../../../Box/Box";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { toggleService } from "../../../../redux/company/companyThunks";
import { platformType } from "../../../../redux/company/platformUpdateThunk";
import { downloadCsv, fetchPrintersAsync, uploadPrintCsv } from "../../../../redux/company/printers/printerThunks";
import './printers.scss';
import {
  Accordion,
  Badge,
  Button, FormatDate,
  Icon,
  Label,
  Modal,
  Pagination,
  Table
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import PrinterDrawer from "./PrinterDrawer";
import { Printer } from "../../../../utils/hub/models";
import { fetchLocationsAsync } from "../../../../redux/company/locations/locationThunks";
import PrinterCard, { PrinterCsvCard } from "./PrinterCard";
import {
  faBox,
  faBuilding, faCheck,
  faEllipsis,
  faEnvelope, faFileArrowDown,
  faLock,
  faPhone
} from "@fortawesome/pro-regular-svg-icons";
import { fetchApplicationsAsync } from "../../../../redux/company/applications/applicationThunks";
import EditPrinterInfoModal, { SecurePrinting } from "./EditPrinterInfoModal";
import { getFilesArray } from "../../../FileUploader/CsvUploader";
import ErrorModal, { PrinterError } from "../Users/ErrorModal/ErrorModal";
import { updatePrintersClear } from "../../../../redux/company/printers/printerReducers";
import { PrintGuideModal } from "../Users/GuideModal/GuideModal";
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";
import { IntilityPermission } from "../../../Permissions";

const printerStatus = [
  '',
  <Icon icon={faCheck}/>,
  'Not set',
];

export const Printers = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {information, printerList, printersStatus} = useSelector(state => state.company.data.printerPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const [isComplete, setIsComplete] = useState(false);
  const {user} = useSelector(state => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(SecurePrinting.OperatingSupplier);
  const [sort, setSort] = useState({key: 'createdAt', direction: 'desc'});
  const [editablePrinter, setEditablePrinter] = useState(new Printer());
  const [replace, setReplace] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const fileRef = useRef(null);
  const dispatch = useDispatch();

  // fetch locations
  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchPrintersAsync(id));
    dispatch(fetchLocationsAsync(id));
    dispatch(fetchApplicationsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    setIsComplete(!information?.isEnabled);
  }, [information]);

  useEffect(() => {
    setErrorOpen(printersStatus.uploadError);
  }, [printersStatus.uploadError]);

  const togglePrinters = value => {
    dispatch(toggleService(platformType.PRINTERS, isComplete));
    setIsComplete(value);
  };

  const upload = () => {
    if (printerList.length > 0) {
      setShowCsvModal(true);
    } else {
      fileRef.current && fileRef.current.click();
    }
  };

  const handleFileUpload = e => {
    const filesAreAttached = e.target.value?.length > 0;
    if (filesAreAttached) {
      dispatch(uploadPrintCsv(e.target.value[0], replace));
    }
  };

  const onFileSelect = e => {
    return handleFileUpload({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.target.files)
      }
    });
  };

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const sortedData = [...printerList].sort((a, b) => {
    if (sort.direction === 'asc') {
      if (sort.key === 'securePrintStatus') return a[sort.key] - b[sort.key];
      if (sort.key === 'guestPrintStatus') return a[sort.key] - b[sort.key];
      if (sort.key === 'applications') return a[sort.key]?.length - b[sort.key]?.length;
      if (sort.key === 'createdAt') return new Date(a[sort.key]) - new Date(b[sort.key]);
      return a[sort.key]?.localeCompare(b[sort.key]);
    } else {
      if (sort.key === 'securePrintStatus') return b[sort.key] - a[sort.key];
      if (sort.key === 'guestPrintStatus') return b[sort.key] - a[sort.key];
      if (sort.key === 'applications') return b[sort.key]?.length - a[sort.key]?.length;
      if (sort.key === 'createdAt') return new Date(b[sort.key]) - new Date(a[sort.key]);
      return b[sort.key]?.localeCompare(a[sort.key]);
    }
  });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: printerList?.length, label: 'All'});
    return tableOptions;
  };

  const indexOfLastPrinter = page * pageSize.value;
  const indexOfFirstApp = indexOfLastPrinter - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstApp, indexOfLastPrinter);

  return (
    <BasicPage id='printers' taskType={taskType.PRINTERS}
               isComplete={isComplete}
               setIsComplete={togglePrinters}>
      <div className='content-main'>
        <SimpleBox
          className='printers-box'
          title='Printers and services'
        >
          <div className='printer-card-box bfl-grid'>
            <PrinterCard isDisabled={isComplete} header='Current operating supplier' icon={faBox} onClick={() => {
              setModalType(SecurePrinting.OperatingSupplier);
              setIsModalOpen(true);
            }}>
              <span className='bf-p'><Icon icon={faBuilding}/>{information?.supplierName || <Badge state={"warning"}>Missing name</Badge>}</span>
              <span className='bf-p'><Icon icon={faPhone}/>{information?.supplierPhoneNumber || <Badge state={"warning"}>Missing phone</Badge>}</span>
              <span className='bf-p'><Icon icon={faEnvelope}/>{information?.supplierEmail || <Badge state={"warning"}>Missing email</Badge>}</span>
            </PrinterCard>
            <PrinterCard isDisabled={isComplete} header='Current secure print' icon={faLock} onClick={() => {
              setModalType(SecurePrinting.SecurePrinting);
              setIsModalOpen(true);
            }}>
              <span className='bf-p'><Icon icon={faBuilding}/>{information?.securePrintType || <Badge state={"warning"}>Missing type</Badge>}</span>
              <span className='bf-p'><Icon icon={faBox}/>{information?.securePrintSupplier || <Badge state={"warning"}>Missing supplier</Badge>}</span>
            </PrinterCard>
            <PrinterCsvCard isDisabled={isComplete} header='Add printer list' icon={faFileArrowDown}>
              <span className='bf-p'>Use our {<PrintGuideModal text={` CSV template (guide and download) `} />} and upload it to the table.</span>
            </PrinterCsvCard>
          </div>
          <div className='printer-button-row'>
            <Label>Printer inventory</Label>
            <div>
              <Button disabled={isComplete} variant={"outline"} onClick={() => upload()}>Add printer list (CSV upload)</Button>
              <IntilityPermission>
                <Button disabled={isComplete} variant={"outline"} onClick={() => dispatch(downloadCsv())}>Download printer list</Button>
              </IntilityPermission>
              <input
                key={1}
                type='file'
                ref={fileRef}
                multiple={false}
                accept={'.csv'}
                onChange={onFileSelect}
                style={{display: 'none'}}
              />
              <Button disabled={isComplete} variant={"filled"} onClick={() => {
                setEditablePrinter(new Printer())
                setIsOpen(true);
              }}>Add printer</Button>
            </div>
          </div>
          {sortedData.length > 0 ?
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell/>
                  <Table.HeaderCell sorting={getSortProp('manufacturer')} onClick={onSortChangeCreator('manufacturer')}>Manufacturer</Table.HeaderCell>
                  <Table.HeaderCell sorting={getSortProp('model')} onClick={onSortChangeCreator('model')}>Model</Table.HeaderCell>
                  <Table.HeaderCell className='from-large' sorting={getSortProp('createdAt')} onClick={onSortChangeCreator('createdAt')}>Created</Table.HeaderCell>
                  <Table.HeaderCell className='from-small' sorting={getSortProp('serialNumber')} onClick={onSortChangeCreator('serialNumber')}>Serial</Table.HeaderCell>
                  <Table.HeaderCell className='from-small' sorting={getSortProp('macAddress')} onClick={onSortChangeCreator('macAddress')}>Mac address</Table.HeaderCell>
                  <Table.HeaderCell className='from-medium' sorting={getSortProp('locationId')} onClick={onSortChangeCreator('locationId')}>Location</Table.HeaderCell>
                  <Table.HeaderCell className='from-medium' sorting={getSortProp('floor')} onClick={onSortChangeCreator('floor')}>Floor</Table.HeaderCell>
                  <Table.HeaderCell className='from-medium' sorting={getSortProp('room')} onClick={onSortChangeCreator('room')}>Room</Table.HeaderCell>
                  <Table.HeaderCell className='from-large' sorting={getSortProp('securePrintStatus')} onClick={onSortChangeCreator('securePrintStatus')}>Secure print</Table.HeaderCell>
                  <Table.HeaderCell className='from-large' sorting={getSortProp('guestPrintStatus')} onClick={onSortChangeCreator('guestPrintStatus')}>Guest print</Table.HeaderCell>
                  <Table.HeaderCell className='from-medium' sorting={getSortProp('applications')} onClick={onSortChangeCreator('applications')}>Applications</Table.HeaderCell>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortedAndIndexedData && sortedAndIndexedData?.map((p, j) => {
                  return (
                    <Table.Row key={j} limitExpandClick content={
                      <div style={{padding: 'var(--bfs24)'}}>
                        <div className='inner-values'>
                          <div className='to-large'>
                            <Label>Guest print</Label>
                            <p>{printerStatus[p?.guestPrintStatus]}</p>
                          </div>
                          <div className='to-large'>
                            <Label>Secure print</Label>
                            <p>{printerStatus[p?.securePrintStatus]}</p>
                          </div>
                          <div className='to-large'>
                            <Label>Created</Label>
                            <p><FormatDate date={new Date(p?.createdAt)}/></p>
                          </div>
                          <div className='to-medium'>
                            <Label>Room</Label>
                            <p>{p?.room}</p>
                          </div>
                          <div className='to-medium'>
                            <Label>Floor</Label>
                            <p>{p?.floor}</p>
                          </div>
                          <div className='to-medium'>
                            <Label>Location</Label>
                            <p>{locationList?.find(location => location?.id === p?.locationId)?.name || ''}</p>
                          </div>
                        </div>
                        {p?.applications?.length > 0 ? [
                          <p className='bf-strong' style={{ margin: '0' }}>Applications</p>,
                          <Accordion>
                            {p?.applications?.map((a, i) => {
                              return (
                                <Accordion.Item key={i}
                                                title={applicationList?.filter(a => a.isEnabled)?.find(app => app.id === a?.applicationId)?.name || ''}>
                                  <p>{a?.comment}</p>
                                </Accordion.Item>
                              )
                            })}
                          </Accordion> ]
                          :
                          <p className='bf-em' style={{margin: '0'}}>No applications</p>
                        }
                      </div>
                    }>
                      <Table.Cell>{p?.manufacturer}</Table.Cell>
                      <Table.Cell>{p?.model}</Table.Cell>
                      <Table.Cell className='from-large'><FormatDate date={new Date(p?.createdAt)}/></Table.Cell>
                      <Table.Cell className='from-small'>{p?.serialNumber}</Table.Cell>
                      <Table.Cell className='from-small'>{p?.macAddress}</Table.Cell>
                      <Table.Cell className='from-medium'>{locationList?.find(location => location?.id === p?.locationId)?.name || ''}</Table.Cell>
                      <Table.Cell className='from-medium'>{p?.floor}</Table.Cell>
                      <Table.Cell className='from-medium'>{p?.room}</Table.Cell>
                      <Table.Cell className='from-large'>{printerStatus[p?.securePrintStatus]}</Table.Cell>
                      <Table.Cell className='from-large'>{printerStatus[p?.guestPrintStatus]}</Table.Cell>
                      <Table.Cell className='from-medium'>{p?.applications?.length}</Table.Cell>
                      <Table.Cell>
                        <button className='table-button' disabled={isComplete} onClick={() => {
                          setEditablePrinter(p);
                          setIsOpen(true);
                        }}>
                          <Icon style={isComplete ? {color: "var(--bfc-base-c-2)"} : {color: "var(--bfc-base-c-1)"}} icon={faEllipsis} />
                        </button>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table> :
            <p className='bf-em'>No printers added yet.</p>
          }
          <div className='bottom-line'>
            <div />
            {Math.ceil(sortedData?.length / pageSize.value) > 1 ? [
              <Pagination
                key='Pagination'
                style={{marginTop: 'var(--bfs16)'}}
                totalPages={Math.ceil(sortedData?.length / pageSize.value)}
                currentPage={page}
                onChange={e => {
                  setPage(e);
                }}
              />,
              <Select
                key='Items'
                label='Table size'
                hideLabel
                value={pageSize}
                className='table-size-dropdown'
                options={tableSizeOptions()}
                onChange={item => {
                  setPage(1);
                  setPageSize(item);
                }}
              />
            ] : [
              <div key='Pagination no items'/>,
              <div key='No items'/>
            ]}
          </div>
        </SimpleBox>
      </div>
      <PrinterDrawer isOpen={isOpen} setIsOpen={setIsOpen} isDisabled={isComplete} printer={editablePrinter} setPrinter={setEditablePrinter}/>
      <EditPrinterInfoModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} infoType={modalType}/>
      <ErrorModal
        isOpen={errorOpen}
        onClose={() => dispatch(updatePrintersClear())}
        type={PrinterError}
      />
      <Modal isOpen={showCsvModal} header={`Upload printers`} key={2} onRequestClose={() => setShowCsvModal(false)}>
        <p>
          Uploading a CSV file with printers will add them to your current printer list.
        </p>
        <div className='csv-uploader-footer'>
          <div/>
          <Button
            variant={'filled'}
            onClick={() => {
              setShowCsvModal(false);
              setReplace(false);
              fileRef.current && fileRef.current.click();
            }}
          >
            Add to list
          </Button>
        </div>
      </Modal>
    </BasicPage>
  );

}

export default Printers