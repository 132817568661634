import {Spinner} from '@intility/bifrost-react';
import { SimpleBox } from "components/Box/Box";
import React from "react";
import {useSelector} from 'react-redux';
import {AccordionComponent} from './AccordionComponent';
import './compliancePolicies.scss';
import {compliancePolicies} from './mobileCompliancePolicyData';

export default function CompliancePolicies() {
  const mobileDevices = useSelector(state => state.company.data.mobilePlatform.mobileDevices);

  const isLoading =
    (mobileDevices.loading === false &&
      (mobileDevices.apple || mobileDevices.android || mobileDevices.appConfig) === null) ||
    mobileDevices.loading;

  if (isLoading) return <Spinner />;

  return (
    <SimpleBox
      title='Compliance Policies'
      id='compliance-main'
    >
      <div className='accordion-container'>
        {compliancePolicies.map((compliancePolicy, i) => {
          const backendValues = mobileDevices[compliancePolicy.policy];

          const merged = compliancePolicy.policies.map(policy => {
            policy.value = backendValues[policy.name];
            return policy;
          });

          compliancePolicy.policies = merged;
          return <AccordionComponent key={i} compliancePolicy={compliancePolicy} />;
        })}
      </div>
    </SimpleBox>
  );
}
