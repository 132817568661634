import { Button, Checkbox, FieldGroup, Input, Message, Pagination, Table } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersAsync, patchUserAsync } from "../../../../../../../redux/company/users/userThunks";
import '../locationEditDrawer.scss';
import { emptyGuid } from "../../../../../../../utils/guid";
import { useHistory } from "react-router-dom";
import CheckUserDeleted from "../../../../../../../utils/hub/checkUserDeleted";

const userSearchOptions = [
  { value: 'firstName', label: 'First name' },
  { value: 'lastName', label: 'Last name' },
  { value: 'department', label: 'Department' }
];

const UserRow = ({user, location, isEnabled, toggleSwitch, isDisabled}) => {
  const [enabled, setEnabled] = useState(isEnabled);

  return (
    <Table.Row>
      <Table.Cell>{isEnabled && <CheckUserDeleted userName={user?.firstName + user?.lastName} phoneNumber={user?.mobilePhone} email={user?.primaryEmailAddress}/>}{user?.firstName + ' ' + user?.lastName}</Table.Cell>
      <Table.Cell>{user?.department}</Table.Cell>
      <Table.Cell><Checkbox button label={'Works here'} checked={enabled} disabled={isDisabled} onChange={() => {
        setEnabled(!enabled);
        toggleSwitch(user.id, location.id, location.name, !enabled);
      }}/></Table.Cell>
    </Table.Row>
  )
};

UserRow.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  isEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  toggleSwitch: PropTypes.func,
}

export const UsersContent = ({ location, isDisabled }) => {
  const { id } = useSelector(state => state.company.data.info);
  const {user} = useSelector(state => state.auth)
  const { userList } = useSelector(state => state.company.data.userPlatform);
  const [searchValue, setSearchValue] = useState('');
  const [userSearchValue, setUserSearchValue] = useState({ value: 'firstName', label: 'First name' });
  const [sort, setSort] = useState({ key: 'firstName', direction: 'asc' });
  const [activeBtn, setActiveBtn] = useState('all')
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 15, label: '15'});
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchUsersAsync(id));
  }, [user, dispatch, id]);


  const sortedData = userList
    .filter(u => {
      if (activeBtn === 'all') {
        return u;
      } else if (activeBtn === 'active') {
        return u.locationId === location.id
      } else if (activeBtn === 'inactive') {
        return u.locationId !== location.id
      }
    })
    .filter(s => {
      if (userSearchValue.value === 'locationId') {
        return userList?.find(b => b.locationId === location.id)?.name?.toLowerCase()?.includes(searchValue?.toLowerCase());
      } else {
        return s[userSearchValue.value]?.toLowerCase().includes(searchValue?.toLowerCase());
      }
    })
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        return a[sort.key]?.toString()?.localeCompare(b[sort.key]?.toString());
      } else {
        return b[sort.key]?.toString()?.localeCompare(a[sort.key]?.toString());
      }
    });

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const toggleUser = (userId, locationId, locationName, toggled) => {
    if (toggled) {
      dispatch(patchUserAsync(userId, locationId, locationName))
    } else {
      dispatch(patchUserAsync(userId, emptyGuid, ''))
    }
  }

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className='users-content'>
      <Message style={{marginBottom: 'var(--bfs8)'}}>For mass allocation, use the <span className='bf-link' onClick={() => history.push(`/${id}/users`)}>Users</span> page</Message>
      <div className='header-content'>
        <FieldGroup className='search-bar'>
          <Select
            label='Search'
            hideLabel
            placeholder={userSearchValue.label}
            options={userSearchOptions}
            onChange={e => setUserSearchValue(e)}
          />
          <Input
            label='Search'
            hideLabel
            placeholder='Search for user'
            clearable
            icon={faSearch}
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
        </FieldGroup>
        <Button.Group>
          <Button
            active={activeBtn === 'all'}
            onClick={() => setActiveBtn('all')}
          >Show all ({userList.length})</Button>
          <Button
            active={activeBtn === 'active'}
            onClick={() => setActiveBtn('active')}
          >Users at location ({userList.filter(u => u.locationId === location.id).length})</Button>
          <Button
            active={activeBtn === 'inactive'}
            onClick={() => setActiveBtn('inactive')}
          >Other users ({userList.filter(u => u.locationId !== location.id).length})</Button>
        </Button.Group>
      </div>
      {sortedAndIndexedData.length > 0 ?
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorting={getSortProp('firstName')} onClick={onSortChangeCreator('firstName')}>User</Table.HeaderCell>
              <Table.HeaderCell style={{width: '135px'}} sorting={getSortProp('department')} onClick={onSortChangeCreator('department')}>Department</Table.HeaderCell>
              <Table.HeaderCell style={{width: '155px'}} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedAndIndexedData.map(u => {
              return <UserRow key={u?.id} isEnabled={u.locationId === location.id} user={u} location={location} toggleSwitch={toggleUser} isDisabled={isDisabled}/>
            })}
          </Table.Body>
        </Table> :
        <div/>
      }
      {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
        <Pagination
          style={{marginTop: 'var(--bfs16)', marginBottom: 'var(--bfs16)'}}
          totalPages={Math.ceil(sortedData?.length / pageSize.value)}
          currentPage={page}
          onChange={e => {
            setPage(e);
          }}
        />
      ) : (
        <div />
      )}
    </div>
  );

}

UsersContent.propTypes = {
  location: PropTypes.object,
  isDisabled: PropTypes.bool
}

export default UsersContent