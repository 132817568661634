import React, {useEffect, useState} from 'react';
import { Badge, Icon } from "@intility/bifrost-react";
import '../backoffice.scss';
import {useSelector} from 'react-redux';
import UserTypeOptions, {
  DefaultIntilityUserTypes
} from "../../Hub/Pages/Users/Options/UserTypeOptions";
import { faCheck, faInfoCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
export const UserAdmin = () => {
  const {information} = useSelector(state => state.company.data.userPlatform);
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    const userTypes = information?.userTypes?.split(',').map(u => UserTypeOptions[u]?.value);
    setUserTypes(userTypes || DefaultIntilityUserTypes);
  }, [information]);

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>User Admin</h3>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{border: '1px solid var(--bfc-base-c-dimmed)', padding: 'var(--bfs24)'}}>
          <p className='bf-large' style={{marginTop: 0}}>Enabled user types</p>
          {UserTypeOptions.map(option => (
            <span key={option.label} style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
              {userTypes?.includes(option.value) ?
                <Icon style={{color: 'var(--bfc-success)', marginRight: 'var(--bfs8)'}} icon={faCheck}/>
                :
                <Icon style={{color: 'var(--bfc-alert)', marginRight: 'var(--bfs8)'}} icon={faTimes}/>
              }
              <p style={{margin: 'var(--bfs4) 0'}}>{option.label}</p>
            </span>
          ))}
        </div>
        <div style={{padding: 'var(--bfs24)', margin: '0 var(--bfs24)'}}>
          <Badge state={information.isRotReady ? 'success' : undefined}>
            <Icon icon={information.isRotReady ? faCheck : faInfoCircle} marginRight />
            {information.isRotReady ? 'User list content has been approved' : 'User list content not yet approved'}
          </Badge>
        </div>
      </div>
    </div>
  );
};

export default UserAdmin;
