import React, {useEffect, useState} from 'react';
import '../users.scss';
import BasicPage from '../../BasicPage';
import { SimpleBox } from "../../../../Box/Box";
import { components } from 'react-select'
import {useDispatch, useSelector} from 'react-redux';
import {toggleService} from '../../../../../redux/company/companyThunks';
import {
  Tag,
  Button,
  Checkbox, FieldGroup,
  Icon,
  Input,
  Modal,
  Pagination,
  Spinner,
  Table,
  useFloatingMessage, Tabs, Tooltip
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import {
  downloadCsv,
  fetchUsersAsync,
  multiPut
} from "../../../../../redux/company/users/userThunks";
import {fetchLocationsAsync} from '../../../../../redux/company/locations/locationThunks';
import {fetchBillingAsync} from '../../../../../redux/company/billing/billingThunks';
import CountryOptions from '../Options/CountryOptions';
import LanguageOptions from '../Options/LanguageOptions';
import SearchOptions from '../Options/SearchOptions';
import UserRow from "./UserRow";
import {faPlus, faSearch, faSpinner, faTrashAlt} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import {updateUsersClear} from '../../../../../redux/company/users/usersReducers';
import ErrorModal, {CompanyUserError} from '../ErrorModal/ErrorModal';
import {
  getIntilityUserDescriptionOptions, getFilterOptions,
  IntilityUserType, UserStatusTypes
} from '../Options/UserTypeOptions';
import {platformType} from '../../../../../redux/company/platformUpdateThunk';
import {
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowDownToLine, faArrowUpRightAndArrowDownLeftFromCenter,
  faCheck,
  faInfoCircle,
  faTimes, faTrashRestore
} from '@fortawesome/pro-regular-svg-icons';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import produce from "immer";
import ButtonWithTooltip from "../../../../ButtonWithTooltip/ButtonWithTooltip";
import LicenseModal from "../LicenseModal/LicenseModal";
import DeletedUserRow from "./DeletedUserRow";
import { taskType } from "../../../../../utils/hub/pageData/pageDataOptions";
import UserRowOptions, {UserRowEditable} from '../Options/UserRowOptions';
import useLocalStorageState from 'use-local-storage-state';
import EmptyUsersState from './EmptyUsersState';
import {IntilityPermission} from '../../../../Permissions';
import UseIntilityPermission from '../../../../../hooks/useIntilityPermission';
import {UserValueContainer} from './UserValueContainer';

export const UserTableType = {
  USERS: 1,
  ONBOARDED: 2,
  DELETED: 3,
};

export const UserRegistration = ({newUser, setUserId, isComplete, setIsComplete, editUser}) => {
  const {userList, information, usersStatus} = useSelector(state => state.company.data.userPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {billingList} = useSelector(state => state.company.data.billingPlatform);
  const {id} = useSelector(state => state.company.data.info);
  const [bulkReady, setBulkReady] = useState(false);
  const [tabSelected, setTabSelected] = useState(UserTableType.USERS);
  const [bulkSelectList, setBulkSelectList] = useState([]);
  const [userTypeSelect, setUserTypeSelect] = useState(null);
  const [locationSelect, setLocationSelect] = useState(null);
  const [languageSelect, setLanguageSelect] = useState(null);
  const [countrySelect, setCountrySelect] = useState(null);
  const [billingSelect, setBillingSelect] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [statusUsers, setStatusUsers] = useState({});
  const [userRows, setUserRows] = useState(UserRowOptions.slice(0, 11));
  const [userTableRows, setUserTableRows] = useState(UserRowOptions.slice(0, 11));
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAll, setIsEditingAll] = useState(false);
  const [saving, setSaving] = useState(false);
  const [guideStep, setGuideStep] = useState(0);
  const [multiFieldSave, setMultiFieldSave] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isMovingToOnboarded, setIsMovingToOnboarded] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 20, label: 20});
  const [selectableBilling, setSelectableBilling] = useState([]);
  const [selectableLocation, setSelectableLocation] = useState([]);
  const [userEditList, setUserEditList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [managers, setManagers] = useState([]);
  const [editAllChecked, setEditAllChecked] = useState(false);
  const [missingInfoUsers, setMissingInfoUsers] = useState(false);
  const [anySelected, setAnySelected] = useState(0);
  const [licenseUser, setLicenseUser] = useState({});
  const [licenseModalOpen, setLicenseModalOpen] = useState(false);
  const [showEditRow, setShowEditRow] = useState(false);
  const [animateExit, setAnimateExit] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchCriterea, setSearchCriterea] = useState({
    value: 'firstName',
    label: 'First name'
  });
  const [intilityUserType, setIntilityUserType] = useState([]);
  const [actionSelect, setActionSelect] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [sort, setSort] = useState({
    key: 'name',
    direction: 'asc'
  });
  const validEmailCharsRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validPhoneNumberRegex = /^\+?[0-9\s\-()]{7,15}$/;
  const {user} = useSelector(state => state.auth);
  const intilityUser = UseIntilityPermission();
  const { showFloatingMessage } = useFloatingMessage();
  const [sideCollapsed, setSideCollapsed] = useLocalStorageState("bfCollapsed", {
    defaultValue: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const filters = window.localStorage[`userFilters${id}`] ? JSON.parse(window.localStorage[`userFilters${id}`]) : [];
    setFilterList([...filters]);
    if (filters !== undefined) {
      setIntilityUserType(filters);
      const test = filters.filter(f => f.type === 'warningFilter' && ['attention', 'warning'].includes(f.value))
      if (test.length === 2) setMissingInfoUsers(true);
    }
    const userRowFilter = filters?.find(f => f.type === 'selectedRows');
    if (userRowFilter !== undefined) {
      setUserRows(JSON.parse(userRowFilter.rows));
      setUserTableRows(JSON.parse(userRowFilter.rows));
    }
  }, [id]);

  useEffect(() => {
    if (isAllSelected || anySelected > 0) {
      setShowEditRow(true);
    } else if (!isAllSelected && isAllSelected <= 0) {
      setAnimateExit(true);
    }
  }, [isAllSelected, anySelected]);

  useEffect(() => {
    if (animateExit) {
      const timer = setInterval(function() {
        setShowEditRow(false);
        setAnimateExit(false);
        clearInterval(timer);
      }, 300);
    }
  }, [animateExit]);

  useEffect(() => {
    setSelectableBilling(
      billingList?.map(billing => ({
        label: billing.name,
        value: billing.id,
        type: 'billingId'
      }))
    );
  }, [billingList]);

  useEffect(() => {
    const items = userList?.map(u => u?.licenses?.map(l => l?.license?.name))?.flatMap(u => u);
    setLicenseOptions([...new Set(items)].map(i => ({label: i, value: i})))
    setManagers(userList?.filter(m => {
      return (m.accountEnabled === true && m.intilityUserType !== IntilityUserType.SharedMailbox);
    })?.map(i => ({label: `${i?.firstName} ${i?.lastName}`, value: i.id, type: 'managerId'})))
  }, [userList]);

  useEffect(() => {
    setUserId(undefined);
  }, [setUserId]);

  useEffect(() => {
    setSelectableLocation(
      locationList?.map(location => ({
        label: location.name,
        value: location.id,
        type: 'locationId'
      }))
    );
  }, [locationList]);

  useEffect(() => {
    if (actionSelect?.value === 'locationId') {
      setIsEditing(false);
    } else if (actionSelect?.value === 'billingId') {
      setIsEditing(false);
    } else if (actionSelect?.value === 'country') {
      setIsEditing(false);
    } else if (actionSelect?.value === 'language') {
      setIsEditing(false);
    } else if (actionSelect?.value === 'intilityUserType') {
      setIsEditing(false);
    } else if (actionSelect?.value === 'Edit selected') {
      setIsEditing(true);
    } else if (actionSelect?.value === 'Delete selected') {
      setSaving(true);
      setBulkReady(true);
      setDeleting(true);
    } else if (actionSelect?.value === 'Restore selected') {
      setSaving(true);
      setBulkReady(true);
      setDeleting(true);
    } else {
      setIsEditing(false);
    }
  }, [actionSelect, selectableLocation, selectableBilling]);

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  useEffect(() => {
    setErrorOpen(usersStatus.uploadError);
  }, [usersStatus.uploadError]);

  useEffect(() => {
    if (anySelected === 0 && !isAllSelected) {
      setUserEditList([]);
      setSaving(false);
      setIsEditing(false);
      setBulkReady(false);
      setActionSelect(null);
      setEditAllChecked(false);
    }
  }, [anySelected, isAllSelected]);

  useEffect(() => {
    if(isEditingAll === false) {
      setIsAllSelected(false);
    }
  }, [isEditingAll]);

  useEffect(() => {
    //if (anySelected === sortedAndIndexedData.length) setIsAllSelected(true);
  }, [anySelected]);

  const toggleUsers = () => {
    let locationErrors = [];
    let billingErrors = [];
    let emailErrors = [];

    userList?.filter(u => u.isEnabled === true)?.forEach(u => {
      if (!locationList.some(b => b.id === u.locationId) || u.locationId === null) locationErrors.push(`${u.firstName || ''} ${u.lastName || ''}`);
      if (!billingList.some(b => b.id === u.billingId)) billingErrors.push(`${u.firstName || ''} ${u.lastName || ''}`);
      if (!validEmailCharsRegex.test(u.primaryEmailAddress)) emailErrors.push(`${u.firstName || ''} ${u.lastName || ''} : ${u.primaryEmailAddress}`)
    })
    if (locationErrors.length > 0 || billingErrors.length > 0 || emailErrors.length > 0) {
      showFloatingMessage(`Information missing: 
        ${locationErrors.length > 0 ? `location: ${locationErrors.length}` : ''}
        ${billingErrors.length > 0 ? `billing: ${billingErrors.length}` : ''}
        ${emailErrors.length > 0 ? `Primary E-mail errors: ${emailErrors.length}` : ''}`
      )
    } else {
      dispatch(toggleService(platformType.COMPANYUSERS, isComplete));
    }
  };

  useEffect(() => {
    if (intilityUserType.length === 0) setMissingInfoUsers(false);
    if (!intilityUserType?.some(t => t.type === 'warningFilter')) setMissingInfoUsers(false);
  }, [intilityUserType?.length]);

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information.isEnabled])

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchUsersAsync(id));
    dispatch(fetchLocationsAsync(id));
    dispatch(fetchBillingAsync(id));
  }, [user, dispatch, id]);

  const addNewUser = () => {
    newUser(true);
  };

  const handleBulkAction = () => {
    if (bulkReady && bulkSelectList.length > 0) {
      const newUsers = userEditList.map(u => {
        const item = {...u};
        bulkSelectList.forEach(b => {
          item[b.type] = b.value;
        })
        return item;
      });
      dispatch(multiPut(newUsers, intilityUser));
      setSubmitted(true);
      setIsAllSelected(false);
      setAnySelected(0);
      setUserEditList([]);
      setActionSelect(null);
      resetBulkAction();
    }
  };

  const resetBulkAction = () => {
    setUserTypeSelect(null);
    setLocationSelect(null);
    setLanguageSelect(null);
    setCountrySelect(null);
    setBillingSelect(null);
    setBulkSelectList([]);
  };

  const clearBulkEdit = () => {
    resetBulkAction();
    setIsEditing(false);
    setActionSelect(null);
    setEditAllChecked(false);
    setIsAllSelected(false);
    setAnySelected(0);
  }

  const resetAction = () => {
    setSubmitted(true);
    setIsAllSelected(false);
    setDeleting(false);
    setAnySelected(0);
    setUserEditList([]);
    setActionSelect(null);
  }

  const handleBulkSelect = (bulkItem, itemType) => {
    const index = bulkSelectList?.findIndex(b => b.type === itemType);
    if (bulkItem === null && index !== -1) {
      setBulkSelectList(produce(bulkSelectList, draft => {
        draft.splice(index, 1);
      }));
    } else if (index !== -1) {
      setBulkSelectList(produce(bulkSelectList, draft => {
        draft[index] = {...bulkItem};
      }));
    } else {
      setBulkSelectList([...bulkSelectList, bulkItem])
    }
    setSaving(true);
    setBulkReady(true);
  }

  const handleBulkDelete = () => {
    if (bulkReady === true && deleting === true) {
      const newUsers = userEditList.map(u => {
        const item = {...u};
        item.isEnabled = tabSelected === UserTableType.DELETED;
        return item;
      });
      dispatch(multiPut(newUsers));
      resetAction();
    }
  };

  const onSave = () => {
    setSaving(true);
    setMultiFieldSave(true);
    setEditAllChecked(false);
  };

  useEffect(() => {
    if (multiFieldSave) {
      setMultiFieldSave(false);
      dispatch(multiPut(userEditList, intilityUser));
      resetAction();
    }
  }, [multiFieldSave]);

  const onSortChangeCreator = key => () => {
    setAnySelected(0);
    setIsAllSelected(false);
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const checkAll = e => {
    const {checked} = e.target;
    setIsAllSelected(checked);
    if (!checked) {
      setAnySelected(0);
    } else {
      setAnySelected(sortedAndIndexedData.length)
      setIsEditingAll(true);
    }
  };

  const getStatus = (user) => {
    const inBilling = billingList.some(b => b.id === user.billingId);
    const inLocation = locationList.some(b => b.id === user.locationId);
    const validPrimaryEmail = validEmailCharsRegex.test(user.primaryEmailAddress);
    const validEmail = validEmailCharsRegex.test(user.mail);
    const validPhoneNumber = user?.intilityUserType === IntilityUserType.IntilityIdUser && !validPhoneNumberRegex.test(user?.mobilePhone);
    const emailMatch = (user?.mail?.length > 0 && user?.primaryEmailAddress?.toLowerCase()?.trim() !== user?.mail?.toLowerCase()?.trim());

    if (!inBilling || !inLocation || user?.primaryEmailAddress?.length === 0) return UserStatusTypes.Attention;
    if (emailMatch) return UserStatusTypes.Warning;
    if (validPhoneNumber) return UserStatusTypes.Warning;
    if (!validPrimaryEmail || ((user.mail !== null && user.mail !== '') && !validEmail)) return UserStatusTypes.Warning;

    return UserStatusTypes.Ready;
  };

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const sortedData = [...userList]?.filter(s => {
    if (searchValue?.length > 0) return s[searchCriterea?.value]?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase());
    return s;
  })?.filter(u => {
    if (tabSelected === UserTableType.USERS) return ((new Date(u?.rotMigrated).getFullYear() === 1 || u?.rotMigrated === null) && u?.isEnabled)
    if (tabSelected === UserTableType.ONBOARDED) return ((new Date(u?.rotMigrated).getFullYear() !== 1 && (new Date(u?.rotMigrated) < new Date(Date.now()))) && u?.isEnabled)
    if (tabSelected === UserTableType.DELETED) return !u.isEnabled;
    return u.isEnabled;
  }).map(u => {
    const test = userList.find(m => m.id === u.managerId);
    if (test === null || test === undefined) return { ...u, manager: null};
    return { ...u, manager: `${test?.firstName} ${test?.lastName}` }
  })
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (sort.key === 'status') return getStatus(a)?.localeCompare(getStatus(b));
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        if (sort.key === 'userType') return a[sort.key] - b[sort.key];
        if (sort.key === 'accountEnabled') return a[sort.key] === b[sort.key];
        if (sort.key === 'intilityUserType') return a[sort.key] - b[sort.key];
        if (sort.key === 'licenses') return a?.licenses?.length - b?.licenses?.length;
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        if (sort.key === 'status') return getStatus(b)?.localeCompare(getStatus(a));
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        if (sort.key === 'userType') return b[sort.key] - a[sort.key];
        if (sort.key === 'accountEnabled') return b[sort.key] === a[sort.key];
        if (sort.key === 'intilityUserType') return b[sort.key] - a[sort.key];
        if (sort.key === 'licenses') return b?.licenses?.length - a?.licenses?.length;
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    })
    .filter(s => {
      const warningFilters = filterList?.filter(filter => filter.type === 'warningFilter');
      if (!warningFilters || warningFilters.length === 0 || warningFilters.length === 3) return true;

      const inBilling = billingList?.some(b => b.id === s.billingId);
      const inLocation = locationList?.some(b => b.id === s.locationId);
      const validPrimaryEmail = validEmailCharsRegex.test(s.primaryEmailAddress);
      const validEmail = s?.mail?.length > 0 ? validEmailCharsRegex.test(s.mail) : true;
      const validPhoneNumber = s?.intilityUserType === IntilityUserType.IntilityIdUser ? validPhoneNumberRegex.test(s?.mobilePhone) : true;
      const email = s?.primaryEmailAddress?.toLowerCase()?.trim();
      const mail = s?.mail?.toLowerCase()?.trim();
      const mailMatch = s?.mail?.length > 0 ? (email === mail) : true;

      if (warningFilters.some(s => s.value === UserStatusTypes.Warning)) {
        if (!inBilling || !inLocation || !s?.primaryEmailAddress) return true;
      }
      if (warningFilters.some(s => s.value === UserStatusTypes.Attention)) {
        if (!mailMatch || ((s?.primaryEmailAddress?.length > 0 && !validPrimaryEmail) || !validEmail || !validPhoneNumber)) return true;
      }
      if (warningFilters.some(s => s.value === UserStatusTypes.Ready)) {
        if (inBilling && inLocation && validPrimaryEmail && validEmail && mailMatch) return true;
      }
      return false;

    })
    .filter(s => {
      if (filterList?.some(f => f.type === 'enabledFilter')){
        let test = false;
        if (filterList?.some(f => f.label === 'Inactive user')){
          test = s.accountEnabled === false;
        }
        if (filterList.some(f => f.label === 'Active user')){
          test = s.accountEnabled === true;
        }
        return test;
      }
      return s;
    }).filter(s => {
      if (filterList?.some(f => f.type === 'intilityUserType')){
        let test = false;
        intilityUserType?.forEach(f => {
          if (s.intilityUserType === f?.value) return test = true;
        })
        return test;
      }
      return s;
      }).filter(s => {
      if (!filterList) return true;
      for (const f of filterList) {
        if (['warningFilter', 'selectedRows', 'enabledFilter', 'intilityUserType'].includes(f?.type)) {
          continue;
        }
        if (f?.type === 'licenses' && !s?.licenses.some(l => l?.license?.name === f?.value)) {
          return false;
        }
        if (f?.type === 'location' && locationList?.some(b => b.id === s.locationId && b.name?.toLowerCase().includes(f?.value?.toLowerCase()))) {
          return true;
        }
        if (f?.type === 'billingCompany' && billingList?.some(b => b.id === s.billingId && b.name?.toLowerCase().includes(f?.value?.toLowerCase()))) {
          return true;
        }

        const property = s[f?.type];
        if (property === null || property === undefined) return false;

        if (typeof property === 'string' && !property.toLowerCase().includes(f?.value?.toLowerCase())) {
          return false;
        }
      }
      return true;
    });

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  const getListStatus = () => {
    let statuses = {warning: 0, ready: 0}
    userList?.filter(u => ((new Date(u?.rotMigrated).getFullYear() === 1 || u?.rotMigrated === null) && u?.isEnabled))?.map((user) => {
      const inBilling = billingList.some(b => b.id === user.billingId);
      const inLocation = locationList.some(b => b.id === user.locationId);
      const validPrimaryEmail = validEmailCharsRegex.test(user.primaryEmailAddress);
      const validEmail = user?.mail?.length > 0 ? validEmailCharsRegex.test(user.mail) : true;
      const validPhoneNumber = user?.intilityUserType === IntilityUserType.IntilityIdUser ? validPhoneNumberRegex.test(user?.mobilePhone) : true;
      const emailMatch = (user?.mail?.length > 0 && user?.primaryEmailAddress?.toLowerCase()?.trim() !== user?.mail?.toLowerCase()?.trim());

      if (!inBilling || !inLocation || user?.primaryEmailAddress?.length === 0) {
        statuses.warning++;
        return;
      }
      if (emailMatch || (!validPrimaryEmail || !validEmail || !validPhoneNumber)) {
        statuses.warning++;
        return;
      }
      if (inBilling && inLocation && !emailMatch && validPrimaryEmail && validEmail) statuses.ready++;
    });
    return statuses;
  };

  useEffect(() => {
    setStatusUsers(getListStatus());
  }, [userList, locationList, billingList]);

  const addFilter = (filter) => {
    if (filter?.type === 'selectedRows' && filterList.some(s => s?.type === filter?.type)) {
      const index = filterList.findIndex(s => s?.type === filter?.type);
      const newFilterList = produce(filterList, draft => {
        draft[index] = {...filter};
      });
      setFilterList(newFilterList);
      setPage(1);
      localStorage.setItem(`userFilters${id}`, JSON.stringify(newFilterList))
      return;
    };
    const newFilterList = [...filterList, filter];
    setFilterList(newFilterList);
    setPage(1);
    setSearchValue('');
    localStorage.setItem(`userFilters${id}`, JSON.stringify(newFilterList))
  }

  const addFilters = (filters) => {
    if (!Array.isArray(filters) || filters.length === 0) return;

    let newFilterList = [...filterList];

    filters.forEach(filter => {
      if (!filter) return;

      const existingFilterIndex = newFilterList.findIndex(s => s?.type === filter?.type && s?.value === filter?.value);

      if (existingFilterIndex === -1) {
        newFilterList.push(filter);
      }
    });

    setFilterList(newFilterList);
    setIntilityUserType(newFilterList);
    setPage(1);
    setSearchValue('');
    localStorage.setItem(`userFilters${id}`, JSON.stringify(newFilterList));
  };



  const addOrChangeFilter = (filter) => {
    const rowFilter = filterList?.find(f => f?.type === 'selectedRows');
    if (rowFilter) {
      setFilterList([...filter, rowFilter]);
      localStorage.setItem(`userFilters${id}`, JSON.stringify([...filter, rowFilter]))
    } else {
      setFilterList(filter);
      localStorage.setItem(`userFilters${id}`, JSON.stringify(filter))
    }
    setPage(1);
  }

  const removeFilter = (filter) => {
    if ((filter?.type === 'warningFilter' || filter?.type === 'userTypeFilter' || filter?.type === 'enabledFilter') && filterList.some(s => s?.type === filter?.type)) {
      const newFilterList = filterList.filter(f => {
        return (f !== filter);
      });
      setFilterList(newFilterList);
      setIntilityUserType(newFilterList);
      localStorage.setItem(`userFilters${id}`, JSON.stringify(newFilterList))
    } else if (filter.type === 'selectedRows') {
      const newFilterList = filterList.filter(f => {
        return f?.type !== filter?.type;
      });
      setFilterList(newFilterList);
      localStorage.setItem(`userFilters${id}`, JSON.stringify(newFilterList))
      setUserRows(UserRowOptions.slice(0, 11));
      setUserTableRows(UserRowOptions.slice(0, 11));
    } else if (filterList.some(s => s === filter)) {
      const newFilterList = filterList.filter(f => {
        return f !== filter;
      });
      setFilterList(newFilterList);
      localStorage.setItem(`userFilters${id}`, JSON.stringify(newFilterList))
    }
    if (filter?.type === 'intilityUserType') setIntilityUserType([])

  }

  const removeFilters = (filters) => {
    if (!Array.isArray(filters) || filters.length === 0) return;
    let newFilterList = [...filterList];  // Start with the current filter list

    filters.forEach(filter => {
      if (!filter) return;
      if (['warningFilter', 'userTypeFilter', 'enabledFilter'].includes(filter?.type) && newFilterList.some(s => s?.type === filter?.type)) {
        newFilterList = newFilterList.filter(f => f !== filter);
        setIntilityUserType(newFilterList);
      } else if (filter.type === 'selectedRows') {
        newFilterList = newFilterList.filter(f => f?.type !== filter?.type);
        setUserRows(UserRowOptions.slice(0, 11));
        setUserTableRows(UserRowOptions.slice(0, 11));
      } else if (newFilterList.some(s => s === filter)) {
        newFilterList = newFilterList.filter(f => f !== filter);
      }
      if (filter?.type === 'intilityUserType') {
        setIntilityUserType([]);
      }
    });

    setFilterList(newFilterList);
    localStorage.setItem(`userFilters${id}`, JSON.stringify(newFilterList));
  };


  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: userList?.length, label: 'All'});
    return tableOptions;
  };

  const openLicenseModal = (user) => {
    setLicenseUser(user);
    setLicenseModalOpen(true);
  };


  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children
    if (Array.isArray(values)) {
      values = `Selected filters (${values.length})`
    }
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    )
  };

  return (
    <BasicPage id='users' taskType={taskType.USERS}
               isComplete={isComplete}
               fullScreen={fullScreen}
               setIsComplete={toggleUsers} togglePrompt={true}>
      <div className={!fullScreen ? 'content-main' : 'content-main full-screen'}>
        <SimpleBox
          className='users-box'
          title='Users'
          fullScreen={fullScreen}
          >
          {usersStatus.fetching ? (
            <div className="spinner-container">
              <Spinner icon={faSpinner} pulse />
            </div>
          ) : (
            userList?.length > 0 ? [
              statusUsers?.warning > 0 &&
              <div className="missing-info-bar" key={1}>
                <div>
                  <Icon style={{color: 'var(--bfc-alert)', marginRight: 'var(--bfs12)'}} icon={faExclamationTriangle} />
                  <h5>{statusUsers?.warning} user{statusUsers?.warning > 1 && 's'} need{statusUsers?.warning === 1 && 's'} your attention</h5>
                </div>
                <div>
                  <Checkbox label={`Show me the user${statusUsers?.warning > 1 ? 's' : ''}`} checked={missingInfoUsers} onChange={(e) => {
                    setMissingInfoUsers(e.target.checked);
                    if (e.target.checked) {
                      addFilters([{value: UserStatusTypes.Attention, label: 'Attention', displayName: 'User registration status', type: 'warningFilter', warning: true},
                      {value: UserStatusTypes.Warning, label: 'Error', displayName: 'User registration status', type: 'warningFilter', alert: true}])
                    } else {
                      removeFilters(filterList?.filter(f => f?.type === 'warningFilter' && (f.value === UserStatusTypes.Attention || f.value === UserStatusTypes.Warning)));
                    }
                  }}/>
                </div>
              </div>,
              <div key={2} className="users-table bf-scrollbar-small">
                <div className="users-filter-row">
                  <div className="users-filter-row-actions bfl-grid">
                    <FieldGroup className="filter-field-group">
                      <Select
                        label="Items"
                        className="select-group-select"
                        hideLabel={true}
                        options={SearchOptions}
                        value={searchCriterea}
                        isSearchable={false}
                        onChange={item => setSearchCriterea(item)}
                      />
                      {searchCriterea?.value === 'licenses' ?
                        <Select className="select-group-input"
                                placeholder="Search filter"
                                label="search"
                                hideLabel
                                clearable
                                options={licenseOptions}
                                onChange={e => {
                                  setSearchValue(e.value);
                                  addFilter({
                                    type: searchCriterea?.value,
                                    value: e?.value,
                                    displayName: searchCriterea?.label
                                  });
                                }} /> :
                        <Input
                          className="select-group-input"
                          placeholder="Search filter"
                          label="search"
                          hideLabel
                          icon={faSearch}
                          clearable
                          value={searchValue}
                          onChange={e => setSearchValue(e.target.value)}
                          rightIcon
                          onKeyDown={(e) => {
                            e.keyCode === 13 && addFilter({
                              type: searchCriterea?.value,
                              value: searchValue,
                              displayName: searchCriterea?.label
                            });
                          }}
                          onIconClick={() => addFilter({
                            type: searchCriterea?.value,
                            value: searchValue,
                            displayName: searchCriterea?.label
                          })}
                        />
                      }
                    </FieldGroup>
                    <UserValueContainer name='Selected columns ' addFilter={addFilter} removeFilter={removeFilter} setUserRows={setUserRows} setUserTableRows={setUserTableRows} userRows={userRows}/>
                    <Select label="User types" isMulti={true} placeholder="Select filter"
                            hideLabel options={getFilterOptions(information.userTypes)} value={intilityUserType}
                            onChange={item => {
                              addOrChangeFilter(item);
                              setIntilityUserType(item);
                            }} style={{minWidth: '200px'}}
                            name="Filter"
                            components={{ValueContainer}} />
                    <Button variant={'outline'} onClick={() => dispatch(downloadCsv(tabSelected))}><Icon
                      icon={faArrowDownToLine} /> Export</Button>
                    <ButtonWithTooltip filled={true} icon={faPlus} isComplete={isComplete}
                                       btnOnClick={() => addNewUser()}>Add new</ButtonWithTooltip>
                  </div>
                  <div className="users-filter-row-filters bf-neutral-link">
                    {filterList?.length > 0 && filterList?.map((f, i) => {
                      return <Tag
                        key={i}
                        style={f.warning ? {color: 'var(--bfc-base-c-warning)'} : f.alert ? {color: 'var(--bfc-base-c-alert)'} : f.excludes ? {color: 'var(--bfc-base-c-alert)'} : undefined}
                        onClick={() => removeFilter(f)}>{f?.displayName}: {f?.label || f?.value}<Icon
                        style={{marginLeft: 'var(--bfs4)'}}
                        icon={faTimes} /></Tag>;
                    })}
                    {filterList?.length === 0 &&
                      <p className="bf-em" style={{margin: 0, width: '100%'}}>Showing all
                        users, no filter selected - add filter to work
                        more
                        efficient</p>}
                  </div>
                </div>
                {showEditRow &&
                  <div className={animateExit ? 'company-users-edit-row fade-out' : 'company-users-edit-row'}>
                    <div className="company-users-edit-row-dropdowns bfl-grid">
                      <Select value={userTypeSelect} isDisabled={tabSelected !== UserTableType.USERS} isClearable placeholder="User type" onChange={item => {
                        handleBulkSelect({value: item?.value, label: item?.shortLabel, type: item?.type}, item?.type);
                        setUserTypeSelect({value: item?.value, label: item?.shortLabel, type: item?.type});
                      }}
                              options={getIntilityUserDescriptionOptions(information.userTypes)} label="User type"
                              hideLabel style={{gridColumn: 'span 1'}} />
                      <Select value={locationSelect} isDisabled={tabSelected !== UserTableType.USERS} isClearable placeholder="Location" onChange={item => {
                        handleBulkSelect(item, 'locationId');
                        setLocationSelect(item);
                      }} options={selectableLocation} label="Location" hideLabel style={{gridColumn: 'span 1'}} />
                      <Select value={languageSelect} isDisabled={tabSelected !== UserTableType.USERS} isClearable placeholder="Language" onChange={item => {
                        handleBulkSelect(item, 'language');
                        setLanguageSelect(item);
                      }} options={LanguageOptions} label="Language" hideLabel style={{gridColumn: 'span 1'}} />
                      <Select value={countrySelect} isDisabled={tabSelected !== UserTableType.USERS} isClearable placeholder="Country" onChange={item => {
                        handleBulkSelect(item, 'country');
                        setCountrySelect(item);
                      }} options={CountryOptions} label="Country" hideLabel style={{gridColumn: 'span 1'}} />
                      <Select value={billingSelect} isDisabled={tabSelected !== UserTableType.USERS} isClearable placeholder="Billing company" onChange={item => {
                        handleBulkSelect(item, 'billingId');
                        setBillingSelect(item);
                      }} options={selectableBilling} label="Billing company" hideLabel style={{gridColumn: 'span 1'}} />
                      <div>
                        <Checkbox label="Edit all fields" type="switch" disabled={tabSelected !== UserTableType.USERS} button checked={editAllChecked} onChange={(e) => {
                          if (e.target.checked) {
                            setIsEditing(true);
                            setEditAllChecked(true);
                          } else {
                            setIsEditing(false);
                            setEditAllChecked(false);
                          }
                        }} style={{gridColumn: 'span 1'}} />
                      </div>
                    </div>
                    <div className="company-users-edit-row-buttons bfl-grid">
                      <IntilityPermission>
                        <Button disabled={tabSelected !== UserTableType.USERS || (!isAllSelected && anySelected <= 0)} onClick={() => {
                          setIsMovingToOnboarded(true);
                          const date = new Date(Date.now());
                          handleBulkSelect({value: date, label: 'Rot migrated', type: 'rotMigrated'}, 'rotMigrated');
                        }}>
                          Move to Onboarded
                        </Button>
                      </IntilityPermission>
                      <Button state={tabSelected === UserTableType.DELETED ? 'success' : 'alert'} variant={'outline'} disabled={!isAllSelected && anySelected <= 0}
                              onClick={() => {
                                setSaving(true);
                                setBulkReady(true);
                                setDeleting(true);
                              }}><Icon
                        icon={tabSelected === UserTableType.DELETED ? faTrashRestore : faTrashAlt} /> ({isAllSelected ? sortedAndIndexedData?.length : anySelected})</Button>
                      <Button variant='flat' onClick={() => clearBulkEdit()}>
                        Cancel
                      </Button>
                      <Button variant={'basic'}
                              disabled={((isAllSelected === false && anySelected <= 0) || bulkSelectList.length === 0) && ((isAllSelected === false && anySelected <= 0) || editAllChecked === false)}
                              onClick={() => {
                                if (editAllChecked) {
                                  onSave();
                                } else {
                                  handleBulkAction();
                                }
                              }}><Icon icon={faCheck} /> Apply & save
                        ({isAllSelected ? sortedAndIndexedData?.length : anySelected})</Button>
                    </div>
                  </div>
                }
                <Tabs style={{position: 'sticky', left: '0'}}>
                  <Tabs.Item onClick={() => {
                    setTabSelected(UserTableType.USERS);
                    setPage(1);
                    setIsAllSelected(false);
                    setAnySelected(0);
                  }} active={tabSelected === UserTableType.USERS} noPadding content={
                    <div className="users-table-tab-content">
                      <Table noBorder>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>
                              <Checkbox label={'Full name'} hideLabel={true} checked={isAllSelected}
                                        onChange={checkAll} />
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              sorting={getSortProp('primaryEmailAddress')}
                              onClick={onSortChangeCreator('primaryEmailAddress')}
                              style={{position: 'sticky', left: '67.5px', overflow: 'hidden', zIndex: 1, background: 'var(--bfc-base-3)', borderRight: '1px solid var(--bfc-base-dimmed)'}}
                            >
                              Username (UPN)
                            </Table.HeaderCell>
                            <Table.HeaderCell sorting={getSortProp('status')}
                                              onClick={onSortChangeCreator('status')}>
                              Status
                            </Table.HeaderCell>
                            {userTableRows.filter((t, i) => {
                              //if (!isEditing) return t.editable === UserRowEditable.TRUE;
                              if (isEditing && isAllSelected) return t.editable === UserRowEditable.TRUE;
                              if (isEditing) if (t.editable === UserRowEditable.FALSE) return <Table.HeaderCell
                                key={i}
                                sorting={getSortProp(t.value)}
                                onClick={onSortChangeCreator(t.value)}
                              >
                                {t.label}
                              </Table.HeaderCell>
                              return t;
                            }).map((r, i) => {
                              return (
                                <Table.HeaderCell
                                  key={i}
                                  sorting={getSortProp(r.value)}
                                  onClick={onSortChangeCreator(r.value)}
                                >
                                  {r.label}
                                </Table.HeaderCell>
                              );
                            })
                            }
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {userList &&
                            sortedAndIndexedData?.filter(u => (new Date(u?.rotMigrated).getFullYear() === 1 || u?.rotMigrated === null))?.map((user, i) => {
                              return (
                                <UserRow
                                  key={user.id}
                                  index={i}
                                  guideStep={guideStep}
                                  setGuideStep={setGuideStep}
                                  user={user}
                                  isEditing={isEditing}
                                  userEditList={userEditList}
                                  save={saving}
                                  bulk={bulkReady}
                                  managers={managers}
                                  isAllSelected={isAllSelected}
                                  setIsAllSelected={setIsAllSelected}
                                  setAnySelected={setAnySelected}
                                  setIsEditingAll={setIsEditingAll}
                                  anySelected={anySelected}
                                  submited={submitted}
                                  setUserId={setUserId}
                                  setNewUser={editUser}
                                  isComplete={isComplete}
                                  openLicenseModal={openLicenseModal}
                                  userRows={userTableRows}
                                />
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </div>
                  }>
                    Users to be onboarded
                    ({userList?.filter(u => ((new Date(u?.rotMigrated).getFullYear() === 1 || u?.rotMigrated === null) && u?.isEnabled))?.length}) <Tooltip
                    content="Users not yet onboarded"><Icon style={{color: 'var(--bfc-attn)'}}
                                                            icon={faInfoCircle} /></Tooltip>
                  </Tabs.Item>
                  <Tabs.Item disabled={userList?.filter(u => ((new Date(u?.rotMigrated).getFullYear() !== 1 && (new Date(u?.rotMigrated) < new Date(Date.now()))) && u?.isEnabled))?.length === 0} onClick={() => {
                    setTabSelected(UserTableType.ONBOARDED);
                    setPage(1);
                    setIsAllSelected(false);
                    setAnySelected(0);
                  }} active={tabSelected === UserTableType.ONBOARDED} noPadding content={
                    <div className='users-table-tab-content-deleted'>
                    <Table noBorder>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            sorting={getSortProp('primaryEmailAddress')}
                            onClick={onSortChangeCreator('primaryEmailAddress')}
                            style={{position: 'sticky', left: '0', overflow: 'hidden', zIndex: 1, background: 'var(--bfc-base-3)', borderRight: '1px solid var(--bfc-base-dimmed)'}}
                          >
                            Username (UPN)
                          </Table.HeaderCell>
                          <Table.HeaderCell sorting={getSortProp('status')}
                                            onClick={onSortChangeCreator('status')}>
                            Status
                          </Table.HeaderCell>
                          {userTableRows.map((r, i) => {
                            return (
                              <Table.HeaderCell
                                key={i}
                                sorting={getSortProp(r.value)}
                                onClick={onSortChangeCreator(r.value)}
                              >
                                {r.label}
                              </Table.HeaderCell>
                            );
                          })
                          }
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {userList &&
                          sortedAndIndexedData?.filter(u => (new Date(u?.rotMigrated).getFullYear() !== 1 && (new Date(u?.rotMigrated) < new Date(Date.now()))))?.map((user, i) => {
                            return (
                              <DeletedUserRow
                                hideCheckbox={true}
                                key={user.id}
                                index={i}
                                user={user}
                                isComplete={isComplete}
                                setAnySelected={setAnySelected}
                                anySelected={anySelected}
                                userEditList={userEditList}
                                save={saving}
                                bulk={bulkReady}
                                openLicenseModal={openLicenseModal}
                                userRows={userTableRows}
                              />
                            );
                          })}
                      </Table.Body>
                    </Table>
                    </div>
                  }>
                    Onboarded users
                    ({userList?.filter(u => ((new Date(u?.rotMigrated).getFullYear() !== 1 && (new Date(u?.rotMigrated) < new Date(Date.now()))) && u?.isEnabled))?.length}) <Tooltip
                    content="Users in this list has been Onboarded to Intility, further editing will be handled through the Intility portal"><Icon
                    style={{color: 'var(--bfc-attn)'}} icon={faInfoCircle} /></Tooltip>
                  </Tabs.Item>
                  <Tabs.Item disabled={userList?.filter(u => !u?.isEnabled)?.length === 0} onClick={() => {
                    setTabSelected(UserTableType.DELETED);
                    setPage(1);
                    setIsAllSelected(false);
                    setAnySelected(0);
                  }} active={tabSelected === UserTableType.DELETED} noPadding content={
                    <div className='users-table-tab-content-deleted'>
                    <Table noBorder>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Checkbox label={'Full name'} hideLabel={true} checked={isAllSelected}
                                      onChange={checkAll} />
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorting={getSortProp('primaryEmailAddress')}
                            onClick={onSortChangeCreator('primaryEmailAddress')}
                            style={{position: 'sticky', left: '67.5px', overflow: 'hidden', zIndex: 1, background: 'var(--bfc-base-3)', borderRight: '1px solid var(--bfc-base-dimmed)'}}
                          >
                            Username (UPN)
                          </Table.HeaderCell>
                          <Table.HeaderCell sorting={getSortProp('status')}
                                            onClick={onSortChangeCreator('status')}>
                            Status
                          </Table.HeaderCell>
                          {userTableRows.map((r, i) => {
                            return (
                              <Table.HeaderCell
                                key={i}
                                sorting={getSortProp(r.value)}
                                onClick={onSortChangeCreator(r.value)}
                              >
                                {r.label}
                              </Table.HeaderCell>
                            );
                          })
                          }
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {userList &&
                          sortedAndIndexedData.map((user, i) => {
                            return (
                              <DeletedUserRow
                                key={user.id}
                                index={i}
                                user={user}
                                isComplete={isComplete}
                                anySelected={anySelected}
                                setAnySelected={setAnySelected}
                                isAllSelected={isAllSelected}
                                bulk={bulkReady}
                                userEditList={userEditList}
                                save={saving}
                                openLicenseModal={openLicenseModal}
                                userRows={userTableRows}
                              />
                            );
                          })}
                      </Table.Body>
                    </Table>
                    </div>
                  }>
                    Deleted users ({userList?.filter(u => !u?.isEnabled)?.length})
                  </Tabs.Item>
                  <div style={{padding: 'var(--bfs8)', marginLeft: 'auto'}}>
                    <Button small onClick={() => {
                      setFullScreen(!fullScreen);
                      setSideCollapsed(!fullScreen);
                    }}><Icon
                      icon={!fullScreen ? faArrowUpRightAndArrowDownLeftFromCenter : faArrowDownLeftAndArrowUpRightToCenter} /></Button>
                  </div>
                </Tabs>
                <div className="bottom-line" key={2}>
                  <div />
                  {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
                    <Pagination
                      style={{marginTop: 'var(--bfs16)'}}
                      totalPages={Math.ceil(sortedData?.length / pageSize.value)}
                      currentPage={page}
                      onChange={e => {
                        setIsAllSelected(false);
                        setAnySelected(0);
                        setPage(e);
                      }}
                    />
                  ) : (
                    <div />
                  )}
                  <div className="row-box">
                    <p>Show rows</p>
                    <Select
                      key="Items"
                      label="Items"
                      className="select-group-select"
                      hideLabel={true}
                      value={pageSize}
                      options={tableSizeOptions()}
                      onChange={item => {
                        setPage(1);
                        setPageSize(item);
                      }}
                    />
                  </div>
                </div>
              </div>
            ] :
              <EmptyUsersState newUser={newUser} isComplete={isComplete} />
          )}
          <Modal
            isOpen={deleting}
            header={`${tabSelected === UserTableType.DELETED ? 'Restore' : 'Delete'} ${anySelected >= 1 && anySelected} ${anySelected > 1 ? 'users' : 'user'}`}
            onRequestClose={() => {
              setSaving(false);
              setBulkReady(false);
              setDeleting(false);
              setIsEditing(false);
              setActionSelect(null);
            }}
          >
            <p className='bf-content'>
              Are you sure you wish to {tabSelected === UserTableType.DELETED ? 'restore' : 'delete'} the
              selected {anySelected > 1 ? 'users?' : 'user?'}.
            </p>
            <br />
            <div className='users-box-modal'>
              <Button
                onClick={() => {
                  setSaving(false);
                  setBulkReady(false);
                  setDeleting(false);
                  setIsEditing(false);
                  setActionSelect(null);
                }}
              >
                Cancel
              </Button>
              <Button variant={tabSelected === UserTableType.DELETED ? 'filled' : 'outline'}
                      state={tabSelected === UserTableType.DELETED ? 'default' : "alert"}
                      onClick={() => handleBulkDelete()}>
                {tabSelected === UserTableType.DELETED ? 'Restore' : 'Delete'}
              </Button>
            </div>
          </Modal>
          <Modal header='Move users to Onboarded' isOpen={isMovingToOnboarded}>
            <p>Selected users will be moved to Onboarded users section, only use this when user has already been onboarded</p>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button onClick={() => {
                setIsMovingToOnboarded(false);
                clearBulkEdit();
              }}>
                Cancel
              </Button>
              <Button variant={'filled'}
                      onClick={() => {
                        if (editAllChecked) {
                          onSave();
                        } else {
                          handleBulkAction();
                        }
                        setIsMovingToOnboarded(false);
                      }}><Icon icon={faCheck} /> Apply & save
                ({isAllSelected ? sortedAndIndexedData?.length : anySelected})
              </Button>
            </div>
          </Modal>
          <ErrorModal isOpen={errorOpen} onClose={() => dispatch(updateUsersClear())} type={CompanyUserError} />
          <LicenseModal user={licenseUser} setUser={setLicenseUser} modalOpen={licenseModalOpen}
                        setModalOpen={setLicenseModalOpen} />
        </SimpleBox>
      </div>
      <div className={guideStep > 0 ? 'guide-dropdown-overlay' : ''} />
    </BasicPage>
  );
};

UserRegistration.propTypes = {
  newUser: PropTypes.func,
  editUser: PropTypes.func,
  setUserId: PropTypes.func,
  isComplete: PropTypes.bool,
  setIsComplete: PropTypes.func
};

export default UserRegistration;
