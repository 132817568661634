import React, {useState, useEffect} from 'react';
import {Grid, Button, Message, Icon} from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import {GetInternalRole, internalRoleOptions} from '../NewApplication/NewContent/NewContact';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import ContactDetails from './ContactDetails';
import {fetchUsersAsync} from '../../../../../redux/company/users/userThunks';

const EditInternalContact = ({ contact, updateContact, deleteContact, handleCancel}) => {
  const [editedContact, setEditedContact] = useState(contact);
  const [alert, setAlert] = useState(false);
  const {id} = useSelector(state => state.company.data.info);
  const dispatch = useDispatch();
  const [roles, setRoles] = useState(
    contact.contactRoles.map(role => ({
      value: role.contactRole,
      label: GetInternalRole(role.contactRole)
    }))
  );

  const generateOptions = contact => ({
    value: contact.id,
    label: `${contact.firstName || ''} ${contact.lastName || ''}`
  });

  useEffect(() => {
    dispatch(fetchUsersAsync(id));
  }, [dispatch]);

  const {userList} = useSelector(state => state.company.data.userPlatform);
  const existingInternalContacts = userList
    ?.filter(u => u.isEnabled === true && (u.firstName || u.lastName))
    .map(contact => {
      const {firstName, lastName, mobilePhone, primaryEmailAddress} = contact;
      return {
        id: contact.id,
        firstName,
        lastName,
        phoneNumber: mobilePhone,
        email: primaryEmailAddress,
        contactRoles: roles.map(role => ({
          contactRole: role.value
        }))
      };
    });

  const handleSubmit = () => {
    if (editedContact) {
      const {firstName, lastName, phoneNumber, email} = editedContact;
      const newContact = {
        id: editedContact.id,
        firstName,
        lastName,
        phoneNumber: phoneNumber,
        email: email,
        contactRoles: roles.map(role => ({
          contactRole: role.value
        }))
      };
      updateContact(contact.id, newContact);
    } else {
      setAlert(true);
    }
  };

  const handleDelete = () => {
    deleteContact(contact.id);
  };

  return (
    <div className='edit-contact-card'>
      <Grid gap={24} cols={1}>
        <div style={{color: 'var(--bfc-base-c-1)', fontSize: '16px', fontWeight: '600'}}>Add new internal contact</div>
        <Message>
          In order to select contacts from your company, you need to add them to the users list first. If you have not
          done this yet you can skip this step and return later.
        </Message>
        <Select
          state={alert ? 'alert' : undefined}
          feedback={alert ? 'Please select a user' : undefined}
          placeholder='Select user'
          label='Internal contact'
          options={existingInternalContacts.map(generateOptions)}
          value={editedContact ? generateOptions(editedContact) : null}
          onChange={selectedOption => {
            const selected = existingInternalContacts.find(contact => contact.id === selectedOption.value);
            if (selected) {
              setEditedContact(selected);
              setAlert(false);
            }
          }}
        />
        <Select
          isMulti
          placeholder='Select the contact role(s)'
          label='Roles'
          options={internalRoleOptions}
          value={roles}
          onChange={setRoles}
        />
        <div>Contact details</div>
        <ContactDetails contact={editedContact} />
        <Grid small={2} style={{display: 'flex', justifyContent: 'space-between', marginTop: '12px'}}>
          <div>
            <Button pill variant='outline' onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              pill
              variant='outline'
              state='alert'
              style={{alignSelf: 'flex-start', marginLeft: '8px'}}
              onClick={handleDelete}
            >
              <Icon icon={faTrash} />
            </Button>
          </div>
          <Button pill variant='filled' style={{alignSelf: 'flex-end'}} onClick={handleSubmit}>
            Save Contact
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditInternalContact;
