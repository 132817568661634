import './users.scss';
import React, {useState} from 'react';
import UserRegistration from './UserRegistration/UserRegistration';
import AddUser from './AddUser/AddUser';
import EditUser from './UserRegistration/EditUser';

export const Users = () => {
  const [newUser, setNewUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userId, setUserId] = useState(undefined);
  const [isComplete, setIsComplete] = useState(false);

  return newUser ? (
    <AddUser isComplete={isComplete} newUser={setNewUser}/>
  ) : editUser ? (
    <EditUser newUser={setEditUser} currentUserId={userId} setCurrentUserId={setUserId} isComplete={isComplete} />
  ) : (
    <UserRegistration
      newUser={setNewUser}
      editUser={setEditUser}
      setUserId={setUserId}
      isComplete={isComplete}
      setIsComplete={setIsComplete}
    />
  );
};

export default Users;
