import {addListItem, deleteListItem, editListItem} from '../../../utils/hub/normalize';

export const editProjectGlobalTeam = (teamList, team) => {
  const newList = editListItem(teamList, team);
  return {
    projectGlobalTeams: {
      list: newList
    }
  };
};

export const addProjectGlobalTeam = (teamList, team) => {
  const newList = addListItem(teamList, team);
  return {
    projectGlobalTeams: {
      list: newList
    }
  };
};

export const deleteProjectGlobalTeam = (teamList, teamId) => {
  const newList = deleteListItem(teamList, teamId);
  return {
    projectGlobalTeams: {
      list: newList
    }
  };
};

export const addMemberRoles = (roleList, role) => {
  const newList = addListItem(roleList, role);
  return {
    projectGlobalTeams: {
      roles: newList
    }
  };
};

export const normalizeProjectGlobalTeams = detailPlatform => {
  return {
    projectGlobalTeams: {
      list: detailPlatform
    }
  };
};

export const normalizeMemberRoles = detailPlatform => {
  return {
    projectGlobalTeams: {
      roles: detailPlatform
    }
  };
};
