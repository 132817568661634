import { Badge, Button, Checkbox, Dropdown, Icon, Table, Tooltip } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import './iamsRegistrationRow.scss';
import {faLaptop} from '@fortawesome/pro-light-svg-icons';
import React, {useEffect, useState} from 'react';
import { checkMemory, getMemoryBadge } from "./RegisteredContent";
import { faEllipsisH, faExclamationTriangle, faInfoCircle, faPencil } from "@fortawesome/pro-regular-svg-icons";
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import produce from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import { updateIamsUserAsync, updateIamsUserDevicesAsync } from "../../../../../redux/company/iamsUsers/iamsUserThunks";
import IamsRegistrationDatePicker, { IamsRegistrationWarrantyDate } from "./IamsRegistrationDatePicker";
import {fetchUsersAsync} from '../../../../../redux/company/users/userThunks';
import { DeleteScan, EditScan, recommendOptions } from "./IamsSelectOptions";
import ReplacementDropdown from "./ReplacementDropdown";
import { compareIsEmptyOrNullGuid, compareValues } from "../../../../../utils/hub/isEmptyOrNull";
import IamsUserType from "./IamsUserTypeOptions";
import CheckUserDeleted, { CheckUserDeletedById } from "../../../../../utils/hub/checkUserDeleted";

export const IamsRegistrationRow = ({user, intilityUser, setEditUser, setNumberSelected, numberSelected, userEditList, setUserEditList, submited, isComplete, editComment, isAllSelected, saving, bulk, expandAll}) => {
  const {info} = useSelector(state => state.company.data);
  const {iamsUserList} = useSelector(state => state.company.data.iamsUserPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const [editable, setEditable] = useState(undefined);
  const [edited, setEdited] = useState(false);
  const [editedDevices, setEditedDevices] = useState(false);
  const [selected, setSelected] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const notRegistered = 'Not registered';
  const dispatch = useDispatch();

  useEffect(() => {
    const iamsUser = iamsUserList.find(i => i.id === user.iamsUser.id);
    if (iamsUser) setEditable(iamsUser);
  }, [user.iamsUser, iamsUserList, submited])


  useEffect(() => {
    if (saving && (edited || (bulk && selected))) {
      // setUserEditList([...userEditList, editable]);
      userEditList.splice(0, 0, editable);
    }
  }, [saving, editable, edited, bulk, selected]);

  useEffect(() => {
    setSelected(isAllSelected);
  }, [isAllSelected]);

  useEffect(() => {
    if (numberSelected === 0 && !isAllSelected) {
      setSelected(false);
      setEdited(false);
    }
  }, [numberSelected, isAllSelected]);

  useEffect(() => {
    if (submited) {
      setSelected(false);
    }
  }, [submited]);

  useEffect(() => {
    if (!expandAll) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [expandAll]);

  useEffect(() => {
    if (!selected) {
      const index = userEditList.indexOf(editable);
      if (index > -1) {
        const test = userEditList.splice(index, 1);
        setUserEditList(test);
      }
    }
  }, [selected]);

  useEffect(() => {
    if (edited) {
      dispatch(updateIamsUserAsync(editable)).then(() => dispatch(fetchUsersAsync(info.id)));
      setEdited(false);
    }
  }, [edited]);

  useEffect(() => {
    if (editedDevices) {
      dispatch(updateIamsUserDevicesAsync(editable)).then(() => dispatch(fetchUsersAsync(info.id)));
      setEditedDevices(false);
    }
  }, [editedDevices]);

  const changeSelection = e => {
    const {checked} = e.target;
    if (checked) {
      setNumberSelected(i => i + 1);
    } else {
      setNumberSelected(i => i - 1);
    }
    setSelected(checked);
  };

  const updateSelect = item => {
    if (item.value === DeleteScan) {
      setEditable(
        produce(draft => {
          draft.isEnabled = false;
          draft.companyUserId = null;
          draft.companyUser = null;
        })
      );
      setEdited(true);
    } else if (item.value === EditScan) {
      setEditUser(user);
    } else {
      setEditable(
        produce(draft => {
          draft.computerAction = item.value;
        })
      );
      setEdited(true);
    }
  };

  const getRecommendation = recommendation => {
    const [updatedRec, setUpdatedRec] = useState({label: recommendation, value: recommendation});
    const [editing, setEditing] = useState(false);

    const save = option => {
      if (!intilityUser) return;
      setEdited(true);
      setUpdatedRec(option);
      setEditable(
        produce(draft => {
          draft.intilityRecommendation = option?.value;
        })
      );
      setEditing(false);
    };

    const UserBadge = () => {
      if (recommendation?.startsWith('Awaiting'))
        return (
          <Badge state='neutral'>{recommendation}</Badge>
        );
      if (recommendation?.startsWith('Replace'))
        return (
          <Badge state='warning'>{recommendation}</Badge>
        );
      if (recommendation?.startsWith('Keep'))
        return 'Keep computer';
      return (
        <Badge state='warning'>{recommendation}</Badge>
      );
    };

    const IntilityBadge = () => {
      if (recommendation?.startsWith('Awaiting'))
        return (
          <Button
            className='button-edit-row-neutral'
            variant={'basic'}
            state={'default'}
            onClick={() => setEditing(true)}
            small={true}
            disabled={isComplete}
          >
            {recommendation}
          </Button>
        );
      if (recommendation?.startsWith('Replace'))
        return (
          <Button
            className='button-edit-row-bad'
            variant={'basic'}
            state={'alert'}
            icon={faExclamationTriangle}
            onClick={() => setEditing(true)}
            small={true}
            disabled={isComplete}
          >
            {recommendation}
          </Button>
        );
      if (recommendation?.startsWith('Keep'))
        return (
          <Button
            className='button-edit-row-good'
            variant={'basic'}
            icon={faCheck}
            onClick={() => setEditing(true)}
            small={true}
            disabled={isComplete}
          >
            {recommendation}
          </Button>
        );
      return (
        <Button
          className='button-edit-row-warn'
          variant={'basic'}
          icon={faInfoCircle}
          onClick={() => setEditing(true)}
          small={true}
          disabled={isComplete}
        >
          {recommendation}
        </Button>
      );
    };

    return editing ? (
      <div className='os-edit-row'>
        <Select
          label={'OS picker'}
          hideLabel={true}
          onMenuClose={() => setEditing(false)}
          onChange={o => save(o)}
          options={recommendOptions}
          value={updatedRec}
        />
      </div>
    ) : intilityUser ? (
      IntilityBadge()
    ) : (
      UserBadge()
    );
  };


  const getName = user => {
    if (user.iamsUser?.userType === IamsUserType.SharedUser || user.iamsUser.displayName === '(Shared)') {
      return <Badge>Shared</Badge>;
    } else if (user.iamsUser?.userType === IamsUserType.MacUser) {
      return <Badge state={'neutral'}>Mac</Badge>;
    } else {
      return (
          <span style={{color: 'var(--bfc-base-c-2)'}}>
          {`(${user.number + 1}/${user.total} `}
            <Icon icon={faLaptop} />)
          </span>
        );
    }
  };

  const savePriority = option => {
    setEdited(true);
    setEditable(
      produce(draft => {
        draft.priority = option?.checked;
      })
    );
  };


  return (
    <Table.Row
      className='iams-table-row'
      content={
        <div className='iams-row'>
          <div className='bfl-grid iams-grid'>
            <div>
              <p className='bf-strong'>User information:</p>
              <div>
                <p>{compareValues((user.iamsUser?.firstName || '' + user.iamsUser?.lastName || ''), (user.companyUser?.firstName || '' + user.companyUser?.lastName || '')) ?
                  user.iamsUser?.firstName + ' ' + user.iamsUser?.lastName :
                  <Tooltip content='Mismatch between scanned user name(s) and users name(s), edit row (...) to address.'>
                    <span style={{color: 'var(--bfc-alert)'}}>{user.iamsUser?.firstName + ' ' + user.iamsUser?.lastName || 'Mismatch'}</span>
                  </Tooltip>
                }
                </p>
                <p>{compareValues(user.iamsUser?.phoneNumber, user.companyUser?.mobilePhone) ?
                  user.iamsUser?.phoneNumber || notRegistered :
                  <Tooltip content='Mismatch between scanned phone number and users phone number, edit row (...) to address.'>
                    <span style={{color: 'var(--bfc-alert)'}}>{user.iamsUser?.phoneNumber || user.companyUser?.mobilePhone || 'Mismatch'}</span>
                  </Tooltip>
                  }
                </p>
                {compareValues(user.iamsUser?.email, user.companyUser?.primaryEmailAddress) ?
                  <a className='bf-link' href={`mailto:${user.iamsUser?.email}`}>
                    {user.iamsUser?.email || notRegistered}
                  </a> :
                  <Tooltip content='Mismatch between scanned email and users email, edit row (...) to address.'>
                    <span style={{color: 'var(--bfc-alert)'}}>{user.iamsUser?.email || user.companyUser?.primaryEmailAddress || 'Mismatch'}</span>
                  </Tooltip>
                }
              </div>
            </div>
            <div>
              <p className='bf-strong'>Computer name:</p>
              <p>{user.iamsUser?.computerName || notRegistered}</p>
            </div>
            <div>
              <p className='bf-strong'>Memory state:</p>
              <span className='bf-p'>{getMemoryBadge(checkMemory(user.iamsUser?.memory))}</span>
            </div>
            <div>
              <p className='bf-strong'>Processor:</p>
              <p>{user.iamsUser?.prosessor}</p>
            </div>
            <div>
              <p className='bf-strong'>Serial number:</p>
              <p>{user.iamsUser?.serial || notRegistered}</p>
            </div>
            <div>
              <p className='bf-strong'>OS:</p>
              <p>{user.iamsUser?.operatingSystem || notRegistered}</p>
            </div>
            <div>
              <p className='bf-strong'>Warranty:</p>
              <span className='bf-p'>
                <IamsRegistrationDatePicker date={user?.warrantyCheck} setSaved={setEdited} setValue={setEditable}/>
              </span>
            </div>
            <div>
              <p className='bf-strong'>WarrantyStartDate:</p>
              <span className='bf-p'><IamsRegistrationWarrantyDate date={user.iamsUser?.warrantyStartDate}/></span>
            </div>
            <div>
              <p className='bf-strong'>Automatic recommendation:</p>
              <span className='bf-p'>{recommendOptions.find(r => r.value === user.iamsUser?.recommendation)?.label || user.iamsUser?.recommendation}</span>
            </div>
          </div>
        </div>
      }
      open={expanded}
      limitExpandClick
      onOpenChange={() => setExpanded(!expanded)}
    >
      <Table.Cell>
        <div className='iams-user-row-first-cell'>
          <Checkbox
            id={`row_${user.iamsUser?.id}`}
            label={`${user.iamsUser?.firstName || ''} ${user.iamsUser?.lastName || ''}`}
            checked={selected}
            onChange={changeSelection}
          />
          {getName(user)}
          <CheckUserDeletedById id={user.companyUser?.id}/>
        </div>
      </Table.Cell>
      <Table.Cell>
        {
          (user.iamsUser?.locationId !== user.companyUser?.locationId && !compareIsEmptyOrNullGuid(user.iamsUser?.locationId, user.companyUser?.locationId)) && user.iamsUser.displayName !== '(Shared)' ?
            <Tooltip content='Mismatch between scanned location and users location, edit row (...) to address.'>
              <span style={{color: 'var(--bfc-alert)'}}>{locationList?.find(location => location?.id === user.iamsUser?.locationId)?.name || 'Mismatch'}</span>
            </Tooltip> :
          locationList?.find(location => location?.id === user.iamsUser?.locationId)?.name || '-'
        }
      </Table.Cell>
      {(info?.id === '260cccd3-d982-4708-c6e0-08dae3f8f162' || info?.id === '2233e8d5-b5d2-47eb-74b7-08db090ab058') &&
        <Table.Cell>{user.companyUser?.state || '-'}</Table.Cell>
      }
      <Table.Cell>{user.iamsUser?.model || '-'}</Table.Cell>
      <Table.Cell>
        <span className='bf-p'>{user.iamsUser?.comment || ''} <Icon icon={faPencil} style={{cursor: 'pointer'}} onClick={() => editComment(user.iamsUser)} /></span>
      </Table.Cell>
      <Table.Cell>
        {user.companyUser?.comment || ''}
      </Table.Cell>
      <Table.Cell><Checkbox onChange={(e) => savePriority(e.target)} label='Priority' checked={user.iamsUser?.priority} hideLabel/></Table.Cell>
      <Table.Cell>{getRecommendation(user.iamsUser?.intilityRecommendation)}</Table.Cell>
      <Table.Cell>
        <ReplacementDropdown userId={user?.iamsUser?.id} isComplete={isComplete}/>
      </Table.Cell>
      <Table.Cell>
        <Dropdown noPadding disabled={isComplete} content={
          <div style={{paddingLeft: 'var(--bfs12)'}}>
            {user.iamsUser.displayName !== '(Shared)' &&
              <p className='bf-link' onClick={() => updateSelect({ value: 'edit', label: 'Edit scan' })}>Edit scan</p>
            }
            <p className='bf-link' style={{color: 'var(--bfc-alert)'}} onClick={() => updateSelect({ value: 'delete', label: 'Delete scan' })}>Delete scan</p>
          </div>
        }>
          <Icon icon={faEllipsisH} style={isComplete ? { color: "grey", cursor: 'pointer'} : {cursor: 'pointer'}}/>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  );
};

IamsRegistrationRow.propTypes = {
  user: PropTypes.object,
  intilityUser: PropTypes.bool,
  setEditUser: PropTypes.func,
  setNumberSelected: PropTypes.func,
  numberSelected: PropTypes.number,
  userEditList: PropTypes.array,
  setUserEditList: PropTypes.func,
  submited: PropTypes.bool,
  isComplete: PropTypes.bool,
  editComment: PropTypes.func,
  isAllSelected: PropTypes.bool,
  saving: PropTypes.bool,
  bulk: PropTypes.bool,
  expandAll: PropTypes.bool,
};
