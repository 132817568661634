import React, {useEffect, useState, useRef} from 'react';
import { Button, Checkbox, FieldGroup, Icon, Input, Modal } from "@intility/bifrost-react";
import DatePicker from "@intility/bifrost-react-datepicker";
import './editComponent.scss';
import {useDispatch, useSelector} from 'react-redux';
import {fetchDomainsAsync} from '../../../redux/company/domain/domainThunks';
import produce from 'immer';
import {departmentType, Detail, detailType} from '../../../utils/hub/models';
import {multiPutAsync} from '../../../redux/company/details/detailThunks';
import { platformType, updateInfoPlatformAsync, updatePlatformAsync } from "../../../redux/company/platformUpdateThunk";
import {faUndo} from '@fortawesome/pro-regular-svg-icons';
import { emptyGuid } from "../../../utils/guid";
import Flex from "../../Flex/Flex";
import apiFetch from "../../../utils/apiFetch";

export const EditInformation = () => {
  const {id, companyServiceId, name} = useSelector(state => state.company.data.info);
  const {domainList, information} = useSelector(state => state.company.data.domainPlatform);
  const {detailList, information: detailsInformation} = useSelector(state => state.company.data.detailsPlatform);
  const [primaryDomain, setPrimaryDomain] = useState('');
  const [editName, setEditName] = useState('');
  const [contractLink, setContractLink] = useState(new Detail());
  const [details, setDetails] = useState(undefined);
  const [edited, setEdited] = useState(false);
  const [companyService, setCompanyService] = useState(false);
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [searchCompany, setSearchCompany] = useState({});
  const dispatch = useDispatch();

  const useUnload = fn => {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
      cb.current = fn;
    }, [fn]);

    useEffect(() => {
      const onUnload = (...args) => cb.current?.(...args);

      window.addEventListener('beforeunload', onUnload);

      return () => window.removeEventListener('beforeunload', onUnload);
    }, []);
  };

  useEffect(() => {
    if (!id) return;
    dispatch(fetchDomainsAsync(id));
  }, [id]);

  useEffect(() => {
    const domain = domainList?.find(i => i.id === information?.primaryId);
    setPrimaryDomain(domain);
  }, [domainList, information]);

  useEffect(() => {
    setContractLink(getDetail('ContractLink'));
  }, [detailList]);

  useEffect(() => {
    setDetails(detailsInformation);
  }, [detailsInformation]);

  useEffect(() => {
    setEditName(name);
  }, [name]);

  const onChange = (value, name, setItem, currentItem, type) => {
    const newValue = produce(currentItem, draft => {
      draft.name = name;
      draft[type] = value;
      draft.valueType = type;
      draft.department = departmentType.GENERAL;
    });

    const i = itemsToEdit.findIndex(i => i.name === name);
    if (i > -1)
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    else
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    setItem(newValue);
  };

  const onPlatformChange = (value, name) => {
    setEdited(true);
    setDetails(
      produce(draft => {
        if (name.endsWith('Date')) {
          draft[name] = new Date(value?.setHours(12));
        } else {
          draft[name] = value;
        }

      })
    );
  };

  const onCodeChange = (value) => {

    if (value.length > 1) {
      apiFetch(`/companies/${id}/companyService/search?code=${value}`)
        .then(response => {
          if (!response.ok) {
            setSearchCompany({});
            return {};
          }
          return response.json()
        })
        .then(company => {
          setSearchCompany(company);
        })
    }
    setEdited(true);
    setDetails(
      produce(draft => {
        draft.companyCode = value;
      })
    );
  };

  const saveDetails = (force) => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutAsync(itemsToEdit));
    }
    if (force === true) {
      dispatch(
        updateInfoPlatformAsync(platformType.DETAILS, details.companyId, {
          isEnabled: details.isEnabled,
          projectTeam: details.projectTeam,
          rotLink: details.rotLink,
          csModule: details.csModule,
          vismaCompany: details.vismaCompany,
          parentCompany: details.parentCompany,
          goLiveDate: details.goLiveDate,
          goLiveConfirmed: details.goLiveConfirmed,
          testingDueDate: details.testingDueDate,
          planningDueDate: details.planningDueDate,
          preparationDueDate: details.preparationDueDate,
          companyCode: details.companyCode,
          companyServiceName: editName
        })
      );
    } else if (edited && details.companyCode?.length > 0 && details.companyCode !== detailsInformation.companyCode) {
      setCompanyService(true);
      setEditName(name);
      return;
    } else if (edited) {
      dispatch(
        updatePlatformAsync(platformType.DETAILS, details.companyId, {
          isEnabled: details.isEnabled,
          projectTeam: details.projectTeam,
          rotLink: details.rotLink,
          csModule: details.csModule,
          vismaCompany: details.vismaCompany,
          parentCompany: details.parentCompany,
          goLiveDate: details.goLiveDate,
          goLiveConfirmed: details.goLiveConfirmed,
          testingDueDate: details.testingDueDate,
          planningDueDate: details.planningDueDate,
          preparationDueDate: details.preparationDueDate,
          companyCode: details.companyCode
        })
      );
    }
    resetValues();
  };

  const resetValues = () => {
    setContractLink(getDetail('ContractLink'));
    setDetails(detailsInformation);
    setEdited(false);
    setItemsToEdit([]);
    setCompanyService(false);
    setEditName(name);
    setSearchCompany({});
  };

  const getDetail = name => {
    return detailList?.filter(d => d.department === departmentType.GENERAL)?.find(i => i.name === name) || new Detail();
  };

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  });

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Main information</h3>
        <div className='backoffice-button-group'>
          {(itemsToEdit.length > 0 || edited === true) && (
              <span onClick={() => resetValues()} className='icon-text bf-link'>
                <Icon icon={faUndo} />
                <span className='bf-p'> Undo changes</span>
              </span>
          )}
          <Button variant={'filled'} disabled={itemsToEdit.length === 0 && !edited} onClick={() => saveDetails(false)}>
            Save
          </Button>
        </div>
      </div>
      <div className='bfl-autocol'>
        <DatePicker
          label='Planning date'
          selected={
            details?.planningDueDate
              ? new Date(details?.planningDueDate).getFullYear() === 1
                ? null
                : new Date(details?.planningDueDate)
              : null
          }
          onChange={e => onPlatformChange(e, 'planningDueDate')}
        />
        <DatePicker
          label='Preparation date'
          selected={
            details?.preparationDueDate
              ? new Date(details?.preparationDueDate).getFullYear() === 1
                ? null
                : new Date(details?.preparationDueDate)
              : null
          }
          onChange={e => onPlatformChange(e, 'preparationDueDate')}
        />
        <DatePicker
          label='Testing date'
          selected={
            details?.testingDueDate
              ? new Date(details?.testingDueDate).getFullYear() === 1
                ? null
                : new Date(details?.testingDueDate)
              : null
          }
          onChange={e => onPlatformChange(e, 'testingDueDate')}
        />
        <div>
          <DatePicker
            label='Go live date'
            selected={
              details?.goLiveDate
                ? new Date(details?.goLiveDate).getFullYear() === 1
                  ? null
                  : new Date(details?.goLiveDate)
                : null
            }
            disabled={details?.goLiveConfirmed}
            onChange={e => onPlatformChange(e, 'goLiveDate')}
          />
          <Checkbox label='Confirm date'
                    checked={details?.goLiveConfirmed}
                    onChange={e => onPlatformChange(e.target.checked, 'goLiveConfirmed')}
          />
        </div>
        <Input disabled={true} label='Main domain' value={primaryDomain?.name || ''} />
        <Input
          label='Project teams'
          placeholder='https://teams.microsoft.com/l/channel/link'
          value={details?.projectTeam || ''}
          onChange={e => onPlatformChange(e.target.value, 'projectTeam')}
        />
        <FieldGroup label='Link to contract'>
          <FieldGroup.Item>URL:</FieldGroup.Item>
          <Input
            label='Link to contract input'
            hideLabel={true}
            value={contractLink?.stringValue}
            onChange={e => onChange(e.target.value, 'ContractLink', setContractLink, contractLink, detailType.STRING)}
          />
        </FieldGroup>
        <Input
          label='Existing customer'
          placeholder='Name of parent company (if any)'
          value={details?.parentCompany || ''}
          onChange={e => onPlatformChange(e.target.value, 'parentCompany')}
        />
        <Input
          label='Company code'
          value={details?.companyCode || ''}
          disabled={(companyServiceId !== emptyGuid && companyServiceId !== null)}
          onChange={e => onCodeChange(e.target.value)}
          {...((companyServiceId !== emptyGuid && companyServiceId !== null) && { description: "Company connected to CompanyService" })}
        />
      </div>
      <Modal header='Review CompanyService fields' isOpen={companyService} onRequestClose={() => resetValues()}>
        <p>On save, a new Company will be created in CompanyService, <br/>if company <u>already exists</u> they will be connected using this Company code and use its <u>existing</u> name.</p>
        <p className='bf-strong'>Review fields to ensure correct code and name in CompanyService</p>
        <Flex flex={[1, 1]}>
          <Input
            label='Company code'
            value={details?.companyCode || ''}
            onChange={e => onCodeChange(e.target.value)}
          />
          <Input
            label={searchCompany?.id ? "Existing Company name" : "Company name"}
            value={searchCompany?.name ? searchCompany?.name : (editName || '')}
            disabled={searchCompany?.name?.length > 0}
            onChange={e => setEditName(e.target.value)}
          />
        </Flex>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 'var(--bfs24)'}}>
          <Button onClick={() => resetValues()}>Cancel</Button>
          <Button variant={"filled"} onClick={() => saveDetails(true)}>{searchCompany?.id ? "Connect" : "Save"}</Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditInformation;
