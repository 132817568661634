import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './documentation.scss';
import { SimpleBox } from "../../../Box/Box";
import BasicPage from '../BasicPage';
import {toggleService} from '../../../../redux/company/companyThunks';
import {Button, Icon, TextArea} from '@intility/bifrost-react';
import {
  deleteDocumentationAsync,
  fetchDocumentationAsync,
  patchDocumentationPlatformAsync,
  uploadDocumentationAsync
} from '../../../../redux/company/documentation/documentationThunks';
import SimpleFileUploader from '../../../FileUploader/SimpleFileUploader';
import produce from 'immer';
import {faTrashAlt} from '@fortawesome/pro-light-svg-icons';
import {DocumentationError, ErrorDropdown} from '../Users/ErrorModal/ErrorModal';
import {updateDocumentationClear} from '../../../../redux/company/documentation/documentationReducers';
import {platformType} from '../../../../redux/company/platformUpdateThunk';
import { fetchApplicationsAsync } from "../../../../redux/company/applications/applicationThunks";
import { faFileAlt, faFileImage, faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { downloadFile } from "../../../../utils/downloadFile";
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";
import { fetchLocationsAsync } from "../../../../redux/company/locations/locationThunks";

export const Documentation = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {information, documentList, documentationStatus} = useSelector(
    state => state.company.data.documentationPlatform
  );
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const [commentState, setCommentState] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [savedContent, setSavedContent] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const {user} = useSelector(state => state.auth);
  const [appDocList, setAppDocList] = useState([]);
  const [locationDocList, setLocationDocList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (information.comment !== null) {
      setCommentState(information.comment);
    }
    setIsComplete(!information.isEnabled);
  }, [information]);

  useEffect(() => {
    setErrorOpen(documentationStatus.uploadError);
  }, [documentationStatus.uploadError]);

  const toggleDocumentation = value => {
    setIsComplete(value);
    dispatch(toggleService(platformType.DOCUMENTATION, isComplete));
  };

  // fetch documentation
  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchDocumentationAsync(id));
    dispatch(fetchApplicationsAsync(id));
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    if (documentList.length > 0 && applicationList.length > 0) {
      const list = { };
      documentList.filter(d => d.origin === 1).forEach(doc => {
        applicationList.forEach(app => {
          if (doc.bucketName.startsWith(app.id)) {
            list[app.name] = ({documents: [...list[app.name]?.documents || [], { appId: app.id, ...doc }]})
          }
        })
      })
      setAppDocList(list);
    }
  }, [applicationList, documentList])

  useEffect(() => {
    if (locationList?.length > 0) {
      const list = { };
      locationList.forEach(location => {
        if (location?.locationSystemDocumentations?.length > 0 || location?.floorPlans.length > 0) {
          list[location.name] = ({documents: [...location?.locationSystemDocumentations, ...location?.floorPlans], locationId: location.id})
        }
      })
      setLocationDocList(list);
    }
  }, [locationList]);

  const getPlanIcon = (name) => {
    const ending = name?.substring(name.length - 5, name.length)?.toLowerCase()?.split('.')[1];

    if (ending === 'pdf') {
      return faFilePdf;
    } else if (ending === 'jpg' || ending === 'png') {
      return faFileImage
    } else {
      return faFileAlt
    }
  }

  const getAppDocs = () => {
    const documentation = []
    for (const [key, value] of Object.entries(appDocList)) {
      documentation.push(
        <div key={key} className='documentation-app-entry'>
          <span>
            <p className='bf-strong'>{key}</p>
            <Link className='bf-p' to={'applications/' + value?.documents[0]?.appId}>
              Go to application
            </Link>
          </span>
          <div>
            {value.documents?.map((document, i) => {
              const fileNameRegex = /\/(.*)/i;
              const fileName = document.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'
              return (
                <span key={document.id + i}>
                  <Icon icon={getPlanIcon(document?.objectName)} />
                  <p className='documentation bf-link' onClick={() => downloadFile(document, `/companies/${id}/documentation/${document.id}/file`)}>
                    {fileName}
                  </p>
                </span>
              );
            })}
          </div>
        </div>
      );
    }
    return documentation.length > 0 ? documentation : [<p key={0} className='bf-em'>No application documents uploaded</p>];
  }

  const getLocationDocs = () => {
    const documentation = []
    for (const [key, value] of Object.entries(locationDocList)) {
      documentation.push(
        <div key={key} className='documentation-app-entry'>
          <span>
            <p className='bf-strong'>{key}</p>
            <Link className='bf-p' to={'locations/' + value?.documents[0]?.locationId}>
              Go to location
            </Link>
          </span>
          <div>
            {value?.documents?.map((document, i) => {
              const fileNameRegex = /\/(.*)/i;
              const fileName = document?.objectName?.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'
              return (
                <span key={document?.id + i}>
                  <Icon icon={getPlanIcon(document?.objectName)} />
                  <p className='documentation bf-link' onClick={() => {
                    if (document?.systemType === 0) {
                      downloadFile(document, `/companies/${id}/locations/${document?.locationId}/file/${document?.id}`)
                    } else {
                      downloadFile(document, `/companies/${id}/locations/${document?.locationId}/floorPlans/${fileName}`)
                    }
                  }}>
                    {fileName}
                  </p>
                </span>
              );
            })}
          </div>
        </div>
      );
    }
    return documentation.length > 0 ? documentation : [<p key={0} className='bf-em'>No location documents uploaded</p>];
  }

  const updateChange = e => {
    setCommentState(e.target.value);
    setSaveEnabled(true);
    setSavedContent(false);
  };

  const saveChanges = state => {
    const newStuff = produce(information, draft => {
      draft.comment = state;
    });
    dispatch(patchDocumentationPlatformAsync(newStuff));
    setSavedContent(true);
    setSaveEnabled(false);
  };

  return (
    <BasicPage id='documentation' taskType={taskType.DOCUMENTATION}
               isComplete={isComplete}
               setIsComplete={toggleDocumentation}>
      <div className='content-main'>
        <SimpleBox
          title='Documentation'
          className='documentation-box'
        >
          <div className='note-content'>
            <TextArea
              label='Add a comment'
              optional={true}
              value={commentState}
              placeholder='Type additional information here...'
              onChange={updateChange}
              disabled={isComplete}
            />
          </div>
          <div className='box-edit'>
            {saveEnabled && (
              <p style={{color: 'var(--bfc-alert)'}}>
                unsaved changes
              </p>
            )}
            {savedContent && (
              <p style={{color: 'var(--bfc-base-c-success)'}}>
                Saved
              </p>
            )}
            <Button variant='filled' disabled={!saveEnabled} onClick={() => saveChanges(commentState)}>
              Save comment
            </Button>
          </div>
          <div className='documentation-box-files'>
            {!isComplete && (
              <SimpleFileUploader
                onChange={e => {
                  dispatch(uploadDocumentationAsync(information.id, e.target.value));
                }}
              />
            )}
            {documentList?.length > 0 ? (
              documentList.filter(o => o.origin === 0).map((document, i) => {
                const fileNameRegex = /\/(.*)/i;
                const fileName = document.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'

                return (
                  <span key={i + document.id}>
                    <p className='documentation bf-link' onClick={() => downloadFile(document, `/companies/${id}/documentation/${document.id}/file`)}>
                      {fileName}
                    </p>
                    <Icon icon={faTrashAlt} onClick={() => dispatch(deleteDocumentationAsync(document.id))} />
                  </span>
                );
              })
            ) : (
              <p style={{color: 'var(--bfc-base-c-2)'}}>
                No documentation uploaded
              </p>
            )}
          </div>
          <div className='documentation-app-container'>
            <h3>Application documentation</h3>
            <p style={{color: 'var(--bfc-base-c-2)'}}>Documents uploaded in System & Services - Applications.</p>
            {getAppDocs()?.map(a => a)}
          </div>
          <div className='documentation-app-container'>
            <h3>Location documentation</h3>
            <p style={{color: 'var(--bfc-base-c-2)'}}>Documents and floorplans uploaded in Locations.</p>
            {getLocationDocs()?.map(a => a)}
          </div>
        </SimpleBox>
      </div>
      <ErrorDropdown
        isOpen={errorOpen}
        onRequestClose={() => dispatch(updateDocumentationClear())}
        type={DocumentationError}
      />
    </BasicPage>
  );
};

export default Documentation;
