import {Button, Icon, Input, Modal} from '@intility/bifrost-react';
import { faBox, faLock } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import './editPrinterInfoModal.scss';
import { useEffect, useState } from "react";
import produce from "immer";
import { updatePrinterPlatform } from "../../../../redux/company/printers/printerThunks";

export const SecurePrinting = {
  SecurePrinting: 'secure',
  OperatingSupplier: 'supplier'
}

const EditPrinterInfoModal = ({isOpen, setIsOpen, infoType}) => {
  const {information} = useSelector(state => state.company.data.printerPlatform);
  const [editableInfo, setEditableInfo] = useState(information);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditableInfo(information);
  }, [isOpen])

  const saveInformation = () => {
    dispatch(updatePrinterPlatform(editableInfo));
    setIsOpen(false);
  }

  const onChange = (e) => {
    const {value, name} = e.target;

    setEditableInfo(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  return (
    <Modal id='printer-modal' isOpen={isOpen}
           onRequestClose={() => setIsOpen(false)}
           header={infoType === SecurePrinting.OperatingSupplier ?
             <><Icon icon={faBox}/>Current operating supplier</> :
             <><Icon icon={faLock}/>Current secure print</>}>
      {infoType === SecurePrinting.OperatingSupplier ?
        <div className='body'>
          <Input label='Supplier' value={editableInfo.supplierName} name='supplierName' onChange={e => onChange(e)}/>
          <div className='bfl-grid'>
            <Input label='E-mail' value={editableInfo.supplierEmail} name='supplierEmail' onChange={e => onChange(e)}/>
            <Input label='Phone number' value={editableInfo.supplierPhoneNumber} name='supplierPhoneNumber' onChange={e => onChange(e)}/>
          </div>
        </div> :
        <div className='body'>
          <div className='bfl-grid'>
            <Input label='Secure print type' value={editableInfo.securePrintType} name='securePrintType' onChange={e => onChange(e)}/>
            <Input label='Supplier' value={editableInfo.securePrintSupplier} name='securePrintSupplier' onChange={e => onChange(e)}/>
          </div>
        </div>
      }
      <div className='button-row'>
        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
        <Button onClick={() => saveInformation()} variant={"filled"}>Save</Button>
      </div>
    </Modal>
  )

}

export default EditPrinterInfoModal