import {Domains} from '../../../components/Hub/Pages';
import pluralize from '../../../utils/pluralize';
import {faBuilding} from '@fortawesome/pro-solid-svg-icons';

export default {
  title: 'Domains',
  path: 'domains',
  icon: faBuilding,
  isEnabled: (company) => {return company?.domainPlatform?.state },
  component: Domains,
  getStatus: company => {
    if (company.domainPlatform.domainCount > 0) {
      return '';
    }
    return 'error';
  },
  getStatusText: (status, company) => {
    if (status === 'error') {
      return 'No domains registered';
    }

    return `${company.domainPlatform.domainCount} ${pluralize(
      'domain',
      company.domainPlatform.information.domainCount
    )} registered`;
  }
};
