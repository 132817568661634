import { Checkbox, CheckboxCard, Icon } from "@intility/bifrost-react";
import AppStepCard from "../AppStepCard";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import activeDirectory from "../../../../../../assets/images/application/activeDirectory.svg";
import azureAd from "../../../../../../assets/images/application/azureAd.svg";
import produce from "immer";

export const StepFive = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, totalSteps, setIsOpen}) => {
  const [notRelevant, setNotRelevant] = useState(appObject.loginNotRelevant || false);

  const onNotRelevant = () => {
    setAppObject(
      produce(draft => {
        draft.activeDirectory = false;
        draft.azureAd = false;
        draft.appSpecificUser = false;
        draft.loginNotRelevant = !notRelevant;
      })
    );
    setNotRelevant(!notRelevant);
  }

  const onChange = (e, name) => {
    const {checked} = e.target;
    setAppObject(
      produce(draft => {
        if (name === 'azureAd' || name === 'activeDirectory') {
          draft.singleSignOnToday = checked ? 'Yes' : 'No';
        }
        draft[name] = checked;
      })
    );
  };

  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} stepReady={true} header='What kind of identity do you use to log in to the application?' totalSteps={totalSteps}>
      <Checkbox label='Not relevant for this application' className='step-not-relevant-check' checked={notRelevant} onChange={() => onNotRelevant()}/>
      <div className='step-two-card bfl-grid'>
        <CheckboxCard
          label='Active directory'
          disabled={notRelevant}
          checked={appObject?.activeDirectory}
          onChange={(e) => onChange(e, 'activeDirectory')}
          header={
            <div style={{
              height: '95px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center' }}>
              <img
                src={activeDirectory}
                alt='laptop-globe'
              />
            </div>
          }
        />
        <CheckboxCard
          label='Entra ID / ADFS'
          checked={appObject?.azureAd}
          disabled={notRelevant}
          onChange={(e) => onChange(e, 'azureAd')}
          header={
            <div style={{
              height: '95px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center' }}>
              <img
                src={azureAd}
                alt='laptop-globe'
              />
            </div>
          }
        />
        <CheckboxCard
          label='App specific user'
          checked={appObject?.appSpecificUser}
          disabled={notRelevant}
          onChange={(e) => onChange(e, 'appSpecificUser')}
          header={
            <div
              style={{
                height: '95px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon
                icon={faUser}
                fixedWidth
                style={{
                  fontSize: 'var(--bf-font-size-h2)',
                  color: 'var(--bfc-base-c-2)'
                }}
              />
            </div>
          }
        />
      </div>
    </AppStepCard>
  );
};

export default StepFive

StepFive.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  newAppType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number
}