import PropTypes from "prop-types";
import { Accordion, Button, Message } from "@intility/bifrost-react";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import NotRegisteredField from "../../../../../../NotRegisteredField/NotRegisteredField";
import React, { useEffect, useState } from "react";
import { DrawerType } from "../LocationInformation";

const CommentAccordionContent = ({location, isDisabled, activeAccordion, handleChange, editLocation}) => {
  const [hasCommentInfo, setHasCommentInfo] = useState(false);

  useEffect(() => {
    if (location === null) return;
    let isCancelled = false;
    if (!isCancelled) {
      setHasCommentInfo(location?.comment !== null && location?.comment?.length !== 0);
    }
    return () => {
      isCancelled = true;
    };
  }, [location]);

  return (
    <Accordion.Item
      actions={!hasCommentInfo ?
        <Button onClick={editLocation}
                state={isDisabled ? 'inactive' : 'default'}
                style={{marginTop: '8px', marginRight: '8px'}}
                variant='filled' small >Add information</Button>
        :
        <Accordion.Action
          icon={faPencil}
          disabled={isDisabled}
          onClick={editLocation}
          aria-label='Edit'
        />}
      title={`Comment (${hasCommentInfo ? 1 : 0})`}
      active={activeAccordion.includes(DrawerType.COMMENT)}
      onClick={() => handleChange(DrawerType.COMMENT)}>
      <div>
        {hasCommentInfo ?
          <NotRegisteredField field={location?.comment} />
          :
          <div>
            <Message>
              Other information that could be useful to us
            </Message>
          </div>
        }
      </div>
    </Accordion.Item>
  )

}

CommentAccordionContent.propTypes = {
  location: PropTypes.object,
  isDisabled: PropTypes.bool,
  activeAccordion: PropTypes.array,
  handleChange: PropTypes.func,
  editLocation: PropTypes.func,
}

export default CommentAccordionContent