import PropTypes from 'prop-types';

const reactChildrenPropType = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.object,
  PropTypes.array,
  PropTypes.string
]);

export const adminPermissionPropTypes = {
  children: reactChildrenPropType
};

export const simplePermissionPropTypes = {
  children: reactChildrenPropType
};

export const writerPermissionPropTypes = {
  children: reactChildrenPropType
};

export const editPermissionPropTypes = {
  children: reactChildrenPropType
};

export const readerPermissionPropTypes = {
  children: reactChildrenPropType
};

const permissionPropTypes = {
  children: reactChildrenPropType,
  neededRoles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default permissionPropTypes;
