import React, {useEffect, useState} from 'react';
import { Icon, Table } from "@intility/bifrost-react";
import '../EditComponents/editComponent.scss';
import '../EditComponents/editWorkplace.scss';
import {useDispatch, useSelector} from 'react-redux';
import { departmentType, Detail } from "../../../utils/hub/models";
import {
  faCheck, faComputerMouse,
  faDesktopAlt,
  faHdd,
  faLaptop,
  faTimes
} from "@fortawesome/pro-regular-svg-icons";
import {
  // eslint-disable-next-line no-unused-vars
  fetchWorkplaceDevicesAsync
} from "../../../redux/company/iamsUsers/workplaceDevice/workplaceDeviceThunks";
import BoxItem from "../../BoxItem/BoxItem";
import { Link } from "react-router-dom";

export const WorkplaceDetails = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {information, workplaceDevices} = useSelector(state => state.company.data.iamsUserPlatform);
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const {user} = useSelector(state => state.auth);
  const [sccmTenantCreated, setSccmTenantCreated] = useState(new Detail());
  const [pcAnalysisComplete, setPcAnalysisComplete] = useState(new Detail());
  const [gpoPoliciesCreated, setGpoPoliciesCreated] = useState(new Detail());
  const [sharefileLink, setSharefileLink] = useState(new Detail());
  const [pcManagement, setPcManagement] = useState(new Detail());
  const [printManagement, setPrintManagement] = useState(new Detail());
  const dispatch = useDispatch();

  useEffect(() => {
    resetValues();
  }, [detailList]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchWorkplaceDevicesAsync(id));
  }, [user, dispatch, id]);


  useEffect(() => {
    setSccmTenantCreated(
      getDetail(
        pcManagement.stringValue === 'Software Center'
          ? 'SccmTenantCreated'
          : pcManagement.stringValue === 'Intune'
            ? 'FirmaportalCreated'
            : 'Not set'
      )
    );
  }, [pcManagement]);


  const resetValues = () => {
    const managementType = getDetail('PcManagement');
    setPcManagement(managementType);
    setSccmTenantCreated(
      getDetail(
        managementType.stringValue === 'Software Center'
          ? 'SccmTenantCreated'
          : managementType.stringValue === 'Intune'
            ? 'FirmaportalCreated'
            : 'Not set'
      )
    );
    setPcAnalysisComplete(getDetail('PcAnalysisComplete'));
    setGpoPoliciesCreated(getDetail('GpoPoliciesCreated'));
    setSharefileLink(getDetail('SharefileLink'));
    setPrintManagement(getDetail('PrintManagement'));
  };

  const getDetail = name => {
    return (
      detailList?.filter(d => d.department === departmentType.WORKPLACE)?.find(i => i.name === name) || new Detail()
    );
  };

  const getDeviceType = type => {
    if (type === 'Computer') return <span><Icon icon={faLaptop} style={{marginRight: 'var(--bfs4)'}}/>Computer</span>;
    if (type === 'Screen') return <span><Icon icon={faDesktopAlt} style={{marginRight: 'var(--bfs4)'}}/>Screen</span>;
    if (type === 'Docking') return <span><Icon icon={faHdd} style={{marginRight: 'var(--bfs4)'}}/>Docking</span>;
    if (type === 'Peripherals') return <span><Icon icon={faComputerMouse} style={{marginRight: 'var(--bfs4)'}}/>Peripherals</span>;
    return "Unknown";
  };



  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Workplace</h3>
      </div>
      <div className='bfl-autocol'>
        <BoxItem label='PC management:' value={pcManagement.stringValue}/>
        {pcManagement?.stringValue === 'Software Center' ?
          <BoxItem label={"SCCM Tenant created:"} value={sccmTenantCreated?.boolValue ?
            <Icon icon={faCheck} style={{color: 'var(--bfc-success)'}}/>
            :
            <Icon icon={faTimes} style={{color: 'var(--bfc-alert)'}}/>}/>
          : pcManagement?.stringValue === 'Intune' ?
          <BoxItem label='Firmaportal created:' value={sccmTenantCreated?.boolValue ?
            <Icon icon={faCheck} style={{color: 'var(--bfc-success)'}}/>
            :
            <Icon icon={faTimes} style={{color: 'var(--bfc-alert)'}}/>}/>
            :
            undefined
        }
        <BoxItem label='Registered IAMS:' value={information.userCount}/>
        <BoxItem label='Print management:' value={printManagement.stringValue}/>
        <BoxItem label='PC Analysis complete:' value={pcAnalysisComplete?.boolValue ?
          <Icon icon={faCheck} style={{color: 'var(--bfc-success)'}}/>
          :
          <Icon icon={faTimes} style={{color: 'var(--bfc-alert)'}}/>}/>
        <BoxItem label='GPO policies created:' value={gpoPoliciesCreated?.boolValue ?
          <Icon icon={faCheck} style={{color: 'var(--bfc-success)'}}/>
          :
          <Icon icon={faTimes} style={{color: 'var(--bfc-alert)'}}/>}/>
        <BoxItem label='Sharefile link:' value={sharefileLink?.stringValue}/>
      </div>

      <Link className='bf-link' to={`/details/${id}/workplace/pdf`}>PDF</Link>


      <div className='workplace-replacement'>
        <h4>Replacement alternatives</h4>
        <div className='workplace-table'>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Model</Table.HeaderCell>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell>Count</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {workplaceDevices && workplaceDevices.map(d => {
                return (
                  <Table.Row key={d.id}>
                    <Table.Cell>{getDeviceType(d.type)}</Table.Cell>
                    <Table.Cell>{d.model}</Table.Cell>
                    <Table.Cell>{d.sku}</Table.Cell>
                    <Table.Cell>{d.count}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </div>
      </div>

    </div>
  );
};

export default WorkplaceDetails;
