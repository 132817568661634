import {Communication} from '../../../components/Hub/Pages';
import pluralize from '../../pluralize';
import {faServer} from '@fortawesome/pro-solid-svg-icons';

export default {
  title: 'Communication',
  path: 'communication',
  icon: faServer,
  isEnabled: (company) => {return company?.communicationPlatform?.state},
  component: Communication,
  getStatus: company => {
    if (company.communicationPlatform.information.communicationCount > 0) {
      return '';
    }
    return 'error';
  },
  getStatusText: (status, company) => {
    return `${company.communicationPlatform.information.communicationCount || 'No'} communication ${pluralize(
      'service',
      company.communicationPlatform.information.communicationCount
    )} registered`;
  }
};
