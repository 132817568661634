import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const DragAndDrop = ({children, handleDropping}) => {
  const [drag, setDrag] = useState(false);
  let dragCounter = 0;

  const dropRef = React.createRef();

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
    // eslint-disable-next-line
  }, []);

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items?.length > 0) {
      setDrag(true);
    }
  };

  const handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDrag(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files?.length > 0) {
      handleDropping(e);
      dragCounter = 0;
    }
  };

  return (
    <div style={{display: 'inline-block', position: 'relative'}} ref={dropRef}>
      {drag && (
        <div
          style={{
            border: 'dashed grey 2px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2
          }}
        />
      )}
      {children}
    </div>
  );
};

DragAndDrop.propTypes = {
  children: PropTypes.object,
  handleDropping: PropTypes.func
};

export default DragAndDrop;
