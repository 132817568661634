import { Button, Grid, Input, Message, Table, TextArea, useFloatingMessage } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import '../locationEditDrawer.scss';
import PropTypes from "prop-types";
import produce from "immer";
import { LocationMeetingRoom } from "../../../../../../../utils/hub/models";
import { useState } from "react";
import { emptyGuid } from "../../../../../../../utils/guid";
import {
  deleteLocationMeetingRoomAsync
} from "../../../../../../../redux/company/locations/locationThunks";
import { useDispatch, useSelector } from "react-redux";
import { IntilityUserType } from "../../../../Users/Options/UserTypeOptions";

export const MeetingRoomContent = ({location, isDisabled, editLocation, editableItems, setEditableItems}) => {
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const [locationItems, setLocationItems] = useState(location?.meetingRooms || []);
  const [meetingRoom, setMeetingRoom] = useState(new LocationMeetingRoom())
  const [sort, setSort] = useState({ key: 'name', direction: 'asc' });
  const { showFloatingMessage } = useFloatingMessage();
  const dispatch = useDispatch();

  const enabledCalendarUsers = userList?.filter(u => u.isEnabled === true).filter(u => u.intilityUserType === IntilityUserType.SharedCalendar)?.map(u => ({value: u.primaryEmailAddress, label: u.primaryEmailAddress}))

  const onChange = (value, name) => {
    setMeetingRoom(
      produce(draft => {
        draft[name] = value;
      })
    );
  }

  const addMeetingRoom = () => {
    if (meetingRoom.floor.length === 0 || meetingRoom.name.length === 0) {
      showFloatingMessage("Missing required values", {state: 'warning'});
      return;
    }
    const newItemList = [...locationItems, meetingRoom];
    const editItemList = [...editableItems, meetingRoom];
    setLocationItems(newItemList);
    setEditableItems(editItemList);
    setMeetingRoom(new LocationMeetingRoom());
    editLocation('meetingRooms', newItemList);
  }

  const deleteMeetingRoom = (room) => {
    const index = locationItems.findIndex(obj => (obj?.name === room?.name && obj?.floor === room?.floor));
    let newItemList;
    let editItemList;
    newItemList = produce(locationItems, draft => {
      draft.splice(index, 1);
    });
    const editIndex = editableItems.findIndex(obj => (obj?.name === room?.name && obj?.floor === room?.floor));
    editItemList = produce(editableItems, draft => {
      draft.splice(editIndex, 1);
    });
    setLocationItems(newItemList);
    setEditableItems(editItemList);
    editLocation('meetingRooms', newItemList)
    if (room.id !== emptyGuid) {
      dispatch(deleteLocationMeetingRoomAsync(location.id, room.id));
    }
  }

  const editMeetingRoom = (value, name, item) => {
    const index = locationItems.findIndex(obj => (obj?.name === item?.name && obj?.floor === item?.floor));
    let newItemList;
    let editItemList;
    if (index < 0) {
      newItemList = [...locationItems, {...item, [name]: value}];
    } else {
      newItemList = produce(locationItems, draft => {
        draft[index] = {...item, [name]: value};
      });
    }
    const editIndex = editableItems.findIndex(obj => (obj?.name === item?.name && obj?.floor === item?.floor));
    if (editIndex < 0) {
      editItemList = [...editableItems, {...item, [name]: value}];
    } else {
      editItemList = produce(editableItems, draft => {
        draft[editIndex] = {...item, [name]: value};
      });
    }

    setLocationItems(newItemList);
    setEditableItems(editItemList);
    editLocation('meetingRooms', newItemList)
  };

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  return (
    <div className='meeting-room-drawer'>
      <Grid className='new-meeting-room bfl-autocol' small={2}>
        <Grid.Span small={1}>
          <Input required label='Room name' value={meetingRoom?.name || ''} onChange={e => onChange(e.target.value, 'name')}/>
        </Grid.Span>
        <Grid.Span small={1}>
          <Input required label='Floor' value={meetingRoom?.floor || ''} onChange={e => onChange(e.target.value, 'floor')}/>
        </Grid.Span>
        <Grid.Span small={1}>
          <Input label='Video conference' optional={true} value={meetingRoom?.videoConference || ''} onChange={e => onChange(e.target.value, 'videoConference')}/>
        </Grid.Span>
        <Grid.Span small={1}>
          <Input label='Booking panel' optional={true} value={meetingRoom?.bookingPanel || ''} onChange={e => onChange(e.target.value, 'bookingPanel')}/>
        </Grid.Span>
        <Grid.Span small={1}>
          <Input label='Screen and Signage' optional={true} value={meetingRoom?.screenAndSignage || ''} onChange={e => onChange(e.target.value, 'screenAndSignage')}/>
        </Grid.Span>
        <Grid.Span small={1}>
          <Input label='ChromeCast / AppleTV' optional={true} value={meetingRoom?.castUnit || ''} onChange={e => onChange(e.target.value, 'castUnit')}/>
        </Grid.Span>
        <Grid.Span small={1}>
          <TextArea label='Additional information' optional={true} value={meetingRoom?.additionalInformation || ''} onChange={e => onChange(e.target.value, 'additionalInformation')}/>
        </Grid.Span>
        <Grid.Span small={1}>
          <Select label='Username (UPN)' optional={true} value={{value: meetingRoom?.sharedCalendarUpn, label: meetingRoom?.sharedCalendarUpn} || ''} options={enabledCalendarUsers} onChange={e => onChange(e.value, 'sharedCalendarUpn')}/>
          <Input label='Software' optional={true} value={meetingRoom?.software || ''} onChange={e => onChange(e.target.value, 'software')}/>
        </Grid.Span>
        <Grid.Span small={2}>
          <div style={{display: 'flex'}}>
            <Button icon={faPlus} variant={"filled"} style={{flex: '1'}} onClick={() => addMeetingRoom()}>Add meeting room</Button>
          </div>
        </Grid.Span>
      </Grid>
      {location?.meetingRooms?.length > 0 ?
        <div className='meeting-room-list'>
          <h5>Meeting rooms added to {location?.name}</h5>
          <div>
            <Table noBorder>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell/>
                  <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>Meeting room</Table.HeaderCell>
                  <Table.HeaderCell sorting={getSortProp('floor')} onClick={onSortChangeCreator('floor')}>Floor</Table.HeaderCell>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {location?.meetingRooms?.map(room => (
                  <Table.Row
                    key={room?.id}
                    content={
                      <Grid className='new-meeting-room bfl-autocol' small={2}>
                        <Grid.Span small={1}>
                          <Input label='Video conference' optional={true} value={room?.videoConference} onChange={(e) => editMeetingRoom(e.target.value, 'videoConference', room)}/>
                        </Grid.Span>
                        <Grid.Span small={1}>
                          <Input label='Booking panel' optional={true} value={room?.bookingPanel} onChange={(e) => editMeetingRoom(e.target.value, 'bookingPanel', room)}/>
                        </Grid.Span>
                        <Grid.Span small={1}>
                          <Input label='Screen and Signage' optional={true} value={room?.screenAndSignage || ''} onChange={e => editMeetingRoom(e.target.value, 'screenAndSignage', room)}/>
                        </Grid.Span>
                        <Grid.Span small={1}>
                          <Input label='ChromeCast / AppleTV' optional={true} value={room?.castUnit || ''} onChange={e => editMeetingRoom(e.target.value, 'castUnit', room)}/>
                        </Grid.Span>
                        <Grid.Span small={1}>
                          <TextArea label='Additional information' optional={true} value={room?.additionalInformation} onChange={(e) => editMeetingRoom(e.target.value, 'additionalInformation', room)}/>
                        </Grid.Span>
                        <Grid.Span small={1}>
                          <Select label='Username (UPN)' optional={true} value={{value: room?.sharedCalendarUpn, label: room?.sharedCalendarUpn} || ''} options={enabledCalendarUsers} onChange={e => editMeetingRoom(e.value, 'sharedCalendarUpn', room)}/>
                          <Input label='Software' optional={true} value={room?.software || ''} onChange={e => editMeetingRoom(e.target.value, 'software', room)}/>
                        </Grid.Span>
                      </Grid>
                    }
                    limitExpandClick
                  >
                    <Table.Cell>
                      <Input label='Room name' hideLabel value={room?.name} onChange={(e) => editMeetingRoom(e.target.value, 'name', room)}/>
                    </Table.Cell>
                    <Table.Cell>
                      <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Input style={{maxWidth: '50px', marginRight: 'var(--bfs8)'}} label='Floor' hideLabel value={room?.floor} onChange={(e) => editMeetingRoom(e.target.value, 'floor', room)}/>
                        .floor
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <Button state={"alert"} small variant={"outline"} icon={faTimes} onClick={() => deleteMeetingRoom(room)}>Delete</Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div> :
        <Message state={"neutral"} style={{marginTop: 'var(--bfs12)'}}>No meeting rooms added yet</Message>
      }
    </div>
  )

}

MeetingRoomContent.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
};

export default MeetingRoomContent