import {
  addMemberRoleSuccess,
  addProjectGlobalTeamSuccess,
  deleteProjectGlobalTeamSuccess,
  fetchMemberRolesSuccess,
  fetchProjectGlobalTeamsSuccess,
  updateProjectGlobalTeamSuccess
} from './projectGlobalTeamsActions';
import {createReducer, createSlice} from '@reduxjs/toolkit';

const updateOnProjectGlobalTeamChangeReducer = getState => {
  return {
    [fetchProjectGlobalTeamsSuccess]: (state, action) => getState(action.payload, state),
    [addProjectGlobalTeamSuccess]: (state, action) => getState(action.payload, state),
    [deleteProjectGlobalTeamSuccess]: (state, action) => getState(action.payload, state),
    [updateProjectGlobalTeamSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const projectGlobalTeamsReducer = createReducer(
  [],
  updateOnProjectGlobalTeamChangeReducer((payload, state) => {
    state = payload.projectGlobalTeams.list;
    return state;
  })
);

const updateOnMemberRolesChangeReducer = getState => {
  return {
    [fetchMemberRolesSuccess]: (state, action) => getState(action.payload, state),
    [addMemberRoleSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const memberGlobalRolesReducer = createReducer(
  [],
  updateOnMemberRolesChangeReducer((payload, state) => {
    state = payload.projectGlobalTeams.roles;
    return state;
  })
);

export const projectGlobalTeamsStatusSlice = createSlice({
  name: 'projectGlobalTeamsAction',
  initialState: {
    fetching: false,
    updating: false,
    error: false,
    fetchingRoles: false,
    updatingRoles: false,
    rolesError: false
  },
  reducers: {
    fetchProjectGlobalTeams: state => {
      state.fetching = true;
    },
    fetchMemberRoles: state => {
      state.fetchingRoles = true;
    },
    fetchProjectGlobalTeamsError: state => {
      state.fetching = false;
      state.error = true;
    },
    fetchMemberRolesError: state => {
      state.fetchingRoles = false;
      state.rolesError = true;
    },
    updateProjectGlobalTeams: state => {
      state.updating = true;
    },
    updateMemberRole: state => {
      state.updatingRoles = true;
    },
    updateProjectGlobalTeamsError: state => {
      state.updating = false;
      state.error = true;
    },
    updateMemberRoleError: state => {
      state.updatingRoles = false;
      state.rolesError = true;
    },
    updateProjectGlobalTeamsClear: state => {
      state.updating = false;
      state.fetching = false;
      state.error = false;
    },
    updateMemberRolesClear: state => {
      state.fetchingRoles = false;
      state.updatingRoles = false;
      state.rolesError = false;
    }
  },
  extraReducers: {
    [fetchProjectGlobalTeamsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateProjectGlobalTeamSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchMemberRolesSuccess]: state => {
      state.fetchingRoles = false;
      state.updatingRoles = false;
      state.rolesError = false;
    },
    [addMemberRoleSuccess]: state => {
      state.updatingRoles = false;
      state.rolesError = false;
    },
    [addProjectGlobalTeamSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [deleteProjectGlobalTeamSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchProjectGlobalTeams,
  fetchMemberRoles,
  updateProjectGlobalTeamsClear,
  updateMemberRolesClear,
  fetchProjectGlobalTeamsError,
  fetchMemberRolesError,
  updateProjectGlobalTeams,
  updateMemberRole,
  updateProjectGlobalTeamsError,
  updateMemberRoleError
} = projectGlobalTeamsStatusSlice.actions;
