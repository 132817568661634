import React from "react";
import { Spinner } from "@intility/bifrost-react";
import { useSelector } from "react-redux";

export const EmptyTableText = () => {
  const {applicationStatus} = useSelector(
    state => state.company.data.applicationPlatform
  );

  return (
    applicationStatus.fetching ? <Spinner overlay size={80} label='Fetching applications…'/> :
    <div style={{backgroundColor: 'var(--bfc-base-dimmed)', padding: 'var(--bfs12) 0', borderRadius: '0 0 3px 3px'}}>
      <p style={{marginTop: '0', marginBottom: '0', textAlign: 'center'}}>No applications added, you may add application above.</p>
    </div>
  )

};

export default EmptyTableText