import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@intility/bifrost-react/dist/bifrost-app.css';
import "@intility/bifrost-react-datepicker/datepicker.css";
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';
import store from './redux/store';
import {isIE} from 'react-device-detect';

// if production, activate Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
  Sentry.captureMessage('Sentry enabled!');
}

// Checks if environment variables have a value
(() => {
  const environmentVariablesNotSet = [];
  const envVariablesToCheck = [
    'REACT_APP_AUTH_MICROSOFT_CLIENT_ID',
    'REACT_APP_GRAPH_MICROSOFT_APP_CLIENT_ID',
    'REACT_APP_AUTH_LINKEDIN_CLIENT_ID',
    'REACT_APP_SENTRY_DSN',
    'REACT_APP_API_URL'
  ];

  const checkVariable = name => {
    const variable = process.env[name];

    if (!variable) {
      environmentVariablesNotSet.push(name);
    }
  };

  envVariablesToCheck.forEach(checkVariable);

  environmentVariablesNotSet.forEach(variable => {
    console.error(`Env variable ${variable} is not set!`);
  });
})();

// render
const container = document.getElementById('root');
const root = createRoot(container);
root.render(isIE ?
  <p>
    Your browser is not supported. Please switch to one of the supported browsers: Google Chrome, Mozilla Firefox or Edge
  </p> :
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>);

serviceWorker.unregister();
