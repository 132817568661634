import React, {useRef} from 'react';
import './fileUploader.scss';
import '../Hub/Pages/Devices.Computers/Customization/DesktopSettings/WallpaperOption/wallpaperoption.scss';
import fileUploaderPropTypes, {fileUploaderFilePropTypes} from './types';
import { Button, Icon } from "@intility/bifrost-react";
import {faUpload} from '@fortawesome/pro-solid-svg-icons';
import { faCamera } from "@fortawesome/pro-solid-svg-icons";

const getFilesArray = fileList => {
  const files = [];

  for (let i = 0; i < fileList?.length; i++) {
    const file = fileList[i];
    files.push(file);
  }

  return files;
};

const File = ({data, onDelete}) => {
  return (
    <div className='file'>
      <span>{data.name}</span>
      <i className='fas fa-times-circle' onClick={onDelete} />
    </div>
  );
};

File.propTypes = fileUploaderFilePropTypes;


export const ButtonFileUploader = ({onChange, name, multiple = true, validContentTypes = ['.pdf', '.jpeg', '.png', '.jpg']}) => {
  const fileRef = useRef(null);

  const onFileSelect = e => {
    return onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.target.files)
      }
    });
  };

  return (
    <div className='simple-button-uploader'>
      <button onClick={() => fileRef.current && fileRef.current.click()} type='button'>
        <Icon icon={faCamera}/>
      </button>
      <input
        type='file'
        ref={fileRef}
        multiple={multiple}
        accept={validContentTypes.join(', ')}
        onChange={onFileSelect}
        style={{display: 'none'}}
      />
    </div>
  );
};

ButtonFileUploader.propTypes = fileUploaderPropTypes;

const SimpleFileUploader = ({onChange, name, multiple = true, validContentTypes = ['.pdf', '.jpeg', '.png', '.jpg', '.docx', '.xlsx', '.xls','.pptx', '.txt', '.vsdx'], isDisabled, small, icon}) => {
  const fileRef = useRef(null);

  const onFileSelect = e => {
    return onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.target.files)
      }
    });
  };

  return (
    <div className='simple-uploader'>
      <Button disabled={isDisabled} onClick={() => fileRef.current && fileRef.current.click()} icon={icon || faUpload} type='button' small={small}>
        {name ? name : 'Select files to upload'}
      </Button>
      <input
        type='file'
        ref={fileRef}
        multiple={multiple}
        accept={validContentTypes.join(', ')}
        onChange={onFileSelect}
        style={{display: 'none'}}
      />
    </div>
  );
};

SimpleFileUploader.propTypes = fileUploaderPropTypes;

export default SimpleFileUploader;
