import { Badge } from "@intility/bifrost-react";
import React from "react";
import Delve from "../../../../assets/images/Microsoft365/Delve.png";
import Dynamics365 from "../../../../assets/images/Microsoft365/dynamics365.png";
import Forms from "../../../../assets/images/Microsoft365/Forms.png";
import Kaizala from "../../../../assets/images/Microsoft365/Microsoft_Kaizala_icon.png";
import Lists from "../../../../assets/images/Microsoft365/Microsoft-Lists_icon.png";
import MyAnalytics from "../../../../assets/images/Microsoft365/MyAnalytics.png";
import OneDrive from "../../../../assets/images/Microsoft365/OneDrive_375x375.png";
import Planner from "../../../../assets/images/Microsoft365/Planner.png";
import PowerApps from "../../../../assets/images/Microsoft365/PowerApps.png";
import PowerAutomate from "../../../../assets/images/Microsoft365/PowerAutomate.png";
import PowerBi from "../../../../assets/images/Microsoft365/PowerBI.png";
import Stream from "../../../../assets/images/Microsoft365/Stream.png";
import Sway from "../../../../assets/images/Microsoft365/Sway.png";
import Teams from "../../../../assets/images/Microsoft365/Microsoft Teams_375x375.png";
import ToDo from "../../../../assets/images/Microsoft365/To-Do.png";
import Whiteboard from "../../../../assets/images/Microsoft365/Whiteboard.png";
import Yammer from "../../../../assets/images/Microsoft365/Yammer_375x375.png";
import AcrobatLogo from "../../../../assets/images/software_logos/acrobat.svg";
import FoxitLogo from "../../../../assets/images/software_logos/foxit.svg";
import FirefoxLogo from "../../../../assets/images/software_logos/firefox.svg";
import ChromeLogo from "../../../../assets/images/software_logos/chrome.svg";
import VSCode from "../../../../assets/images/software_logos/VSCode.png";
import LogitechOptions from "../../../../assets/images/software_logos/LogitechOptions.png";
import LogitechUnifying from "../../../../assets/images/software_logos/LogitechUnifying.png";
import PaintDotNet from "../../../../assets/images/software_logos/PaintDotNet.png";
import Greenshot from "../../../../assets/images/software_logos/Greenshot.png";
import PlantronicsHub from "../../../../assets/images/software_logos/PlantronicsHub.png";
import JabraDirect from "../../../../assets/images/software_logos/JabraDirect.png";
import SevenZipLogo from "../../../../assets/images/software_logos/7-zip.png";
import SpotifyLogo from "../../../../assets/images/software_logos/spotify.png";
import TidalLogo from "../../../../assets/images/software_logos/tidal.png";
import AppleMusicLogo from "../../../../assets/images/software_logos/apple-music.png";
import VLCLogo from "../../../../assets/images/software_logos/vlc.png";
import GimpLogo from "../../../../assets/images/software_logos/gimp.png";
import MMALogo from "../../../../assets/images/software_logos/MMA.png";

export const statusOptions = [
  { value: 0, label: (<Badge state='warning'>Missing info</Badge>)},
  { value: 1, label: (<Badge state={"success"}>Ready</Badge>)},
];

export const ApplicationRowOptions = [
  {value: 'name', label: 'Name', isDisabled: true},
  {value: 'modifiedAt', label: 'Modified'},
  {value: 'readyStatus', label: 'Status'},
  {value: 'origin', label: 'Origin'},
  {value: 'responsible', label: 'Responsible'},
  {value: 'companyUsers', label: 'Users with access'},
  {value: 'serverApp', label: 'Server'},
  {value: 'clientApp', label: 'Client'},
  {value: 'saasApp', label: 'SaaS'},
  {value: 'virtualClient', label: 'Virtual client'},
  {value: 'singleSignOnToday', label: 'SSO'},
  {value: 'vendor', label: 'Vendor'},
  {value: 'environment', label: 'Environment'},
  {value: 'location', label: 'Location'},
  {value: 'currentVersion', label: 'Current version'},
  {value: 'newVersion', label: 'New version'},
];

export const ApplicationSearchOptions = [
  {value: 'name', label: 'Name'},
  {value: 'modifiedAt', label: 'Modified'},
  {value: 'readyStatus', label: 'Status'},
  {value: 'origin', label: 'Origin'},
  {value: 'responsible', label: 'Responsible'},
  {value: 'applicationType', label: 'Application type'},
  {value: 'vendor', label: 'Vendor'},
  {value: 'currentVersion', label: 'Current version'},
  {value: 'newVersion', label: 'New version'},
  {value: 'singleSignOnToday', label: 'SSO'},
  {value: 'environment', label: 'Environment'},
  {value: 'location', label: 'Location'},
];

export const userReadyStatusOptions = [
  {value: 1, label: 'Ready'},
  {value: 3, label: 'Files not available'}
];

export const ApplicationType = {
  ServerApp: 'Server',
  SaaSApp: 'SaaS',
  ClientApp: 'Client',
};

export const ssoOptions = [
  {value: 'Yes', label: 'Yes'},
  {value: 'No', label: 'No'}
];

export const identityOptions = [
  {value: 'azureAd', label: 'Entra ID / ADFS'},
  {value: 'activeDirectory', label: 'Active directory'},
  {value: 'appSpecificUser', label: 'App specific user'},
];

export const environmentOptions = [
  {value: 'prod', label: 'Production'},
  {value: 'dev', label: 'Development'},
  {value: 'test', label: 'Testing'},
];

export const deliveryOptions = [
  {value: 'browserAccess', label: 'Browser access'},
  {value: 'clientApp', label: 'Local client'},
  {value: 'virtualClient', label: 'Virtual client (Citrix, VDI, etc.)'},
];

export const appOptions = [
  {value: 'serverApp', label: 'Server'},
  {value: 'clientApp', label: 'Client'},
  {value: 'saasApp', label: 'SaaS'}
];

export const appValidationOptions = [
  {value: 0, label: 'No action taken', type: 'inPortfolio'},
  {value: 1, label: 'Added', type: 'inPortfolio'},
  {value: 2, label: 'Not added', type: 'inPortfolio'}
];

export const appTypeOptions = [
  {value: 'serverApp', label: 'Server'},
  {value: 'clientApp', label: 'Client'},
  {value: 'saasApp', label: 'SaaS'},
  {value: 'other', label: 'Other'}
];

export const appStatusOptions = [
  {value: 0, label: 'Missing info', type: 'readyStatus'},
  {value: 2, label: 'Need files', type: 'readyStatus'},
  {value: 4, label: 'Awaiting review', type: 'readyStatus'},
  {value: 5, label: 'Packaging', type: 'readyStatus'},
  {value: 1, label: 'Ready', type: 'readyStatus'}
];

export const selectApps = [
  {name: 'Delve', value: 'delve', selected: false, image: Delve, inPortfolio: 0},
  {
    name: 'Dynamics 365',
    value: 'dynamics365',
    selected: false,
    image: Dynamics365,
    inPortfolio: 0},
  {
    name: 'Dynamics 365 Operations',
    value: 'dynamics365Operations',
    selected: false,
    image: Dynamics365,
    inPortfolio: 0},
  {name: 'Forms', value: 'forms', selected: false, image: Forms, inPortfolio: 0},
  {name: 'Kaizala', value: 'kaizala', selected: false, image: Kaizala, inPortfolio: 0},
  {name: 'Lists', value: 'lists', selected: false, image: Lists, inPortfolio: 0},
  {
    name: 'MyAnalytics',
    value: 'myAnalytics',
    selected: false,
    image: MyAnalytics,
    inPortfolio: 0},
  {name: 'OneDrive', value: 'oneDrive', selected: false, image: OneDrive, inPortfolio: 0},
  {name: 'Planner', value: 'planner', selected: false, image: Planner, inPortfolio: 0},
  {name: 'Power Apps', value: 'powerApps', selected: false, image: PowerApps, inPortfolio: 0},
  {
    name: 'Power Automate',
    value: 'powerAutomate',
    selected: false,
    image: PowerAutomate,
    inPortfolio: 0},
  {name: 'Power BI', value: 'powerBi', selected: false, image: PowerBi, inPortfolio: 0},
  {name: 'Stream', value: 'stream', selected: false, image: Stream, inPortfolio: 0},
  {name: 'Sway', value: 'sway', selected: false, image: Sway, inPortfolio: 0},
  {name: 'Teams', value: 'teams', selected: false, image: Teams, inPortfolio: 0},
  {name: 'To Do', value: 'toDo', selected: false, image: ToDo, inPortfolio: 0},
  {
    name: 'Whiteboard',
    value: 'whiteboard',
    selected: false,
    image: Whiteboard,
    inPortfolio: 0},
  {name: 'Yammer', value: 'yammer', selected: false, image: Yammer, inPortfolio: 0}
];

export const freewareOptions = [
  {
    name: 'Acrobat Reader',
    image: AcrobatLogo,
    inPortfolio: 0
  },
  {
    name: 'Foxit Reader',
    image: FoxitLogo,
    inPortfolio: 0
  },
  {
    name: 'Firefox',
    image: FirefoxLogo,
    inPortfolio: 0
  },
  {
    name: 'Google Chrome',
    image: ChromeLogo,
    inPortfolio: 0
  },
  {
    name: 'Visual Studio Code',
    image: VSCode,
    inPortfolio: 0
  },
  {
    name: 'Logitech Options',
    image: LogitechOptions,
    inPortfolio: 0
  },
  {
    name: 'Logitech Unifying',
    image: LogitechUnifying,
    inPortfolio: 0
  },
  {
    name: 'paint.NET',
    image: PaintDotNet,
    inPortfolio: 0
  },
  {
    name: 'Greenshot',
    image: Greenshot,
    inPortfolio: 0
  },
  {
    name: 'Plantronics Hub',
    image: PlantronicsHub,
    inPortfolio: 0
  },
  {
    name: 'Jabra Direct',
    image: JabraDirect,
    inPortfolio: 0
  },
  {
    name: '7-Zip',
    image: SevenZipLogo,
    inPortfolio: 0
  },
  {
    name: 'Spotify',
    image: SpotifyLogo,
    inPortfolio: 0
  },
  {
    name: 'Tidal',
    image: TidalLogo,
    inPortfolio: 0
  },
  {
    name: 'iTunes',
    image: AppleMusicLogo,
    inPortfolio: 0
  },
  {
    name: 'VLC Media Player',
    image: VLCLogo,
    inPortfolio: 0
  },
  {
    name: 'Gimp',
    image: GimpLogo,
    inPortfolio: 0
  },
  {
    name: 'Make me admin',
    image: MMALogo,
    inPortfolio: 0
  }
];

export const preDefinedApps = [
  {
    name: 'Acrobat Reader',
    image: AcrobatLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Foxit Reader',
    image: FoxitLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Firefox',
    image: FirefoxLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Google Chrome',
    image: ChromeLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Visual Studio Code',
    image: VSCode,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Logitech Options',
    image: LogitechOptions,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Logitech Unifying',
    image: LogitechUnifying,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'paint.NET',
    image: PaintDotNet,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Greenshot',
    image: Greenshot,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Plantronics Hub',
    image: PlantronicsHub,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Jabra Direct',
    image: JabraDirect,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: '7-Zip',
    image: SevenZipLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Spotify',
    image: SpotifyLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'Tidal',
    image: TidalLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'iTunes',
    image: AppleMusicLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {
    name: 'VLC Media Player',
    image: VLCLogo,
    type: 'Freeware',
    inPortfolio: 0
  },
  {name: 'Gimp',image: GimpLogo, type: 'Freeware', inPortfolio: 0 },
  {name: 'Make me admin', image: MMALogo, type: 'Freeware', inPortfolio: 0 },
  {name: 'Delve', image: Delve, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Dynamics 365',image: Dynamics365, type: "Microsoft 365", inPortfolio: 0 },
  {name: 'Dynamics 365 Operations',image: Dynamics365, type: "Microsoft 365", inPortfolio: 0 },
  {name: 'Forms', image: Forms, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Kaizala', image: Kaizala, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Lists', image: Lists, type: "Microsoft 365", inPortfolio: 0},
  {name: 'MyAnalytics',image: MyAnalytics, type: "Microsoft 365", inPortfolio: 0 },
  {name: 'OneDrive', image: OneDrive, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Planner', image: Planner, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Power Apps', image: PowerApps, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Power Automate', image: PowerAutomate, type: "Microsoft 365", inPortfolio: 0 },
  {name: 'Power BI', image: PowerBi, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Stream', image: Stream, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Sway', image: Sway, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Teams', image: Teams, type: "Microsoft 365", inPortfolio: 0},
  {name: 'To Do', image: ToDo, type: "Microsoft 365", inPortfolio: 0},
  {name: 'Whiteboard', image: Whiteboard, type: "Microsoft 365", inPortfolio: 0 },
  {name: 'Yammer', image: Yammer, type: "Microsoft 365", inPortfolio: 0}
];