import { Checkbox, CheckboxCard, Icon } from "@intility/bifrost-react";
import AppStepCard from "../AppStepCard";
import React, { useState } from "react";
import PropTypes from "prop-types";
import laptopGlobalIcon from 'assets/images/application/laptopBrowser.svg';
import { faBrowser, faLaptop } from "@fortawesome/pro-regular-svg-icons";
import produce from "immer";

export const StepTwo = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, totalSteps, setIsOpen}) => {
  const [notRelevant, setNotRelevant] = useState(appObject.deliveryNotRelevant || false);

  const onNotRelevant = (e) => {
    const {checked} = e.target;
    setAppObject(
      produce(draft => {
        draft.clientApp = checked ? 2 : 0;
        draft.virtualClient = checked ? 2 : 0;
        draft.browserAccess = checked ? 2 : 0;
        draft.deliveryNotRelevant = !notRelevant;
      })
    );
    setNotRelevant(!notRelevant);
  }

  const onChange = (e, name) => {
    const {checked} = e.target;
    setAppObject(
      produce(draft => {
        draft[name] = checked ? 1 : 0;
      })
    );
  };

  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} header='How is the application delivered to end-users?' totalSteps={totalSteps} stepReady={true}>
      <Checkbox label='Not relevant for this application' className='step-not-relevant-check' checked={notRelevant} onChange={(e) => onNotRelevant(e)}/>
      <div className='step-two-card bfl-grid'>
        <CheckboxCard
          label='Client application'
          disabled={notRelevant}
          checked={appObject?.clientApp === 1}
          onChange={(e) => onChange(e, 'clientApp')}
          header={
            <div
              style={{
                height: '95px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon
                icon={faLaptop}
                fixedWidth
                style={{
                  fontSize: 'var(--bf-font-size-h2)',
                  color: 'var(--bfc-base-c-2)'
                }}
              />
            </div>
          }
        >
          Local client installation
        </CheckboxCard>
        <CheckboxCard
          label='Virtual Client'
          checked={appObject?.virtualClient === 1}
          disabled={notRelevant}
          onChange={(e) => onChange(e, 'virtualClient')}
          header={
            <div style={{
              height: '95px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center' }}>
              <img
                src={laptopGlobalIcon}
                alt='laptop-globe'
              />
            </div>
          }
        >
          Citrix, VDI, etc
        </CheckboxCard>
        <CheckboxCard
          label='Web browser'
          checked={appObject?.browserAccess === 1}
          disabled={notRelevant}
          onChange={(e) => onChange(e, 'browserAccess')}
          header={
            <div
              style={{
                height: '95px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon
                icon={faBrowser}
                fixedWidth
                style={{
                  fontSize: 'var(--bf-font-size-h2)',
                  color: 'var(--bfc-base-c-2)'
                }}
              />
            </div>
          }
        >
          Google Chrome, Firefox, Edge, etc
        </CheckboxCard>
      </div>
    </AppStepCard>
  );
};

export default StepTwo

StepTwo.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  newAppType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number
}