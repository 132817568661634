export const IntilityUserType = {
  NoIntilityUser: 0,
  IntilityIdUser: 1,
  EmailUser: 2,
  ExternalUser: 3,
  SharedMailbox: 4,
  ResourceAccount: 5,
  AdminUser: 6,
  SharedCalendar: 7,
  PrivilegedAccess: 8,
  IntilityIdUserCustom: 9,
  LimitedUser: 10,
  ServiceAccount: 11,
};

export const RequiredValue = (userType) => {
  switch (userType) {
    case IntilityUserType.ResourceAccount: return false;
    case IntilityUserType.ServiceAccount: return false;
    case IntilityUserType.SharedCalendar: return false;
    case IntilityUserType.SharedMailbox: return false;
    case IntilityUserType.NoIntilityUser: return false;
    default: return true;
  }
}

export const DefaultIntilityUserTypes = [
  IntilityUserType.NoIntilityUser,
  IntilityUserType.IntilityIdUser,
  IntilityUserType.ExternalUser,
  IntilityUserType.SharedMailbox,
  IntilityUserType.ServiceAccount,
  IntilityUserType.SharedCalendar
]

const UserTypeOptions = [
  {value: IntilityUserType.NoIntilityUser, label: 'No Intility user type', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.IntilityIdUser, label: 'Intility Id User', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.EmailUser, label: 'Email User', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.ExternalUser, label: 'External User', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.SharedMailbox, label: 'Shared mailbox', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.ResourceAccount, label: 'Resource Account', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.AdminUser, label: 'Admin user', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.SharedCalendar, label: 'Shared calendar', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.PrivilegedAccess, label: 'Privileged access', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.IntilityIdUserCustom, label: 'Intility Id User (Custom)', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.LimitedUser, label: 'Limited user', type: 'intilityUserType', displayName: 'Intility user type'},
  {value: IntilityUserType.ServiceAccount, label: 'Service account', type: 'intilityUserType', displayName: 'Intility user type'},
];

export const getIntilityUserOptions = (options) => {
  if (options === null || options === '') return DefaultIntilityUserTypes.map(o => UserTypeOptions[o]);
  return options?.split(',')?.map(o => UserTypeOptions[o]);
}

export const UserTypeDescriptionOptions = [
  {value: IntilityUserType.NoIntilityUser, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>No Intility user type</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Not yet defined</p></span>, type: 'intilityUserType', shortLabel: 'No Intility user type'},
  {value: IntilityUserType.IntilityIdUser, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Intility ID User</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Personal user account for people on a domain registered machine / Intune machine.</p></span>, type: 'intilityUserType', shortLabel: 'Intility ID User'},
  {value: IntilityUserType.EmailUser, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Email User</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Personal user account for people who are not on a domain-registered machine, but who need access to company e-mail.</p></span>, type: 'intilityUserType', shortLabel: 'Email User'},
  {value: IntilityUserType.ExternalUser, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>External User</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Personal user account for people who do not sit on a domain-registered machine, but who need access to company resources via the Web, Citrix or VPN.</p></span>, type: 'intilityUserType', shortLabel: 'External User'},
  {value: IntilityUserType.SharedMailbox, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Shared mailbox</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Impersonal mailbox that users with company mail access via their personal account in Outlook.</p></span>, type: 'intilityUserType', shortLabel: 'Shared mailbox'},
  {value: IntilityUserType.ResourceAccount, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Resource Account</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Non-personal accounts that does not fit into other types.</p></span>, type: 'intilityUserType', shortLabel: 'Resource Account'},
  {value: IntilityUserType.AdminUser, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Admin user</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Additional personal accounts that are used for elevated access i.e. admin access to servers or in Microsoft 365.</p></span>, type: 'intilityUserType', shortLabel: 'Admin user'},
  {value: IntilityUserType.SharedCalendar, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Shared calendar</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Bookable resource in Outlook - e.g. meeting room.</p></span>, type: 'intilityUserType', shortLabel: 'Shared calendar'},
  {value: IntilityUserType.PrivilegedAccess, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Privileged access</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Personal user account for people who are not on a domain-registered machine. User gets access to run an encrypted session with administrator access to servers by using RDP or SSH.</p></span>, type: 'intilityUserType', shortLabel: 'Privileged access'},
  {value: IntilityUserType.IntilityIdUserCustom, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Intility ID User (Custom)</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>This is a customized user type for your company. Role based user account.</p></span>, type: 'intilityUserType', shortLabel: 'Intility ID User (Custom)'},
  {value: IntilityUserType.LimitedUser, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Limited user</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Personal account for employee that from external/private Computer is granted access to company resources through Web or Citrix. Can have Company e-mail, but not VPN.</p></span>, type: 'intilityUserType', shortLabel: 'Limited user'},
  {value: IntilityUserType.ServiceAccount, label: <span style={{display: 'flax'}}><p style={{marginBottom: 0}}>Service account</p><p className='bf-small' style={{color: 'var(--bfc-base-c-2)', margin: 0}}>Non-personal account used for Services.</p></span>, type: 'intilityUserType', shortLabel: 'Service account'},
];

export const getIntilityUserDescriptionOptions = (options) => {
  if (options === null || options === '') return DefaultIntilityUserTypes.map(o => UserTypeDescriptionOptions[o]);
  return options?.split(',')?.map(o => UserTypeDescriptionOptions[o]);
}

export const UserStatusTypes = {
  Ready: 'ready',
  Attention: 'attention',
  Warning: 'warning'
}

export const getFilterOptions = (options) => {
  let filterOptions;
  if (options === null || options === '') filterOptions = DefaultIntilityUserTypes.map(o => UserTypeOptions[o]);
  else filterOptions =  options?.split(',')?.map(o => UserTypeOptions[o]);
  return ([
      {
        label: 'Entra ID status',
        options: [
          {value: true, label: 'Active user', displayName: 'Entra ID Status', type: 'enabledFilter'},
          {value: false, label: 'Inactive user', displayName: 'Entra ID Status', excludes: true, type: 'enabledFilter'},
        ]
      },
      {
        label: 'User registration status',
        options: [
          {value: UserStatusTypes.Ready, label: 'Ready', displayName: 'User registration status', type: 'warningFilter',},
          {value: UserStatusTypes.Attention, label: 'Attention', displayName: 'User registration status', type: 'warningFilter', warning: true},
          {value: UserStatusTypes.Warning, label: 'Error', displayName: 'User registration status', type: 'warningFilter', alert: true},
        ]
      },
    {
      label: 'Intility user type',
      options: filterOptions
    }
  ]);
}

export const getIntilityUserType = userType => {
  switch (userType) {
    case IntilityUserType.NoIntilityUser:
      return 'No user type';
    case IntilityUserType.IntilityIdUser:
      return 'Intility ID User';
    case IntilityUserType.EmailUser:
      return 'Email User';
    case IntilityUserType.ExternalUser:
      return 'External user';
    case IntilityUserType.SharedMailbox:
      return 'Shared mailbox';
    case IntilityUserType.ResourceAccount:
      return 'Resource account';
    case IntilityUserType.AdminUser:
      return 'Admin user';
    case IntilityUserType.SharedCalendar:
      return 'Shared calendar';
    case IntilityUserType.PrivilegedAccess:
      return 'Privileged access';
    case IntilityUserType.IntilityIdUserCustom:
      return 'Intility ID User (Custom)';
    case IntilityUserType.LimitedUser:
      return 'Limited user';
    case IntilityUserType.ServiceAccount:
      return 'Service account';
    default:
      return 'Other';
  }
};

export default UserTypeOptions;
