import React from 'react';
import './networkProgressBox.scss';
import PropTypes from 'prop-types';
import {StepBar} from '@intility/bifrost-react';
import { getTime } from "../../CompanyOverview/CompanyOverview";

const NetworkProgressBox = ({location}) => {
  const surveyDate = new Date(location?.locationNetwork?.siteSurvey);
  const lineDate = new Date(location?.locationNetwork?.lineDate);
  const currentDate = new Date(Date.now());
  const nextChecked = 'next-checked';
  const currentChecked = 'current-checked ';
  const prevChecked = 'prev-checked';

  const isColored = () => {
    let number = 0;
    if (location?.locationNetwork?.locationNetworkCreated) number = 1;
    if (surveyDate.getFullYear() !== 1 && surveyDate <= currentDate) number = 2;
    if (location?.locationNetwork?.equipmentOfferSent) number = 3;
    if (lineDate.getFullYear() !== 1 && lineDate <= currentDate) number = 4;
    return number;
  };

  const isComplete = () => {
    return (
      location?.locationNetwork?.locationNetworkCreated &&
      surveyDate.getFullYear() !== 1 && surveyDate <= currentDate &&
      location?.locationNetwork?.equipmentOfferSent &&
      lineDate.getFullYear() !== 1 && lineDate <= currentDate
    );
  };

  const checkDate = date => {
    const dateToCheck = new Date(date);
    const currentDate = new Date(Date.now());
    return dateToCheck.getFullYear() !== 1 && dateToCheck <= currentDate;
  };

  const locationValue = isColored();
  const complete = isComplete();

  return (
    <div className='network-progress-box'>
      <div className='box-stepbar'>
        <StepBar variant='circle'>
          <StepBar.Item
            active={location?.locationNetwork?.locationNetworkCreated}
            className={locationValue > 1 ? nextChecked : locationValue === 1 ? currentChecked : prevChecked}
          >
            Location network created
          </StepBar.Item>
          <StepBar.Item
            active={checkDate(location?.locationNetwork?.siteSurvey)}
            className={locationValue > 2 ? nextChecked : locationValue === 2 ? currentChecked : prevChecked}
          >
            Site survey complete
          </StepBar.Item>
          <StepBar.Item
            active={location?.locationNetwork?.equipmentOfferSent}
            className={locationValue > 3 ? nextChecked : locationValue === 3 ? currentChecked : prevChecked}
          >
            Equipment offer sent
          </StepBar.Item>
          <StepBar.Item
            active={checkDate(location?.locationNetwork?.lineDate)}
            className={
              locationValue >= 4 && !complete
                ? `${nextChecked} ${currentChecked}`
                : locationValue === 4 && complete
                ? nextChecked
                : prevChecked
            }
          >
            Line date complete
          </StepBar.Item>
          <StepBar.Item active={complete} className={complete ? `${currentChecked} last-element` : prevChecked}>
            Ready to go live
          </StepBar.Item>
        </StepBar>
      </div>
      <div className='box-details'>
        <p>{location.name}</p>
        <div className='box-dates'>
          <p>
            <span>Site survey:</span> {getTime(surveyDate, 'Not set')}
          </p>
          <p>
            <span>Line date:</span> {getTime(lineDate, 'Not set')}
          </p>
        </div>
      </div>
    </div>
  );
};

NetworkProgressBox.propTypes = {
  location: PropTypes.object
};

export default NetworkProgressBox;
