import useMediaQuery from '@intility/bifrost-react/hooks/useMediaQuery';
import useLocalStorageState from 'use-local-storage-state';

export const useCurrentTheme = () => {
  const [localStorageTheme] = useLocalStorageState("bfTheme", {
    defaultValue: "system",
  });

  const systemDark = useMediaQuery("(prefers-color-scheme: dark)");

  if (localStorageTheme === 'dark' || localStorageTheme === 'light') return localStorageTheme;
  return systemDark ? 'dark' : 'light'
}

export default useCurrentTheme
