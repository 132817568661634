import {createReducer, createSlice} from '@reduxjs/toolkit';
import {
  fetchGlobalIntilityServicesSuccess,
  fetchInformationSuccess,
  fetchIntilityServiceListSuccess,
  fetchIntilityServicesSuccess,
  updateGlobalIntilityServiceSuccess,
  updateIntilityServiceInformationSuccess,
  updateIntilityServiceSuccess
} from './intilityServiceActions';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';

const updateOnIntilityServiceChangeReducer = getState => {
  return {
    [fetchIntilityServicesSuccess]: (state, action) => getState(action.payload, action.type, state),
    [updateIntilityServiceSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};
const updateOnGlobalIntilityServiceChangeReducer = getState => {
  return {
    [fetchGlobalIntilityServicesSuccess]: (state, action) => getState(action.payload, action.type, state),
    [updateGlobalIntilityServiceSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};

export const intilityServiceReducer = createReducer(
  [],
  updateOnIntilityServiceChangeReducer((payload, type, state) => {
    state = payload.intilityServicePlatform.intilityServiceList;
    return state;
  })
);
export const globalIntilityServiceReducer = createReducer(
  [],
  updateOnGlobalIntilityServiceChangeReducer((payload, type, state) => {
    state = payload.intilityServicePlatform.globalIntilityServiceList;
    return state;
  })
);

const updateOnIntilityServiceStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchIntilityServicesSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const intilityServiceStateReducer = createReducer(
  0,
  updateOnIntilityServiceStateReducer((payload, state) => {
    state = payload.intilityServicePlatform.state;
    return state;
  })
);

const updateOnChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchIntilityServicesSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateIntilityServiceInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const intilityServiceInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    listCount: 0
  },
  updateOnChangeReducer((payload, state) => {
    state.id = payload.intilityServicePlatform.information.id;
    state.companyId = payload.intilityServicePlatform.information.companyId;
    state.modifiedAt = payload.intilityServicePlatform.information.modifiedAt;
    state.listCount = payload.intilityServicePlatform.information.listCount;
    state.isEnabled = payload.intilityServicePlatform.information.isEnabled;
    return state;
  })
);

export const intilityServiceStatusReducer = createSlice({
  name: 'intilityServicesAction',
  initialState: {
    fetching: false,
    fetchingGlobal: false,
    updatingGlobal: false,
    error: false,
    errorGlobal: false
  },
  reducers: {
    fetchIntilityServices: state => {
      state.fetching = true;
    },
    fetchIntilityServicesError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateIntilityServices: state => {
      state.updating = true;
    },
    updateIntilityServicesError: state => {
      state.updating = false;
      state.error = true;
    },
    fetchGlobalIntilityServices: state => {
      state.fetchingGlobal = true;
    },
    fetchGlobalIntilityServicesError: state => {
      state.fetchingGlobal = false;
      state.errorGlobal = true;
    },
    updateGlobalIntilityServices: state => {
      state.updatingGlobal = true;
    },
    updateGlobalIntilityServicesError: state => {
      state.updatingGlobal = false;
      state.errorGlobal = true;
    },
  },
  extraReducers: {
    [fetchIntilityServicesSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateIntilityServiceSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchGlobalIntilityServicesSuccess]: state => {
      state.fetchingGlobal = false;
      state.updatingGlobal = false;
      state.errorGlobal = false;
    },
    [updateGlobalIntilityServiceSuccess]: state => {
      state.updatingGlobal = false;
      state.errorGlobal = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchIntilityServiceListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updateIntilityServiceInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchIntilityServices,
  fetchIntilityServicesError,
  fetchGlobalIntilityServices,
  fetchGlobalIntilityServicesError,
  updateGlobalIntilityServices,
  updateGlobalIntilityServicesError,
  updateIntilityServicesError,
  updateIntilityServices,
} = intilityServiceStatusReducer.actions;
