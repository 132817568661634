import { CreatableSelect} from "@intility/bifrost-react-select";
import InputWithFeedback from "../InputWithFeedback/InputWithFeedback";
import React, { useEffect, useState } from "react";
import './newContact.scss';
import PropTypes from "prop-types";
import apiFetch from "../../utils/apiFetch";
import { useSelector } from "react-redux";
import { validateContact } from "../../utils/wizard/stateValidations";
import { Contact } from "../../utils/hub/models";

export const NewContact = ({contact, editContact}) => { // TODO revisit later, fix onChange
  const {id} = useSelector(state => state.company.data.info);
  const [contactOptions, setContactOptions] = useState([]);
  const [contactOption, setContactOption] = useState();
  const [fetchingContacts, setFetchingContacts] = useState(true);
  const [errors, setErrors] = useState({ email: false, phoneNumber: false });

  useEffect(() => {
    if (!fetchingContacts) return;
    apiFetch(`/companies/${id}/contacts`)
      .then(response => {
        if (!response.ok) {
          setContactOptions([]);
          setFetchingContacts(false);
          return [];
        }
        return response.json()
      })
      .then(contacts => {
        const newContacts = contacts?.map(c => ({label: `${c.firstName} ${c.lastName}`, value: c}));
        setContactOptions(newContacts);
        setFetchingContacts(false);
      });
  }, [fetchingContacts])

  useEffect(() => {
    if (contact?.email.length === 0 && contact?.email.length === 0 && contact?.firstName.length === 0 && contact?.lastName.length === 0) return;
    const user = contactOptions?.find(u => u?.value?.email === contact?.email || u?.value?.mobilePhone === contact?.phoneNumber);
    if (user !== undefined) {
      setContactOption(user);
    }
  }, [contactOptions]);

  useEffect(() => {
    setErrors(validateContact(contact));
  }, [contact])

  const handleCreate = option => {
    const newOption = {
      value: ({ ...new Contact(), firstName: option }),
      label: option
    }
    setContactOptions(options => [...options, newOption])
    setContactOption(newOption);
    editContact('locationContact', 'firstName', option);
    editContact('locationContact', 'lastName', '');
    editContact('locationContact', 'email', '');
    editContact('locationContact', 'phoneNumber', '');
  }

  const onContactChange = e => {
    const {name, value} = e.target;
    editContact('locationContact', name, value);
  };

  const onUserChange = e => {
    const {value} = e;
    setErrors(validateContact(value));
    setContactOption(e);
    editContact('locationContact', 'firstName', value.firstName);
    editContact('locationContact', 'lastName', value.lastName);
    editContact('locationContact', 'email', value.email);
    editContact('locationContact', 'phoneNumber', value.phoneNumber);
  };

  return (
    <div className='new-contact-container'>
      <CreatableSelect
        label='Choose from existing onboard contacts and users'
        hideLabel
        description='Local resource with IT responsibilities and/or, who can coordinate onsite work at the location.'
        placeholder='Search / Create contact'
        value={contactOption}
        options={contactOptions}
        name='contact'
        onChange={e => onUserChange(e)}
        onCreateOption={option => handleCreate(option)}
      />
      <div className='bfl-grid contact-content'>
        <InputWithFeedback onChange={onContactChange} value={contact?.firstName} name='firstName' label='First Name' />
        <InputWithFeedback onChange={onContactChange} value={contact?.lastName} name='lastName' label='Last Name' />
        <InputWithFeedback
          onChange={onContactChange}
          value={contact?.phoneNumber}
          name='phoneNumber'
          label='Phone Number'
          otherProps={
            (errors?.phoneNumber === true && errors?.email === true) && {
              state: 'warning',
              feedback: 'Need email or phone number'
            }
          }
        />
        <InputWithFeedback
          onChange={onContactChange}
          value={contact?.email}
          name='email'
          label='E-mail'
          otherProps={
            (errors?.phoneNumber === true && errors?.email) === true && {
              state: 'warning',
              feedback: 'Need email or phone number'
            }
          }
        />
      </div>
    </div>
  )

}

NewContact.propTypes = {
  contact: PropTypes.object,
  editContact: PropTypes.func,
  validation: PropTypes.bool
}

export default NewContact;