import {useEffect, useState} from 'react';
import useRolePermission from './useRolePermission';
import {useSelector} from 'react-redux';

const useEditPermission = () => {
  const hasRole = useRolePermission(['Admin', 'Writer', 'Reader']);
  const {companyMemberships} = useSelector(state => state.auth.user);
  const {id} = useSelector(state => state.company.data.info);
  const [accessToCompany, setAccessToCompany] = useState(false);

  useEffect(() => {
    const currentCompanyOnUser = companyMemberships?.some(
      userCompanyId => userCompanyId.companyId === id && userCompanyId.userRight !== 2
    );

    if (currentCompanyOnUser) {
      setAccessToCompany(true);
    }
    return () => {
      setAccessToCompany(false);
    };
  }, [companyMemberships, id]);

  return hasRole || accessToCompany;
};

export default useEditPermission;
