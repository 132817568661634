import {createAction} from '@reduxjs/toolkit';
import {
  addMemberRoles,
  addProjectTeam,
  deleteProjectTeam,
  editProjectTeam,
  normalizeMemberRoles,
  normalizeProjectTeams,
  normalizeResourceTeams
} from './projectTeamsNormalizers';

export const fetchProjectTeamsSuccess = createAction(
  'projectTeamsAction/fetchProjectTeamsSuccess',
  apiProjectTeams => ({
    payload: normalizeProjectTeams(apiProjectTeams)
  })
);

export const addProjectTeamSuccess = createAction('projectTeamsAction/addProjectTeamSuccess', (team, teamList) => ({
  payload: addProjectTeam(teamList, team)
}));

export const deleteProjectTeamSuccess = createAction(
  'projectTeamsAction/deleteProjectTeamSuccess',
  (teamId, teamList) => ({
    payload: deleteProjectTeam(teamList, teamId)
  })
);

export const updateProjectTeamSuccess = createAction(
  'projectTeamsAction/updateProjectTeamSuccess',
  (team, teamList) => ({
    payload: editProjectTeam(teamList, team)
  })
);

export const fetchMemberRolesSuccess = createAction('projectTeamsAction/fetchMemberRolesSuccess', apiProjectTeams => ({
  payload: normalizeMemberRoles(apiProjectTeams)
}));

export const fetchResourceTeamsSuccess = createAction('projectTeamsAction/fetchResourceTeamsSuccess', apiDetails => ({
    payload: normalizeResourceTeams(apiDetails)
}));

export const addMemberRoleSuccess = createAction('projectTeamsAction/updateMemberRoleSuccess', (role, roleList) => ({
  payload: addMemberRoles(roleList, role)
}));
