import React from 'react';
import './loader.scss';

/**
 * Basic spinning circle thing
 */
const Loader = () => {
  return (
    <div className='loader'>
      <i className='far fa-spinner-third loading-spinner' />
    </div>
  );
};

export default Loader;
