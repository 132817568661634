import {faContactCard} from '@fortawesome/pro-regular-svg-icons';
import PageState from '../pageState';
import ContactUs from '../../../components/Hub/Pages/ContactUs/ContactUs';

export default {
  title: 'Contact us',
  path: 'contactUs',
  icon: faContactCard,
  isEnabled: (company) => {return PageState.ENABLED},
  component: ContactUs,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Intility Services';
  }
};
