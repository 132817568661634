import React from "react";
import './box.scss';
import {faPlus, faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import {ButtonWithTooltip} from '../ButtonWithTooltip/ButtonWithTooltip';
import {Button, Checkbox, Icon, useFloatingMessage} from '@intility/bifrost-react';
import useIntilityPermission from "../../hooks/useIntilityPermission";
import { IntilityPermission } from "../Permissions";

/**
 * Simple Box with color and border.
 */
const Box = ({
  children,
  title,
  isComplete,
  setIsComplete,
  togglePrompt,
  buttonText,
  buttonClick,
  buttonIcon,
  isBlocked,
  hideButtonIcon,
  cancelButton,
  showCancelButton,
  confirmButton,
  isConfirmed,
  noTaskBox,
  ...props
}) => {
  const intilityUser = useIntilityPermission();
  const { showFloatingMessage } = useFloatingMessage()

  const toggle = () => {
    if (isBlocked) {
      showFloatingMessage('Missing required content, please review page.');
    } else if (togglePrompt && !isComplete) {
      const result = confirm('You will not be able to make additional changes here once toggled. Future additions/edits will need to be made in the Intility portal. Do you wish to proceed?');
      if (result) setIsComplete(true);
    } else if (togglePrompt && isComplete && !intilityUser) {
      showFloatingMessage('Changes locked, future changes will be handled by Intility portal.');
    } else {
      setIsComplete(x => !x);
    }
  };

  return (
    <div className='box-container'>
      <div className='box-container-checker'>
        <div className={isComplete ? "box-container-btngrp bfl-grid" : "box-container-btngrp unchecked bfl-grid"}>
          {noTaskBox ?
            <div/>
            :
            <p className='btngrp-label bf-strong'><span><Icon icon={faExclamationTriangle}/>
            </span> {
              isComplete ? ' The task is complete, uncheck if you would like to edit the information'
              : ' Complete the task to let Intility know you’ve filled in all your information'
            }</p>
          }
          <div className='btngrp-btns bfl-grid'>
            {intilityUser && (confirmButton && isComplete) &&
              <Button className='btngrp-btn' disabled={isConfirmed} onClick={confirmButton}>
                {isConfirmed ? 'Content has been approved' : 'Confirm content'}
              </Button>
            }
            {isComplete !== undefined && (
              <Checkbox className={isComplete ? 'btngrp-btn' : "btngrp-btn unchecked"} button label='Complete task' checked={isComplete} onChange={() => toggle()} />
            )}
            {cancelButton && showCancelButton && (
              <Button className='btngrp-btn' variant='outline' title={'cancel'} onClick={cancelButton}>
                Cancel
              </Button>
            )}
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
          {title && <h2>{title}</h2>}
          {buttonText && (
            <div>
              <ButtonWithTooltip
                isComplete={isComplete}
                icon={hideButtonIcon ? undefined : buttonIcon || faPlus}
                buttonSpecs={{variant: 'filled'}}
                btnOnClick={buttonClick}
              >
                {buttonText}
              </ButtonWithTooltip>
            </div>
          )}
        </div>
      </div>
      <div {...props} className={`box ${props.className || ''}`}>
        {children}
      </div>
    </div>
  );
};

Box.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  title: PropTypes.string,
  isComplete: PropTypes.bool,
  setIsComplete: PropTypes.func,
  cancelButton: PropTypes.func,
  showCancelButton: PropTypes.bool,
  togglePrompt: PropTypes.bool,
  isBlocked: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.object,
  buttonClick: PropTypes.func,
  hideButtonIcon: PropTypes.bool,
  className: PropTypes.string,
  confirmButton: PropTypes.func,
  isConfirmed: PropTypes.bool,
  noTaskBox: PropTypes.bool,
};

export default Box;

/**
 * Simple Box with color and border.
 */
export const SimpleBox = ({
               children,
               title,
               isComplete,
               fullScreen,
               buttonText,
               buttonClick,
               buttonIcon,
               hideButtonIcon,
               intilityButton,
               ...props
             }) => {

  return (
    <div className='box-container'>
      <div className='box-container-checker'>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
          {(title && !fullScreen) && <h2>{title}</h2>}
          {buttonText && (
            intilityButton ?
              <IntilityPermission>
                <ButtonWithTooltip
                  isComplete={isComplete}
                  icon={hideButtonIcon ? undefined : buttonIcon || faPlus}
                  buttonSpecs={{variant: 'filled'}}
                  btnOnClick={buttonClick}
                >
                  {buttonText}
                </ButtonWithTooltip>
              </IntilityPermission> :
            <div>
              <ButtonWithTooltip
                isComplete={isComplete}
                icon={hideButtonIcon ? undefined : buttonIcon || faPlus}
                buttonSpecs={{variant: 'filled'}}
                btnOnClick={buttonClick}
              >
                {buttonText}
              </ButtonWithTooltip>
            </div>
          )}
        </div>
      </div>
      <div {...props} className={`box ${props.className || ''}`}>
        {children}
      </div>
    </div>
  );
};

SimpleBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.string]),
  title: PropTypes.string,
  isComplete: PropTypes.bool,
  fullScreen: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.object,
  buttonClick: PropTypes.func,
  hideButtonIcon: PropTypes.bool,
  intilityButton: PropTypes.bool,
};