import './domainDetails.scss';
import { Button, Input, Pagination, ProgressBar } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDomainsAsync } from "../../../redux/company/domain/domainThunks";
import DomainProgressBox from "../Components/DomainProgressBox";

export const countPercentageRow = (domain, percentage) => {
  if (!domain?.moveToIntility) {
    return percentage ? 0 : -1;
  }
  let checked = 0;
  if (domain?.domainStatus?.establishedDialogue > 0) {
    checked += 1;
  }
  checked += domain?.domainStatus?.scheduledTransfer;
  return checked;
};

export const DomainDetails = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {domainList} = useSelector(state => state.company.data.domainPlatform);
  const [sort, setSort] = useState(0);
  const [search, setSearch] = useState('');
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const dispatch = useDispatch();
  let totalNumber = 0;
  let currentNumber = 0;
  let inProgress = 0;
  let ready = 0;
  let notRelevant = 0;

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchDomainsAsync(id));
  }, [user, dispatch, id]);

  const sortedData = domainList
    .filter(a => a.isEnabled === true)
    .filter(s => s.name?.toLowerCase().includes(search.toLowerCase()));

  domainList && sortedData?.forEach(l => {
    const test = countPercentageRow(l);
    totalNumber++;
    currentNumber += test >= 0 ? test : 0;
    if (test === 3) {
      ready++;
    } else if (test >= 0 && test < 3) {
      inProgress++;
    } else if (test === -1) {
      notRelevant++;
    }
  });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: domainList?.length, label: 'All'});
    return tableOptions;
  };

  const filteredData = sortedData && sortedData.filter(s => {
    if (sort === 0) {
      return s;
    } else if (sort === 1) {
      return countPercentageRow(s) < 3 && countPercentageRow(s) >= 0;
    } else if (sort === 2) {
      return countPercentageRow(s) === 3;
    } else if (sort === 3) {
      return countPercentageRow(s) === -1;
    }
    return s;
  });
  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = filteredData && filteredData.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div id='backoffice-application-details'>
      <p className='bf-strong'>Total progress:</p>
      <p style={{color: 'var(--bfc-base-c-2)'}}>
        Showing the total progress of all domains
      </p>
      <div className='progress-bar'>
        <ProgressBar size={'large'} value={Math.round((100 / ((totalNumber - notRelevant) * 3)) * currentNumber)} />
      </div>
      <div className='button-bar'>
        <Input
          label='Search'
          placeholder='Search for application'
          hideLabel={true}
          icon={faSearch}
          onChange={e => setSearch(e.target.value)}
        />
        <div className='button-bar-group'>
          <Button.Group>
            <Button active={sort === 0} onClick={() => setSort(0)}>
              Show all ({totalNumber})
            </Button>
            <Button active={sort === 1} onClick={() => setSort(1)}>
              In progress ({inProgress})
            </Button>
            <Button active={sort === 2} onClick={() => setSort(2)}>
              Ready ({ready})
            </Button>
            <Button active={sort === 3} onClick={() => setSort(3)}>
              Not relevant ({notRelevant})
            </Button>
          </Button.Group>
        </div>
      </div>
      <div className='network-details-content'>
        {sortedAndIndexedData.map(domain => {
          return <DomainProgressBox key={domain.id} domain={domain} />
        })}
      </div>
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
    </div>
  )

}

export default DomainDetails