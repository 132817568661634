import produce from 'immer';
import {apiFetch, apiFetchIdCollection} from 'utils/apiFetch';
import {
  addApplication,
  addApplicationError,
  addApplicationFilter,
  addApplicationFilterError,
  deleteApplicationFilter,
  deleteApplicationFilterError,
  fetchApplicationFilters,
  fetchApplicationFiltersError,
  fetchApplications,
  fetchApplicationsError,
  seedingApplications,
  seedingApplicationsError,
  seedingApplicationsSuccess,
  seedingApplicationUsers,
  seedingApplicationUsersError,
  seedingApplicationUsersSuccess,
  updateApplication,
  updateApplicationError,
  updateApplications,
  updateApplicationsError,
  uploadApplicationsError
} from './applicationReducers';
import {
  addApplicationFilterSuccess,
  addApplicationSuccess,
  deleteApplicationFilterSuccess,
  fetchApplicationFiltersSuccess,
  fetchApplicationsSuccess,
  toggleApplicationSuccess,
  updateApplicationsSuccess,
  updateApplicationSuccess,
  updateApplicationUserSuccess
} from './applicationActions';
import {updateDocumentation, uploadDocumentationError} from '../documentation/documentationReducers';
import {fetchDocumentationAsync} from '../documentation/documentationThunks';
import {Application} from '../../../utils/hub/models';
import {emptyGuid} from '../../../utils/guid';

export const fetchApplicationAsync = applicationId => (dispatch, getState) => {
  const {applicationStatus, applicationList} = getState().company.data.applicationPlatform;
  if (applicationStatus.fetching) return null;
  dispatch(fetchApplications());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applications/${applicationId}`,
    method: 'GET'
  }).then(application => dispatch(updateApplicationsSuccess(application, applicationList)))
    .catch(error => dispatch(fetchApplicationsError(error)));
};

export const fetchApplicationsAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.applicationPlatform.applicationStatus;
  if (fetching) return null;
  dispatch(fetchApplications());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/applications`,
    method: 'GET'
  }).then(application => dispatch(fetchApplicationsSuccess(application)))
    .catch(error => dispatch(fetchApplicationsError(error)));
};

export const seedIamsApplicationsAsync = () => (dispatch, getState) => {
  const {seeding} = getState().company.data.applicationPlatform.applicationStatus;
  if (seeding) return null;
  dispatch(seedingApplications());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applications/update`,
    method: 'GET'
  }).then(() => {
    dispatch(fetchApplicationsAsync(id));
    dispatch(seedingApplicationsSuccess())
  }).catch(error => {
    dispatch(seedingApplicationsError(error))
    dispatch(fetchApplicationsError(error))
  });
};

export const seedIamsApplicationUsersAsync = () => (dispatch, getState) => {
  const {seeding} = getState().company.data.applicationPlatform.applicationStatus;
  if (seeding) return null;
  dispatch(seedingApplicationUsers());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applications/updateAppUsers`,
    method: 'GET'
  }).then(() => {
    dispatch(fetchApplicationsAsync(id));
    dispatch(seedingApplicationUsersSuccess())
  }).catch(error => {
    dispatch(seedingApplicationUsersError(error))
    dispatch(fetchApplicationsError(error))
  });
};

export const fetchApplicationFiltersAsync = () => (dispatch, getState) => {
  const {fetchingFilters} = getState().company.data.applicationPlatform.applicationStatus;
  if (fetchingFilters) return null;
  dispatch(fetchApplicationFilters());

  return apiFetchIdCollection(apiFetch, {
    url: `/applicationFilter`,
    method: 'GET'
  }).then(filters => dispatch(fetchApplicationFiltersSuccess(filters)))
    .catch(error => dispatch(fetchApplicationFiltersError(error)));
};

export const addApplicationFilterAsync = filter => (dispatch, getState) => {
  const {filters} = getState().company.data.applicationPlatform;
  const {addingFilter} = getState().company.data.applicationPlatform.applicationStatus;
  if (addingFilter) return null;
  dispatch(addApplicationFilter());

  return apiFetchIdCollection(apiFetch, {
    url: `/applicationFilter`,
    method: 'POST',
    body: filter
  })
    .then((item) => dispatch(addApplicationFilterSuccess(item, filters)))
    .catch(error => dispatch(addApplicationFilterError(error)));
};

export const toggleApplicationAsync = applicationId => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplication());

  const {id} = getState().company.data.info;
  const {applicationList} = getState().company.data.applicationPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applications/${applicationId}/toggle`,
    method: 'PATCH'
  })
    .then(() => dispatch(toggleApplicationSuccess(applicationId, applicationList)))
    .catch(error => dispatch(updateApplicationError(error)));
};

export const deleteApplicationFilterAsync = filterId => (dispatch, getState) => {
  const {filters} = getState().company.data.applicationPlatform;
  const {deletingFilter} = getState().company.data.applicationPlatform.applicationStatus;
  if (deletingFilter) return null;
  dispatch(deleteApplicationFilter());

  return apiFetchIdCollection(apiFetch, {
    url: `/applicationFilter/${filterId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(deleteApplicationFilterSuccess(filterId, filters)))
    .catch(error => dispatch(deleteApplicationFilterError(error)));
};

export const multiPutAppStatusAsync = applicationList => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;
  dispatch(updateApplications());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applicationStatus/bulk`,
    method: 'PUT',
    body: applicationList
  })
    .then(() => dispatch(fetchApplicationsAsync(id)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const updateApplicationAsync = application => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplication());

  const {information} = getState().company.data.applicationPlatform;
  const {applicationList} = getState().company.data.applicationPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/${application.id}`,
    method: 'PUT',
    body: application
  })
    .then((res) => dispatch(updateApplicationSuccess(res, applicationList)))
    .catch(error => dispatch(updateApplicationError(error)));
};

export const addApplicationAsync = application => (dispatch, getState) => {
  const {adding} = getState().company.data.applicationPlatform.applicationStatus;
  if (adding) return null;
  dispatch(addApplication());

  const {information} = getState().company.data.applicationPlatform;
  const {applicationList} = getState().company.data.applicationPlatform;

  const newApp = produce(application, draft => {
    draft.applicationPlatformId = information.id;
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications`,
    method: 'POST',
    body: newApp
  })
    .then(res => {
      dispatch(addApplicationSuccess(res, applicationList));
      return res;
    })
    .catch(error => dispatch(addApplicationError(error)));
};

export const addApplicationsAsync = applications => (dispatch, getState) => {
  const {adding} = getState().company.data.applicationPlatform.applicationStatus;
  if (adding) return null;
  dispatch(addApplication());

  const {information} = getState().company.data.applicationPlatform;
  const {applicationList} = getState().company.data.applicationPlatform;

  const newApps = applications.map(application => 
    produce(application, draft => {
      draft.applicationPlatformId = information.id;
    })
  );

  const reformattedApps = newApps.map(app => {
    const {
      vendorContactFirstName,
      vendorContactLastName,
      vendorContactEmail,
      vendorContactPhoneNumber,
      ...rest
    } = app;

    return {
      ...rest,
      vendorContacts: [{
        firstName: vendorContactFirstName,
        lastName: vendorContactLastName,
        email: vendorContactEmail,
        phoneNumber: vendorContactPhoneNumber,
      }],
    };
  });

  console.log(reformattedApps);

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/range`,
    method: 'POST',
    body: reformattedApps
  })
    .then(res => {
      dispatch(addApplicationSuccess(res, [...applicationList, ...reformattedApps]));
      return res;
    })
    .catch(error => dispatch(addApplicationError(error)));
};

export const uploadApplicationFilesAsync = (applicationId, files) => (dispatch, getState) => {
  const {updating} = getState().company.data.documentationPlatform.documentationStatus;
  if (updating) return null;
  dispatch(updateDocumentation());

  const {id} = getState().company.data.info;

  const formData = new FormData();

  files.forEach(file => {
    formData.append(id + '/' + file.name, file);
  });

  return apiFetch(`/companies/${id}/applications/${applicationId}/file`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchDocumentationAsync(id)))
    .catch(error => {
      console.log(error);
      dispatch(uploadDocumentationError(error));
    });
};

export const addCompleteApplicationAsync = application => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null; // TODO update when backend ready

  const {information} = getState().company.data.applicationPlatform;

  const identity = [];
  application.activeDirectory && identity.push('activeDirectory');
  application.azureAd && identity.push('azureAd');
  application.appSpecificUser && identity.push('appSpecificUser');

  const body = produce(new Application(), draft => {
    draft.applicationPlatformId = information.id;
    draft.companyId = information.companyId;
    draft.id = emptyGuid;
    draft.name = application.name;
    draft.vendor = application.vendor || '';
    draft.identity = application.identity;
    draft.environment = application.extraNotRelevant === true ? '' : application.environment;
    draft.url = application.url ? application.url : 'N/A';
    draft.location = application.location ? application.location : 'N/A';
    draft.currentVersion = application.currentVersion || '';
    draft.newVersion = application.newVersion || '';
    draft.comment = application.comment ? application.comment : 'N/A';
    draft.appSpecificCred = application.appSpecificUser || 0;
    draft.browserAccess = application.browserAccess || 0;
    draft.virtualClient = application.virtualClient || 0;
    draft.description = application.nickname ? application.nickname : 'N/A';
    draft.serverApp = application.serverApp || 0;
    draft.saasApp = application.saasApp || 0;
    draft.clientApp = application.clientApp || 0;
    draft.vendorStatus = application.vendorStatus || 0;
    draft.internalStatus = application?.internalContacts?.length > 0 ? 0 : 2;
    draft.integrationStatus = application.integrationStatus || 0;
    draft.productId = application.productId || null;
    draft.readyStatus = 1;
    draft.singleSignOnToday = '';
    draft.softwareLicense = 0; // TODO mangler
    draft.vendorContacts = application.vendorContacts || [];
    draft.internalContacts = application.internalContacts || [];
    draft.childApplications = application.applicationRelations?.map(r => ({applicationRelationType: r.applicationRelationType, childId: r.relationId, parentId: emptyGuid})) || [];
    draft.parentApplications = [];
    draft.companyUsers = [];
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/complete`,
    method: 'POST',
    body: body
  })
    .then(res => {
      dispatch(fetchApplicationsAsync(information.companyId));
      return res;
    })
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const addApplicationsToApplicationAsync = (applicationId, childId, relationType) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {information} = getState().company.data.applicationPlatform;

  const relation = {
    applicationRelationType: 1,
    parentId: applicationId,
    childId: childId
  };

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/${applicationId}/relations`,
    method: 'POST',
    body: relation
  })
    .then(() => dispatch(fetchApplicationsAsync(information.companyId)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const addCompanyUserApplicationAsync = (relation) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;
  const {applicationList} = getState().company.data.applicationPlatform;

  const body = relation.map(relation => relation);

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applications/${relation[0].applicationId}/companyUser`,
    method: 'POST',
    body: body
  }).then(res => dispatch(updateApplicationUserSuccess(res, applicationList, false)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const deleteCompanyUserApplicationAsync = (relation) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;
  const {applicationList} = getState().company.data.applicationPlatform;

  const body = relation.map(relation => relation);

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applications/${relation[0].applicationId}/companyUser`,
    method: 'PATCH',
    body: body
  }).then(res => dispatch(updateApplicationUserSuccess(res, applicationList, true)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const deleteApplicationAsync = applicationId => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applications/${applicationId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchApplicationsAsync(id)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const addApplicationCommentAsync = (comment, statusId) => (dispatch, getState) => {
  const {applicationStatus} = getState().company.data.applicationPlatform;
  if (applicationStatus.updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applicationStatus/${statusId}/comment`,
    method: 'POST',
    body: comment
  })
    .then(() => dispatch(fetchApplicationsAsync(id)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const editApplicationCommentAsync = (comment) => (dispatch, getState) => {
  const {applicationStatus} = getState().company.data.applicationPlatform;
  if (applicationStatus.updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applicationStatus/${comment?.applicationStatusId}/comment/${comment?.id}`,
    method: 'PATCH',
    body: comment
  })
    .then(() => dispatch(fetchApplicationsAsync(id)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const deleteApplicationCommentAsync = (statusId, commentId) => (dispatch, getState) => {
  const {fetching} = getState().company.update;
  if (fetching) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/applicationStatus/${statusId}/comment/${commentId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchApplicationsAsync(id)))
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const uploadCsv = (file, replace) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;
  const formData = new FormData();
  formData.append(`csv/${id}`, file);

  return apiFetch(`/companies/${id}/applications/csv?replace=${replace}`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchApplicationsAsync(id)))
    .catch(error => {
      console.log(error);
      dispatch(uploadApplicationsError(error));
    });
};

export const uploadClientAppsCsv = (file) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;
  const formData = new FormData();
  formData.append(`csv/${id}`, file);

  return apiFetch(`/companies/${id}/applications/iamsLikeAppsCsv`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchApplicationsAsync(id)))
      .catch(error => {
        console.log(error);
        dispatch(uploadApplicationsError(error));
      });
};

export const mapUserAppsCsv = (file) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {id} = getState().company.data.info;
  const formData = new FormData();
  formData.append(`csv/${id}`, file);

  return apiFetch(`/companies/${id}/applications/userAppsCsv`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchApplicationsAsync(id)))
      .catch(error => {
        console.log(error);
        dispatch(uploadApplicationsError(error));
      });
};

export const downloadCsv = () => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;

  return apiFetch(`/companies/${id}/applications/csv`, {
    method: 'GET'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to download CSV.');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `applications_${id}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .then(res => res.flush())
    .catch(error => console.log(error));
};
