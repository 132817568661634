import {faPhoneLaptop} from '@fortawesome/pro-solid-svg-icons';
import ComputerRegistration
  from '../../../components/Hub/Pages/Devices.Computers/ComputerRegistration/ComputerRegistration';
import ComputerCustomization from '../../../components/Hub/Pages/Devices.Computers/Customization/ComputerCustomization';

export default {
  title: 'Computer Registration',
  path: 'computers/registration',
  icon: faPhoneLaptop,
  isEnabled: (company) => {return company?.iamsUserPlatform?.state},
  component: ComputerRegistration
};

export const CustomData = {
  title: 'Client Design',
  path: 'computers/clientDesign',
  icon: faPhoneLaptop,
  isEnabled: (company) => {return company?.computerSetupPlatform?.state},
  component: ComputerCustomization
};
