import {createAction} from '@reduxjs/toolkit';
import {editOnboardTicket, normalizeOnboardTickets} from './onboardTicketNormalizers';

export const fetchOnboardTicketsSuccess = createAction('onboardTicketsAction/fetchOnboardTicketsSuccess', apiOnboardTickets => ({
  payload: normalizeOnboardTickets(apiOnboardTickets)
}));

export const updateOnboardTicketSuccess = createAction('onboardTicketsAction/updateOnboardTicketSuccess', (apiOnboardTicket, onboardTicketList) => ({
  payload: editOnboardTicket(onboardTicketList, apiOnboardTicket)
}));
