import React, {useEffect, useState} from 'react';
import apiFetch from "../../../utils/apiFetch";
import { useSelector } from "react-redux";
import { Badge, FieldGroup, FormatDate, Input, Pagination, Spinner, Table } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { emptyGuid } from "../../../utils/guid";
import './logs.scss';

export const SearchOptions = [
  {value: '@timestamp', label: 'Timestamp'},
  {value: 'Action', label: 'Action'},
  {value: 'Message', label: 'Message'},
  {value: 'Entity', label: 'Entity'},
  {value: 'User', label: 'User'},
];

export const Logs = () => {
  const {id, name} = useSelector(state => state.company.data.info);
  const {data} = useSelector(state => state.companies);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [searchCriterea, setSearchCriterea] = useState({value: '@timestamp', label: 'Timestamp'});
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState({key: 'Timestamp', direction: 'desc'});
  const [logs, setLogs] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([{value: emptyGuid, label: 'All'}])
  const [company, setCompany] = useState({value: id, label: name})
  const [loading, setLoading] = useState(false);

  const fetchLogs = (setValue, id) => {
    setLoading(true);
    apiFetch(`/companies/${id}/elastic`)
      .then(response => {
        if (!response.ok) {
          setValue([]);
          setLoading(false);
          return [];
        }
        return response.json()
      })
      .then(logs => {
        setValue(logs?.hits?.hits)
        setLoading(false);
      })
  };

  useEffect(() => {
    setCompany({value: id, label: name})
  }, [id]);

  useEffect(() => {
    const companyArray = [{value: emptyGuid, label: 'All'}];
    data && data.forEach(c => {
      companyArray.push({value: c.id, label: `${c.name}`})
    });
    setCompanyOptions(companyArray);
  }, [data])

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData && sortedData.forEach((a, i) => {
      if (i >= counter) {
        tableOptions.push({value: counter, label: `${counter}`})
        counter = (counter * 2);
      }
    })
    tableOptions.push({value: logs?.length, label: 'All'});
    return tableOptions;
  };

  const getAction = (action) => {
    switch (action) {
      case "Added":
        return <Badge state={"success"}>Added</Badge>
      case "Modified":
        return <Badge state={"warning"}>Modified</Badge>
      case "Deleted":
        return <Badge state={"alert"}>Deleted</Badge>
    }
  }

  const formatDate = (date) => {
    const formatedDate = new Date(date);
    return formatedDate.toLocaleDateString() + ' - ' + formatedDate.toLocaleTimeString()
  }

  useEffect(() => {
    if (company.value !== undefined) {
      fetchLogs(setLogs, company.value)
    }
  }, [company])

  const sortedData = logs && [...logs]
    .sort((a, b) => {
      if (sort.key === 'Timestamp' && sort.direction === 'asc') {
        return a._source['@timestamp']?.localeCompare(b._source['@timestamp']);
      } else if (sort.key === 'Timestamp' && sort.direction === 'desc') {
        return b._source['@timestamp']?.localeCompare(a._source['@timestamp']);
      } else if (sort.direction === 'asc') {
        return a._source?.fields[sort.key]?.localeCompare(b._source?.fields[sort.key]);
      } else {
        return b._source?.fields[sort.key]?.localeCompare(a._source?.fields[sort.key]);
      }
    }).filter(s => {
      if (searchCriterea.value === '@timestamp') {
        return s._source[searchCriterea.value]?.toLowerCase().includes(searchValue.toLowerCase());
      } else {
        return s._source?.fields[searchCriterea.value]?.toLowerCase().includes(searchValue.toLowerCase());
      }
    });

  const indexOfLastUser = page * pageSize.value;
  const indexOfFirstUser = indexOfLastUser - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstUser, indexOfLastUser);

  return(
    <div className='elastic-logs-page'>
      <div className='content-top-bar bfl-grid'>
        <FieldGroup label='Search'>
          <Select
            label='search criterea'
            hideLabel
            className='select-group-select'
            value={searchCriterea}
            options={SearchOptions}
            onChange={item => setSearchCriterea(item)}
          />
          <Input
            className='select-group-input'
            placeholder={`Search for ${searchCriterea.label}`}
            label='search input'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
          <Select
            key='Filter'
            label='Filter type'
            hideLabel
            placeholder='Filter type'
            className='table-size-dropdown'
            value={company}
            options={companyOptions}
            onChange={setCompany}
          />
        </FieldGroup>
      </div>
      {loading ? <div style={{display: 'flex', justifyContent: 'center'}}>
        <Spinner size={100} label='Loading logs…'/>
      </div> :
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('Timestamp')} onClick={onSortChangeCreator('Timestamp')}>Timestamp</Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('Action')} onClick={onSortChangeCreator('Action')}>Action</Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('Message')} onClick={onSortChangeCreator('Message')}>Mesage</Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('Entity')} onClick={onSortChangeCreator('Entity')}>Entity</Table.HeaderCell>
              <Table.HeaderCell sorting={getSortProp('User')} onClick={onSortChangeCreator('User')}>User</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedAndIndexedData && sortedAndIndexedData?.map((l, i) => {
              return (
                <Table.Row key={i} content={
                  <div style={{ padding: '20px' }}>
                    <div>
                      {l?._source?.fields?.Data}
                    </div>
                  </div>
                }>
                  <Table.Cell><FormatDate date={new Date(l?._source["@timestamp"])} show={"datetime"} /></Table.Cell>
                  <Table.Cell>{getAction(l?._source?.fields?.Action)}</Table.Cell>
                  <Table.Cell>{l?._source?.fields?.Message}</Table.Cell>
                  <Table.Cell>{l?._source?.fields?.Entity}</Table.Cell>
                  <Table.Cell>{l?._source?.fields?.User}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      }
      <div className='bottom-line'>
        <div />
        {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
          <Pagination
            style={{marginTop: 'var(--bfs16)'}}
            totalPages={Math.ceil(sortedData?.length / pageSize.value)}
            currentPage={page}
            onChange={e => {
              setPage(e);
            }}
          />
        ) : (
          <div className='row-box' />
        )}
        <Select
          key='Items'
          label='Show users'
          className='row-box'
          hideLabel
          value={pageSize}
          options={tableSizeOptions()}
          onChange={item => {
            setPage(1);
            setPageSize(item);
          }}
        />
      </div>
    </div>
  )


}

export default Logs