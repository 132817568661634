import {faServer} from '@fortawesome/pro-regular-svg-icons';
import UserAdmin from '../../components/BackOffice/UserAdmin/UserAdmin';

export default {
  title: 'Useradmin',
  path: 'useradmin/',
  icon: faServer,
  editable: false,
  component: UserAdmin,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
