import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import './computerRegistration.scss';
import windowsGuide from 'assets/files/IAMS_Guide_Windows.docx';
import { SimpleBox } from "components/Box/Box";
import BasicPage from '../../BasicPage';
import ComputerRegistrationSlider from './ComputerRegistrationSlider/ComputerRegistrationSlider';
import { Button } from "@intility/bifrost-react";
import {
  faFileDownload, faBook, faEnvelope
} from "@fortawesome/pro-regular-svg-icons";
import IamsRegistration from '../IamsRegistration/IamsRegistration';
import ComputerRegistrationBox from "./ComputerRegistrationBox/ComputerRegistrationBox";
import PropTypes from "prop-types";
import { taskType } from "../../../../../utils/hub/pageData/pageDataOptions";
import { toggleService } from "../../../../../redux/company/companyThunks";
import { platformType } from "../../../../../redux/company/platformUpdateThunk";

const ComputerRegistration = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {information, iamsUserList} = useSelector(state => state.company.data.iamsUserPlatform);
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const dispatch = useDispatch();

  const toggleComputerRegistration = value => {
    dispatch(toggleService(platformType.IAMSUSERS, isComplete));
    setIsComplete(value);
  };

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  return (
    <BasicPage id='computer-registration' taskType={taskType.COMPUTERS}
               isComplete={isComplete}
               setIsComplete={toggleComputerRegistration}>
      <div className='content-main'>
        <SimpleBox
          className='computer-registration-system-box'
          title='Computer registration'
        >
          <div className='computer-registration-box-container bfl-grid'>
            <ComputerRegistrationBox step={1} icon={faBook} header='How does this work?' >
              <p>We need to register all devices in your company. In order to do this as efficient as possible, we created IAMS.</p>
              <Button pill small onClick={() => window.open(windowsGuide, "_blank")}>Read guide</Button>
            </ComputerRegistrationBox>
            <ComputerRegistrationBox step={2} icon={faFileDownload} header='Start IAMS' >
              <p>Intility Asset Management System (IAMS) makes it easy to register all devices in your company.</p>
              <Button pill small onClick={() => window.open(`https://iams.intility.com/company/${id}`, "_blank")}>Download IAMS</Button>
            </ComputerRegistrationBox>
            <ComputerRegistrationBox step={3} icon={faEnvelope} header='Share with your colleagues' >
              <p>Use our E-mail template to tell everyone in your company to run IAMS on their computer.</p>
              <Button pill small onClick={() => setIsOpen(true)}>Customize E-mail template</Button>
            </ComputerRegistrationBox>
          </div>
          <IamsRegistration isComplete={isComplete} />
        </SimpleBox>
      </div>
      <ComputerRegistrationSlider
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        iamsLink={`https://iams.intility.com/company/${id}`}
      />
    </BasicPage>
  );
};

ComputerRegistration.propTypes = {
  setTask: PropTypes.func
}
export default ComputerRegistration;
