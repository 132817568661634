import React, {useEffect, useState} from 'react';
import './billing.scss';
import {useDispatch, useSelector} from 'react-redux';
import BasicPage from '../BasicPage';
import BillingBox from './BillingBox/BillingBox';
import AddBillingSlider from './AddBillingSlider/AddBillingSlider';
import { SimpleBox } from "../../../Box/Box";
import { Button, Icon, Message } from "@intility/bifrost-react";
import {faStar} from '@fortawesome/pro-regular-svg-icons';
import { toggleService, updateService } from "../../../../redux/company/companyThunks";
import {fetchBillingAsync} from '../../../../redux/company/billing/billingThunks';
import {platformType} from '../../../../redux/company/platformUpdateThunk';
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import ButtonWithTooltip from "../../../ButtonWithTooltip/ButtonWithTooltip";

const Billing = () => {
  const {billingList, information} = useSelector(state => state.company.data.billingPlatform);
  const {id} = useSelector(state => state.company.data.info);
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentBillingId, setCurrentBillingId] = useState(false);
  const [mainBilling, setMainBilling] = useState(undefined);

  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  useEffect(() => {
    setMainBilling(billingList?.find(main => main.id === information.mainId));
  }, [billingList, information.mainId]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchBillingAsync(id));
  }, [user, dispatch, id]);

  const toggleBilling = value => {
    setIsComplete(value);
    dispatch(toggleService(platformType.BILLING, isComplete));
  };
  const addNewBilling = () => {
    setCurrentBillingId(null);
    setIsOpen(true);
  };

  const onItemDrop = e => {
    e.preventDefault();
    const id = e.dataTransfer.getData('id');
    dispatch(updateService(platformType.BILLING, {mainId: id}));
  };

  return (
    <BasicPage id='billing' taskType={taskType.BILLING}
               isComplete={isComplete}
               setIsComplete={toggleBilling}>
      <div className='content-main'>
        <SimpleBox
          className='billing-box'
          title='Billing companies'
        >
          {billingList && billingList.length > 0 && (
            <>
              <h5>
                <Icon style={{marginRight: 'var(--bfs8)'}} icon={faStar} />
                Main billing information
              </h5>
              <div
                className='droppable bfl-grid'
                onDrop={e => onItemDrop(e)}
                onDragOver={e => {
                  e.preventDefault();
                }}
              >
                {mainBilling !== undefined && (
                  <BillingBox
                    main={true}
                    key={mainBilling.organizationNumber}
                    billing={mainBilling}
                    toggleEdit={setIsOpen}
                    setBillingId={setCurrentBillingId}
                    isComplete={isComplete}
                  />
                )}
              </div>
              <div className='billing-add-row'>
                <h5>Additional billing information</h5>
                <div>
                  <ButtonWithTooltip
                    isComplete={isComplete}
                    icon={faPlus}
                    buttonSpecs={{variant: 'filled'}}
                    btnOnClick={() => addNewBilling()}
                  >
                    Add billing company
                  </ButtonWithTooltip>
                </div>
              </div>

              <div className='box-list bfl-grid'>
                {billingList.length > 0 &&
                  billingList.map(billing => {
                    if (billing.id !== information.mainId) {
                      return (
                        <BillingBox
                          main={false}
                          key={billing.organizationNumber}
                          billing={billing}
                          toggleEdit={setIsOpen}
                          setBillingId={setCurrentBillingId}
                          isComplete={isComplete}
                        />
                      );
                    }
                  })}
              </div>
              {billingList.length < 2 && <Message state={"neutral"}>No additional billing information</Message>}
            </>
          )}
        </SimpleBox>
      </div>
      <AddBillingSlider isOpen={isOpen} setIsOpen={setIsOpen} billingId={currentBillingId} />
    </BasicPage>
  );
};

export default Billing;
