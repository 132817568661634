import PropTypes from "prop-types";
import './sidebarCards.scss';
import { Badge, Button, Icon, Tooltip, useBreakpoint } from "@intility/bifrost-react";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { fetchThumbnail } from "../../../../../../../utils/fetchThumbnail";
import { useSelector } from "react-redux";
import { Document, Page, pdfjs } from 'react-pdf';
import { downloadFile } from "../../../../../../../utils/downloadFile";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FloorplanCard = ({ location, editLocation, isComplete }) => {
  const {id} = useSelector(state => state.company.data.info);
  const [thumbnail, setThumbnail] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const toLarge = useBreakpoint('small', 'large')

  const downloadFloorPlan = floorPlan => {
    const fileNameRegex = /\/(.*)/i;
    const fileName = floorPlan.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'
    const relativeUrl = `/companies/${id}/locations/${location.id}/floorPlans/${fileName}`;
    downloadFile(floorPlan, relativeUrl)
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function getFileName(filename) {
    const cleanName = filename?.objectName?.split('/')[1];
    const namesum = cleanName?.length;
    if (namesum < 15) {
      return <Badge state='neutral' key={cleanName} className='bf-p bf-link' onClick={() => downloadFloorPlan(filename)}>{cleanName}</Badge>
    }
    return <Tooltip content={cleanName} key={cleanName} placement='top'><Badge state='neutral' className='bf-link' onClick={() => downloadFloorPlan(filename)}>{cleanName.substring(0, 6) + '...' + cleanName.substring((namesum - 7), namesum)}</Badge></Tooltip>
  }

  useEffect(() => {
    if (location?.floorPlans?.length > 0) {
      fetchThumbnail(location.floorPlans[0], location?.id, id).then(r => {
        setThumbnail(r);
      });
    }
  }, [location]);

  return (
    <div className='floorplan-card'>
      <div style={toLarge ? {width: '200px'} : {width: '300px'}} className='floorplan-image'>
        {thumbnail?.type === 'PDF' ?
          <Document file={thumbnail?.imageUri} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
            <Page width={toLarge ? 178 : 278} pageNumber={pageNumber} />
          </Document>
          : thumbnail?.type ?
          <img style={toLarge ? {width: '178px'} : {width: '278px'}} src={thumbnail?.imageUri} alt={thumbnail?.name || ''} />
            : <div className='empty-floorplan-container' style={toLarge ? {width: '178px'} : {width: '278px'}}><p className='bf-em'>No floor plans</p></div>
        }
      </div>
      {location?.floorPlans?.length >= 1 ?
        <div className='floorplan-header'>
          <p className='bf-large bf-strong'>Floor plans</p>
          <button className='location-info-sidebar-edit-icon' onClick={editLocation}><Icon icon={faPencil} />
          </button>
        </div> :
        <>
          <p className='bf-large bf-strong' style={{marginBottom: 'var(--bfs4)'}}>Floor plans</p>
          <p style={{marginTop: '0'}}>Please add all the floor plans for this location.</p>
          <div style={{display: 'flex'}}>
            <Button state={isComplete ? 'inactive' : 'default'} variant={"filled"} style={{flex: '1'}} onClick={editLocation}>Add information</Button>
          </div>
        </>
      }
      <div className='floorplan-container'>
        {location?.floorPlans.map(l => getFileName(l))}
      </div>
    </div>
  )
}

FloorplanCard.propTypes = {
  location: PropTypes.object,
  editLocation: PropTypes.func,
  isComplete: PropTypes.bool,
}

export default FloorplanCard