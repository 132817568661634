import { Badge } from "@intility/bifrost-react";
import React from "react";


const getTitle = (contact, num, roles) => {
  return (
    <div className='accordion-header'>
      <p>{contact?.firstName + ' ' + contact?.lastName}</p>
      <div className='badge-container'>
        {roles.map((r, i) => {
          return (
            <Badge key={i} state='neutral'>
              {r}
            </Badge>
          );
        })}
        {contact?.contactRoles?.length > num && <Badge state='neutral'>...</Badge>}
      </div>
    </div>
  );
};


export const getVendorTitle = (contact, num) => {
  const roles = [];
  contact?.contactRoles?.slice(0, num).forEach(r => {
    switch (r?.contactRole) {
      case 0:
        roles.splice(0, 0, 'ApplicationProvider');
        break;
      case 1:
        roles.splice(0, 0, 'SupportProvider');
        break;
      case 2:
        roles.splice(0, 0, 'LicenseProvider');
        break;
      case 3:
        roles.splice(0, 0, 'ProjectManager');
        break;
      case 4:
        roles.splice(0, 0, 'Implementor');
        break;
    }
  });
  return getTitle(contact, num, roles);
};


export const getInternalTitle = (contact, num) => {
  const roles = [];
  contact?.contactRoles?.slice(0, num).forEach(r => {
    switch (r?.contactRole) {
      case 0:
        roles.splice(0, 0, 'SuperUser');
        break;
      case 1:
        roles.splice(0, 0, 'SystemOwner');
        break;
      case 2:
        roles.splice(0, 0, 'Tester');
        break;
    }
  });
  return getTitle(contact, num, roles);
};