import React, {useEffect, useState} from 'react';
import { Button, Checkbox, Icon, Label } from "@intility/bifrost-react";
import './editComponent.scss';
import {useDispatch, useSelector} from 'react-redux';
import {faUndo} from '@fortawesome/pro-regular-svg-icons';
import UserTypeOptions, { DefaultIntilityUserTypes } from "../../Hub/Pages/Users/Options/UserTypeOptions";
import { setValidUserTypesAsync, updateRotReadyness } from "../../../redux/company/users/userThunks";
import { Link } from "react-router-dom";

export const EditUserAdmin = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {information} = useSelector(state => state.company.data.userPlatform);
  const [userTypes, setUserTypes] = useState([]);
  const [updatedUserTypes, setUpdatedUserTypes] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const userTypes = information?.userTypes?.split(',').map(u => UserTypeOptions[u]?.value);
    setUserTypes(userTypes || DefaultIntilityUserTypes);
  }, [information]);

  const saveUserAdmin = () => {
    if (updatedUserTypes.length > 0) {
      dispatch(setValidUserTypesAsync(updatedUserTypes));
    }
    setUpdatedUserTypes('');
  };

  const resetValues = () => {
    const userTypes = information?.userTypes?.split(',').map(u => UserTypeOptions[u]?.value);
    setUserTypes(userTypes || DefaultIntilityUserTypes);
    setUpdatedUserTypes('');
  };

  const toggle = (option, e) => {
    let newTypes = [];
    if (e.target.checked) {
      newTypes = [...userTypes, option.value];
    } else {
      newTypes = userTypes?.filter(x => x !== option.value);
    }
    setUserTypes(newTypes);
    setUpdatedUserTypes(newTypes.join(','));
  }

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>User Admin</h3>
        <div className='backoffice-button-group'>
          {updatedUserTypes.length > 0 && (
            <span onClick={() => resetValues()} className='bf-p icon-text bf-link'>
              <Icon icon={faUndo} />
              <span className='bf-p'> Undo changes</span>
            </span>
          )}
          <Button variant={'filled'} disabled={updatedUserTypes.length === 0} onClick={() => saveUserAdmin()}>
            Save
          </Button>
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{border: '1px solid var(--bfc-base-c-dimmed)', padding: 'var(--bfs24)'}}>
          <p className='bf-large' style={{marginTop: 0}}>Enabled user types</p>
          {UserTypeOptions.map(option => (
            <div key={option.label}>
              <Checkbox
                label={option.label}
                checked={userTypes?.includes(option.value)}
                onChange={e => toggle(option, e)}
              />
            </div>
          ))}
        </div>
        <div style={{padding: 'var(--bfs24)', margin: '0 var(--bfs24)'}}>
          <p>Confirm user content for BALL import, this button initiates automatic ball import of users and is only enabled when the customer has completed all tasks.</p>
          <p className='bf-em'>Review userlist on the <Link className='bf-link' to={`/${id}/users`}>Users</Link> page before toggle</p>
          <Button className='btngrp-btn' disabled={information.isRotReady || information.isEnabled} onClick={() => dispatch(updateRotReadyness(true))}>
            {information.isRotReady ? 'Content has been approved' : 'Confirm content'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditUserAdmin;
