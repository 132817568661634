import PropTypes from "prop-types";
import { Button, Drawer, Modal } from "@intility/bifrost-react";
import {
  DocumentationType,
  DrawerType,
  NetworkSystemType,
  PropTechSystemType
} from "../LocationInformation/LocationInformation";
import React, { useEffect, useState } from "react";
import './locationEditDrawer.scss';
import { InfoContent } from "./DrawerContent/InfoContent";
import { FloorPlansContent } from "./DrawerContent/FloorPlansContent";
import { DeliveryContent } from "./DrawerContent/DeliveryContent";
import { ComlinesContent } from "./DrawerContent/ComlinesContent";
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";
import {
  addLocationMeetingRoomsAsync,
  addLocationSystemsAsync,
  deleteLocationAsync,
  updateLocationNewAsync
} from "../../../../../../redux/company/locations/locationThunks";
import { UsersContent } from "./DrawerContent/UsersContent";
import HeaderContent from "./DrawerContent/HeaderContent";
import { NetworkContent } from "./DrawerContent/NetworkContent";
import { CommentContent } from "./DrawerContent/CommentContent";
import { useHistory } from "react-router-dom";
import { Contact, LocationCompany } from "../../../../../../utils/hub/models";
import SystemsContent from "./DrawerContent/SystemsContent";
import isEqual from 'lodash.isequal';
import MeetingRoomContent from "./DrawerContent/MeetingRoomContent";

export const LocationEditDrawer = ({page, location, onRequestClose, isDisabled}) => {
  const [editLocation, setEditLocation] = useState(location);
  const [editableLocationItems, setEditableLocationItems] = useState([]);
  const {id} = useSelector(state => state.company.data.info);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAbortModal, setShowAbortModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setEditLocation(location);
  }, [location]);

  useEffect(() => {
    if (isDeleting) {
      dispatch(deleteLocationAsync(location.id));
      history.push(`/${id}/locations`);
    }
  }, [isDeleting])

  useEffect(() => {
    setEditLocation(location);
  }, [onRequestClose])

  const editCurrentLocation = (name, value) => {
    setEditLocation(
      produce(draft => {
        draft[name] = value;
      })
    );
  }

  const editCurrentLocationChild = (child, name, value) => {
    if (editLocation[child] === null) {
      let newObject = new Contact();
      switch (child) {
        case 'recipientCompany': {
          newObject = new LocationCompany();
          break;
        }
        case 'legalCompany': {
          newObject = new LocationCompany();
          break;
        }
      }
      setEditLocation(produce(draft => {
          draft[child] = newObject
        })
      );
    }

    setEditLocation(
      produce(draft => {
        draft[child][name] = value;
      })
    );
  }

  const saveLocation = (page) => {
    switch (page) {
      case DrawerType.NETWORKSYSTEMS:
      case DrawerType.PROPTECHSYSTEMS:
        if (editableLocationItems.length === 0) break;
        dispatch(addLocationSystemsAsync(location.id, editableLocationItems));
        setEditableLocationItems([]);
        break;
      case DrawerType.MEETINGROOM:
        if (editableLocationItems.length === 0) break;
        dispatch(addLocationMeetingRoomsAsync(location.id, editableLocationItems));
        setEditableLocationItems([]);
        break;
      default:
        dispatch(updateLocationNewAsync(editLocation));
        break;
    }
    onRequestClose();
  }

  const getContent = (page) => {
    switch (page) {
      case DrawerType.INFO:
        return <InfoContent location={editLocation} editLocation={editCurrentLocation} editLocationChild={editCurrentLocationChild} />;
      case DrawerType.HEADER:
        return <HeaderContent location={editLocation} editLocation={editCurrentLocation} />;
      case DrawerType.NETWORK:
        return <NetworkContent location={editLocation} editLocation={editCurrentLocation} />;
      case DrawerType.MEETINGROOM:
        return <MeetingRoomContent location={editLocation} editLocation={editCurrentLocation} editableItems={editableLocationItems} setEditableItems={setEditableLocationItems} isDisabled={isDisabled}/>;
      case DrawerType.FLOORPLANS:
        return <FloorPlansContent location={editLocation} isDisabled={isDisabled}/>;
      case DrawerType.DELIVERY:
        return <DeliveryContent location={editLocation} editLocation={editCurrentLocation} editLocationChild={editCurrentLocationChild} />;
      case DrawerType.COMLINES:
        return <ComlinesContent location={editLocation} editLocation={editCurrentLocation} />;
      case DrawerType.COMMENT:
        return <CommentContent location={editLocation} editLocation={editCurrentLocation} />;
      case DrawerType.USERS:
        return <UsersContent location={editLocation} isDisabled={isDisabled} />;
      case DrawerType.NETWORKSYSTEMS:
        return <SystemsContent location={editLocation} editLocation={editCurrentLocation} editableItems={editableLocationItems} setEditableItems={setEditableLocationItems} isDisabled={isDisabled} itemTypes={NetworkSystemType} page={page} type={DocumentationType.NETWORK}/>;
      case DrawerType.PROPTECHSYSTEMS:
        return <SystemsContent location={editLocation} editLocation={editCurrentLocation} editableItems={editableLocationItems} setEditableItems={setEditableLocationItems} isDisabled={isDisabled} itemTypes={PropTechSystemType} page={page} type={DocumentationType.PROPTECH}/>;
    }
  }

  const getHeader = (page) => {
    switch (page) {
      case DrawerType.INFO:
        return "Location";
      case DrawerType.HEADER:
        return "General info";
      case DrawerType.NETWORK:
        return "Network coverage";
      case DrawerType.MEETINGROOM:
        return "Meeting rooms & Audio Visual units";
      case DrawerType.FLOORPLANS:
        return "Floor plans";
      case DrawerType.DELIVERY:
        return "Delivery";
      case DrawerType.COMLINES:
        return "Comlines";
      case DrawerType.USERS:
        return "Users at location";
      case DrawerType.NETWORKSYSTEMS:
        return "Network units";
      case DrawerType.PROPTECHSYSTEMS:
        return "Technical systems";
      case DrawerType.COMMENT:
        return "Comments";
      default:
        return "";
    }
  }

  return (
    <Drawer overlay className='location-drawer' onRequestClose={() => {
      if (!isEqual(location, editLocation)) {
        setShowAbortModal(true)
      } else {
        setEditableLocationItems([]);
        onRequestClose();
      }
    }} isOpen={page !== null} header={getHeader(page)}
    footer={(page !== DrawerType.FLOORPLANS && page !== DrawerType.USERS) &&
      <div className='drawer-bottom-button-row'>
        {page === DrawerType.HEADER &&
          <Button variant={"outline"} state={"alert"} onClick={() => setShowDeleteModal(true)}>Delete</Button>
        }
        <Button onClick={() => {
          setEditableLocationItems([]);
          onRequestClose();
        }}>Cancel</Button>
        <Button disabled={isDisabled} onClick={() => saveLocation(page)} variant={"filled"}>Save</Button>
      </div>
    }>
      <div className='location-drawer-content'>
        {getContent(page)}
      </div>
      <Modal className='delete-location-modal' header={`Do you wish to save changes?`} isOpen={showAbortModal} onRequestClose={() => setShowAbortModal(false)}>
        <div>
          <Button disabled={isDisabled} variant={"filled"} state={"default"} onClick={() => {
            setEditableLocationItems([]);
            onRequestClose();
            setShowAbortModal(false)
          }}>Yes</Button>
          <Button variant={"outline"} onClick={() => {
            setEditableLocationItems([]);
            onRequestClose();
            setShowAbortModal(false)
          }}>No</Button>
        </div>
      </Modal>
      <Modal className='delete-location-modal' header={`Delete ${location.name}`} isOpen={showDeleteModal} onRequestClose={() => setShowDeleteModal(false)}>
        <p >Are you sure you want to delete this location? You will not be able undo this action</p>
        <div>
          <Button variant={"outline"} onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button disabled={isDisabled} variant={"outline"} state={"alert"} onClick={() => setIsDeleting(true)}>Delete</Button>
        </div>
      </Modal>
    </Drawer>
  )

}

LocationEditDrawer.propTypes = {
  page: PropTypes.string,
  location: PropTypes.object,
  isDisabled: PropTypes.bool,
  onRequestClose: PropTypes.func
}

export default LocationEditDrawer