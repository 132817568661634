import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {Button, Input, Table} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import {produce} from 'immer';
import '../../apps.scss';

const formatOptionLabel = ({label, isRecommended}) => {
  return (
    <div style={{display: 'flex'}}>
      <div>{label}</div>
      {isRecommended && <div style={{color: '#969696'}}>&nbsp;(recommended)</div>}
    </div>
  );
};

export const CUSTOM_OPTIONS = [
  {
    label: 'Automatically installed',
    value: 'Required'
  },
  {
    label: 'Yes',
    value: 'Available'
  }
];

export const APP_OPTIONS = [
  {
    label: 'Automatically installed',
    value: 'Required'
  },
  {
    label: 'Yes',
    value: 'Available'
  },
  {
    label: 'No',
    value: 'NotAvailable'
  }
];

export const APP_PROTECTION_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

export default function EditApps({appsState, setAppsState, appType, getSortProp, onSortChangeCreator}) {
  return appsState
    .filter(app => app.type === appType)
    .map(app => {
      return (
        <Table.Row key={app.id}>
          <Table.Cell>
            <div style={{display: 'flex'}}>
              {appType === 'Custom' ? (
                <Input
                  placeholder={app.displayName}
                  onChange={event => {
                    const index = appsState.findIndex(a => a.id === app.id);
                    const newState = produce(appsState, draft => {
                      draft[index].name = event.target.value;
                      draft[index].displayName = event.target.value;
                    });

                    setAppsState(newState);
                  }}
                 label='display name' hideLabel/>
              ) : (
                <span style={{marginTop: '3px'}}>{app.displayName}</span>
              )}
            </div>
          </Table.Cell>
          <Table.Cell>
            <Select
              label='companyPortal'
              hideLabel
              isSearchable={false}
              value={APP_OPTIONS.find(option => option.value === app.deploymentType)}
              options={APP_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              onChange={option => {
                const index = appsState.findIndex(a => a.id === app.id);
                const newState = produce(appsState, draft => {
                  draft[index].deploymentType = option.value;
                });

                setAppsState(newState);
              }}
            />
          </Table.Cell>

          {appType === 'Custom' && (
            <Table.Cell>
              <Button
                state='alert'
                variant='outline'
                onClick={() => {
                  const newState = produce(appsState, draft => {
                    const index = draft.findIndex(a => a.id === app.id);
                    draft.splice(index, 1);
                  });
                  setAppsState(newState);
                }}
                icon={faTrash}
              >
                Delete
              </Button>
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
}
