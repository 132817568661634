import EditOnboardTasks from '../../components/BackOffice/EditComponents/EditOnboardTasks';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Tasks',
  path: 'tasks/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditOnboardTasks
};
