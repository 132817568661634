import {Dropdown, Input} from '@intility/bifrost-react';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../../../../BackOffice/Components/memberDropdown.scss';
import apiFetch from "../../../../../utils/apiFetch";

export const AppSearch = ({selectedApp, setSelectedApp, onChange, appObject}) => {
  const [searchApps, setsearchApps] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const value = selectedApp?.name || '';
    setSearchValue(value);
  }, [selectedApp]);

  const setApplication = app => {
    setSearchValue(app.name);
    setSelectedApp(app);
    setShowDropdown(false);
    setsearchApps([]);
  };

  const onClose = () => {
    setSearchValue('');
    setShowDropdown(false);
    setsearchApps([]);
  };

  const fetchApplications = name => {
    if (name === '') {
      setSelectedApp(null);
      return;
    }
    setSearchValue(name);
    setShowDropdown(true);
    if (name.length > 2) {
      apiFetch(`/Dap?search=${name}`)
        .then(response => {
          if (!response.ok) {
            setsearchApps([]);
            return [];
          }
          return response.json()
        })
        .then(users => setsearchApps(users))
    }
  };

  return (
    <Dropdown
      popperOptions={{
        modifiers: [
          {
            name: 'sameWidth',
            enabled: true,
            fn: ({state}) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            effect: ({state}) => {
              state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
            }
          }
        ]
      }}
      noPadding
      arrow={false}
      visible={searchApps.length > 0 && showDropdown}
      onClickOutside={() => onClose()}
      content={
        <div>
          {searchApps?.length > 0 &&
            searchApps.map((m, i) => {
              return (
                <div className='team-edit-modal-dropdown' key={i} onClick={() => setApplication(m)}>
                  <p>
                    {m?.name}
                  </p>
                </div>
              );
            })}
        </div>
      }
    >
      <Input
        name='name'
        label='Official name of application'
        required={true}
        value={appObject?.name}
        onChange={e => {
          onChange(e);
          fetchApplications(e.target.value)
        }}
        icon={faSearch}
        rightIcon={true}
        clearable={true}
      />
    </Dropdown>
  );
};

AppSearch.propTypes = {
  selectedApp: PropTypes.object,
  setSelectedApp: PropTypes.func,
  onChange: PropTypes.func,
  appObject: PropTypes.object
};

export default AppSearch;
