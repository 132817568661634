import React, {useRef} from 'react';
import './fileUploader.scss';
import '../Hub/Pages/Devices.Computers/Customization/DesktopSettings/WallpaperOption/wallpaperoption.scss';
import fileUploaderPropTypes, {fileUploaderFilePropTypes} from './types';
import {Icon} from '@intility/bifrost-react';
import {faCloudUpload} from '@fortawesome/pro-solid-svg-icons';
import DragAndDrop from './DragAndDrop';
import { faPaperclip } from "@fortawesome/pro-light-svg-icons";

const getFilesArray = fileList => {
  const files = [];

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    files.push(file);
  }

  return files;
};

const File = ({data, onDelete}) => {
  return (
    <div className='file'>
      <span>{data.name}</span>
      <i className='fas fa-times-circle' onClick={onDelete} />
    </div>
  );
};

File.propTypes = fileUploaderFilePropTypes;

const FileUploader = ({onChange, name, multiple = true, validContentTypes = ['.pdf', '.jpeg', '.png']}) => {
  const fileRef = useRef(null);

  const onFileSelect = e => {
    return onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.target.files)
      }
    });
  };

  const onFileDrop = e => {
    return onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.dataTransfer.files)
      }
    });
  };

  return (
    <div className='wallpaper-option'>
      <DragAndDrop handleDropping={onFileDrop}>
        <div style={{background: 'var(--bfc-base-dimmed)'}} className='wallpaper-option-image'>
          <div className='icon-container'>
            <Icon icon={faCloudUpload} />
            <p>Drop file to upload or</p>
            <p onClick={() => fileRef.current && fileRef.current.click()} className='bf-link'>
              browse
            </p>
          </div>
        </div>
      </DragAndDrop>
      <input
        type='file'
        ref={fileRef}
        multiple={multiple}
        accept={validContentTypes.join(', ')}
        onChange={onFileSelect}
        style={{display: 'none'}}
      />
    </div>
  );
};

FileUploader.propTypes = fileUploaderPropTypes;

export const ApplicationFileUploader = ({onChange, name, multiple = true, validContentTypes = ['.pdf', '.jpeg', '.png']}) => {
  const fileRef = useRef(null);

  const onFileSelect = e => {
    return onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.target.files)
      }
    });
  };

  const onFileDrop = e => {
    return onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.dataTransfer.files)
      }
    });
  };

  return (
    <div className='application-file-uploader'>
      <DragAndDrop handleDropping={onFileDrop}>
        <div className='wallpaper-option-image'>
          <div className='icon-container'>
            <Icon icon={faPaperclip} />
            <span><p>Drag and drop a file, or </p><p onClick={() => fileRef.current && fileRef.current.click()} className='bf-link'>browse</p><p> your computer</p></span>
          </div>
        </div>
      </DragAndDrop>
      <input
        type='file'
        ref={fileRef}
        multiple={multiple}
        accept={validContentTypes.join(', ')}
        onChange={onFileSelect}
        style={{display: 'none'}}
      />
    </div>
  );
};
ApplicationFileUploader.propTypes = fileUploaderPropTypes;

export default FileUploader;
