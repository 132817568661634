import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  addPrinter,
  addPrinterError,
  fetchPrinters,
  fetchPrintersError,
  updatePrinters,
  updatePrintersError,
  uploadPrintersError
} from './printerReducers';
import {
  addPrinterSuccess,
  deletePrintersSuccess,
  deletePrinterSuccess,
  fetchPrintersSuccess,
  updatePrintersInformationSuccess,
  updatePrintersSuccess,
  updatePrinterSuccess
} from './printerActions';
import devLog from '../../../utils/devLog';
import produce from 'immer';

export const fetchPrintersAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.printerPlatform.printersStatus;
  if (fetching) return null;
  dispatch(fetchPrinters());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/printers`,
    method: 'GET'
  }).then(printers => dispatch(fetchPrintersSuccess(printers)))
    .catch(error => dispatch(fetchPrintersError(error)));
};


export const addPrinterAsync = printer => (dispatch, getState) => {
  const {printersStatus, information, printerList} = getState().company.data.printerPlatform;
  const {id} = getState().company.data.info;
  if (printersStatus.adding) return null;
  dispatch(addPrinter());

  const body = {
    ...printer,
    printerPlatformId: information.id
  };

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/printers`,
    method: 'POST',
    body: body
  }).then(printer => dispatch(addPrinterSuccess(printer, printerList)))
    .catch(error => dispatch(addPrinterError(error)));
};

export const updatePrinterPlatform = information => (dispatch, getState) => {
  const {updating} = getState().company.data.printerPlatform.printersStatus;
  const {id} = getState().company.data.info;
  if (updating) return null;
  dispatch(updatePrinters());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/printers`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: information
  }).then(platform => dispatch(updatePrintersInformationSuccess(platform)))
    .catch(error => dispatch(updatePrintersError(error)));
}

export const updatePrinterAsync = printer => (dispatch, getState) => {
  const {updating} = getState().company.data.printerPlatform.printersStatus;
  if (updating) return null;
  dispatch(updatePrinters());

  const {information, printerList} = getState().company.data.printerPlatform;
  let iamsBody;
  if (printer?.companyUser?.printers !== undefined) {
    iamsBody = produce(printer, draft => {
      draft.companyUser.printers = [];
    });
  } else iamsBody = printer

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/printers/${printer.id}`,
    method: 'PUT',
    body: iamsBody
  }).then(printer => dispatch(updatePrinterSuccess(printer, printerList)))
    .catch(error => dispatch(updatePrintersError(error)));
};


export const deletePrinterAsync = id => (dispatch, getState) => {
  const {updating} = getState().company.data.printerPlatform.printersStatus;
  if (updating) return null;
  dispatch(updatePrinters());

  const {information, printerList} = getState().company.data.printerPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/printers/${id}`,
    method: 'DELETE'
  })
    .then(() => dispatch(deletePrinterSuccess(id, printerList)))
    .catch(error => dispatch(updatePrintersError(error)));
};

export const uploadPrintCsv = (file, replace) => (dispatch, getState) => {
  const {updating} = getState().company.data.printerPlatform.printersStatus;
  if (updating) return null;
  dispatch(updatePrinters());

  const {id} = getState().company.data.info;
  const formData = new FormData();
  formData.append(`csv/${id}`, file);

  return apiFetch(`/companies/${id}/printers/csv?replace=${replace}`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchPrintersAsync(id)))
    .catch(error => {
      console.log(error);
      dispatch(uploadPrintersError(error));
    });
};

export const downloadCsv = () => (dispatch, getState) => {
  const {updating} = getState().company.data.printerPlatform.printersStatus;
  if (updating) return null;
  dispatch(updatePrinters());

  const {information} = getState().company.data.printerPlatform;

  return apiFetch(`/companies/${information.companyId}/printers/csv`, {
    method: 'GET'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to download CSV.');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `printers_${information.companyId}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .then(res => res.flush())
    .catch(error => dispatch(updatePrintersError(error)));
};

export const bulkDelete = printers => (dispatch, getState) => {
  const {printersStatus, information, printerList} = getState().company.data.printerPlatform;
  if (printersStatus.updating) return null;
  dispatch(updatePrinters());

  return apiFetch(`/companies/${information.companyId}/printers/deleteBulk`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(printers)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .then(printers => {
      dispatch(deletePrintersSuccess(printers, printerList));
    })
    .catch(error => dispatch(updatePrintersError(error)));
};

export const multiPut = printers => (dispatch, getState) => {
  const {printersStatus, information, printerList} = getState().company.data.printerPlatform;
  if (printersStatus.updating) return null;
  dispatch(updatePrinters());

  devLog('PUT', printers);

  return apiFetch(`/companies/${information.companyId}/printers/${information.id}/bulk`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(printers)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .then(printers => {
      dispatch(updatePrintersSuccess(printers, printerList));
    })
    .catch(error => dispatch(updatePrintersError(error)));
};
