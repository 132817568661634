import React, {useEffect, useState} from 'react';
import './iamsRegistration.scss';
import { Button, Icon, ProgressBar, Tabs } from "@intility/bifrost-react";
import WaitingContent from './WaitingContent';
import RegisteredContent, {checkMemory, checkOS, checkWarranty} from './RegisteredContent';
import {useDispatch, useSelector} from 'react-redux';
import {
  downloadCsv,
  fetchIamsUsersAsync,
  seedIamsUsersAsync
} from '../../../../../redux/company/iamsUsers/iamsUserThunks';
import {fetchUsersAsync} from '../../../../../redux/company/users/userThunks';
import {fetchLocationsAsync} from '../../../../../redux/company/locations/locationThunks';
import {NavLink} from 'react-router-dom';
import {faDownload, faEnvelope} from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle, faTimesCircle, faTrash, faBan, faPlus } from "@fortawesome/pro-regular-svg-icons";
import {fetchBillingAsync} from '../../../../../redux/company/billing/billingThunks';
import {DeletedScans} from './DeletedScans';
import {NoComputerUsers} from './NoComputerUsers';
import {IntilityPermission} from '../../../../Permissions';
import produce from 'immer';
import {CompanyUser} from '../../../../../utils/hub/models';
import PropTypes from "prop-types";
import NewIamsRegistrationModal from "./NewIamsRegistrationModal";
import IamsUserType from "./IamsUserTypeOptions";
import { emptyGuid } from "../../../../../utils/guid";
import { IntilityUserType } from "../../Users/Options/UserTypeOptions";

export const Waiting = 'Waiting';
export const Registered = 'Registered';
export const DeletedUser = 'Deleted';
export const NoComputer = 'NoComputer';

export const IamsRegistration = ({isComplete}) => {
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const {information, iamsUserList} = useSelector(state => state.company.data.iamsUserPlatform);
  const {id} = useSelector(state => state.company.data.info);
  const [active, setActive] = useState(Waiting);
  const [isOpen, setIsOpen] = useState(false);
  const [csvFilter, setCsvFilter] = useState(0);
  const loggedInUser = useSelector(state => state.auth.user);
  const [seedDispatched, setSeedDispatched] = useState(false);
  const dispatch = useDispatch();

  const enabledUsers = userList?.filter(u => u.isEnabled === true)

  const noComputerList = enabledUsers?.filter(u => u.userType === IamsUserType.NotPcUser);
  const deletedScans = iamsUserList?.filter(u => u.isEnabled === false);

  const filteredUserList = enabledUsers?.filter(u => {
    return u.userType !== IamsUserType.NotPcUser && (u.intilityUserType === IntilityUserType.IntilityIdUser || u.intilityUserType === IntilityUserType.IntilityIdUserCustom || u.intilityUserType === IntilityUserType.ExternalUser)
  });
  const waitingUserList = filteredUserList.filter(u => u.iamsUsers.length === 0);
  const waitingList = iamsUserList
    .filter(u => (u.userType !== IamsUserType.SharedUser) || (u.userType === IamsUserType.SharedUser && (u.companyUserId !== null && u.companyUserId !== emptyGuid)))
    .filter(u => (u.companyUserMatch === null || u.companyUserMatch > 0) && u.isEnabled === true)
    .concat(waitingUserList);

  useEffect(() => {
    if (!loggedInUser || !id) return;
    dispatch(fetchIamsUsersAsync(id));
    dispatch(fetchUsersAsync(id));
    dispatch(fetchLocationsAsync(id));
    dispatch(fetchBillingAsync(id));
  }, [loggedInUser, dispatch, id]);

  const mailingList = waitingUserList.map(u => u.primaryEmailAddress);
  const progress = waitingList?.filter(u => u.companyUserMatch > 0);

  const sharedUserList = iamsUserList
    .filter(u => u.userType === IamsUserType.SharedUser)
    .filter(u => u.isEnabled)
    .filter(u => u.companyUserId === null || u.companyUserId === emptyGuid)
    .map(u => {
      const temporarySharedUser = produce(u, draft => {
        draft.companyUserMatch = 0;
        if (u.firstName?.length === 0 || u.firstName === null) {
          draft.firstName = draft.oldUsername;
        }
        draft.displayName = '(Shared)';
      });
      return produce(new CompanyUser(), draft => {
        if (u.firstName?.length === 0 || u.firstName === null) {
          draft.firstName = u.oldUsername;
        } else {
          draft.firstName = u.firstName;
        }
        draft.iamsUsers.push(temporarySharedUser);
      });
    });

  const registeredList = enabledUsers
    .concat(sharedUserList)
    .map(u => {
      return u.iamsUsers
        .filter(i => i.companyUserMatch === 0)
        .map((v, i) => {
          const memory = checkMemory(v?.memory);
          const os = checkOS(v?.operatingSystem);
          const warranty = checkWarranty(v?.warranty);
          return {
            number: i,
            total: u.iamsUsers.length,
            iamsUser: v,
            companyUser: u,
            memoryCheck: memory,
            osCheck: os,
            warrantyCheck: warranty
          };
        });
    })
    .flatMap(u => u).filter(u => u.iamsUser.isEnabled === true);

  const handleChange = (event, item) => {
    event.preventDefault();
    setActive(item);
  };

  useEffect(() => {
    if (!loggedInUser || !id) return;
    if (active === Waiting && !seedDispatched && information?.isEnabled === true) {
      setSeedDispatched(true);
      dispatch(seedIamsUsersAsync());
    }
  }, [active, information, loggedInUser, id])

  const getContent = page => {
    switch (page) {
      case Waiting:
        return <WaitingContent waitingList={waitingList} isComplete={isComplete}/>;
      case Registered:
        return <RegisteredContent userList={registeredList} isComplete={isComplete} setCsvFilter={setCsvFilter}/>;
      case DeletedUser:
        return <DeletedScans deletedScans={deletedScans} isComplete={isComplete}/>;
      case NoComputer:
        return <NoComputerUsers deletedUsers={noComputerList} isComplete={isComplete}/>;
    }
  };

  return (
    <div id='iams-registration'>
      <h3>Live IAMS registrations</h3>
      {userList?.filter(u => u.userType !== 1)?.filter(u => u.iamsUsers.length === 0).length > 0 &&
        <div className='mail-box'>
          <div className='mail-box-info'>
            <div className='icon-container'>
              <Icon icon={faEnvelope} />
            </div>
              <div className='text-container'>
                <p>
                  Send a reminder to not scanned users ({waitingUserList.length})
                </p>
              </div>
          </div>
          <div className='mail-box-button'>
            <a
              href={`mailto:${mailingList.join(
                ';'
              )}?subject=Intility onboarding&body=Remember to run the IAMS client on your computer.`}
            >
              <h5 className='bf-link'>Draft E-mail</h5>
            </a>
          </div>
        </div>
      }
      <div className='progressbar-container'>
        <ProgressBar value={((100 / (waitingList.length + registeredList.length)) * registeredList.length).toPrecision(2) / 1} />
      </div>
      <div className='bf-p'>
        We will try to match{' '}
        {
          <NavLink to={`/${id}/users`} className={'bf-link'}>
            user registrations
          </NavLink>
        }{' '}
        with computer registrations (IAMS), or provide suggestions based on a combination of email, phone number and
        name.
      </div>
      <div className='iams-content'>
        <Tabs>
          <a href='#' onClick={e => handleChange(e, Waiting)} className={active === Waiting ? 'active' : ''}>
            {`Waiting (${waitingList.length}) `}<Icon icon={faTimesCircle}/>
          </a>
          <a href='#' onClick={e => handleChange(e, Registered)} className={active === Registered ? 'active' : ''}>
            {`Registered (${registeredList.length}) `}<Icon icon={faCheckCircle}/>
          </a>
          <a href='#' onClick={e => handleChange(e, DeletedUser)} className={active === DeletedUser ? 'active' : ''}>
            {`Deleted scans (${deletedScans.length}) `}<Icon icon={faTrash}/>
          </a>
          <a href='#' onClick={e => handleChange(e, NoComputer)} className={active === NoComputer ? 'active' : ''}>
            {`No computer (${noComputerList.length}) `}<Icon icon={faBan}/>
          </a>
          <div className='tabs-button'>
            <Button onClick={() => setIsOpen(true)} disabled={isComplete} variant={'basic'} icon={faPlus} small={true}>
              Add Computer
            </Button>
            <IntilityPermission>
              <Button onClick={() => dispatch(downloadCsv(csvFilter))} variant={'basic'} icon={faDownload} small={true}>
                {csvFilter === 0 ? 'Export all computers' : csvFilter === 1 ? 'Export keep computers' : 'Export replaced computers'}
              </Button>
            </IntilityPermission>
          </div>
        </Tabs>
        <div>{getContent(active)}</div>
      </div>
      <NewIamsRegistrationModal isOpen={isOpen} setIsOpen={setIsOpen} userList={enabledUsers}/>
    </div>
  );
};

IamsRegistration.propTypes = {
  isComplete: PropTypes.bool
};

export default IamsRegistration;
