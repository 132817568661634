import projectTeamData from './projectTeamData';
import informationData from './informationData';
import networkDetailsData from './networkDetailsData';
import financialData from './financialData';
import applicationDetailsData from './applicationDetailsData';
import logsData from './logData';
import projectTeamEdit from './projectTeamEdit';
import mainInformationEdit from './mainInformationEdit';
import financeEdit from './financeEdit';
import logisticsEdit from './logisticsEdit';
import ms365Edit from './ms365Edit';
import ms365Data from './ms365Data';
import networkEdit from './networkEdit';
import workplaceEdit from './workplaceEdit';
import workplaceDetails from './workplaceData';
import applicationsEdit from './applicationsEdit';
import applicationFiltersEdit from './applicationFiltersEdit';
import applicationFilterData from './applicationFilterData';
import locationsEdit from './locationsEdit';
import locationsData from './companyServiceLocations';
import domainDetailsData from './domainDetailsData';
import domainsEdit from './domainsEdit';
import userAdminData from './userAdminData';
import userAdminEdit from './userAdminEdit';
import onboardTasksEdit from './onboardTasksEdit';
import logisticsData from './logisticsData';
import onboardTasksData from './onboardTasksData';
import onboardLayoutEdit from './onboardLayoutEdit';
import onboardLayoutData from './onboardLayoutData';
import serviceSettingsData from './serviceSettingsData';
import serviceSettingsEdit from './serviceSettingsEdit';

export default [
  informationData,
  mainInformationEdit,
  projectTeamData,
  projectTeamEdit,
  financialData,
  financeEdit,
  logisticsData,
  logisticsEdit,
  ms365Data,
  ms365Edit,
  networkDetailsData,
  applicationDetailsData,
  domainDetailsData,
  networkEdit,
  applicationsEdit,
  domainsEdit,
  locationsEdit,
  locationsData,
  userAdminData,
  userAdminEdit,
  onboardTasksEdit,
  onboardTasksData,
  applicationFiltersEdit,
  applicationFilterData,
  workplaceEdit,
  workplaceDetails,
  onboardLayoutEdit,
  onboardLayoutData,
  logsData,
  serviceSettingsData,
  serviceSettingsEdit,
];
