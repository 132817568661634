import {editListItem} from '../../../utils/hub/normalize';

export const editOnboardTicket = (ticketList, onboardTicket) => {
  const newList = editListItem(ticketList, onboardTicket);

  return {
    ticketPlatform: {
      ticketList: newList
    }
  };
};

export const normalizeOnboardTickets = tickets => {
  return {
    ticketPlatform: {
      ticketList: tickets,
    }
  };
};
