import {editListItem} from '../../../utils/hub/normalize';

export const editDocumentation = (documentList, documentation) => {
  const newList = editListItem(documentList, documentation);

  return {
    documentationPlatform: {
      documentList: newList
    }
  };
};

export const normalizeDocumentation = documentationPlatform => {
  return {
    documentationPlatform: {
      documentList: documentationPlatform.documentList,
      information: documentationPlatform,
      state: documentationPlatform.state
    }
  };
};

export const normalizeDocumentationInformation = documentationPlatform => {
  return {
    documentationPlatform: {
      information: documentationPlatform,
      state: documentationPlatform.state
    }
  };
};

export const normalizeDocumentList = documentationPlatform => {
  return {
    documentationPlatform: {
      documentList: documentationPlatform.documentList
    }
  };
};
