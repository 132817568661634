import PropTypes from "prop-types";
import React from "react";
import { Badge } from "@intility/bifrost-react";

export const NotRegisteredField = ({field, fieldTwo}) => {
  return (
    field ?
    <p style={{ color: 'var(--bfc-base-c-1)' }}>
      {field} {fieldTwo && ` ${fieldTwo}`}
    </p> :
      <div>
        <Badge state={"warning"}>Missing information</Badge>
      </div>
  );
};

NotRegisteredField.propTypes = {
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  fieldTwo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default NotRegisteredField