import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import './microsoft365.scss';
import { SimpleBox } from "components/Box/Box";
import { faPaperPlane} from '@fortawesome/pro-regular-svg-icons';
import Microsoft365Box from './Microsoft365Box/Microsoft365Box';
import {Drawer} from '@intility/bifrost-react';
import AdminAccessSlider from './AdminAccessSlider/AdminAccessSlider';
import { useDispatch, useSelector } from "react-redux";
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";
import BasicPage from "../BasicPage";
import { fetchMs365PlatformAsync } from "../../../../redux/company/ms365/ms365Thunks";

const adminLink = (setOpen) => {
  return (
    <div
      onClick={() => setOpen(true)}
      className='microsoft365-box-link'
    >
      <h5>Accept invite</h5>
    </div>
  );
};


const Microsoft365 = () => {
  const {id} = useSelector(state => state.company.data.info);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const strings = queryString.parse(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(fetchMs365PlatformAsync(id));
  }, [id]);

  useEffect(() => {
    if (strings?.open === 'true') {
      setIsOpen(true);
    }
  }, [strings]);

  const onClose = () => {
    setIsOpen(false);
    history.push(`/${id}/microsoft365`);
  };

  return (
    <BasicPage id='microsoft365' taskType={taskType.MS365}>
      <div className='content-main'>
        <SimpleBox className='microsoft365-box' title='Microsoft 365 and Azure'>
          <div className='bfl-grid microsoft365-box-container'>
            <Microsoft365Box step={1} icon={faPaperPlane} link={adminLink(setIsOpen)} backgroundC='var(--bfc-theme-c-1)' iconColor='var(--bfc-theme-1)'>
              <p className='bf-large body-header'>Connect your Microsoft Cloud environment to Intility's Management</p>
              <p>
                Invite Intility to Manage my Cloud Environment by accepting this invitation.
              </p>
            </Microsoft365Box>
          </div>
        </SimpleBox>
      </div>
      <Drawer
        header={'Administrate access'}
        isOpen={isOpen}
        onRequestClose={() => onClose()}
        overlay
      >
      <AdminAccessSlider />
      </Drawer>
    </BasicPage>
  );
};

export default Microsoft365;
