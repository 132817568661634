import {editListItem} from '../../../utils/hub/normalize';

export const editOnboardTask = (taskList, onboardTask) => {
  const newList = editListItem(taskList, onboardTask);

  return {
    taskPlatform: {
      taskList: newList
    }
  };
};
export const editGlobalTask = (globalTaskList, onboardTask) => {
  const newList = editListItem(globalTaskList, onboardTask);

  return {
    taskPlatform: {
      globalTaskList: newList
    }
  };
};

export const normalizeOnboardTasks = taskPlatform => {
  return {
    taskPlatform: {
      taskList: taskPlatform.taskList,
      information: taskPlatform
    }
  };
};

export const normalizeGlobalTasks = globalTaskList => {
  return {
    taskPlatform: {
      globalTaskList: globalTaskList
    }
  };
};

export const normalizeOnboardTaskInformation = taskPlatform => {
  return {
    taskPlatform: {
      information: taskPlatform
    }
  };
};

export const normalizeOnboardTaskList = taskPlatform => {
  return {
    taskPlatform: {
      taskList: taskPlatform.taskList
    }
  };
};
