import React, {useEffect, useState} from 'react';
import './settings.scss';
import {useSelector} from 'react-redux';
import apiFetch from '../../../../../utils/apiFetch';
import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {AdminPermission} from '../../../../Permissions';
import useAdminPermission from '../../../../../hooks/useAdminPermission';
import EditorCard, {EditorCardPending} from './EditorCard';
import {Badge, Button, Icon, Input, Modal, Tooltip, useBreakpoint, useFloatingMessage} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import PropTypes from "prop-types";
import Flex from "../../../../Flex/Flex";
import { faAngleLeft, faArrowRight, faEnvelope, faInfoCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import produce from "immer";


export const projectResponsibilityOptions = [
  {label: 'Workplace', value: 'Workplace', type: 'projectArea'},
  {label: 'Network', value: 'Network', type: 'projectArea'},
  {label: 'Application', value: 'Application', type: 'projectArea'},
  {label: 'Cloud App & Identity', value: 'CloudAppIdentity', type: 'projectArea'},
  {label: 'Security', value: 'Security', type: 'projectArea'},
  {label: 'Project Management', value: 'ProjectManagement', type: 'projectArea'},
];

export const fetchUsers = (companyId, setValue) => {
  apiFetch(`/companies/${companyId}/users`)
    .then(response => {
      if (!response.ok) {
        setValue([]);
        return [];
      }
      return response.json()
    })
    .then(users => setValue(users));
};

export const fetchPending = (companyId, setValue) => {
  apiFetch(`/companies/${companyId}/membershiptokens`)
    .then(response => {
      if (!response.ok) {
        setValue([]);
        return [];
      }
      return response.json()
    })
    .then(users => setValue(users));
};

const addUser = (companyId, body, setUsers, setPending, showFloatingMessage) =>
  apiFetch(`/companies/${companyId}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => {
      if (!response.ok) {
        showFloatingMessage('This action requires admin rights.', {state: 'alert'});
        return [];
      } else {
        return response.json();
      }
    })
    .then(res => {
      if (res[0]?.user !== undefined && res[0]?.user !== null) {
        fetchUsers(companyId, setUsers);
      } else {
        fetchPending(companyId, setPending);
      }
    })
    .catch(error => console.log(error));

const removeUser = (companyId, userId, setValue, setError) => {
  apiFetch(`/companies/${companyId}/users/${userId}`, {method: 'DELETE'})
    .then(response => {
      if (!response.ok) {
        if (response.status === 403) setError(true);
        return [];
      }
      return fetchUsers(companyId, setValue);
    })
    .catch(error => console.log(error));
};

const updateUser = (companyId, userId, userRight, userRole, setValue, showFloatingMessage) => {
  const body = {
    userRight: userRight,
    userRole: userRole,
  };

  apiFetch(`/companies/${companyId}/users/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => {
      if (!response.ok) {
        if (response.status === 403) showFloatingMessage('This action requires admin rights.', {state: 'alert'});
        return [];
      }
      return fetchUsers(companyId, setValue);
    })
    .catch(error => console.log(error));
};

const updatePendingUser = (companyId, tokenId, userRight, userName, userEmail, userRole, setPending, showFloatingMessage) => {
  const body = {
    name: userName,
    email: userEmail,
    userRight: userRight,
    userRole: userRole,
  };

  apiFetch(`/companies/${companyId}/membershiptokens/${tokenId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => {
      if (!response.ok) {
        if (response.status === 403) showFloatingMessage('This action requires admin rights.', {state: 'alert'});
        return [];
      }
      return fetchPending(companyId, setPending);
    })
    .catch(error => console.log(error));
};

const removePending = (companyId, tokenId, setValue, showFloatingMessage) => {
  apiFetch(`/companies/${companyId}/membershiptokens/${tokenId}`, {
    method: 'DELETE'
  })
    .then(response => {
      if (!response.ok) {
        if (response.status === 403) showFloatingMessage('This action requires admin rights.', {state: 'alert'});
        return [];
      }
      return fetchPending(companyId, setValue);
    })
    .catch(error => console.log(error));
};

export const OnboardRightsOptions = [
  {value: 0, label: 'Admin'},
  {value: 1, label: 'Editor'},
  {value: 'remove', label: <span style={{borderTop: '1px solid var(--bfc-base-dimmed)', width: '100%'}}>Remove user</span>}
];

export const OnboardMemberRightsOptions = [
  {value: 0, label: 'Admin'},
  {value: 1, label: 'Editor'}
];

export const RegistrationModal = ({open, setOpen, users, setUsers, pending, setPending, showFloatingMessage}) => {
  const {id, name} = useSelector(state => state.company.data.info);
  const [email, setEmail] = useState('');
  const [userRight, setUserRight] = useState(1);
  const [userResponsibility, setUserResponsibility] = useState([]);
  const [userName, setUserName] = useState('');
  const [emailError, setEmailError] = useState(true);
  const [addedUser, setAddedUser] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const[changedUser, setChangedUser] = useState(false);
  const canEdit = useAdminPermission();

  useEffect(() => {
    const roleValues = editUser?.userRole?.length > 1 ? editUser?.userRole?.split(',') : [];
    const roleArr = roleValues?.map(r => projectResponsibilityOptions.find(i => i.value === r));
    setUserResponsibility(roleArr);
  }, [editUser]);

  const deleteUser = (user) => {
    if (user.pending === true) {
      removePending(user.companyId, user.id, setPending, showFloatingMessage);
      resetEdit();
    } else {
      const result = confirm(`Are you sure you wish to remove ${user.name} (${OnboardRightsOptions[user.userRight]?.label}) from the company?`);
      if (result) {
        removeUser(id, user.userId, setUsers);
        resetEdit();
      }
    }
  }

  const saveUser = (user) => {
    if (user.pending === true) {
      updatePendingUser(id, user.id, user.userRight, user.name, user.email, user.userRole, setPending, showFloatingMessage)
    } else {
      updateUser(id, user.userId, user.userRight, user.userRole, setUsers, showFloatingMessage);
    }
    resetEdit();
  }

  const changeUserValue = (name, value) => {
    setEditUser(
      produce(editUser, draft => {
        if (name === 'userRole') {
          draft.userRole = value.map(i => i.value).join(',')
        } else {
          draft[name] = value;
        }
      })
    );
    setChangedUser(true);
  }

  const handleInput = email => {
    // https://emailregex.com/
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    setEmail(email);
    setEmailError(!regex.test(email));
  };

  const onClick = () => {
    if (!emailError) {
      const newUser = {email: email, name: userName, userRight: userRight, userRole: userResponsibility?.map(r => r.value)?.join(',') || ''};
      addUser(id, newUser, setUsers, setPending, showFloatingMessage);
      setEmailError(true);
      setAddedUser(true);
    }
  };

  const resetEdit = () => {
    setEmail('');
    setUserName('');
    setEmailError(true);
    setEditUser(null);
    setChangedUser(false);
    setUserResponsibility([]);
    setUserRight(1);
    setAddedUser(false);
  }

  return(
    <Modal className='settings-modal bf-scrollbar-small' isOpen={open} header='' onRequestClose={() => {
      setOpen(false);
      resetEdit();
    }}>
      {editUser !== null ?
        <div className='settings-content bfl-grid'>
          <div className='edit-profile'>
            <span onClick={() => resetEdit()} style={{cursor: 'pointer'}}>
              <Icon icon={faAngleLeft}/>
              <p className='pf-p'>All team members</p>
            </span>
            <div className='profile-content'>
              <div className='editor-card-image'>
                {editUser?.profilePhoto ? (
                  <img src={`data:image/png;base64,${editUser?.profilePhoto}`} alt={editUser?.name} />
                ) : (
                  <div>
                    <Icon icon={faUser} />
                  </div>
                )}
              </div>
              <p className='bf-em' style={editUser?.userRight === 0 ? {color: "var(--bfc-base-c-attn)" } : {color: "var(--bfc-base-c-2)" }}> ({editUser?.userRight === 0 ? 'Admin' : editUser?.userRight === 1 ? 'Editor' : 'Viewer'})</p>
              <h4>{editUser?.name}</h4>
              <p>{editUser?.email}</p>
              { userResponsibility?.length > 0 && <div><span>{userResponsibility.map((e, i) => <Badge key={i} style={{marginRight: 'var(--bfs4)'}}>{e?.label}</Badge>)}</span></div> }
            </div>
            <div>
              <Button variant='outline' state='alert' icon={faTimes} onClick={() => deleteUser(editUser)}>Delete user</Button>
            </div>
          </div>
          <div className='edit-profile-fields'>
            <AdminPermission>
              {editUser.pending === true &&
                <div>
                  <p className='bf-em' style={{color: 'var(--bfc-base-c-2)'}}>Before the account has used Onboard you can edit email and name, <br/>after first login it is no longer possible.</p>
                  <Flex flex={[2, 2]} style={{alignItems: 'flex-end'}}>
                    <Input
                      label='Full name'
                      onChange={e => changeUserValue("name", e.target.value)}
                      value={editUser.name}
                    />
                    <Input
                      label='E-mail'
                      onChange={e => changeUserValue("email", e.target.value)}
                      value={editUser.email}
                    />
                  </Flex>
                </div>
              }
              <Select value={OnboardMemberRightsOptions[editUser.userRight]}
                      options={OnboardMemberRightsOptions}
                      onChange={e => changeUserValue("userRight", e.value)} label={<Tooltip content='Admins can add new colleagues to Onboard, while users with "Edit" access can edit information across the portal.' placement='right'>
                <span>Onboard role <Icon style={{color: 'var(--bfc-theme)'}} icon={faInfoCircle}/></span>
              </Tooltip>}/>
              <Select value={userResponsibility}
                      options={projectResponsibilityOptions}
                      isMulti={true}
                      onChange={e => changeUserValue("userRole", e)} label='Project responsibility'/>
            </AdminPermission>
            {changedUser === true &&
              <Button variant='filled' onClick={() => saveUser(editUser)}>Save</Button>
            }
          </div>
        </div>
        :
        <div className='settings-content bfl-grid'>
          {addedUser ?
            <div className='settings-added-success'>
              <h1>Success!</h1>
              <p>Please remember to send {userName} the link to Onboard</p>
              <div>
                <a
                  href={`mailto:${email}?subject=Intility Onboard&body=You have been added to the ${name} project team in Intility Onboard. %0D%0A%0D%0APlease visit https://onboard.intility.com to redeem your invite.`}
                >
                  <Button key={0} icon={faEnvelope}>Share Onboard</Button>
                </a>
                <Button variant='filled' onClick={() => resetEdit()}>Invite a new colleague</Button>
              </div>
            </div> :
            <form
              onSubmit={event => {
                event.preventDefault();
                onClick();
              }}
              className='add-user'
            >
              <h4>Invite a colleague</h4>
              <Flex flex={[2, 2]} style={{alignItems: 'flex-end'}}>
                <Input
                  label='Full name'
                  onChange={e => setUserName(e.target.value)}
                  value={userName}
                />
                <Input
                  label='E-mail'
                  onChange={e => handleInput(e.target.value)}
                  value={email}
                />
              </Flex>
              <Flex flex={[2, 2]} style={{alignItems: 'flex-end'}}>
                <AdminPermission>
                  <Select value={OnboardMemberRightsOptions[userRight]}
                          options={OnboardMemberRightsOptions}
                          onChange={e => setUserRight(e.value)} label={<Tooltip content='Admins can add new colleagues to Onboard, while users with "Edit" access can edit information across the portal.'>
                    <span>Onboard role <Icon style={{color: 'var(--bfc-theme)'}} icon={faInfoCircle}/></span>
                  </Tooltip>}/>
                </AdminPermission>
                <AdminPermission>
                  <Select value={projectResponsibilityOptions[userResponsibility]}
                          options={projectResponsibilityOptions}
                          isMulti={true}
                          className='respo-dropdown'
                          onChange={e => setUserResponsibility(e)} label={<Tooltip content='Specify area(s) of responsibility for the user.'>
                    <span>Project responsibility <Icon style={{color: 'var(--bfc-theme)'}} icon={faInfoCircle}/></span>
                  </Tooltip>}/>
                </AdminPermission>
              </Flex>
              <Button disabled={emailError} variant='filled' onClick={() => onClick()}>
                Grant access to Onboard
              </Button>
            </form>
          }
          <div className='team-member-list bf-scrollbar-small'>
            <h4>Team members</h4>
            {users?.sort((u, u2) => u?.userRight > u2?.userRight)?.map((user, i) => (
                <EditorCard
                  key={i}
                  user={user}
                  setEditUser={setEditUser}
                />
              ))}
            {pending?.map((user, i) => (
              <EditorCardPending
                key={i}
                user={user}
                setEditUser={setEditUser}
              />
            ))}
          </div>
        </div>
      }
    </Modal>
  )
}

RegistrationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setUsers: PropTypes.func,
  users: PropTypes.array,
  setPending: PropTypes.func,
  pending: PropTypes.array,
  showFloatingMessage: PropTypes.func
}

export const SettingsButton = ({name, icon}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [users, setUsers] = useState([]);
  const [pending, setPending] = useState([]);
  const [open, setOpen] = useState(false);
  const { showFloatingMessage } = useFloatingMessage();
  const canEdit = useAdminPermission();

  useEffect(() => {
    if (id !== null && id !== undefined && id !== '') {
      fetchUsers(id, setUsers);
      fetchPending(id, setPending);
    }
  }, [id]);

  return (
    <div className='settings-button'>
      <AdminPermission>
        <RegistrationModal pending={pending} setPending={setPending} users={users} setUsers={setUsers} showFloatingMessage={showFloatingMessage} setOpen={setOpen} open={open}/>
      </AdminPermission>
      <Button icon={icon ? icon : undefined} onClick={() => (canEdit ? setOpen(true) : showFloatingMessage('This action requires admin rights.', {state: 'alert'}))}>{name}</Button>
    </div>
  );

}

SettingsButton.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.any
}
export const SettingsCard = ({highlight, isOpen, setIsOpen}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [users, setUsers] = useState([]);
  const [pending, setPending] = useState([]);
  const [open, setOpen] = useState(false);
  const { showFloatingMessage } = useFloatingMessage();
  const canEdit = useAdminPermission();
  const fromMedium = useBreakpoint('medium', null);
  const fromSmall = useBreakpoint('small', null);

  useEffect(() => {
    if (id !== null && id !== undefined && id !== '') {
      fetchUsers(id, setUsers);
      fetchPending(id, setPending);
    }
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
      setIsOpen(false);
    }
  }, [isOpen]);

  return (
    <div className={highlight ? 'settings-card highlighted' : "settings-card"}>
      <div className='settings-button-images'>
        {users.concat(pending)?.slice(0, (fromMedium || !fromSmall) ? 4 : 3)
          .sort((u, u2) => u?.userRight > u2?.userRight)
          .map((user, i) => (
            user?.profilePhoto ? (
              <span key={i} style={{display: 'flex', alignItems: 'center'}}>
                <img style={{height: '18px', width: '18px', borderRadius: '50%'}} src={`data:image/png;base64,${user?.profilePhoto}`} alt='img' />
                <p className='bf-small'>{user?.name}</p>
              </span>
            ) : (
              <span key={i} style={{display: 'flex', alignItems: 'center'}}>
                <span style={{display: 'flex', alignItems: 'center'}} className='icon-span'>
                  <Icon style={{height: '12px', width: '12px', borderRadius: '50%'}} icon={faUser} />
                </span>
                <p className='bf-small'>{user?.name}</p>
              </span>
            )
          ))
        }
        {users?.length > ((fromMedium || !fromSmall) ? 4 : 3) && <p className='bf-small' style={{color: 'var(--bfc-base-c-1)', margin: 0}}>{users?.concat(pending)?.slice((fromMedium || !fromSmall) ? 4 : 3)?.length} more</p>}
      </div>
      <div className='invite-text' onClick={() => canEdit ? setOpen(true) : showFloatingMessage('This action requires admin rights.', {state: 'alert'})}>
        <h4>
          <Icon icon={faArrowRight}/>
        </h4>
        <h3>Collaborate with your team</h3>
      </div>
      <AdminPermission>
        <RegistrationModal pending={pending} setPending={setPending} users={users} setUsers={setUsers} showFloatingMessage={showFloatingMessage} setOpen={setOpen} open={open}/>
      </AdminPermission>
    </div>
  );

}

SettingsCard.propTypes = {
  highlight: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}
