import { Badge, Button, Checkbox, Icon, Modal, Table, Tabs, Tooltip } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import {faLaptop, faUser} from '@fortawesome/pro-light-svg-icons';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import {updateIamsUserAsync} from '../../../../../redux/company/iamsUsers/iamsUserThunks';
import {useDispatch, useSelector} from 'react-redux';
import './iamsRegistrationModal.scss';
import {fetchUsersAsync} from '../../../../../redux/company/users/userThunks';
import { emptyGuid } from "../../../../../utils/guid";
import isEmptyOrNull from "../../../../../utils/hub/isEmptyOrNull";

export const rowValue = value => {
  return isEmptyOrNull(value) ? <span style={{color: 'var(--bfc-base-c-2)'}}>No value</span> : value;
};

export const IamsRegistrationModal = ({user, isOpen, setIsOpen, userOptions}) => {
  const {iamsUserList, information} = useSelector(state => state.company.data.iamsUserPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const [selectedIamsUser, setSelectedIamsUser] = useState(user?.iamsUser);
  const [selectedUser, setSelectedUser] = useState({
    value: user?.companyUser,
    label: `${user?.companyUser?.firstName} ${user?.companyUser?.lastName}`
  });
  const [tooltip, setTooltip] = useState(false);
  const [userMatch, setUserMatch] = useState({
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    location: null
  });
  const [selectedFirstName, setSelectedFirstName] = useState({label: '', firstName: ''});
  const [selectedLastName, setSelectedLastName] = useState({label: '', lastName: ''});
  const [selectedEmail, setSelectedEmail] = useState({label: '', value: ''});
  const [selectedMobile, setSelectedMobile] = useState({label: '', value: '' });
  const [selectedLocation, setSelectedLocation] = useState({label: '', value: '' });
  const dispatch = useDispatch();

  const canUpdate = () => {
    return (
      (userMatch.firstName || selectedFirstName.label?.length !== 0) &&
      (userMatch.lastName || selectedLastName.label?.length !== 0) &&
      (userMatch.email || selectedEmail.label?.length !== 0) &&
      (userMatch.mobile || selectedMobile.label?.length !== 0) &&
      (userMatch.location || selectedLocation.label?.length !== 0) &&
      selectedUser.label !== 'None selected' &&
      selectedUser?.value?.isEnabled === true
    );
  };

  const updateScan = user => {
    const updatedUser = produce(user, draft => {
      draft.isEnabled = false;
      draft.companyUserId = null;
      draft.companyUser = null;
    });
    dispatch(updateIamsUserAsync(updatedUser)).then(() => dispatch(fetchUsersAsync(information.companyId)));
    closeModal();
  };

  const closeModal = () => {
    setIsOpen(false);
    setTooltip(false);
    resetRadios();
  };

  const resetRadios = () => {
    setSelectedFirstName({label: '', firstName: ''});
    setSelectedLastName({label: '', lastName: ''});
    setSelectedEmail({label: '', value: ''});
    setSelectedMobile({label: '', value: ''});
    setSelectedLocation({label: '', value: ''});
  }

  const updateUser = () => {
    const userToUpdate = iamsUserList.find(u => u.id === user?.iamsUser?.id);
    if (userToUpdate === undefined || userToUpdate === null) return;
    const updatedIamsUser = produce(userToUpdate, draft => {
      draft.firstName = !userMatch.firstName ? selectedFirstName.firstName : draft.firstName;
      draft.lastName = !userMatch.lastName ? selectedLastName.lastName : draft.lastName;
      draft.email = !userMatch.email ? selectedEmail.value : draft.email;
      draft.phoneNumber = !userMatch.mobile ? selectedMobile.value : draft.phoneNumber;
      draft.locationId = !userMatch.location ? selectedLocation.value : draft.locationId;
      draft.companyUserId = selectedUser.value.id ??= draft.companyUserId;
      draft.companyUserMatch = 0;
      draft.companyUser = selectedUser.value;
    });
    const updatedIamsCompanyUser = produce(updatedIamsUser, draft => {
      draft.companyUser.primaryEmailAddress = !userMatch.email
        ? selectedEmail.value
        : draft.companyUser.primaryEmailAddress;
      draft.companyUser.firstName = !userMatch.firstName ? selectedFirstName.firstName : draft.companyUser.firstName;
      draft.companyUser.lastName = !userMatch.lastName ? selectedLastName.lastName : draft.companyUser.lastName;
      draft.companyUser.mobilePhone = !userMatch.mobile ? selectedMobile.value : draft.companyUser.mobilePhone;
      draft.companyUser.locationId = !userMatch.location ? selectedLocation.value : draft.companyUser.locationId;
      draft.companyUser.iamsUsers = [];
    });
    dispatch(updateIamsUserAsync(updatedIamsCompanyUser)).then(() => dispatch(fetchUsersAsync(information.companyId)));
    closeModal();
  };

  useEffect(() => {
    setSelectedUser({
      value: user?.companyUser,
      label: `${user?.companyUser?.firstName} ${user?.companyUser?.lastName}`
    });
    setSelectedIamsUser(user?.iamsUser);
  }, [user]);

  useEffect(() => {
    const firstName = compareValues(selectedUser?.value?.firstName, selectedIamsUser?.firstName);
    const lastName = compareValues(selectedUser?.value?.lastName, selectedIamsUser?.lastName);
    const email = compareValues(selectedUser?.value?.primaryEmailAddress, selectedIamsUser?.email);
    const mobile = compareValues(selectedUser?.value?.mobilePhone, selectedIamsUser?.phoneNumber);
    const location = compareValues(selectedUser?.value?.locationId, selectedIamsUser?.locationId);
    const userVars = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mobile,
      location: location
    };
    resetRadios();
    setUserMatch(userVars);
  }, [selectedUser]);

  const compareValues = (a, b) => {
    if ((a === null || a?.length === 0 || a === emptyGuid) && (b === null || b?.length === 0 || b === emptyGuid)) return true;
    if (a === null || a === undefined || b === null || b === undefined) return false;
    if (a === emptyGuid || b === emptyGuid) return false;
    if (a.length === 0 && b.length === 0) return true;
    if (a.length <= 0 || b.length <= 0) return false;
    return (
      a
        ?.toString()
        ?.trim()
        ?.toLowerCase() ===
      b
        ?.toString()
        ?.trim()
        ?.toLowerCase()
    );
  };

  const getBadge = value => {
    if (value === null) return <Badge state='alert'>No match</Badge>;
    if (value) return <Badge state='success'>Match</Badge>;
    return <Badge state='alert'>No match</Badge>;
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        header={'Review connection'}
        shouldCloseOnOverlayClick={false}
      >
        <div className='registration-modal'>
          <div className='review-modal-select'>
            <Select
              label='Owner of computer'
              filterOption={(option, rawInput) => {
                if (option?.value === '201') return true;
                return option?.data?.data?.toString()?.toLowerCase()?.includes(rawInput?.toLowerCase());
              }}
              options={userOptions}
              value={selectedUser}
              onChange={item => setSelectedUser(item)}
              required={true}
              state={
                !selectedIamsUser?.companyUserMatch && selectedUser?.value?.label === 'None selected'
                  ? 'alert'
                  : 'default'
              }
              feedback={
                !selectedIamsUser?.companyUserMatch && selectedUser?.value?.label === 'None selected' && 'No user match'
              }
            />
            <div />
          </div>
          <h5>Select correct information</h5>
          <p style={{color: 'var(--bfc-base-c-2)'}}>
            This will overwrite current registration in onboard user-list{' '}
          </p>
          <Tabs>
            <Tabs.Item noPadding content={
              <Table className='usermatch-table' vertical noBorder>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Onboard user</Table.HeaderCell>
                    <Table.HeaderCell>IAMS registration</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!userMatch.firstName ? (
                    <Table.Row>
                      <Table.HeaderCell>First name</Table.HeaderCell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedUser?.value?.firstName) ? (
                            rowValue(selectedUser?.value?.firstName)
                        ) : (
                          <Checkbox
                            label={selectedUser?.value?.firstName || ''}
                            type='radio'
                            checked={selectedFirstName.label === selectedUser?.value?.firstName}
                            onChange={() =>
                              setSelectedFirstName({
                                label: selectedUser?.value?.firstName,
                                firstName: selectedUser?.value?.firstName
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedIamsUser?.firstName) ? (
                            rowValue(selectedIamsUser?.firstName)
                        ) : (
                          <Checkbox
                            label={selectedIamsUser?.firstName || ''}
                            type='radio'
                            checked={selectedFirstName.label === selectedIamsUser?.firstName}
                            onChange={() =>
                              setSelectedFirstName({
                                label: selectedIamsUser?.firstName,
                                firstName: selectedIamsUser?.firstName
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>{getBadge(userMatch.firstName)}</Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.HeaderCell>First name</Table.HeaderCell>
                      <Table.Cell>{rowValue(selectedUser?.value?.firstName)}</Table.Cell>
                      <Table.Cell>{rowValue(selectedIamsUser?.firstName)}</Table.Cell>
                      <Table.Cell>{getBadge(userMatch.firstName)}</Table.Cell>
                    </Table.Row>
                  )}

                  {!userMatch.lastName ? (
                    <Table.Row>
                      <Table.HeaderCell>Last name</Table.HeaderCell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedUser?.value?.lastName) ? (
                          rowValue(selectedUser?.value?.lastName)
                        ) : (
                          <Checkbox
                            label={selectedUser?.value?.lastName || ''}
                            type='radio'
                            checked={selectedLastName.label === selectedUser?.value?.lastName}
                            onChange={() =>
                              setSelectedLastName({
                                label: selectedUser?.value?.lastName,
                                lastName: selectedUser?.value?.lastName
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedIamsUser?.lastName) ? (
                          rowValue(selectedIamsUser?.lastName)
                        ) : (
                          <Checkbox
                            label={selectedIamsUser?.lastName || ''}
                            type='radio'
                            checked={selectedLastName.label === selectedIamsUser?.lastName}
                            onChange={() =>
                              setSelectedLastName({
                                label: selectedIamsUser?.lastName,
                                lastName: selectedIamsUser?.lastName
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>{getBadge(userMatch.lastName)}</Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.HeaderCell>Last name</Table.HeaderCell>
                      <Table.Cell>{rowValue(selectedUser?.value?.lastName)}</Table.Cell>
                      <Table.Cell>{rowValue(selectedIamsUser?.lastName)}</Table.Cell>
                      <Table.Cell>{getBadge(userMatch.lastName)}</Table.Cell>
                    </Table.Row>
                  )}
                  {!userMatch.email ? (
                    <Table.Row>
                      <Table.HeaderCell>E-mail</Table.HeaderCell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedUser?.value?.primaryEmailAddress) ? (
                          rowValue(selectedUser?.value?.primaryEmailAddress)
                        ) : (
                          <Checkbox
                            label={selectedUser?.value?.primaryEmailAddress || ''}
                            type='radio'
                            checked={selectedEmail.label === selectedUser?.value?.primaryEmailAddress}
                            onChange={() =>
                              setSelectedEmail({
                                label: selectedUser?.value?.primaryEmailAddress,
                                value: selectedUser?.value?.primaryEmailAddress
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedIamsUser?.email) ? (
                          rowValue(selectedIamsUser?.email)
                        ) : (
                          <Checkbox
                            label={selectedIamsUser?.email || ''}
                            type='radio'
                            checked={selectedEmail.label === selectedIamsUser?.email}
                            onChange={() =>
                              setSelectedEmail({
                                label: selectedIamsUser?.email,
                                value: selectedIamsUser?.email
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>{getBadge(userMatch.email)}</Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.HeaderCell>E-mail</Table.HeaderCell>
                      <Table.Cell>{rowValue(selectedUser?.value?.primaryEmailAddress)}</Table.Cell>
                      <Table.Cell>{rowValue(selectedIamsUser?.email)}</Table.Cell>
                      <Table.Cell>{getBadge(userMatch.email)}</Table.Cell>
                    </Table.Row>
                  )}
                  {!userMatch.mobile ? (
                    <Table.Row>
                      <Table.HeaderCell>Mobile number</Table.HeaderCell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedUser?.value?.mobilePhone) ? (
                          rowValue(selectedUser?.value?.mobilePhone)
                        ) : (
                          <Checkbox
                            label={selectedUser?.value?.mobilePhone || ''}
                            type='radio'
                            checked={selectedMobile.label === selectedUser?.value?.mobilePhone}
                            onChange={() =>
                              setSelectedMobile({
                                label: selectedUser?.value?.mobilePhone,
                                value: selectedUser?.value?.mobilePhone
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedIamsUser?.phoneNumber) ? (
                          rowValue(selectedIamsUser?.phoneNumber)
                        ) : (
                          <Checkbox
                            label={selectedIamsUser?.phoneNumber || ''}
                            type='radio'
                            checked={selectedMobile.label === selectedIamsUser?.phoneNumber}
                            onChange={() =>
                              setSelectedMobile({
                                label: selectedIamsUser?.phoneNumber,
                                value: selectedIamsUser?.phoneNumber
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>{getBadge(userMatch.mobile)}</Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.HeaderCell>Mobile number</Table.HeaderCell>
                      <Table.Cell>{rowValue(selectedUser?.value?.mobilePhone)}</Table.Cell>
                      <Table.Cell>{rowValue(selectedIamsUser?.phoneNumber)}</Table.Cell>
                      <Table.Cell>{getBadge(userMatch.mobile)}</Table.Cell>
                    </Table.Row>
                  )}
                  {!userMatch.location ? (
                    <Table.Row>
                      <Table.HeaderCell>Location</Table.HeaderCell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedUser?.value?.locationId) ? (
                            rowValue(locationList?.find(location => location?.id === selectedUser?.value?.locationId)?.name)
                        ) : (
                          <Checkbox
                            label={locationList?.find(location => location?.id === selectedUser?.value?.locationId)?.name || ''}
                            type='radio'
                            checked={selectedLocation.label === selectedUser?.value?.locationId}
                            onChange={() =>
                              setSelectedLocation({
                                label: selectedUser?.value?.locationId,
                                value: selectedUser?.value?.locationId
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isEmptyOrNull(selectedIamsUser?.locationId) ? (
                            rowValue(locationList?.find(location => location?.id === selectedIamsUser?.locationId)?.name)
                        ) : (
                          <Checkbox
                            label={locationList?.find(location => location?.id === selectedIamsUser?.locationId)?.name || ''}
                            type='radio'
                            checked={selectedLocation.label === selectedIamsUser?.locationId}
                            onChange={() =>
                              setSelectedLocation({
                                label: selectedIamsUser?.locationId,
                                value: selectedIamsUser?.locationId
                              })
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>{getBadge(userMatch.location)}</Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.HeaderCell>Location</Table.HeaderCell>
                      <Table.Cell>{rowValue(locationList?.find(location => location?.id === selectedUser?.value?.locationId)?.name)}</Table.Cell>
                      <Table.Cell>{rowValue(locationList?.find(location => location?.id === selectedIamsUser?.locationId)?.name)}</Table.Cell>
                      <Table.Cell>{getBadge(userMatch.location)}</Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.HeaderCell>Old username</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedUser?.value?.oldSam)}</Table.Cell>
                    <Table.Cell>{rowValue(selectedIamsUser?.oldUsername)}</Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Office/Seat</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedUser?.value?.physicalOffice)}</Table.Cell>
                    <Table.Cell>{rowValue(selectedIamsUser?.peck)}</Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                </Table.Body>
              </Table>
            }>
              User <Icon icon={faUser}/>
            </Tabs.Item>
            <Tabs.Item noPadding content={
              <Table className='usermatch-table' vertical noBorder>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>IAMS computer</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.HeaderCell>Model</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedIamsUser?.model)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Memory</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedIamsUser?.memory)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Processor</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedIamsUser?.prosessor)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Serial no.</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedIamsUser?.serial)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>OS</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedIamsUser?.operatingSystem)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Warranty</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedIamsUser?.warranty)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Comment</Table.HeaderCell>
                    <Table.Cell>{rowValue(selectedIamsUser?.comment)}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            }>Computer <Icon icon={faLaptop}/>
            </Tabs.Item>
          </Tabs>
        </div>
        <div className='modal-button-bar'>
          <Button onClick={() => updateScan(selectedIamsUser)} variant={'outline'} state={'alert'}>
            Delete IAMS scan
          </Button>
          <div>
            <Button variant={'outline'} onClick={() => closeModal()}>
              Cancel
            </Button>
            <Tooltip content='User invalid or checkboxes missing' visible={tooltip && !canUpdate()} placement='top'>
              <Button
                variant={'filled'}
                state={canUpdate() ? 'default' : 'inactive'}
                onClick={() => {
                  setTooltip(true);
                  if (canUpdate()) updateUser();
                }}
              >
                Update user
              </Button>
            </Tooltip>
          </div>
        </div>
      </Modal>
    </div>
  );
};

IamsRegistrationModal.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool,
  userOptions: PropTypes.array,
  setIsOpen: PropTypes.func
};

export default IamsRegistrationModal;
