import React, {useState} from 'react';
import {Grid, Input, Button, Icon} from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import {GetVendorRole, externalRoleOptions} from '../NewApplication/NewContent/NewContact';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';

const EMAIL_REGEX = /^.+@.+$/;

const EditVendorContact = ({contact, updateContact, deleteContact, handleCancel}) => {
  const [updatedContact, setUpdatedContact] = useState({...contact, submitted: false});
  const [roles, setRoles] = useState(
    contact.contactRoles.map(role => ({
      value: role.contactRole,
      label: GetVendorRole(role.contactRole)
    }))
  );

  const handleInputChange = event => {
    setUpdatedContact({
      ...updatedContact,
      [event.target.name]: event.target.value
    });
  };

  const handleSave = () => {
    setUpdatedContact(prevContact => ({...prevContact, submitted: true}));
    if (
      updatedContact.firstName &&
      updatedContact.lastName &&
      updatedContact.email &&
      EMAIL_REGEX.test(updatedContact.email)
    ) {
      updateContact({
        ...updatedContact,
        contactRoles: roles.map(role => ({
          contactRole: role.value
        }))
      });
    }
  };

  const handleDelete = () => {
    deleteContact(contact.id);
  };

  return (
    <div className='edit-contact-card'>
      <div style={{color: 'var(--bfc-base-c-1)', fontSize: '16px', fontWeight: '600', marginBottom: '24px'}}>
        Edit vendor contact
      </div>
      <Grid gap={12} small={2}>
        <Input
          label='First name'
          name='firstName'
          value={updatedContact.firstName}
          onChange={handleInputChange}
          state={
            updatedContact.submitted && !updatedContact.firstName ? 'alert' : updatedContact.firstName ? 'success' : ''
          }
          feedback={updatedContact.submitted && !updatedContact.firstName ? 'Please enter first name' : undefined}
        />
        <Input
          label='Last name'
          name='lastName'
          value={updatedContact.lastName}
          onChange={handleInputChange}
          state={
            updatedContact.submitted && !updatedContact.lastName ? 'alert' : updatedContact.lastName ? 'success' : ''
          }
          feedback={updatedContact.submitted && !updatedContact.lastName ? 'Please enter last name' : undefined}
        />
        <Input
          label='Phone number'
          name='phoneNumber'
          description='Vendor phone number'
          value={updatedContact.phoneNumber}
          onChange={handleInputChange}
        />
        <Input
          label='E-mail'
          name='email'
          description='Vendor e-mail address'
          value={updatedContact.email}
          onChange={handleInputChange}
          state={
            updatedContact.submitted && (!updatedContact.email || !EMAIL_REGEX.test(updatedContact.email))
              ? 'alert'
              : updatedContact.email
              ? 'success'
              : ''
          }
          feedback={
            updatedContact.submitted && (!updatedContact.email || !EMAIL_REGEX.test(updatedContact.email))
              ? 'Please enter a valid email address'
              : undefined
          }
        />
        <Grid.Span small={2}>
          <Select
            label='Roles'
            description='Select the contact role(s)'
            isMulti
            options={externalRoleOptions}
            value={roles}
            onChange={selectedOptions => {
              setRoles(selectedOptions || []);
              setUpdatedContact({
                ...updatedContact,
                contactRoles: selectedOptions ? selectedOptions.map(option => option.value) : []
              });
            }}
          />
        </Grid.Span>
        <Grid.Span small={2} style={{display: 'flex', justifyContent: 'space-between', marginTop: '12px'}}>
          <div>
            <Button pill variant='outline' onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              pill
              variant='outline'
              state='alert'
              style={{alignSelf: 'flex-start', marginLeft: '8px'}}
              onClick={handleDelete}
            >
              <Icon icon={faTrash} />
            </Button>
          </div>
          <Button pill variant='filled' style={{alignSelf: 'flex-end'}} onClick={handleSave}>
            Save Contact
          </Button>
        </Grid.Span>
      </Grid>
    </div>
  );
};

export default EditVendorContact;
