import {createAction} from '@reduxjs/toolkit';
import {
  addNewPrinter,
  deleteMultiplePrinters,
  deletePrinter,
  editMultiplePrinters,
  editPrinter,
  normalizePrinters,
  normalizePrintersInformation,
  normalizePrintersList
} from './printerNormalizers';

export const fetchPrintersListSuccess = createAction('printersAction/fetchPrintersListSuccess', apiPrinters => ({
  payload: normalizePrintersList(apiPrinters)
}));

export const fetchInformationSuccess = createAction(
  'printersAction/fetchPrintersInformationSuccess',
  apiPrinters => ({
    payload: normalizePrintersInformation(apiPrinters)
  })
);

export const fetchPrintersSuccess = createAction('printersAction/fetchPrintersSuccess', apiPrinters => ({
  payload: normalizePrinters(apiPrinters)
}));

export const updatePrintersSuccess = createAction(
  'printersAction/updatePrintersSuccess',
  (printers, printerList) => ({
    payload: editMultiplePrinters(printerList, printers)
  })
);

export const updatePrinterSuccess = createAction(
  'printersAction/updatePrinterSuccess',
  (printer, printerList) => ({
    payload: editPrinter(printer, printerList)
  })
);

export const deletePrinterSuccess = createAction(
  'printersAction/deletePrinterSuccess',
  (printerId, printerList) => ({
    payload: deletePrinter(printerId, printerList)
  })
);

export const deletePrintersSuccess = createAction(
  'printersAction/deletePrintersSuccess',
  (printers, printerList) => ({
    payload: deleteMultiplePrinters(printerList, printers)
  })
);

export const addPrinterSuccess = createAction('printersAction/addPrinterSuccess', (printer, printerList) => ({
  payload: addNewPrinter(printer, printerList)
}));


export const updatePrintersInformationSuccess = createAction(
  'printersAction/updateInformationSuccess',
  information => ({
    payload: normalizePrintersInformation(information)
  })
);
