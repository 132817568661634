import {faPhoneLaptop} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 5,
  text: 'All device settings registered',
  icon: faPhoneLaptop,
  isEnabled: data => {
    if (data.iamsUserPlatform?.information?.isEnabled) return true;
    return data.computerSetupPlatform?.desktopSettings?.isEnabled;
  },
  isEnabledOverview: data => {
    if (data.iamsUserPlatform?.isEnabled) return true;
    return data.computerSetupPlatform?.isEnabled;
  },
  getStatusText: data => {
    return data.computerSetupPlatform.desktopSettings.modifiedAt;
  }
};
