import React from 'react';
import PropTypes from 'prop-types';
import './contactContainer.scss';
import ContactInformation from '../ContactInformation/ContactInformation';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

export const ContactContainer = ({list, contactType, title, link}) => {
  const {id} = useSelector(state => state.company.data.info);
  const history = useHistory();

  return (
    <div className='contact-container'>
      <div className='contact-header'>
        <h2>{title}</h2>
        <p className='bf-link' style={{display: 'inline'}} onClick={() => history.push(`/${id}/${link}`)}>
          {link}
        </p>
      </div>
      <div className='bfl-grid contact-grid'>
        {list.map((contact, i) => {
          return (
            <div className='contact' key={i}>
              <ContactInformation contact={contact[contactType]} title={contact.name} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ContactContainer.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
  contactType: PropTypes.string.isRequired
};

export default ContactContainer;
