import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {fetchDomains, fetchDomainsError, updateDomains, updateDomainsError, uploadDomainsError} from './domainReducers';
import {fetchDomainsSuccess} from './domainActions';
import produce from 'immer';

export const fetchDomainsAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.domainPlatform.domainStatus;
  if (fetching) return null;
  dispatch(fetchDomains());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/domains`,
    method: 'GET'
  }).then(domains => dispatch(fetchDomainsSuccess(domains)))
    .catch(error => dispatch(fetchDomainsError(error)));
};

export const addDomainAsync = domain => (dispatch, getState) => {
  const {domainStatus, information} = getState().company.data.domainPlatform;
  if (domainStatus.updating) return null;
  dispatch(updateDomains());

  const {id} = getState().company.data.info;

  const body = produce(domain, draft => {
    draft.domainPlatformId = information.id
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/domains`,
    method: 'POST',
    body: body
  })
    .then(() => dispatch(fetchDomainsAsync(id)))
    .catch(error => dispatch(updateDomainsError(error)));
};

export const updateDomainAsync = state => (dispatch, getState) => {
  const {updating} = getState().company.data.domainPlatform.domainStatus;
  if (updating) return null;
  dispatch(updateDomains());

  const {information} = getState().company.data.domainPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/domains/${state.id}`,
    method: 'PUT',
    body: state
  }).then(() => dispatch(fetchDomainsAsync(information.companyId)))
    .catch(error => dispatch(updateDomainsError(error)));
};

export const multiPutDomainStatusAsync = domainList => (dispatch, getState) => {
  const {updating} = getState().company.data.domainPlatform.domainStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;
  dispatch(updateDomains());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/domainStatus/bulk`,
    method: 'PUT',
    body: domainList
  })
    .then(() => dispatch(fetchDomainsAsync(id)))
    .catch(error => dispatch(updateDomainsError(error)));
};

export const addDomainCommentAsync = (comment, statusId) => (dispatch, getState) => {
  const {domainStatus} = getState().company.data.domainPlatform;
  if (domainStatus.updating) return null;
  dispatch(updateDomains());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/domainStatus/${statusId}/comment`,
    method: 'POST',
    body: comment
  })
    .then(() => dispatch(fetchDomainsAsync(id)))
    .catch(error => dispatch(updateDomainsError(error)));
};

export const editDomainCommentAsync = (comment) => (dispatch, getState) => {
  const {domainStatus} = getState().company.data.domainPlatform;
  if (domainStatus.updating) return null;
  dispatch(updateDomains());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/domainStatus/${comment?.domainStatusId}/comment/${comment?.id}`,
    method: 'PATCH',
    body: comment
  })
    .then(() => dispatch(fetchDomainsAsync(id)))
    .catch(error => dispatch(updateDomainsError(error)));
};

export const deleteDomainAsync = domainId => (dispatch, getState) => {
  const {fetching} = getState().company.update;
  if (fetching) return null;
  dispatch(updateDomains());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/domains/${domainId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchDomainsAsync(id)))
    .catch(error => dispatch(updateDomainsError(error)));
};

export const deleteDomainCommentAsync = (statusId, commentId) => (dispatch, getState) => {
  const {fetching} = getState().company.update;
  if (fetching) return null;
  dispatch(updateDomains());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/domainStatus/${statusId}/comment/${commentId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchDomainsAsync(id)))
    .catch(error => dispatch(updateDomainsError(error)));
};

export const uploadDomainCsv = (file, replace) => (dispatch, getState) => {
  const {updating} = getState().company.data.domainPlatform.domainStatus;
  if (updating) return null;
  dispatch(updateDomains());

  const {id} = getState().company.data.info;
  const formData = new FormData();
  formData.append(`csv/${id}`, file);

  return apiFetch(`/companies/${id}/domains/csv?replace=${replace}`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchDomainsAsync(id)))
    .catch(error => {
      dispatch(uploadDomainsError(error));
    });
};

export const downloadDomainCsv = () => (dispatch, getState) => {
  const {updating} = getState().company.data.domainPlatform.domainStatus;
  if (updating) return null;
  const {id} = getState().company.data.info;

  return apiFetch(`/companies/${id}/domains/getExcelFile`, {
    method: 'GET'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to download CSV.');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `locations_${id}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .then(res => res.flush())
    .catch(error => console.log(error));
};