import React, {useState, useEffect, useCallback} from 'react';
import {Grid, Input, Button} from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import {externalRoleOptions} from '../NewApplication/NewContent/NewContact';
import {useSelector, useDispatch} from 'react-redux';
import {fetchApplicationsAsync} from '../../../../../redux/company/applications/applicationThunks';
import {v4 as uuidv4} from 'uuid';

const EMAIL_REGEX = /^.+@.+$/;

const initialContactState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  roles: [],
  selectedContact: null,
  submitted: false
};

function validateContact(contact) {
  return contact.firstName && contact.lastName && contact.email && EMAIL_REGEX.test(contact.email);
}

function submitContact(contact, addContact) {
  if (!validateContact(contact)) return;

  const newContact = {
    id: uuidv4(),
    firstName: contact.firstName,
    lastName: contact.lastName,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
    contactRoles: contact.roles.map(role => ({
      contactRole: role.value
    }))
  };

  addContact(newContact, false);
}

const AddNewVendorContact = ({addContact, cancelAddContact}) => {
  const [contact, setContact] = useState(initialContactState);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const {id} = useSelector(state => state.company.data.info);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    setContact(prevContact => ({...prevContact, submitted: true}));
    submitContact(contact, addContact);
  }, [contact, addContact]);

  const handleCancel = useCallback(() => {
    setContact(initialContactState);
    cancelAddContact(false);
  }, [cancelAddContact]);

  useEffect(() => {
    dispatch(fetchApplicationsAsync(id));
  }, [dispatch]);

  let vendorOptions = applicationList.flatMap(a => a.vendorContacts);

  useEffect(() => {
    if (contact.selectedContact) {
      setContact(prevContact => ({
        ...prevContact,
        firstName: contact.selectedContact.firstName,
        lastName: contact.selectedContact.lastName,
        phoneNumber: contact.selectedContact.phoneNumber,
        email: contact.selectedContact.email
      }));
    }
  }, [contact.selectedContact]);

  return (
    <div className='add-new-contact-card'>
      <div style={{color: 'var(--bfc-base-c-1)', fontSize: '16px', fontWeight: '600', marginBottom: '24px'}}>
        Add new vendor contact
      </div>
      <Grid gap={12} small={2}>
        {vendorOptions.length > 0 && (
          <Grid.Span small={2} style={{marginBottom: '12px'}}>
            <Select
              label='Select vendor'
              description='Vendor from existing list. If you need a new contact, you can fill in the form below.'
              placeholder='Select user'
              options={vendorOptions.map(contact => ({
                value: contact.id,
                label: `${contact.firstName} ${contact.lastName}`
              }))}
              onChange={selectedOption => {
                const selected = vendorOptions.find(contact => contact.id === selectedOption.value);
                setContact(prevContact => ({...prevContact, selectedContact: selected}));
              }}
            />
          </Grid.Span>
        )}
        <Input
          label='First name'
          value={contact.firstName}
          onChange={e => setContact(prevContact => ({...prevContact, firstName: e.target.value}))}
          state={contact.submitted && !contact.firstName ? 'alert' : contact.firstName ? 'success' : ''}
          feedback={contact.submitted && !contact.firstName ? 'Please enter first name' : undefined}
        />
        <Input
          label='Last name'
          value={contact.lastName}
          onChange={e => setContact(prevContact => ({...prevContact, lastName: e.target.value}))}
          state={contact.submitted && !contact.lastName ? 'alert' : contact.lastName ? 'success' : ''}
          feedback={contact.submitted && !contact.lastName ? 'Please enter last name' : undefined}
        />
        <Input
          label='Phone number'
          description='Vendor phone number'
          value={contact.phoneNumber}
          onChange={e => setContact(prevContact => ({...prevContact, phoneNumber: e.target.value}))}
        />
        <Input
          label='E-mail'
          description='Vendor e-mail address'
          value={contact.email}
          onChange={e => setContact(prevContact => ({...prevContact, email: e.target.value}))}
          state={
            contact.submitted && (!contact.email || !/^.+@.+$/.test(contact.email))
              ? 'alert'
              : contact.email
              ? 'success'
              : ''
          }
          feedback={
            contact.submitted && (!contact.email || !/^.+@.+$/.test(contact.email))
              ? 'Please enter a valid email'
              : undefined
          }
        />
        <Grid.Span small={2} style={{marginBottom: '12px'}}>
          <Select
            label='Roles'
            description='Select the contact role(s)'
            isMulti
            options={externalRoleOptions}
            value={contact.roles}
            onChange={selectedRoles => setContact(prevContact => ({...prevContact, roles: selectedRoles}))}
            placeholder='Select roles'
          />
        </Grid.Span>
      </Grid>
      <Grid.Span small={2} style={{display: 'flex', justifyContent: 'space-between', marginTop: '12px'}}>
        <Button pill style={{alignSelf: 'flex-start'}} onClick={handleCancel}>
          Cancel
        </Button>
        <Button pill variant='filled' onClick={handleSubmit}>
          Save Contact
        </Button>
      </Grid.Span>
    </div>
  );
};
export default AddNewVendorContact;
