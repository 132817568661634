import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  fetchDocumentationSuccess,
  fetchInformationSuccess,
  updateDocumentationInformationSuccess,
  updateDocumentationSuccess
} from './documentationActions';

const updateOnInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchDocumentationSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateDocumentationInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const documentationInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    comment: '',
    documentationCount: 0
  },
  updateOnInformationChangeReducer((payload, state) => {
    state.id = payload.documentationPlatform.information.id;
    state.companyId = payload.documentationPlatform.information.companyId;
    state.isEnabled = payload.documentationPlatform.information.isEnabled;
    state.modifiedAt = payload.documentationPlatform.information.modifiedAt;
    state.comment = payload.documentationPlatform.information.comment;
    state.documentationCount = payload.documentationPlatform.information.documentationCount;
    return state;
  })
);

const updateOnDocumentationChangeReducer = getState => {
  return {
    [fetchDocumentationSuccess]: (state, action) => getState(action.payload, state),
    [updateDocumentationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const documentationReducer = createReducer(
  [],
  updateOnDocumentationChangeReducer((payload, state) => {
    state = payload.documentationPlatform.documentList;
    return state;
  })
);

const updateOnDocumentationStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchDocumentationSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const documentationStateReducer = createReducer(
  0,
  updateOnDocumentationStateReducer((payload, state) => {
    state = payload.documentationPlatform.state;
    return state;
  })
);

export const documentationStatusSlice = createSlice({
  name: 'documentationAction',
  initialState: {
    fetching: false,
    adding: false,
    updating: false,
    error: false,
    addError: false,
    uploadError: false,
    uploadErrorMessage: '',
    uploadErrorResult: []
  },
  reducers: {
    fetchDocumentation: state => {
      state.fetching = true;
    },
    fetchDocumentationError: state => {
      state.fetching = false;
      state.error = true;
    },
    fetchInformation: state => {
      state.fetching = true;
    },
    fetchInformationError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateDocumentation: state => {
      state.updating = true;
    },
    updateDocumentationError: state => {
      state.updating = false;
      state.error = true;
    },
    uploadDocumentationError: (state, action) => {
      state.updating = false;
      state.uploadError = true;
      state.uploadErrorMessage = "Upload error";
      state.uploadErrorResult = action.payload;
    },
    uploadDocumentationSuccess: state => {
      state.updating = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    },
    updateDocumentationClear: state => {
      state.updating = false;
      state.uploadError = false;
      state.adding = false;
      state.addError = false;
      state.fetching = false;
      state.error = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    }
  },
  extraReducers: {
    [fetchDocumentationSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateDocumentationInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateDocumentationSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    }
  }
});

export const {
  updateDocumentation,
  updateDocumentationError,
  updateDocumentationClear,
  uploadDocumentationError,
  uploadDocumentationSuccess,
  fetchDocumentation,
  fetchDocumentationError,
  fetchInformation,
  fetchInformationError
} = documentationStatusSlice.actions;
