import {Card, Icon} from '@intility/bifrost-react';
import {Link, useHistory} from 'react-router-dom';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import './overviewPages.scss';

export const OverviewPage = ({image, link, header, children}) => {
  const history = useHistory();

  return (
    <Card
      className='overview-page-box'
      tabIndex={0}
      onKeyDown={e => {
        e.keyCode !== 13 || history.push(link);
      }}
    >
      <Link to={link}>
        <Card.Logo imageUrl={image} />
        <span>
          <Card.Title>{header}</Card.Title>
          <Icon icon={faArrowRight} />
        </span>
        <Card.Content>{children}</Card.Content>
      </Link>
    </Card>
  );
};

export default OverviewPage;
