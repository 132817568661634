import { Badge, Icon, Spinner } from "@intility/bifrost-react";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import apiFetch from "../../../../utils/apiFetch";
import './serviceArticle.scss';
import { faCheck } from "@fortawesome/pro-regular-svg-icons";


export const ServiceArticle = ({article, id, intilityServices}) => {
  const [image, setimage ] = useState();
  const [fetching, setFetching] = useState(true)
  const history = useHistory();

  useEffect(() => {
    if (article === undefined) return;
    if (image !== undefined) return;
    imageFetcher().finally(() => setFetching(false))
  }, [article])

  const imageFetcher = async () => {
    apiFetch(`/Publish/images/${article?.logo}?language=en-us`).then(response => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    }).then(value => setimage(value))
  };

  return (
      <div className='service-article' onClick={() => history.push(`services/${id}`)}>
        <div key="image" className="service-article-header" style={{ marginRight: "flex-end", borderRadius: "11px 0 0 11px" }}>
          <div className='left-header'>
            {(!image && !fetching) ? <Spinner size={42} style={{marginRight: 'var(--bfs12)'}} />
              :
              <img src={image} className="article-image" alt="Article image" style={{ borderRadius: "11px 0 0 11px" }} />
            }
            <div>
              <h5>{article?.textContents[0]?.title}</h5>
              <p className="bf-small">{article?.included ? "Included service" : "Optional service"}</p>
            </div>
          </div>
          <div>
            {intilityServices?.find(i => i.articleId === article.id)?.isActive &&
              <Badge state={"success"} pill>
                <Icon icon={faCheck}/>
                Added
              </Badge>
            }
          </div>
        </div>
        <div className='content-container'>
          <div style={{padding: 'var(--bfs12)'}}>
            <p>{article?.textContents[0]?.ingress}</p>
            {article?.categories?.map((c, i) => <Badge key={i} style={{marginRight: 'var(--bfs4)'}} pill>{c?.names[0]?.name}</Badge>)}
          </div>
        </div>
      </div>
  );
}

export default ServiceArticle