import {CheckboxCard, Input, Grid, Label} from '@intility/bifrost-react';
import managedIcons from 'assets/images/addApplication/ManagedIcons.png';
import saasIcons from 'assets/images/addApplication/SaasIcons.png';

export const BasicDetails = ({appObject, setAppObject, submitted}) => {
  const handleInputChange = event => {
    setAppObject({...appObject, [event.target.name]: event.target.value});
  };

  const handleCheckboxChange = event => {
    if (event.target.value === 'managed') {
      setAppObject({
        ...appObject,
        saasApp: 0,
        serverApp: 1,
        url: '',
        identity: ''
      });
    } else if (event.target.value === 'saas') {
      setAppObject({
        ...appObject,
        saasApp: 1,
        serverApp: 0
      });
    }
  };

  const handleLoginTypeChange = event => {
    setAppObject({...appObject, identity: event.target.value});
  };

  return (
    <div className='basic-detail-box'>
      <div className='basic-detail-box-header'>
        <h5>Basic details</h5>
      </div>
      <Grid gap={16} className='basic-detail-box-content'>
        <Input
          description='Enter official name of the application'
          label='Application name'
          name='name'
          value={appObject.name}
          onChange={handleInputChange}
          required
          state={submitted && !appObject.name ? 'alert' : appObject.name ? 'success' : ''}
          feedback={submitted && !appObject.name ? 'Application name is required' : undefined}
        />
        <Input
          description='Application vendor name'
          label='Vendor name'
          name='vendor'
          value={appObject.vendor}
          onChange={handleInputChange}
        />
        <div>
          <Label>What kind of application is this</Label>
          <div className='bfc-base-2'>Select application type</div>
          <Grid small={2} style={{marginTop: 4}}>
            <CheckboxCard
              label='Managed Software Solution'
              type='radio'
              name='applicationTypeRadio'
              value='managed'
              onChange={handleCheckboxChange}
            >
              <Grid>
                <span>Applicable if your app is dependent on servers, containers or IaaS/PaaS services.</span>
                <img src={managedIcons} />
              </Grid>
            </CheckboxCard>
            <CheckboxCard
              label='SaaS application'
              type='radio'
              name='applicationTypeRadio'
              value='saas'
              onChange={handleCheckboxChange}
            >
              <Grid>
                <span>
                  Hosted by application vendor. Intility will handle access and identity with Single-Sign-On (SSO).
                </span>
                <img src={saasIcons} />
              </Grid>
            </CheckboxCard>
          </Grid>
        </div>
        {appObject.saasApp === 1 && (
          <div>
            <Input
              description='URL to the sign in page'
              label='Sign in url'
              name='url'
              value={appObject.saasInfo}
              onChange={handleInputChange}
            />
            <div style={{marginTop: 16}}>
              <Label>Log in identity</Label>
              <div className='bfc-base-2'>Select how users log in to the application</div>
              <Grid small={3} style={{marginTop: 8}}>
                <CheckboxCard
                  label='Other/unknown'
                  type='radio'
                  name='saasAppLoginType'
                  value='Other/unknown'
                  onChange={handleLoginTypeChange}
                >
                  If you are unsure about how you log in to the application
                </CheckboxCard>
                <CheckboxCard
                  label='Single Sign-on (Entra ID/Azure AD)'
                  type='radio'
                  name='saasAppLoginType'
                  value='azureAd'
                  onChange={handleLoginTypeChange}
                >
                  Using one account to log in to multiple services
                </CheckboxCard>
                <CheckboxCard
                  label='Application specific account'
                  type='radio'
                  name='saasAppLoginType'
                  value='appSpecificUser'
                  onChange={handleLoginTypeChange}
                >
                  If the log in identity is used only for this application
                </CheckboxCard>
              </Grid>
            </div>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default BasicDetails;
