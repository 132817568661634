import React, { useEffect, useState } from "react";
import './editorCard.scss';
import PropTypes from 'prop-types';
import { Badge, Icon, Tooltip, useFloatingMessage } from "@intility/bifrost-react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { projectResponsibilityOptions } from "./Settings";


export const EditorCardAdder = ({addMethod, isAdmin}) => {
  const { showFloatingMessage } = useFloatingMessage();

  return (
    <div
      className='editor-card'
      style={{cursor: 'pointer'}}
      onClick={() => (isAdmin ? addMethod(true) : showFloatingMessage('This action requires admin rights.', {state: 'alert'}))}
    >
      <span className='editor-card-adder'>
        <Icon icon={faPlus} />
        <p className='bf-title-link  '>Invite a colleague</p>
      </span>
    </div>
  );
};

EditorCardAdder.propTypes = {
  addMethod: PropTypes.func,
  setPostError: PropTypes.func,
  isAdmin: PropTypes.bool
};

export const EditorCardPending = ({user, setEditUser}) => {
  const [userResponsibility, setUserResponsibility] = useState("");

  useEffect(() => {
    const roleValues = user?.userRole?.length > 1 ? user?.userRole?.split(',') : [];
    const roleArr = roleValues?.map(r => projectResponsibilityOptions.find(i => i.value === r));
    setUserResponsibility(roleArr);
  }, [user]);

  return (
    <div className='editor-card' aria-label="Edit user" tabIndex={0} onKeyDown={(e) => {e.keyCode !== 13 || setEditUser(user)}} onClick={() => setEditUser(user)}>
      <div className='editor-card-image'>
        <Tooltip content='Pending' placement='bottom'>
          <div>
            <Icon icon={faUser} />
          </div>
        </Tooltip>
      </div>
      <div className='editor-card-text'>
        <span className='bf-p bf-small'>
          <p className='bf-strong'>{user.name}</p>
          <p className='bf-em' style={user?.userRight === 0 ? {color: "var(--bfc-base-c-attn)" } : {color: "var(--bfc-base-c-2)" }}> ({user?.userRight === 0 ? 'Admin' : user?.userRight === 1 ? 'Editor' : 'Viewer'})</p>
          { userResponsibility?.length > 0 && <span>{userResponsibility.map((e, i) => <Badge className='bf-small' state={"neutral"} key={i} style={{marginRight: 'var(--bfs4)'}}>{e?.label}</Badge>)}</span> }
        </span>
      </div>
    </div>
  );
};

EditorCardPending.propTypes = {
  user: PropTypes.object,
  setEditUser: PropTypes.func
};

export const EditorCard = ({user, setEditUser}) => {
  const [userResponsibility, setUserResponsibility] = useState("");

  useEffect(() => {
    const roleValues = user?.userRole?.length > 1 ? user?.userRole?.split(',') : [];
    const roleArr = roleValues?.map(r => projectResponsibilityOptions.find(i => i.value === r));
    setUserResponsibility(roleArr);
  }, [user]);

  return (
    <div className='editor-card' aria-label="Edit user" tabIndex={0} onKeyDown={(e) => {e.keyCode !== 13 || setEditUser(user)}} onClick={() => setEditUser(user)}>
      <div className='editor-card-image'>
        {user?.profilePhoto ? (
          <img src={`data:image/png;base64,${user?.profilePhoto}`} alt={user?.name} />
        ) : (
          <div>
            <Icon icon={faUser} />
          </div>
        )}
      </div>
      <div className='editor-card-text'>
        <span className='bf-p bf-small'>
          <p className='bf-strong'>{user.name}</p>
          <p className='bf-em' style={user?.userRight === 0 ? {color: "var(--bfc-base-c-attn)" } : {color: "var(--bfc-base-c-2)" }}> ({user?.userRight === 0 ? 'Admin' : user?.userRight === 1 ? 'Editor' : 'Viewer'})</p>
          { userResponsibility?.length > 0 && <span>{userResponsibility.map((e, i) => <Badge className='bf-small' key={i} style={{marginRight: 'var(--bfs4)'}}>{e?.label}</Badge>)}</span> }
        </span>
      </div>
      <div/>
    </div>
  );
};

EditorCard.propTypes = {
  user: PropTypes.object,
  setEditUser: PropTypes.func
};

export default EditorCard;
