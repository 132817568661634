import {Breadcrumbs, Button, Checkbox, Label} from '@intility/bifrost-react';
import React, {useEffect, useState} from 'react';
import produce from 'immer';
import {CompanyUser} from '../../../../../utils/hub/models';
import {useDispatch, useSelector} from 'react-redux';
import './editUser.scss';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import CountryOptions from '../Options/CountryOptions';
import LanguageOptions from '../Options/LanguageOptions';
import { isStateValid, validateUser, validateUserIntility } from "../../../../../utils/wizard/stateValidations";
import {multiPut} from '../../../../../redux/company/users/userThunks';
import PropTypes from 'prop-types';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import SelectWithFeedback from '../../../../SelectWithFeedback/SelectWithFeedback';
import devLog from '../../../../../utils/devLog';
import UserTypeOptions, { getIntilityUserDescriptionOptions, RequiredValue } from "../Options/UserTypeOptions";
import useIntilityPermission from "../../../../../hooks/useIntilityPermission";
import {Link} from 'react-router-dom';
import {checkImmutableMatch} from './UserRow';

export const EditUser = ({newUser, templateUser, currentUserId, setCurrentUserId, isComplete, clean}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {userList, information} = useSelector(state => state.company.data.userPlatform);
  const [editable, setEditable] = useState(new CompanyUser());
  const [verify, setVerify] = useState(false);
  const [validation, setValidation] = useState(undefined);
  const [selectableBilling, setSelectableBilling] = useState([]);
  const [selectableLocation, setSelectableLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedBilling, setSelectedBilling] = useState({});
  const [userType, setUserType] = useState();
  const [optionalFieldsOpen, setOptionalFieldsOpen] = useState(false);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {billingList} = useSelector(state => state.company.data.billingPlatform);
  const validEmailCharsRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const intilityUser = useIntilityPermission();
  const dispatch = useDispatch();
  const locationName = locationList?.find(b => b.id === editable?.locationId)?.name;
  const billingName = billingList?.find(b => b.id === editable?.billingId)?.name;

  useEffect(() => {
    if (currentUserId) {
      const errors = validateUser(editable);
      setValidation(errors);
    }
  }, [editable, currentUserId]);

  useEffect(() => {
    if (verify) {
      const errors = validateUser(editable);
      setValidation(errors);
    }
  }, [editable]);

  useEffect(() => {
    if (currentUserId) {
      setEditable(userList.find(u => u.id === currentUserId));
    }
  }, [userList, currentUserId]);

  useEffect(() => {
    if (templateUser) {
      setSelectedLocation({value: templateUser.locationId, label: templateUser.locationName, type: 'locationId'});
      devLog('User template: ', templateUser.locationId);
      setEditable(
        produce(editable, draft => {
          draft.firstName = templateUser.firstName;
          draft.lastName = templateUser.lastName;
          draft.mobilePhone = templateUser.phoneNumber;
          draft.primaryEmailAddress = templateUser.email;
          draft.locationId = templateUser.locationId;
        })
      );
    } else if (currentUserId) {
      setSelectedLocation({value: editable.locationId, label: locationName, type: 'locationId'});
      setSelectedBilling({value: editable.billingId, label: billingName, type: 'billingId'});
    }
  }, [templateUser]);

  useEffect(() => {
    if (billingList.length === 1) {
      setSelectedBilling({value: billingList[0].id, label: billingList[0].name, type: 'billingId'});
      setEditable(
        produce(draft => {
          draft.billingId = billingList[0].id;
        })
      );
    }
    setSelectableBilling(
      billingList?.map(billing => ({
        label: billing.name,
        value: billing.id,
        type: 'billingId'
      }))
    );
  }, [billingList]);

  useEffect(() => {
    if (locationList.length === 1) {
      setSelectedLocation({value: locationList[0].id, label: locationList[0].name, type: 'locationId'});
      setEditable(
        produce(draft => {
          draft.locationId = locationList[0].id;
        })
      );
    }
    setSelectableLocation(
      locationList?.map(location => ({
        label: location.name,
        value: location.id,
        type: 'locationId'
      }))
    );
  }, [locationList]);

  const onChange = e => {
    const {name, value, checked} = e.target;

    if (name === 'accountEnabled') {
      setEditable(
        produce(draft => {
          draft[name] = checked;
        })
      );
    } else {
      setEditable(
        produce(draft => {
          draft[name] = value;
        })
      );
    }

  };

  const updateSelect = item => {
    if (item === null) return;
    setEditable(
      produce(draft => {
        draft[item.type] = item.value;
      })
    );
  };

  const saveUser = () => {
    setVerify(true);
    let errors = validateUser(editable);
    if (intilityUser) {
      errors = validateUserIntility(editable);
    }
    setValidation(errors);
    if (isStateValid(errors)) {
      dispatch(multiPut([editable]));
      newUser(false);
      setVerify(false);
    }
  };

  const deleteUser = () => {
    if (currentUserId !== undefined) {
      const user = produce(editable, draft => {
        draft.isEnabled = false;
      })
      setEditable(user);
      dispatch(multiPut([user]));
      setCurrentUserId(undefined);
      newUser(false);
    }
  };

  return (
    <div id='edit-user' >
      <div className='add-users-top-bar'>
        <div>
          <Breadcrumbs>
            <Breadcrumbs.Item>
              <Link to={`/${id}`}>Home</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>
              <Link to={'#'} onClick={() => newUser(false)}>Users</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>
              {`${editable.firstName || ''} ${editable.lastName || ''}`}
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <h1>{`${editable.firstName || ''} ${editable.lastName || ''}`}</h1>
        </div>
      </div>

      <div className='add-users-content'>
        <div className="add-user-content">
          <div className="add-user-method">
            <h5>Edit user</h5>
          </div>
          <div className="add-user-method-description">
            <div className="manual-user-import">
              <div className="manual-user-import-required bfl-grid">
                <SelectWithFeedback
                  isValidated={validation?.intilityUserType}
                  maxMenuHeight={200}
                  label={'Intility user type'}
                  isClearable={false}
                  className="users-box-select"
                  placeholder={'- Select user type -'}
                  options={getIntilityUserDescriptionOptions(information.userTypes)}
                  value={userType}
                  onChange={item => {
                    setUserType({value: item.value, label: item.shortLabel, type: item.type});
                    updateSelect({value: item.value, label: item.shortLabel, type: item.type});
                  }}
                  otherProps={
                    currentUserId && {
                      value: UserTypeOptions[editable?.intilityUserType]
                    }
                  }
                />
                <InputWithFeedback
                  onChange={onChange}
                  value={editable.firstName}
                  label='First Name'
                  name='firstName'
                  required={true}
                  otherProps={
                    validation?.firstName && {
                      state: 'alert',
                      feedback: 'Missing value'
                    }
                  }
                />
                <div>
                  <InputWithFeedback
                    onChange={onChange}
                    value={editable.lastName}
                    label='Last Name'
                    name='lastName'
                    required={RequiredValue(editable.intilityUserType)}
                    otherProps={
                      validation?.lastName ? {
                        state: 'alert',
                        feedback: 'Missing value'
                      } : (!checkImmutableMatch(editable, 'lastName') && editable?.immutableCopy?.lastName) &&
                        {
                          feedback: `Last sync Azure value: ${editable?.immutableCopy?.lastName}`
                        }
                    }
                  />
                </div>
                <SelectWithFeedback
                  isValidated={validation?.billingId}
                  label='Billing company'
                  maxMenuHeight={200}
                  required={true}
                  isClearable={false}
                  value={selectedBilling}
                  className='users-box-select'
                  placeholder={'- Select billing company -'}
                  options={selectableBilling}
                  onChange={item => {
                    updateSelect(item);
                    setSelectedBilling(item);
                  }}
                  otherProps={
                    currentUserId && {
                      value: {
                        value: editable?.billingId,
                        label: billingName
                      }
                    }
                  }
                />
                <SelectWithFeedback
                  isValidated={validation?.language}
                  label='Language'
                  maxMenuHeight={200}
                  required={true}
                  isClearable={false}
                  className='users-box-select'
                  placeholder={'- Select language -'}
                  options={LanguageOptions}
                  onChange={item => updateSelect(item)}
                  otherProps={
                    currentUserId && {
                      value: {value: editable?.language, label: editable?.language}
                    }
                  }
                />
                <div>
                  <InputWithFeedback
                    onChange={onChange}
                    value={editable.primaryEmailAddress}
                    name='primaryEmailAddress'
                    label='Username (UPN)'
                    required={true}
                    otherProps={
                      validation?.primaryEmailAddress ? {
                        state: 'alert',
                        feedback: 'Missing value or wrong format'
                      } : !validEmailCharsRegex.test(editable.primaryEmailAddress) && {
                        state: 'alert',
                        feedback: 'Email invalid, please review'
                      }
                    }
                  />
                </div>
                <div>
                  <InputWithFeedback
                    onChange={onChange}
                    maxLength={20}
                    value={editable.mobilePhone}
                    name='mobilePhone'
                    label='Mobile Phone'
                    required={RequiredValue(editable.intilityUserType)}
                    otherProps={
                      validation?.mobilePhone && {
                        state: 'alert',
                        feedback: 'Needs to be in format: +4712431243'
                      }
                    }
                  />
                </div>
                <SelectWithFeedback
                  isValidated={validation?.locationId}
                  maxMenuHeight={200}
                  required={true}
                  label={'Location'}
                  isClearable={false}
                  value={selectedLocation}
                  className='users-box-select'
                  placeholder={'- Select location -'}
                  options={selectableLocation}
                  onChange={item => {
                    updateSelect(item);
                    setSelectedLocation(item);
                  }}
                  otherProps={
                    currentUserId && {
                      value: {value: editable.locationId, label: locationName}
                    }
                  }
                />
              </div>
              <SelectWithFeedback
                isValidated={validation?.country}
                maxMenuHeight={200}
                required={true}
                label={'Country'}
                isClearable={false}
                className='users-box-select'
                placeholder={'- Select country -'}
                options={CountryOptions}
                onChange={item => updateSelect(item)}
                otherProps={
                  currentUserId && {
                    value: {value: editable.country, label: editable.country}
                  }
                }
              />
              <div className='add-user-button-expand'>
                <Button.Expand open={optionalFieldsOpen} onClick={() => setOptionalFieldsOpen(!optionalFieldsOpen)}>
                  Optional fields
                </Button.Expand>
                {optionalFieldsOpen &&
                  <div className='manual-user-import-optional bfl-grid'>
                    <InputWithFeedback onChange={onChange} value={editable.department} name='department'
                                       label='Department' />
                    <InputWithFeedback onChange={onChange} value={editable.title} name='title' label='Title' />
                    <InputWithFeedback onChange={onChange} value={editable.displayName} name='displayName'
                                       label='Display name' />
                    <InputWithFeedback onChange={onChange} value={editable.oldSam} name='oldSam' label='Old Sam' />
                    <InputWithFeedback
                      onChange={onChange}
                      otherProps={
                        !validEmailCharsRegex.test(editable.mail) && {
                          state: 'alert',
                          feedback: 'Email invalid, please review'
                        }
                      }
                      value={editable.mail}
                      name='mail'
                      label='Mail' />
                    <InputWithFeedback onChange={onChange} value={editable.employeeId} name='employeeId'
                                       label='Employee Id' />
                    <InputWithFeedback onChange={onChange} value={editable.oldSid} name='oldSid' label='Old Sid' />
                    <div>
                      <Label style={{marginBottom: 'var(--bfs4)'}}>Entra ID status</Label>
                      <Checkbox style={{marginTop: 'auto'}} button type={"check"} onChange={onChange}
                                checked={editable.accountEnabled} name='accountEnabled' label='Enable account' />
                    </div>
                    <InputWithFeedback onChange={onChange} value={editable.description} name='description'
                                       label='Description' />
                    <InputWithFeedback onChange={onChange} value={editable.comment} name='comment' label='Comment' />
                    <InputWithFeedback onChange={onChange} value={editable.physicalOffice} name='physicalOffice'
                                       label='Physical Office' />
                    <InputWithFeedback onChange={onChange} value={editable.officePhone} name='officePhone'
                                       label='Office Phone' />
                    <InputWithFeedback onChange={onChange} value={editable.streetAddress} name='streetAddress'
                                       label='Street Address' />
                    <InputWithFeedback onChange={onChange} value={editable.postalCode} name='postalCode'
                                       label='Postal Code' />
                    <InputWithFeedback onChange={onChange} value={editable.city} name='city' label='City' />
                    <InputWithFeedback onChange={onChange} value={editable.state} name='state' label='State' />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="add-user-method-footer">
          <Button variant="outline" state="alert" icon={faTrash} onClick={() => deleteUser()}>
            Delete
          </Button>
          <div>
            <Button
              onClick={() => {
                newUser(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="filled" onClick={() => saveUser()}>
              {currentUserId ? 'Save changes' : 'Add user'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

EditUser.propTypes = {
  newUser: PropTypes.func,
  templateUser: PropTypes.object,
  currentUserId: PropTypes.string,
  setCurrentUserId: PropTypes.func,
  isComplete: PropTypes.bool,
  clean: PropTypes.bool
};

export default EditUser;
