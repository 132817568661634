import EditLogistics from '../../components/BackOffice/EditComponents/EditLogistics';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Logistics',
  path: 'logistics/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditLogistics
};
