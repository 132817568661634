import PropTypes from "prop-types";
import './locationSystemAccordion.scss';
import { useSelector } from "react-redux";
import React from "react";
import { downloadFile } from "../../../../../../../utils/downloadFile";
import { Accordion, Badge, Icon} from "@intility/bifrost-react";
import { faBox, faEnvelope, faFile, faPencil, faPhone } from "@fortawesome/pro-regular-svg-icons";
import CheckUserDeleted from "../../../../../../../utils/hub/checkUserDeleted";

const LocationSystemAccordion = ({location, editLocation, activeAccordion, accordionName, accordionTitle, type, handleChange, isDisabled, children}) => {
  const {id} = useSelector(state => state.company.data.info);
  const files = location?.locationSystemDocumentations.filter(d => d.systemType === type);

  const getAccordionBadge = (icon, number) => {
    return <Badge style={{margin: '0 var(--bfs4)'}} state={number > 0 ? "chill" : "neutral"}><Icon icon={icon}/>{number}</Badge>
  }

  return (
    <Accordion.Item
      title={
        <span style={{display: 'flex', justifyContent: 'space-between'}}>
          {accordionTitle || accordionName}
          <span>
            {getAccordionBadge(faBox, location?.locationSystemItems?.filter(d => d?.systemType === type)?.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0))}
            {getAccordionBadge(faFile, location?.locationSystemDocumentations?.filter(d => d?.systemType === type)?.length || 0)}
          </span>
        </span>
      }
      actions={
        <Accordion.Action
          icon={faPencil}
          style={isDisabled ? { color: 'var(--bfc-neutral)' } : undefined}
          onClick={editLocation}
          aria-label='Edit'
        />}
      active={activeAccordion.includes(accordionName)}
      onClick={() => handleChange(accordionName)}
    >
      <div className='location-system-accordion'>
        <div className='location-system-accordion-header'>
          <div className='header-files'>
            {files.length > 0 ?
              files.map((document, i) => {
                const fileNameRegex = /\/(.*)/i;
                const fileName = document.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'

                return (
                  <span key={i + document.id}>
                    <p className='documentation bf-link' onClick={() => downloadFile(document, `/companies/${id}/locations/${location.id}/file/${document.id}`)}>
                      {fileName}
                    </p>
                  </span>
                );
              })
              :
              <p className='bf-em'>No documents uploaded</p>
            }
          </div>
          <div className='header-contacts bfl-autocol'>
            {location?.locationSystemContacts?.filter(c => c.systemType === type)?.sort((a, b) => {
              return b.systemResponsibilities?.includes('Main contact');
            })?.map(c => {
              return (
                <div className='drawer-contact-list-contact' key={c?.id}>
                  <span><p className='bf-strong'><CheckUserDeleted userName={c?.firstName + c?.lastName} email={c?.email} phoneNumber={c?.phoneNumber}/>{c?.firstName} {c?.lastName}</p><p className='bf-em'>{c?.systemResponsibilities?.length > 0 && `(${c?.systemResponsibilities})`}</p></span>
                  <span className='bf-link'><Icon icon={faEnvelope}/><a className='bf-link' style={{color: 'var(--bfc-base-c)'}} href={`mailto:${c?.email}`}>{c?.email}</a></span>
                  <span><Icon icon={faPhone}/>{c?.phoneNumber}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div className='location-system-accordion-content bfl-grid'>
          {children}
        </div>
      </div>
    </Accordion.Item>
  )

}

LocationSystemAccordion.propTypes = {
  type: PropTypes.number,
  location: PropTypes.object,
  contactList: PropTypes.array,
  activeAccordion: PropTypes.array,
  accordionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  accordionName: PropTypes.string,
  handleChange: PropTypes.func,
  editLocation: PropTypes.func,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,

}

export default LocationSystemAccordion