import React, {useEffect, useState} from 'react';
import achievements from '../../utils/hub/achievements';
import {ProgressBar} from '@intility/bifrost-react';
import PropTypes from 'prop-types';

const OverviewProgress = ({company, size = 'medium'}) => {
  const achievementPages = achievements;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressPoints = 0;
    achievementPages.forEach(achievement => {
      if (!achievement.isEnabledOverview(company)) {
        progressPoints += achievement.weight;
      }
    });
    setProgress(Math.round(progressPoints));
  }, [achievementPages, company]);

  return <ProgressBar style={{minWidth: '200px'}} value={progress} size={size}>{progress}%</ProgressBar>;
};

OverviewProgress.propTypes = {
  company: PropTypes.object.isRequired,
  size: PropTypes.string
};

export const DetailedOverviewProgress = ({company}) => {
  const achievementPages = achievements;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressPoints = 0;
    achievementPages.forEach(achievement => {
      if (!achievement.isEnabled(company)) {
        progressPoints += achievement.weight;
      }
    });
    setProgress(Math.round(progressPoints));
  }, [achievementPages, company]);

  return <ProgressBar style={{minWidth: '200px'}} value={progress}>{progress}%</ProgressBar>;
};

DetailedOverviewProgress.propTypes = {
  company: PropTypes.object.isRequired
};

export default OverviewProgress;
