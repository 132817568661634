const PrepareContact = contactState => {
  const {onboardingContact, productionContact, differentProductionContact} = contactState;

  return {
    onboardingContact,
    productionContact: differentProductionContact ? productionContact : onboardingContact
  };
};

const PrepareCompany = companyState => {
  const {name, organizationNumber, address, email} = companyState.data;

  return {
    billingList: [
      {
        name,
        organizationNumber: organizationNumber.replace(/\s/g, ''),
        address,
        email,
        main: true
      }
    ],
    billingCount: 1
  };
};

const PrepareDomain = domainState => {
  return {
    domainList: [
      {
        name: domainState.data.domainName,
        main: true
      }
    ],
    domainCount: 1
  };
};

export default state => {
  const {company} = state;

  // convert current state into shape that backend requires
  return {
    name: company.data.name,
    ...PrepareContact(company),
    billingPlatform: PrepareCompany(company),
    domainPlatform: PrepareDomain(company)
  };
};
