import useRolePermission from '../../hooks/useRolePermission';
import {useSelector} from 'react-redux';
import permissionPropTypes from './permissionPropTypes';

const IntilityOrMultiCompanyUser = ({children}) => {
  const hasRole = useRolePermission(['Admin', 'Writer', 'Reader']);
  const {companyMemberships} = useSelector(state => state.auth.user);

  if (hasRole) return children;
  if (companyMemberships?.length <= 1) return null;

  return children;
};

IntilityOrMultiCompanyUser.propTypes = permissionPropTypes;

export default IntilityOrMultiCompanyUser;
