import { Badge, Checkbox, Icon, Spinner, Table } from "@intility/bifrost-react";
import React, { useEffect, useState } from "react";
import apiFetch from "../../../utils/apiFetch";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";


export const ServiceRow = ({article, id, editable, activateService, publishService}) => {
  const {intilityServiceList, globalIntilityServiceList} = useSelector(state => state.company.data.intilityServicePlatform);
  const [image, setimage ] = useState();
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    if (article === undefined) return;
    if (image !== undefined) return;
    imageFetcher().finally(() => setFetching(false))
  }, [article])

  const imageFetcher = async () => {
    apiFetch(`/Publish/images/${article?.logo}?language=en-us`).then(response => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    }).then(value => setimage(value))
  };

  const existingArticle = intilityServiceList.find(s => s.articleId === article.id)

  return (
    <Table.Row key={article?.id}>
      <Table.Cell style={{display: 'flex', flexDirection: 'row'}}>{(!image && !fetching) ? <Spinner style={{marginRight: 'var(--bfs8)'}} size={21} /> :
        <img src={image} className="article-image" alt="IMG" style={{ height: '21px', width: '21px', marginRight: 'var(--bfs8)'}} />
      } {article?.textContents[0]?.title}
      </Table.Cell>
      <Table.Cell>{article?.categories?.map(c => <Badge pill style={{marginRight: 'var(--bfs4)'}}>{c?.names[0]?.name}</Badge>)}</Table.Cell>
      <Table.Cell>
        {editable ?
          <Checkbox label="Active" checked={existingArticle ? existingArticle.isActive : false} hideLabel onChange={e => activateService(article, e.target.checked)}/>
          :
          <Icon icon={existingArticle?.isActive ? faCheck : faTimes} style={existingArticle?.isActive  ? {color: 'var(--bfc-success)'} : {color: 'var(--bfc-alert)'}}/>}
      </Table.Cell>
      <Table.Cell>{editable ?
        <Checkbox label="Published" checked={existingArticle ? existingArticle.isEnabled : false} hideLabel onChange={() => publishService(article)} />
        :
        <Icon icon={existingArticle?.isEnabled ? faCheck : faTimes} style={existingArticle?.isEnabled ? {color: 'var(--bfc-success)'} : {color: 'var(--bfc-alert)'}}/>}
      </Table.Cell>
    </Table.Row>
  );
}

export default ServiceRow