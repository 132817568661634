import devLog from '../../utils/devLog';

export default apiCompany => {
  devLog('Normalizing state', apiCompany);

  return {
    applicationPlatform: {
      state: apiCompany.applicationPlatform?.state,
    },
    domainPlatform: {
      state: apiCompany.domainPlatform?.state,
    },
    locationPlatform: {
      state: apiCompany.locationPlatform?.state,
    },
    documentationPlatform: {
      state: apiCompany.documentationPlatform?.state,
    },
    userPlatform: {
      state: apiCompany.userPlatform?.state,
    },
    iamsUserPlatform: {
      state: apiCompany.iamsUserPlatform?.state,
    },
    printerPlatform: {
      state: apiCompany.printerPlatform?.state,
    },
    billingPlatform: {
      state: apiCompany.billingPlatform?.state,
    },
    communicationPlatform: {
      state: apiCompany.communicationPlatform?.state,
    },
    computerSetupPlatform: {
      state: apiCompany.computerSetupPlatform?.state,
    },
    mobilePlatform: {
      state: apiCompany.mobilePlatform?.state,
    },
    microsoft365Platform: {
      state: apiCompany.microsoft365Platform?.state,
    }
  };
};
