import {createReducer, createSlice} from '@reduxjs/toolkit';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';
import {
  addLocationSuccess,
  fetchInformationSuccess,
  fetchLocationsSuccess,
  updateLocationInformationSuccess,
  updateLocationsSuccess
} from './locationActions';

const updateOnInformationChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchLocationsSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateLocationInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const locationsInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    locationCount: 0
  },
  updateOnInformationChangeReducer((payload, state) => {
    state.id = payload.locationPlatform.information.id;
    state.companyId = payload.locationPlatform.information.companyId;
    state.isEnabled = payload.locationPlatform.information.isEnabled;
    state.modifiedAt = payload.locationPlatform.information.modifiedAt;
    state.locationCount = payload.locationPlatform.information.locationCount;
    return state;
  })
);

const updateOnLocationChangeReducer = getState => {
  return {
    [fetchLocationsSuccess]: (state, action) => getState(action.payload, state),
    [updateLocationsSuccess]: (state, action) => getState(action.payload, state),
    [addLocationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const locationsReducer = createReducer(
  [],
  updateOnLocationChangeReducer((payload, state) => {
    state = payload.locationPlatform.locationList;
    return state;
  })
);

const updateOnLocationStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchLocationsSuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const locationStateReducer = createReducer(
  0,
  updateOnLocationStateReducer((payload, state) => {
    state = payload.locationPlatform.state;
    return state;
  })
);

export const locationsStatusSlice = createSlice({
  name: 'locationsAction',
  initialState: {
    fetching: false,
    updating: false,
    adding: false,
    error: false,
    addError: false
  },
  reducers: {
    fetchLocations: state => {
      state.fetching = true;
    },
    fetchLocationsError: state => {
      state.fetching = false;
      state.error = true;
    },
    fetchInformation: state => {
      state.fetching = true;
    },
    fetchInformationError: state => {
      state.fetching = false;
      state.error = true;
    },
    addLocation: state => {
      state.adding = true;
    },
    addLocationError: state => {
      state.adding = false;
      state.addError = true;
    },
    updateLocations: state => {
      state.updating = true;
    },
    updateLocationsError: state => {
      state.updating = false;
      state.error = true;
    }
  },
  extraReducers: {
    [fetchLocationsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateLocationInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateLocationsSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [addLocationSuccess]: state => {
      state.adding = false;
      state.addError = false;
    }
  }
});

export const {
  updateLocations,
  updateLocationsError,
  addLocation,
  addLocationError,
  fetchLocations,
  fetchLocationsError,
  fetchInformation,
  fetchInformationError
} = locationsStatusSlice.actions;
