const LanguageOptions = [
  {value: 'en-US', label: 'English – United States', type: 'language'},
  {value: 'en-GB', label: 'English – United Kingdom', type: 'language'},
  {value: 'nb-NO', label: 'Norwegian (Bokmål) – Norway', type: 'language'},
  {value: 'nn-NO', label: 'Norwegian (Nynorsk) – Norway', type: 'language'},
  {value: 'sv-SE', label: 'Swedish – Sweden', type: 'language'},
  {value: 'sv-FI', label: 'Swedish – Finland', type: 'language'},
  {value: 'da-DK', label: 'Danish – Denmark', type: 'language'},
  {value: 'fi-FI', label: 'Finnish – Finland', type: 'language'},
  {value: 'sq-AL', label: 'Albanian – Albania', type: 'language'},
  {value: 'ar-SA', label: 'Arabic – Saudi Arabia', type: 'language'},
  {value: 'ar-AE', label: 'Arabic – United Arab Emirates', type: 'language'},
  {value: 'bg-BG', label: 'Bulgarian – Bulgaria', type: 'language'},
  {value: 'zh-CN', label: 'Chinese – China', type: 'language'},
  {value: 'hr-HR', label: 'Croatian – Croatia', type: 'language'},
  {value: 'cs-CZ', label: 'Czech – Czech Republic', type: 'language'},
  {value: 'nl-NL', label: 'Dutch – The Netherlands', type: 'language'},
  {value: 'nl-BE', label: 'Dutch – Belgium', type: 'language'},
  {value: 'et-EE', label: 'Estonian – Estonia', type: 'language'},
  {value: 'fr-FR', label: 'French – France', type: 'language'},
  {value: 'fr-CH', label: 'French – Switzerland', type: 'language'},
  {value: 'ka-GE', label: 'Georgian – Georgia', type: 'language'},
  {value: 'de-DE', label: 'German – Germany', type: 'language'},
  {value: 'de-CH', label: 'German – Switzerland', type: 'language'},
  {value: 'el-GR', label: 'Greek – Greece', type: 'language'},
  {value: 'hu-HU', label: 'Hungarian – Hungary', type: 'language'},
  {value: 'is-IS', label: 'Icelandic – Iceland', type: 'language'},
  {value: 'id-ID', label: 'Indonesian – Indonesia', type: 'language'},
  {value: 'it-IT', label: 'Italian – Italy', type: 'language'},
  {value: 'ja-JP', label: 'Japanese – Japan', type: 'language'},
  {value: 'lv-LV', label: 'Latvian – Latvia', type: 'language'},
  {value: 'lt-LT', label: 'Lithuanian – Lithuania', type: 'language'},
  {value: 'pl-PL', label: 'Polish – Poland', type: 'language'},
  {value: 'pt-PT', label: 'Portuguese – Portugal', type: 'language'},
  {value: 'sk-SK', label: 'Slovak – Slovakia', type: 'language'},
  {value: 'sl-SI', label: 'Slovenian – Slovenia', type: 'language'},
  {value: 'es-ES', label: 'Spanish – Spain', type: 'language'},
  {value: 'tr-TR', label: 'Turkish – Turkey', type: 'language'},
  {value: 'uk-UA', label: 'Ukrainian – Ukraine', type: 'language'},
];

export default LanguageOptions;
