import Select from "@intility/bifrost-react-select";
import React from 'react';
import PropTypes from 'prop-types';

export const SelectWithFeedback = ({
  isValidated,
  maxMenuHeight,
  feedBack = 'Missing value',
  value,
  onChange,
  label,
  hideLabel = false,
  disabled = false,
  options,
  required = false,
  placeholder,
  className,
  isClearable = true,
  description,
  otherProps
}) => {
  return (
    <Select
      {...(isValidated && {state: 'alert', feedback: feedBack})}
      maxMenuHeight={maxMenuHeight}
      required={required}
      label={label}
      value={value}
      isClearable={isClearable}
      hideLabel={hideLabel}
      isDisabled={disabled}
      className={className}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      description={description}
      {...otherProps}
    />
  );
};

SelectWithFeedback.propTypes = {
  isValidated: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  feedBack: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  otherProps: PropTypes.any,
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string])
};

export default SelectWithFeedback;
