import {apiFetch, apiFetchIdCollection} from '../../../../utils/apiFetch';
import {
  addWorkplaceDeviceSuccess,
  deleteWorkplaceDeviceSuccess,
  fetchWorkplaceDevicesSuccess
} from '../iamsUsersActions';
import {
  addWorkplaceDevice,
  addWorkplaceDeviceError,
  fetchWorkplaceDevices,
  fetchWorkplaceDevicesError,
  updateWorkplaceDevices,
  updateWorkplaceDevicesError
} from '../iamsUsersReducers';

export const fetchWorkplaceDevicesAsync = companyId => (dispatch, getState) => {
  const {fetchingDevices} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (fetchingDevices) return null;
  dispatch(fetchWorkplaceDevices());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/workplaceDevices`,
    method: 'GET'
  }).then(iamsUsers => dispatch(fetchWorkplaceDevicesSuccess(iamsUsers)))
    .catch(error => dispatch(fetchWorkplaceDevicesError(error)));
};

export const addWorkplaceDeviceAsync = device => (dispatch, getState) => {
  const {workplaceDevices} = getState().company.data.iamsUserPlatform;
  const {addingDevice} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (addingDevice) return null;
  dispatch(addWorkplaceDevice());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/workplaceDevices`,
    method: 'POST',
    body: device
  })
    .then((item) => dispatch(addWorkplaceDeviceSuccess(item, workplaceDevices)))
    .catch(error => dispatch(addWorkplaceDeviceError(error)));
};

export const deleteWorkplaceDeviceAsync = deviceId => (dispatch, getState) => {
  const {workplaceDevices} = getState().company.data.iamsUserPlatform;
  const {updatingDevices} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (updatingDevices) return null;
  dispatch(updateWorkplaceDevices());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/workplaceDevices/${deviceId}`,
    method: 'DELETE'
  })
    .then(() => dispatch(deleteWorkplaceDeviceSuccess(deviceId, workplaceDevices)))
    .catch(error => dispatch(updateWorkplaceDevicesError(error)));
};