import {
  addCurrentVendorContactSuccess,
  deleteCurrentVendorContactSuccess,
  updateCompanyInformationSuccess,
  updateCompanySuccess,
  updateCurrentVendorContactSuccess
} from './companyUpdateSlice';
import {fetchCompanySuccess} from './companyFetchSlice';
import {createReducer} from '@reduxjs/toolkit';
import {Contact, VendorContact} from '../../utils/hub/models';
import {emptyGuid} from '../../utils/guid';

export const updateOnCompanyChange = getState => {
  return {
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const companyInformationReducer = createReducer(
  {
    id: '',
    name: '',
    deploymentDate: null,
    onboardedDate: null,
    lockOutTimer: null,
    companyServiceId: emptyGuid,
    isEnabled: true,
    modifiedAt: '',
    createdAt: '',
    tenantId: '',
    office365Url: '',
    office365Status: '',
    onboardingContact: new Contact(),
    productionContact: new Contact()
  },
  updateOnCompanyChange((payload, state) => {
    state.id = payload.id;
    state.name = payload.name;
    state.isEnabled = payload.isEnabled;
    state.deploymentDate = payload.deploymentDate;
    state.onboardedDate = payload.onboardedDate;
    state.lockOutTimer = payload.lockOutTimer;
    state.companyServiceId = payload.companyServiceId;
    state.modifiedAt = payload.modifiedAt;
    state.createdAt = payload.createdAt;
    state.tenantId = payload.tenantId;
    state.office365Url = payload.office365Url;
    state.office365Status = payload.office365Status;
    state.onboardingContact = payload.onboardingContact;
    state.productionContact = payload.productionContact;
    return state;
  })
);

export const lockOutTimerReducer = createReducer(
  {lockOutTimer: ''},
  updateOnCompanyChange((payload, state) => {
    state.lockOutTimer = payload?.lockOutTimer;
  })
);

export const onboardedDateReducer = createReducer(
  {onboardedDate: ''},
  updateOnCompanyChange((payload, state) => {
    state.onboardedDate = payload?.onboardedDate;
  })
);

export const updateOnCurrentVendorChange = getState => {
  return {
    [updateCurrentVendorContactSuccess]: (state, action) => getState(action.payload, state),
    [addCurrentVendorContactSuccess]: (state, action) => getState(action.payload, state),
    [deleteCurrentVendorContactSuccess]: (state, action) => getState(action.payload, state),
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
  };
};

export const currentVendorContactReducer = createReducer(
  {currentVendorContact: new VendorContact()},
  updateOnCurrentVendorChange((payload, state) => {
    state.currentVendorContact = payload?.currentVendorContact;
  })
);

export default updateOnCompanyChange;
