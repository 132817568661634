import {Button, Drawer} from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import React, {useEffect, useState} from 'react';
import produce from 'immer';
import '../NewApplication/NewContent/newContact.scss';
import {ApplicationContact} from '../../../../../utils/hub/models';
import PropTypes from 'prop-types';
import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUsersAsync} from '../../../../../redux/company/users/userThunks';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import {
  externalRoleOptions,
  GetVendorRole,
} from "../NewApplication/NewContent/NewContact";

export const NewStepVendorContact = ({editContact, setEditContact, isOpen, setIsOpen, appObject, setAppObject}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const [contact, setContact] = useState(new ApplicationContact());
  const [editContactIndex, setEditContactIndex] = useState(undefined);
  const [validation, setValidation] = useState(undefined);
  const [contactRoles, setContactRoles] = useState([]);
  const [vendorOption, setVendorOption] = useState(null);
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const onContactChange = e => {
    const {name, value} = e.target;
    setContact(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const onRoleChange = e => {
    setContactRoles(e)
    setContact(
      produce(draft => {
        draft.contactRoles = e.map(r => ({contactRole: r.value}));
      }));
  };

  let vendorOptions = applicationList.map(a => a.vendorContacts).flatMap(u => u)
  vendorOptions = [...new Map(vendorOptions.map(item =>
    [item.email, item])).values()]
    .map(u => {
      return {value: u, label: `${u.firstName || ''} ${u.lastName || ''}`};
    });

  const onSave = () => {
    const isValid = contact?.phoneNumber?.length > 0 || contact?.email?.length > 0
    setValidation(isValid);
    if (isValid && editContact !== undefined && editContact !== null) {
      setAppObject(
        produce(draft => {
          draft.vendorContacts?.length > 0 ?
          draft.vendorContacts[editContactIndex] = contact :
          draft.vendorContacts = [contact]
        })
      );
      resetContact();
    } else if (isValid) {
      setAppObject(
        produce(draft => {
          draft.vendorContacts?.length > 0 ?
          draft.vendorContacts = [...draft?.vendorContacts, contact] :
          draft.vendorContacts = [contact]
        })
      );
      resetContact();
    }
  };

  const onDelete = () => {
    setIsOpen(false);
    const index = appObject?.vendorContacts?.findIndex(obj => obj === editContact);
    setAppObject(produce(appObject, draft => {
      draft?.vendorContacts.splice(index, 1);
    }));
    resetContact();
  }

  const resetContact = () => {
    setContactRoles([]);
    setContact(new ApplicationContact());
    setIsOpen(false);
    setValidation(undefined);
    setVendorOption(null);
    setEditContact(undefined);
  };

  const onUserChange = e => {
    const {value} = e;
    setVendorOption(e);
    setContact(
      produce(draft => {
        draft.firstName = value.firstName;
        draft.lastName = value.lastName;
        draft.email = value.email || value.primaryEmailAddress;
        draft.phoneNumber = value.phoneNumber || value.mobilePhone;
      })
    );
  };

  const getRoles = (contactroles) => {
    return contactroles.map(r => {
      return {value: r.contactRole, label: GetVendorRole(r.contactRole)};
    });
  };

  useEffect(() => {
    if (editContact !== undefined && editContact !== null) {
      setEditContactIndex(appObject.vendorContacts.findIndex(u => u.email === editContact.email || u.phoneNumber === editContact.phoneNumber))
      setContact(editContact);
      setContactRoles(getRoles(editContact.contactRoles));
      const user = vendorOptions?.find(
        u => u.email === editContact.email || u.mobilePhone === editContact.phoneNumber
      );
      setVendorOption({
        value: user,
        label: `${user?.firstName || ''} ${user?.lastName || ''}`
      });
    } else {
      setContact(new ApplicationContact());
    }
  }, [editContact]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchUsersAsync(id));
  }, [editContact, dispatch, user, id]);

  return (
    <Drawer
      isOpen={isOpen}
      header={editContact !== undefined && editContact !== null ? `Edit contact` : 'Add new contact'}
      onRequestClose={() => resetContact()}
      id='new-contact'
      overlay={true}
      footer={
        <div className='drawer-bottom-button-row'>
          {editContact !== undefined && editContact !== null ? (
            <Button
              variant='outline'
              state='alert'
              onClick={() => onDelete()}
              icon={faTimes}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <div className='button-row-collection'>
            <Button onClick={() => resetContact()} key={0}>
              Cancel
            </Button>
            <Button variant='filled' onClick={() => onSave()} key={1}>
              {editContact !== undefined && editContact !== null ? 'Save' : 'Add'}
            </Button>
          </div>
        </div>
      }
    >
      <div className='body'>
          {(editContact === undefined || editContact === null) &&
            <Select
              label='Choose from existing vendors'
              value={vendorOption}
              options={vendorOptions}
              name='contact'
              onChange={e => onUserChange(e)}
            />
          }
        <div className='bfl-grid vendor-content'>
          <InputWithFeedback
            onChange={onContactChange}
            value={contact?.firstName}
            name='firstName'
            label='First Name'
          />
          <InputWithFeedback onChange={onContactChange} value={contact.lastName} name='lastName' label='Last Name' />
          <InputWithFeedback
            onChange={onContactChange}
            value={contact.phoneNumber}
            name='phoneNumber'
            label='Phone Number'
            otherProps={
              validation === false && {
                state: 'warning',
                feedback: 'Need email or phone number'
              }
            }
          />
          <InputWithFeedback
            onChange={onContactChange}
            value={contact.email}
            name='email'
            label='E-mail'
            otherProps={
              validation === false && {
                state: 'warning',
                feedback: 'Need email or phone number'
              }
            }
          />
          <Select
            label='Role(s)'
            placeholder='- Select contact roles -'
            options={externalRoleOptions}
            onChange={onRoleChange}
            value={contactRoles}
            isMulti
          />
        </div>
      </div>
    </Drawer>
  );
};

NewStepVendorContact.propTypes = {
  editContact: PropTypes.object,
  setEditContact: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func
};

export default NewStepVendorContact;
