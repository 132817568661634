import { Badge, Button, Checkbox, Dropdown, Input, Table, useFloatingMessage } from "@intility/bifrost-react";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { countRowChecks } from "./EditDomains";
import produce from "immer";
import { DomainComment, DomainStatus } from "../../../utils/hub/models";
import { emptyGuid } from "../../../utils/guid";
import CommentRow, { commentTypes } from "../Components/CommentRow";
import { useSelector } from "react-redux";

export const EditDomainsTableRow = ({domain, listCopy, setListCopy, itemsToEdit, setItemsToEdit, setOpenModal, setCurrentStatus, setEditComment}) => {
  const {information} = useSelector(state => state.company.data.domainPlatform);
  const { showFloatingMessage } = useFloatingMessage()

  const onDomainChange = (name, value, domain, stage) => {
    const newValue = produce(domain, draft => {
      if (draft.domainStatus === null) draft.domainStatus = new DomainStatus();
      if (draft.domainStatus.domainId === emptyGuid) draft.domainStatus.domainId = draft.id;
      if (name === 'establishedDialogue') {
        draft.domainStatus[name] = value ? stage : stage - 2;
      } else {
        draft.domainStatus[name] = value ? stage : stage - 1;
      }
    });

    const index = listCopy.findIndex(obj => obj.id === domain.id);
    setListCopy(
      produce(listCopy, draft => {
        draft[index] = {...newValue};
      })
    );

    const i = itemsToEdit.findIndex(obj => obj?.id === domain.id);
    if (i > -1) {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    } else {
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    }
  };

  const getRowValue = domain => {
    const count = countRowChecks(domain, true);
    return count < 3 ? `${count}/3 steps completed` : 'Ready to go live';
  };

  const editComment = (c) => {
    setOpenModal(true);
    setEditComment(c);
    setCurrentStatus(domain?.domainStatus);
  }

  const sortedComments = domain?.domainStatus?.comments && [...domain?.domainStatus?.comments]?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <Table.Row key={domain.id} className='edit-app-row' limitExpandClick content={
      <div style={{ padding: 'var(--bfs16) var(--bfs24)' }}>
        <Button variant={"outline"} state={!domain?.domainStatus ? "inactive" : "default"} onClick={() => {
          if (!domain?.domainStatus) {
            showFloatingMessage("Need to set and save a status before adding message on old domains.")
          } else {
            setOpenModal(true);
            setEditComment(new DomainComment())
            setCurrentStatus(domain?.domainStatus);
          }
        }}>Add comment</Button>
        {sortedComments?.map(c => {
          return <CommentRow key={c?.id} comment={c} editComment={editComment} commentType={commentTypes.DOMAIN}/>
        })}
      </div>
    }>
      <Table.Cell>{domain?.name} {domain?.registrar && `(${domain?.registrar}) `}{information.primaryId === domain.id && <Badge>Main domain</Badge>}</Table.Cell>
      <Table.Cell className='backoffice-weird-input'>
        <Dropdown
          arrow={false}
          popperOptions={{
            modifiers: [
              {
                name: 'sameWidth',
                enabled: true,
                fn: ({state}) => {
                  state.styles.popper.width = `${state.rects.reference.width}px`;
                },
                phase: 'beforeWrite',
                requires: ['computeStyles'],
                effect: ({state}) => {
                  state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                }
              }
            ]
          }}
          noPadding
          content={
            <>
              <Checkbox
                className='hover-checkbox'
                label='Established dialogue with current administrator'
                checked={domain?.domainStatus?.establishedDialogue === 2}
                onChange={e => onDomainChange('establishedDialogue', e.target.checked, domain, 2)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Scheduled transfer to Intility'
                checked={domain?.domainStatus?.scheduledTransfer > 0}
                onChange={e => onDomainChange('scheduledTransfer', e.target.checked, domain, 1)}
              />
              <Checkbox
                className='hover-checkbox'
                label='Completed (transferred to Intility)'
                checked={domain?.domainStatus?.scheduledTransfer > 1}
                onChange={e => onDomainChange('scheduledTransfer', e.target.checked, domain, 2)}
              />
            </>
          }
        >
          <Input
            {...(countRowChecks(domain) === 10 && {
              icon: faCheck
            })}
            readOnly
            value={getRowValue(domain)}
            label={'test'}
            hideLabel={true}
          />
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )

}

EditDomainsTableRow.propTypes = {
  application: PropTypes.object,
  listCopy: PropTypes.array,
  setListCopy: PropTypes.func,
  itemsToEdit: PropTypes.array,
  setItemsToEdit: PropTypes.func,
  openModal: PropTypes.func,
  setCurrentStatus: PropTypes.func,
  setEditComment: PropTypes.func,
}

export default EditDomainsTableRow

