import React, {useEffect, useState} from 'react';
import {Grid, Message, Button} from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import {internalRoleOptions} from '../NewApplication/NewContent/NewContact';
import {v4 as uuidv4} from 'uuid';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUsersAsync} from '../../../../../redux/company/users/userThunks';
import ContactDetails from './ContactDetails';

const AddNewInternalContact = ({cancelAddContact, addContact}) => {
  const [contact, setContact] = useState(null);
  const [roles, setRoles] = useState([]);
  const [alert, setAlert] = useState(false);
  const {id} = useSelector(state => state.company.data.info);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersAsync(id));
  }, [dispatch]);

  const {userList} = useSelector(state => state.company.data.userPlatform);
  const existingInternalContacts = userList?.filter(u => u.isEnabled === true && (u.firstName || u.lastName));

  const generateOptions = contact => ({
    value: contact.id,
    label: `${contact.firstName || ''} ${contact.lastName || ''}`
  });

  const handleSubmit = () => {
    if (contact) {
      const {firstName, lastName, mobilePhone, primaryEmailAddress} = contact;
      const newContact = {
        id: uuidv4(),
        firstName,
        lastName,
        phoneNumber: mobilePhone,
        email: primaryEmailAddress,
        contactRoles: roles.map(role => ({
          contactRole: role.value
        }))
      };
      addContact(newContact, true);
    } else {
      setAlert(true);
    }
  };

  const handleCancel = () => {
    setContact(null);
    setRoles([]);
    cancelAddContact(true);
  };

  return (
    <div className='add-new-contact-card'>
      <Grid gap={24} cols={1}>
        <div style={{color: 'var(--bfc-base-c-1)', fontSize: '16px', fontWeight: '600'}}>Add new internal contact</div>
        <Message>
          In order to select contacts from your company, you need to add them to the users list first. If you have not
          done this yet you can skip this step and return later.
        </Message>
        <Select
          state={alert ? 'alert' : undefined}
          feedback={alert ? 'Please select a user' : undefined}
          placeholder='Select user'
          label='Internal contact'
          options={existingInternalContacts.map(generateOptions)}
          value={contact ? generateOptions(contact) : null}
          onChange={selectedOption => {
            const selected = existingInternalContacts.find(contact => contact.id === selectedOption.value);
            if (selected) {
              setContact(selected);
              setAlert(false);
            }
          }}
        />
        <Select
          isMulti
          placeholder='Select the contact role(s)'
          label='Roles'
          options={internalRoleOptions}
          value={roles}
          onChange={setRoles}
        />
        <div>Contact details</div>
        <ContactDetails contact={contact} />
        <Grid small={2} style={{display: 'flex', justifyContent: 'space-between', marginTop: '12px'}}>
          <Button pill style={{alignSelf: 'flex-start'}} onClick={handleCancel}>
            Cancel
          </Button>
          <Button pill variant='filled' onClick={handleSubmit}>
            Save Contact
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewInternalContact;
