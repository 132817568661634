import {faUser} from '@fortawesome/pro-regular-svg-icons';
import Information from '../../components/BackOffice/Information/Information';

export default {
  title: 'Information',
  path: 'information/',
  icon: faUser,
  editable: false,
  component: Information,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional users';
  }
};
