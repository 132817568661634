import { Input } from "@intility/bifrost-react";
import AppStepCard from "../AppStepCard";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { preDefinedApps } from "../../ApplicationOptions";
import { FreewareApps, Microsoft365Apps } from "../../Applications";
import AppSearch from "../../AppSearch/AppSearch";

export const StepOne = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, totalSteps, setIsOpen, switchToPage}) => {
  const [suggestedApp, setSuggestedApp] = useState();
  const [selectedApp, setSelectedApp] = useState();

  useEffect(() => {
    if (selectedApp?.name) {
      setAppObject(
        produce(draft => {
          draft.name = selectedApp?.name;
          draft.productId = selectedApp?.id;
        })
      );
    } else if (selectedApp === null) {
      setAppObject(
        produce(draft => {
          draft.name = '';
          draft.productId = null;
        })
      );
    }
  }, [selectedApp])


  const onChange = e => {
    const {name, value} = e.target;
    if (value.length > 3) {
      const suggestion = preDefinedApps.find(s => s.name?.toLowerCase()?.includes(value?.toLowerCase()))
      if (suggestion) {
        setSuggestedApp(suggestion)
      } else {
        setSuggestedApp(null);
      }
    } else {
      setSuggestedApp(null);
    }
    if (name === 'name') {
      setAppObject(
        produce(draft => {
          draft[name] = value;
          draft.productId = null;
        })
      );
    } else {
      setAppObject(
        produce(draft => {
          draft[name] = value;
        })
      );
    }
  };

  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} header='What do you call the application?' totalSteps={totalSteps} stepReady={appObject?.name?.length > 0}>
      <div className='step-one-card'>
        <AppSearch selectedApp={selectedApp} setSelectedApp={setSelectedApp} onChange={onChange} appObject={appObject} value={appObject?.name}/>
        {suggestedApp &&
        <div className='step-one-card-suggestion' onClick={(e) => {
          setIsOpen(false);
          if (suggestedApp.type === 'Freeware') {
            switchToPage(e, FreewareApps);
          } else {
            switchToPage(e, Microsoft365Apps);
          }
        }}>
          <p className='bf-small bf-strong'>Suggestion:</p>
          <span className='bf-p bf-small bf-strong bf-link'><img src={suggestedApp.image} alt='icon'/> {suggestedApp.name} (from {suggestedApp.type})</span>
        </div>
        }
        <Input name='nickname' label='Nickname' optional onChange={onChange} value={appObject?.nickname} description='Does your organization have a nickname for the application?'/>
        <Input name='url' label='URL' description='If you use an internal or external URL to access the application' optional onChange={onChange} value={appObject?.url} />
      </div>
    </AppStepCard>
  );
};

export default StepOne

StepOne.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  newAppType: PropTypes.string,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number,
  switchToPage: PropTypes.func
}
