import './addUsers.scss';
import {
  Badge,
  Breadcrumbs,
  Button,
  Checkbox,
  CheckboxCard,
  FormatDate,
  Icon,
  Label,
  Spinner,
  useFloatingMessage
} from '@intility/bifrost-react';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {faMicrosoft} from '@fortawesome/free-brands-svg-icons';
import {faArrowDownToLine, faArrowUpRight, faSync, faCheckCircle} from '@fortawesome/pro-regular-svg-icons';
import {fetchAdminRelationship} from '../../SystemsAndServices.Microsoft365/AdminAccessSlider/AdminAccessSlider';
import apiFetch from '../../../../../utils/apiFetch';
import {
  addCompanyUserAsync,
  populateGraphUsersAsync,
  uploadCsv as uploadUserCsv
} from '../../../../../redux/company/users/userThunks';
import importTemplate from '../../../../../assets/files/Intility_User_Import_Template.xlsx';
import guideImage from '../../../../../assets/images/docTest.png';
import CsvUploader from '../../../../FileUploader/CsvUploader';
import PropTypes from 'prop-types';
import {getIntilityUserDescriptionOptions, RequiredValue} from '../Options/UserTypeOptions';
import SelectWithFeedback from '../../../../SelectWithFeedback/SelectWithFeedback';
import InputWithFeedback from '../../../../InputWithFeedback/InputWithFeedback';
import LanguageOptions from '../Options/LanguageOptions';
import {CompanyUser} from '../../../../../utils/hub/models';
import produce from 'immer';
import {isStateValid, validateUser, validateUserIntility} from '../../../../../utils/wizard/stateValidations';
import useIntilityPermission from '../../../../../hooks/useIntilityPermission';
import CountryOptions from '../Options/CountryOptions';
import {updateUsersClear} from '../../../../../redux/company/users/usersReducers';
import ErrorModal, {CompanyUserError} from '../ErrorModal/ErrorModal';


const ImportType = {
  EntraId: 'entra',
  Csv: 'csv',
  Manually: 'manually'
}
export const AddUser = ({isComplete, newUser}) => {
  const {id} = useSelector(state => state.company.data.info);
  const [importType, setImportType] = useState(ImportType.EntraId);

  const getContent = () => {
    switch (importType) {
      case ImportType.EntraId:
        return <EntraIdImportContent isComplete={isComplete} newUser={newUser}/>
      case ImportType.Csv:
        return <CsvImportContent isComplete={isComplete} newUser={newUser}/>
      case ImportType.Manually:
        return <ManualImportContent isComplete={isComplete} newUser={newUser}/>
    }
  }

  return (
    <div id='add-users' >
      <div className='add-users-top-bar'>
        <div>
          <Breadcrumbs>
            <Breadcrumbs.Item>
              <Link to={`/${id}`}>Home</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>
              <Link to={'#'} onClick={() => newUser(false)}>Users</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>
              Add user
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <h1>Add user</h1>
          <p>Add new user(s) to Onboard</p>
        </div>
      </div>

      <div className='add-users-content'>

        <div className='add-user-header-container'>
          <div className='add-user-header'>
            <h5>Add users</h5>
          </div>
          <div className='add-user-header-content'>
            <Label>How would you like to add users?</Label>
            <p>Select one</p>
            <div className='bfl-grid'>
              <CheckboxCard type='radio' checked={importType === ImportType.EntraId} onChange={() => {setImportType(ImportType.EntraId)}} label='Import from Entra ID'>Connect your organization to Intility's Cloud Management and import users.</CheckboxCard>
              <CheckboxCard type='radio' checked={importType === ImportType.Csv} onChange={() => {setImportType(ImportType.Csv)}} label='Import from .CSV file'>Use our excel .CSV template, add the users and upload the file to Onboard.</CheckboxCard>
              <CheckboxCard type='radio' checked={importType === ImportType.Manually} onChange={() => {setImportType(ImportType.Manually)}} label='Add manually'>Fill inn users manually by filling in the user details in a form.</CheckboxCard>
            </div>
          </div>
        </div>
        {getContent()}
      </div>
    </div>
  )

}

AddUser.propTypes = {
  isComplete: PropTypes.bool
}

export default AddUser

const EntraIdImportContent = ({isComplete, newUser}) => {
  const {id, tenantId} = useSelector(state => state.company.data.info);
  const {syncing} = useSelector(state => state.company.data.userPlatform.usersStatus);
  const {information} = useSelector(state => state.company.data.userPlatform);
  const [office365Status, setOffice365Status] = useState();
  const [adminConsent, setAdminConsent] = useState(false);
  const dispatch = useDispatch();
  const { showFloatingMessage } = useFloatingMessage()

  useEffect(() => {
    if (!id) return;
    fetchAdminRelationship(id, setOffice365Status)
  }, [id]);

  useEffect(() => {
    if (id) {
      testAdminRelationship()
    }
  }, [id]);
  const testAdminRelationship = () => {
    apiFetch(`/companies/${id}/companyUsers/testAdminConsent`)
      .then(response => {
        if (!response.ok) {
          setAdminConsent(false);
          return false;
        }
        return response.json()
      })
      .then(res => setAdminConsent(res));
  }

  const loginMicrosoftAzureAd = (userTenant) => {
    const loginWindow = window.open(`https://login.microsoftonline.com/${userTenant}/adminconsent?client_id=${process.env.REACT_APP_GRAPH_MICROSOFT_APP_CLIENT_ID}`,'_blank','height=600,width=500');

    const timer = setInterval(function() {
      if(loginWindow.closed) {
        clearInterval(timer);
        testAdminRelationship();
      }
    }, 1000);
  };

  const loginCloudManagement = () => {
    const loginCloudWindow = window.open(office365Status?.inviteUrl,'_blank','height=600,width=600');

    const timer = setInterval(function() {
      if(loginCloudWindow.closed) {
        clearInterval(timer);
        fetchAdminRelationship(id, setOffice365Status);
      }
    }, 1000);
  }

  return (
    <div className="add-user-content">
      <div className="add-user-method">
        <h5>Import from Azure AD</h5>
      </div>
      <div className="add-user-method-description">
        <div className="entra-import-content">
          <div className="step-box">
            <div className="step-box-header">
              <h5>1</h5>
              <h5>Connect organization</h5>
              {office365Status?.status === 'active' && <Badge state='success'><Icon icon={faCheckCircle} /> Connected</Badge>}
            </div>
            <div className="step-box-content">
              <p>
                Connect your organization to Intility's Cloud Management. Note: you will need to have Global
                Administrator Privileges.
              </p>
              <Button state={office365Status?.status === 'active' ? 'inactive' : 'default'}
                      onClick={() => loginCloudManagement()} variant="filled"><Icon icon={faMicrosoft} /> Connect with
                Microsoft 365 admin <Icon icon={faArrowUpRight} /></Button>
            </div>
          </div>
          <div className="step-box">
            <div className="step-box-header">
              <h5>2</h5>
              <h5>Grant access</h5>
              {adminConsent === true && <Badge state='success'><Icon icon={faCheckCircle} /> Connected</Badge>}
            </div>
            <div className="step-box-content">
              <p>
                Grant Onboard access to your Entra ID by consenting. This enables you to import all user in the next
                step. (Consent may need some minutes to register)
              </p>
              <Button state={(adminConsent === true || office365Status?.status !== 'active') ? 'inactive' : 'default'}
                      onClick={() => {
                        if (office365Status?.status === 'active') {
                          loginMicrosoftAzureAd(tenantId);
                        } else {
                          showFloatingMessage('Requires cloud management consent, please complete step 1.');
                        }
                      }} variant="filled"><Icon icon={faMicrosoft} /> Consent with Microsoft 365 admin <Icon
                icon={faArrowUpRight} /></Button>
            </div>
          </div>
          <div className="step-box">
            <div className="step-box-header">
              <h5>3</h5>
              <h5>Synchronize & import users</h5>
            </div>
            <div className="step-box-content">
              <p>
                Automatically import all your users from Entra ID. To ensure Onboard is up to date with your latest
                changes
                in Azure AD, you can always perform a new synchronization.
              </p>
              <div>
                <Button state={adminConsent === false ? 'inactive' : 'default'} disabled={syncing}
                        onClick={() => {
                          if (adminConsent === false) {
                            showFloatingMessage('Missing Entra ID consent, please complete step 2.');
                          } else {
                            dispatch(populateGraphUsersAsync(id));
                          }
                        }} variant="filled"><Icon icon={faSync} /> Synchronize & import users</Button>
                {syncing && <Spinner style={{marginLeft: 'var(--bfs8)'}} size={16} />}
                <span className="bf-em bf-small" style={{color: 'var(--bfc-base-c-2)', marginLeft: 'var(--bfs8)'}}>Last sync: <FormatDate
                  show="datetime"
                  date={new Date(information?.lastAzureSync)} /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-user-method-footer">
        <Button onClick={() => newUser(false)}>Cancel</Button>
      </div>
    </div>
  )
}

const CsvImportContent = ({isComplete, newUser}) => {
  const {userList, usersStatus} = useSelector(state => state.company.data.userPlatform);
  const [userInfoOpen, setUserInfoOpen] = useState(true);
  const [saveFileOpen, setSaveFileOpen] = useState(true);
  const [fileRequirementsOpen, setFileRequirementsOpen] = useState(true);
  const [replace, setReplace] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setErrorOpen(usersStatus.uploadError);
  }, [usersStatus.uploadError]);

  const handleFileUpload = e => {
    const filesAreAttached = e.target.value?.length > 0;
    if (filesAreAttached) {
      dispatch(uploadUserCsv(e.target.value[0], replace));
    }
  };


  return (
    <div className="add-user-content">
      <div className="add-user-method">
        <h5>Import from .CSV file</h5>
      </div>
      <div className="add-user-method-description">
        <div className="entra-import-content">
          <div className="step-box">
            <div className="step-box-header">
              <h5>1</h5>
              <h5>Download template</h5>
            </div>
            <div className="step-box-content">
              <p>In order to upload an existing list of users to Intility Onboard, you need to download our Excel user
                template, fill your user information into the template, and save the file in CSV format.</p>
              <a className="bf-link bf-button-filled bf-button" rel="noopener noreferrer" target="_blank"
                 href={importTemplate}>
                <Icon icon={faArrowDownToLine} /> Download template (.xlsx)
              </a>
            </div>
          </div>
          <div className="step-box">
            <div className="step-box-header">
              <h5>2</h5>
              <h5>Fill in user information</h5>
            </div>
            <div className="step-box-content">
              <p>Fill in user information into the excel-template you downloaded in the previous step.</p>
              <div className="step-box-button-expand">
                <Button.Expand open={userInfoOpen} onClick={() => setUserInfoOpen(!userInfoOpen)}>
                  Required fields
                </Button.Expand>
                {userInfoOpen &&
                  <div className="button-expand-list">
                    <ul>
                      <li>FirstName</li>
                      <li>LastName</li>
                      <li>MobilePhone</li>
                      <li>UserPrincipalName</li>
                    </ul>
                    <p className="bf-small bf-em">All required fields are marked with an asterisk (*) and bold text in
                      the
                      template</p>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="step-box">
            <div className="step-box-header">
              <h5>3</h5>
              <h5>Save your user list</h5>
            </div>
            <div className="step-box-content">
              <p>When you are ready to upload the file to Intility Onboard, you need to save the file in CSV UTF-8
                format.
                To do this, select File > Save As, and select a folder. Then, just below the name of the file, select
                CSV
                UTF-8 (Comma delimited) from the dropdown list, and press Save</p>
              <div className="step-box-button-expand">
                <Button.Expand open={saveFileOpen} onClick={() => setSaveFileOpen(!saveFileOpen)}>
                  How to save the file
                </Button.Expand>
                {saveFileOpen &&
                  <div className="button-expand-list">
                    <ul>
                      <li>Go to “File”-menu in Excel</li>
                      <li>Press “Save As”</li>
                      <li>Select where you want to save the file</li>
                      <li>In the save dialog, open the “Save as type” drop down and select the “CSV UTF-8 (Comma
                        delimited)
                        option in the list.
                      </li>
                      <li>Press “Save” and upload the file to Onboard in the file upload below.</li>
                    </ul>
                    <img className='from-medium' src={guideImage} alt="guide" />
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="step-box">
            <div className="step-box-header">
              <h5>4</h5>
              <h5>Upload file</h5>
            </div>
            <div className="step-box-content">
              <p>Press button below and select your user file.</p>
              <CsvUploader
                multiple={false}
                setReplace={setReplace}
                listLength={userList?.length}
                isDisabled={isComplete}
                filled={true}
                name="Upload file.."
                type="user"
                onChange={handleFileUpload}
                pill={false}
                validContentTypes={['.csv']}
              />
              <div className="step-box-button-expand">
                <Button.Expand open={fileRequirementsOpen}
                               onClick={() => setFileRequirementsOpen(!fileRequirementsOpen)}>
                  File requirements
                </Button.Expand>
                {fileRequirementsOpen &&
                  <div className="button-expand-list">
                    <ul>
                      <li>File type: .CSV UTF-8 (Comma delimited)</li>
                      <li>Max file size. 16mb</li>
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-user-method-footer">
        <Button onClick={() => newUser(false)}>Cancel</Button>
      </div>
      <ErrorModal isOpen={errorOpen} onClose={() => dispatch(updateUsersClear())} type={CompanyUserError} />
    </div>
  )

}

const ManualImportContent = ({isComplete, newUser}) => {
  const {information} = useSelector(state => state.company.data.userPlatform);
  const {locationList} = useSelector(state => state.company.data.locationPlatform);
  const {billingList} = useSelector(state => state.company.data.billingPlatform);
  const [optionalFieldsOpen, setOptionalFieldsOpen] = useState(false);
  const [editable, setEditable] = useState(new CompanyUser());
  const [verify, setVerify] = useState(false);
  const [validation, setValidation] = useState(undefined);
  const [selectableBilling, setSelectableBilling] = useState([]);
  const [selectableLocation, setSelectableLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedBilling, setSelectedBilling] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const [userType, setUserType] = useState();
  const validEmailCharsRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const intilityUser = useIntilityPermission();
  const dispatch = useDispatch();
  const { showFloatingMessage } = useFloatingMessage()

  useEffect(() => {
    if (billingList.length === 1) {
      setSelectedBilling({value: billingList[0].id, label: billingList[0].name, type: 'billingId'});
      setEditable(
        produce(draft => {
          draft.billingId = billingList[0].id;
        })
      );
    }
    setSelectableBilling(
      billingList?.map(billing => ({
        label: billing.name,
        value: billing.id,
        type: 'billingId'
      }))
    );
  }, [billingList]);

  useEffect(() => {
    if (locationList.length === 1) {
      setSelectedLocation({value: locationList[0].id, label: locationList[0].name, type: 'locationId'});
      setEditable(
        produce(draft => {
          draft.locationId = locationList[0].id;
        })
      );
    }
    setSelectableLocation(
      locationList?.map(location => ({
        label: location.name,
        value: location.id,
        type: 'locationId'
      }))
    );
  }, [locationList]);

  useEffect(() => {
    if (verify) {
      let errors;
      if (intilityUser) {
        errors = validateUserIntility(editable);
      } else {
        errors = validateUser(editable);
      }
      setValidation(errors);
    }

  }, [editable]);

  const onChange = e => {
    const {name, value, checked} = e.target;

    if (name === 'accountEnabled') {
      setEditable(
        produce(draft => {
          draft[name] = checked;
        })
      );
    } else {
      setEditable(
        produce(draft => {
          draft[name] = value;
        })
      );
    }

  };

  const updateSelect = item => {
    if (item === null) return;
    setEditable(
      produce(draft => {
        draft[item.type] = item.value;
      })
    );
  };

  const saveUser = (exit) => {
    setVerify(true);
    let errors = validateUser(editable);
    if (intilityUser) {
      errors = validateUserIntility(editable);
    }
    setValidation(errors);
    if (isStateValid(errors)) {
      dispatch(addCompanyUserAsync(editable));
      setVerify(false);
      if (exit) {
        newUser(false);
      } else {
        setEditable(new CompanyUser());
        setValidation(undefined);
        showFloatingMessage('User created', {state: 'success'});
      }
      setSelectedLocation({});
      setSelectedBilling({});
      setSelectedCountry({});
      setSelectedLanguage({});
    }
  };

  return (
    <div className='add-user-content'>
      <div className="add-user-method">
        <h5>Add manually</h5>
      </div>
      <div className='add-user-method-description'>
        <div className='manual-user-import'>
          <div className='manual-user-import-required bfl-grid'>
            <SelectWithFeedback
              isValidated={validation?.intilityUserType}
              maxMenuHeight={200}
              label={'Intility user type'}
              isClearable={false}
              className='users-box-select'
              placeholder={'- Select user type -'}
              options={getIntilityUserDescriptionOptions(information.userTypes)}
              value={userType}
              onChange={item => {
                setUserType({value: item.value, label: item.shortLabel, type: item.type});
                updateSelect({value: item.value, label: item.shortLabel, type: item.type})
              }}
            />
            <InputWithFeedback
              onChange={onChange}
              value={editable.firstName}
              label='First Name'
              name='firstName'
              required={true}
              otherProps={
                validation?.firstName && {
                  state: 'alert',
                  feedback: 'Missing value'
                }
              }
            />
            <div>
              <InputWithFeedback
                onChange={onChange}
                value={editable.lastName}
                label='Last Name'
                name='lastName'
                required={RequiredValue(editable.intilityUserType)}
                otherProps={
                  validation?.lastName && {
                    state: 'alert',
                    feedback: 'Missing value'
                  }
                }
              />
            </div>
            <SelectWithFeedback
              isValidated={validation?.billingId}
              label='Billing company'
              maxMenuHeight={200}
              required={true}
              isClearable={false}
              value={selectedBilling}
              className='users-box-select'
              placeholder={'- Select billing company -'}
              options={selectableBilling}
              onChange={item => {
                updateSelect(item);
                setSelectedBilling(item);
              }}
            />
            <SelectWithFeedback
              isValidated={validation?.language}
              label='Language'
              maxMenuHeight={200}
              required={true}
              isClearable={false}
              value={selectedLanguage}
              className='users-box-select'
              placeholder={'- Select language -'}
              options={LanguageOptions}
              onChange={item => {
                updateSelect(item)
                setSelectedLanguage(item);
              }}
            />
            <div>
              <InputWithFeedback
                onChange={onChange}
                value={editable.primaryEmailAddress}
                name='primaryEmailAddress'
                label='Username (UPN)'
                required={true}
                otherProps={
                  validation?.primaryEmailAddress ? {
                    state: 'alert',
                    feedback: 'Missing value or wrong format'
                  } : !validEmailCharsRegex.test(editable.primaryEmailAddress) && {
                    state: 'alert',
                    feedback: 'Email invalid, please review'
                  }
                }
              />
            </div>
            <div>
              <InputWithFeedback
                onChange={onChange}
                maxLength={20}
                value={editable.mobilePhone}
                name='mobilePhone'
                label='Mobile Phone'
                required={RequiredValue(editable.intilityUserType)}
                otherProps={
                  validation?.mobilePhone && {
                    state: 'alert',
                    feedback: 'Needs to be in format: +4712431243'
                  }
                }
              />
            </div>
            <SelectWithFeedback
              isValidated={validation?.locationId}
              maxMenuHeight={200}
              required={true}
              label={'Location'}
              isClearable={false}
              value={selectedLocation}
              className='users-box-select'
              placeholder={'- Select location -'}
              options={selectableLocation}
              onChange={item => {
                updateSelect(item);
                setSelectedLocation(item);
              }}
            />
          </div>
          <SelectWithFeedback
            isValidated={validation?.country}
            maxMenuHeight={200}
            required={true}
            label={'Country'}
            value={selectedCountry}
            isClearable={false}
            className='users-box-select'
            placeholder={'- Select country -'}
            options={CountryOptions}
            onChange={item => {
              updateSelect(item)
              setSelectedCountry(item)
            }}
          />
          <div className='add-user-button-expand'>
            <Button.Expand open={optionalFieldsOpen} onClick={() => setOptionalFieldsOpen(!optionalFieldsOpen)}>
              Optional fields
            </Button.Expand>
            {optionalFieldsOpen &&
              <div className='manual-user-import-optional bfl-grid'>
                <InputWithFeedback onChange={onChange} value={editable.department} name='department'
                                   label='Department' />
                <InputWithFeedback onChange={onChange} value={editable.title} name='title' label='Title' />
                <InputWithFeedback onChange={onChange} value={editable.displayName} name='displayName'
                                   label='Display name' />
                <InputWithFeedback onChange={onChange} value={editable.oldSam} name='oldSam' label='Old Sam' />
                <InputWithFeedback
                  onChange={onChange}
                  otherProps={
                    !validEmailCharsRegex.test(editable.mail) && {
                      state: 'alert',
                      feedback: 'Email invalid, please review'
                    }
                  }
                  value={editable.mail}
                  name='mail'
                  label='Mail' />
                <InputWithFeedback onChange={onChange} value={editable.employeeId} name='employeeId'
                                   label='Employee Id' />
                <InputWithFeedback onChange={onChange} value={editable.oldSid} name='oldSid' label='Old Sid' />
                <div>
                  <Label style={{marginBottom: 'var(--bfs4)'}}>Entra ID status</Label>
                  <Checkbox style={{marginTop: 'auto'}} button type={"check"} onChange={onChange}
                            checked={editable.accountEnabled} name='accountEnabled' label='Enable account' />
                </div>
                <InputWithFeedback onChange={onChange} value={editable.description} name='description'
                                   label='Description' />
                <InputWithFeedback onChange={onChange} value={editable.comment} name='comment' label='Comment' />
                <InputWithFeedback onChange={onChange} value={editable.physicalOffice} name='physicalOffice'
                                   label='Physical Office' />
                <InputWithFeedback onChange={onChange} value={editable.officePhone} name='officePhone'
                                   label='Office Phone' />
                <InputWithFeedback onChange={onChange} value={editable.streetAddress} name='streetAddress'
                                   label='Street Address' />
                <InputWithFeedback onChange={onChange} value={editable.postalCode} name='postalCode'
                                   label='Postal Code' />
                <InputWithFeedback onChange={onChange} value={editable.city} name='city' label='City' />
                <InputWithFeedback onChange={onChange} value={editable.state} name='state' label='State' />
              </div>
            }
          </div>
        </div>
      </div>
      <div className='add-user-method-footer'>
        <Button onClick={() => newUser(false)}>Cancel</Button>
        <div>
          <Button style={{marginRight: 'var(--bfs12)'}} onClick={() => {
            saveUser();
            //setEditable(new CompanyUser());
            //setVerify(false);
            //setValidation(undefined);
            //showFloatingMessage('User created', {state: 'success'});
          }} variant={'filled'}>Save and create new</Button>
          <Button onClick={() => {
            saveUser(true);
          }} variant={'filled'}>Save and exit</Button>
        </div>
      </div>
    </div>
  )

}

