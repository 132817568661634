import {createAction} from '@reduxjs/toolkit';
import {normalizeComputerSetup, normalizeComputerSetupInformation} from './computerSetupNormalizers';

export const fetchComputerSetupSuccess = createAction(
  'computerSetupFetch/fetchComputerSetupSuccess',
  apiComputerSetup => ({
    payload: normalizeComputerSetup(apiComputerSetup)
  })
);

export const updateComputerSetupSuccess = createAction(
  'computerSetupUpdate/updateComputerSetupSuccess',
  apiDefaultItems => ({
    payload: normalizeComputerSetup(apiDefaultItems)
  })
);

export const updateComputerSetupInformationSuccess = createAction(
  'computerSetupUpdate/updateInformationSuccess',
  information => ({
    payload: normalizeComputerSetupInformation(information)
  })
);
