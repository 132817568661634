import React from 'react';
import './flex.scss';
import PropTypes from 'prop-types';

/**
 * Every child will take up 1 part of the Flex component.
 * Every child will also receive the editing prop.
 * This means every child should be a React component
 */
const Flex = ({children, single, flex, extraClasses, ...props}) => {
  return (
    <div className={`flex${single ? ' single ' : ' '}` + extraClasses} {...props}>
      {flex
        ? React.Children.map(children, (child, i) => React.cloneElement(child, {style: {flex: flex[i] || 0}}))
        : children}
    </div>
  );
};

Flex.propTypes = {
  children: PropTypes.any,
  single: PropTypes.bool,
  flex: PropTypes.array,
  extraClasses: PropTypes.string
};

export default Flex;
