export const fetchBrregCompany = async (organizationNumber) => {
  const orgNr = organizationNumber?.replace(/\s/g, '');

  if (orgNr?.length !== 9) return new Promise(resolve => {
    resolve({
      name: '',
      address: '',
      postalPlace: '',
      zipCode: '',
      country: ''
    })
  });
  return fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgNr}`)
    .then(response => response.json())
    .catch(() => {
      console.error('Could not find company with regnr ' + organizationNumber);
    });
};
