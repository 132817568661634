import {createReducer, createSlice} from '@reduxjs/toolkit';
import {
  fetchDomainListSuccess,
  fetchDomainsSuccess,
  fetchInformationSuccess,
  updateDomainInformationSuccess,
  updateDomainSuccess,
  updatePrimarySuccess
} from './domainActions';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';

const updateOnDomainChangeReducer = getState => {
  return {
    [fetchDomainsSuccess]: (state, action) => getState(action.payload, action.type, state),
    [updateDomainSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};

export const domainListReducer = createReducer(
  [],
  updateOnDomainChangeReducer((payload, type, state) => {
    state = payload.domainPlatform.domainList;
    return state;
  })
);

const updateOnDomainStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchDomainsSuccess]: (state, action) => getState(action.payload, action.type, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, action.type, state)
  };
};

export const domainStateReducer = createReducer(
  0,
  updateOnDomainStateReducer((payload, type, state) => {
    state = payload.domainPlatform.state;
    return state;
  })
);

const updateOnChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchDomainsSuccess]: (state, action) => getState(action.payload, state),
    [updatePrimarySuccess]: (state, action) => getState(action.payload, state),
    [fetchInformationSuccess]: (state, action) => getState(action.payload, state),
    [updateDomainInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const domainInformationReducer = createReducer(
  {
    id: '',
    companyId: '',
    isEnabled: true,
    modifiedAt: '',
    primaryId: '',
    domainCount: 0
  },
  updateOnChangeReducer((payload, state) => {
    state.id = payload.domainPlatform.information.id;
    state.companyId = payload.domainPlatform.information.companyId;
    state.modifiedAt = payload.domainPlatform.information.modifiedAt;
    state.domainCount = payload.domainPlatform.information.domainCount;
    state.primaryId = payload.domainPlatform.information.primaryId;
    state.isEnabled = payload.domainPlatform.information.isEnabled;
    return state;
  })
);

export const domainStatusReducer = createSlice({
  name: 'domainsAction',
  initialState: {
    fetching: false,
    updating: false,
    error: false,
    uploadError: false,
    uploadErrorMessage: '',
    uploadErrorResult: []
  },
  reducers: {
    fetchDomains: state => {
      state.fetching = true;
    },
    fetchDomainsError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateDomains: state => {
      state.updating = true;
    },
    updateDomainsError: state => {
      state.updating = false;
      state.error = true;
    },
    updatePrimaryDomain: state => {
      state.updating = true;
    },
    updatePrimaryDomainError: state => {
      state.updating = false;
      state.error = true;
    },
    uploadDomainsError: (state, action) => {
      state.updating = false;
      state.uploadError = true;
      state.uploadErrorMessage = "Upload error";
      state.uploadErrorResult = action.payload;
    },
    updateDomainsClear: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
      state.uploadError = false;
      state.uploadErrorMessage = '';
      state.uploadErrorResult = [];
    }
  },
  extraReducers: {
    [fetchDomainsSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateDomainSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [fetchInformationSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [fetchDomainListSuccess]: state => {
      state.fetching = false;
      state.error = false;
    },
    [updatePrimarySuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateDomainInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchDomains,
  fetchDomainsError,
  updateDomainsError,
  updateDomains,
  updatePrimaryDomain,
  updatePrimaryDomainError,
  uploadDomainsError,
  updateDomainsClear
} = domainStatusReducer.actions;
