import PropTypes from "prop-types";
import {
  addLocationSystemContactAsync,
  deleteLocationDocumentationAsync,
  deleteLocationSystemContactAsync,
  deleteLocationSystemCustomItemAsync,
  uploadLocationDocumentationAsync
} from "../../../../../../../redux/company/locations/locationThunks";
import SimpleFileUploader from "../../../../../../FileUploader/SimpleFileUploader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationSystemContact, LocationSystemItem } from "../../../../../../../utils/hub/models";
import {
  Button,
  Icon,
  Input,
  Label,
  Spinner,
  useFloatingMessage
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { CreatableSelect } from "@intility/bifrost-react-select";
import produce from "immer";
import '../locationEditDrawer.scss';
import {
  LocationSystemAccordionItemEditable, LocationSystemAccordionItemNew
} from "../../LocationInformation/LocationSystems/LocationSystemAccordionItem";
import { downloadFile } from "../../../../../../../utils/downloadFile";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faBox, faEnvelope, faKey, faPhone, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { emptyGuid } from "../../../../../../../utils/guid";
import { validateContact } from "../../../../../../../utils/wizard/stateValidations";
import CheckUserDeleted from "../../../../../../../utils/hub/checkUserDeleted";
import { DocumentationType } from "../../LocationInformation/LocationInformation";

export const SystemsContent = ({location, isDisabled, itemTypes, page, type, editLocation, editableItems, setEditableItems}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {locationStatus} = useSelector(state => state.company.data.locationPlatform);
  const {userList} = useSelector(state => state.company.data.userPlatform);
  const [contactList, setContactList] = useState([]);
  const [locationItems, setLocationItems] = useState(location?.locationSystemItems || []);
  const [editLocationContactOption, setEditLocationContactOption] = useState(undefined);
  const [editLocationContact, setEditLocationContact] = useState(new LocationSystemContact());
  const [contactResp, setContactResp] = useState([]);
  const [changeOptions, setChangeOptions] = useState(true);
  const [contactRespOptions, setContactRespOptions] = useState([]);
  const dispatch = useDispatch();
  const { showFloatingMessage } = useFloatingMessage();
  const enabledUsers = userList?.filter(u => u.isEnabled === true);
  const files = location?.locationSystemDocumentations?.filter(d => d.systemType === type);

  useEffect(() => {
    if (!changeOptions) return;
    let typeOptions;
    const customItems = locationItems.filter(l => (l.itemType === 1 && l.systemType === type)).map(l => ({ label: l.name, value: l.name, type: type, icon: faBox}))
    const mainOptions = {
        label: 'Main contact',
        options: [{ value: 'Main contact', label: 'Main contact' }]
      };
    if (contactResp.some(c => c.value === 'Main contact')) {
      typeOptions = itemTypes.map(i => ({label: i.name, value: i.name, isDisabled: true }))
      setContactResp([{ value: 'Main contact', label: 'Main contact' }])
    } else {
      typeOptions = itemTypes.map(i => ({label: i.name, value: i.name}))
    }
    typeOptions.push(...customItems);
    setContactRespOptions(
      [mainOptions, {label: 'Responsibilities', options: typeOptions}]
    );
    setChangeOptions(false);
  }, [itemTypes, changeOptions])

  useEffect(() => {
    const contactList = location?.locationSystemContacts?.map(c => ({label: `${c.firstName} ${c.lastName}`, value: c})) || [];
    const localUsers = [...enabledUsers]?.sort((a,b) => a?.firstName > b?.firstName)?.map(u => ({label: `${u.firstName || ''} ${u.lastName || ''}`, value: {
        firstName: u.firstName,
        lastName: u.lastName,
        phoneNumber: u.mobilePhone,
        email: u.primaryEmailAddress
      }}))
    const combo = localUsers?.concat(contactList?.filter((contact, index) => localUsers?.findIndex(t => t.phoneNumber !== contact.phoneNumber && t.email !== contact.email) === index))
    setContactList(combo);
  }, [page]);

  const updateSelect = item => {
    setEditLocationContactOption(item)
    setEditLocationContact(
      produce(draft => {
        draft.firstName = item.value?.firstName;
        draft.lastName = item.value?.lastName;
        draft.email = item.value?.email;
        draft.phoneNumber = item.value?.phoneNumber;
        draft.systemType = type;
      })
    );
  }

  const handleCreate = option => {
    const newOption = {
      value: ({ ...new LocationSystemContact(), firstName: option }),
      label: option
    }
    setContactList(options => [...options, newOption])
    updateSelect(newOption)
  }

  const onContactChange = e => {
    const {name, value} = e.target;
    setEditLocationContact(
      produce(draft => {
        draft[name] = value;
        draft.systemType = type;
      })
    );
  };

  const onContactRespChange = value => {
    setEditLocationContact(
      produce(draft => {
        draft.systemResponsibilities = value?.map(v => v.value)?.join(',');
      })
    );
  };

  const saveContact = () => {
    const validatedUser = validateContact(editLocationContact)
    if (validatedUser.email && validatedUser.phoneNumber) {
      showFloatingMessage('You need at least an email or phone number.');
    } else {
      dispatch(addLocationSystemContactAsync(location.id, editLocationContact));
      setEditLocationContactOption({value: new LocationSystemContact(), label: 'New contact'});
      setEditLocationContact(new LocationSystemContact());
      setContactResp([]);
      setChangeOptions(true);
    }
  }

  const deleteContact = (contactId) => {
    dispatch(deleteLocationSystemContactAsync(location.id, contactId));
  }

  const deleteSystemItem = (item) => {
    const index = locationItems.findIndex(obj => (obj?.name === item?.name && obj?.systemType === item?.systemType));
    let newItemList;
    let editItemList;
    newItemList = produce(locationItems, draft => {
      draft.splice(index, 1);
    });
    const editIndex = editableItems.findIndex(obj => (obj?.name === item?.name && obj?.systemType === item?.systemType));
    editItemList = produce(editableItems, draft => {
      draft.splice(editIndex, 1);
    });
    setLocationItems(newItemList);
    setEditableItems(editItemList);
    editLocation('locationSystemItems', newItemList)
    if (item.id !== emptyGuid) {
      dispatch(deleteLocationSystemCustomItemAsync(location.id, item.id));
    }
  }

  const editSystemItem = (value, name, item) => {
    const index = locationItems.findIndex(obj => (obj?.name === item?.name && obj.systemType === item.systemType));
    let newItemList;
    let editItemList;
    if (index < 0) {
      newItemList = [...locationItems, {...item, [name]: value}];
    } else {
      newItemList = produce(locationItems, draft => {
        draft[index] = {...item, [name]: value};
      });
    }
    const editIndex = editableItems.findIndex(obj => (obj?.name === item?.name && obj?.systemType === item?.systemType));
    if (editIndex < 0) {
      editItemList = [...editableItems, {...item, [name]: value}];
    } else {
      editItemList = produce(editableItems, draft => {
        draft[editIndex] = {...item, [name]: value};
      });
    }

    setLocationItems(newItemList);
    setEditableItems(editItemList);
    editLocation('locationSystemItems', newItemList)
  }

  const addCustomItem = () => {
    const customItem = produce(new LocationSystemItem(), draft => {
      draft.systemType = type;
      draft.itemType = 1;
    })
    const newItemList = [...locationItems, customItem];
    const editItemList = [...editableItems, customItem];
    setLocationItems(newItemList);
    setEditableItems(editItemList);
    editLocation('locationSystemItems', newItemList)
    // setAddCustom(false);
  }

  return (
    <div className='location-systems-drawer'>
      {locationStatus.updating && (
        <Spinner
          overlay
          size={80}
          onOverlayClick={() => console.log(false)}
          label='Loading…'
        />
      )}
      <div className='drawer-file-documentation'>
        <div>
          <p className='bf-strong bf-large'>Relevant documentation</p>
          <SimpleFileUploader
            onChange={e => {
              dispatch(uploadLocationDocumentationAsync(location.id, e.target.value, type));
            }}
            name='Upload'
            small={true}
          />
        </div>
        <p className='bf-em bf-small' style={{color: 'var(--bfc-base-c-2)', margin: '0'}}>max filesize: 50MB</p>
        <div className='drawer-file-container'>
          {files?.length > 0 ?
            files?.map((document, i) => {
              const fileNameRegex = /\/(.*)/i;
              const fileName = document.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'

              return (
                <span key={i + document.id}>
                  <p className='documentation bf-link' onClick={() => downloadFile(document, `/companies/${id}/locations/${location.id}/file/${document.id}`)}>
                    {fileName}
                  </p>
                  <Icon icon={faTrashAlt} onClick={() => {
                    if (!isDisabled) dispatch(deleteLocationDocumentationAsync(location.id, document.id))
                  }} />
                </span>
              );
            })
            :
            <p className='bf-em'>No documents uploaded</p>
          }
        </div>
      </div>
      <div className='drawer-contact-adder'>
        <CreatableSelect maxMenuHeight={200} label='Contact' hideLabel placeholder={'Search / Create contact'}
                options={contactList}
                description={type === DocumentationType.NETWORK ? 'Resource who is responsible for, or can provide insight to, the network units' : 'Resource who is responsible for, or can provide insight to, the property technical systems'}
                onChange={item => updateSelect(item)}
                onCreateOption={option => handleCreate(option)}
                value={editLocationContactOption}/>
        <div className='bfl-grid'>
          <Input onChange={onContactChange} name='firstName' value={editLocationContact?.firstName} label='First name'/>
          <Input onChange={onContactChange} name='lastName' value={editLocationContact?.lastName} label='Last name'/>
        </div>
        <div className='bfl-grid'>
          <Input onChange={onContactChange} name='email' value={editLocationContact?.email} label='E-mail'/>
          <Input onChange={onContactChange} name='phoneNumber' value={editLocationContact?.phoneNumber} label='Phone number'/>
        </div>
        <Select maxMenuHeight={500} label='Responsibilities' options={contactRespOptions} isMulti value={contactResp} onChange={item => {
          setContactResp(item);
          setChangeOptions(true);
          onContactRespChange(item);
        }}/>
        <Button variant={"filled"} onClick={() => saveContact()}>Add contact</Button>
      </div>
      <div className='drawer-contact-list'>
        {location?.locationSystemContacts?.filter(c => c.systemType === type)?.sort((a, b) => {
          return b.systemResponsibilities?.includes('Main contact');
        })?.map(c => {
          return (
            <div className='drawer-contact-list-contact' key={c?.id}>
              <span><CheckUserDeleted userName={c?.firstName + c?.lastName} email={c?.email} phoneNumber={c?.phoneNumber}/><p className='bf-strong'>{c?.firstName} {c?.lastName}</p><p className='bf-em'>{c?.systemResponsibilities?.length > 0 && `(${c?.systemResponsibilities})`}</p><Icon icon={faTimes} onClick={() => deleteContact(c?.id)}/></span>
              <span><Icon icon={faEnvelope}/><a className='bf-link' style={{color: 'var(--bfc-base-c)'}} href={`mailto:${c?.email}`}>{c?.email}</a></span>
              <span><Icon icon={faPhone}/>{c?.phoneNumber}</span>
            </div>
          )
        })}
      </div>
      <div className='drawer-system-items bfl-grid'>
        {itemTypes.map(i => {
          return (
            <LocationSystemAccordionItemEditable key={i?.name} location={location} icon={i.icon} title={i.name} type={type} onChange={editSystemItem}/>
          )
        })}
        <Label>Other systems</Label>
        {locationItems?.filter(i => (i.itemType === 1 && i.systemType === type))?.map((l, i) => {
          return (
            <LocationSystemAccordionItemNew key={i} icon={faBox} onChange={editSystemItem} onDelete={deleteSystemItem} item={l}/>
          )
        })}
        <div style={{marginTop: 'var(--bfs8)'}}>
          <Button onClick={() => addCustomItem()}>Add custom unit</Button>
        </div>
      </div>
    </div>
  )

}

SystemsContent.propTypes = {
  location: PropTypes.object,
  isDisabled: PropTypes.bool,
  itemTypes: PropTypes.array,
  page: PropTypes.string,
  type: PropTypes.number,
  editLocation: PropTypes.func,
  editableItems: PropTypes.array,
  setEditableItems: PropTypes.func
}

export default SystemsContent