import apiFetch from './apiFetch';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const fetchThumbnail = async (floorPlan, locationId, companyId) => {
  const fileNameRegex = /\/(.*)/i;
  const fileName = floorPlan.objectName.match(fileNameRegex)[1]; // objectName looks like 'locationId/filename.pdf', so we want what's after the first '/'
  const relativeUrl = `/companies/${companyId}/locations/${locationId}/floorPlans/${fileName}`;
  const blob = await apiFetch(relativeUrl).then(res => {
    return res.blob()
  });

  if (fileName?.toLowerCase()?.endsWith('.pdf')) {
    return {
      imageUri: `${await toBase64(blob)}`,
      type: 'PDF',
      name: fileName
    };
  } else {
    const image = URL.createObjectURL(blob);
    return {
      imageUri: image,
      type: 'IMAGE',
      name: fileName
    };
  }

};
