import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { fetchCompaniesAsync } from "redux/companiesSlice";
import "./companyOverviewNew.scss";
import BasicPage from "../Hub/Pages/BasicPage";
import {
  Button,
  FormatDate, Grid,
  Icon,
  Input,
  Tabs,
  useBreakpoint
} from "@intility/bifrost-react";
import DatePicker from "@intility/bifrost-react-datepicker";
import OverviewProgress from "../CompanyOverview/OverviewProgress";
import {
  faAngleDown,
  faAngleRight,
  faBuilding,
  faCoins, faDiagramProject, faLock, faPlus,
  faSearch,
  faServer,
  faStar,
  faUser
} from "@fortawesome/pro-regular-svg-icons";
import { faStar as faSolidStar } from "@fortawesome/pro-solid-svg-icons";
import apiFetch from "../../utils/apiFetch";
import useIntilityPermission from "../../hooks/useIntilityPermission";
import { emptyGuid } from "../../utils/guid";
import { animated, useSpring } from "@react-spring/web";
import PropTypes from "prop-types";
import OverviewCard, { MoneyCard } from "./OverviewCard";
import { fetchFinancialDetailsAsync } from "../../redux/company/details/detailThunks";
import { getShortName } from "./FavoriteCard";
import { faCog } from "@fortawesome/pro-duotone-svg-icons";
import ValuesCard from "./ValuesCard";
import { fetchGlobalTasksAsync } from "../../redux/company/onboardTasks/onboardTaskThunks";
import { setView } from "../../redux/viewSlice";


Date.prototype.getWeek = function() {
  const date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
    - 3 + (week1.getDay() + 6) % 7) / 7);
};

export function useMeasure() {
  const ref = useRef();
  const [bounds, set] = useState({left: 0, top: 0, width: 0, height: 0});
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
  useEffect(() => {
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
  }, [ro]);
  return [{ref}, bounds];
}

const compareTime = (a, b) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateA.getFullYear() === 1) return 1;
  if (dateB.getFullYear() === 1) return -1;
  return dateA.getTime() - dateB.getTime();
};

export const months = [
  {month: 0, name: 'January'},
  {month: 1, name: 'February'},
  {month: 2, name: 'March'},
  {month: 3, name: 'April'},
  {month: 4, name: 'May'},
  {month: 5, name: 'June'},
  {month: 6, name: 'July'},
  {month: 7, name: 'August'},
  {month: 8, name: 'September'},
  {month: 9, name: 'October'},
  {month: 10, name: 'November'},
  {month: 11, name: 'December'},
];

export const revenueType = {
  ESTIMATED: 'Estimated',
  ACTUAL: 'Actual'
}

export const activeBtnType = {
  DEFAULT: 'default',
  CURRENT: 'current',
  UPCOMING: 'upcoming',
  FAVORITES: 'favorites',
  ALL: 'all'
}

export const activeListBtnType = {
  ALL: 'all',
  PERSONAL: 'personal',
}

const TableRow = ({ company, getIcon, similarDates, startDate, resetSimilar, activeCompanies, setActiveCompanies, fetchProjectLeader, leaderList, size }) => {
  const [isOpen, setOpen] = useState(false);
  const {financialDetails} = useSelector(state => state);
  const [bind, {height: viewHeight}] = useMeasure();
  const props = useSpring({height: isOpen ? viewHeight : 0});
  const [timelineValues, setTimelineValues] = useState({start: 0, end: 0, goLivePixels: 0, borderRadius: '0', textPlacement: 'initial'})
  const [goLiveDate, setGoLiveDate] = useState(null);
  const [weekendColor, setWeekendColor] = useState('var(--bfc-theme-c-2)');
  const [contentSize, setContentSize] = useState(0);
  const [projectLeader, setProjectLeader] = useState({name: '', photo: ''});
  const [financialPlatform, setFinancialPlatform] = useState();
  const [isActiveCompany, setIsActiveCompany] = useState(false);
  const [monneh, setMonneh] = useState();
  const [revType, setRevType] = useState(revenueType.ESTIMATED);

  useEffect(() => {
    if (company === undefined || company === null) return;
    const date = new Date(company?.detailsPlatform?.goLiveDate);
    similarDates.push(date.getWeek());
    setGoLiveDate(date);
    setContentSize(size * 52);
  }, [company]);

  useEffect(() => {
    setFinancialPlatform(financialDetails.find(f => f.companyId === company.id));
  }, [financialDetails])

  useEffect(() => {
    let estimatedDetails = 0;
    let contractualDetails = 0;
    let actualDetails = 0;

    financialPlatform?.detailList?.forEach(d => {
      if (d?.name.startsWith("Predicted")) {
        estimatedDetails += +d.stringValue;
      } else if (d?.name.startsWith("Actual")) {
        actualDetails += +d.stringValue;
      } else if (d?.name.startsWith("Estimated")) {
        contractualDetails += +d.stringValue;
      }
    });

    if (actualDetails > 0) {
      setMonneh(actualDetails.toLocaleString());
      setRevType(revenueType.ACTUAL);
    } else if (estimatedDetails > 0) {
      setMonneh(estimatedDetails.toLocaleString());
      setRevType(revenueType.ESTIMATED);
    } else {
      setMonneh(contractualDetails.toLocaleString());
      setRevType(revenueType.ESTIMATED);
    }

  }, [financialPlatform])

  useEffect(() => {
    setProjectLeader(fetchProjectLeader(company));
  }, [leaderList])

  useEffect(() => {
    if (goLiveDate === null) return;
    getTimeline(startDate, company?.createdAt, goLiveDate, size, contentSize);
  }, [goLiveDate, startDate])

  useEffect(() => {
    const newSize = size * 52;
    setContentSize(newSize);
    getTimeline(startDate, company?.createdAt, goLiveDate, size, newSize);
  }, [size])

  useEffect(() => {
    if (goLiveDate === null) return;
    const collidingDates = similarDates.filter(d => {
      return d === goLiveDate.getWeek()
    }).length;

    if (collidingDates > 1) {
      setWeekendColor('var(--bfc-warning)');
    }

  }, [similarDates, goLiveDate, resetSimilar])


  const getTimeline = (initialDate, createdAt, goLiveDate, size, contentSize) => {
    const initial = new Date(initialDate);
    const created = new Date(createdAt);
    const goLive = new Date(goLiveDate);

    let startWeeksFromInitial = created.getWeek();
    if (created < initial) startWeeksFromInitial = 0;
    let startPixel = startWeeksFromInitial * size;

    const goLiveWeek = goLive.getWeek();

    const weeks = goLiveWeek - startWeeksFromInitial;
    const maxWidth = contentSize - startPixel;

    let endPixel = 0;
    let goLivePixel = 0;

    if (goLive.getFullYear() > initial.getFullYear()) {
      endPixel = maxWidth;
    } else if (goLive.getFullYear() < initial.getFullYear()) {
      startPixel = 0;
    } else {
      endPixel = (weeks * size);
      goLivePixel = (size - 4);
    }

    if (created.getFullYear() > initial.getFullYear()) {
      startPixel = 0;
      endPixel = 0;
    }

    let startRadius = 0;
    let endRadius = 0;
    if (startPixel > 0) startRadius = 3;
    if ((startPixel + endPixel) < contentSize) endRadius = 3;
    const radius = `${startRadius}px ${endRadius}px ${endRadius}px ${startRadius}px`;
    if (endPixel > 0) endPixel = endPixel - 2;

    let textPlacement = 'absolute';
    if (contentSize - (startPixel + endPixel) > 800 || startPixel === 0 || (contentSize - startPixel) > 800) {
      textPlacement = 'initial';
    }

    setTimelineValues({start: startPixel, end: endPixel, goLivePixels: goLivePixel, borderRadius: radius, textPlacement: textPlacement});
  }

  const toggleActiveCompany = (company) => {
    const companyInList = activeCompanies.find(c => c.id === company.id)
    if (companyInList !== undefined) {
      setActiveCompanies(current => current.filter(c => c.id !== company.id));
    } else {
      setActiveCompanies([...activeCompanies, company]);
    }
  }

  useEffect(() => {
    setIsActiveCompany(activeCompanies.some(c => c.id === company.id));
  }, [activeCompanies])

  return (
    <div key={company.id} className='table-row'>
      <div className='table-row-content' >
        <div className='overview-header' {...bind}>
          <button onClick={() => setOpen(!isOpen)}>
            <Icon icon={isOpen ? faAngleDown : faAngleRight} />
          </button>
          <div className='overview-header-fav'>{getIcon(company.id)}</div>
          <div className='overview-header-title'>
            <span>
              <Link className='bf-title-link' style={{color: 'var(--bfc-base-c-1)'}} to={'/' + company.id + '/'}>
                {getShortName(company.name.toUpperCase(), 22)} ({company?.detailsPlatform?.companyCode || 'N/A'})
              </Link>
              {company?.detailsPlatform?.parentCompany && <p className='bf-small bf-em' style={{margin: 0}}>Existing customer: {company?.detailsPlatform?.parentCompany}</p>}
            </span>
            <div className='backoffice-cog'>
              <Link style={{margin: '0', color: 'var(--bfc-base-c-2)'}} to={`/details/${company.id}/information/`} className='bf-p bf-link'><Icon icon={faCog}/></Link>
            </div>
          </div>
          <div className='from-medium'>

          </div>
        </div>
        <div className={isActiveCompany ? 'company-progress active-company from-large' : 'company-progress from-large'} onClick={() => toggleActiveCompany(company)}>
          <div style={{marginLeft: `${timelineValues.start > contentSize ? 0 : timelineValues.start}px`, position: 'relative'}}>
            <div style={{width: `${timelineValues.end}px`, maxWidth: `${contentSize}px`, borderRadius: `${timelineValues.borderRadius}`,
              backgroundImage: `-moz-linear-gradient(right, ${weekendColor} ${timelineValues.goLivePixels}px, var(--bfc-theme-c-1) ${timelineValues.goLivePixels}px)`,
              backgroundImage: `-webkit-linear-gradient(right, ${weekendColor} ${timelineValues.goLivePixels}px, var(--bfc-theme-c-1) ${timelineValues.goLivePixels}px)`
            }}/>
            {goLiveDate?.getFullYear() >= startDate?.getFullYear() &&
              <div className='text-content' style={{position: timelineValues.textPlacement}}>
                <p className='bf-small bf-strong'>{company.name} ({company?.detailsPlatform?.companyCode || 'N/A'})</p>
                <div className='overview-row-image'>
                  {projectLeader?.photo ? (
                    <img src={`data:image/png;base64,${projectLeader?.photo}`} alt={projectLeader?.name} />
                  ) : (
                    <Icon icon={faUser} />
                  )}
                  <p className='bf-small' style={{color: 'var(--bfc-base-c-2)'}}>{projectLeader?.name}</p>
                </div>
                <p className='bf-small' style={{color: 'var(--bfc-base-c-2)'}}>Go live: {goLiveDate !== null ? <FormatDate date={new Date(goLiveDate)} show={'date'} /> : 'Undefined'} {company?.detailsPlatform?.goLiveConfirmed && <Icon icon={faLock}/>}</p>
                <p className={revType === revenueType.ESTIMATED ? 'bf-small bf-em' : "bf-small"} style={{color: 'var(--bfc-base-c-2)'}}><Icon icon={faCoins}/> {monneh} kr/mo</p>
                <p className='bf-small' style={{color: 'var(--bfc-base-c-2)'}}><Icon icon={faBuilding}/> {company?.locationPlatform?.locationCount}</p>
                <p className='bf-small' style={{color: 'var(--bfc-base-c-2)'}}><Icon icon={faUser}/> {company?.userPlatform?.userCount}</p>
                <p className='bf-small' style={{color: 'var(--bfc-base-c-2)'}}><Icon icon={faServer}/> {company?.applicationPlatform?.applicationCount}</p>
              </div>
            }
          </div>
        </div>
      </div>
      <animated.div className={isActiveCompany ? 'overview-dropdown active-company' : 'overview-dropdown'} style={{...props, minWidth: `${contentSize}px`}}>
        <div className='dropdown-percentage'>
          <OverviewProgress company={company} size='small'/>
          <span>
            <Link to={`/details/${company.id}/information/`} className='bf-p bf-link'>View in Backoffice</Link><Icon icon={faAngleRight}/>
          </span>
        </div>
        <div className='dropdown-empty'/>
      </animated.div>
    </div>
  )
}

TableRow.propTyes = {
  company: PropTypes.object,
  getIcon: PropTypes.func,
  similarDates: PropTypes.array,
  setSimilarDates: PropTypes.func,
  startDate: PropTypes.object,
  fetchProjectLeader: PropTypes.func,
  setActiveCompanies: PropTypes.func,
  leaderList: PropTypes.array,
  activeCompanies: PropTypes.array,
  size: PropTypes.number,
  dateItems: PropTypes.number,
  months: PropTypes.array,
  isCollapsed: PropTypes.bool,
}

export const fetchFavorites = (setValue, setFetching) => {
  apiFetch(`/favorites`)
    .then(response => {
      if (!response.ok) {
        setValue([]);
        setFetching(false);
        return [];
      }
      return response.json()
    })
    .then(users => {
      setValue(users);
      setFetching(false);
    });
};

export const fetchProjectLead = setValue => {
  apiFetch(`/companies/${emptyGuid}/projectTeams/projectLead`)
    .then(response => {
      if (!response.ok) {
        setValue([]);
        return [];
      }
      return response.json()
    })
    .then(team => setValue(team));
};

export const addFavorite = (companyId, setFavorites, setFetching) => {
  apiFetch(`/companies/${companyId}/favorites`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(() => {
      fetchFavorites(setFavorites, setFetching)
    })
    .catch(error => console.log(error));
};

export const removeFavorite = (companyId, setFavorites, setFetching) => {
  apiFetch(`/companies/${companyId}/favorites`, {
    method: 'DELETE'
  })
    .then(response => {
      if (response.ok) return response;
      throw response
    })
    .then(() => {
      fetchFavorites(setFavorites, setFetching)
    })
    .catch(error => console.log(error));
};

const CompanyOverviewNew = () => {
  const {data, fetching} = useSelector(state => state.companies);
  const {globalTaskList} = useSelector(state => state.company.data.taskPlatform);
  const {usersProjects} = useSelector(state => state.auth.user);
  const {financialDetails} = useSelector(state => state);
  const {update} = useSelector(state => state.company);
  const [fetchingFavorites, setFetchingFavorites] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [similarDates, setSimilarDates] = useState([]);
  const [elements, setElements] = useState([{ elements: [] }]);
  const [startYear, setStartYear] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [endYear, setEndYear] = useState(new Date(new Date().getFullYear(), 11, 31));
  const [resetSimilar, setResetSimilar] = useState(false);
  const [managers, setManagers] = useState([]);
  const [activeWeekStart, setActiveWeekStart] = useState(-1);
  const [activeWeekEnd, setActiveWeekEnd] = useState(-1);
  const [estimatedRev, setEstimatedRev] = useState('0');
  const [actualRev, setActualRev] = useState('0');
  const [companySearch, setCompanySearch] = useState('');
  const [activeCompanies, setActiveCompanies] = useState([]);
  const [size, setSize] = useState(30);
  const [activeBtn, setActiveBtn] = useState(activeBtnType.DEFAULT)
  const [activeListBtn, setActiveListBtn] = useState(activeListBtnType.ALL)
  const intilityUser = useIntilityPermission();
  const currentDate = new Date(Date.now());
  const sideBarSize = 390;
  const dispatch = useDispatch();
  const fromXxl = useBreakpoint("xxl", null);
  const fromMedium = useBreakpoint("medium", null);
  const toXxl = useBreakpoint("xl", "xxl");
  const toXl = useBreakpoint("large", "xl");

  const baseHeight = window.innerHeight - 300 - 50 || document.documentElement.clientHeight - 300 - 50 ||
    document.body.clientHeight - 300 - 50;

  useEffect(() => {
    if (fromXxl) {
      setSize(40)
    }
    if (toXxl){
      setSize(30)
    }
    if (toXl) {
      setSize(26)
    }
  }, [toXl, toXxl, fromXxl])

  useEffect(() => {
    dispatch(fetchFinancialDetailsAsync(emptyGuid));
  }, [intilityUser]);

  useEffect(() => {
    const value = getFilteredCompanies(activeBtn, companySearch) * 58;
    drawWeeks(value);
    setResetSimilar(true);
    changeActiveBtn(window.localStorage.activeCompanyBar || activeBtnType.DEFAULT)
  }, [])

  useEffect(() => {
    const value = getFilteredCompanies(activeBtn, companySearch) * 58;
    drawWeeks(value);
    setEndYear(new Date(startYear?.getFullYear(), 11, 31));
  }, [activeWeekStart, activeWeekEnd, startYear, size])

  useEffect(() => {
    if (resetSimilar) {
      setResetSimilar(false);
    }
  }, [resetSimilar])

  useEffect(() => {
    if (data.length > 1 && intilityUser) {
      fetchFavorites(setFavorites, setFetchingFavorites);
      fetchProjectLead(setManagers);
      dispatch(fetchGlobalTasksAsync())
    }
  }, [data, intilityUser]);

  useEffect(() => {
    if (data?.length !== 0) return;
    dispatch(fetchCompaniesAsync());
  }, [dispatch, data.length]);

  useEffect(() => {
    if (activeCompanies.length > 0) {
      setActiveWeekStart(-1);
      setActiveWeekEnd(-1);
    }
  }, [activeCompanies])

  useEffect(() => {
    if (update.fetching === true) {
      dispatch(fetchCompaniesAsync());
    }
  }, [update, dispatch]);

  const financialInRange = financialDetails && financialDetails.filter(c => {
    if (activeListBtn === activeListBtnType.PERSONAL) return usersProjects?.find(f => f === c.companyId);
    else return c;
  }).filter(d => {
    const goLiveDate = new Date(d?.goLiveDate);
    const createdDate = new Date(d.createdAt);
    const goLiveWeek = goLiveDate?.getWeek();
    if (activeBtn === activeBtnType.ALL) {
      return d;
    } else if (activeBtn === activeBtnType.UPCOMING) {
      if (goLiveDate > currentDate || goLiveDate?.getFullYear() === 1) return d;
    } else if (activeBtn === activeBtnType.DEFAULT) {
      return goLiveDate?.getFullYear() === startYear?.getFullYear() || (goLiveDate > endYear && createdDate < endYear);
    } else if (activeBtn === activeBtnType.CURRENT) {
      return goLiveDate?.getFullYear() === startYear?.getFullYear();
    } else if (activeBtn === activeBtnType.FAVORITES) {
      return favorites.find(f => f === d.companyId);
    } else {
      if (goLiveDate?.getFullYear() !== startYear?.getFullYear()) return false;
      if (activeWeekStart < 0 || activeWeekEnd < 0) return d;
      return goLiveWeek >= activeWeekStart && goLiveWeek <= activeWeekEnd
    }
  });

  useEffect(() => {
    let estimatedRev = 0;
    let actualRev = 0;

    financialInRange?.filter(f => {
        if (activeCompanies.length === 0) return true;
        return activeCompanies.some(a => a.id === f.companyId);
      })?.filter(r => r.detailList.length > 0)?.forEach(f => {
      let estimatedDetails = 0;
      let contractualDetails = 0;
      let actualDetails = 0;

      f?.detailList?.forEach(d => {
        if (d?.name.startsWith("Predicted")) {
          estimatedDetails += +d.stringValue;
        } else if (d?.name.startsWith("Actual")) {
          actualDetails += +d.stringValue;
        } else if (d?.name.startsWith("Estimated")) {
          contractualDetails += +d.stringValue;
        }
      });

      actualRev += actualDetails;

      if (actualDetails > 0) {
        estimatedRev += actualDetails;
      } else if (estimatedDetails > 0) {
        estimatedRev += estimatedDetails;
      } else {
        estimatedRev += contractualDetails;
      }

    });

    setEstimatedRev(estimatedRev.toLocaleString());
    setActualRev(actualRev.toLocaleString());
  }, [financialInRange, activeCompanies])


  if (fetching) return <p>Fetching companies...</p>;
  // redirect to hub with the one company
  if (data?.length === 1) return <Redirect to={`/${data[0].id}/`} />;

  const getFilteredCompanies = (type, search) => {
    return data.filter(c => c.name?.toLowerCase()?.startsWith(search))?.filter(c => {
      const liveDate = new Date(c.detailsPlatform?.goLiveDate);
      const createdDate = new Date(c.createdAt);
      if (type === activeBtnType.DEFAULT) return liveDate?.getFullYear() === startYear?.getFullYear() || (liveDate > endYear && createdDate < endYear);
      if (type === activeBtnType.CURRENT) return liveDate?.getFullYear() === startYear?.getFullYear();
      if (type === activeBtnType.UPCOMING) return liveDate?.getFullYear() === 1 || (liveDate > currentDate && liveDate?.getFullYear() >= startYear?.getFullYear());
      if (type === activeBtnType.FAVORITES)  return favorites.find(f => f === c.id);
      else return c;
    })?.length;
  };

  const changeActiveBtn = (buttonValue) => {
    setActiveBtn(buttonValue);
    localStorage.setItem('activeCompanyBar', buttonValue);
    setSimilarDates([]);
    const value = getFilteredCompanies(buttonValue, companySearch) * 58;
    drawWeeks(value);
  }

  const changeActiveBtnList = (buttonValue) => {
    setActiveListBtn(buttonValue);
    setSimilarDates([]);
    const value = getFilteredCompanies(buttonValue, companySearch) * 58;
    drawWeeks(value);
  }

  const getFavoriteIcon = id => {
    if (favorites.find(f => f === id))
      return (
        <Icon
          style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}
          icon={faSolidStar}
          onClick={() => removeFavorite(id, setFavorites, setFetchingFavorites)}
        />
      );
    else
      return (
        <Icon
          style={{color: 'var(--bfc-base-c-theme)', cursor: 'pointer'}}
          icon={faStar}
          onClick={() => addFavorite(id, setFavorites, setFetchingFavorites)}
        />
      );
  };

  function getWeeksInRange(month, year) {
    const weeks = [];
    const date = new Date(year, month, 1);
    const end = new Date(year, 11, 31);
    date.setHours(0,0,0,0);
    end.setHours(0,0,0,0);
    while (date.getMonth() === month && date.getDate() <= end.getDate()) {
      const week = date.getWeek();
      if (month === 0 && week === 52) {
        date.setDate(date.getDate() + 1);
        continue;
      }
      if (weeks.find(w => w === week) === undefined && month === 11) {
        weeks.push(week);
      } else if (weeks.find(w => w === week) === undefined && date.getDay() === 0) weeks.push(week);
      date.setDate(date.getDate() + 1);
    }
    return weeks;
  }

  const fetchProjectLeader = (company) => {
    const leader = managers?.find(m => m.companyId === company.id)?.member;
    return ({name: leader ? `${leader?.givenName} ${leader?.surname}` : 'No project manager', photo: leader?.profilePhoto})
  };

  const setActive = (week) => {
    setActiveCompanies([]);
    if (activeWeekStart < 0) setActiveWeekStart(week);
    if (week === activeWeekStart) {
      setActiveWeekStart(-1);
      setActiveWeekEnd(-1);
    } else if (activeWeekStart > 0 && activeWeekEnd > 0 && week < activeWeekStart) {
      setActiveWeekEnd(activeWeekStart);
      setActiveWeekStart(week);
    } else if (activeWeekEnd < 0 && week > activeWeekStart) {
      setActiveWeekEnd(week);
    } else if (activeWeekEnd >= 0) setActiveWeekEnd(week);
  }

  const getWeekColor = (week, size, currentWeek, year, currentDate, height, i) => {
    const weekColor = data.filter(c => {
      const goLiveDate = new Date(c?.detailsPlatform?.goLiveDate);
      if (goLiveDate.getFullYear() === 1 || year !== goLiveDate.getFullYear()) return false;
      return goLiveDate?.getWeek() === week
    });

    const isCurrentWeek = currentWeek === week && year === currentDate.getFullYear()
    const activeWeek = week >= activeWeekStart && week <= activeWeekEnd;
    const newHeight = (height + 24) > (baseHeight - 34) ? (baseHeight - 34) : (height + 24);
    const spanSize = (size - 4);
    if (weekColor.length === 0) return (
      <span key={i} onClick={() => setActive(week)} style={{width: `${spanSize}px`}} className={isCurrentWeek ? 'bf-p outline' : 'bf-p'}>
        {week}
        <div style={activeWeek ? {height: `${newHeight}px`, width: spanSize} : { height: 0, width: spanSize }} className={activeWeek ? 'marker-active' : 'marker'}/>
      </span>
    );
    if (weekColor.length < 2) return (
      <span key={i} onClick={() => setActive(week)} style={{backgroundColor: 'var(--bfc-theme-fade)', color: 'var(--bfc-theme)', width: `${spanSize}px`}} className={isCurrentWeek ? 'bf-p outline' : 'bf-p'}>
        {week}
        <div style={activeWeek ? {height: `${newHeight}px`, width: spanSize} : { height: 0, width: spanSize }} className={activeWeek ? 'marker-active' : 'marker'}/>
      </span>
    );
    if (weekColor.length >= 2) return (
      <span key={i} onClick={() => setActive(week)} style={{backgroundColor: 'var(--bfc-warning)', width: `${spanSize}px`}} className={isCurrentWeek ? 'bf-p outline' : 'bf-p'}>
        {week}
        <div style={activeWeek ? {height: `${newHeight}px`, width: spanSize} : { height: 0, width: spanSize }} className={activeWeek ? 'marker-active' : 'marker'}/>
      </span>
    );

  }

  const drawWeeks = (height) => {
    const now = new Date(Date.now());
    const currentWeek = now.getWeek();
    let elements = 0;

    setElements(months.map((m, i) =>
      ({ name: <span className='bf-p'>{m.name} <span className='bf-em bf-p from-xxl' style={{fontWeight: '400', fontSize: '14px'}}>(weeks)</span></span>, year: startYear?.getFullYear(), elements: getWeeksInRange(m.month, startYear?.getFullYear()).map((d, i) => {
          elements++;
          return getWeekColor(d, size, currentWeek, startYear.getFullYear(), currentDate, height, i)
        })})
    ));
    setResetSimilar(true);
  }

  const goLiveDatesInRange = data && data?.filter(c => {
    if (activeListBtn === activeListBtnType.PERSONAL) return usersProjects?.find(f => f === c.id);
    else return c;
  }).filter(f => {
    if (activeCompanies.length === 0) return true;
    return activeCompanies.some(a => a.id === f.id);
  }).filter(d => {
    const goLiveDate = new Date(d.detailsPlatform?.goLiveDate);
    const goLiveWeek = goLiveDate?.getWeek();
    const createdDate = new Date(d.createdAt);
    if (activeBtn === activeBtnType.ALL) {
      return d;
    } else if (activeBtn === activeBtnType.UPCOMING) {
      if (goLiveDate > currentDate || goLiveDate?.getFullYear() === 1) return d;
    } else if (activeBtn === activeBtnType.DEFAULT) {
      return goLiveDate?.getFullYear() === startYear?.getFullYear() || (goLiveDate > endYear && createdDate < endYear);
    } else if (activeBtn === activeBtnType.CURRENT) {
      return goLiveDate?.getFullYear() === startYear?.getFullYear();
    } else if (activeBtn === activeBtnType.FAVORITES) {
      return favorites.find(f => f === d.id);
    } else {
      if (goLiveDate?.getFullYear() !== startYear?.getFullYear()) return;
      if (activeWeekStart < 0 || activeWeekEnd < 0) return d;
      return goLiveWeek >= activeWeekStart && goLiveWeek <= activeWeekEnd;
    }
  });

  const applicationsFiltered = goLiveDatesInRange && goLiveDatesInRange?.map(c => c?.applicationPlatform?.applicationCount)?.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const locationsFiltered = goLiveDatesInRange && goLiveDatesInRange?.map(c => c?.locationPlatform?.locationCount)?.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const usersFiltered = goLiveDatesInRange && goLiveDatesInRange?.map(c => c?.userPlatform?.userCount)?.reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const filteredCompanies = data.filter(c => c?.name?.toLowerCase()?.includes(companySearch?.toLowerCase()))?.filter(c => {
    if (activeListBtn === activeListBtnType.PERSONAL) return usersProjects?.find(f => f === c.id);
    else return c;
  }).filter(c => {
    const liveDate = new Date(c.detailsPlatform?.goLiveDate);
    const createdDate = new Date(c.createdAt);
    if (activeBtn === activeBtnType.DEFAULT) return liveDate?.getFullYear() === startYear?.getFullYear() || (liveDate > endYear && createdDate < endYear);
    if (activeBtn === activeBtnType.CURRENT) return liveDate?.getFullYear() === startYear?.getFullYear();
    if (activeBtn === activeBtnType.UPCOMING) return liveDate > currentDate && liveDate?.getFullYear() >= startYear?.getFullYear() || liveDate?.getFullYear() === 1;
    if (activeBtn === activeBtnType.FAVORITES) return favorites.find(f => f === c.id);
    else return c;
  }).sort((a, b) => {
    return compareTime(a?.detailsPlatform?.goLiveDate, b?.detailsPlatform?.goLiveDate);
  });

  return (
    <BasicPage id='company-overview-new'>
      <div className='content-main'>
        <div className='card-bar' style={{maxWidth: `${(size * 52) + sideBarSize}px`}}>
          {fromMedium ?
            <Grid className='overview-cards' cols={6} medium={10} large={18} xl={20} gap={12}>
              <Grid.Span medium={7} large={6} xl={8} ><MoneyCard header='Finance' icon={faCoins} actual={actualRev} estimated={estimatedRev}/></Grid.Span>
              <Grid.Span medium={2} large={3} xl={2} ><OverviewCard header='Projects' icon={faDiagramProject} value={goLiveDatesInRange?.length}/></Grid.Span>
              <Grid.Span medium={2} large={3} xl={2} ><OverviewCard header='Applications' icon={faServer} value={applicationsFiltered}/></Grid.Span>
              <Grid.Span medium={2} large={3} xl={2} ><OverviewCard header='Locations' icon={faBuilding} value={locationsFiltered}/></Grid.Span>
              <Grid.Span medium={2} large={3} xl={2} ><OverviewCard header='Users' icon={faUser} value={usersFiltered}/></Grid.Span>
            </Grid>
          :
            <ValuesCard
              actualIncome={actualRev}
              esticatedIncome={estimatedRev}
              projects={goLiveDatesInRange?.length}
              applications={applicationsFiltered}
              locations={locationsFiltered}
              users={usersFiltered}
            />
          }

        </div>
        <div className='search-bar'>
          <div className='button-group-section'>
            <Input label='Search'
                   hideLabel
                   iconButton
                   rightIcon
                   value={companySearch}
                   onChange={e => {
                     setCompanySearch(e.target.value);
                     const value = getFilteredCompanies(activeBtn, e.target.value) * 58;
                     drawWeeks(value);
                   }}
                   icon={faSearch}
                   placeholder='Example: Intility AS'
                   clearable
                   variant='outline'
            />
            <Button.Group>
              <Button active={activeBtn === activeBtnType.DEFAULT} onClick={() => {
                changeActiveBtn(activeBtnType.DEFAULT)
              }}>Default</Button>
              <Button active={activeBtn === activeBtnType.CURRENT} onClick={() => {
                changeActiveBtn(activeBtnType.CURRENT)
              }}>Current year</Button>
              <Button active={activeBtn === activeBtnType.ALL} onClick={() => {
                changeActiveBtn(activeBtnType.ALL)
              }}>All projects</Button>
              <Button active={activeBtn === activeBtnType.UPCOMING} onClick={() => {
                changeActiveBtn(activeBtnType.UPCOMING)
              }}>Upcoming projects</Button>
              <Button active={activeBtn === activeBtnType.FAVORITES} onClick={() => {
                changeActiveBtn(activeBtnType.FAVORITES)
              }} icon={faStar}>Favorites</Button>
            </Button.Group>
          </div>
          <div className='date-button-section'>
            <div className='date-picker-row'>
              <DatePicker
                label='Year Select'
                hideLabel
                selected={startYear}
                onChange={d => {
                  setStartYear(d);
                  setSimilarDates([]);
                }}
                placeholderText='- Select year -'
                showYearPicker
              />
            </div>
            <Button variant={"filled"} icon={faPlus} onClick={() => dispatch(setView('Wizard'))} rightIcon>Create new project</Button>
          </div>
        </div>
        <div className='overview-table bf-scrollbar-small' style={{maxWidth: `${(size * 52) + sideBarSize}px`, maxHeight: `${baseHeight}px`}}>
        <Tabs className='overview-table-tabs'>
          <Tabs.Item noPadding content={<div/>} active={activeListBtn === activeListBtnType.ALL} onClick={() => {
            changeActiveBtnList(activeListBtnType.ALL)
          }}>All projects</Tabs.Item>
          <Tabs.Item noPadding content={<div/>} active={activeListBtn === activeListBtnType.PERSONAL} onClick={() => {
            changeActiveBtnList(activeListBtnType.PERSONAL)
          }}>My projects</Tabs.Item>
        </Tabs>
          <div className='table-header from-large' style={{minWidth: `${(size * 52) + sideBarSize}px`}}>
            <div className='table-header-customer'>
              <p className='bf-strong'>
                Customer
              </p>
            </div>
            <div className='table-header-days'>
              {elements.map((e, i) => {
                return <div className='table-header-month' key={i}>
                  <p className='bf-strong' style={{margin: 'var(--bfs8) 0 var(--bfs4) var(--bfs4)', color: 'var(--bfc-base-c-theme)'}}>{e.name}</p>
                  <div>
                    {e?.elements.map(e => e)}
                  </div>
                </div>
              })}
            </div>
          </div>
          <div className='table-body' style={{minWidth: `${(size * 52) + sideBarSize}px`}}>
            {filteredCompanies && filteredCompanies.map(company =>
              <TableRow key={company.id}
                        company={company}
                        getIcon={getFavoriteIcon}
                        similarDates={similarDates}
                        startDate={startYear}
                        resetSimilar={resetSimilar}
                        fetchProjectLeader={fetchProjectLeader}
                        leaderList={managers}
                        activeCompanies={activeCompanies}
                        setActiveCompanies={setActiveCompanies}
                        size={size}/>
            )}
          </div>
        </div>
      </div>
    </BasicPage>
  );
};

export default CompanyOverviewNew;
