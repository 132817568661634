import {addListItem, deleteListItem, deleteListItems, editListItem, editListItems} from '../../../utils/hub/normalize';

export const editIamsUser = (iamsUser, iamsUserList) => {
  const newList = editListItem(iamsUserList, iamsUser);

  return {
    iamsUserPlatform: {
      iamsUserList: newList
    }
  };
};

export const addNewUser = (user, userList) => {
  const newList = addListItem(userList, user);
  return {
    iamsUserPlatform: {
      iamsUserList: newList,
      iamsUser: user
    }
  };
};

export const addNewWorkplaceDevice = (device, deviceList) => {
  const newList = addListItem(deviceList, device);
  return {
    iamsUserPlatform: {
      workplaceDevices: newList
    }
  };
};

export const deleteWorkplaceDevice = (deviceId, deviceList) => {
  const newList = deleteListItem(deviceList, deviceId);

  return {
    iamsUserPlatform: {
      workplaceDevices: newList
    }
  };
};

export const normalizeWorkplaceDevices = deviceList => {
  return {
    iamsUserPlatform: {
      workplaceDevices: deviceList
    }
  };
};

export const editMultipleIamsUsers = (currentList, newList) => {
  const updatedList = editListItems(currentList, newList);

  return {
    iamsUserPlatform: {
      iamsUserList: updatedList
    }
  };
};

export const deleteMultipleIamsUsers = (currentList, newList) => {
  const updatedList = deleteListItems(currentList, newList);

  return {
    iamsUserPlatform: {
      iamsUserList: updatedList
    }
  };
};

export const normalizeIamsUsers = iamsUserPlatform => {
  return {
    iamsUserPlatform: {
      iamsUserList: iamsUserPlatform.iamsUserList,
      information: iamsUserPlatform,
      state: iamsUserPlatform.state
    }
  };
};

export const normalizeIamsUsersInformation = iamsUserPlatform => {
  return {
    iamsUserPlatform: {
      information: iamsUserPlatform,
      state: iamsUserPlatform.state
    }
  };
};

export const normalizeIamsUsersList = iamsUserPlatform => {
  return {
    iamsUserPlatform: {
      iamsUserList: iamsUserPlatform.iamsUserList
    }
  };
};
