import { Button, Checkbox, Message } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import AppStepCard from "../AppStepCard";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { integrationOptions } from "../../NewApplication/NewContent/NewIntegration";
import devLog from "../../../../../../utils/devLog";
import { useSelector } from "react-redux";
import { ApplicationIntegration } from "../../../../../../utils/hub/models";
import produce from "immer";
import { NewStepIntegration } from "../NewStepIntegration";
import { ApplicationType } from "../../ApplicationOptions";

export const integrationRelation = {
  PARENT: 'parent',
  CHILD: 'child'
};

export const StepSix = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, totalSteps, setIsOpen}) => {
  const {applicationList} = useSelector(state => state.company.data.applicationPlatform);
  const [integration, setIntegration] = useState(new ApplicationIntegration());
  const [applicationOption, setApplicationOption] = useState(null);
  const [integrationType, setIntegrationType] = useState(null);
  const [notRelevant, setNotRelevant] = useState(appObject.integrationStatus === 2);
  const applicationOptions = applicationList
    ?.filter(a => a.isEnabled)
    ?.filter(a => {
      if (appObject?.applicationRelations?.length === 0) return true;
      return !appObject?.applicationRelations?.some(i => i?.relationId === a.id);
    })
    .map(u => {
      return {value: u.id, label: `${u.name}`};
    });

  const onNotRelevant = (e) => {
    const { checked } = e.target;
    setAppObject(
      produce(draft => {
        draft.applicationRelations = [];
        draft.integrationStatus = checked ? 2 : 0;
      })
    );
    setNotRelevant(checked);
  }

  const onSave = () => {
    devLog('Adding!', integration);
    devLog('App!', appObject);
    setAppObject(
      produce(draft => {
        draft?.applicationRelations?.length > 0 ?
          draft.applicationRelations = [...draft?.applicationRelations, integration] :
          draft.applicationRelations = [integration]
      })
    );
    resetIntegration();
  };

  const onIntegrationChange = e => {
    const {value} = e;
    setIntegrationType(e);
    setIntegration(
      produce(draft => {
        draft.applicationRelationType = value;
      })
    );
  };

  const onApplicationChange = e => {
    const {value} = e;
    setApplicationOption(e);
    setIntegration(
      produce(draft => {
        draft.relationId = value;
      })
    );
  };

  const resetIntegration = () => {
    setIntegration(new ApplicationIntegration());
    setIntegrationType(null);
    setApplicationOption(null);
  };

  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} stepReady={true} progress={progress} setProgress={setProgress} header={`Is your ${newAppType !== ApplicationType.SaaSApp ? 'application' : 'SaaS'} integrated with other applications?`} totalSteps={totalSteps}>
      <Checkbox label='Not relevant for this application' className='step-not-relevant-check' checked={notRelevant} onChange={(e) => onNotRelevant(e)}/>
      <Message header='How to complete this step'>Connect your applications and describe data flows. If the integrated app is not populated in your portfolio yet, you can return later.</Message>
      <div className='step-six-card'>
        <div className='bfl-grid step-six-select-row'>
          <Select
            options={applicationOptions}
            value={applicationOption}
            onChange={onApplicationChange}
            label='Integrated application'
            description='Choose from applications in you portfolio'
            isDisabled={notRelevant}
          />
          <Select
            options={integrationOptions}
            value={integrationType}
            onChange={onIntegrationChange}
            label='Data flow'
            description='In what way is this integrated?'
            isDisabled={notRelevant}
          />
        </div>
        <div>
          <Button disabled={integrationType === null || applicationOption === null} onClick={() => onSave()}>Add</Button>
        </div>
      </div>
      <div>
        <NewStepIntegration
          integrations={appObject?.applicationRelations}
          appObject={appObject}
          setAppObject={setAppObject}
        />
      </div>
    </AppStepCard>
  );
};

export default StepSix

StepSix.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  newAppType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number
}