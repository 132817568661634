import {faServer} from '@fortawesome/pro-regular-svg-icons';
import DomainDetails from '../../components/BackOffice/DomainDetails/DomainDetails';

export default {
  title: 'Domains',
  path: 'domains/',
  icon: faServer,
  editable: false,
  component: DomainDetails,
  getStatus: () => {
    return '';
  },
  getStatusText: (status, company) => {
    return 'Additional details';
  }
};
