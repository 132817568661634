import produce from 'immer';
import {apiFetch, apiFetchIdCollection} from 'utils/apiFetch';
import {
  fetchApplications,
  fetchApplicationsError,
  updateApplications,
  updateApplicationsError
} from '../applicationReducers';
import {
  addInternalContactSuccess,
  addVendorContactSuccess,
  deleteInternalContactSuccess,
  deleteVendorContactSuccess,
  fetchApplicationsSuccess,
  updateInternalContactSuccess,
  updateVendorContactSuccess
} from '../applicationActions';
import {emptyGuid} from '../../../../utils/guid';
import {contactType} from '../../../../components/Hub/Pages/SystemsAndServices.Applications/ApplicationTypes';

export const fetchApplicationContactAsync = (companyId, applicationId) => (dispatch, getState) => {
  const {fetching} = getState().company.data.applicationPlatform.applicationStatus;
  if (fetching) return null;
  dispatch(fetchApplications());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/applications/${applicationId}/vendorContacts`,
    method: 'GET'
  }).then(company => dispatch(fetchApplicationsSuccess(company)))
    .catch(error => dispatch(fetchApplicationsError(error)));
};

export const updateApplicationContactAsync = (contact, contactRoles, type) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {information, applicationList} = getState().company.data.applicationPlatform;

  const updatedContact = produce(contact, draft => {
    draft.contactRoles = contactRoles.map(r => {
      return {[type + 'ContactId']: contact.id, contactRole: r.value};
    });
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/${contact.applicationId}/${type}Contacts/${contact.id}`,
    method: 'PATCH',
    body: updatedContact
  }).then((contact) => {
      if (type === contactType.VENDOR) {
        dispatch(updateVendorContactSuccess(contact.applicationId, contact, applicationList));
      } else {
        dispatch(updateInternalContactSuccess(contact.applicationId, contact, applicationList));
      }
    })
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const addApplicationContactAsync = (applicationId, contact, contactRoles, type) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {information, applicationList} = getState().company.data.applicationPlatform;

  const newContact = produce(contact, draft => {
    draft.applicationId = applicationId;
    draft.contactRoles = contactRoles.map(r => {
      return {[type + 'ContactId']: emptyGuid, contactRole: r.value};
    });
  });

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/${applicationId}/${type}Contacts`,
    method: 'POST',
    body: newContact
  })
    .then((contact) => {
      if (type === contactType.VENDOR) {
        dispatch(addVendorContactSuccess(applicationId, contact, applicationList));
      } else {
        dispatch(addInternalContactSuccess(applicationId, contact, applicationList));
      }
    })
    .catch(error => dispatch(updateApplicationsError(error)));
};

export const deleteApplicationContactAsync = (contact, type) => (dispatch, getState) => {
  const {updating} = getState().company.data.applicationPlatform.applicationStatus;
  if (updating) return null;
  dispatch(updateApplications());

  const {information, applicationList} = getState().company.data.applicationPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/applications/${contact.applicationId}/${type}Contacts/${contact.id}`,
    method: 'DELETE'
  })
    .then(() => {
      if (type === contactType.VENDOR) {
        dispatch(deleteVendorContactSuccess(contact.applicationId, contact.id, applicationList));
      } else {
        dispatch(deleteInternalContactSuccess(contact.applicationId, contact.id, applicationList));
      }
    })
    .catch(error => dispatch(updateApplicationsError(error)));
};
