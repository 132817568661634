import {createAction} from '@reduxjs/toolkit';
import {
  addMemberRoles,
  addProjectGlobalTeam,
  deleteProjectGlobalTeam,
  editProjectGlobalTeam,
  normalizeMemberRoles,
  normalizeProjectGlobalTeams
} from './projectGlobalTeamsNormalizers';

export const fetchProjectGlobalTeamsSuccess = createAction(
  'projectGlobalTeamsAction/fetchProjectGlobalTeamsSuccess',
  apiProjectGlobalTeams => ({
    payload: normalizeProjectGlobalTeams(apiProjectGlobalTeams)
  })
);

export const addProjectGlobalTeamSuccess = createAction('projectGlobalTeamsAction/addProjectGlobalTeamSuccess', (team, teamList) => ({
  payload: addProjectGlobalTeam(teamList, team)
}));

export const deleteProjectGlobalTeamSuccess = createAction(
  'projectGlobalTeamsAction/deleteProjectGlobalTeamSuccess',
  (teamId, teamList) => ({
    payload: deleteProjectGlobalTeam(teamList, teamId)
  })
);

export const updateProjectGlobalTeamSuccess = createAction(
  'projectGlobalTeamsAction/updateProjectGlobalTeamSuccess',
  (team, teamList) => ({
    payload: editProjectGlobalTeam(teamList, team)
  })
);

export const fetchMemberRolesSuccess = createAction('projectGlobalTeamsAction/fetchMemberRolesSuccess', apiProjectGlobalTeams => ({
  payload: normalizeMemberRoles(apiProjectGlobalTeams)
}));

export const addMemberRoleSuccess = createAction('projectGlobalTeamsAction/updateMemberRoleSuccess', (role, roleList) => ({
  payload: addMemberRoles(roleList, role)
}));
