import EditInformation from '../../components/BackOffice/EditComponents/EditInformation';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';

export default {
  title: 'Information',
  path: 'information/edit',
  icon: faMoneyBill,
  editable: true,
  component: EditInformation
};
