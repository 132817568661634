import {faNetworkWired} from '@fortawesome/pro-solid-svg-icons';

export default {
  weight: 5,
  text: 'All domain addresses registered',
  icon: faNetworkWired,
  isEnabled: data => {
    return data.domainPlatform?.information?.isEnabled;
  },
  isEnabledOverview: data => {
    return data.domainPlatform?.isEnabled;
  },
  getStatusText: data => {
    return data.domainPlatform?.information?.modifiedAt;
  }
};
