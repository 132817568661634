import { Button, Input, Modal } from "@intility/bifrost-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renameCompanyAsync } from "../../redux/company/companyThunks";
import './backoffice.scss';

const RenameCompanyModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const { id, name } = useSelector(state => state.company.data.info);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setNewName(name);
    }
  }, [name, isOpen])

  const closeModal = () => {
    setIsOpen(false);
    setNewName('');
  }

  const renameCompany = (newName) => {
    const result = confirm('Are you sure you wish to rename company?');
    if (result) {
      dispatch(renameCompanyAsync(id, newName));
      closeModal();
    }
  };

  return (
    <div className='container-dropdown-content'>
      <p style={{margin: '0', padding: '7px 0'}} onClick={() => setIsOpen(true)}>
        Rename company
      </p>
      <Modal isOpen={isOpen} header={'Rename company'} onRequestClose={() => setIsOpen(false)}>
        <div style={{minHeight: '150px', minWidth: '300px', justifyContent: 'space-between'}}>
          <Input label={"Company name"} value={newName} onChange={e => setNewName(e.target.value)}/>
          <div className='modal-button-bar'>
            <Button variant={'outline'} onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button
              variant={'filled'}
              disabled={newName === name || newName.length === 0}
              onClick={() =>  renameCompany(newName)}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )



}

export default RenameCompanyModal
