import React, {useEffect, useState} from 'react';
import {Button, FieldGroup, Icon, Input} from '@intility/bifrost-react';
import './editComponent.scss';
import {useDispatch, useSelector} from 'react-redux';
import {fetchBillingAsync} from '../../../redux/company/billing/billingThunks';
import {departmentType, Detail, detailType} from '../../../utils/hub/models';
import produce from 'immer';
import {multiPutAsync} from '../../../redux/company/details/detailThunks';
import {faUndo} from '@fortawesome/pro-regular-svg-icons';

export const EditFinance = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {billingList, information} = useSelector(state => state.company.data.billingPlatform);
  const {detailList} = useSelector(state => state.company.data.detailsPlatform);
  const [vismaCompany, setVismaCompany] = useState(new Detail());
  const [predictedRev, setPredictedRev] = useState(new Detail());
  const [estimatedPIAS, setEstimatedPIAS] = useState(new Detail());
  const [estimatedComlines, setEstimatedComlines] = useState(new Detail());
  const [estimatedLicenses, setEstimatedLicenses] = useState(new Detail());
  const [estimatedNAAS, setEstimatedNAAS] = useState(new Detail());
  const [actualPIAS, setActualPIAS] = useState(new Detail());
  const [actualComlines, setActualComlines] = useState(new Detail());
  const [actualLicenses, setActualLicenses] = useState(new Detail());
  const [actualNAAS, setActualNAAS] = useState(new Detail());
  const [establishmentCost, setEstablishmentCost] = useState(new Detail());
  const [mainBilling, setMainBilling] = useState('');
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(fetchBillingAsync(id));
  }, [id]);

  useEffect(() => {
    const billing = billingList?.find(i => i.id === information?.mainId);
    setMainBilling(billing);
  }, [billingList, information]);

  useEffect(() => {
    resetValues();
  }, [detailList]);

  const onChange = (value, name, setItem, currentItem, type) => {
    const newValue = produce(currentItem, draft => {
      draft.name = name;
      draft[type] = value;
      draft.valueType = type;
      draft.department = departmentType.FINANCE;
    });

    const i = itemsToEdit.findIndex(i => i.name === name);
    if (i > -1)
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft[i] = {...newValue};
        })
      );
    else
      setItemsToEdit(
        produce(itemsToEdit, draft => {
          draft.push(newValue);
        })
      );
    setItem(newValue);
  };

  const saveDetails = () => {
    if (itemsToEdit.length > 0) {
      dispatch(multiPutAsync(itemsToEdit));
    }
    setItemsToEdit([]);
  };

  const resetValues = () => {
    setVismaCompany(getDetail('VismaCompany'));
    setEstimatedPIAS(getDetail('EstimatedPIAS'));
    setEstimatedComlines(getDetail('EstimatedComlines'));
    setEstimatedLicenses(getDetail('EstimatedLicenses'));
    setEstimatedNAAS(getDetail('EstimatedRevenue'));
    setActualPIAS(getDetail('ActualPIAS'));
    setActualComlines(getDetail('ActualComlines'));
    setActualLicenses(getDetail('ActualLicenses'));
    setActualNAAS(getDetail('ActualRevenue'));
    setEstablishmentCost(getDetail('EstablishmentCost'));
    setPredictedRev(getDetail('PredictedRev'));
    setItemsToEdit([]);
  };

  const getMonney = new Intl.NumberFormat('no', {
    style: 'currency',
    currency: 'NOK'
  });

  const getDetail = name => {
    return detailList?.filter(d => d.department === departmentType.FINANCE)?.find(i => i.name === name) || new Detail();
  };

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Finance</h3>
        <div className='backoffice-button-group'>
          {itemsToEdit.length > 0 && (
            <span onClick={() => resetValues()} className='icon-text bf-link'>
              <Icon icon={faUndo} />
              <span> Undo changes</span>
            </span>
          )}
          <Button variant={'filled'} disabled={itemsToEdit.length === 0} onClick={() => saveDetails()}>
            Save
          </Button>
        </div>
      </div>
      <div className='bfl-autocol'>
        <Input label='Billing company' disabled={true} value={mainBilling?.name || ''} />
        <Input
          label='Visma Company'
          value={vismaCompany?.stringValue}
          onChange={e => onChange(e.target.value, 'VismaCompany', setVismaCompany, vismaCompany, detailType.STRING)}
        />
        <FieldGroup label='Predicted revenue'>
          <FieldGroup.Item>Kr</FieldGroup.Item>
          <Input
            label='Predicted revenue'
            hideLabel
            value={predictedRev?.stringValue}
            onChange={e => onChange(e.target.value, 'PredictedRev', setPredictedRev, predictedRev, detailType.STRING)}
          />
        </FieldGroup>
        <div style={{border: '1px solid var(--bfc-base-c-dimmed)', padding: 'var(--bfs12)'}}>
          <h3 style={{marginTop: '0'}}>Contractual revenue</h3>
          <p>Total {getMonney.format(+estimatedPIAS?.stringValue + +estimatedComlines?.stringValue + +estimatedLicenses?.stringValue + +estimatedNAAS?.stringValue)}</p>
          <FieldGroup label='Platform Infrastructure & Services'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Platform Infrastructure & Services'
              hideLabel
              value={estimatedPIAS?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'EstimatedPIAS', setEstimatedPIAS, estimatedPIAS, detailType.STRING)
              }
            />
          </FieldGroup>
          <FieldGroup label='Comlines'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Comlines'
              hideLabel
              value={estimatedComlines?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'EstimatedComlines', setEstimatedComlines, estimatedComlines, detailType.STRING)
              }
            />
          </FieldGroup>
          <FieldGroup label='Licenses'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Licenses'
              hideLabel
              value={estimatedLicenses?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'EstimatedLicenses', setEstimatedLicenses, estimatedLicenses, detailType.STRING)
              }
            />
          </FieldGroup>
          <FieldGroup label='Network-as-a-Service'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Network-as-a-Service'
              hideLabel
              value={estimatedNAAS?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'EstimatedRevenue', setEstimatedNAAS, estimatedNAAS, detailType.STRING)
              }
            />
          </FieldGroup>
        </div>
        <div style={{border: '1px solid var(--bfc-base-c-dimmed)', padding: 'var(--bfs12)'}}>
          <h3 style={{marginTop: '0'}}>Actual revenue</h3>
          <p>Total {getMonney.format(+actualPIAS?.stringValue + +actualComlines?.stringValue + +actualLicenses?.stringValue + +actualNAAS?.stringValue)}</p>
          <FieldGroup label='Platform Infrastructure & Services'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Platform Infrastructure & Services'
              hideLabel
              value={actualPIAS?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'ActualPIAS', setActualPIAS, actualPIAS, detailType.STRING)
              }
            />
          </FieldGroup>
          <FieldGroup label='Comlines'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Comlines'
              hideLabel
              value={actualComlines?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'ActualComlines', setActualComlines, actualComlines, detailType.STRING)
              }
            />
          </FieldGroup>
          <FieldGroup label='Licenses'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Licenses'
              hideLabel
              value={actualLicenses?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'ActualLicenses', setActualLicenses, actualLicenses, detailType.STRING)
              }
            />
          </FieldGroup>
          <FieldGroup label='Network-as-a-Service'>
            <FieldGroup.Item>Kr</FieldGroup.Item>
            <Input
              label='Network-as-a-Service'
              hideLabel
              value={actualNAAS?.stringValue}
              onChange={e =>
                onChange(e.target.value, 'ActualRevenue', setActualNAAS, actualNAAS, detailType.STRING)
              }
            />
          </FieldGroup>
        </div>
        <FieldGroup label='Establishment Cost'>
          <FieldGroup.Item>Kr</FieldGroup.Item>
          <Input
            label='Establishment Cost'
            hideLabel
            value={establishmentCost?.stringValue}
            onChange={e =>
              onChange(e.target.value, 'EstablishmentCost', setEstablishmentCost, establishmentCost, detailType.STRING)
            }
          />
        </FieldGroup>
      </div>
    </div>
  );
};

export default EditFinance;
