import {addListItem, deleteListItem, deleteListItems, editListItem, editListItems} from '../../../utils/hub/normalize';

export const editPrinter = (printer, printerList) => {
  const newList = editListItem(printerList, printer);

  return {
    printerPlatform: {
      printerList: newList
    }
  };
};

export const addNewPrinter = (printer, printerList) => {
  const newList = addListItem(printerList, printer);
  return {
    printerPlatform: {
      printerList: newList,
      printer: printer
    }
  };
};

export const deletePrinter = (printerId, printerList) => {
  const newList = deleteListItem(printerList, printerId);

  return {
    printerPlatform: {
      printerList: newList
    }
  };
};



export const editMultiplePrinters = (currentList, newList) => {
  const updatedList = editListItems(currentList, newList);

  return {
    printerPlatform: {
      printerList: updatedList
    }
  };
};

export const deleteMultiplePrinters = (currentList, newList) => {
  const updatedList = deleteListItems(currentList, newList);

  return {
    printerPlatform: {
      printerList: updatedList
    }
  };
};

export const normalizePrinters = printerPlatform => {
  return {
    printerPlatform: {
      printerList: printerPlatform.printerList,
      information: printerPlatform,
      state: printerPlatform.state
    }
  };
};

export const normalizePrintersInformation = printerPlatform => {
  return {
    printerPlatform: {
      information: printerPlatform,
      state: printerPlatform.state
    }
  };
};

export const normalizePrintersList = printerPlatform => {
  return {
    printerPlatform: {
      printerList: printerPlatform.printerList
    }
  };
};
