import './projectManagementOffice.scss';
import {
  Accordion,
  Badge, Button,
  Checkbox,
  FormatDate,
  Icon,
  Input,
  Label,
  Tabs, Tooltip
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { faCalendarAlt, faList, faSearch, faUser, faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import useIntilityPermission from "../../hooks/useIntilityPermission";
import apiFetch from "../../utils/apiFetch";
import { emptyGuid } from "../../utils/guid";
import {
  fetchGlobalRolesAsync,
  fetchProjectGlobalTeamsAsync
} from "../../redux/company/projectTeams/projectGlobalTeamsThunks";
import { Redirect } from "react-router-dom";
import produce from "immer";
import {
  fetchResourceTeamsAsync,
  multiPutTeamRoleComplexityAsync
} from "../../redux/company/projectTeams/projectTeamsThunks";


const sortOptionsProjects = [
  {value: 'nameAsc', label: 'Project name asc'},
  {value: 'nameDesc', label: 'Project name desc'},
  {value: 'dateAsc', label: 'Go live asc'},
  {value: 'dateDesc', label: 'Go live desc'}
];

const sortOptionsResources = [
  {value: 'nameAsc', label: 'User name asc'},
  {value: 'nameDesc', label: 'User name desc'}
];

const sortOptionsGoLive = [
  {value: 'dateAsc', label: 'Go live asc'},
  {value: 'dateDesc', label: 'Go live desc'}
];

const activeTab = {
  PROJECT: 0,
  RESOURCES: 1,
  GOLIVE: 2
}

export const ProjectManagementOffice = () => {
  const {projectGlobalTeamStatus} = useSelector(state => state.company.data.projectGlobalTeams);
  const {user} = useSelector(state => state.auth);
  const {resourceTeams} = useSelector(state => state);
  const intilityUser = useIntilityPermission();
  const [members, setMembers] = useState([]);
  const [editRoleComplexity, setEditRoleComplexity] = useState([]);
  const [search, setSearch] = useState('');
  const [hideImages, setHideImages] = useState(false);
  const [hideComplexity, setHideComplexity] = useState(false);
  const [editComplexity, setEditComplexity] = useState(false);
  const [resourceGroups, setResourceGroups] = useState([]);
  const [resourceGroupOptions, setResourceGroupsOptions] = useState([]);
  const [resourceRoles, setResourceRoles] = useState([]);
  const [resourceRoleOptions, setResourceRoleOptions] = useState([]);
  const [sorting, setSorting] = useState({value: 'dateAsc', label: 'Go live asc'});
  const [active, setActive] = useState(activeTab.PROJECT);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;
    dispatch(fetchProjectGlobalTeamsAsync());
    dispatch(fetchGlobalRolesAsync());
  }, [user, dispatch]);

  useEffect(() => {
    const newTeams = resourceTeams.flatMap(l => l.teams);
    const distinctTeams = [...new Map(newTeams.map(item => [item.name, item])).values()]?.map(t => ({value: t, label: t.name}));
    setResourceGroupsOptions(distinctTeams);
  }, [resourceTeams]);

  useEffect(() => {
    const newTeams = resourceTeams?.flatMap(l => l.teams);
    const newRoles = newTeams?.filter(t => {
      return resourceGroups.find(r => r.label === t.name)
    })?.flatMap(r => r?.teamRoles);

    const distinctRoles = [...new Map(newRoles?.map(item => [item.name, item])).values()]?.map(t => ({value: t, label: t.name}))

    setResourceRoleOptions(distinctRoles)
  }, [resourceGroups.length]);

  const resetSearch = (active) => {
    setSorting(active === activeTab.RESOURCES ? {value: 'nameAsc', label: 'User name asc'} : {value: 'dateAsc', label: 'Go live asc'});
    setActive(active)
  }

  useEffect(() => {
    dispatch(fetchResourceTeamsAsync())
    fetchProjectTeamMembers();

    setResourceRoles(window.localStorage?.resourceRoles ? JSON.parse(window.localStorage?.resourceRoles) : [])
    setResourceGroups(window.localStorage?.resourceGroups ? JSON.parse(window.localStorage?.resourceGroups) : [])
  }, []);

  const fetchProjectTeamMembers = () => {
    apiFetch(`/companies/${emptyGuid}/projectTeams/allMembers`)
      .then(response => {
        if (!response.ok) {
          setMembers([]);
          return [];
        }
        return response.json()
      })
      .then(res => {
        setMembers(res);
      })
  };

  const getManagerSortedTeamRoles = (team) => {
    return team?.teamRoles?.toSorted((a, b) => {
      if (a?.id === team?.teamManager) {
        return -1;
      } else if (b?.id === team?.teamManager) {
        return 1;
      }
      return 0;
    });
  }

  const getColorTheme = (i) => {
    switch (i) {
      case 0: return {color: 'var(--bfc-chill)', contrast: 'var(--bfc-chill-c)'}
      case 1: return {color: 'var(--bfc-base-c-theme)', contrast: 'var(--bfc-theme-c-1)'}
      case 2: return {color: 'var(--bfc-base-c-attn)', contrast: 'var(--bfc-attn-c)'}
      case 3: return {color: 'var(--bfc-base-c-warning)', contrast: 'var(--bfc-warning-fade)'}
      case 4: return {color: 'var(--bfc-base-c-success)', contrast: 'var(--bfc-success-fade)'}
      case 5: return {color: 'var(--bfc-chill)', contrast: 'var(--bfc-chill-c)'}
      case 6: return {color: 'var(--bfc-base-c-theme)', contrast: 'var(--bfc-theme-c-1)'}
      case 7: return {color: 'var(--bfc-base-c-attn)', contrast: 'var(--bfc-attn-c)'}
      case 8: return {color: 'var(--bfc-base-c-warning)', contrast: 'var(--bfc-warning-fade)'}
      case 9: return {color: 'var(--bfc-base-c-success)', contrast: 'var(--bfc-success-fade)'}
      default: return {color: 'var(--bfc-chill)', contrast: 'var(--bfc-chill-c)'}
    }
  }

  const setComplexity = (role, value) => {
    const newValue = produce(role, draft => {
      draft.complexity = value;
    });

    const index = editRoleComplexity.findIndex((obj => obj.id === role.id));

    if (index > -1) {
      setEditRoleComplexity(
          produce(editRoleComplexity, draft => {
            draft[index] = {...newValue};
          })
      );
    } else {
      setEditRoleComplexity(
          produce(editRoleComplexity, draft => {
            draft.push(newValue);
          })
      );
    }
  };

  const saveComplexityChanges = () => {
    dispatch(multiPutTeamRoleComplexityAsync(editRoleComplexity))
    setEditComplexity(false);
  }

  const tabTopBar = (tab) => {
    const options = tab === activeTab.PROJECT ? sortOptionsProjects : tab === activeTab.RESOURCES ? sortOptionsResources : sortOptionsGoLive

    return (
      <div className='pmo-tab-content-top-bar'>
        <Input placeholder='Search' label='Search' icon={faSearch} iconButton={true} rightIcon={true} variant='outline' onIconClick={(e) => setSearch(e.target.value)} onChange={(e) => setSearch(e.target.value)} hideLabel={true}/>
        <Select isMulti value={resourceGroups} placeholder={'Resource groups'} label='Filter groups' options={resourceGroupOptions} onChange={e => {
          if (e.length < resourceGroups.length) {
            setResourceRoles([]);
            localStorage.setItem(`resourceRoles`, JSON.stringify([]));
          }
          setResourceGroups(e);
          localStorage.setItem(`resourceGroups`, JSON.stringify(e));
        }} hideLabel={true}/>
        <Select isMulti value={resourceRoles} placeholder={'Resource roles'} label='Filter roles' options={resourceRoleOptions} onChange={e => {
          setResourceRoles(e);
          localStorage.setItem(`resourceRoles`, JSON.stringify(e));
        }} hideLabel={true}/>
        <Checkbox label='Hide images' checked={hideImages} onChange={(e) => setHideImages(e.target.checked)}/>
        <Checkbox label='Hide complexity' checked={hideComplexity} onChange={(e) => setHideComplexity(e.target.checked)}/>
        <div style={{alignSelf: 'flex-end', display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 0}}>
          <Label style={{marginRight: 'var(--bfs12)'}}>Sort by</Label>
          <Select placeholder='Sorting' options={options} onChange={e => setSorting(e)} value={sorting} label='Sort by' hideLabel={true}/>
        </div>
      </div>
    )
  }

  const teamUser = (role, colors, i) => {
    const complexity = editRoleComplexity.find(i => i.id === role.id);

    return (
      <div className='pmo-team-user' key={i}>
        {!hideImages &&
          (role?.member?.profilePhoto ? (
          <img style={{border: `1px solid ${colors.color}`}} src={`data:image/png;base64,${role?.member?.profilePhoto}`} alt='img' />
            ) : (
            <div style={{border: `1px solid ${colors.color}`}} className='pmo-icon-container'>
              <Icon icon={faUser} />
            </div>
            ))
        }
        <p>{(role?.member?.givenName || role?.member?.surname) ? (role?.member?.givenName + " " + role?.member?.surname) : <span className='bf-em'>Not assigned</span>}</p>
        <p className='bf-small' style={{color: 'var(--bfc-base-c-2)'}}>{role?.name}</p>
        {(!hideComplexity && !editComplexity) ?
          <span style={{marginTop: 'var(--bfs8)'}}>
            <div style={{border: role?.complexity > 0 ? `1px solid ${colors.color}` : `1px solid var(--bfc-base-dimmed)`, background: role?.complexity > 0 ? colors.contrast : 'none', marginRight: 'var(--bfs4)'}}/>
            <div style={{border: role?.complexity > 1 ? `1px solid ${colors.color}` : `1px solid var(--bfc-base-dimmed)`, background: role?.complexity > 1 ? colors.contrast : 'none', marginRight: 'var(--bfs4)'}}/>
            <div style={{border: role?.complexity > 2 ? `1px solid ${colors.color}` : `1px solid var(--bfc-base-dimmed)`, background: role?.complexity > 2 ? colors.contrast : 'none'}}/>
          </span> : (!hideComplexity && editComplexity) &&
            <div className='complexity-checkbox-container'>
              <Checkbox label='complexity' hideLabel type={"radio"} onChange={e => setComplexity(role, e.target.checked ? 1 : 0)} checked={(!!(complexity && complexity?.complexity === 1)) || (!complexity && role?.complexity === 1)}/>
              <Checkbox label='complexity' hideLabel type={"radio"} onChange={e => setComplexity(role, e.target.checked ? 2 : 0)} checked={(!!(complexity && complexity?.complexity === 2)) || (!complexity && role?.complexity === 2)}/>
              <Checkbox label='complexity' hideLabel type={"radio"} onChange={e => setComplexity(role, e.target.checked ? 3 : 0)} checked={(!!(complexity && complexity?.complexity === 3)) || (!complexity && role?.complexity === 3)}/>
            </div>
        }
      </div>
    )
  }

  Date.prototype.addDays = function(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const projectView = () => {
    const filteredTeams = resourceTeams?.filter(t => t?.name?.toLowerCase()?.startsWith(search?.toLowerCase()))?.sort((a, b) => {
      if (!sorting?.value) return;
      if (sorting.value === 'nameAsc') {
        return a.name?.localeCompare(b.name);
      } else if (sorting.value === 'nameDesc') {
        return b.name?.localeCompare(a.name);
      } else if (sorting.value === 'dateDesc') {
        const dateA = new Date(a.goLiveDate);
        const dateB = new Date(b.goLiveDate);
        if (dateA.getFullYear() === 1) return -1;
        if (dateB.getFullYear() === 1) return 1;
        return dateB.getTime() - dateA.getTime();
      } else if (sorting.value === 'dateAsc') {
        const dateA = new Date(a.goLiveDate);
        const dateB = new Date(b.goLiveDate);
        if (dateA.getFullYear() === 1) return 1;
        if (dateB.getFullYear() === 1) return -1;
        return dateA.getTime() - dateB.getTime();
      }
    });

    return (
      <Accordion multiple mode='compact' style={{borderRadius: 'var(--bfs16)'}} >
        {filteredTeams?.map((c, i) => {
          return (
            <Accordion.Item style={i === 0 ? {borderTopLeftRadius: 'var(--bfs16)', borderTopRightRadius: 'var(--bfs16)'} : i === (filteredTeams.length - 1) ? {borderBottomLeftRadius: 'var(--bfs16)', borderBottomRightRadius: 'var(--bfs16)'} : undefined} noPadding key={i} title={
              <span className='pmo-accordion-header'>
                <span>{c?.name} {c?.code && <Badge style={{marginLeft: 'var(--bfs8)'}} state={"neutral"} pill>{c.code}</Badge>}</span>
                <span className='pmo-accordion-right-header'>
                  <span>
                    {c?.teams?.filter(i => {
                      if (resourceGroups.length === 0) return i;
                      if(resourceGroups?.find(r => r.label === i.name)) {
                        return i;
                      } return;
                    })?.map((t, i) => {
                      const sortedTeam = getManagerSortedTeamRoles(t);
                      const firstUser = sortedTeam[0];

                      if (hideImages) return;
                      const colors = getColorTheme(i);
                      return (
                        firstUser?.member?.profilePhoto ? (
                              <Tooltip content={`${firstUser?.member?.givenName} ${firstUser?.member?.surname} - ${firstUser?.name}`} key={i}>
                                <span className='pmo-team-user'>
                                  <img style={{border: `1px solid ${colors.color}`}} src={`data:image/png;base64,${firstUser?.member?.profilePhoto}`} alt='img' />
                                </span>
                              </Tooltip>
                          ) : (
                            <Tooltip content={`${firstUser?.name}`} key={i}>
                              <div style={{border: `1px solid ${colors.color}`}} className='pmo-icon-container'>
                                <Icon icon={faUser} />
                              </div>
                            </Tooltip>
                          )
                      );
                    })}
                  </span>
                  <span className='accordion-right-header-date' >{new Date(c?.goLiveDate).getFullYear() > 1 ?<FormatDate locale={'no-NB'} date={new Date(c?.goLiveDate)}/> : 'No date set'}</span>
                </span>
              </span>
            }>
              {c?.teams?.filter(i => {
                if (resourceGroups.length === 0) return i;
                if(resourceGroups?.find(r => r.label === i.name)) {
                  return i;
                } return;
              }).map((t, i) => {
                const sortedTeam = getManagerSortedTeamRoles(t);
                const colors = getColorTheme(i);
                return <div key={i} style={{borderBottom: '1px solid var(--bfc-base-dimmed)', display: 'flex'}}>
                  <div style={{padding: 'var(--bfs24) var(--bfs12)'}}>
                    <Tooltip content={t?.name}>
                      <div style={{color: colors.color, background: colors.contrast}} className='pmo-team-user-header'>{t?.name?.substring(0, 1)?.toUpperCase()}</div>
                    </Tooltip>
                  </div>
                  <div className='pmo-user-accordion-row bfl-grid' key={i}>
                    {sortedTeam?.filter(r => {
                      if (resourceRoles.length === 0) return r;
                      if (resourceRoles.find(t => t.label === r.name)) {
                        return r;
                      } return;
                    })?.map((r, i) => {
                      return teamUser(r, colors, i)
                    })}
                  </div>
                </div>
              })}
            </Accordion.Item>
          )
        })}
      </Accordion>
    )

  }

  const resourcesView = () => {
    const mappedMembers = members.filter(m => m.teamRoles.length > 0)

    const filteredMembers = mappedMembers?.filter(t => `${t.givenName} ${t?.surname}`?.toLowerCase()?.includes(search?.toLowerCase())).sort((a, b) => {
      if (!sorting?.value) return;
      if (sorting.value === 'nameAsc') {
        return `${a.givenName} ${a?.surname}`?.localeCompare(`${b.givenName} ${b?.surname}`);
      } else if (sorting.value === 'nameDesc') {
        return `${b.givenName} ${b?.surname}`?.localeCompare(`${a.givenName} ${a?.surname}`)
      } else if (sorting.value === 'dateDesc') {
        const dateA = new Date(a.goLiveDate);
        const dateB = new Date(b.goLiveDate);
        if (dateA.getFullYear() === 1) return -1;
        if (dateB.getFullYear() === 1) return 1;
        return dateB.getTime() - dateA.getTime();
      } else if (sorting.value === 'dateAsc') {
        const dateA = new Date(a.goLiveDate);
        const dateB = new Date(b.goLiveDate);
        if (dateA.getFullYear() === 1) return 1;
        if (dateB.getFullYear() === 1) return -1;
        return dateA.getTime() - dateB.getTime();
      }
    });

    const filteredNewMembers = filteredMembers.map(m => {

      const mappedRoles =  m.teamRoles.flatMap(r => {
        return ({...r, companyId: r.team.companyId});
      }).filter(p => {
        if (resourceGroups.length > 0 && !resourceGroups?.find(r => r.label === p?.team?.name)) {
          return;
        }
        const company = resourceTeams.find(d => d.id === p.companyId);
        if (company && resourceRoles.length === 0) return p;
        if (resourceRoles.find(r => r.label === p?.name) && company) {
          return p;
        } else if (!resourceRoles.find(r => r.label === p?.name) === undefined && company) {
          return;
        }
      });

      const groupedRoles = { };

      for(const {companyId, ...role} of mappedRoles) {
        if(!groupedRoles[companyId]) groupedRoles[companyId] = [];
        groupedRoles[companyId].push({ companyId, ...role });
      }

      const rows = [];

      for (const [key, value] of Object.entries(groupedRoles)) {
        rows.push({key: key, value: value});
      }
      return { member: m, roles: rows };
    });

    return (
      filteredNewMembers?.map((c, i) => {

        if (c.roles.length > 0)
        return (
          <div className='pmo-resource-view' key={i}>
            <div className='pmo-resource-header'>
              <span>
                <span className='pmo-team-user'>
                  <img style={{ border: `1px solid var(--bfc-chill)` }}
                       src={`data:image/png;base64,${c?.member?.profilePhoto}`} alt='img' />
                </span>
                <p className='bf-strong bf-large'>{c?.member?.givenName} {c?.member?.surname}</p>
                <Badge state={"neutral"} pill>{c.roles.length} projects</Badge>
              </span>
              <span className='pmo-accordion-right-header'>
                <span>
                  <p className='bf-small bf-strong' style={{ margin: 0 }}>Role and complexity</p>
                </span>
                <span className='accordion-right-header-date'>
                  <p className='bf-small bf-strong' style={{ margin: 0 }}>Go live date</p>
                </span>
              </span>
            </div>
            <Accordion multiple mode='compact' style={{marginBottom: 'var(--bfs24)', borderBottom: 0}}>
              {c?.roles?.map((p, i) => {
                const company = resourceTeams.find(d => d.id === p?.key);
                return (
                  <Accordion.Item noPadding style={i === (c?.roles?.length - 1) ? {borderBottomRightRadius: '16px', borderBottomLeftRadius: '16px'} : undefined} key={i} title={
                    <span className='pmo-accordion-item-header'>
                      <span>
                        {company?.name} {company?.code && `(${company?.code})`}
                      </span>
                      <span className='pmo-accordion-right-header'>
                        {p?.value?.map((r, i) => {
                          return (
                            <span key={i} className='pmo-accordion-right-resource'>
                              <p className='bf-small'>{r.name}</p>
                              <div style={{border: r?.complexity > 0 ? `1px solid var(--bfc-chill)` : `1px solid var(--bfc-base-dimmed)`, background: r?.complexity > 0 ? 'var(--bfc-chill-c)' : 'none', marginRight: 'var(--bfs4)'}}/>
                              <div style={{border: r?.complexity > 1 ? `1px solid var(--bfc-chill)` : `1px solid var(--bfc-base-dimmed)`, background: r?.complexity > 1 ? 'var(--bfc-chill-c)' : 'none', marginRight: 'var(--bfs4)'}}/>
                              <div style={{border: r?.complexity > 2 ? `1px solid var(--bfc-chill)` : `1px solid var(--bfc-base-dimmed)`, background: r?.complexity > 2 ? 'var(--bfc-chill-c)' : 'none'}}/>
                            </span>
                          )
                        })}
                        <span className='accordion-right-header-date' >{new Date(company?.goLiveDate).getFullYear() > 1 ?<FormatDate locale={'no-NB'} date={new Date(company?.goLiveDate)}/> : 'No date set'}</span>
                      </span>
                    </span>

                  }>
                    {company?.teams?.filter(i => {
                      if (resourceGroups.length === 0) return i;
                      if (!p.value.find(v => v?.team?.name === i.name)) return;
                      if(resourceGroups?.find(r => r.label === i.name)) {
                        return i;
                      } return;
                    })?.map((t, i) => {
                      const sortedTeam = getManagerSortedTeamRoles(t);
                      const colors = getColorTheme(i);
                      return <div style={(company?.teams.length - 1) === i ? {borderBottom: 0, display: 'flex'} : {borderBottom: '1px solid var(--bfc-base-dimmed)', display: 'flex'}} key={i}>
                        <div style={{padding: 'var(--bfs24) var(--bfs12)'}}>
                          <Tooltip content={t?.name}>
                            <div style={{color: colors.color, background: colors.contrast}} className='pmo-team-user-header'>{t?.name?.substring(0, 1)?.toUpperCase()}</div>
                          </Tooltip>
                        </div>
                        <div className='pmo-user-accordion-row bfl-grid' key={i}>
                          {sortedTeam?.filter(r => {
                            if (resourceRoles.length === 0) return r;
                            if (resourceRoles.find(t => t.label === r.name)) {
                              return r;
                            } return;
                          })?.map((r, i)=> {
                            return teamUser(r, colors, i)
                          })}
                        </div>
                      </div>
                    })}
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        )
      })
    )
  }

  const goLiveView = () => {
    const groupedTeams = { };

    for(const {goLiveDate, ...team} of resourceTeams) {
      if(!groupedTeams[goLiveDate]) groupedTeams[goLiveDate] = [];
      groupedTeams[goLiveDate].push({ goLiveDate, ...team });
    }

    const rows = [];

    for (const [key, value] of Object.entries(groupedTeams)) {
      rows.push({key: key, value: value});
    }

    return rows?.sort((a, b) => {
      if (!sorting?.value) return;
      if (sorting.value === 'dateDesc') {
        const dateA = new Date(a.key);
        const dateB = new Date(b.key);
        if (dateA.getFullYear() === 1) return -1;
        if (dateB.getFullYear() === 1) return 1;
        return dateB.getTime() - dateA.getTime();
      } else if (sorting.value === 'dateAsc') {
        const dateA = new Date(a.key);
        const dateB = new Date(b.key);
        if (dateA.getFullYear() === 1) return 1;
        if (dateB.getFullYear() === 1) return -1;
        return dateA.getTime() - dateB.getTime();
      }
    })?.map((r, i) => {
      return (
        <div className='pmo-resource-view' key={i}>
          <div className='pmo-resource-header'>
          <span>
            <span className='pmo-strong-date'>
              {new Date(r?.key)?.getFullYear() > 1 ?<FormatDate locale={'no-NB'} date={new Date(r?.key)}/> : 'No date set'}
            </span>
            <Badge state={"neutral"} pill>{r.value.length} projects</Badge>
          </span>
          <span className='pmo-accordion-right-header'>
            <span className='accordion-right-header-date'>
              <p className='bf-small bf-strong' style={{ margin: 0 }}>Project managers</p>
            </span>
          </span>
          </div>
          <Accordion multiple mode='compact' style={{marginBottom: 'var(--bfs24)', borderBottom: 0}}>
            {r.value?.map((company, i) => {
              return (
                <Accordion.Item style={i === (r.value.length - 1) ? {borderBottomRightRadius: '16px', borderBottomLeftRadius: '16px'} : undefined} noPadding key={i} title={
                  <span className='pmo-accordion-header'>
                    <span>
                      {company?.name} {company?.code && `(${company?.code})`}
                    </span>
                    <span className='pmo-accordion-right-header'>
                      <span>
                        {company?.teams?.filter(i => {
                          if (resourceGroups.length === 0) return i;
                          if(resourceGroups?.find(r => r.label === i.name)) {
                            return i;
                          } return;
                        })?.map((t, i) => {
                          const sortedTeam = getManagerSortedTeamRoles(t);
                          const firstUser = sortedTeam[0];

                          const colors = getColorTheme(i);
                          return (
                            firstUser?.member?.profilePhoto ? (
                                <Tooltip content={`${firstUser?.member?.givenName} ${firstUser?.member?.surname} - ${firstUser?.name}`} placement='right' key={i}>
                                  <span className='pmo-team-user'>
                                    <img style={{border: `1px solid ${colors.color}`}} src={`data:image/png;base64,${firstUser?.member?.profilePhoto}`} alt='img' />
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip content={`${firstUser?.name}`} key={i}>
                                  <div style={{border: `1px solid ${colors.color}`}} className='pmo-icon-container'>
                                    <Icon icon={faUser} />
                                  </div>
                                </Tooltip>
                              )
                          );
                        })}
                      </span>
                    </span>
                  </span>
                }>
                  {company?.teams?.filter(i => {
                    if (resourceGroups.length === 0) return i;
                    if(resourceGroups?.find(r => r.label === i.name)) {
                      return i;
                    } return;
                  }).map((t, i) => {
                    const sortedTeam = getManagerSortedTeamRoles(t);
                    const colors = getColorTheme(i);
                    return <div style={(company?.teams.length - 1) === i ? {borderBottom: 0, display: 'flex'} : {borderBottom: '1px solid var(--bfc-base-dimmed)', display: 'flex'}} key={i}>
                      <div style={{padding: 'var(--bfs24) var(--bfs12)'}}>
                        <div style={{color: colors.color, background: colors.contrast}} className='pmo-team-user-header'>{t?.name?.substring(0, 1)?.toUpperCase()}</div>
                      </div>
                      <div className='pmo-user-accordion-row bfl-grid' key={i}>
                        {sortedTeam?.filter(r => {
                          if (resourceRoles.length === 0) return r;
                          if (resourceRoles.find(t => t.label === r.name)) {
                            return r;
                          } return;
                        })?.map((r, i) => {
                          return teamUser(r, colors, i)
                        })}
                      </div>
                    </div>
                  })}
                </Accordion.Item>
              )
            })}
          </Accordion>
        </div>
      )
    })
  }

  if (projectGlobalTeamStatus?.error && !intilityUser) return <Redirect to='/' />;

  return (
    <div id='project-management-office'>
      <div className='pmo-header'>
        <div>
          <h1>Project resource overview</h1>
          <p>A quick view to see resources, representing upcoming projects, counting past projects one month back from today's date.</p>
        </div>
        <div className='pmo-header-buttons'>
          {!editComplexity ?
            <Button onClick={() => setEditComplexity(true)}>
              Edit role complexity
            </Button> :
            <div>
              <Button style={{marginRight: 'var(--bfs12)'}} variant={"outline"} state={"alert"} onClick={() => {
                setEditComplexity(false);
                setEditRoleComplexity([]);
              }}>Cancel</Button>
              <Button variant={"filled"} onClick={() => saveComplexityChanges()}>Save</Button>
            </div>
          }
        </div>
      </div>
      <div className='pmo-main-content'>
        <Tabs variant={"styled"} multiple>
          <Tabs.Item onClick={() => resetSearch(activeTab.PROJECT)} active={active === activeTab.PROJECT} content={<div className='pmo-tab-content'>
            {tabTopBar(activeTab.PROJECT)}
            {projectView()}
          </div>}>
            <Icon icon={faList} marginRight />
            Projects
          </Tabs.Item>
          <Tabs.Item onClick={() => resetSearch(activeTab.RESOURCES)} active={active === activeTab.RESOURCES} content={<div className='pmo-tab-content'>
            {tabTopBar(activeTab.RESOURCES)}
            {resourcesView()}
          </div>}>
            <Icon icon={faUserCircle} marginRight />
            Resources
          </Tabs.Item>
          <Tabs.Item onClick={() => resetSearch(activeTab.GOLIVE)} active={active === activeTab.GOLIVE} content={<div className='pmo-tab-content'>
            {tabTopBar(activeTab.GOLIVE)}
            {goLiveView()}
          </div>}>
            <Icon icon={faCalendarAlt} marginRight />
            Go-live dates
          </Tabs.Item>
        </Tabs>
      </div>
    </div>
  )

}

export default ProjectManagementOffice