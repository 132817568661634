import React, { useEffect, useState } from "react";
import {useSelector} from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import './articleComponent.scss';
import apiFetch from "../../../../../utils/apiFetch";
import { Spinner } from "@intility/bifrost-react";
import devLog from "../../../../../utils/devLog";

export const ArticleComponent = ({id}) => {
  const {info} = useSelector(state => state.company.data);
  const [ data, setData ] = useState();
  const [ image, setimage ] = useState();
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    articleFetcher().finally(() => setFetching(false))
  }, [])

  useEffect(() => {
    if (data === undefined) return;
    imageFetcher().finally(() => setFetching(false))
  }, [data])

  const articleFetcher = async () => {
    apiFetch(`/Publish/${id}?language=en-us`).then(response => {
      return response.json();
    }).then(value => setData(JSON.parse(value)))
  };

  const imageFetcher = async () => {
    apiFetch(`/Publish/images/${data?.logo}?language=en-us`).then(response => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    }).then(value => setimage(value))
  };

  return (
    (!image && !fetching) ? <Spinner />
      :
    <div className='articleComponent'>
      <img className='articleImage' src={image}  alt='Article image'/>
      <div className='contentContainer'>
        <div className='textContainer'>
          <h4>{data?.title}</h4>
          <p>{data?.ingress}</p>
        </div>

        <div className='read-article-link-container'>
          <Link to={`/${info.id}/mobile-devices/articles/${id}`} className='bf-title-link bf-strong' style={{fontSize: 'var(--bf-font-size-m)'}}>
            Read the article
          </Link>
        </div>
      </div>
    </div>
  );
};


export const TaskArticleComponent = ({category}) => {
  const {info} = useSelector(state => state.company.data);
  const [ data, setData ] = useState(undefined);
  const [ articles, setArticles ] = useState();
  const [ image, setimage ] = useState();
  const [ noImage, setNoImage ] = useState(false);
  const [fetching, setFetching] = useState(true)
  const [fetchingArticles, setFetchingArticles] = useState(true)
  const location = useLocation();

  useEffect(() => {
    articleFetcher().finally(() => {
      setFetchingArticles(false);
    })
  }, []);

  useEffect(() => {
    if (data) setFetching(false);
  }, [data]);

  useEffect(() => {
    if (articles === undefined) return;
    if (!fetchingArticles && articles?.value?.length === 0) {
      setFetching(false);
      return;
    }
    singleArticleFetcher().catch((e) => devLog('article', e));
    imageFetcher().catch((e) => devLog('image', e));
  }, [articles?.value])

  const articleFetcher = async () => {
    apiFetch(`/Publish?subPage=${category}`).then(response => {
      return response.json();
    }).then(value => setArticles(JSON.parse(value)))
  };

  const imageFetcher = async () => {
    apiFetch(`/Publish/images/${articles?.value[0]?.feedImage}?language=en-us`).then(response => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    }).then(value => setimage(value))
  };

  const singleArticleFetcher = async () => {
    apiFetch(`/Publish/${articles?.value[0]?.id}?language=en-us`).then(response => {
      return response.json();
    }).then(value => setData(JSON.parse(value)))
  };

  return (
    (fetching) ? <Spinner />
      : data ?
        <div className='box-dropdown-info bf-content'>
          <div className='box-info-article'>
            <div className='task-article-component'>
              <div className='task-article-container'>
                <img className='article-image' src={image} alt='No image'/>
              </div>
              <div className='read-article-link-container'>
                <Link to={{pathname: `/${info.id}/articles/${data && data?.id}`, state: { prevPath: location.pathname }}} className='bf-title-link bf-strong' style={{fontSize: 'var(--bf-font-size-m)'}}>
                  {data?.title}
                </Link>
                {data?.ingress && <p style={{margin: '0'}}>{data?.ingress.substring(0, 70) + '...'}</p> }
              </div>
            </div>
          </div>
          <div className='box-info-text bf-scrollbar-small from-large'>
            <div>
              <h3>Why do Intility need this data?</h3>
              <p className='bf-large'>Read more about why it is important that you provide this data about your company</p>
            </div>
          </div>
        </div>
      :
        <div className='box-dropdown-info'>
          <div className='box-info-text'>
            <div>
              <h3>
                Onboard to Intility</h3>
              <p>Complete all tasks to get a smooth onboarding process.</p>
            </div>
          </div>
        </div>
  );
};