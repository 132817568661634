import {apiFetch, apiFetchIdCollection} from '../../../utils/apiFetch';
import {
  addIamsUser,
  addIamsUserError,
  fetchIamsUsers,
  fetchIamsUsersError,
  seedingIamsUsers,
  seedingIamsUsersError,
  seedingIamsUsersSuccess,
  updateIamsUsers,
  updateIamsUsersError,
  uploadIamsUsersError
} from './iamsUsersReducers';
import {
  addIamsUserSuccess,
  deleteIamsUsersSuccess,
  fetchIamsUsersSuccess,
  updateIamsUsersSuccess,
  updateIamsUserSuccess
} from './iamsUsersActions';
import devLog from '../../../utils/devLog';
import {fetchUsersAsync} from '../users/userThunks';
import produce from 'immer';

export const fetchIamsUsersAsync = companyId => (dispatch, getState) => {
  const {fetching} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (fetching) return null;
  dispatch(fetchIamsUsers());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${companyId}/iamsUsers`,
    method: 'GET'
  }).then(iamsUsers => dispatch(fetchIamsUsersSuccess(iamsUsers)))
    .catch(error => dispatch(fetchIamsUsersError(error)));
};

export const seedIamsUsersAsync = () => (dispatch, getState) => {
  const {seeding} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (seeding) return null;
  dispatch(seedingIamsUsers());

  const {id} = getState().company.data.info;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/iamsUsers/update`,
    method: 'GET'
  }).then(() => {
    dispatch(fetchIamsUsersAsync(id));
    dispatch(fetchUsersAsync(id));
    dispatch(seedingIamsUsersSuccess());
  })
    .catch(error => dispatch(seedingIamsUsersError(error)));
};

export const addIamsUserAsync = user => (dispatch, getState) => {
  const {iamsUsersStatus, information, iamsUserList} = getState().company.data.iamsUserPlatform;
  const {id} = getState().company.data.info;
  if (iamsUsersStatus.adding) return null;
  dispatch(addIamsUser());

  const body = {
    ...user,
    iamsUserPlatformId: information.id
  };

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/iamsUsers`,
    method: 'POST',
    body: body
  }).then(user => dispatch(addIamsUserSuccess(user, iamsUserList)))
    .catch(error => dispatch(addIamsUserError(error)));
};

export const changeIamsUserTypeAsync = (iamsUserId, type) => (dispatch, getState) => {
  const {iamsUsersStatus, iamsUserList} = getState().company.data.iamsUserPlatform;
  const {id} = getState().company.data.info;
  if (iamsUsersStatus.updating) return null;
  dispatch(updateIamsUsers());

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/iamsUsers/${iamsUserId}/userType`,
    method: 'POST',
    body: type
  }).then(user => dispatch(updateIamsUserSuccess(user, iamsUserList)))
    .catch(error => dispatch(updateIamsUsersError(error)));
};

export const updateIamsUserAsync = user => (dispatch, getState) => {
  const {updating} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (updating) return null;
  dispatch(updateIamsUsers());

  const {information, iamsUserList} = getState().company.data.iamsUserPlatform;
  let iamsBody;
  if (user?.companyUser?.iamsUsers !== undefined) {
    iamsBody = produce(user, draft => {
      draft.companyUser.iamsUsers = [];
    });
  } else iamsBody = user

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/iamsUsers/${user.id}`,
    method: 'PUT',
    body: iamsBody
  }).then(user => dispatch(updateIamsUserSuccess(user, iamsUserList)))
    .catch(error => dispatch(updateIamsUsersError(error)));
};

export const updateIamsUserDevicesAsync = users => (dispatch, getState) => {
  const {updating} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (updating) return null;
  dispatch(updateIamsUsers());

  const {information, iamsUserList} = getState().company.data.iamsUserPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/iamsUsers/workplaceDevices`,
    method: 'PUT',
    body: users
  }).then(res => dispatch(updateIamsUsersSuccess(res, iamsUserList)))
    .catch(error => dispatch(updateIamsUsersError(error)));
};

export const updateIamsUsersDevicesBulkAsync = users => (dispatch, getState) => {
  const {updating} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (updating) return null;
  dispatch(updateIamsUsers());

  const {id} = getState().company.data.info;
  const {iamsUserList} = getState().company.data.iamsUserPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${id}/iamsUsers/workplaceDevices/bulk`,
    method: 'PUT',
    body: users
  }).then(res => dispatch(updateIamsUsersSuccess(res, iamsUserList)))
    .catch(error => dispatch(updateIamsUsersError(error)));
};

export const deleteIamsUserAsync = id => (dispatch, getState) => {
  const {updating} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (updating) return null;
  dispatch(updateIamsUsers());

  const {information} = getState().company.data.iamsUserPlatform;

  return apiFetchIdCollection(apiFetch, {
    url: `/companies/${information.companyId}/iamsUsers/${id}`,
    method: 'DELETE'
  })
    .then(() => dispatch(fetchIamsUsersAsync(information.companyId)))
    .catch(error => dispatch(updateIamsUsersError(error)));
};

export const uploadIamsCsv = file => (dispatch, getState) => {
  const {updating} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (updating) return null;
  dispatch(updateIamsUsers());

  const {id} = getState().company.data.info;
  const formData = new FormData();
  formData.append(`csv/${id}`, file);

  return apiFetch(`/companies/${id}/iamsUsers/csv`, {
    method: 'POST',
    body: formData
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(response => {throw response})
  }).then(() => dispatch(fetchIamsUsersAsync(id)))
    .catch(error => {
      console.log(error);
      dispatch(uploadIamsUsersError(error));
    });
};

export const downloadCsv = (filter) => (dispatch, getState) => {
  const {updating} = getState().company.data.iamsUserPlatform.iamsUsersStatus;
  if (updating) return null;
  dispatch(updateIamsUsers());

  const {information} = getState().company.data.iamsUserPlatform;

  return apiFetch(`/companies/${information.companyId}/iamsUsers/csv?filter=${filter}`, {
    method: 'GET'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred while trying to download CSV.');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `iamsUsers_${information.companyId}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .then(res => res.flush())
    .catch(error => dispatch(updateIamsUsersError(error)));
};

export const bulkDelete = iamsUsers => (dispatch, getState) => {
  const {iamsUsersStatus, information, iamsUserList} = getState().company.data.iamsUserPlatform;
  if (iamsUsersStatus.updating) return null;
  dispatch(updateIamsUsers());

  return apiFetch(`/companies/${information.companyId}/iamsUsers/deleteBulk`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(iamsUsers)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .then(iamsUsers => {
      dispatch(deleteIamsUsersSuccess(iamsUsers, iamsUserList));
    })
    .catch(error => dispatch(updateIamsUsersError(error)));
};

export const multiPut = iamsUsers => (dispatch, getState) => {
  const {iamsUsersStatus, information, iamsUserList} = getState().company.data.iamsUserPlatform;
  if (iamsUsersStatus.updating) return null;
  dispatch(updateIamsUsers());

  devLog('PUT', iamsUsers);

  return apiFetch(`/companies/${information.companyId}/iamsUsers/${information.id}/bulk`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(iamsUsers)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .then(iamsUsers => {
      dispatch(updateIamsUsersSuccess(iamsUsers, iamsUserList));
    })
    .catch(error => dispatch(updateIamsUsersError(error)));
};
