import {
  AutoCol,
  Button,
  Checkbox, CheckboxCard,
  Drawer, Dropdown,
  Icon,
  Input,
  Message,
  Modal,
  Table,
  TextArea
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import './globalTaskSettings.scss';
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";
import { GlobalTask } from "../../../utils/hub/models";
import {
  addGlobalTaskAsync,
  deleteGlobalTaskAsync,
  updateGlobalTaskAsync
} from "../../../redux/company/onboardTasks/onboardTaskThunks";
import React, { useEffect, useState } from "react";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faEllipsisH, faPencil, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { pageDataOptions, projectAreaOptions } from "../../../utils/hub/pageData/pageDataOptions";
import { PublishArticleDrawer } from "../../Hub/Pages/Devices.MobileDevices/Articles/PublishArticle";
import { responsibleType } from "../../BackOffice/EditComponents/EditOnboardTasks";
export const GlobalTaskSettings = ({}) => {
  const {globalTaskList} = useSelector(state => state.company.data.taskPlatform);
  const [sort, setSort] = useState({key: 'request', direction: 'asc'});
  const [showModal, setShowModal] = useState(false);
  const [globalTask, setGlobalTask] = useState(new GlobalTask());
  const [searchValue, setSearchValue] = useState('');
  const [taskFilter, setTaskFilter] = useState(0);
  const [projectAreas, setProjectAreas] = useState([]);
  const {user} = useSelector(state => state.auth);
  const [selectedTask, setSelectedTask] = useState(null);
  const [savingGlobal, setSavingGlobal] = useState(false);
  const dispatch = useDispatch();


  const onChange = (e, name) => {
    if (name === 'taskType') {
      setGlobalTask(
        produce(globalTask, draft => {
          draft.taskType = e;
        })
      )
    } else {
      const {value} = e;

      setGlobalTask(
        produce(globalTask, draft => {
          if (name === 'priority') {
            const newValue = parseInt(value?.toString()) || 0;
            draft[name] = newValue > 100 ? 100 : newValue;
          } else if (name === 'defaultEnabled') {
            draft.defaultEnabled = e;
          } else {
            draft[name] = value;
          }
        })
      )
    }
  }

  const editTask = (task) => {
    setGlobalTask(task);
    setShowModal(true);
  };

  const resetTasks = () => {
    setGlobalTask(new GlobalTask());
    setShowModal(false);
    setSavingGlobal(false);
  }

  const onSave = (newTask) => {
    setSavingGlobal(true);
    if (newTask?.id !== undefined) {
      dispatch(updateGlobalTaskAsync(newTask));
    } else if (globalTask?.request?.length === 0 || globalTask?.projectArea?.length === 0) {
      return;
    } else {
      if (globalTask.request.length > 0) {
        dispatch(addGlobalTaskAsync(globalTask));
      }
    }
    resetTasks();
  }

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  const deleteGlobal = (task) => {
    const result = confirm('Are you sure you wish to delete this global task? This will delete it from all companies.');
    if (result) {
      return dispatch(deleteGlobalTaskAsync(task?.id));
    }
  }

  const filterProjectAreaFunction = (item) => {
    if (projectAreas.length === 0) return true;
    let filtered = false;
    projectAreas.forEach(f => {
      if (item?.projectArea === f.value) {
        filtered = true;
      }
    });
    return filtered;
  }

  const sortedData = [...globalTaskList]
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (sort.key === 'deadlineStartDays') return a[sort.key] - b[sort.key];
        if (sort.key === 'taskType') return a[sort.key] - b[sort.key];
        if (sort.key === 'defaultEnabled') return a?.defaultEnabled === true;
        if (sort.key === 'section') return pageDataOptions[a[sort.key]]?.label.localeCompare(pageDataOptions[b[sort.key]]?.label);
        if (!a[sort.key] || a[sort.key] === null) return 1;
        if (!b[sort.key] || b[sort.key] === null) return -1;
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        if (sort.key === 'deadlineStartDays') return b[sort.key] - a[sort.key];
        if (sort.key === 'taskType') return b[sort.key] - a[sort.key];
        if (sort.key === 'defaultEnabled') return b?.defaultEnabled === true;
        if (sort.key === 'section') return pageDataOptions[b[sort.key]]?.label.localeCompare(pageDataOptions[a[sort.key]]?.label);
        if (!b[sort.key] || b[sort.key] === null) return 1;
        if (!a[sort.key] || a[sort.key] === null) return -1;
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    }).filter(t => filterProjectAreaFunction(t))
    .filter(t => {
      if (taskFilter === 1) return t?.defaultEnabled === true;
      if (taskFilter === 2) return t?.defaultEnabled === false;
      return t;
    }).filter(s => {
      return s.request?.toLowerCase().includes(searchValue.toLowerCase());
    });


  return (
    <div id='global-task-settings' className='global-settings-page'>
      <Message header='Global tasks available for all companies' state={"warning"}>
        Editing, adding or deleting tasks on this page will affect all companies.
      </Message>
      <div className='global-filter-row'>
        <div className='filter-btn-row'>
          <Input
            className='table-search-input'
            placeholder='Search for task'
            label='Search'
            hideLabel
            icon={faSearch}
            clearable
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setPage(1);
            }}
            rightIcon
          />
          <Select className='header-select' label='Project area filter' placeholder='Project area' isMulti={true} value={projectAreas} options={projectAreaOptions} hideLabel onChange={(e) => setProjectAreas(e)} />
          <Button.Group>
            <Button active={taskFilter === 0} onClick={() => {
              setTaskFilter(0)
            }}>All tasks ({globalTaskList?.length})</Button>
            <Button active={taskFilter === 1} onClick={() => {
              setTaskFilter(1)
            }}>Published ({globalTaskList?.filter(g => g.defaultEnabled === true)?.length})</Button>
            <Button active={taskFilter === 2} onClick={() => {
              setTaskFilter(2)
            }}>Deactivated ({globalTaskList?.filter(g => g.defaultEnabled === false)?.length})</Button>
          </Button.Group>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button style={{marginTop: 'auto'}} onClick={() => setShowModal(true)}>Add global Task</Button>
        </div>
      </div>
      <Table style={{marginTop: 'var(--bfs16)'}}>
        <colgroup>
          <col className='from-large' style={{width: '35%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '10%'}} />
          <col className='from-large' style={{width: '5%'}} />
        </colgroup>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorting={getSortProp('request')} onClick={onSortChangeCreator('request')}>
              Request
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('guideUrl')} onClick={onSortChangeCreator('guideUrl')}>
              Guide Url
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('section')} onClick={onSortChangeCreator('section')}>
              Onboard Section
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('projectArea')} onClick={onSortChangeCreator('projectArea')}>
              Project Area
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('deadlineStartDays')} onClick={onSortChangeCreator('deadlineStartDays')}>
              Deadline days
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('taskType')} onClick={onSortChangeCreator('taskType')}>
              Internal
            </Table.HeaderCell>
            <Table.HeaderCell sorting={getSortProp('defaultEnabled')} onClick={onSortChangeCreator('defaultEnabled')}>
              Global setting
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedData.map((task, i) => (
            <Table.Row key={i}>
              <Table.Cell>{task.request}</Table.Cell>
              <Table.Cell><a href='#' className='bf-link' style={{}} onClick={() => setSelectedTask(task)}>{task?.guideUrl?.includes('publish.intility.com') ? 'Publish article' : task?.guideUrl?.length > 0 ? 'Other link' : ''}</a>{
                task?.guideUrl?.includes('publish.intility.com') ? <a className='bf-link' style={{marginLeft: 'var(--bfs4)',display: 'inline'}} href={`https://publish.intility.com/articles/${task?.guideUrl.split('/articles/')[1]}/edit`} rel='noopener noreferrer' target='_blank'><Icon icon={faPencil} /></a>
                  :
                  <span style={{color: 'var(--bfc-base-c-2)'}}>No guide</span>
              }
              </Table.Cell>
              <Table.Cell>{pageDataOptions[task?.section]?.label}</Table.Cell>
              <Table.Cell>{projectAreaOptions.find(o => o.value === task?.projectArea)?.label}</Table.Cell>
              <Table.Cell>{task.deadlineStartDays}</Table.Cell>
              <Table.Cell>{task?.taskType === responsibleType.CUSTOMER ? <Icon icon={faTimes}/> : task?.taskType === responsibleType.INTILITY && <Icon icon={faCheck}/>}</Table.Cell>
              <Table.Cell>{task.defaultEnabled ? <span><Icon style={{color: 'var(--bfc-success)', marginRight: 'var(--bfs8)'}} icon={faCheck}/>Default published</span> : <span style={{color: 'var(--bfc-base-c-2)'}}>Default deactivated</span>}</Table.Cell>
              <Table.Cell>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Dropdown content={
                    <>
                      <Button variant={"outline"} style={{marginRight: 'var(--bfs8)'}} onClick={() => editTask(task)}>Edit task</Button>
                      <Button variant={"outline"} state={"alert"} onClick={() => {
                        deleteGlobal(task);
                      }}>Delete</Button>
                    </>
                  } variant='border'>
                    <Icon icon={faEllipsisH} style={{cursor: 'pointer'}}/>
                  </Dropdown>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal isOpen={showModal} onRequestClose={() => resetTasks()} header={`${globalTask?.id !== undefined ? `Edit: ${globalTask.request}` : 'Add new global Task'}`}>
        <div id='task-modal'>
          <div className='task-modal-global'>
            <AutoCol>
              <CheckboxCard
                label='External'
                checked={globalTask?.taskType === responsibleType.CUSTOMER}
                onChange={(e) => onChange(e.target.checked && responsibleType.CUSTOMER, 'taskType')}
                type='radio'
              >
                Will be visible for customers in Onboard
              </CheckboxCard>
              <CheckboxCard
                label='Internal'
                checked={globalTask?.taskType === responsibleType.INTILITY}
                onChange={(e) => onChange(e.target.checked && responsibleType.INTILITY, 'taskType')}
                type='radio'
              >
                Visible for Intility only. Can connect with CS ticket
              </CheckboxCard>
            </AutoCol>
            <Input label='Deadline start days' description='Number of days from Onboard company created to default deadline for this task, set to 0 to avoid setting default due date' value={globalTask?.deadlineStartDays} onChange={(e) => onChange(e.target, 'deadlineStartDays')}/>
            <Select label='Onboard Section' value={pageDataOptions[globalTask?.section]} required options={pageDataOptions} onChange={(e) => onChange(e, 'section')}/>
            <Select label='Project area' state={(savingGlobal && globalTask?.projectArea?.length === 0) ? "warning" : 'default'} feedback={(savingGlobal && globalTask?.projectArea?.length === 0) ? 'Missing value' : ''} value={projectAreaOptions.find(o => o.value === globalTask?.projectArea)} required options={projectAreaOptions} onChange={(e) => onChange(e, 'projectArea')}/>
            <Input label='GuideUrl' value={globalTask?.guideUrl} optional onChange={(e) => onChange(e.target, 'guideUrl')}/>
            <TextArea label='Request' state={(savingGlobal && globalTask?.request?.length === 0) ? "warning" : 'default'} feedback={(savingGlobal && globalTask?.request?.length === 0) ? 'Missing value' : ''} value={globalTask?.request} required onChange={(e) => onChange(e.target, 'request')}/>
          </div>
        </div>
        <div style={{marginTop: 'var(--bfs16)', display: 'flex', justifyContent: 'space-between'}}>
          <Checkbox button label='Default enabled' checked={globalTask?.defaultEnabled} onChange={(e) => onChange(e.target.checked, 'defaultEnabled')} type={"switch"}/>
          <Button state={(globalTask?.request?.length === 0 || !globalTask?.projectArea) ? "inactive" : 'default'} onClick={() => onSave(globalTask)}>Save</Button>
        </div>
      </Modal>
      <Drawer isOpen={selectedTask !== null} overlay={true} onRequestClose={() => setSelectedTask(null)}>
        <PublishArticleDrawer articleId={selectedTask?.guideUrl.split('/articles/')[1] || null}/>
      </Drawer>
    </div>
  )
}

export default GlobalTaskSettings

