import { Icon } from "@intility/bifrost-react";
import './overviewCard.scss';
import PropTypes from "prop-types";
import {
  faBuilding, faDiagramProject,
  faServer, faUser, faCoins
} from "@fortawesome/pro-regular-svg-icons";

export const ValuesCard = ({ actualIncome, esticatedIncome, projects, applications, locations, users }) => {
  return (
    <div className='values-card'>
      <div className='money-card'>
        <div className='card-header'>
          <Icon icon={faCoins}/>
          <p className='bf-strong bf-small'>Finance</p>
        </div>
        <div className='card-body'>
          <div>
            <h4>{actualIncome}</h4>
            <p className='bf-small'>Installed (NOK)</p>
          </div>
          <div className='separator'/>
          <div>
            <h4>{esticatedIncome}</h4>
            <p className='bf-small'>Predicted (NOK)</p>
          </div>
        </div>
      </div>
      <div className='card-values'>
        <span className='bf-strong bf-small'><Icon icon={faDiagramProject}/> Projects: <span style={{color: 'var(--bfc-theme)'}} className='bf-strong'>{projects}</span></span>
        <span className='bf-strong bf-small'><Icon icon={faServer}/> Applications: <span style={{color: 'var(--bfc-theme)'}} className='bf-strong'>{applications}</span></span>
        <span className='bf-strong bf-small'><Icon icon={faBuilding}/> Locations: <span style={{color: 'var(--bfc-theme)'}} className='bf-strong'>{locations}</span></span>
        <span className='bf-strong bf-small'><Icon icon={faUser}/> Users: <span style={{color: 'var(--bfc-theme)'}} className='bf-strong'>{users}</span></span>
      </div>
    </div>
  )
}


ValuesCard.propTypes = {
  actualIncome: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  esticatedIncome: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  projects: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  applications: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  locations: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  users: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
}

export default ValuesCard