import { Checkbox, Input } from "@intility/bifrost-react";
import AppStepCard from "../AppStepCard";
import React, { useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";

export const StepThree = ({step, setStep, newAppType, progress, setProgress, appObject, setAppObject, totalSteps, setIsOpen}) => {
  const [notRelevant, setNotRelevant] = useState(appObject.versionNotRelevant || false);
  const [isSameVersion, setIsSameVersion] = useState(appObject.versionIsSame || true);

  const onNotRelevant = (e) => {
    const {checked} = e.target;
    setAppObject(
      produce(draft => {
        draft.newVersion = checked ? 'N/A' : '';
        draft.currentVersion = checked ? 'N/A' : '';
        draft.versionNotRelevant = !notRelevant;
        draft.versionIsSame = true;
      })
    );
    setNotRelevant(!notRelevant);
  }

  const onChange = (e) => {
    const {name, value} = e.target;
    setAppObject(
      produce(draft => {
        draft[name] = value;
        draft.currentVersion = isSameVersion ? value : draft.currentVersion;
      })
    );
  };

  const flipVersion = (e) => {
    const {checked} = e.target;

    setIsSameVersion(!checked)
    setAppObject(
      produce(draft => {
        draft.versionIsSame = !checked;
      })
    );

    if (!checked) {
      setAppObject(
        produce(draft => {
          draft.currentVersion = '';
        })
      );
    }

  };

  const setLatest = (e) => {
    const {checked} = e.target;

    if (checked === false) {
      setAppObject(produce(draft => {
        draft.newVersion = '';
      }))
    } else {
      setAppObject(produce(draft => {
        draft.newVersion = 'Latest';
        draft.currentVersion = isSameVersion === false ? 'Latest' : draft.currentVersion;
      }))
    }
  }

  return (
    <AppStepCard key={step} appObject={appObject} setAppObject={setAppObject} setIsOpen={setIsOpen} step={step} setStep={setStep} newAppType={newAppType} progress={progress} setProgress={setProgress} header='What version of the app should be installed?' totalSteps={totalSteps} stepReady={true}>
      <Checkbox label='Not relevant for this application' className='step-not-relevant-check' checked={notRelevant} onChange={(e) => onNotRelevant(e)}/>
      <div className='step-input-checkbox'>
        <Input label='Specify a version' disabled={appObject?.newVersion === 'Latest' || notRelevant} name='newVersion' value={appObject?.newVersion} onChange={onChange}/>
        <Checkbox label='Latest version' disabled={notRelevant} checked={appObject?.newVersion === 'Latest'} onChange={(e) => setLatest(e)}/>
      </div>
      <Checkbox disabled={notRelevant} style={{marginBottom: 'var(--bfs12)'}} label={`This is not the same version as my organization use today`} button checked={appObject?.versionIsSame === false} onChange={(e) => flipVersion(e)}/>
      {appObject?.versionIsSame === false &&
        <Input disabled={notRelevant} label='What is the current version?' name='currentVersion' value={appObject?.currentVersion} onChange={onChange}/>
      }
    </AppStepCard>
  );
};

export default StepThree

StepThree.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  newAppType: PropTypes.string,
  appObject: PropTypes.object,
  setAppObject: PropTypes.func,
  progress: PropTypes.number,
  setProgress:PropTypes.func,
  setIsOpen:PropTypes.func,
  totalSteps: PropTypes.number
}