import React, { useEffect, useState } from "react";
import apiFetch from "../../../../../utils/apiFetch";
import Article from "@intility/publish-article";
import { Link, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import './publishArticle.scss';
import { Breadcrumbs } from "@intility/bifrost-react";
import { useSelector } from "react-redux";

export const PublishArticle = () => {
  const {id} = useSelector(state => state.company.data.info);
  const {articleId} = useParams();
  const [ data, setData ] = useState();
  const [fetching, setFetching] = useState(true)
  const location = useLocation();


  useEffect(() => {
    articleFetcher().finally(() => setFetching(false))
  }, [])

  const getBreadcrumbs = () => {
    if (location?.pathname?.split('/')[2] === 'services') {
      return [
        <Breadcrumbs.Item key='home'>
          <Link to={`/${id}`}>Home</Link>
        </Breadcrumbs.Item>,
        <Breadcrumbs.Item key='services'>
          <Link to={`/${id}/${location?.pathname?.split("/")[2]}`}>Services</Link>
        </Breadcrumbs.Item>
      ]
    }

    if (location?.state?.prevPath === undefined) {
      return (
        <Breadcrumbs.Item>
          <Link to={`/${id}`}>Home</Link>
        </Breadcrumbs.Item>
      );
    }

    const path = location?.state?.prevPath?.split('/');
    let link = path.pop();

    return (
      path.length === 3 ?
        [
          <Breadcrumbs.Item key={1}>
            {path[2].charAt(0).toUpperCase() + path[2].slice(1)}
          </Breadcrumbs.Item>,
          <Breadcrumbs.Item key={2}>
            <Link to={location?.state?.prevPath}>{link.charAt(0).toUpperCase() + link.slice(1)}</Link>
          </Breadcrumbs.Item>
        ]
        :
      <Breadcrumbs.Item>
        <Link to={location?.state?.prevPath}>{link.charAt(0).toUpperCase() + link.slice(1)}</Link>
      </Breadcrumbs.Item>
    )
  }

  const articleFetcher = async () => {
    apiFetch(`/Publish/${articleId}?language=en-us`).then(response => {
      return response.json();
    }).then(value => setData(JSON.parse(value)))
  };

  return (
    <div id='publish-article'>
      <Breadcrumbs style={{background: 'var(--bfc-base-2)', marginLeft: 'var(--bfs12)', marginTop: 'var(--bfs4)'}}>
        {getBreadcrumbs()}
        <Breadcrumbs.Item>{data?.title || 'Current page'}</Breadcrumbs.Item>
      </Breadcrumbs>
      <Article
        {...data}
        published={undefined}
        modified={undefined}
        categories={undefined}
        locale={'en-us'}
        loading={fetching}
        fetcher={(id) => apiFetch(`/Publish/images/${id}?language=en-us`)}
      />
    </div>
  );
};

export const PublishArticleDrawer = ({articleId}) => {
  const [ data, setData ] = useState();
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    if (articleId !== null && articleId !== undefined)
      articleFetcher().finally(() => setFetching(false))
  }, [articleId])

  const articleFetcher = async () => {
    apiFetch(`/Publish/${articleId}?language=en-us`).then(response => {
      return response.json();
    }).then(value => setData(JSON.parse(value)))
  };

  return (
    <div id='drawer-publish-article'>
      <Article
        {...data}
        published={undefined}
        modified={undefined}
        categories={undefined}
        locale={'en-us'}
        loading={fetching}
        fetcher={(id) => apiFetch(`/Publish/images/${id}?language=en-us`)}
      />
    </div>
  );
};

PublishArticleDrawer.propTypes = {
  articleId: PropTypes.string
}
