import {createReducer, createSlice} from '@reduxjs/toolkit';
import {
  fetchComputerSetupSuccess,
  updateComputerSetupInformationSuccess,
  updateComputerSetupSuccess
} from './computerSetupActions';
import {fetchCompanySuccess} from '../companyFetchSlice';
import {updateCompanyStateSuccess, updateCompanySuccess} from '../companyUpdateSlice';

const updateOnComputerSettingsChangeReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanySuccess]: (state, action) => getState(action.payload, state),
    [fetchComputerSetupSuccess]: (state, action) => getState(action.payload, state),
    [updateComputerSetupSuccess]: (state, action) => getState(action.payload, state),
    [updateComputerSetupInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const desktopSettingsReducer = createReducer(
  {
    id: null,
    companyId: null,
    isEnabled: true,
    type: 0,
    chosenWallpaper: null,
    modifiedAt: '',
    iamsMacUrl: '',
    screensaverTimeout: null,
    officeBitVersion: '',
    osLanguage: '',
    hasAppLocker: null,
    hasHardenedOffice: null,
    hasHardenedBrowser: null,
    hasHardenedWindows: null,
    hasBitLockerPin: null,
    hasBiometricAuth: null,
    has4G5G: null,
    hasTouch: null,
    hasPrivacy: null,
    hasNumpad: null,
    hasWarrantyUpgrade: null,
  },
  updateOnComputerSettingsChangeReducer((payload, state) => {
    state.customWallpaper = payload.computerSetupPlatform.desktopSettings.customWallpaper;
    state.type = payload.computerSetupPlatform.desktopSettings.defaultBackground;
    state.chosenWallpaper = payload.computerSetupPlatform.desktopSettings.chosenWallpaper;
    state.screensaverTimeout = payload.computerSetupPlatform.desktopSettings.screensaverTimeout;
    state.officeBitVersion = payload.computerSetupPlatform.desktopSettings.officeBitVersion;
    state.osLanguage = payload.computerSetupPlatform.desktopSettings.osLanguage;
    state.wallpaperLink = payload.computerSetupPlatform.desktopSettings.wallpaperLink;
    state.hasAppLocker = payload.computerSetupPlatform.desktopSettings.hasAppLocker;
    state.hasHardenedOffice = payload.computerSetupPlatform.desktopSettings.hasHardenedOffice;
    state.hasHardenedBrowser = payload.computerSetupPlatform.desktopSettings.hasHardenedBrowser;
    state.hasHardenedWindows = payload.computerSetupPlatform.desktopSettings.hasHardenedWindows;
    state.hasBitLockerPin = payload.computerSetupPlatform.desktopSettings.hasBitLockerPin;
    state.hasBiometricAuth = payload.computerSetupPlatform.desktopSettings.hasBiometricAuth;
    state.has4G5G = payload.computerSetupPlatform.desktopSettings.has4G5G;
    state.hasTouch = payload.computerSetupPlatform.desktopSettings.hasTouch;
    state.hasPrivacy = payload.computerSetupPlatform.desktopSettings.hasPrivacy;
    state.hasNumpad = payload.computerSetupPlatform.desktopSettings.hasNumpad;
    state.hasWarrantyUpgrade = payload.computerSetupPlatform.desktopSettings.hasWarrantyUpgrade;
    state.id = payload.computerSetupPlatform.desktopSettings.id;
    state.iamsMacUrl = payload.computerSetupPlatform.desktopSettings.iamsMacUrl;
    state.isEnabled = payload.computerSetupPlatform.desktopSettings.isEnabled;
    state.companyId = payload.computerSetupPlatform.desktopSettings.companyId;
    state.modifiedAt = payload.computerSetupPlatform.desktopSettings.modifiedAt;
  })
);


const updateOnComputerSettingsStateReducer = getState => {
  return {
    [fetchCompanySuccess]: (state, action) => getState(action.payload, state),
    [updateCompanyStateSuccess]: (state, action) => getState(action.payload, state),
    [fetchComputerSetupSuccess]: (state, action) => getState(action.payload, state),
    [updateComputerSetupInformationSuccess]: (state, action) => getState(action.payload, state)
  };
};

export const desktopSettingsStateReducer = createReducer(
  0,
  updateOnComputerSettingsStateReducer((payload, state) => {
    state = payload.computerSetupPlatform.state;
    return state;
  })
);

export const computerSetupStatusSlice = createSlice({
  name: 'computerSetupAction',
  initialState: {
    fetching: false,
    updating: false,
    error: false
  },
  reducers: {
    fetchComputerSetup: state => {
      state.fetching = true;
    },
    fetchComputerSetupError: state => {
      state.fetching = false;
      state.error = true;
    },
    updateComputerSetup: state => {
      state.updating = true;
    },
    updateComputerSetupError: state => {
      state.updating = false;
      state.error = true;
    }
  },
  extraReducers: {
    [fetchComputerSetupSuccess]: state => {
      state.fetching = false;
      state.updating = false;
      state.error = false;
    },
    [updateComputerSetupSuccess]: state => {
      state.updating = false;
      state.error = false;
    },
    [updateComputerSetupInformationSuccess]: state => {
      state.updating = false;
      state.error = false;
    }
  }
});

export const {
  fetchComputerSetup,
  fetchComputerSetupError,
  updateComputerSetup,
  updateComputerSetupError
} = computerSetupStatusSlice.actions;
