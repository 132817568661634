import React, { useEffect, useRef, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import './domains.scss';
import BasicPage from '../BasicPage';
import DomainBox from './DomainBox/DomainBox';
import { SimpleBox } from "../../../Box/Box";
import { Button, Grid, Modal, Table } from "@intility/bifrost-react";
import AddDomainSlider from './AddDomainSlider/AddDomainSlider';
import { toggleService } from "../../../../redux/company/companyThunks";
import { downloadDomainCsv, fetchDomainsAsync, uploadDomainCsv } from "../../../../redux/company/domain/domainThunks";
import {platformType} from '../../../../redux/company/platformUpdateThunk';
import { faFileCsv, faPlus, faFileUpload } from "@fortawesome/pro-regular-svg-icons";
import { DomainGuideModal } from "../Users/GuideModal/GuideModal";
import { getFilesArray } from "../../../FileUploader/CsvUploader";
import ErrorModal, { DomainError } from "../Users/ErrorModal/ErrorModal";
import { updateDomainsClear } from "../../../../redux/company/domain/domainReducers";
import DomainCard from "./DomainCard/DomainCard";
import { taskType } from "../../../../utils/hub/pageData/pageDataOptions";

const Domains = () => {
  const {domainList, information, domainStatus} = useSelector(state => state.company.data.domainPlatform);
  const {id} = useSelector(state => state.company.data.info);
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentDomainId, setCurrentDomainId] = useState(false);
  const [sort, setSort] = useState({key: 'name', direction: 'asc'});
  const [errorOpen, setErrorOpen] = useState(false);
  const fileRef = useRef(null);
  const [replace, setReplace] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  useEffect(() => {
    setErrorOpen(domainStatus.uploadError);
  }, [domainStatus.uploadError]);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchDomainsAsync(id));
  }, [user, dispatch, id]);

  const toggleDomains = value => {
    setIsComplete(value);
    dispatch(toggleService(platformType.DOMAINS, isComplete));
  };
  const addNewDomain = () => {
    setCurrentDomainId(null);
    setIsOpen(true);
  };

  const upload = () => {
    if (domainList.length > 0) {
      setShowCsvModal(true);
    } else {
      fileRef.current && fileRef.current.click();
    }
  };

  const handleFileUpload = e => {
    const filesAreAttached = e.target.value?.length > 0;
    if (filesAreAttached) {
      dispatch(uploadDomainCsv(e.target.value[0], replace));
    }
  };

  const onFileSelect = e => {
    return handleFileUpload({
      ...e,
      target: {
        ...e.target,
        name,
        value: getFilesArray(e.target.files)
      }
    });
  };

  const filteredList = domainList && [...domainList].sort((a, b) => {
    if (sort.direction === 'asc') {
      if (sort.key === 'contact') return a?.domainContact?.firstName + ' ' + a?.domainContact?.lastName - b?.domainContact?.firstName + ' ' + b?.domainContact?.lastName;
      if (!a[sort.key] || a[sort.key] === null) return 1;
      if (!b[sort.key] || b[sort.key] === null) return -1;
      return a[sort.key].toString()?.localeCompare(b[sort.key].toString());
    } else {
      if (sort.key === 'contact') return b?.domainContact?.firstName + ' ' + b?.domainContact?.lastName - a?.domainContact?.firstName + ' ' + a?.domainContact?.lastName;
      if (!b[sort.key] || b[sort.key] === null) return 1;
      if (!a[sort.key] || a[sort.key] === null) return -1;
      return b[sort.key].toString()?.localeCompare(a[sort.key].toString());
    }
    }).sort((a, b) => {
    if (a.id === information.primaryId) {
      return -1
    }
    return 1
  });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const onSortChangeCreator = key => () => {
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });
  };

  return (
    <BasicPage id='domains' taskType={taskType.DOMAINS}
               isComplete={isComplete}
               setIsComplete={toggleDomains}>
      <div className='content-main bf-content'>
        <SimpleBox
          className='domain-box'
          title='Domains'
          buttonIcon={faFileCsv}
          buttonText='Download Excel'
          buttonClick={() => dispatch(downloadDomainCsv())}
          intilityButton={true}
        >
          <Grid cols={2} small={1} large={2} style={{margin: 'var(--bfs16) 0'}} gap={16}>
            <DomainCard header='Multiple domains?' button={<Button disabled={isComplete} variant={"outline"} icon={faFileUpload} onClick={() => upload()}>Add domain list (CSV upload)</Button>} icon={faFileCsv}>
              <span className='bf-p'>Use our {<DomainGuideModal text={` CSV template (guide and download) `} />} and upload it to the table.</span>
            </DomainCard>
            <DomainCard header='Add new domain' button={<Button disabled={isComplete} onClick={() => addNewDomain()} variant={"filled"} icon={faPlus}>Add new domain</Button>} icon={faPlus}>
              <p>The following domain(s) is registered. Please add additional addresses if applicable.</p>
            </DomainCard>
          </Grid>
          <input
            key={1}
            type='file'
            ref={fileRef}
            multiple={false}
            accept={'.csv'}
            onChange={onFileSelect}
            style={{display: 'none'}}
          />
          {domainList && domainList.length > 0 && (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>Address</Table.HeaderCell>
                  <Table.HeaderCell className='from-medium' sorting={getSortProp('registrar')} onClick={onSortChangeCreator('registrar')}>Registrar</Table.HeaderCell>
                  <Table.HeaderCell className='from-xl' sorting={getSortProp('contact')} onClick={onSortChangeCreator('contact')}>Contact</Table.HeaderCell>
                  <Table.HeaderCell className='from-small' sorting={getSortProp('isEmailDomain')} onClick={onSortChangeCreator('isEmailDomain')}>E-mail domain</Table.HeaderCell>
                  <Table.HeaderCell className='from-small' sorting={getSortProp('isWebDomain')} onClick={onSortChangeCreator('isWebDomain')}>Web domain</Table.HeaderCell>
                  <Table.HeaderCell sorting={getSortProp('moveToIntility')} onClick={onSortChangeCreator('moveToIntility')}>Move to Intility</Table.HeaderCell>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredList.map(domain => {
                  return (
                    <DomainBox
                      key={domain.name}
                      domain={domain}
                      primaryId={information.primaryId}
                      toggleEdit={setIsOpen}
                      setDomainId={setCurrentDomainId}
                      isComplete={isComplete}
                    />
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </SimpleBox>
      </div>
      <AddDomainSlider isOpen={isOpen} setIsOpen={setIsOpen} domainId={currentDomainId} />
      <ErrorModal
        isOpen={errorOpen}
        onClose={() => dispatch(updateDomainsClear())}
        type={DomainError}
      />
      <Modal isOpen={showCsvModal} header={`Upload domains`} key={2} onRequestClose={() => setShowCsvModal(false)}>
        <p>
          Uploading a CSV file with domains will add them to your current domain list.
        </p>
        <div className='csv-uploader-footer'>
          <div/>
          <Button
            variant={'filled'}
            onClick={() => {
              setShowCsvModal(false);
              setReplace(false);
              fileRef.current && fileRef.current.click();
            }}
          >
            Add to list
          </Button>
        </div>
      </Modal>
    </BasicPage>
  );
};

export default Domains;
