import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './wallpaperpicker.scss';
import FileUploader from 'components/FileUploader/FileUploader';
import {useDispatch, useSelector} from 'react-redux';
import { setSetupValue, uploadWallpaper } from "redux/company/computerSetup/computerSetupThunks";
import WallpaperOption from '../WallpaperOption/WallpaperOption';
import InitlityDefaultWallpaper from 'assets/images/intility-default-wallpaper.png';
import { Badge, Button, Input, useFloatingMessage } from "@intility/bifrost-react";
import produce from "immer";

const WallpaperPicker = ({customWallpaper, canEdit, disabled}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {desktopSettings} = useSelector(state => state.company.data.computerSetupPlatform);
  const [editSettings, setEditSettings] = useState({});
  const { showFloatingMessage } = useFloatingMessage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (desktopSettings) {
      setEditSettings(desktopSettings);
    }
  }, [desktopSettings]);

  const onChange = (e) => {
    const {value} = e.target;
    setEditSettings(
      produce(draft => {
        draft.wallpaperLink = value;
      })
    );
  };

  const onSave = () => {
    dispatch(setSetupValue('wallpaperLink', editSettings.wallpaperLink)).then(() => showFloatingMessage("Value saved"))
  }

  return (
    <div className='wallpaper-picker'>
      <Badge state={"warning"}>Customized wallpaper requires Enterprise OS licenses from Microsoft. This option may trigger additional monthly costs</Badge>
      <h5>Please select your desired wallpaper</h5>
      <div className='wallpaper-picker-container'>
        <WallpaperOption
          wallpaper={InitlityDefaultWallpaper}
          isDefault={true}
          disabled={disabled}
          type={0}
          title='Intility Default Wallpaper'
          canEdit={canEdit}
        />
        {customWallpaper && (
          <WallpaperOption
            wallpaper={customWallpaper}
            isDefault={false}
            disabled={disabled}
            title='Custom'
            canEdit={canEdit}
          />
        )}
      </div>
      {desktopSettings?.type === 2 && (
        <div className='wallpaper-picker-button-row'>
          <Input label='Link to wallpaper' onChange={e => onChange(e)} disabled={disabled} value={editSettings?.wallpaperLink} />
          <Button disabled={desktopSettings?.wallpaperLink === editSettings?.wallpaperLink} variant={"filled"} onClick={() => onSave()}>Save</Button>
        </div>
      )}
    </div>
  );
};

WallpaperPicker.propTypes = {
  customWallpaper: PropTypes.shape({
    imageUri: PropTypes.string,
    objectName: PropTypes.string
  }),
  canEdit: PropTypes.bool,
  disabled: PropTypes.bool
};

export default WallpaperPicker;
