import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './applications.scss';
import ApplicationBox, {getResponsibility} from './ApplicationBox/ApplicationBox';
import {
  downloadCsv,
  fetchApplicationsAsync,
  seedIamsApplicationsAsync,
  seedIamsApplicationUsersAsync
} from '../../../../redux/company/applications/applicationThunks';
import {SimpleBox} from '../../../Box/Box';
import {toggleService} from '../../../../redux/company/companyThunks';
import {
  Button,
  Icon,
  Input,
  Pagination,
  Spinner,
  Tabs,
  Table,
  Tooltip,
  useBreakpoint,
  useFloatingMessage,
  Tag,
  SlideDown,
  FieldGroup
} from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import ErrorModal, {ApplicationError} from '../Users/ErrorModal/ErrorModal';
import {updateApplicationsClear} from '../../../../redux/company/applications/applicationReducers';
import BasicPage from '../BasicPage';
import {platformType} from '../../../../redux/company/platformUpdateThunk';
import UserApplicationSlider from './UserApplicationSlider/UserApplicationSlider';
import {fetchUsersAsync} from '../../../../redux/company/users/userThunks';
import {faSearch} from '@fortawesome/pro-solid-svg-icons';
import {fetchLocationsAsync} from '../../../../redux/company/locations/locationThunks';
import {
  appTypeOptions,
  selectApps,
  freewareOptions,
  appValidationOptions,
  ApplicationRowOptions,
  ApplicationSearchOptions,
  appStatusOptions
} from './ApplicationOptions';
import {ImportCard, SaaSCard, ServerCard} from './ApplicationCard/ApplicationCard';
import {
  faAdd,
  faAngleDown,
  faAngleUp,
  faFileExport,
  faQuestionCircle,
  faTimes
} from '@fortawesome/pro-regular-svg-icons';
import produce from 'immer';
import EmptyTableText from './EmptyTableText';
import NewAppSteps from './NewApplication/NewAppSteps';
import {taskType} from '../../../../utils/hub/pageData/pageDataOptions';
import SimpleApplicationBox from './ApplicationBox/SimpleApplicationBox';
import {components} from 'react-select';
import {Link} from 'react-router-dom';

export const PortfolioApps = 'PortfolioApps';
export const IamsApps = 'IamsApps';
export const Microsoft365Apps = 'Microsoft365Apps';
export const FreewareApps = 'FreewareApps';
export const ManualApps = 'ManualApps';
export const ManualOrigin = 0;
export const IamsOrigin = 1;
export const Microsoft365Origin = 2;
export const FreewareOrigin = 3;
export const AnyOrigin = 4;

export const getOrigin = e => {
  switch (e) {
    case 0:
      return 'User added';
    case 1:
      return 'IAMS';
    case 2:
      return 'Microsoft365';
    case 3:
      return 'Freeware';
    default:
      return 'Unknown';
  }
};

const Applications = () => {
  const {applicationList, information, applicationStatus} = useSelector(
    state => state.company.data.applicationPlatform
  );

  const {id} = useSelector(state => state.company.data.info);
  const [isOpen, setIsOpen] = useState(false);
  const [newAppType, setNewAppType] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [userSliderOpen, setUserSliderOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentApplicationId, setCurrentApplicationId] = useState(false);
  const {user} = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({value: 10, label: '10'});
  const [searchValue, setSearchValue] = useState('');
  const [originFilter, setOriginFilter] = useState(AnyOrigin);
  const [active, setActive] = useState(PortfolioApps);
  const [appValidationFilter, setAppValidationFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [searchInclude, setSearchInclude] = useState({label: 'Includes', value: true});
  const [searchCriterea, setSearchCriterea] = useState({
    value: 'name',
    label: 'Name'
  });
  const [applicationRows, setApplicationRows] = useState(ApplicationRowOptions.slice(0, 11));
  const [applicationTableRows, setApplicationTableRows] = useState(ApplicationRowOptions.slice(0, 11));
  const [open, setOpen] = useState(false);
  const [seedDispatched, setSeedDispatched] = useState(false);
  const dispatch = useDispatch();
  const {showFloatingMessage} = useFloatingMessage();
  const [sort, setSort] = useState({
    key: 'name',
    direction: 'asc'
  });
  const fromSmall = useBreakpoint('small', null);
  const toLarge = useBreakpoint(null, 'large');
  const fromMedium = useBreakpoint('medium', null);

  useEffect(() => {
    if (active === PortfolioApps) {
      setPageSize({value: 40, label: '40'});
    }
  }, []);

  useEffect(() => {
    const filters = window.localStorage[`applicationFilters${id}`]
      ? JSON.parse(window.localStorage[`applicationFilters${id}`])
      : [];
    setFilterList(filters);
    if (window.localStorage[`applicationFilters${id}`] && filters.length > 0) {
      setOpen(true);
    }
    const validationFilter = filters?.find(f => f.search === 'inPortfolio');
    const userRowFilter = filters?.find(f => f.search === 'selectedRows');
    if (validationFilter !== undefined) {
      setAppValidationFilter({value: validationFilter.value, label: validationFilter.displayName, type: 'inPortfolio'});
    }
    if (userRowFilter !== undefined) {
      setApplicationRows(JSON.parse(userRowFilter.rows));
      setApplicationTableRows(JSON.parse(userRowFilter.rows));
    }
  }, [id]);

  const handleChange = (e, item) => {
    e.preventDefault();
    setActive(item);
    setStatusFilter([]);
    setPage(1);
    setSort({key: 'name', direction: 'asc'});
    if (item === PortfolioApps) {
      setPageSize({value: 40, label: '40'});
      setOriginFilter(AnyOrigin);
    } else if (item === IamsApps) {
      setOriginFilter(IamsOrigin);
    } else if (item === Microsoft365Apps) {
      setOriginFilter(Microsoft365Origin);
    } else if (item === FreewareApps) {
      setOriginFilter(FreewareOrigin);
    } else if (item === ManualApps) {
      setOriginFilter(ManualOrigin);
    }
  };

  const onSortChangeCreator = key => () =>
    setSort(oldSort => {
      if (oldSort.key === key && oldSort.direction === 'asc') {
        return {key, direction: 'desc'};
      } else {
        return {key, direction: 'asc'};
      }
    });

  const getSortProp = key => (sort.key === key ? sort.direction : 'none');

  const showTabContent = activeTab => {
    switch (activeTab) {
      case PortfolioApps:
        return tableContent(AnyOrigin);
      case IamsApps:
        return tableContent(IamsOrigin);
      case Microsoft365Apps:
        return microsoftTableContent();
      case FreewareApps:
        return freewareTableContent();
      case ManualApps:
        return tableContent(ManualOrigin);
    }
  };

  const sortedData = [...applicationList]
    ?.filter(s => {
      if (searchValue === '' || searchValue === undefined) return s;
      if (searchCriterea?.value === 'origin') {
        if (
          getOrigin(s[searchCriterea?.value])
            ?.toLowerCase()
            ?.includes(searchValue?.toString().toLowerCase()) !== searchInclude.value
        )
          return;
        return s;
      } else if (searchCriterea?.value === 'responsible') {
        if (
          getResponsibility(s.readyStatus)
            ?.toLowerCase()
            ?.includes(searchValue?.toString().toLowerCase()) !== searchInclude.value
        )
          return;
        return s;
      } else if (searchCriterea?.value === 'applicationType') {
        return s;
      }
      return s[searchCriterea?.value]
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchValue?.toString()?.toLowerCase());
    })
    ?.filter(a => {
      if (active !== PortfolioApps) return a.origin === originFilter;
      else return a.isEnabled;
    })
    .sort((a, b) => {
      if (sort.direction === 'asc') {
        if (Number.isInteger(a[sort.key]) && Number.isInteger(b[sort.key])) return a[sort.key] - b[sort.key];
        if (sort.key === 'modifiedAt') return new Date(a[sort.key]) - new Date(b[sort.key]);
        if (sort.key === 'responsible') return a.readyStatus - b.readyStatus;
        if (sort.key === 'companyUsers' && a.origin === 3) return 1;
        if (sort.key === 'companyUsers' && b.origin === 3) return 0;
        if (sort.key === 'companyUsers')
          return (
            a[sort.key]?.filter(u => u.isDisabled === false)?.length -
            b[sort.key]?.filter(u => u.isDisabled === false)?.length
          );
        if (sort.key === 'isEnabled') return a?.isEnabled === true;
        return a[sort.key]?.localeCompare(b[sort.key]);
      } else {
        if (Number.isInteger(b[sort.key]) && Number.isInteger(a[sort.key])) return b[sort.key] - a[sort.key];
        if (sort.key === 'modifiedAt') return new Date(b[sort.key]) - new Date(a[sort.key]);
        if (sort.key === 'responsible') return b.readyStatus - a.readyStatus;
        if (sort.key === 'companyUsers' && b.origin === 3) return 1;
        if (sort.key === 'companyUsers' && a.origin === 3) return 0;
        if (sort.key === 'companyUsers')
          return (
            b[sort.key]?.filter(u => u.isDisabled === false)?.length -
            a[sort.key]?.filter(u => u.isDisabled === false)?.length
          );
        if (sort.key === 'isEnabled') return b?.isEnabled === true;
        return b[sort.key]?.localeCompare(a[sort.key]);
      }
    })
    .filter(s => {
      let test = true;
      filterList.forEach(f => {
        if (f?.search === 'selectedRows') return;
        if (f?.search === 'inPortfolio' && f?.value === 'all') return;
        if (f?.search === 'inPortfolio') {
          if ((s[f?.search] === f?.value) !== f.includes) test = false;
          return;
        }
        if (f?.search === 'origin') {
          if (
            getOrigin(s[f?.search])
              ?.toLowerCase()
              ?.includes(f?.value?.toLowerCase()) !== f.includes
          )
            test = false;
          return;
        }
        if (f?.search === 'readyStatus' && f?.value === 'all') return;
        if (f?.search === 'readyStatus') {
          if ((s[f?.search] === f?.value) !== f.includes) test = false;
          return;
        }
        if (f?.search === 'responsible') {
          if (
            getResponsibility(s.readyStatus)
              ?.toLowerCase()
              ?.includes(f.value?.toString().toLowerCase()) !== f.includes
          )
            test = false;
          return;
        }
        if (f?.search === 'applicationType') {
          if (f.value === 'other' && s.serverApp + s.clientApp + s.saasApp === 0) {
            test = false;
          } else if ((s[f.value] !== 1) === f.includes) {
            test = false;
          }
          return;
        }
        if ((s[f?.search] === null || s[f?.search] === undefined) && f.includes === true) return (test = false);
        if (s[f?.search]?.toLowerCase()?.includes(f?.value?.toLowerCase()) !== f.includes) test = false;
        if ((s[f?.search] === null || s[f?.search] === undefined) && f.includes === false) test = true;
      });
      return test;
    });

  const tableSizeOptions = () => {
    const tableOptions = [{value: 10, label: '10'}];
    let counter = 20;
    sortedData &&
      sortedData.forEach((a, i) => {
        if (i >= counter) {
          tableOptions.push({value: counter, label: `${counter}`});
          counter = counter * 2;
        }
      });
    tableOptions.push({value: applicationList?.length, label: 'All'});
    return tableOptions;
  };

  useEffect(() => {
    setIsComplete(!information.isEnabled);
  }, [information]);

  useEffect(() => {
    setErrorOpen(applicationStatus.uploadError);
  }, [applicationStatus.uploadError]);

  useEffect(() => {
    if (applicationStatus.updateError)
      showFloatingMessage('An error occurred updating application', {state: 'warning'});
  }, [applicationStatus.updateError]);

  useEffect(() => {
    if (applicationStatus.addError) showFloatingMessage('An error occurred adding the application', {state: 'warning'});
  }, [applicationStatus.addError]);

  const toggleApplications = value => {
    setIsComplete(value);
    dispatch(toggleService(platformType.APPLICATIONS, isComplete));
  };

  const addNewApplication = applicationType => {
    setCurrentApplicationId(null);
    setNewAppType(applicationType);
    setIsOpen(true);
  };

  const addFilter = filter => {
    if (
      (filter?.search === 'inPortfolio' || filter?.search === 'selectedRows') &&
      filterList.some(s => s?.search === filter?.search)
    ) {
      const index = filterList.findIndex(s => s?.search === filter?.search);
      const newFilterList = produce(filterList, draft => {
        draft[index] = {...filter};
      });
      setFilterList(newFilterList);
      setPage(1);
      localStorage.setItem(`applicationFilters${id}`, JSON.stringify(newFilterList));
      return;
    }
    if (filter?.search === 'userTypeFilter' && filterList.some(s => s?.search === filter?.search)) return;
    const newFilterList = [...filterList, filter];
    setFilterList(newFilterList);
    setPage(1);
    setSearchValue('');
    localStorage.setItem(`applicationFilters${id}`, JSON.stringify(newFilterList));
  };

  const changeTypeFilter = filter => {
    let newFilterList;
    const index = filterList.findIndex(obj => obj.search === filter?.search);
    if (index > -1) {
      newFilterList = produce(filterList, draft => {
        draft[index] = {...filter};
      });
    } else {
      newFilterList = [...filterList, filter];
    }
    setFilterList(newFilterList);
    setPage(1);
    localStorage.setItem(`applicationFilters${id}`, JSON.stringify(newFilterList));
  };

  const removeFilter = filter => {
    if (filter?.search === 'inPortfolio' && filterList.some(s => s?.search === filter?.search)) {
      const newFilterList = filterList.filter(f => {
        return f?.search !== filter?.search;
      });
      setFilterList(newFilterList);
      setAppValidationFilter([]);
      localStorage.setItem(`applicationFilters${id}`, JSON.stringify(newFilterList));
    } else if (filter.search === 'selectedRows') {
      const newFilterList = filterList.filter(f => {
        return f?.search !== filter?.search;
      });
      setFilterList(newFilterList);
      localStorage.setItem(`applicationFilters${id}`, JSON.stringify(newFilterList));
      setApplicationRows(ApplicationRowOptions.slice(0, 11));
      setApplicationTableRows(ApplicationRowOptions.slice(0, 11));
    } else if (filterList.some(s => s === filter)) {
      const newFilterList = filterList.filter(f => {
        return f !== filter;
      });
      setFilterList(newFilterList);
      localStorage.setItem(`applicationFilters${id}`, JSON.stringify(newFilterList));
    }
  };

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchApplicationsAsync(id));
    dispatch(fetchUsersAsync(id));
    dispatch(fetchLocationsAsync(id));
  }, [user, dispatch, id]);

  const indexOfLastApp = page * pageSize.value;
  const indexOfFirstApp = indexOfLastApp - pageSize.value;
  const sortedAndIndexedData = sortedData && sortedData.slice(indexOfFirstApp, indexOfLastApp);

  const microsoftTableContent = () => {
    const microsoftApps = applicationList.filter(a => a.origin === Microsoft365Origin);
    const tableApps = selectApps
      .map(item => {
        const found = microsoftApps.find(s => s.name === item.name);
        if (found) {
          return {...found, image: item.image};
        }
        return item;
      })
      ?.filter(s => {
        if (searchValue === '' || searchValue === undefined) return s;
        if (searchCriterea?.value === 'origin') {
          if (
            getOrigin(s[searchCriterea?.value])
              ?.toLowerCase()
              ?.includes(searchValue?.toString().toLowerCase()) !== searchInclude.value
          )
            return;
          return s;
        } else if (searchCriterea?.value === 'responsible') {
          if (
            getResponsibility(s.readyStatus)
              ?.toLowerCase()
              ?.includes(searchValue?.toString().toLowerCase()) !== searchInclude.value
          )
            return;
          return s;
        } else if (searchCriterea?.value === 'applicationType') {
          return s;
        }
        return s[searchCriterea?.value]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchValue?.toString()?.toLowerCase());
      })
      ?.sort((a, b) => {
        if (sort.direction === 'asc') {
          if (sort.key === 'origin' || sort.key === 'inPortfolio') return a[sort.key] - b[sort.key];
          return a[sort.key]?.localeCompare(b[sort.key]);
        } else {
          if (sort.key === 'origin' || sort.key === 'inPortfolio') return b[sort.key] - a[sort.key];
          return b[sort.key]?.localeCompare(a[sort.key]);
        }
      })
      .filter(s => {
        let test = true;
        filterList.forEach(f => {
          if (f?.search === 'selectedRows') return;
          if (f?.search === 'inPortfolio' && f?.value === 'all') return;
          if (f?.search === 'inPortfolio') {
            if ((s[f?.search] === f?.value) !== f.includes) test = false;
            return;
          }
          if (f?.search === 'origin') {
            if (
              getOrigin(s[f?.search])
                ?.toLowerCase()
                ?.includes(f?.value?.toLowerCase()) !== f.includes
            )
              test = false;
            return;
          }
          if (f?.search === 'readyStatus' && f?.value === 'all') return;
          if (f?.search === 'readyStatus') {
            if ((s[f?.search] === f?.value) !== f.includes) test = false;
            return;
          }
          if (f?.search === 'applicationType') {
            if (f.value === 'other' && s.serverApp + s.clientApp + s.saasApp === 0) {
              test = false;
            } else if ((s[f.value] !== 1) === f.includes) {
              test = false;
            }
            return;
          }
          if ((s[f?.search] === null || s[f?.search] === undefined) && f.includes === true) return (test = false);
          if (s[f?.search]?.toLowerCase()?.includes(f?.value?.toLowerCase()) !== f.includes) test = false;
          if ((s[f?.search] === null || s[f?.search] === undefined) && f.includes === false) test = true;
        });
        return test;
      });

    return (
      <div className='application-table'>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={active === PortfolioApps ? {minWidth: '40%'} : {minWidth: '70%'}}
                sorting={getSortProp('name')}
                onClick={onSortChangeCreator('name')}
              >
                Application
              </Table.HeaderCell>
              <Table.HeaderCell
                className='application-button-row'
                sorting={getSortProp('inPortfolio')}
                onClick={onSortChangeCreator('inPortfolio')}
              >
                Add to portfolio?
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableApps.map((app, i) => (
              <SimpleApplicationBox key={i} application={app} origin={Microsoft365Origin} isComplete={isComplete} />
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const freewareTableContent = () => {
    const freewareApps = applicationList.filter(a => a.origin === FreewareOrigin);
    const tableApps = freewareOptions
      .map(item => {
        const found = freewareApps.find(s => s.name === item.name);
        if (found) {
          return {...found, image: item.image};
        }
        return item;
      })
      ?.filter(s => {
        if (searchValue === '' || searchValue === undefined) return s;
        if (searchCriterea?.value === 'origin') {
          if (
            getOrigin(s[searchCriterea?.value])
              ?.toLowerCase()
              ?.includes(searchValue?.toString().toLowerCase()) !== searchInclude.value
          )
            return;
          return s;
        } else if (searchCriterea?.value === 'responsible') {
          if (
            getResponsibility(s.readyStatus)
              ?.toLowerCase()
              ?.includes(searchValue?.toString().toLowerCase()) !== searchInclude.value
          )
            return;
          return s;
        } else if (searchCriterea?.value === 'applicationType') {
          return s;
        }
        return s[searchCriterea?.value]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchValue?.toString()?.toLowerCase());
      })
      ?.sort((a, b) => {
        if (sort.direction === 'asc') {
          if (sort.key === 'origin' || sort.key === 'inPortfolio') return a[sort.key] - b[sort.key];
          return a[sort.key]?.localeCompare(b[sort.key]);
        } else {
          if (sort.key === 'origin' || sort.key === 'inPortfolio') return b[sort.key] - a[sort.key];
          return b[sort.key]?.localeCompare(a[sort.key]);
        }
      })
      .filter(s => {
        let test = true;
        filterList.forEach(f => {
          if (f?.search === 'selectedRows') return;
          if (f?.search === 'inPortfolio' && f?.value === 'all') return;
          if (f?.search === 'inPortfolio') {
            if ((s[f?.search] === f?.value) !== f.includes) test = false;
            return;
          }
          if (f?.search === 'origin') {
            if (
              getOrigin(s[f?.search])
                ?.toLowerCase()
                ?.includes(f?.value?.toLowerCase()) !== f.includes
            )
              test = false;
            return;
          }
          if (f?.search === 'readyStatus' && f?.value === 'all') return;
          if (f?.search === 'readyStatus') {
            if ((s[f?.search] === f?.value) !== f.includes) test = false;
            return;
          }
          if (f?.search === 'applicationType') {
            if (f.value === 'other' && s.serverApp + s.clientApp + s.saasApp === 0) {
              test = false;
            } else if ((s[f.value] !== 1) === f.includes) {
              test = false;
            }
            return;
          }
          if ((s[f?.search] === null || s[f?.search] === undefined) && f.includes === true) return (test = false);
          if (s[f?.search]?.toLowerCase()?.includes(f?.value?.toLowerCase()) !== f.includes) test = false;
          if ((s[f?.search] === null || s[f?.search] === undefined) && f.includes === false) test = true;
        });
        return test;
      });

    return (
      <div className='application-table'>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorting={getSortProp('name')} onClick={onSortChangeCreator('name')}>
                Application
              </Table.HeaderCell>
              {fromMedium && <Table.HeaderCell>Users</Table.HeaderCell>}
              <Table.HeaderCell
                className='application-button-row'
                sorting={getSortProp('inPortfolio')}
                onClick={onSortChangeCreator('inPortfolio')}
              >
                Add to portfolio?
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableApps.map((app, i) => (
              <SimpleApplicationBox key={i} application={app} origin={FreewareOrigin} isComplete={isComplete} />
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const tableContent = origin => {
    const dispatch = useDispatch();

    if (origin === IamsOrigin && !seedDispatched) {
      setSeedDispatched(true);
      dispatch(seedIamsApplicationsAsync()).then(() => dispatch(seedIamsApplicationUsersAsync()));
    }

    return applicationStatus.seeding ? (
      <Spinner overlay size={80} label='Synchronizing applications…' />
    ) : (
      <div className='application-table'>
        {applicationList && applicationList?.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {active === PortfolioApps
                  ? applicationTableRows.map((r, i) => {
                      if (r.value === 'name') {
                        return (
                          <Table.HeaderCell
                            key={i}
                            sorting={getSortProp(r.value)}
                            onClick={onSortChangeCreator(r.value)}
                          >
                            {r.label}
                          </Table.HeaderCell>
                        );
                      } else {
                        return (
                          <Table.HeaderCell
                            key={i}
                            sorting={getSortProp(r.value)}
                            onClick={onSortChangeCreator(r.value)}
                            className={
                              i < 3
                                ? 'from-small'
                                : i < 6
                                ? 'from-medium'
                                : i < 9
                                ? 'from-large'
                                : i < 12
                                ? 'from-xl'
                                : 'from-xxl'
                            }
                          >
                            {r.label}
                          </Table.HeaderCell>
                        );
                      }
                    })
                  : [
                      <Table.HeaderCell
                        key={'name'}
                        sorting={getSortProp('name')}
                        onClick={onSortChangeCreator('name')}
                      >
                        Application
                      </Table.HeaderCell>,
                      <Table.HeaderCell
                        key={'companyUsers'}
                        className='from-small'
                        sorting={getSortProp('companyUsers')}
                        onClick={onSortChangeCreator('companyUsers')}
                      >
                        Users with access
                      </Table.HeaderCell>,
                      <Table.HeaderCell
                        key={'inPortfolio'}
                        className='from-medium application-button-row'
                        sorting={getSortProp('inPortfolio')}
                        onClick={onSortChangeCreator('inPortfolio')}
                      >
                        Add to portfolio?
                      </Table.HeaderCell>
                    ]}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedAndIndexedData.map(application => (
                <ApplicationBox
                  key={application.id}
                  active={active}
                  application={application}
                  openUserSlider={setUserSliderOpen}
                  setApplicationId={setCurrentApplicationId}
                  tableRows={applicationTableRows}
                  isComplete={isComplete}
                />
              ))}
            </Table.Body>
          </Table>
        ) : (
          <EmptyTableText />
        )}
        <div className='bottom-line'>
          <div />
          {Math.ceil(sortedData?.length / pageSize.value) > 1 ? (
            <Pagination
              style={{marginTop: 'var(--bfs16)'}}
              totalPages={Math.ceil(sortedData?.length / pageSize.value)}
              currentPage={page}
              onChange={e => {
                setPage(e);
              }}
            />
          ) : (
            <div />
          )}
          <Select
            key='Items'
            label='Table size'
            hideLabel
            value={pageSize}
            className='table-size-dropdown'
            options={tableSizeOptions()}
            onChange={item => {
              setPage(1);
              setPageSize(item);
            }}
          />
        </div>
      </div>
    );
  };

  const ValueContainer = ({children, ...props}) => {
    let [values, input] = children;
    if (Array.isArray(values)) {
      values = `Selected rows (${values.length})`;
    }
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };

  return (
    <BasicPage
      id='applications'
      taskType={taskType.APPLICATIONS}
      isComplete={isComplete}
      setIsComplete={toggleApplications}
    >
      <ErrorModal isOpen={errorOpen} onClose={() => dispatch(updateApplicationsClear())} type={ApplicationError} />
      {!isOpen ? (
        <div className='content-main'>
          <SimpleBox className='application-box' title='Business applications'>
            <div className='bfl-grid application-box-csv-list'>
              {/* <ServerCard addNewApplication={addNewApplication} isComplete={isComplete} />
              <SaaSCard addNewApplication={addNewApplication} isComplete={isComplete} /> */}
              {/* <ImportCard isComplete={isComplete} /> */}
            </div>
            <div className='search-row-input'></div>
            <div className='users-filter-row'>
              <div className='users-filter-row-filters bf-neutral-link'>
                {filterList.map((f, i) => {
                  if (f?.search === 'inPortfolio') {
                    return (
                      <Tag
                        key={i}
                        style={!f.includes ? {color: 'var(--bfc-base-c-alert)'} : undefined}
                        onClick={() => removeFilter(f)}
                      >
                        {f?.displayName}
                        <Icon style={{marginLeft: 'var(--bfs4)'}} icon={faTimes} />
                      </Tag>
                    );
                  } else if (f?.search === 'readyStatus') {
                    return (
                      <Tag
                        key={i}
                        style={!f.includes ? {color: 'var(--bfc-base-c-alert)'} : undefined}
                        onClick={() => removeFilter(f)}
                      >
                        {f?.displayName}
                        <Icon style={{marginLeft: 'var(--bfs4)'}} icon={faTimes} />
                      </Tag>
                    );
                  } else
                    return (
                      <Tag
                        key={i}
                        style={
                          f.warning
                            ? {color: 'var(--bfc-base-c-warning)'}
                            : !f.includes
                            ? {color: 'var(--bfc-base-c-alert)'}
                            : undefined
                        }
                        onClick={() => removeFilter(f)}
                      >
                        {f?.displayName}: {f?.value}
                        <Icon style={{marginLeft: 'var(--bfs4)'}} icon={faTimes} />
                      </Tag>
                    );
                })}
                {filterList.length === 0 && (
                  <p className='bf-em' style={{margin: 0, width: '100%'}} onClick={() => setOpen(!open)}>
                    Showing all applications, no filter selected -{' '}
                    <span className='bf-neutral-link-text'>add filter</span> to work more efficient
                  </p>
                )}
                <button className='add-button' onClick={() => setOpen(!open)}>
                  Add filter <Icon icon={open ? faAngleDown : faAngleUp} />
                </button>
              </div>
              <SlideDown autoScroll open={open}>
                <div className='users-filter-row-actions bfl-grid'>
                  {fromSmall ? (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <FieldGroup className='filter-field-group'>
                        <Select
                          label='Items'
                          className='select-group-select'
                          hideLabel={true}
                          options={ApplicationSearchOptions}
                          value={searchCriterea}
                          isSearchable={false}
                          onChange={item => setSearchCriterea(item)}
                        />
                        <Select
                          label='Includes'
                          className='select-group-select'
                          hideLabel={true}
                          isSearchable={false}
                          value={searchInclude}
                          options={[
                            {label: 'Includes', value: true},
                            {label: 'Does not include', value: false}
                          ]}
                          onChange={item => setSearchInclude(item)}
                        />
                        {searchCriterea?.value === 'applicationType' ? (
                          <Select
                            className='select-group-input'
                            placeholder='Search filter'
                            label='search'
                            hideLabel
                            clearable
                            options={appTypeOptions}
                            onChange={e => {
                              addFilter({
                                search: searchCriterea?.value,
                                value: e?.value,
                                includes: searchInclude.value,
                                displayName: searchCriterea?.label
                              });
                            }}
                          />
                        ) : searchCriterea?.value === 'readyStatus' ? (
                          <Select
                            className='select-group-input'
                            placeholder='Search filter'
                            label='search'
                            hideLabel
                            clearable
                            options={appStatusOptions}
                            onChange={item => {
                              changeTypeFilter({
                                search: item?.type,
                                value: item?.value,
                                includes: searchInclude.value,
                                displayName: `Status: ${item?.label}`
                              });
                            }}
                          />
                        ) : (
                          <Input
                            className='select-group-input'
                            placeholder='Search filter'
                            label='search'
                            hideLabel
                            icon={faSearch}
                            clearable
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            rightIcon
                            onKeyDown={e => {
                              e.keyCode === 13 &&
                                addFilter({
                                  search: searchCriterea?.value,
                                  value: searchValue,
                                  includes: searchInclude.value,
                                  displayName: searchCriterea?.label
                                });
                            }}
                            onIconClick={() =>
                              addFilter({
                                search: searchCriterea?.value,
                                value: searchValue,
                                includes: searchInclude.value,
                                displayName: searchCriterea?.label
                              })
                            }
                          />
                        )}
                      </FieldGroup>
                      {filterList?.length > 0 && active !== Microsoft365Apps && active !== FreewareApps && (
                        <p style={{marginLeft: 'var(--bfs4)', marginBottom: 0}} className='bf-em bf-li'>
                          {(filterList?.length > 0 || statusFilter?.length > 0) && `${sortedData.length} applications`}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className='small-select-group'>
                      <Select
                        label='Items'
                        className='select-group-select'
                        hideLabel={true}
                        options={ApplicationSearchOptions}
                        value={searchCriterea}
                        onChange={item => setSearchCriterea(item)}
                      />
                      <Select
                        label='Includes'
                        className='select-group-select'
                        hideLabel={true}
                        value={searchInclude}
                        options={[
                          {label: 'Includes', value: true},
                          {label: 'Does not include', value: false}
                        ]}
                        onChange={item => setSearchInclude(item)}
                      />
                      {searchCriterea?.value === 'applicationType' ? (
                        <Select
                          className='select-group-input'
                          placeholder='Search filter'
                          label='search'
                          hideLabel
                          clearable
                          options={appTypeOptions}
                          onChange={e => {
                            addFilter({
                              search: searchCriterea?.value,
                              value: e?.value,
                              includes: searchInclude.value,
                              displayName: searchCriterea?.label
                            });
                          }}
                        />
                      ) : searchCriterea?.value === 'readyStatus' ? (
                        <Select
                          className='select-group-input'
                          placeholder='Search filter'
                          label='search'
                          hideLabel
                          clearable
                          options={appStatusOptions}
                          onChange={item => {
                            changeTypeFilter({
                              search: item?.type,
                              value: item?.value,
                              includes: searchInclude.value,
                              displayName: `Status: ${item?.label}`
                            });
                          }}
                        />
                      ) : (
                        <Input
                          className='select-group-input'
                          placeholder='Search filter'
                          label='search'
                          hideLabel
                          icon={faSearch}
                          clearable
                          value={searchValue}
                          onChange={e => setSearchValue(e.target.value)}
                          rightIcon
                          onKeyDown={e => {
                            e.keyCode === 13 &&
                              addFilter({
                                search: searchCriterea?.value,
                                value: searchValue,
                                includes: searchInclude.value,
                                displayName: searchCriterea?.label
                              });
                          }}
                          onIconClick={() =>
                            addFilter({
                              search: searchCriterea?.value,
                              value: searchValue,
                              includes: searchInclude.value,
                              displayName: searchCriterea?.label
                            })
                          }
                        />
                      )}
                      {filterList?.length > 0 && active !== Microsoft365Apps && active !== FreewareApps && (
                        <p className='bf-em bf-li'>
                          {(filterList?.length > 0 || statusFilter?.length > 0) && `${sortedData.length} applications`}
                        </p>
                      )}
                    </div>
                  )}
                  <div className='select-group bfl-grid'>
                    <Select
                      label='Toggle rows'
                      hideLabel
                      placeholder='View columns'
                      options={ApplicationRowOptions}
                      onChange={e => {
                        setApplicationRows(e);
                        setApplicationTableRows(e);
                        if (e.length > 0) {
                          addFilter({
                            search: 'selectedRows',
                            value: e.length,
                            includes: true,
                            displayName: 'Selected rows',
                            rows: JSON.stringify(e)
                          });
                        } else {
                          removeFilter({search: 'selectedRows'});
                        }
                      }}
                      value={applicationRows}
                      isMulti={true}
                      style={{minWidth: '200px'}}
                      components={{ValueContainer}}
                    />
                    <div>
                      <Select
                        className='select-group-select'
                        label='Portfolio Validation'
                        placeholder='- Validation -'
                        hideLabel
                        options={appValidationOptions}
                        value={appValidationFilter}
                        onChange={item => {
                          changeTypeFilter({
                            search: item?.type,
                            value: item?.value,
                            includes: true,
                            displayName: `Portfolio: ${item?.label}`
                          });
                          setAppValidationFilter(item);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </SlideDown>
            </div>
            <div className='application-search-row'></div>
            <div className='filter-row' style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                <ImportCard isComplete={isComplete} />
                <Button onClick={() => dispatch(downloadCsv())} icon={faFileExport} style={{marginLeft: '12px'}}>
                  Export portfolio
                </Button>
                <Link className='bf-button bf-button-filled' to={`/${id}/addApplication`} style={{marginLeft: '12px', display: 'flex', alignItems: 'center'}}>
                  <Icon icon={faAdd} /> <div style={{marginLeft: '4px'}}>Add application</div>
                </Link>
              </div>
            </div>
            <Tabs className='application-tabs'>
              <a
                href='#'
                onClick={e => handleChange(e, PortfolioApps)}
                className={active === PortfolioApps ? 'active' : ''}
              >
                Your applications portfolio <span>{applicationList?.filter(a => a.isEnabled === true)?.length}</span>
              </a>
              <hr style={{margin: 0, border: 0, backgroundColor: 'var(--bfc-base-dimmed)', width: '1px'}} />
              <a href='#' onClick={e => handleChange(e, IamsApps)} className={active === IamsApps ? 'active' : ''}>
                Client applications{' '}
                <Tooltip content='Applications from the IAMS scan'>
                  <Icon style={{color: 'var(--bfc-base-c-theme)'}} icon={faQuestionCircle} />
                </Tooltip>{' '}
                <span>
                  {applicationList?.filter(a => a.origin === IamsOrigin && a.isEnabled)?.length} of{' '}
                  {applicationList?.filter(a => a.origin === IamsOrigin)?.length}
                </span>
              </a>
              <a
                href='#'
                onClick={e => handleChange(e, Microsoft365Apps)}
                className={active === Microsoft365Apps ? 'active' : ''}
              >
                Microsoft 365{' '}
                <Tooltip content='Applications from the Microsoft 365 suite'>
                  <Icon style={{color: 'var(--bfc-base-c-theme)'}} icon={faQuestionCircle} />
                </Tooltip>{' '}
                <span>
                  {
                    applicationList?.filter(a => a.origin === Microsoft365Origin)?.filter(a => a.isEnabled === true)
                      ?.length
                  }{' '}
                  of {selectApps.length}
                </span>
              </a>
              <a
                href='#'
                onClick={e => handleChange(e, FreewareApps)}
                className={active === FreewareApps ? 'active' : ''}
              >
                Freeware{' '}
                <Tooltip content='Standardized software for end users'>
                  <Icon style={{color: 'var(--bfc-base-c-theme)'}} icon={faQuestionCircle} />
                </Tooltip>{' '}
                <span>
                  {applicationList?.filter(a => a.origin === FreewareOrigin)?.filter(a => a.isEnabled === true)?.length}{' '}
                  of {freewareOptions.length}
                </span>
              </a>
              <a href='#' onClick={e => handleChange(e, ManualApps)} className={active === ManualApps ? 'active' : ''}>
                Manually added{' '}
                <Tooltip content='Applications added manually above'>
                  <Icon style={{color: 'var(--bfc-base-c-theme)'}} icon={faQuestionCircle} />
                </Tooltip>{' '}
                <span>
                  {applicationList?.filter(a => a.origin === ManualOrigin && a.isEnabled)?.length} of{' '}
                  {applicationList?.filter(a => a.origin === ManualOrigin)?.length}
                </span>
              </a>
            </Tabs>
            <div className='tab-bar-content'>{showTabContent(active)}</div>
            <UserApplicationSlider
              applicationId={currentApplicationId}
              isComplete={isComplete}
              setApplicationId={setCurrentApplicationId}
              isOpen={userSliderOpen}
              setIsOpen={setUserSliderOpen}
            />
          </SimpleBox>
        </div>
      ) : (
        <NewAppSteps
          setNewAppType={setNewAppType}
          newAppType={newAppType}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          switchToPage={handleChange}
        />
      )}
    </BasicPage>
  );
};

export default Applications;
