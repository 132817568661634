import React from "react";
import './AppProgressCard.scss';
import PropTypes from "prop-types";
import serverImage from "../../../../../assets/images/application/serverImage.svg";
import saasImage from "../../../../../assets/images/application/16_9saas.svg";
import splitImage from "../../../../../assets/images/application/serversplit.svg";
import { Badge, Icon } from "@intility/bifrost-react";
import {
  faArrowRight,
  faBrowser,
  faCheck, faCodeMerge, faInfoCircle,
  faLaptop, faLocationDot, faSignInAlt, faUserCircle
} from "@fortawesome/pro-regular-svg-icons";
import laptopGlobalIcon from 'assets/images/application/laptopBrowser.svg';
import { faPencil } from "@fortawesome/pro-light-svg-icons/faPencil";
import { ApplicationType } from "../ApplicationOptions";
import { faComment } from "@fortawesome/pro-solid-svg-icons";


const AppProgressCard = ({ appObject, step, setStep, progress, setEditAppType, setCurrentAppType, newAppType }) => {

  return (
    <div className='app-progress-card'>
      <div>
        <div className='app-progress-card-image' style={{backgroundImage: `url(${(appObject?.serverApp === 1 && appObject?.saasApp === 1) ? splitImage : appObject?.serverApp === 1 ? serverImage : saasImage})`,
          backgroundSize: 'cover'}}/>
        <div className='app-progress-card-header'>
          <p className='bf-strong'>Application type</p>
          <Icon icon={faArrowRight}/>
        </div>
        { appObject?.serverApp === 1 && <span><Icon icon={faCheck}/><p className='bf-small'> Server application</p></span>}
        { appObject?.saasApp === 1 && <span><Icon icon={faCheck}/><p className='bf-small'> SaaS application</p></span>}
        <div>
          <span onClick={() => {
            setEditAppType(true);
            setCurrentAppType({serverApp: appObject?.serverApp, saasApp: appObject?.saasApp});
          }}><Icon icon={faPencil}/><p className='bf-small'>Edit application type</p></span>
        </div>
      </div>
      {progress >= 1 &&
        <div className='app-progress-card-body'>
          <div className='app-progress-card-body-header' onClick={() => setStep(1)}>
            <span><p className='bf-strong'>{appObject?.name} {appObject?.nickname && `(${appObject?.nickname})`}</p><Icon icon={faArrowRight}/></span>
          </div>
          {(progress >= 2 && newAppType !== ApplicationType.SaaSApp) &&
            <div className='app-progress-card-body-content' onClick={() => setStep(2)}>
              { appObject?.clientApp === true && <div><span><Icon icon={faLaptop}/><p className='bf-small'>Client application</p></span><Icon icon={faArrowRight}/></div> }
              { appObject?.virtualClient === true && <div><span><img src={laptopGlobalIcon} alt='Laptop' style={{height: 'var(--bfs12)'}}/><p className='bf-small'>Virtual client</p></span><Icon icon={faArrowRight}/></div> }
              { appObject?.webBrowser === true && <div><span><Icon icon={faBrowser}/><p className='bf-small'>Web browser</p></span><Icon icon={faArrowRight}/></div> }
            </div>
          }
          {(progress >= 3 && newAppType !== ApplicationType.SaaSApp) &&
            <div className='app-progress-card-body-content' onClick={() => setStep(3)}>
              { appObject?.newVersion?.length > 0 && <div><span><Icon icon={faInfoCircle}/><p className='bf-small'>Version {appObject?.newVersion}</p></span><Icon icon={faArrowRight}/></div> }
            </div>
          }
          {((progress >= 5 && newAppType !== ApplicationType.SaaSApp) || (progress >= 3 && newAppType === ApplicationType.SaaSApp)) &&
            <div className='app-progress-card-body-content' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 5 : 3)}>
              { appObject?.activeDirectory === true && <div><span><Icon icon={faSignInAlt}/><p className='bf-small'>Active directory</p></span><Icon icon={faArrowRight}/></div> }
              { appObject?.azureAd === true && <div><span><Icon icon={faSignInAlt}/><p className='bf-small'>Azure active directory</p></span><Icon icon={faArrowRight}/></div> }
              { appObject?.appSpecificUser === true && <div><span><Icon icon={faSignInAlt}/><p className='bf-small'>App specific user</p></span><Icon icon={faArrowRight}/></div> }
            </div>
          }
          {((progress >= 6 && newAppType !== ApplicationType.SaaSApp) || (progress >= 4 && newAppType === ApplicationType.SaaSApp)) &&
            <div className='app-progress-card-body-content' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 6 : 4)}>
              { appObject?.applicationRelations?.length > 0 && <div><span><Icon icon={faCodeMerge}/><p className='bf-small'>{appObject?.applicationRelations?.length} Integrations</p></span><Icon icon={faArrowRight}/></div> }
            </div>
          }
          {((progress >= 7 && newAppType !== ApplicationType.SaaSApp) || (progress >= 5 && newAppType === ApplicationType.SaaSApp)) &&
            <div className='app-progress-card-body-content' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 7 : 5)}>
              { appObject?.internalContacts?.length > 0 && <div><span><Icon icon={faUserCircle}/><p className='bf-small'>{appObject?.internalContacts?.length} Internal contacts</p></span><Icon icon={faArrowRight}/></div> }
            </div>
          }
          {((progress >= 4 && newAppType !== ApplicationType.SaaSApp) || (progress >= 2 && newAppType === ApplicationType.SaaSApp)) &&
            <div>
              <hr/>
              <div className='app-progress-card-body-header' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 4 : 2)}>
                <span><p className='bf-strong'>{appObject?.vendor}</p><Icon icon={faArrowRight}/></span>
              </div>
              <div className='app-progress-card-body-content' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 4 : 2)}>
                { appObject?.vendorContacts?.length > 0 && <div><span><Icon icon={faUserCircle}/><p className='bf-small'>{appObject?.vendorContacts?.length} Vendor contacts</p></span><Icon icon={faArrowRight}/></div> }
              </div>
            </div>
          }
          {((progress >= 7 && newAppType !== ApplicationType.SaaSApp) || (progress >= 5 && newAppType === ApplicationType.SaaSApp)) &&
            <div>
              <hr/>
              <div className='app-progress-card-body-header' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 8 : 6)}>
                <span><p className='bf-strong'>Misc</p><Icon icon={faArrowRight}/></span>
              </div>
              <div className='app-progress-card-body-content' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 8 : 6)}>
                { appObject?.comment?.length > 0 && <div><span><Icon icon={faComment}/><p className='bf-small'>{appObject?.comment}</p></span></div> }
              </div>
              <div className='app-progress-card-body-content' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 8 : 6)}>
                { appObject?.environment?.length > 0 && <div><span>{appObject?.environment.split(',').map((e, i) => <Badge key={i} style={{marginRight: 'var(--bfs4)'}}>{e}</Badge>)}</span></div> }
              </div>
              <div className='app-progress-card-body-content' onClick={() => setStep(newAppType !== ApplicationType.SaaSApp ? 8 : 6)}>
                { appObject?.location?.length > 0 && <div><span><Icon icon={faLocationDot}/><p className='bf-small'>{appObject?.location}</p></span></div> }
              </div>
            </div>
          }
        </div>
      }
    </div>
  )

}

export default AppProgressCard

AppProgressCard.propTypes = {
  appObject: PropTypes.object,
  step: PropTypes.number,
  progress: PropTypes.number,
  setStep: PropTypes.func,
  setEditAppType: PropTypes.func,
  setCurrentAppType: PropTypes.func,
  newAppType: PropTypes.string
}