import {isStateValid, validateState} from 'utils/wizard/stateValidations';
import React, {useState, useEffect, useCallback} from 'react';
import PrepareForRequest from 'utils/wizard/requestPreparer';
import './wizard.css';
import PropTypes from 'prop-types';
import * as pages from './Pages';
import {ApproveState, CompanyState} from 'utils/wizard/states';
import produce from 'immer';
import devLog from '../../utils/devLog';
import {fetchCompaniesAsync} from '../../redux/companiesSlice';
import {useDispatch} from 'react-redux';
import lightImage from "../../assets/images/Wizard/Background.png";
import darkImage from "../../assets/images/Wizard/BackgroundDark.png";
import {FloatingMessage, useTheme} from '@intility/bifrost-react';
import {useCurrentTheme} from '../Navigation/useCurrentTheme';

/**
 * Create the company in the database
 */
const createCompany = (body, apiFetch) =>
  apiFetch('/companies', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) throw new Error('Error submitting company');

      return response.json();
    })
    .catch(error => {
      console.log('Error creating company', error);
      throw error;
    });

/**
 * The Wizard handles the state across the application.
 * It renders a Container which in turn renders the children inside it.
 */
const Wizard = ({apiFetch, user}) => {
  const [formState, setFormState] = useState(() => ({
    company: new CompanyState(),
    approve: new ApproveState()
  }));
  const theme = useCurrentTheme();
  const dispatch = useDispatch();

  const updateFormState = useCallback(newState => {
    setFormState(prevFormState => {
      // on any change, the data is not approved anymore
      const newApprove = {...prevFormState.approve, approved: false};
      newApprove.isValid = isStateValid(newApprove.errors);

      // immer, get immutable state by altering draft
      return produce(prevFormState, draft => {
        draft.approve = newApprove;
        draft.company = Object.assign(draft.company, newState);

        const errors = validateState(draft.company);
        const isValid = isStateValid(errors);

        draft.company.errors = errors;
        draft.company.isValid = isValid;
      });
    });
  }, []);

  /**
   * Function that is called at the time of submit.
   * Creates a company.
   */
  const onSubmit = () => {
    devLog('You submitted following values: ', formState);
    const body = PrepareForRequest({...formState});

    return createCompany(body, apiFetch)
      .then(company => {
        devLog('Created company:', company);
      })
      .then(() => dispatch(fetchCompaniesAsync()));
  };

  // set onboarding contact to be the logged-in user
  useEffect(() => {
    if (!user) return;

    const {firstName, lastName, email, phoneNumber} = user;

    const onboardingContact = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phoneNumber: phoneNumber || ''
    };

    updateFormState({onboardingContact});
  }, [user, updateFormState]);

  return (
    <div id='wizard' style={
      theme === 'light'
        ? {
          backgroundImage: `url(${lightImage})`,
          backgroundSize: 'cover'
        }
        : {
          backgroundImage: `url(${darkImage})`,
          backgroundSize: 'cover'
        }
    }>
      <FloatingMessage>
        <pages.Company state={formState.company} updateFormState={updateFormState} onSubmit={onSubmit} />
      </FloatingMessage>
    </div>
  );
};

Wizard.propTypes = {
  apiFetch: PropTypes.func,
  user: PropTypes.object
};

export default Wizard;
