import {Accordion, Button} from '@intility/bifrost-react';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {updateCompliancePolicy} from 'redux/company/mobileDevices/mobileDevicesSlice';
import './accordionComponent.scss';
import EditPolicies from './EditPolicies';
import ViewPolicies from './ViewPolicies';

export const AccordionComponent = ({compliancePolicy}) => {
  const mobileDevices = useSelector(store => store.company.data.mobilePlatform.mobileDevices);
  const {isEnabled} = useSelector(state => state.company.data.mobilePlatform.mobileDevices);
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(mobileDevices[compliancePolicy.policy]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEnabled) {
      onCancel()
    }
  }, [isEnabled])


  const onCancel = () => {
    setIsEditing(false);
  };

  const handleSave = () => {
    dispatch(updateCompliancePolicy({values: formState, policyType: compliancePolicy.policy}));
    setIsEditing(prev => !prev);
  };

  return (
    <>
      <Accordion variant={"styled"}>
        <Accordion.Item title={compliancePolicy.tabName} icon={compliancePolicy.icon}>
          <div className='accordion-header-box'>
            <div className='accordion-header'>
              <h3 style={{marginBottom: 0}}>{compliancePolicy.text}</h3>
              <p style={{marginTop: 0}}>{compliancePolicy.description}</p>
            </div>
            <div className='action-button-compliance'>
              {isEditing ? (
                <>
                  <Button onClick={onCancel}>Cancel</Button>
                  <Button variant='filled' onClick={handleSave}>
                    Save Changes
                  </Button>
                </>
              ) : (
                <Button disabled={!isEnabled} onClick={() => setIsEditing(true)}>Edit</Button>
              )}
            </div>
          </div>

          {isEditing ? (
            <EditPolicies
              compliancePolicy={compliancePolicy}
              formState={formState}
              setFormState={setFormState}
              policyType={compliancePolicy.policy}
            />
          ) : (
            <ViewPolicies compliancePolicy={compliancePolicy} formState={formState} />
          )}
        </Accordion.Item>
      </Accordion>
    </>
  );
};
