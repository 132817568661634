import apiFetch from '../../utils/apiFetch';
import devLog from '../../utils/devLog';
import {updateBilling, updateBillingError} from './billing/billingReducers';
import {updateBillingInformationSuccess} from './billing/billingActions';
import {updateApplicationInformationSuccess} from './applications/applicationActions';
import {updateApplications, updateApplicationsError} from './applications/applicationReducers';
import {updateCommunicationItems, updateCommunicationItemsError} from './communication/communicationReducers';
import {updateCommunicationItemsInformationSuccess} from './communication/communicationActions';
import {updateComputerSetup, updateComputerSetupError} from './computerSetup/computerSetupReducers';
import {updateComputerSetupInformationSuccess} from './computerSetup/computerSetupActions';
import {updateDomains, updateDomainsError} from './domain/domainReducers';
import {updateDomainInformationSuccess} from './domain/domainActions';
import {updateLocations, updateLocationsError} from './locations/locationReducers';
import {updateLocationInformationSuccess} from './locations/locationActions';
import {updateUsers, updateUsersError} from './users/usersReducers';
import {updateUsersInformationSuccess} from './users/usersActions';
import {updateIamsUsers, updateIamsUsersError} from './iamsUsers/iamsUsersReducers';
import {updateIamsUsersInformationSuccess} from './iamsUsers/iamsUsersActions';
import {updateDocumentation, updateDocumentationError} from './documentation/documentationReducers';
import {updateDocumentationInformationSuccess} from './documentation/documentationActions';
import {updateDetails, updateDetailsError} from './details/detailReducers';
import {updateDetailInformationSuccess} from './details/detailActions';
import {updatePrinters, updatePrintersError} from './printers/printerReducers';
import {updatePrintersInformationSuccess} from './printers/printerActions';
import {fetchCompanyAsync} from './companyThunks';

export const platformType = {
  BILLING: 'billing',
  APPLICATIONS: 'applications',
  CLIENTAPPLICATIONS: 'clientApplications',
  COMMUNICATION: 'communication',
  DOCUMENTATION: 'documentation',
  COMPUTERSETUP: 'computerSetup',
  DOMAINS: 'domains',
  LOCATIONS: 'locations',
  COMPANYUSERS: 'companyUsers',
  IAMSUSERS: 'iamsUsers',
  DETAILS: 'details',
  PRINTERS: 'printers',
  MOBILE_DEVICES: 'mobileDevices',
  MS365: 'microsoft365',
};

const updatePlatform = type => {
  const updateValues = {
    update: null,
    success: null,
    error: null
  };

  switch (type.toString()) {
    case platformType.BILLING:
      updateValues.update = updateBilling();
      updateValues.success = updateBillingInformationSuccess;
      updateValues.error = updateBillingError();
      break;
    case platformType.APPLICATIONS:
      updateValues.update = updateApplications();
      updateValues.success = updateApplicationInformationSuccess;
      updateValues.error = updateApplicationsError();
      break;
    case platformType.COMMUNICATION:
      updateValues.update = updateCommunicationItems();
      updateValues.success = updateCommunicationItemsInformationSuccess;
      updateValues.error = updateCommunicationItemsError();
      break;
    case platformType.DOCUMENTATION:
      updateValues.update = updateDocumentation();
      updateValues.success = updateDocumentationInformationSuccess;
      updateValues.error = updateDocumentationError();
      break;
    case platformType.COMPUTERSETUP:
      updateValues.update = updateComputerSetup();
      updateValues.success = updateComputerSetupInformationSuccess;
      updateValues.error = updateComputerSetupError();
      break;
    case platformType.DOMAINS:
      updateValues.update = updateDomains();
      updateValues.success = updateDomainInformationSuccess;
      updateValues.error = updateDomainsError();
      break;
    case platformType.LOCATIONS:
      updateValues.update = updateLocations();
      updateValues.success = updateLocationInformationSuccess;
      updateValues.error = updateLocationsError();
      break;
    case platformType.COMPANYUSERS:
      updateValues.update = updateUsers();
      updateValues.success = updateUsersInformationSuccess;
      updateValues.error = updateUsersError();
      break;
    case platformType.IAMSUSERS:
      updateValues.update = updateIamsUsers();
      updateValues.success = updateIamsUsersInformationSuccess;
      updateValues.error = updateIamsUsersError();
      break;
    case platformType.DETAILS:
      updateValues.update = updateDetails();
      updateValues.success = updateDetailInformationSuccess;
      updateValues.error = updateDetailsError();
      break;
    case platformType.PRINTERS:
      updateValues.update = updatePrinters();
      updateValues.success = updatePrintersInformationSuccess;
      updateValues.error = updatePrintersError();
      break;
    case platformType.MS365:
      updateValues.update = updatePrinters();
      updateValues.success = updatePrintersInformationSuccess;
      updateValues.error = updatePrintersError();
      break;
    default:
      break;
  }
  return updateValues;
};

export const updatePlatformAsync = (type, companyId, data) => (dispatch, getState) => {
  const values = updatePlatform(type);

  dispatch(values.update);

  return apiFetch(`/companies/${companyId}/${type}?serviceName=${data.companyServiceName}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to update company.');
      }
      return response.json();
    })
    .then(platform => {
      devLog('Update platform response', platform);
      dispatch(values.success(platform));
      return platform;
    })
    .catch(() => dispatch(values.error));
};

export const updateInfoPlatformAsync = (type, companyId, data) => (dispatch, getState) => {
  const values = updatePlatform(type);

  dispatch(values.update);

  return apiFetch(`/companies/${companyId}/${type}?serviceName=${data.companyServiceName || ''}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to update company.');
      }
      return response.json();
    })
    .then(platform => {
      devLog('Update platform response', platform);
      dispatch(values.success(platform));
      dispatch(fetchCompanyAsync(companyId))
      return platform;
    })
    .catch(() => dispatch(values.error));
};

export const togglePlatformAsync = (type, companyId, isEnabled) => (dispatch, getState) => {
  const values = updatePlatform(type);

  dispatch(values.update);

  return apiFetch(`/companies/${companyId}/${type}/toggle?setValue=${isEnabled}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('An error occurred trying to update company.');
      }
      return response.json();
    })
    .then(platform => {
      devLog('Update platform response', platform);
      dispatch(values.success(platform));
      return platform;
    })
    .catch(() => dispatch(values.error));
};
