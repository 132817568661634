import { Button, Input, Modal } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import React, { useEffect, useState } from "react";
import { Location } from "../../../../../../utils/hub/models";
import produce from "immer";
import { useDispatch } from "react-redux";
import { addLocationAsync } from "../../../../../../redux/company/locations/locationThunks";
import './newLocationModal.scss';
import GoogleAddressAutocomplete from "../GoogleMapsPane/GoogleAddressAutocomplete";
import InputWithFeedback from "../../../../../InputWithFeedback/InputWithFeedback";

export const locationTypeOptions = [
  { label: 'Office', value: 'Office' },
  { label: 'Warehouse', value: 'Warehouse' },
  { label: 'Workshop', value: 'Workshop' },
  { label: 'Production facility', value: 'Production facility' },
  { label: 'Real estate', value: 'Real estate' },
  { label: 'Unmanned station', value: 'Unmanned station' },
  { label: 'Public network', value: 'Public network' }
];

const NewLocationModal = ({isOpen, setIsOpen}) => {
  const [locationTypes, setLocationTypes] = useState([]);
  const [newLocation, setNewLocation] = useState(new Location());
  const [validation, setValidation] = useState(false);
  const [verify, setVerify] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (verify) {
      const errors = newLocation?.name.length === 0;
      setValidation(errors);
    }
  }, [newLocation, verify]);

  const saveLocation = () => {
    setVerify(true);
    const isValid = newLocation?.name.length > 0;
    if (isValid) {
      dispatch(addLocationAsync(newLocation));
      cancelLocation();
    }
  }

  const cancelLocation = () => {
    setNewLocation(new Location());
    setLocationTypes([]);
    setIsOpen(false);
    setValidation(undefined);
    setVerify(false);
  }

  const setAddress = (addressObject) => {
    setNewLocation(produce(newLocation, draft => {
      draft.address = addressObject.address;
      draft.postalPlace = addressObject.postalPlace;
      draft.zipCode = addressObject.zipCode;
      draft.country = addressObject.country;
      draft.intilityManaged = 1;
      draft.eoriNumber = addressObject.country === 'Norway' ? 'Not relevant' : '';
    }));
  }

  const onChange = (e) => {
    const {name, value} = e.target;
    setNewLocation(produce(newLocation, draft => {
      draft[name] = value;
      draft.intilityManaged = 1;
    }));
  }

  const onTypeChange = (value) => {
    setLocationTypes(value)
    setNewLocation(produce(newLocation, draft => {
      draft.locationTypes = value.map(v => v.value).join(',');
    }));
  }

  return (
    <Modal onRequestClose={() => cancelLocation()} isOpen={isOpen} header='Add new location' className='new-location-modal'>
      <InputWithFeedback
        onChange={onChange}
        value={newLocation?.name}
        label='Name of location'
        placeholder='e.g "Main Office"'
        required={true}
        name='name'
        otherProps={
          validation && {
            state: 'alert',
            feedback: 'Missing value'
          }
        }
      />
      <GoogleAddressAutocomplete location={newLocation} setLocation={setNewLocation} selectLocation={setAddress}/>
      <div className='select-row'>
        <Input value={newLocation?.zipCode} label='Zip code' name='zipCode' onChange={onChange} />
        <Input value={newLocation?.postalPlace} label='Postal place' name='postalPlace' onChange={onChange} />
      </div>
      <div className='select-row'>
        <Input value={newLocation?.country} label='Country' name='country' onChange={onChange} />
        <Select label='Location type(s)' maxMenuHeight={110} isMulti options={locationTypeOptions} value={locationTypes} onChange={e => onTypeChange(e)}/>
      </div>
      <div className='button-row'>
        <Button onClick={() => cancelLocation()}>Cancel</Button>
        <Button variant={"filled"} onClick={() => saveLocation()}>Save</Button>
      </div>
    </Modal>
  );

}

export default NewLocationModal