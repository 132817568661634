const SearchOptions = [
  {value: 'firstName', label: 'First name'},
  {value: 'lastName', label: 'Last name'},
  {value: 'primaryEmailAddress', label: 'User principal name'},
  {value: 'mail', label: 'Mail'},
  {value: 'licenses', label: 'License'},
  {value: 'mobilePhone', label: 'Mobile phone'},
  {value: 'manager', label: 'Manager'},
  {value: 'billingCompany', label: 'Billing company'},
  {value: 'location', label: 'Location'},
  {value: 'language', label: 'Language'},
  {value: 'country', label: 'Country'},
  {value: 'userName', label: 'Username'},
  {value: 'department', label: 'Department'},
  {value: 'officePhone', label: 'Office phone'},
  {value: 'title', label: 'Title'},
  {value: 'physicalOffice', label: 'Physical office'},
  {value: 'streetAddress', label: 'Street address'},
  {value: 'postalCode', label: 'Postal code'},
  {value: 'city', label: 'City'},
  {value: 'state', label: 'State'},
  {value: 'adCompany', label: 'AD company'},
  {value: 'description', label: 'Description'},
  {value: 'oldSam', label: 'Old SAM'},
  {value: 'newSam', label: 'New SAM'},
  {value: 'oldSid', label: 'Old SID'},
  {value: 'newSid', label: 'New SID'},
  {value: 'employeeId', label: 'Employee ID'},
  {value: 'locationId', label: 'Location ID'}
];

export default SearchOptions;
