import React, {useEffect, useState} from 'react';
import { Icon } from "@intility/bifrost-react";
import '../backoffice.scss';
import {useSelector} from 'react-redux';
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { LayoutPageOptions } from "./layoutOptions";
import PageState from "../../../utils/hub/pageState";

export const OnboardLayout = () => {
  const {data} = useSelector(state => state.company);
  const [layoutPages, setLayoutPages] = useState([]);

  useEffect(() => {
    const userTypes = LayoutPageOptions.filter(p => {
      if (data[p.value]?.state !== PageState.DISABLED) return p;
    }).map(u => u.value)
    setLayoutPages(userTypes);
  }, [data]);

  return (
    <div className='backoffice-editable-container'>
      <div className='backoffice-editable-header'>
        <h3>Onboard Layout</h3>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{border: '1px solid var(--bfc-base-c-dimmed)', padding: 'var(--bfs24)'}}>
          <p className='bf-large' style={{marginTop: 0}}>Enabled pages</p>
          {LayoutPageOptions.map(option => (
            <span key={option.label} style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
              {layoutPages?.includes(option.value) ?
                <Icon style={{color: 'var(--bfc-success)', marginRight: 'var(--bfs8)'}} icon={faCheck}/>
                :
                <Icon style={{color: 'var(--bfc-alert)', marginRight: 'var(--bfs8)'}} icon={faTimes}/>
              }
              <p style={{margin: 'var(--bfs4) 0'}}>{option.label}</p>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardLayout;
