import {createAction} from '@reduxjs/toolkit';
import {
  editGlobalTask,
  editOnboardTask,
  normalizeGlobalTasks,
  normalizeOnboardTaskInformation,
  normalizeOnboardTaskList,
  normalizeOnboardTasks
} from './onboardTaskNormalizers';

export const fetchOnboardTaskListSuccess = createAction('onboardTasksAction/fetchOnboardTaskListSuccess', apiOnboardTasks => ({
  payload: normalizeOnboardTaskList(apiOnboardTasks)
}));

export const fetchInformationSuccess = createAction('onboardTasksAction/fetchOnboardTaskInformationSuccess', apiOnboardTasks => ({
  payload: normalizeOnboardTaskInformation(apiOnboardTasks)
}));

export const fetchOnboardTasksSuccess = createAction('onboardTasksAction/fetchOnboardTasksSuccess', apiOnboardTasks => ({
  payload: normalizeOnboardTasks(apiOnboardTasks)
}));

export const updateOnboardTaskSuccess = createAction('onboardTasksAction/updateOnboardTaskSuccess', (apiOnboardTask, onboardTaskList) => ({
  payload: editOnboardTask(onboardTaskList, apiOnboardTask)
}));

export const fetchGlobalTasksSuccess = createAction('onboardTasksAction/fetchGlobalTasksSuccess', globalTaskList => ({
  payload: normalizeGlobalTasks(globalTaskList)
}));

export const updateGlobalTaskSuccess = createAction('onboardTasksAction/updateGlobalTaskSuccess', (apiOnboardTask, globalTaskList) => ({
  payload: editGlobalTask(globalTaskList, apiOnboardTask)
}));

export const updateOnboardTaskInformationSuccess = createAction('onboardTasksAction/updateInformationSuccess', information => ({
  payload: normalizeOnboardTaskInformation(information)
}));
