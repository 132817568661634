import {Icon, Spinner, useBreakpoint} from '@intility/bifrost-react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {fetchOnboardTasksAsync} from '../../../../../redux/company/onboardTasks/onboardTaskThunks';
import {pageDataOptions} from '../../../../../utils/hub/pageData/pageDataOptions';
import {findNextTask} from '../../Tasks/Tasks';
import {faArrowRight, faCheck, faHourglassHalf, faListCheck, faSparkles} from '@fortawesome/pro-regular-svg-icons';

export const OverviewTaskBox = ({}) => {
  const {id} = useSelector(state => state.company.data.info);
  const {taskList, taskStatus} = useSelector(state => state.company.data.taskPlatform);
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [nextTask, setNextTask] = useState();
  const fromSmall = useBreakpoint('medium');
  const history = useHistory();

  const enabledTasks = taskList && taskList?.filter(t => t.isEnabled === true);

  useEffect(() => {
    if (!user || !id) return;
    dispatch(fetchOnboardTasksAsync(id));
  }, [user, dispatch, id]);

  useEffect(() => {
    if (enabledTasks.length > 0) {
      setNextTask(findNextTask(enabledTasks));
    }
  }, [enabledTasks]);

  return taskStatus.fetching ? (
    <div className='task-box' style={fromSmall ? {gridColumn: 'span 2'} : {gridColumn: 'span 1'}}>
      <Spinner />
    </div>
  ) : (
    <div
      className='task-box'
      style={fromSmall ? {gridColumn: 'span 2'} : {gridColumn: 'span 1'}}
      tabIndex={0}
      onKeyDown={e => {
        e.keyCode !== 13 || history.push(`/${id}/${pageDataOptions[nextTask?.section]?.path || 'tasks'}`);
      }}
      onClick={() => history.push(`/${id}/${pageDataOptions[nextTask?.section]?.path || 'tasks'}`)}
    >
      {!nextTask && enabledTasks.length > 0 ? (
        <>
          <span className='icon-box checked'>
            <Icon icon={faCheck} />
          </span>
          <div className='task-text'>
            <p className='bf-p bf-small bf-strong'>All tasks completed</p>
            <span>
              <p>View your task page</p>
              <Icon icon={faArrowRight} />
            </span>
          </div>
        </>
      ) : !nextTask ? (
        <>
          <span className='icon-box hourglass'>
            <Icon icon={faHourglassHalf} />
          </span>
          <div className='task-text'>
            <p className='bf-p bf-small bf-strong'>No tasks yet</p>
            <span>
              <p>View your task page</p>
              <Icon icon={faArrowRight} />
            </span>
          </div>
        </>
      ) : (
        <>
          <span className='icon-box'>
            <Icon icon={faListCheck} />
          </span>
          <div className='task-text'>
            <p className='bf-p bf-small bf-strong'>Next task</p>
            <span>
              <p>
                <span className='bf-strong'>{pageDataOptions[nextTask?.section]?.label}: </span> {nextTask?.request}
              </p>
              <Icon icon={faArrowRight} />
            </span>
          </div>
        </>
      )}
    </div>
  );
};
export const OverviewWelcomeBox = ({}) => {
  const {id} = useSelector(state => state.company.data.info);
  const history = useHistory();

  return (
    <div
      className='task-box'
      style={{gridColumn: 'span 1'}}
      tabIndex={0}
      onKeyDown={e => {
        e.keyCode !== 13 || history.push(`/${id}/articles/df50aa43-817a-4443-4a90-08db41884f6c`);
      }}
      onClick={() => history.push(`/${id}/articles/df50aa43-817a-4443-4a90-08db41884f6c`)}
    >
      <span className='icon-box icon-box-chill'>
        <Icon icon={faSparkles} />
      </span>
      <div className='task-text'>
        <p className='bf-p bf-small bf-strong'>Onboard guide</p>
        <span>
          <p>Welcome to Intility Onboard</p>
          <Icon icon={faArrowRight} />
        </span>
      </div>
    </div>
  );
};
